<template class="ma-0 pa-0">
  <div id="svg-container" class="ma-0 pa-0">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 595.3 841.9" style="enable-background:new 0 0 595.3 841.9;" xml:space="preserve">
    <svg:style type="text/css">
      .st0{opacity:0.26;}
      .st1{clip-path:url(#SVGID_2_);}
      
        .st2{fill:none;stroke:#B7B7B7;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:0,8;}
      .st3{clip-path:url(#SVGID_4_);}
      .st4{fill:url(#SVGID_5_);}
      .st5{fill:url(#SVGID_6_);}
      .st6{fill:url(#SVGID_7_);}
      .st7{fill:#1B75BC;}
      .st8{enable-background:new    ;}
      .st9{font-family:'Montserrat-SemiBold';}
      .st10{font-size:9.3817px;}
      .st11{font-size:13.6093px;}
      .st12{font-family:'Montserrat-ExtraBold';}
      .st13{font-size:{{companyFont}}px;}
      .st14{font-size:{{productFont}}px;}
      .st15{font-size:11.0247px;}
      .st16{fill:#414042;}
      .st17{fill:url(#SVGID_8_);}
      .st18{opacity:0.58;}
      .st19{clip-path:url(#SVGID_10_);}
      .st20{opacity:0.2;}
      .st21{fill:#020202;}
      .st22{fill:url(#SVGID_11_);}
      .st23{opacity:0.25;}
      .st24{fill-rule:evenodd;clip-rule:evenodd;fill:#5A3B18;}
      .st25{fill:#010101;}
      .st26{fill:#FFFFFF;}
      .st1505{clip-path:url(#SVGID_2_);}
	    .st1506{fill:url(#SVGID_147_);}
	    .st1511{fill-rule:evenodd;clip-rule:evenodd;fill:#5A3B18;}
      .st1512{font-family:'Montserrat-Bold';}
      .st1513{font-size:8.0915px;font-weight: 600;}
	    .st1510{fill:url(#SVGID_150_);}
      .st221{opacity:0.25;}
    </svg:style>
    <g class="st0">
      <g>
        <defs>
          <rect id="SVGID_1_" x="-137.6" y="-272.2" width="985.7" height="1202.3"/>
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
        </clipPath>
        <g class="st1">
          <g>
            <g>
              <path class="st2" d="M-192.8-726.2C230.4-549,654.1-373,1076.5-194"/>
              <path class="st2" d="M-193.6-719.6C17.2-631.8,228.3-544.7,438.7-456c212.2,89.5,426.5,174.8,636.9,268.5"/>
              <path class="st2" d="M-194.4-712.9C15.8-625.8,226.7-540,436-450.9c212.5,90.5,429.3,172.7,638.8,270.2"/>
              <path class="st2" d="M-195.2-706.3c114.9,47.4,229.1,96.5,343.7,144.5c94.6,39.6,190.6,75.6,284.8,116.3
                c95.4,41.2,191.7,79.5,287,121c117.5,51,238.2,94.5,353.7,150.3"/>
              <path class="st2" d="M-196.1-699.7c115,47.2,229,96.7,343.7,144.7c94,39.4,189.6,74.3,283,115.2
                c44.6,19.5,89.1,39.1,134.3,57.1c51.3,20.3,100.9,41.9,151.5,64c118.4,51.7,240.9,93.4,356.7,151.2"/>
              <path class="st2" d="M-196.9-693.1c115.1,47.1,229,96.9,343.6,145c93.5,39.2,188.6,73,281.3,114.5
                c44.1,19.7,88.1,39.5,133,57.2c25.3,10,52.5,17.2,76.9,29.1c24.8,12,49.4,24,74.7,35C832-260.2,956.2-220.6,1072.3-161"/>
              <path class="st2" d="M-197.7-686.5c115.2,46.9,228.9,97.2,343.6,145.3c93,39,187.7,72,279.6,114c43.6,19.9,87.2,40,131.8,57.5
                c25.1,9.9,52.4,16.6,76.5,28.6c24.9,12.3,49.8,24.5,75.2,35.6c120.2,52.3,246.1,89.8,362.5,151"/>
              <path class="st2" d="M-198.6-679.8C-83.3-633,30.3-582.4,145-534.2c92.6,38.9,186.8,71.1,277.9,113.9
                c43.1,20.2,86.2,40.6,130.5,58.1c25,9.8,52.2,16.2,76.1,28.1c25.1,12.5,50,25,75.8,36.1c121.1,52.4,248.6,87.5,365.3,150.2"/>
              <path class="st2" d="M-199.4-673.2c115.3,46.7,228.8,97.8,343.5,146.1c48.5,20.4,98,36.5,146.9,55.6
                c44.1,17.2,86.8,37.9,129.4,58.4c42.6,20.6,85.3,41.3,129.3,58.8c24.8,9.8,52.1,15.8,75.8,27.8c25.2,12.8,50.3,25.4,76.3,36.5
                c121.9,52.3,251,84.8,368,148.8"/>
              <path class="st2" d="M-200.2-666.6C-84.8-620,28.5-568.5,143.2-520.1c48.3,20.4,97.8,36,146.5,55.2
                c43.9,17.2,86.1,38.3,128.2,59.3c42.2,21,84.4,42.2,128.2,59.7c24.6,9.8,51.9,15.5,75.4,27.5c25.3,13,50.6,25.7,76.9,36.8
                c122.8,52,253.3,81.8,370.6,147"/>
              <path class="st2" d="M-201-660c115.5,46.6,228.7,98.5,343.3,147c48.2,20.4,97.6,35.6,146.1,54.9
                c43.6,17.3,85.4,38.9,127.1,60.3c41.7,21.5,83.5,43.2,127,60.8c24.4,9.9,51.7,15.3,75,27.3c25.5,13.2,50.9,26,77.4,37
                c123.7,51.6,255.5,78.4,373.2,144.7"/>
              <path class="st2" d="M-201.9-653.4c115.6,46.5,228.6,98.9,343.3,147.6c48,20.4,97.5,35.2,145.7,54.6
                c43.4,17.5,84.7,39.6,126,61.5c41.3,22,82.6,44.2,125.9,62c24.3,10,51.6,15.2,74.7,27.2c25.6,13.3,51.1,26.2,77.9,37.2
                c124.5,51.1,257.6,74.7,375.7,141.9"/>
              <path class="st2" d="M-202.7-646.7C-87-600.3,25.8-547.4,140.5-498.6c47.9,20.4,97.3,34.9,145.3,54.5
                c43.2,17.7,84.1,40.3,124.9,62.9c40.8,22.5,81.7,45.4,124.8,63.4c24.2,10.1,51.4,15.1,74.4,27.2c25.7,13.4,51.4,26.3,78.3,37.2
                c125.4,50.4,259.6,70.7,378.2,138.7"/>
              <path class="st2" d="M-203.5-640.1C-87.8-593.7,25-540.2,139.6-491.3c47.8,20.4,97.3,34.6,145,54.5
                c43,17.9,83.5,41.2,123.8,64.4c40.4,23.2,80.9,46.7,123.8,64.9c24,10.2,51.1,15.2,74.1,27.2c25.8,13.5,51.6,26.4,78.8,37.1
                c126.3,49.6,261.5,66.4,380.5,135.1"/>
              <path class="st2" d="M-204.4-633.5C-88.5-587.1,24-533.1,138.7-484c47.6,20.4,97.2,34.3,144.7,54.5
                c42.8,18.2,82.9,42.3,122.8,66c40,23.8,80,48,122.8,66.7c23.9,10.4,50.9,15.3,73.8,27.3c25.9,13.6,51.9,26.4,79.3,36.9
                c127.1,48.7,263.3,61.7,382.8,131.1"/>
              <path class="st2" d="M-205.2-626.9C-89.3-580.4,23.1-526,137.7-476.7c23.7,10.2,47.3,21.1,71.6,29.8
                c24.2,8.7,49,14.5,72.8,24.8c42.7,18.6,82.4,43.4,121.8,67.8c39.6,24.5,79.2,49.5,121.8,68.5c23.8,10.6,50.7,15.5,73.5,27.5
                c26,13.7,52.1,26.4,79.7,36.7c128,47.6,265,56.8,385.1,126.7"/>
              <path class="st2" d="M-206-620.3c116,46.4,228.2,101.4,342.8,150.9c23.6,10.2,47.3,21.2,71.6,29.9
                c24.2,8.7,48.8,14.4,72.5,24.9c42.6,19,81.8,44.6,120.9,69.8c39.1,25.3,78.4,51,120.8,70.5c23.7,10.9,50.4,15.7,73.2,27.7
                c26.1,13.7,52.3,26.4,80.1,36.4c128.9,46.4,266.6,51.5,387.2,121.9"/>
              <path class="st2" d="M-206.8-613.6c116.1,46.5,228.1,102,342.7,151.7c23.6,10.3,47.3,21.2,71.6,29.9
                c24.2,8.7,48.7,14.4,72.2,25.2c42.5,19.5,81.3,45.9,120,71.9s77.6,52.7,119.9,72.6c23.6,11.1,50.1,16,73,28
                c26.2,13.7,52.5,26.3,80.5,36c129.7,45.1,268.1,46,389.3,116.7"/>
              <path class="st2" d="M-207.7-607c116.1,46.5,228,102.6,342.6,152.5c23.6,10.3,47.3,21.3,71.5,30c24.1,8.7,48.5,14.4,71.9,25.4
                c42.4,20,80.8,47.3,119.1,74.1c38.3,26.9,76.8,54.3,119,74.9c23.5,11.4,49.9,16.4,72.7,28.3c26.3,13.7,52.7,26.1,80.9,35.5
                C800.7-142.5,939.5-146,1061.5-75"/>
              <path class="st2" d="M-208.5-600.4C-92.3-553.8,19.4-497.2,134-447c23.6,10.3,47.3,21.4,71.5,30.1
                c24.1,8.7,48.4,14.5,71.6,25.8c42.3,20.6,80.4,48.8,118.2,76.4c37.9,27.7,76,56.1,118.1,77.2c23.4,11.7,49.6,16.8,72.5,28.7
                c26.4,13.7,52.9,25.9,81.3,35C728-154.3,791.4-147,854-136.7c72.9,12,142.3,30.9,206.6,68.3"/>
              <path class="st2" d="M-209.3-593.8C-93-547.2,18.5-490,133.1-439.5c23.6,10.4,47.3,21.5,71.5,30.2
                c24.1,8.7,48.2,14.6,71.4,26.2c42.2,21.2,79.9,50.3,117.4,78.8c37.6,28.6,75.2,58,117.3,79.7c23.3,12,49.3,17.3,72.3,29.2
                c26.4,13.7,53.1,25.7,81.7,34.4c61,18.7,124.7,24.5,187.6,33.5c73.3,10.5,143,28.3,207.6,65.7"/>
              <path class="st2" d="M-210.2-587.1C-93.8-540.5,17.5-482.7,132.1-432c23.6,10.4,47.2,21.5,71.5,30.3
                c24.2,8.7,48.1,14.7,71.2,26.6c42.2,21.8,79.5,52,116.6,81.4c37.2,29.5,74.5,59.9,116.5,82.3c23.2,12.4,49,17.8,72.1,29.7
                c26.5,13.6,53.3,25.4,82,33.7c61.4,17.9,125.3,22.2,188.5,29.8c73.7,8.9,143.6,25.6,208.5,63"/>
              <path class="st2" d="M-211-580.5c116.5,46.8,227.6,105.1,342.2,156.1c23.6,10.5,47.2,21.6,71.5,30.4
                c24.2,8.8,48,14.9,70.9,27.1c42.2,22.5,79.1,53.7,115.8,84c36.8,30.4,73.7,61.8,115.7,85c23.1,12.8,48.7,18.4,71.9,30.2
                c26.6,13.6,53.5,25.1,82.4,33c61.7,17.1,125.9,19.7,189.3,26c74.1,7.3,144.2,22.8,209.4,60.1"/>
              <path class="st2" d="M-211.8-573.9c116.5,46.8,227.5,105.8,342,157c23.6,10.5,47.2,21.7,71.5,30.5
                c24.2,8.8,47.9,15.1,70.8,27.7c42.1,23.3,78.7,55.5,115.1,86.8c36.5,31.4,73,63.8,115,87.8c23.1,13.2,48.4,19,71.7,30.8
                c26.6,13.5,53.7,24.7,82.7,32.3c62,16.1,126.4,17.2,190,22c74.4,5.6,144.8,19.8,210.3,57.1"/>
              <path class="st2" d="M-212.7-567.3c116.6,46.9,227.4,106.5,341.9,158c46.4,20.9,98.1,33.8,142.1,58.9
                c42.1,24.1,78.4,57.4,114.4,89.6c36.1,32.4,72.3,65.9,114.2,90.7c23,13.6,48.1,19.7,71.6,31.5c26.7,13.4,53.9,24.3,83,31.4
                c62.5,15.3,127,14.6,190.9,17.9c74.7,3.9,145.3,16.8,211.1,54"/>
              <path class="st2" d="M-213.5-560.7c116.7,47,227.3,107.2,341.8,159c23.5,10.6,47.2,21.8,71.5,30.7
                c24.3,8.9,47.8,15.6,70.4,28.9c42.2,24.9,78.1,59.3,113.7,92.5c35.8,33.4,71.6,68,113.6,93.6c22.9,14,47.8,20.4,71.4,32.1
                c26.8,13.3,54.1,23.9,83.3,30.6C715-78.8,779.8-81.2,843.8-79.4c74.9,2.1,145.7,13.7,211.9,50.7"/>
              <path class="st2" d="M-214.3-554c116.8,47.1,227.1,107.9,341.6,160c46.2,21,98.5,34,141.7,60.5c42.2,25.8,77.7,61.3,113,95.5
                c35.4,34.4,70.9,70.2,112.9,96.7c22.8,14.4,47.5,21.1,71.3,32.8C593-95.3,620.4-85,649.8-78.8c63.3,13.3,128.2,9.1,192.3,9.4
                C917.3-69.1,988.3-59,1054.8-22"/>
              <path class="st2" d="M-215.1-547.4c116.9,47.3,227,108.7,341.5,161.1c46.2,21.1,98.7,34.2,141.6,61.3
                c42.2,26.7,77.5,63.4,112.4,98.6c35.1,35.4,70.3,72.4,112.3,99.8c22.7,14.8,47.2,21.9,71.2,33.6c26.9,13.1,54.4,23,83.8,28.7
                c63.7,12.3,128.7,6.3,193,5c75.2-1.5,146.4,7.1,213.3,43.9"/>
              <path class="st2" d="M-216-540.8c117,47.4,226.9,109.4,341.4,162.2c46.2,21.3,98.9,34.4,141.4,62.2
                c42.3,27.6,77.2,65.5,111.8,101.8c34.8,36.5,69.6,74.6,111.7,103c22.7,15.2,47,22.6,71.2,34.3c26.9,12.9,54.6,22.5,84.1,27.7
                c64.1,11.2,129.2,3.4,193.6,0.5c75.3-3.4,146.7,3.7,214,40.3"/>
              <path class="st2" d="M-216.8-534.2C-99.8-486.6,10-424,124.4-370.9c46.2,21.4,99.2,34.7,141.3,63.1
                c42.3,28.6,76.9,67.6,111.3,105c34.5,37.6,69,76.9,111.1,106.2c22.5,15.7,46.6,23.6,71,35.2c26.9,12.8,54.7,22,84.3,26.7
                c64.5,10.1,129.7,0.5,194.2-4C913-44,984.5-38.4,1052.2-2.1"/>
              <path class="st2" d="M-217.6-527.6c117.1,47.7,226.7,111,341.1,164.3c46.2,21.5,99.4,34.9,141.2,64.1
                c42.4,29.6,76.7,69.8,110.7,108.2c34.3,38.7,68.4,79.1,110.6,109.5C529.2-50.2,589-27,641.5-19.8c64.9,9,130.2-2.5,194.8-8.7
                c75.4-7.2,147.1-3.3,215.2,32.9"/>
              <path class="st2" d="M-218.5-520.9c117.2,47.8,226.6,111.8,341,165.5c46.2,21.7,99.6,35.2,141.1,65.1
                c42.5,30.6,76.5,72,110.2,111.5c34,39.8,67.9,81.5,110.1,112.9c42.9,32,102.7,55,155.6,61.3c65.4,7.8,130.7-5.6,195.3-13.4
                c75.3-9.1,147.2-6.9,215.8,29.1"/>
              <path class="st2" d="M-219.3-514.3c117.3,48,226.4,112.6,340.8,166.6c46.3,21.9,99.8,35.5,141.1,66.1
                c42.6,31.6,76.3,74.3,109.8,114.9c33.7,40.9,67.3,83.8,109.6,116.3c42.7,32.8,102.3,55.7,155.7,61.1
                c141.2,14.3,276.3-63.2,412.1,7"/>
              <path class="st2" d="M-220.1-507.7C-102.8-459.6,6.2-394.3,120.6-340c46.4,22,100.1,35.8,141,67.1
                c42.7,32.7,76.1,76.6,109.3,118.3c33.5,42,66.8,86.2,109.2,119.8c42.6,33.7,102,56.4,155.9,60.9c141.6,11.7,276-71.7,413-1.7"
                />
              <path class="st2" d="M-221-501.1c117.4,48.3,226.2,114.2,340.5,168.9c46.5,22.2,100.3,36.1,141,68.2
                c42.8,33.8,75.9,78.9,108.9,121.7c33.3,43.1,66.3,88.5,108.8,123.2c42.4,34.6,101.5,57.1,156,60.6
                c142.1,9,275.7-80.1,413.9-10.6"/>
              <path class="st2" d="M-221.8-494.4c117.5,48.4,226,115,340.4,170c46.6,22.4,100.5,36.4,141,69.3
                c42.9,34.8,75.7,81.3,108.6,125.2c33,44.2,65.8,90.8,108.4,126.7c42.3,35.5,101.1,57.9,156.1,60.3
                c142.4,6.3,275.3-88.6,414.6-19.5"/>
              <path class="st2" d="M-222.6-487.8C-105-439.2,3.3-372,117.6-316.7c46.7,22.6,100.8,36.8,140.9,70.3
                c43,36,75.6,83.6,108.2,128.7c32.9,45.4,65.4,93.3,108.1,130.3C517,49.1,575.5,71.3,631.1,72.7
                c142.7,3.6,274.8-97.2,415.3-28.5"/>
              <path class="st2" d="M-223.4-481.2c117.7,48.8,225.8,116.6,340.1,172.3c46.9,22.9,101,37.1,141,71.4
                c43.2,37.1,75.5,86,107.9,132.2c32.7,46.6,64.9,95.7,107.8,133.8C515.3,66,573.4,88,629.7,88.3c142.9,0.8,274.3-105.8,416-37.5
                "/>
              <path class="st2" d="M-224.3-474.6c117.8,48.9,225.7,117.4,339.9,173.5c47.1,23.1,101.2,37.5,141,72.5
                c43.3,38.2,75.4,88.3,107.6,135.7c65,95.4,135.2,198.6,264,196.9c143-1.9,273.7-114.3,416.5-46.5"/>
              <path class="st2" d="M-225.1-468c117.9,49.1,225.5,118.2,339.8,174.7c47.2,23.3,101.5,37.8,141,73.6
                C299.2-180.4,331-129,363.1-80.5C427,16.1,496.6,124,627,119.7C770.1,115,900.1-3.2,1044,64.1"/>
              <path class="st2" d="M-225.9-461.3C-108-412-0.5-342.3,113.7-285.5c47.4,23.6,101.7,38.2,141.1,74.7
                C298.4-170.3,330.1-117.6,362-68c62.8,97.6,131.9,210.3,263.8,203.4c143-7.4,272.4-131.5,417.4-64.7"/>
              <path class="st2" d="M-226.8-454.7c118.1,49.5,225.3,119.9,339.5,177c47.6,23.8,102,38.5,141.2,75.8
                c43.7,41.7,75.2,95.4,107,146.2C422.6,42.8,491.2,160.5,624.6,151c143-10.1,271.7-140,417.7-73.7"/>
              <path class="st2" d="M-227.6-448.1c118.2,49.6,225.1,120.7,339.3,178.2C159.6-245.8,214-231,253-193
                c43.9,42.9,75.2,97.8,106.9,149.7c60.6,99.3,128.9,222.1,263.7,210c142.8-12.8,271-148.5,418-82.7"/>
              <path class="st2" d="M-228.4-441.5c118.3,49.8,225,121.5,339.2,179.4c48,24.3,102.6,39.2,141.3,78
                c44,44,75.2,100.1,106.7,153.2c59.5,100,127.5,228,263.7,213.2C765,166.8,892.7,25.4,1040.6,90.6"/>
              <path class="st2" d="M-229.2-434.8c118.4,50,224.9,122.4,339,180.5c48.3,24.6,102.8,39.6,141.5,79.1
                c44.2,45.1,75.2,102.4,106.6,156.6c58.5,100.6,126.2,234,263.7,216.5C763.8,179.8,891,32.7,1039.8,97.3"/>
              <path class="st2" d="M-230.1-428.2C-111.7-378.1-5.4-305,108.8-246.5c48.5,24.9,103.1,40,141.6,80.1
                c44.4,46.2,75.2,104.7,106.6,160c57.4,101.2,125,239.9,263.8,219.6C762.5,192.6,889.4,39.7,1039,103.9"/>
              <path class="st2" d="M-230.9-421.6c119.4,50.7,226.2,125.1,341.4,184.2c45.5,23.4,97.3,37.6,134.1,74.5
                C292-115.2,323.8-52.5,356.2,5.8C412,106.4,478.5,249.7,616.8,229.1c143-21.3,269.6-182.7,421.3-118.6"/>
              <path class="st2" d="M-231.7-415c119.5,50.9,226.1,125.9,341.2,185.3c45.7,23.6,97.5,37.9,134.2,75.4
                c47.7,48.7,79.4,112.7,111.7,172.1c54.8,101,121.3,249.8,260.8,226.5C758.7,220.5,885,53.4,1037.4,117"/>
              <path class="st2" d="M-232.6-408.4c119.7,51,226,126.7,341.1,186.5c45.9,23.8,97.8,38.2,134.4,76.3
                c47.9,49.8,79.5,115,111.7,175.5c53.9,101.2,120.4,255.5,261,229.6c141.9-26.2,267.9-198.9,421-135.8"/>
              <path class="st2" d="M-233.4-401.7c119.8,51.2,225.8,127.5,340.9,187.6c46.1,24.1,98.1,38.6,134.6,77.1
                c48.2,50.9,79.7,117.3,111.8,178.8C406.9,143.2,473.5,303,615,274.5C756.3,246,882,67.7,1035.7,130.4"/>
              <path class="st2" d="M-234.2-395.1c119.9,51.4,225.7,128.3,340.8,188.7c46.3,24.3,98.3,38.9,134.8,77.9
                c48.4,52,79.8,119.6,111.9,182.1c52.1,101.6,118.9,266.9,261.3,235.7c73.6-16.1,137-66.4,200-105
                C881.9,143,956,105,1034.9,136.9"/>
              <path class="st2" d="M-235.1-388.5c120,51.5,225.6,129.1,340.6,189.8c46.5,24.5,98.6,39.2,135,78.7
                C289.3-67,320.6,1.7,352.7,65.3C403.9,166.9,471,337.7,614.2,304c73.9-17.4,137.1-69.4,199.8-109.4
                c66.6-42.5,140.7-82.7,220.1-51"/>
              <path class="st2" d="M-235.9-381.9C-115.8-330.2-10.4-252,104.6-191c46.7,24.8,98.9,39.5,135.3,79.5
                c49,53.9,80.2,123.8,112.2,188.4C402.5,178.5,470,354.7,614,318.5c74.2-18.6,137-72.4,199.5-113.8
                c65.8-43.6,140-86.1,219.7-54.5"/>
              <path class="st2" d="M-236.7-375.2c120.2,51.8,225.3,130.6,340.4,192c46.8,25,99.2,39.7,135.5,80.1
                c49.3,54.8,80.4,125.8,112.5,191.4C401.2,189.9,469,371.4,613.8,332.7c74.5-19.9,137-75.4,199.2-118.1
                c65.1-44.7,139.3-89.5,219.4-57.8"/>
              <path class="st2" d="M-237.5-368.6c120.3,51.9,225.2,131.4,340.2,193c47,25.2,99.5,40,135.8,80.8C288-39.1,319.2,33,351.2,99.6
                C400,201,468.3,387.8,613.7,346.8c74.7-21.1,137-78.2,198.9-122.2c64.4-45.7,138.5-92.7,219-61.1"/>
              <path class="st2" d="M-238.4-362C-118-309.9-13.3-229.9,101.7-168c47.1,25.4,99.7,40.2,136.1,81.4
                c49.9,56.6,81,129.7,113,197.1c48.2,101.3,117,293.1,262.9,249.7c74.9-22.3,136.8-80.9,198.5-126.1
                c63.6-46.6,137.7-95.7,218.5-64.1"/>
              <path class="st2" d="M-239.2-355.4c120.5,52.1,224.9,132.7,339.9,194.9c47.4,25.6,99.8,40.7,136.2,82.3
                c50,57.1,81.2,130.6,113.5,198.4c48.2,101.2,117.7,296.1,264,250.3c74.8-23.4,136.4-82.2,198-127.9
                c63-46.7,136.8-96.7,217.5-65.9"/>
              <path class="st2" d="M-240-348.8C-119.5-296.6-15.3-215.5,99.7-153c47.5,25.9,99.9,41,136.4,82.9
                c50.1,57.5,81.6,131.2,114.2,199.4c48.3,101.2,118.6,298.4,265.1,250.6c74.6-24.3,135.8-83.2,197.5-129.3
                c62.4-46.7,135.9-97.4,216.3-67.3"/>
              <path class="st2" d="M-240.9-342.1c120.6,52.2,224.6,133.8,339.5,196.6c47.7,26,100,41.3,136.5,83.4
                c50.2,57.8,82,131.6,114.8,200c48.5,101.1,119.6,300,266.3,250.6c74.4-25.1,135.3-83.9,196.9-130.3
                c61.9-46.6,135.1-97.8,215.2-68.2"/>
              <path class="st2" d="M-241.7-335.5C-121-283.2-17.2-201.1,97.6-138.1c47.8,26.2,100.1,41.6,136.8,83.8
                C284.8,3.6,316.8,77.5,349.9,146c48.8,101,120.8,301.2,267.5,250.3c74.2-25.7,134.7-84.5,196.2-131
                c61.4-46.5,134.2-97.9,213.9-68.7"/>
              <path class="st2" d="M-242.5-328.9c120.8,52.4,224.3,135,339.1,198.3c47.8,26.4,100.2,41.8,137,84.1
                c50.5,58,82.8,131.8,116.3,200.4C399,254.6,472,455.6,618.6,403.5c73.9-26.2,134.1-84.8,195.4-131.3
                c60.9-46.2,133.4-97.8,212.6-68.9"/>
              <path class="st2" d="M-243.3-322.3c120.9,52.4,224.2,135.6,338.9,199.2c47.8,26.5,100.3,42,137.2,84.2
                C283.5,19,316.1,92.8,349.9,161.2C399.5,261.8,473.4,463.1,620,410.1c73.5-26.6,133.5-84.9,194.6-131.4
                c60.4-46,132.5-97.5,211.2-68.8"/>
              <path class="st2" d="M-244.2-315.7c121,52.5,224,136.2,338.7,200C142.4-89,194.9-73.5,232-31.4c51,57.8,83.9,131.4,118,199.7
                c50,100.3,125,301.6,271.4,247.9c73.2-26.8,132.8-84.8,193.8-131.3c59.9-45.6,131.7-97.1,209.8-68.5"/>
              <path class="st2" d="M-245-309c121.1,52.6,223.9,136.8,338.5,200.9C141.3-81.4,194-65.9,231.3-24
                c51.1,57.6,84.4,131,118.8,199.1c50.6,100.1,126.6,301,272.8,246.8c72.8-27,132.1-84.7,192.9-130.9
                c59.5-45.2,130.9-96.4,208.3-67.9"/>
              <path class="st2" d="M-245.8-302.4C-124.6-249.8-22.1-165,92.5-100.7c47.8,26.9,100.7,42.4,138.2,84.3
                c51.4,57.4,85,130.7,119.8,198.8c51.1,99.8,128.4,301,274.3,245.9c72.4-27.3,131.3-84.7,191.9-130.9
                c58.9-44.9,129.9-96,206.7-67.6"/>
              <path class="st2" d="M-246.7-295.8c121.3,52.7,223.6,138,338.1,202.6c48,27.1,100.9,42.7,138.6,84.7
                c51.7,57.6,85.8,131,121,199.2c51.4,99.5,130.2,302.3,276,245.7c71.9-27.9,130.4-85.4,190.7-131.7
                c58.1-44.7,128.6-96.3,204.8-68.3"/>
              <path class="st2" d="M-247.5-289.2C-126.2-236.4-24.1-150.6,90.4-85.7c48.1,27.2,101.1,43,139,85.1
                c52,57.7,86.5,131.2,122.1,199.5c35.9,68.8,70.3,142.3,122,200.9c42.2,47.8,94.4,68.8,155.6,44.5
                c71.5-28.5,129.6-85.9,189.6-132.4c57.3-44.6,127.3-96.5,203-68.9"/>
              <path class="st2" d="M-248.3-282.5C-126.9-229.7-25.1-143.4,89.4-78.3C137.6-50.9,190.7-35,228.7,7.1
                C281,65,315.8,138.5,351.8,206.9c36.3,68.8,71,142.3,123,201c42.5,47.9,94.8,68.9,156,44c71.1-29,128.8-86.4,188.5-132.9
                c56.8-44.3,126.3-96.5,201.5-69.3"/>
              <path class="st2" d="M-249.2-275.9C-127.6-223-26.1-136.2,88.4-70.8C136.6-43.2,189.8-27.3,228,14.9
                c52.5,57.9,87.7,131.4,124.1,199.8c36.6,68.8,71.7,142.4,124,201c42.8,48,95.1,69,156.5,43.5c70.8-29.4,128.1-86.8,187.6-133.4
                c56.2-44.1,125.3-96.5,199.9-69.6"/>
              <path class="st2" d="M-250-269.3c121.6,53,223,140.3,337.3,206c48.3,27.7,101.5,43.8,139.9,85.9
                c52.7,57.9,88.2,131.4,124.9,199.9c36.9,68.8,72.3,142.3,124.8,200.9c43.1,48.1,95.5,69,157,43
                c70.4-29.8,127.5-87.1,186.8-133.8c55.6-43.8,124.4-96.4,198.5-69.8"/>
              <path class="st2" d="M-250.8-262.7C-129.1-209.6-28-121.8,86.3-55.9C134.7-28,188-11.9,226.4,30.3
                c52.9,58,88.6,131.4,125.7,199.9C389.3,299,425,372.4,477.7,431c43.3,48.2,95.9,69,157.5,42.6c70.1-30.1,126.9-87.4,186-134.1
                c55.1-43.6,123.6-96.2,197.2-70"/>
              <path class="st2" d="M-251.6-256.1C-129.8-202.9-29-114.6,85.3-48.4C133.7-20.3,187-4.2,225.6,38c53,58,89,131.4,126.4,199.9
                c37.5,68.7,73.4,142.1,126.3,200.7c43.6,48.3,96.2,69,158,42.1c69.8-30.5,126.4-87.7,185.4-134.4
                c54.7-43.3,122.8-96.1,196-70.1"/>
              <path class="st2" d="M-252.5-249.4C-130.6-196.2-30-107.3,84.3-40.9C132.7-12.7,186,3.5,224.7,45.7
                c53.1,58,89.4,131.4,126.9,199.9c37.7,68.7,73.9,142.1,126.9,200.5c43.9,48.4,96.6,69,158.5,41.6c69.6-30.8,126-88,184.8-134.7
                c54.3-43.1,122.1-95.9,194.9-70.3"/>
              <path class="st2" d="M-253.3-242.8C-131.3-189.6-31-100.1,83.2-33.4C131.7-5.1,185,11.2,223.8,53.5
                c53.2,58.1,89.6,131.4,127.5,199.9c37.9,68.6,74.3,142,127.4,200.4c44.2,48.6,97,69,159,41.1c69.3-31.2,125.6-88.3,184.4-135
                C876,317,943.6,264.1,1016,289.5"/>
              <path class="st2" d="M-254.1-236.2C-132.1-182.9-32-92.9,82.2-26c48.5,28.4,101.9,44.8,140.7,87.1
                c53.3,58.1,89.9,131.5,127.9,199.9c38.1,68.6,74.6,141.9,127.8,200.4c44.5,48.9,97.3,69,159.5,40.6
                c69.1-31.5,125.3-88.6,184-135.4c53.6-42.7,121-95.7,193-70.7"/>
              <path class="st2" d="M-255-229.6C-132.8-176.2-33-85.7,81.2-18.5c48.5,28.6,101.9,45,140.7,87.4c53.3,58.2,90,131.6,128.2,200
                c38.3,68.6,74.9,141.9,128.2,200.4c44.7,49.1,97.7,69,160.1,40.1c69-31.9,125-89,183.7-135.9c53.2-42.5,120.5-95.7,192.2-71"/>
              <path class="st2" d="M-255.8-223C-133.5-169.5-33.9-78.5,80.1-11C128.7,17.7,182,34.1,220.9,76.7
                C274.2,135.1,311,208.5,349.4,277c38.4,68.6,75.2,141.9,128.4,200.5c19,20.8,41.4,41,68.5,50.4c30.9,10.7,63.5,2.7,92.1-10.7
                c68.9-32.3,124.8-89.5,183.6-136.4c53-42.4,120-95.7,191.5-71.4"/>
              <path class="st2" d="M-256.6-216.3C-134.3-162.8-34.9-71.3,79.1-3.5c48.6,28.8,101.8,45.4,140.7,88.1
                c53.3,58.6,90.2,132,128.6,200.6c38.5,68.7,75.3,142,128.6,200.7c18.9,20.8,41.3,41.1,68.4,50.5c31.2,10.7,64,2.3,92.7-11.3
                c68.8-32.7,124.7-90,183.5-137.1c52.7-42.3,119.6-95.8,190.9-72"/>
              <path class="st2" d="M-257.5-209.7C-135-156-35.9-64.1,78.1,3.8c48.6,29,101.8,45.6,140.7,88.5c53.2,58.8,90.2,132.3,128.7,201
                c38.6,68.8,75.5,142.1,128.7,201c18.9,20.8,41.2,41.4,68.3,50.7c31.4,10.8,64.5,2,93.3-11.9c68.7-33.1,124.7-90.6,183.5-137.8
                c52.5-42.2,119.3-96,190.5-72.8"/>
              <path class="st2" d="M-258.3-203.1C-136.5-148.4-36.8-57.8,77.2,9.9c48.6,28.9,102,46,140.8,88.8
                c53.3,58.7,90.4,132.2,128.9,200.9c38.6,68.8,75.4,142.1,128.6,201c18.9,20.9,41.2,41.4,68.2,51c31.4,11,64.5,2.7,93.5-11
                c68.8-32.5,125.2-89.6,183.6-137c52.3-42.4,118.7-97.2,190.1-74.5"/>
              <path class="st2" d="M-259.1-196.5C-138-140.9-37.6-51.8,76.4,15.7c48.6,28.8,102.1,46.3,141,88.9
                c53.4,58.7,90.5,132.2,129.1,200.9c38.6,68.8,75.4,142,128.4,201.1c18.8,21,41.1,41.6,68.1,51.3c31.3,11.3,64.5,3.5,93.7-10
                c68.9-31.9,125.6-88.7,183.7-136.1c52.1-42.6,118.1-98.3,189.8-76.1"/>
              <path class="st2" d="M-259.9-189.8c120.4,56.3,221.5,144,335.6,211c48.7,28.6,102.3,46.5,141.1,89.1
                c53.5,58.7,90.7,132.2,129.3,201s75.4,142,128.2,201.3c18.8,21.1,41,41.7,68,51.7c31.3,11.6,64.4,4.3,93.8-9
                c69-31.3,126.1-87.6,183.8-135.2c52-42.8,117.6-99.3,189.4-77.6"/>
              <path class="st2" d="M-260.8-183.2c118.8,56.6,219.9,141.7,333,208C124.7,55.6,181,74.9,221.9,122
                c50.4,58,86.4,128.1,123.7,194.7c37.3,66.5,72.9,136.4,122.4,195c46.8,55.4,101.4,81.2,171,48.9C707.2,529,763.8,474,821,426.8
                c51.5-42.6,116-98.9,187.5-77.8"/>
              <path class="st2" d="M-261.6-176.6c118.2,57.1,220,140.7,333.1,206.4c52.6,30.5,109,50.3,149.9,97.2
                c50.5,58,86.5,128.2,123.9,194.8c37.3,66.6,72.9,136.5,122.2,195.2c46.6,55.4,101.2,82.1,171.1,50.5
                c68.2-30.9,125.2-85.4,182-132.8c51.4-42.7,115.5-99.9,187.1-79.2"/>
              <path class="st2" d="M-262.4-170c117.5,57.6,220,139.5,333.2,204.5c52.7,30.3,109.2,50.3,150.1,97.2
                c50.6,58,86.6,128.3,124,195.1c37.3,66.6,72.8,136.6,122.1,195.5c46.3,55.3,101,83,171.1,52.1
                c68.3-30.2,125.6-84.3,182.1-131.6c51.2-42.9,115-100.8,186.7-80.5"/>
              <path class="st2" d="M-263.3-163.3c116.9,57.9,220.2,138,333.4,202.2c52.9,30,109.4,50.4,150.3,97.2
                c50.7,58.1,86.7,128.5,124.1,195.3c37.3,66.7,72.8,136.7,121.9,195.8c46,55.3,100.8,84,171.1,53.7
                c68.4-29.5,125.9-83.1,182.2-130.4c51.1-43,114.4-101.6,186.3-81.7"/>
              <path class="st2" d="M-264.1-156.7C-147.8-98.6-43.8-20.2,69.5,43.1c53.1,29.7,109.6,50.3,150.5,97.2
                c50.7,58.1,86.9,128.6,124.3,195.6C381.6,402.7,417,472.8,466,532.1c45.7,55.3,100.6,85,171.1,55.4
                c68.5-28.7,126.3-81.8,182.3-129.2c51-43.1,113.9-102.4,185.9-82.8"/>
              <path class="st2" d="M-264.9-150.1C-149.3-91.8-44.4-15.3,68.9,47c53.3,29.3,109.8,50.3,150.7,97.1
                c50.8,58.2,87,128.8,124.4,195.9c37.3,66.9,72.7,137.1,121.5,196.6c45.3,55.3,100.3,86,171.1,57.2
                c68.5-28,126.7-80.5,182.3-127.8c50.8-43.2,113.4-103.2,185.5-83.9"/>
              <path class="st2" d="M-265.8-143.5C-150.7-85-45.1-10.6,68.3,50.8c53.5,28.9,110,50.1,150.9,97
                c50.9,58.3,87.1,129.1,124.5,196.3C381,411,416.3,481.3,465,541.1c45,55.3,100.1,87.1,171,59c68.6-27.2,127-79.2,182.4-126.4
                c50.7-43.3,112.9-103.9,185.1-84.9"/>
              <path class="st2" d="M-266.6-136.9C-152.1-78.4-45.7-6,67.8,54.3c53.7,28.5,110.2,49.9,151.1,96.8
                c50.9,58.4,87.2,129.3,124.6,196.7c37.3,67.2,72.6,137.5,121.1,197.5c44.7,55.4,99.8,88.2,171,60.8
                C704.3,579.7,763,528.3,818,481.2c50.6-43.4,112.3-104.5,184.7-85.8"/>
              <path class="st2" d="M-267.4-130.2C-153.5-71.8-46.2-1.5,67.2,57.6c54,28.1,110.5,49.7,151.3,96.6
                c51,58.6,87.3,129.6,124.7,197.1c37.3,67.3,72.5,137.7,120.9,198c44.4,55.4,99.5,89.3,171,62.7
                c68.7-25.5,127.7-76.3,182.4-123.4c50.5-43.4,111.8-105.2,184.2-86.7"/>
              <path class="st2" d="M-268.2-123.6C-154.9-65.3-46.8,2.8,66.8,60.6C121,88.3,177.4,110.1,218.3,157
                c51.1,58.7,87.4,129.9,124.9,197.6c37.3,67.4,72.5,138,120.7,198.6c44.1,55.4,99.3,90.4,171,64.7
                c68.8-24.6,128-74.8,182.4-121.8c50.4-43.5,111.3-105.7,183.8-87.4"/>
              <path class="st2" d="M-269.1-117C-156.2-58.9-47.3,6.9,66.3,63.5c54.6,27.2,110.9,49.1,151.8,96.1
                c51.1,58.8,87.5,130.2,125,198c37.3,67.6,72.4,138.2,120.4,199.1c43.8,55.4,99,91.5,170.9,66.7
                c68.8-23.8,128.3-73.2,182.5-120.1c50.3-43.5,110.8-106.3,183.3-88.1"/>
              <path class="st2" d="M-269.9-110.4C-157.5-52.5-47.8,10.9,65.8,66.2c54.9,26.7,111.1,48.7,152,95.8
                c51.2,59,87.6,130.5,125.1,198.5c37.3,67.8,72.3,138.5,120.2,199.7c43.5,55.5,98.7,92.7,170.9,68.7
                c68.8-22.8,128.6-71.6,182.5-118.4c50.2-43.5,110.3-106.7,182.9-88.7"/>
              <path class="st2" d="M-270.7-103.8C-158.8-46.2-48.3,14.8,65.4,68.7c55.2,26.1,111.3,48.3,152.2,95.5
                c51.3,59.1,87.7,130.8,125.2,199c37.3,67.9,72.3,138.8,120,200.3c43.2,55.5,98.4,93.8,170.8,70.8
                c68.8-21.9,128.8-69.9,182.5-116.5c50.1-43.5,109.8-107.2,182.4-89.3"/>
              <path class="st2" d="M-271.6-97.1C-160.1-39.9-48.8,18.6,65,71c55.6,25.6,111.5,47.8,152.5,95.1
                c51.3,59.3,87.8,131.2,125.3,199.6c37.3,68.1,72.2,139.1,119.8,200.9c42.9,55.6,98.1,95,170.8,72.9
                c68.8-20.9,129.1-68.2,182.4-114.7c50-43.5,109.3-107.6,181.9-89.8"/>
              <path class="st2" d="M-272.4-90.5C-161.4-33.7-49.2,22.3,64.7,73.1c55.9,25,111.8,47.3,152.7,94.7
                c51.4,59.5,87.9,131.5,125.4,200.1c37.3,68.3,72.2,139.5,119.6,201.5c42.6,55.7,97.8,96.2,170.7,75.1
                c68.9-19.9,129.3-66.4,182.4-112.7c49.9-43.5,108.8-107.9,181.4-90.2"/>
              <path class="st2" d="M-273.2-83.9C-162.6-27.5-49.7,25.8,64.3,75.1c56.3,24.4,112,46.8,153,94.3
                c51.5,59.6,88,131.9,125.5,200.6c37.3,68.4,72.1,139.8,119.3,202.1c42.3,55.7,97.6,97.3,170.6,77.3
                c68.9-18.9,129.5-64.6,182.4-110.7C865,495.3,923.4,430.6,996,448.3"/>
              <path class="st2" d="M-274.1-77.3C-163.7-21.3-50.1,29.2,64,76.9c56.7,23.7,112.2,46.2,153.2,93.8
                C268.7,230.6,305.3,303,342.7,372c37.2,68.6,72,140.1,119.1,202.7c42,55.8,97.3,98.5,170.6,79.5
                c68.8-17.8,129.7-62.7,182.4-108.6c49.8-43.4,107.8-108.4,180.4-90.7"/>
              <path class="st2" d="M-274.9-70.7C-164.9-15.2-50.5,32.5,63.7,78.6c57.1,23.1,112.4,45.6,153.5,93.4
                c51.6,60,88.2,132.6,125.6,201.8c37.2,68.8,72,140.4,118.9,203.4c41.7,55.9,97,99.7,170.5,81.8
                c68.8-16.8,129.9-60.7,182.3-106.5c49.7-43.4,107.3-108.6,179.9-90.9"/>
              <path class="st2" d="M-275.7-64C-166-9.1-50.9,35.7,63.4,80.1C120.9,102.5,176,125,217.1,173c51.7,60.2,88.3,133,125.7,202.3
                c37.2,69,71.9,140.8,118.7,204c41.4,56,96.7,100.9,170.4,84.1c68.8-15.7,130-58.7,182.3-104.2c49.6-43.3,106.8-108.8,179.3-91"
                />
              <path class="st2" d="M-276.5-57.4C-167.1-3.1-51.3,38.8,63.1,81.5c58,21.7,112.8,44.2,154,92.3
                c51.7,60.3,88.4,133.3,125.8,202.9c37.2,69.2,71.8,141.1,118.4,204.7c41.1,56.1,96.5,102.1,170.3,86.4
                c68.8-14.6,130.2-56.6,182.2-102c49.6-43.2,106.3-108.9,178.8-91"/>
              <path class="st2" d="M-277.4-50.8C-168.1,3-51.7,41.8,62.8,82.8c58.4,20.9,113,43.5,154.3,91.7
                C268.9,235,305.6,308.1,343,377.9c37.2,69.4,71.8,141.4,118.2,205.3c40.9,56.2,96.2,103.2,170.3,88.8
                c68.7-13.4,130.3-54.5,182.1-99.6c49.5-43.1,105.8-109,178.2-91"/>
              <path class="st2" d="M-278.2-44.2C-169.1,9-52.1,44.7,62.6,83.9c58.8,20.1,113.2,42.7,154.6,91.1c51.8,60.7,88.6,134,126,204
                c37.2,69.5,71.7,141.8,118,205.9c40.6,56.3,96,104.4,170.2,91.2c68.7-12.2,130.3-52.3,182-97.2c49.4-43,105.3-109,177.7-90.8"
                />
              <path class="st2" d="M-279-37.6C-170,15-52.4,47.5,62.3,84.9c59.3,19.3,113.5,41.9,154.8,90.4
                c51.9,60.9,88.7,134.4,126.1,204.5c37.2,69.7,71.6,142.1,117.8,206.5c40.4,56.4,95.8,105.6,170.1,93.6
                c68.6-11.1,130.4-50,181.9-94.7c49.4-42.8,104.8-109,177.1-90.6"/>
              <path class="st2" d="M-279.9-31c109,52,227,81.2,342,116.8c59.7,18.5,113.7,41.1,155.1,89.7c52,61,88.8,134.7,126.2,205.1
                C380.6,450.5,415,523,461,587.8c40.2,56.5,95.5,106.7,170,96c68.5-9.8,130.4-47.7,181.9-92.2c49.3-42.7,104.3-108.9,176.5-90.4
                "/>
              <path class="st2" d="M-280.7-24.3c109,51.3,227.5,77.2,342.6,111c60.2,17.7,113.9,40.2,155.4,89c52,61.2,88.9,135,126.3,205.6
                C380.8,451.3,415.1,524,461,589c40,56.7,95.3,107.9,169.9,98.5c68.4-8.6,130.5-45.3,181.7-89.6c49.3-42.5,103.8-108.8,176-90"
                />
              <path class="st2" d="M-281.5-17.7c109,50.7,228,73.2,343.2,105.1c60.6,16.8,114.1,39.3,155.7,88.3
                c52.1,61.3,89,135.4,126.4,206.1c37.2,70.2,71.5,143,117.1,208.3c39.8,56.8,95.1,109,169.8,101c68.3-7.3,130.5-42.9,181.6-86.9
                c49.3-42.3,103.3-108.6,175.4-89.6"/>
              <path class="st2" d="M-282.3-11.1C-173.3,38.9-53.9,58,61.5,88c61,15.9,114.3,38.4,156,87.5C269.7,237,306.6,311.2,344,382.1
                c37.1,70.4,71.4,143.3,116.9,208.8c39.6,57,95,110.1,169.7,103.5c68.2-6.1,130.4-40.4,181.5-84.2
                C861.3,568,915,501.8,986.9,521.1"/>
              <path class="st2" d="M-283.2-4.5c109.2,49.4,229,65,344.5,93.1c61.5,15,114.5,37.4,156.3,86.7c52.2,61.6,89.2,136,126.6,207
                c37.1,70.5,71.3,143.6,116.7,209.3c39.4,57.1,94.8,111.2,169.6,106c68-4.8,130.4-37.8,181.4-81.4
                c49.2-42,102.4-108.1,174.1-88.6"/>
              <path class="st2" d="M-284,2.1c109.4,48.7,229.4,60.7,345.2,87c61.9,14,114.7,36.4,156.6,85.9
                c52.3,61.7,89.4,136.2,126.8,207.4c37,70.6,71.2,143.8,116.4,209.8c39.2,57.3,94.7,112.3,169.5,108.6
                c67.9-3.4,130.3-35.2,181.2-78.5c49.2-41.8,101.9-107.8,173.5-87.9"/>
              <path class="st2" d="M-284.8,8.7C-175.2,56.8-54.9,65.2,61,89.5c62.3,13.1,114.9,35.4,156.9,85
                c52.4,61.8,89.5,136.5,126.9,207.8c37.1,70.8,71.2,144.1,116.3,210.3c39.1,57.5,94.6,113.4,169.4,111.1
                c67.7-2.1,130.1-32.5,181.1-75.6c49.2-41.5,101.4-107.4,172.9-87.3"/>
              <path class="st2" d="M-285.7,15.4C-174.8,63.2-53.3,67.3,63.8,90.5c57.8,11.5,108.6,32,148.4,76.7
                c55.8,62.7,94.3,141.1,132.9,214.9c38.5,73.5,73.6,150.2,121.3,218.4C503.8,654,557.5,706.2,627,706.6
                c68.1,0.4,131.5-28.4,183-71.4c49.6-41.4,101.8-108.3,173.7-87.7"/>
              <path class="st2" d="M-286.5,22C-175.3,69.2-53.7,69.5,63.6,90.8c58.1,10.6,108.8,31,148.7,75.8
                c55.8,62.7,94.4,141.3,133,215.3c38.5,73.7,73.6,150.4,121.1,218.8c37.3,53.8,91,106.8,160.5,108.6
                c67.9,1.7,131.3-25.5,182.8-68.3c49.6-41.1,101.3-107.9,173-86.8"/>
              <path class="st2" d="M-287.3,28.6C-175.7,75.1-54,71.6,63.5,91c58.5,9.6,109,30,149,74.9c55.9,62.8,94.5,141.5,133.2,215.6
                c38.5,73.8,73.5,150.5,120.9,219.2c37.3,54,90.9,107.9,160.4,111.1c67.6,3.1,131.1-22.6,182.6-65.1
                c49.6-40.8,100.8-107.4,172.4-85.9"/>
              <path class="st2" d="M-288.1,35.2c112,45.9,233.8,38.6,351.5,56c58.8,8.7,109.1,29,149.3,74C268.7,228,307.4,306.9,346,381
                c38.5,73.9,73.5,150.7,120.7,219.5c37.2,54.3,90.9,108.9,160.3,113.6c67.4,4.5,130.8-19.6,182.4-61.8
                c49.6-40.6,100.3-106.8,171.7-84.9"/>
              <path class="st2" d="M-289,41.8C-176.5,87-54.7,75.9,63.2,91.4c59.1,7.8,109.3,27.9,149.6,73c56.1,62.9,94.8,141.8,133.5,216
                c38.5,73.9,73.4,150.8,120.5,219.8c37.2,54.6,90.8,110,160.2,116.2c67.1,6,130.5-16.6,182.3-58.5
                c49.6-40.3,99.9-106.2,171-83.9"/>
              <path class="st2" d="M-289.8,48.4C-176.8,93-55.1,78,63.1,91.6c59.4,6.8,109.5,26.9,149.9,72c56.2,62.9,95,141.9,133.6,216.2
                c38.5,74,73.4,150.9,120.3,220.1c37.1,54.8,90.8,111,160.1,118.6c66.8,7.4,130.2-13.5,182.1-55.2
                c49.7-39.9,99.4-105.5,170.3-82.7"/>
              <path class="st2" d="M-290.6,55C-177.1,98.9-55.4,80.1,63,91.7c59.7,5.9,109.7,25.8,150.2,71.1c56.3,62.9,95.1,142,133.8,216.3
                c38.5,74,73.4,151,120.1,220.4c37.1,55.1,90.8,112,160,121.2c66.4,8.8,129.9-10.3,181.9-51.8c49.7-39.6,98.9-104.9,169.6-81.6"
                />
              <path class="st2" d="M-291.5,61.7C-177.4,105-55.8,82.2,62.8,92c60,4.9,109.9,24.7,150.5,70.1
                c56.3,62.9,95.2,142.1,133.9,216.5c38.5,74.1,73.3,151.1,119.9,220.7c37.1,55.4,90.8,113,159.9,123.7
                c66.1,10.2,129.5-7.3,181.7-48.5c49.8-39.3,98.4-104.2,168.9-80.5"/>
              <path class="st2" d="M-292.3,68.3c114.7,42.6,236.1,16,355,23.9c60.2,4,110.1,23.7,150.8,69.2c56.4,63,95.4,142.2,134.1,216.8
                c38.5,74.2,73.3,151.3,119.7,221c37.1,55.8,90.8,114.1,159.8,126.2c65.7,11.5,129.2-4.2,181.5-45.2
                c49.8-39,97.9-103.7,168.3-79.5"/>
              <path class="st2" d="M-293.1,74.9c115.3,42,236.6,11.5,355.7,17.5c60.5,3,110.3,22.7,151.1,68.2c56.5,63,95.5,142.4,134.2,217
                c38.5,74.3,73.2,151.5,119.5,221.4c37.2,56.1,90.8,115.1,159.7,128.7c65.3,12.9,128.8-1.2,181.3-42.1
                c49.8-38.8,97.4-103.1,167.6-78.6"/>
              <path class="st2" d="M-293.9,81.5c116,41.4,237.1,7.1,356.4,11.1c60.8,2.1,110.4,21.7,151.4,67.4
                c56.6,63.1,95.6,142.6,134.3,217.4c38.5,74.4,73.2,151.7,119.3,221.8c37.2,56.6,90.7,116.2,159.6,131.2
                c65,14.2,128.5,1.7,181.2-38.9c49.9-38.5,96.9-102.6,167-77.8"/>
              <path class="st2" d="M-294.8,88.1C-178.1,129-57.2,90.8,62.3,93c61.1,1.1,110.6,20.7,151.7,66.5
                c56.7,63.2,95.8,142.9,134.5,217.7c38.5,74.5,73.2,151.9,119.1,222.3c37.2,57,90.7,117.2,159.5,133.6
                c64.6,15.4,128.1,4.7,181-35.9c49.9-38.2,96.4-102.2,166.3-77"/>
              <path class="st2" d="M-295.6,94.7c117.3,40.3,238-1.7,357.8-1.4c61.3,0.2,110.8,19.7,152,65.7
                c56.7,63.3,95.9,143.1,134.6,218.1c38.5,74.7,73.1,152.2,118.9,222.8C505,657.4,558.3,718.2,627.1,736
                c64.2,16.6,127.8,7.5,180.9-32.9c49.9-38,95.8-101.8,165.7-76.3"/>
              <path class="st2" d="M-296.4,101.3c116.9,39.4,236.6-5,355.4-7.5c66.9-1.4,117.5,20.3,161.3,71.7
                c53.4,62.8,91.2,138.9,128.7,211.7c37.1,72.1,70.6,146.5,113.6,215.3c38.6,61.8,94.5,128,167.8,147.4
                c63.2,16.7,126.2,8.3,178.7-31.9c49.5-37.8,94.5-100.3,163.7-74.6"/>
              <path class="st2" d="M-297.3,108c117.6,38.9,237.1-9.3,356.1-13.5c67.2-2.4,117.7,19.3,161.6,70.9
                c53.5,62.9,91.3,139.2,128.8,212.1c37.1,72.2,70.5,146.8,113.4,215.8c38.7,62.3,94.4,129.1,167.6,150
                c62.8,17.9,125.8,11,178.6-29.1c49.5-37.6,94-100,163.1-74"/>
              <path class="st2" d="M-298.1,114.6C-179.8,153-60.5,101.1,58.6,95.1c67.5-3.4,117.9,18.3,161.9,70.1
                c53.5,63,91.4,139.5,128.9,212.6c37.1,72.4,70.5,147.1,113.2,216.4C501.3,657,557,724.4,630.1,746.7
                c62.4,19,125.5,13.7,178.5-26.4c49.5-37.4,93.5-99.7,162.5-73.6"/>
              <path class="st2" d="M-298.9,121.2c119,37.9,238.1-17.7,357.4-25.4c27.2-1.8,55.1-0.3,81.2,7.9c32.9,10.3,59.1,35.8,81,61.5
                c53.6,63.2,91.6,139.8,129,213.1c37,72.5,70.4,147.4,113,216.9c38.8,63.4,94.3,131.3,167.4,155c62,20.1,125.2,16.3,178.4-23.7
                c49.5-37.3,92.9-99.5,161.9-73.1"/>
              <path class="st2" d="M-299.8,127.8C-180,165.3-61.2,106,58.2,96.6c27.2-2.1,55.2-1,81.4,7c33,10.2,59.3,35.9,81.1,61.6
                c53.7,63.3,91.7,140.1,129.1,213.6c37,72.7,70.4,147.7,112.8,217.5c38.8,64,94.3,132.3,167.3,157.4
                c61.6,21.2,124.9,18.9,178.3-21.1c49.5-37.1,92.4-99.4,161.3-72.8"/>
              <path class="st2" d="M-300.6,134.4c120.4,37,239.1-25.8,358.6-36.9c27.2-2.5,55.3-1.8,81.6,6.2c33,10,59.4,36,81.1,61.8
                C274.5,229,312.5,306,349.9,379.7c37,72.9,70.3,148,112.5,218.1c38.9,64.5,94.2,133.4,167.2,159.9
                c61.2,22.2,124.6,21.5,178.2-18.5c49.5-37,91.9-99.3,160.7-72.5"/>
              <path class="st2" d="M-301.4,141c121.2,36.7,239.6-29.8,359.2-42.5c27.2-2.9,55.4-2.5,81.8,5.5c33.1,9.9,59.5,36.2,81.2,61.9
                c53.8,63.7,91.9,140.8,129.3,214.7c37,73,70.2,148.4,112.3,218.7c38.9,65.1,94.2,134.5,167.2,162.3
                c60.7,23.2,124.3,24,178.1-16.1c49.5-36.8,91.3-99.2,160.1-72.3"/>
              <path class="st2" d="M-302.2,147.7c121.8,36.3,240-33.7,359.7-48c27.2-3.2,55.5-3.1,82,4.7c33.1,9.8,59.6,36.3,81.3,62
                c53.9,63.8,92,141.2,129.4,215.2c37,73.2,70.2,148.7,112.1,219.4c39,65.7,94.1,135.5,167.1,164.7c60.3,24.1,124,26.4,178-13.7
                c49.5-36.7,90.8-99.2,159.6-72.2"/>
              <path class="st2" d="M-303.1,154.3c122.5,36,240.6-37.5,360.3-53.3c27.2-3.6,55.6-3.8,82.1,4c33.2,9.7,59.7,36.5,81.4,62.2
                c53.9,64,92.1,141.5,129.5,215.8c37,73.4,70.1,149.1,111.9,220c39.1,66.3,94.1,136.6,167,167c59.9,25,123.7,28.8,177.9-11.3
                c49.5-36.6,90.2-99.3,159-72.2"/>
              <path class="st2" d="M-303.9,160.9c123.2,35.7,241.1-41.3,360.9-58.5c27.2-3.9,55.7-4.4,82.3,3.3c33.2,9.6,59.9,36.6,81.5,62.3
                c54,64.2,92.2,141.9,129.6,216.3c37,73.6,70,149.5,111.6,220.6c39.1,66.9,94,137.6,166.9,169.4c59.5,25.9,123.5,31.2,177.9-9
                c49.5-36.5,89.6-99.4,158.5-72.2"/>
              <path class="st2" d="M-304.7,167.5c123.9,35.5,241.6-44.9,361.4-63.6c27.2-4.2,55.8-5.1,82.5,2.6c33.2,9.5,60,36.8,81.6,62.5
                c54,64.3,92.3,142.2,129.7,216.9c37,73.8,70,149.8,111.4,221.3c39.2,67.6,94,138.6,166.8,171.7c59,26.8,123.2,33.5,177.8-6.8
                c49.4-36.5,89.1-99.6,157.9-72.3"/>
              <path class="st2" d="M-305.6,174.1c124.5,35.3,242.1-48.5,361.9-68.6c27.2-4.6,55.9-5.7,82.7,1.9c33.3,9.4,60.1,36.9,81.6,62.6
                c54.1,64.5,92.4,142.6,129.8,217.5c36.9,74,69.9,150.2,111.2,221.9c39.3,68.2,93.9,139.7,166.7,173.9
                c58.6,27.6,123,35.8,177.8-4.7c49.4-36.4,88.5-99.8,157.4-72.5"/>
              <path class="st2" d="M-306.4,180.7c125.1,35.1,242.6-51.9,362.4-73.4c27.2-4.9,56-6.2,82.9,1.3c33.3,9.4,60.2,37.1,81.7,62.8
                c54.2,64.7,92.5,143,129.9,218c36.9,74.2,69.8,150.5,111,222.5c39.3,68.8,93.9,140.7,166.7,176.2c58.2,28.4,122.7,38,177.7-2.6
                c49.4-36.4,87.9-100,156.9-72.7"/>
              <path class="st2" d="M-307.2,187.4c125.7,35,243.1-55.3,362.9-78c27.2-5.2,56.1-6.8,83,0.7c33.4,9.3,60.3,37.2,81.8,62.9
                c54.2,64.9,92.6,143.3,130,218.6c36.9,74.3,69.8,150.9,110.7,223.2c39.4,69.5,93.8,141.7,166.6,178.4
                c57.8,29.1,122.5,40.1,177.7-0.6c49.3-36.4,87.3-100.3,156.4-73"/>
              <path class="st2" d="M-308,194c126.3,35,243.5-58.6,363.3-82.5c27.2-5.4,56.2-7.3,83.2,0.2c33.4,9.3,60.4,37.3,81.9,63.1
                c54.3,65,92.7,143.7,130.1,219.1c69,139.3,129.4,327.4,277.1,404.3c57.4,29.9,122.3,42.3,177.7,1.4
                c49.2-36.4,86.7-100.7,155.9-73.4"/>
              <path class="st2" d="M-308.9,200.6c126.8,35,244-61.7,363.8-86.8c27.2-5.7,56.3-7.8,83.4-0.3c33.4,9.2,60.5,37.5,82,63.3
                c54.3,65.2,92.8,144,130.2,219.6c69.3,140.4,129.3,327.9,276.8,407c57,30.6,122.2,44.3,177.7,3.3
                c49.2-36.4,86.2-101.1,155.4-73.9"/>
              <path class="st2" d="M-309.7,207.2c127.3,35.1,244.5-64.7,364.2-90.9c27.2-6,56.3-8.3,83.5-0.8c33.5,9.2,60.5,37.6,82,63.4
                c54.5,65.3,93,144.3,130.3,220.1C420,540.6,479.6,727.4,626.9,808.7c56.6,31.2,122,46.3,177.7,5.1
                c49.1-36.4,85.6-101.6,154.9-74.4"/>
              <path class="st2" d="M-310.5,213.8c127.8,35.2,245-67.6,364.6-94.9c27.2-6.2,56.4-8.8,83.7-1.3c33.5,9.2,60.6,37.7,82.1,63.5
                c54.5,65.5,93.1,144.7,130.4,220.6c70,142.6,129.2,328.8,276.3,412.3c56.2,31.9,121.8,48.3,177.7,6.8
                c49-36.4,85-102.1,154.5-75"/>
              <path class="st2" d="M-311.4,220.5c128.2,35.4,245.4-70.4,364.9-98.6c27.2-6.4,56.5-9.2,83.8-1.7c33.6,9.2,60.7,37.8,82.2,63.7
                C274,249.5,312.7,328.9,350,405c70.4,143.7,129.2,329.3,276,414.9c55.8,32.5,121.7,50.2,177.7,8.5c49-36.5,84.4-102.6,154-75.7
                "/>
              <path class="st2" d="M-312.2,227.1c128.6,35.7,245.9-73,365.3-102.2c27.2-6.6,56.6-9.6,83.9-2c33.6,9.3,60.8,37.9,82.3,63.8
                c54.6,65.8,93.3,145.2,130.6,221.6C420.7,553,479.1,738.1,625.7,825.6c55.4,33.1,121.6,52.1,177.7,10.1
                c48.9-36.6,83.8-103.3,153.6-76.5"/>
              <path class="st2" d="M-313,233.7c128.9,36,246.3-75.5,365.6-105.5c27.2-6.8,56.6-9.9,84.1-2.3c33.6,9.3,60.8,38,82.4,63.9
                c54.7,65.9,93.4,145.5,130.7,222C420.8,557.5,479,742,625.3,831.6c55,33.7,121.5,53.9,177.7,11.7
                c48.8-36.7,83.1-103.9,153.1-77.3"/>
              <path class="st2" d="M-313.9,240.3C-184.6,276.8-67.1,162.4,52,131.7c27.2-7,56.7-10.2,84.2-2.6c33.7,9.4,60.9,38,82.5,64.1
                c54.7,66,93.5,145.8,130.8,222.4c71.4,146.7,129.2,330.6,275.3,422.1c54.6,34.2,121.4,55.7,177.8,13.1
                c48.7-36.8,82.5-104.7,152.7-78.3"/>
              <path class="st2" d="M-314.7,247c129.5,36.9,247.2-80.1,366.1-111.5c27.2-7.2,56.8-10.5,84.3-2.8c33.7,9.4,60.9,38.1,82.5,64.2
                c54.8,66.1,93.7,146,130.9,222.8C421,567.2,478.6,750.6,624.4,843.8c54.3,34.7,121.4,57.4,177.8,14.6
                c48.6-36.9,81.9-105.4,152.3-79.3"/>
              <path class="st2" d="M-315.5,253.6c129.7,37.5,247.6-82.1,366.4-114.2c27.3-7.4,56.9-10.8,84.5-3c33.7,9.5,61,38.1,82.6,64.2
                c54.9,66.2,93.8,146.2,131.1,223.1C421.2,572.2,478.4,755.1,624,850.2c53.9,35.2,121.3,59.1,177.9,15.9
                c48.4-37,81.3-106.3,151.9-80.3"/>
              <path class="st2" d="M-316.3,260.2c129.9,38.2,248-84,366.6-116.6c27.3-7.5,56.9-11,84.6-3.1c33.8,9.6,61,38.1,82.7,64.3
                c55,66.3,93.9,146.4,131.2,223.4C421.2,577.6,478.2,760,623.5,856.7c53.6,35.7,121.3,60.7,177.9,17.2
                c48.3-37.2,80.7-107.2,151.5-81.5"/>
              <path class="st2" d="M-317.2,266.8c65.9,19.7,129.7-6.6,188.4-35.8c58.3-29,115.1-65.4,178.3-83c27.3-7.6,57-11.2,84.7-3.2
                c33.8,9.8,61,38.1,82.8,64.4c55,66.3,94,146.5,131.3,223.6C421,583.1,477.8,764.9,622.8,863.3c53.3,36.2,121.3,62.3,178,18.4
                c48.2-37.4,80-108.1,151.1-82.7"/>
              <path class="st2" d="M-318,273.4c66.5,20.3,130.9-7.2,190-37.1c58.7-29.8,116-67.3,180.1-84.5c27.5-7.4,57.6-10.5,85.1-1.2
                c29.2,9.9,53.3,33.3,73.2,56.1c58.6,67,99.2,151.4,137.6,231C420.6,588,476.5,767,619.1,867.9c53,37.5,122.3,67,180.1,22.7
                c48.7-37.3,80.1-110.2,151.9-85"/>
              <path class="st2" d="M-318.8,280.1c66.6,20.8,131.1-7.2,190-37.5c58.7-30.2,116-68.3,180.3-85.8c65-17.7,115.3,5.6,158.5,54.9
                c58.7,67.1,99.3,151.5,137.8,231.2C420.7,594,476.4,772.4,618.7,874.8c52.7,37.9,122.3,68.5,180.2,23.8
                c48.6-37.5,79.4-111.2,151.6-86.4"/>
              <path class="st2" d="M-319.7,286.7c66.6,21.3,131.2-7.2,189.9-37.8s116-69.2,180.4-86.9c65-17.9,115.5,5.8,158.6,55
                c58.8,67.1,99.5,151.6,138,231.3C420.4,600.1,476,778,618,881.8c52.4,38.3,122.4,70,180.3,24.9
                c48.5-37.8,78.8-112.3,151.2-87.8"/>
              <path class="st2" d="M-320.5,293.3c66.6,21.9,131.2-7,189.9-37.9s115.9-70.1,180.5-87.9c64.9-18,115.7,6.1,158.8,55.1
                c58.8,67,99.7,151.6,138.1,231.4c73.5,152.5,128.9,329.8,270.7,435c52.1,38.7,122.5,71.4,180.4,25.8
                c48.3-38,78.1-113.5,150.9-89.4"/>
              <path class="st2" d="M-321.3,299.9c66.5,22.5,131.3-6.8,189.8-37.9c58.7-31.2,115.9-70.8,180.5-88.8
                c64.8-18,115.9,6.4,159,55.3c58.9,67,99.8,151.6,138.3,231.4C420.1,613,475.4,789.8,616.8,896.4c51.8,39,122.6,72.8,180.5,26.7
                c48.2-38.3,77.5-114.7,150.5-91"/>
              <path class="st2" d="M-322.2,306.6c66.5,23.2,131.3-6.4,189.7-37.8c58.6-31.5,115.8-71.5,180.6-89.5
                c64.7-18,116.2,6.9,159.1,55.5c59,66.9,100,151.5,138.5,231.3C419.9,619.9,475.1,796,616.2,903.9
                c51.5,39.4,122.7,74.1,180.6,27.5c48-38.6,76.8-115.9,150.2-92.7"/>
              <path class="st2" d="M-323,313.2c66.5,23.5,131.4-5.9,190-37.3s115.7-71.3,180.5-89.1c64.4-17.7,116,7.3,158.8,55.6
                c59,66.6,99.8,150.9,138.3,230.4c74,152.9,129.7,327.7,270.2,435c51.4,39.3,122.5,74.2,180.7,28.5
                c48.5-38.1,77.5-114.1,150.6-90.9"/>
              <path class="st2" d="M-323.8,319.8c66.6,23.7,131.6-5.6,190.4-36.9c58.5-31.3,115.7-71.1,180.3-88.7
                c64.1-17.4,115.8,7.7,158.5,55.7c58.9,66.2,99.7,150.2,138.1,229.4c73.8,152,130.1,325.5,269.8,432
                c51.3,39.1,122.3,74.1,180.8,29.4c49.1-37.5,78.3-112,151.2-88.8"/>
              <path class="st2" d="M-324.6,326.5c66.7,23.9,131.9-5.3,190.7-36.7c58.5-31.2,115.6-71,180.2-88.4
                c63.8-17.1,115.6,8.1,158.1,55.8c58.9,65.9,99.5,149.7,137.9,228.5c73.6,151.2,130.4,323.4,269.5,429.2
                c51.2,39,122.1,74.1,180.9,30.4c49.6-36.9,79.1-109.9,151.7-86.7"/>
              <path class="st2" d="M-325.5,333.1c66.8,24,132.1-5.1,191.1-36.6c58.4-31.1,115.5-71,180.2-88.2
                c63.6-16.9,115.4,8.4,157.8,55.8c58.8,65.7,99.3,149.1,137.7,227.8C414.6,642.3,472,813.3,610.4,918.5
                c51.1,38.9,121.9,74.1,181,31.4c50.1-36.2,79.9-107.8,152.3-84.6"/>
              <path class="st2" d="M-326.3,339.7c67,24.2,132.3-5,191.5-36.5c58.4-31.1,115.4-71,180.1-88.1c63.4-16.7,115.1,8.7,157.5,55.9
                c58.7,65.4,99.1,148.7,137.4,227.1c73.1,149.6,131,319.5,268.7,424c51,38.7,121.7,74.1,181.1,32.3
                c50.6-35.6,80.7-105.8,152.8-82.6"/>
              <path class="st2" d="M-327.1,346.3c67.1,24.2,132.6-5,191.9-36.6c58.3-31.1,115.4-71.1,180-88c63.2-16.5,114.9,9,157.1,55.9
                c58.7,65.2,98.9,148.2,137.2,226.5C411.9,653,470.3,821.7,607.5,925.6c51,38.6,121.5,74.1,181.2,33.3
                c51.1-34.9,81.5-103.7,153.4-80.5"/>
              <path class="st2" d="M-327.9,352.9c67.3,24.3,132.9-5,192.4-36.7c58.3-31.1,115.3-71.2,179.9-88.1c63-16.4,114.7,9.2,156.8,56
                c58.6,65,98.7,147.9,136.9,225.9c72.6,148.2,131.3,315.9,268,419.2c50.9,38.5,121.3,74,181.3,34.3
                c51.6-34.3,82.3-101.6,153.9-78.4"/>
              <path class="st2" d="M-328.8,359.6c67.5,24.3,133.2-5.1,192.8-36.9c58.3-31.1,115.2-71.4,179.9-88.1
                c62.9-16.3,114.5,9.4,156.5,56C258.9,355.5,298.9,438.1,337,516c72.3,147.6,131.4,314.2,267.5,416.9
                c50.8,38.4,121.2,74,181.4,35.2c52.1-33.6,83.1-99.5,154.4-76.3"/>
              <path class="st2" d="M-329.6,366.2c67.6,24.4,133.5-5.2,193.2-37.1c58.2-31.2,115.2-71.6,179.8-88.3
                c62.7-16.2,114.2,9.6,156.1,56.1C258,361.6,297.9,444,336,521.7c72,147,131.5,312.5,267.1,414.6c50.8,38.2,121,74,181.5,36.2
                c52.6-32.9,83.9-97.4,155-74.2"/>
              <path class="st2" d="M-330.4,372.8c67.8,24.4,133.8-5.3,193.6-37.4c58.2-31.2,115.1-71.8,179.8-88.5
                c62.6-16.1,114,9.8,155.8,56.1c58.3,64.6,98.1,146.9,136.1,224.5C406.6,673.9,466.5,838.4,601.6,940
                c50.7,38.1,120.8,73.9,181.6,37.1c53.1-32.1,84.7-95.3,155.5-72.1"/>
              <path class="st2" d="M-331.3,379.4C-197.4,427.3-79.1,284.2,42.6,253c62.5-16,113.8,9.9,155.5,56.1
                c58.2,64.5,97.9,146.7,135.8,224.1C405.3,679,465.5,842.5,600.2,943.5c50.7,38,120.7,73.9,181.6,38.1
                c53.6-31.4,85.5-93.2,156.1-70"/>
              <path class="st2" d="M-332.1,386.1C-197.8,433.9-79.8,290.3,42.2,259c62.3-16,113.5,10.1,155.2,56.1
                c58.2,64.4,97.7,146.4,135.5,223.7C404,684.1,464.5,846.7,598.7,947.1c50.7,37.9,120.5,73.8,181.7,39.1
                c54-30.7,86.3-91.1,156.6-68"/>
              <path class="st2" d="M-332.9,392.7C-198.2,440.5-80.5,296.3,41.8,265c62.2-15.9,113.3,10.2,154.8,56.2
                c58.1,64.3,97.4,146.2,135.3,223.4c70.9,144.7,131.7,306.3,265.4,406.2c50.7,37.8,120.3,73.8,181.8,40
                c54.5-29.9,87.1-89.1,157.2-65.9"/>
              <path class="st2" d="M-333.7,399.3c135.2,47.8,252.5-97,375.1-128.4c62.1-15.9,113.1,10.3,154.5,56.2
                c58,64.2,97.2,145.9,135,223c70.6,144.1,131.7,304.8,265,404.1c50.7,37.8,120.2,73.7,181.9,41c54.9-29.1,88-87,157.7-63.8"/>
              <path class="st2" d="M-334.6,405.9C-199,453.7-82,308.3,40.9,276.9c62-15.8,112.8,10.5,154.2,56.2
                c57.9,64.1,97,145.7,134.7,222.6c70.3,143.6,131.7,303.2,264.5,402c50.6,37.7,120,73.7,182,41.9
                c55.3-28.3,88.8-84.9,158.2-61.7"/>
              <path class="st2" d="M-335.4,412.5c136,47.7,252.7-98.3,375.9-129.6c61.9-15.8,112.6,10.6,153.8,56.3
                c57.8,64,96.8,145.5,134.4,222.3c70,143.1,131.7,301.7,264.1,400c50.6,37.6,119.9,73.6,182.1,42.9
                c55.8-27.5,89.6-82.8,158.8-59.6"/>
              <path class="st2" d="M-336.2,419.2c136.7,47.2,253-99.8,376.5-131.7c61.9-16,112.4,10.5,153.5,56.2
                c57.7,64.1,96.5,145.6,134,222.5C397.5,709.1,459.3,867.1,591.4,965c50.7,37.5,119.7,73.5,182.2,43.9
                c56.2-26.7,90.4-80.7,159.3-57.5"/>
              <path class="st2" d="M-337,425.8c137.3,46.7,253.2-101.3,377-133.5c61.9-16.1,112.1,10.4,153.2,56.2
                c57.6,64.2,96.3,145.7,133.7,222.6c69.3,142.5,131.2,300,263,397.4c50.7,37.4,119.6,73.5,182.3,44.8
                c56.5-25.8,91.3-78.6,159.9-55.4"/>
              <path class="st2" d="M-337.9,432.4C-200,478.8-84.5,329.9,39.7,297.3c61.9-16.2,111.8,10.3,152.9,56.2
                c57.5,64.3,96,145.7,133.4,222.7c69,142.1,131,298.9,262.5,395.9c50.7,37.4,119.5,73.4,182.4,45.8
                c56.9-25,92.1-76.5,160.4-53.4"/>
              <path class="st2" d="M-338.7,439c138.5,46.1,253.6-103.6,378.1-136.4c61.9-16.3,111.6,10.4,152.5,56.2
                c57.4,64.3,95.8,145.7,133,222.6c68.7,141.7,130.8,297.7,262,394.2c50.7,37.3,119.3,73.3,182.5,46.7
                c57.3-24.1,92.9-74.3,161-51.3"/>
              <path class="st2" d="M-339.5,445.7c139,45.9,253.8-104.4,378.6-137.4c61.8-16.3,111.4,10.4,152.2,56.3
                C248.6,428.9,286.8,510.2,324,587c68.4,141.3,130.7,296.4,261.5,392.4c50.8,37.2,119.2,73.3,182.6,47.7
                c24.8-10,45.6-26.8,68.6-39.9c31-17.7,59-20.8,92.9-9.3"/>
              <path class="st2" d="M-340.4,452.3c139.4,45.9,253.9-105,379-138c61.7-16.3,111.1,10.6,151.9,56.3
                c57.2,64.2,95.3,145.3,132.5,222c68.1,140.8,130.7,294.8,261.1,390.3c50.8,37.2,119.1,73.2,182.7,48.7
                c24.8-9.6,45.8-25.9,69-38.7c31.2-17.2,59.3-20,93.1-8.4"/>
              <path class="st2" d="M-341.2,458.9c139.8,46,253.9-105.3,379.3-138.2C99.6,304.6,149,331.5,189.6,377
                c57.1,64,95.1,144.9,132.2,221.5c67.9,140.2,130.8,293,260.7,387.9c50.9,37.1,119,73.1,182.7,49.6
                c24.9-9.2,45.9-25.3,69.3-37.4c31.7-16.5,59.4-19.3,93.3-7.6"/>
              <path class="st2" d="M-342,465.5c140,46.3,253.9-105.3,379.6-137.9c61.3-15.9,110.7,11.1,151.2,56.4
                c57,63.7,94.9,144.4,132,220.7c67.7,139.5,130.9,291,260.3,385.3c50.9,37.1,118.9,73,182.8,50.6c24.9-8.8,46.2-24.4,69.6-36.1
                c31.8-15.9,59.7-18.4,93.5-6.8"/>
              <path class="st2" d="M-342.8,472.1C-202.6,518.9-89,367.2,37,335.1c61-15.6,110.5,11.5,150.9,56.5
                c57,63.4,94.8,143.8,131.8,219.8C387.2,750,450.8,900,579.7,993.6c51,37,118.8,73,182.9,51.6c25-8.3,46.4-23.5,69.9-34.9
                c32-15.4,60-17.6,93.7-6"/>
              <path class="st2" d="M-343.7,478.8c140.4,47.4,253.7-104.2,380-135.7c60.7-15.1,110.4,12,150.6,56.5
                c56.9,63,94.6,142.9,131.6,218.5C385.9,756,450,904.2,578.2,997.1c51.1,37,118.7,72.9,183,52.5c25-7.9,46.6-22.7,70.3-33.6
                c32.2-14.9,60.3-16.8,93.9-5.2"/>
              <path class="st2" d="M-344.5,485.4c140.4,48.3,253.4-103,380.1-133.7c60.2-14.6,110.1,12.7,150.2,56.7
                c56.9,62.4,94.5,141.9,131.4,217.1c67.4,137,132,283.2,259.5,375.3c51.1,37,118.6,72.8,183.1,53.5
                c25.1-7.5,46.9-21.8,70.6-32.3c32.4-14.4,60.6-16,94.2-4.3"/>
              <path class="st2" d="M-345.3,492c140.3,49.2,253.1-101.3,380-130.9c59.7-13.9,109.9,13.4,149.9,56.8
                C241.4,479.7,279,558.6,316,633.2c67.4,135.9,132.7,279.9,259.4,371.2c51.2,36.9,118.5,72.7,183.2,54.4
                c25.1-7.1,47.1-20.9,70.9-31.1c32.5-13.8,60.9-15.2,94.4-3.5"/>
              <path class="st2" d="M-346.1,498.6c139.6,48.9,252.4-97.7,379.5-126C92.9,359.3,143,386.3,183,429.4
                c56.7,60.9,94.4,138.7,131.7,212.3C382.6,776,448.6,918,574.6,1008.2c51.4,36.7,118,72.1,182.8,54.8
                c25.1-6.7,47.2-20,71.1-29.7c32.7-13.2,61.1-14.2,94.5-2.5"/>
              <path class="st2" d="M-347,505.3c138.9,48.7,251.6-94,378.8-121c59.4-12.6,109.4,14.1,149.6,56.7
                c56.5,60,94.5,136.7,131.9,209.3C381.9,783,448.6,923,573.9,1011.9c51.4,36.5,117.6,71.5,182.4,55.3
                c25.1-6.3,47.4-19.1,71.3-28.3c32.8-12.6,61.3-13.2,94.6-1.5"/>
              <path class="st2" d="M-347.8,511.9c138.2,48.5,250.9-90.2,378.2-115.7c59.2-11.9,109.1,14.5,149.4,56.7
                c56.4,59,94.5,134.5,132.2,206.1c69.1,131.1,136.6,268.8,261.1,356.6c51.5,36.4,117.2,71,182,55.7c25-5.9,47.5-18.2,71.6-26.9
                c32.9-11.9,61.6-12.2,94.7-0.5"/>
              <path class="st2" d="M-348.6,518.5c137.4,48.4,250.2-86.2,377.5-110.1c144-27.1,227,157.7,281.8,259.6
                c69.7,129.4,138.1,264.8,261.7,351.4c51.6,36.2,116.8,70.4,181.5,56.2c25-5.5,47.7-17.3,71.8-25.5c33-11.3,61.8-11.2,94.8,0.5"
                />
              <path class="st2" d="M-349.5,525.1C-212.9,573.5-100,443,27.4,420.7c143.1-25,226.1,154.6,282,256.2
                C379.7,804.6,449,937.6,571.7,1023c51.7,36,116.4,69.9,181.1,56.7c25-5.1,47.8-16.3,72-24.1c33.1-10.6,62-10.3,94.9,1.6"/>
              <path class="st2" d="M-350.3,531.8c135.8,48.5,248.6-78.1,376.1-98.4C168.1,410.6,250.8,584.6,308,686
                c71,125.9,141.1,256.4,262.9,340.7c51.7,35.8,116,69.4,180.7,57.2c25-4.7,48-15.4,72.2-22.7c33.2-10,62.3-9.3,94.9,2.6"/>
              <path class="st2" d="M-351.1,538.4c134.9,48.6,247.9-73.7,375.3-92.3c141.4-20.6,224.1,148.2,282.4,249.2
                c71.6,124,142.7,252.1,263.6,335.1c52,35.7,115.5,68.6,180.3,57.7c25-4.2,48.2-14.5,72.4-21.2c33.3-9.3,62.5-8.3,95,3.7"/>
              <path class="st2" d="M-352,545c134,48.7,247.2-69.3,374.6-86C163,440.6,245.7,604,305.3,704.5
                c72.3,122.1,144.3,247.6,264.2,329.4c52,35.6,115.1,68.1,179.9,58.3c25-3.8,48.3-13.5,72.6-19.8c33.4-8.6,62.7-7.2,95.1,4.7"/>
              <path class="st2" d="M-352.8,551.6c133,48.9,246.5-64.7,373.8-79.5c139.4-16.2,222.1,141.8,282.9,241.8
                c73,120.1,146,243,264.9,323.7c52.1,35.4,114.7,67.6,179.5,58.8c25-3.4,48.5-12.6,72.9-18.4c33.5-8,62.9-6.2,95.2,5.8"/>
              <path class="st2" d="M-353.6,558.2c132.2,49.2,245.6-60.5,373-72.9c138.5-13.5,220.9,138.3,283.1,238.1
                c73.7,118.1,147.7,238.3,265.6,317.8c52.2,35.2,114.3,67.1,179.1,59.3c25-3,48.6-11.6,73.1-17c33.6-7.3,63.1-5.2,95.3,6.8"/>
              <path class="st2" d="M-354.5,564.9c131.1,49.5,245-55.7,372.1-66.2C155.1,487.4,237.5,633.9,301,732.9
                c74.5,116.1,149.4,233.6,266.3,311.9c52.3,35,114,66.6,178.7,59.9c25-2.6,48.8-10.7,73.3-15.5c33.6-6.6,63.3-4.2,95.4,7.9"/>
              <path class="st2" d="M-355.3,571.5c130.1,49.8,244.4-51,371.3-59.3c136.2-9,218.7,132.1,283.6,230.4
                c75.2,114,151.2,228.8,267,305.8c52.4,34.9,113.6,66.1,178.3,60.4c25-2.2,48.9-9.7,73.5-14.1c33.7-6,63.4-3.2,95.5,8.9"/>
              <path class="st2" d="M-356.1,578.1c129,50.1,243.8-46.1,370.5-52.4c135.1-6.7,217.6,129,283.9,226.5
                c76,111.8,153,223.9,267.7,299.7c52.4,34.7,113.3,65.6,177.8,61c25-1.8,49.1-8.8,73.7-12.6c33.8-5.3,63.6-2.2,95.6,10"/>
              <path class="st2" d="M-356.9,584.7c127.9,50.4,243.2-41.2,369.6-45.4c133.8-4.4,216.5,125.9,284.1,222.5
                c76.7,109.6,154.8,218.9,268.4,293.6c52.5,34.5,113,65.1,177.4,61.5c25-1.4,49.2-7.8,73.9-11.2c33.8-4.6,63.8-1.2,95.7,11"/>
              <path class="st2" d="M-357.8,591.4c126.8,50.7,242.7-36.2,368.7-38.2c132.6-2.1,215.4,123,284.4,218.6
                c77.5,107.4,156.6,213.9,269.1,287.4c52.6,34.3,112.7,64.7,177,62c25-1,49.4-6.9,74.1-9.8c33.8-4,64-0.2,95.8,12.1"/>
              <path class="st2" d="M-358.6,598c125.7,51.1,242.1-31.2,367.8-31.1c131.3,0.1,214.3,120,284.6,214.6
                c78.2,105.2,158.4,208.9,269.9,281.2c52.7,34.2,112.4,64.2,176.6,62.6c60.1-1.5,111.2-18.3,170.2,4.8"/>
              <path class="st2" d="M-359.4,604.6c124.6,51.4,241.6-26.2,367-23.9c130,2.4,213.2,117.2,284.9,210.6
                c79,102.9,160.2,203.9,270.6,275c52.8,34,112.1,63.7,176.2,63.1c60.1-0.6,111.8-15.8,170.5,7.3"/>
              <path class="st2" d="M-360.3,611.2c123.5,51.8,241.1-21.1,366.1-16.6c128.7,4.6,212,114.4,285.2,206.6
                c79.8,100.5,161.9,198.8,271.3,268.7c52.8,33.8,111.8,63.2,175.8,63.6c60,0.4,112.4-13.4,170.8,9.8"/>
              <path class="st2" d="M-361.1,617.9c122.5,52.1,240.7-16.1,365.2-9.4C131.4,615.3,215,720.2,289.5,811
                c80.5,98.2,163.7,193.7,272.1,262.5c52.9,33.6,111.6,62.7,175.4,64.2c60,1.3,113-11,171.1,12.3"/>
              <path class="st2" d="M-361.9,624.5c121.4,52.5,240.3-11,364.3-2.1c125.9,9,209.8,109.2,285.7,198.6
                c81.3,95.8,165.5,188.6,272.8,256.3c53,33.4,111.3,62.3,175,64.7c60,2.2,113.6-8.6,171.4,14.7"/>
              <path class="st2" d="M-362.8,631.1c120.4,52.8,239.8-5.9,363.4,5.1c124.6,11.1,208.8,106.8,286,194.6
                c82.1,93.4,167.2,183.5,273.5,250c53.1,33.2,111.1,61.8,174.6,65.2c59.9,3.2,114.1-6.2,171.8,17.2"/>
              <path class="st2" d="M-363.6,637.7c119.4,53.2,239.5-0.8,362.5,12.3C122,663.2,206.6,754.5,285.1,840.7
                c82.8,91,168.9,178.4,274.2,243.8c53.1,33,110.9,61.5,174.1,65.6c59.8,4,114.7-3.8,172.1,19.6"/>
              <path class="st2" d="M-364.4,644.4c118.4,53.5,239.1,4.2,361.6,19.5C118.8,679,203.9,766,283.7,850.6
                c83.6,88.6,170.6,173.3,275,237.7c53.1,32.8,110.7,61,173.7,66.1c59.8,4.9,115.2-1.5,172.4,22"/>
              <path class="st2" d="M-365.2,651c116.5,53.4,236.8,10.3,358,26.2c121,15.9,208.1,99.9,289.4,183.3
                c84.8,86.9,173.9,170,278.3,233.2c53,32.1,109.8,59.2,172.1,65.1c59.3,5.6,114.7,0.9,171.3,24.3"/>
              <path class="st2" d="M-366.1,657.6c115.6,53.7,236.5,15.2,357.2,33.2c119.6,17.9,207.1,98,289.6,179.5
                c85.5,84.5,175.5,164.9,279,227.1c53,31.8,109.6,58.8,171.7,65.6c59.3,6.5,115.2,3.2,171.6,26.7"/>
              <path class="st2" d="M-366.9,664.2c114.8,54,236.1,20,356.3,40.1c118.1,19.7,206.2,96.1,289.9,175.8
                c86.2,82.1,177.1,159.9,279.7,221.1c53.1,31.6,109.4,58.3,171.3,66.1c59.3,7.4,115.6,5.5,171.9,29"/>
              <path class="st2" d="M-367.7,670.8c114.1,54.3,235.8,24.8,355.5,46.9c116.6,21.5,205.3,94.4,290.1,172.2
                c87,79.7,178.6,155,280.4,215.1c53.1,31.4,109.3,58,170.9,66.5c59.2,8.2,116,7.7,172.3,31.3"/>
              <path class="st2" d="M-368.6,677.5C-255.2,732-133.1,707-13.9,731.1c115.1,23.2,204.5,92.9,290.3,168.6
                c42.3,37.3,84.6,74.9,130,108.4c24.9,18.4,52.3,33.1,77.1,51.3c24,17.6,48.3,34.5,74,49.6c53.1,31.1,109.2,57.6,170.5,66.9
                c59.2,9,116.4,9.9,172.6,33.6"/>
              <path class="st2" d="M-369.4,684.1c112.8,54.8,235.1,34.2,353.9,60.2C98.1,769.1,188.2,835.7,275,909.4
                c42.5,36.1,85.1,72.5,130.6,104.9c25,17.8,52.5,31.9,77.3,49.8c24,17.3,48.2,34,73.9,48.8c53.2,30.9,109.1,57.1,170.1,67.3
                c59.2,9.9,116.8,12.1,172.9,35.9"/>
              <path class="st2" d="M-370.2,690.7c112.2,55,234.8,38.8,353.1,66.6C95,783.7,185.8,847.4,273.6,919
                c42.8,34.9,85.6,70.1,131.2,101.4c25.1,17.3,52.7,30.8,77.5,48.4c24,17,48.2,33.4,73.7,48.1c53.2,30.6,109,56.7,169.7,67.7
                c59.2,10.8,117.1,14.3,173.2,38.2"/>
              <path class="st2" d="M-371.1,697.3c111.7,55.2,234.4,43.3,352.3,72.9C92,798.1,183.5,859.1,272.1,928.6
                c43.1,33.8,86.2,67.7,131.8,98c25.2,16.7,52.9,29.8,77.6,47c24,16.7,48.2,32.9,73.6,47.3c53.3,30.3,108.9,56.2,169.4,68.1
                c59.2,11.6,117.4,16.4,173.6,40.4"/>
              <path class="st2" d="M-371.9,704c111.3,55.4,234.1,47.7,351.6,79c109.4,29.2,201.6,87.7,291,155.1
                c43.3,32.6,86.7,65.5,132.4,94.7c25.3,16.2,53.1,28.8,77.8,45.7c24,16.4,48.1,32.3,73.5,46.6c53.3,30.1,108.8,55.8,169,68.4
                c59.2,12.4,117.7,18.5,173.9,42.6"/>
              <path class="st2" d="M-372.7,710.6c110.9,55.5,233.8,51.9,350.8,84.9c25.1,7.1,48.2,17.1,72,27.4
                c27.2,11.7,55.2,20.2,81.7,33.9c48.9,25.3,92.9,58.5,137.4,90.7c43.6,31.5,87.2,63.3,133,91.5c25.4,15.7,53.3,27.8,78,44.4
                c24,16.1,48.1,31.7,73.4,45.8c53.4,29.8,108.7,55.3,168.6,68.7c59.2,13.2,118,20.5,174.3,44.8"/>
              <path class="st2" d="M-373.6,717.2c110.6,55.6,233.5,56,350.1,90.7c24.9,7.4,48.4,17.2,71.9,28c26.8,12.2,55.1,20,81.7,33.3
                c48.8,24.5,93,56.5,137.7,87.7c43.8,30.5,87.7,61.1,133.5,88.4c25.5,15.2,53.4,26.9,78.1,43.1c24,15.8,48.1,31.1,73.2,45
                c53.4,29.5,108.7,54.9,168.2,69c59.2,14,118.3,22.6,174.6,46.9"/>
              <path class="st2" d="M-374.4,723.8c110.4,55.7,233.2,60,349.5,96.2c24.8,7.7,48.3,17.8,71.9,28.6
                c26.6,12.2,55.1,19.8,81.6,32.7c48.7,23.8,93.1,54.7,138,84.7c44,29.4,88.1,59,134.1,85.4c25.6,14.7,53.5,26,78.3,42
                c24,15.4,48,30.5,73.1,44.2c53.5,29.2,108.6,54.4,167.9,69.2c59.2,14.8,118.5,24.6,175,49"/>
              <path class="st2" d="M-375.2,730.4c110.3,55.8,232.8,63.8,348.8,101.5c24.7,8,48.2,18.3,71.8,29.1c26.5,12.2,55,19.6,81.5,32.3
                c48.5,23.1,93.2,52.9,138.3,81.9c44.3,28.4,88.6,57,134.6,82.6c25.6,14.3,53.6,25.2,78.4,40.8c24,15.1,48,29.9,73,43.4
                c53.5,28.8,108.6,53.9,167.5,69.4C778,1227,837.4,1238,894,1262.5"/>
              <path class="st2" d="M-376,737.1c110.2,55.7,232.5,67.5,348.2,106.5c24.7,8.3,48.1,18.7,71.7,29.6
                c26.4,12.2,54.9,19.5,81.4,31.8c48.4,22.5,93.3,51.2,138.6,79.2c44.5,27.5,89,55.1,135.1,79.8c25.7,13.8,53.7,24.5,78.5,39.8
                c24,14.8,48,29.3,72.9,42.6c53.6,28.5,108.5,53.4,167.1,69.6c59.3,16.4,118.9,28.5,175.7,53.1"/>
              <path class="st2" d="M-376.9,743.7c110.2,55.7,232.2,71,347.6,111.3c24.6,8.6,48,19.1,71.6,30c26.3,12.1,54.8,19.4,81.3,31.5
                c48.3,21.9,93.3,49.6,138.8,76.7c44.7,26.6,89.5,53.2,135.6,77.2c25.8,13.4,53.8,23.8,78.6,38.8c24,14.4,47.9,28.7,72.8,41.7
                c53.6,28.1,108.5,52.9,166.8,69.8c59.4,17.2,119.1,30.4,176,55.1"/>
              <path class="st2" d="M-377.7,750.3c110.2,55.6,231.9,74.3,347.1,115.9c24.5,8.9,47.8,19.9,71.6,30.4
                c26.5,11.6,54.6,19.4,81.2,31.2c48.1,21.4,93.4,48.1,139,74.2c44.9,25.7,89.9,51.4,136.1,74.8c25.9,13.1,53.8,23.2,78.8,37.8
                c24,14.1,47.9,28.1,72.6,40.9c53.7,27.8,108.5,52.4,166.4,69.9c59.4,17.9,119.3,32.2,176.4,57.1"/>
              <path class="st2" d="M-378.5,756.9C-268.2,812.3-147,834.3-32,877c24.5,9.1,47.7,20.3,71.5,30.7c26.4,11.6,54.5,19.4,81.1,30.9
                c48,20.9,93.4,46.7,139.2,72c45.1,24.9,90.3,49.8,136.6,72.4c25.9,12.7,53.9,22.6,78.9,37c24,13.8,47.9,27.4,72.5,40
                c53.7,27.4,108.5,51.9,166.1,69.9c59.5,18.6,119.5,34.1,176.8,59"/>
              <path class="st2" d="M-379.4,763.5c110.5,55.1,231.3,80.4,346.1,124.1c24.4,9.3,47.7,20.5,71.5,31c26.4,11.6,54.4,19.4,81,30.8
                c47.9,20.5,93.5,45.4,139.4,69.8c45.3,24.1,90.7,48.2,137,70.3c26,12.4,53.9,22.2,79,36.2c24,13.4,47.9,26.8,72.4,39.1
                c53.7,27,108.5,51.3,165.7,70c59.5,19.4,119.6,35.9,177.2,60.9"/>
              <path class="st2" d="M-380.2,770.1c110.8,54.8,231,83.1,345.6,127.7c24.3,9.5,47.6,20.7,71.4,31.2
                c26.3,11.6,54.2,19.5,80.8,30.7c47.7,20.1,93.5,44.3,139.6,67.9c45.5,23.3,91.1,46.7,137.5,68.3
                c51.1,23.8,100.8,48.7,151.3,73.7c53.8,26.6,108.5,50.8,165.4,70c59.6,20.1,119.8,37.6,177.5,62.7"/>
              <path class="st2" d="M-381,776.8c111.1,54.4,230.7,85.5,345.3,131c98.9,39.3,196.4,80.7,291.8,128.1
                c45.7,22.7,91.4,45.3,137.9,66.4c51.1,23.2,100.8,47.6,151.3,72.1c53.8,26.2,108.5,50.2,165.1,69.9
                c59.7,20.8,119.9,39.3,177.9,64.5"/>
              <path class="st2" d="M-381.9,783.4c111.4,53.9,230.4,87.8,344.9,134C61.4,957.1,159,998,254.8,1044
                c96.1,46.2,193.4,89.4,289.5,135.4c111.6,53.4,229.8,87.1,343.1,136.1"/>
              <path class="st2" d="M-382.7,790c112.7,53.6,232,90.4,347.2,137.7c97.1,39.9,193.9,79.8,289.1,124.2
                c95.4,44.6,191.9,86.4,287.2,131.3c112.8,53.1,231.5,89.6,345.8,138.9"/>
              <path class="st2" d="M-383.5,796.6c113.1,52.9,231.7,92.2,347,140c96.7,40.1,193.6,79.7,289,123.1
                c95.6,43.5,192.1,84.7,287.5,128.7c113.3,52.3,231.4,91.1,345.9,140.3"/>
              <path class="st2" d="M-384.3,803.2c113.6,52.1,231.5,93.7,346.7,141.9c96.5,40.3,193.2,79.8,288.8,122.2
                c95.8,42.5,192.2,83.2,287.7,126.4c113.7,51.4,231.3,92.6,346,141.6"/>
              <path class="st2" d="M-385.2,809.8c209.4,93.7,424.8,173.6,635.2,265c95.9,41.7,192.3,82,287.9,124.3
                c114.2,50.5,231.2,94,346.1,142.9"/>
              <path class="st2" d="M-386,816.4C-175.7,908,37.9,992,248.9,1082s423,177.3,634.3,266.6"/>
              <path class="st2" d="M-386.8,823.1c422.7,178.4,846.3,354.6,1269.2,532.2"/>
            </g>
          </g>
          <g>
            <g>
              <path class="st2" d="M-192.8-726.2C230.4-549,654.1-373,1076.5-194"/>
              <path class="st2" d="M-193.6-719.6C17.2-631.8,228.3-544.7,438.7-456c212.2,89.5,426.5,174.8,636.9,268.5"/>
              <path class="st2" d="M-194.4-712.9C15.8-625.8,226.7-540,436-450.9c212.5,90.5,429.3,172.7,638.8,270.2"/>
              <path class="st2" d="M-195.2-706.3c114.9,47.4,229.1,96.5,343.7,144.5c94.6,39.6,190.6,75.6,284.8,116.3
                c95.4,41.2,191.7,79.5,287,121c117.5,51,238.2,94.5,353.7,150.3"/>
              <path class="st2" d="M-196.1-699.7c115,47.2,229,96.7,343.7,144.7c94,39.4,189.6,74.3,283,115.2
                c44.6,19.5,89.1,39.1,134.3,57.1c51.3,20.3,100.9,41.9,151.5,64c118.4,51.7,240.9,93.4,356.7,151.2"/>
              <path class="st2" d="M-196.9-693.1c115.1,47.1,229,96.9,343.6,145c93.5,39.2,188.6,73,281.3,114.5
                c44.1,19.7,88.1,39.5,133,57.2c25.3,10,52.5,17.2,76.9,29.1c24.8,12,49.4,24,74.7,35C832-260.2,956.2-220.6,1072.3-161"/>
              <path class="st2" d="M-197.7-686.5c115.2,46.9,228.9,97.2,343.6,145.3c93,39,187.7,72,279.6,114c43.6,19.9,87.2,40,131.8,57.5
                c25.1,9.9,52.4,16.6,76.5,28.6c24.9,12.3,49.8,24.5,75.2,35.6c120.2,52.3,246.1,89.8,362.5,151"/>
              <path class="st2" d="M-198.6-679.8C-83.3-633,30.3-582.4,145-534.2c92.6,38.9,186.8,71.1,277.9,113.9
                c43.1,20.2,86.2,40.6,130.5,58.1c25,9.8,52.2,16.2,76.1,28.1c25.1,12.5,50,25,75.8,36.1c121.1,52.4,248.6,87.5,365.3,150.2"/>
              <path class="st2" d="M-199.4-673.2c115.3,46.7,228.8,97.8,343.5,146.1c48.5,20.4,98,36.5,146.9,55.6
                c44.1,17.2,86.8,37.9,129.4,58.4c42.6,20.6,85.3,41.3,129.3,58.8c24.8,9.8,52.1,15.8,75.8,27.8c25.2,12.8,50.3,25.4,76.3,36.5
                c121.9,52.3,251,84.8,368,148.8"/>
              <path class="st2" d="M-200.2-666.6C-84.8-620,28.5-568.5,143.2-520.1c48.3,20.4,97.8,36,146.5,55.2
                c43.9,17.2,86.1,38.3,128.2,59.3c42.2,21,84.4,42.2,128.2,59.7c24.6,9.8,51.9,15.5,75.4,27.5c25.3,13,50.6,25.7,76.9,36.8
                c122.8,52,253.3,81.8,370.6,147"/>
              <path class="st2" d="M-201-660c115.5,46.6,228.7,98.5,343.3,147c48.2,20.4,97.6,35.6,146.1,54.9
                c43.6,17.3,85.4,38.9,127.1,60.3c41.7,21.5,83.5,43.2,127,60.8c24.4,9.9,51.7,15.3,75,27.3c25.5,13.2,50.9,26,77.4,37
                c123.7,51.6,255.5,78.4,373.2,144.7"/>
              <path class="st2" d="M-201.9-653.4c115.6,46.5,228.6,98.9,343.3,147.6c48,20.4,97.5,35.2,145.7,54.6
                c43.4,17.5,84.7,39.6,126,61.5c41.3,22,82.6,44.2,125.9,62c24.3,10,51.6,15.2,74.7,27.2c25.6,13.3,51.1,26.2,77.9,37.2
                c124.5,51.1,257.6,74.7,375.7,141.9"/>
              <path class="st2" d="M-202.7-646.7C-87-600.3,25.8-547.4,140.5-498.6c47.9,20.4,97.3,34.9,145.3,54.5
                c43.2,17.7,84.1,40.3,124.9,62.9c40.8,22.5,81.7,45.4,124.8,63.4c24.2,10.1,51.4,15.1,74.4,27.2c25.7,13.4,51.4,26.3,78.3,37.2
                c125.4,50.4,259.6,70.7,378.2,138.7"/>
              <path class="st2" d="M-203.5-640.1C-87.8-593.7,25-540.2,139.6-491.3c47.8,20.4,97.3,34.6,145,54.5
                c43,17.9,83.5,41.2,123.8,64.4c40.4,23.2,80.9,46.7,123.8,64.9c24,10.2,51.1,15.2,74.1,27.2c25.8,13.5,51.6,26.4,78.8,37.1
                c126.3,49.6,261.5,66.4,380.5,135.1"/>
              <path class="st2" d="M-204.4-633.5C-88.5-587.1,24-533.1,138.7-484c47.6,20.4,97.2,34.3,144.7,54.5
                c42.8,18.2,82.9,42.3,122.8,66c40,23.8,80,48,122.8,66.7c23.9,10.4,50.9,15.3,73.8,27.3c25.9,13.6,51.9,26.4,79.3,36.9
                c127.1,48.7,263.3,61.7,382.8,131.1"/>
              <path class="st2" d="M-205.2-626.9C-89.3-580.4,23.1-526,137.7-476.7c23.7,10.2,47.3,21.1,71.6,29.8
                c24.2,8.7,49,14.5,72.8,24.8c42.7,18.6,82.4,43.4,121.8,67.8c39.6,24.5,79.2,49.5,121.8,68.5c23.8,10.6,50.7,15.5,73.5,27.5
                c26,13.7,52.1,26.4,79.7,36.7c128,47.6,265,56.8,385.1,126.7"/>
              <path class="st2" d="M-206-620.3c116,46.4,228.2,101.4,342.8,150.9c23.6,10.2,47.3,21.2,71.6,29.9
                c24.2,8.7,48.8,14.4,72.5,24.9c42.6,19,81.8,44.6,120.9,69.8c39.1,25.3,78.4,51,120.8,70.5c23.7,10.9,50.4,15.7,73.2,27.7
                c26.1,13.7,52.3,26.4,80.1,36.4c128.9,46.4,266.6,51.5,387.2,121.9"/>
              <path class="st2" d="M-206.8-613.6c116.1,46.5,228.1,102,342.7,151.7c23.6,10.3,47.3,21.2,71.6,29.9
                c24.2,8.7,48.7,14.4,72.2,25.2c42.5,19.5,81.3,45.9,120,71.9s77.6,52.7,119.9,72.6c23.6,11.1,50.1,16,73,28
                c26.2,13.7,52.5,26.3,80.5,36c129.7,45.1,268.1,46,389.3,116.7"/>
              <path class="st2" d="M-207.7-607c116.1,46.5,228,102.6,342.6,152.5c23.6,10.3,47.3,21.3,71.5,30c24.1,8.7,48.5,14.4,71.9,25.4
                c42.4,20,80.8,47.3,119.1,74.1c38.3,26.9,76.8,54.3,119,74.9c23.5,11.4,49.9,16.4,72.7,28.3c26.3,13.7,52.7,26.1,80.9,35.5
                C800.7-142.5,939.5-146,1061.5-75"/>
              <path class="st2" d="M-208.5-600.4C-92.3-553.8,19.4-497.2,134-447c23.6,10.3,47.3,21.4,71.5,30.1
                c24.1,8.7,48.4,14.5,71.6,25.8c42.3,20.6,80.4,48.8,118.2,76.4c37.9,27.7,76,56.1,118.1,77.2c23.4,11.7,49.6,16.8,72.5,28.7
                c26.4,13.7,52.9,25.9,81.3,35C728-154.3,791.4-147,854-136.7c72.9,12,142.3,30.9,206.6,68.3"/>
              <path class="st2" d="M-209.3-593.8C-93-547.2,18.5-490,133.1-439.5c23.6,10.4,47.3,21.5,71.5,30.2
                c24.1,8.7,48.2,14.6,71.4,26.2c42.2,21.2,79.9,50.3,117.4,78.8c37.6,28.6,75.2,58,117.3,79.7c23.3,12,49.3,17.3,72.3,29.2
                c26.4,13.7,53.1,25.7,81.7,34.4c61,18.7,124.7,24.5,187.6,33.5c73.3,10.5,143,28.3,207.6,65.7"/>
              <path class="st2" d="M-210.2-587.1C-93.8-540.5,17.5-482.7,132.1-432c23.6,10.4,47.2,21.5,71.5,30.3
                c24.2,8.7,48.1,14.7,71.2,26.6c42.2,21.8,79.5,52,116.6,81.4c37.2,29.5,74.5,59.9,116.5,82.3c23.2,12.4,49,17.8,72.1,29.7
                c26.5,13.6,53.3,25.4,82,33.7c61.4,17.9,125.3,22.2,188.5,29.8c73.7,8.9,143.6,25.6,208.5,63"/>
              <path class="st2" d="M-211-580.5c116.5,46.8,227.6,105.1,342.2,156.1c23.6,10.5,47.2,21.6,71.5,30.4
                c24.2,8.8,48,14.9,70.9,27.1c42.2,22.5,79.1,53.7,115.8,84c36.8,30.4,73.7,61.8,115.7,85c23.1,12.8,48.7,18.4,71.9,30.2
                c26.6,13.6,53.5,25.1,82.4,33c61.7,17.1,125.9,19.7,189.3,26c74.1,7.3,144.2,22.8,209.4,60.1"/>
              <path class="st2" d="M-211.8-573.9c116.5,46.8,227.5,105.8,342,157c23.6,10.5,47.2,21.7,71.5,30.5
                c24.2,8.8,47.9,15.1,70.8,27.7c42.1,23.3,78.7,55.5,115.1,86.8c36.5,31.4,73,63.8,115,87.8c23.1,13.2,48.4,19,71.7,30.8
                c26.6,13.5,53.7,24.7,82.7,32.3c62,16.1,126.4,17.2,190,22c74.4,5.6,144.8,19.8,210.3,57.1"/>
              <path class="st2" d="M-212.7-567.3c116.6,46.9,227.4,106.5,341.9,158c46.4,20.9,98.1,33.8,142.1,58.9
                c42.1,24.1,78.4,57.4,114.4,89.6c36.1,32.4,72.3,65.9,114.2,90.7c23,13.6,48.1,19.7,71.6,31.5c26.7,13.4,53.9,24.3,83,31.4
                c62.5,15.3,127,14.6,190.9,17.9c74.7,3.9,145.3,16.8,211.1,54"/>
              <path class="st2" d="M-213.5-560.7c116.7,47,227.3,107.2,341.8,159c23.5,10.6,47.2,21.8,71.5,30.7
                c24.3,8.9,47.8,15.6,70.4,28.9c42.2,24.9,78.1,59.3,113.7,92.5c35.8,33.4,71.6,68,113.6,93.6c22.9,14,47.8,20.4,71.4,32.1
                c26.8,13.3,54.1,23.9,83.3,30.6C715-78.8,779.8-81.2,843.8-79.4c74.9,2.1,145.7,13.7,211.9,50.7"/>
              <path class="st2" d="M-214.3-554c116.8,47.1,227.1,107.9,341.6,160c46.2,21,98.5,34,141.7,60.5c42.2,25.8,77.7,61.3,113,95.5
                c35.4,34.4,70.9,70.2,112.9,96.7c22.8,14.4,47.5,21.1,71.3,32.8C593-95.3,620.4-85,649.8-78.8c63.3,13.3,128.2,9.1,192.3,9.4
                C917.3-69.1,988.3-59,1054.8-22"/>
              <path class="st2" d="M-215.1-547.4c116.9,47.3,227,108.7,341.5,161.1c46.2,21.1,98.7,34.2,141.6,61.3
                c42.2,26.7,77.5,63.4,112.4,98.6c35.1,35.4,70.3,72.4,112.3,99.8c22.7,14.8,47.2,21.9,71.2,33.6c26.9,13.1,54.4,23,83.8,28.7
                c63.7,12.3,128.7,6.3,193,5c75.2-1.5,146.4,7.1,213.3,43.9"/>
              <path class="st2" d="M-216-540.8c117,47.4,226.9,109.4,341.4,162.2c46.2,21.3,98.9,34.4,141.4,62.2
                c42.3,27.6,77.2,65.5,111.8,101.8c34.8,36.5,69.6,74.6,111.7,103c22.7,15.2,47,22.6,71.2,34.3c26.9,12.9,54.6,22.5,84.1,27.7
                c64.1,11.2,129.2,3.4,193.6,0.5c75.3-3.4,146.7,3.7,214,40.3"/>
              <path class="st2" d="M-216.8-534.2C-99.8-486.6,10-424,124.4-370.9c46.2,21.4,99.2,34.7,141.3,63.1
                c42.3,28.6,76.9,67.6,111.3,105c34.5,37.6,69,76.9,111.1,106.2c22.5,15.7,46.6,23.6,71,35.2c26.9,12.8,54.7,22,84.3,26.7
                c64.5,10.1,129.7,0.5,194.2-4C913-44,984.5-38.4,1052.2-2.1"/>
              <path class="st2" d="M-217.6-527.6c117.1,47.7,226.7,111,341.1,164.3c46.2,21.5,99.4,34.9,141.2,64.1
                c42.4,29.6,76.7,69.8,110.7,108.2c34.3,38.7,68.4,79.1,110.6,109.5C529.2-50.2,589-27,641.5-19.8c64.9,9,130.2-2.5,194.8-8.7
                c75.4-7.2,147.1-3.3,215.2,32.9"/>
              <path class="st2" d="M-218.5-520.9c117.2,47.8,226.6,111.8,341,165.5c46.2,21.7,99.6,35.2,141.1,65.1
                c42.5,30.6,76.5,72,110.2,111.5c34,39.8,67.9,81.5,110.1,112.9c42.9,32,102.7,55,155.6,61.3c65.4,7.8,130.7-5.6,195.3-13.4
                c75.3-9.1,147.2-6.9,215.8,29.1"/>
              <path class="st2" d="M-219.3-514.3c117.3,48,226.4,112.6,340.8,166.6c46.3,21.9,99.8,35.5,141.1,66.1
                c42.6,31.6,76.3,74.3,109.8,114.9c33.7,40.9,67.3,83.8,109.6,116.3c42.7,32.8,102.3,55.7,155.7,61.1
                c141.2,14.3,276.3-63.2,412.1,7"/>
              <path class="st2" d="M-220.1-507.7C-102.8-459.6,6.2-394.3,120.6-340c46.4,22,100.1,35.8,141,67.1
                c42.7,32.7,76.1,76.6,109.3,118.3c33.5,42,66.8,86.2,109.2,119.8c42.6,33.7,102,56.4,155.9,60.9c141.6,11.7,276-71.7,413-1.7"
                />
              <path class="st2" d="M-221-501.1c117.4,48.3,226.2,114.2,340.5,168.9c46.5,22.2,100.3,36.1,141,68.2
                c42.8,33.8,75.9,78.9,108.9,121.7c33.3,43.1,66.3,88.5,108.8,123.2c42.4,34.6,101.5,57.1,156,60.6
                c142.1,9,275.7-80.1,413.9-10.6"/>
              <path class="st2" d="M-221.8-494.4c117.5,48.4,226,115,340.4,170c46.6,22.4,100.5,36.4,141,69.3
                c42.9,34.8,75.7,81.3,108.6,125.2c33,44.2,65.8,90.8,108.4,126.7c42.3,35.5,101.1,57.9,156.1,60.3
                c142.4,6.3,275.3-88.6,414.6-19.5"/>
              <path class="st2" d="M-222.6-487.8C-105-439.2,3.3-372,117.6-316.7c46.7,22.6,100.8,36.8,140.9,70.3
                c43,36,75.6,83.6,108.2,128.7c32.9,45.4,65.4,93.3,108.1,130.3C517,49.1,575.5,71.3,631.1,72.7
                c142.7,3.6,274.8-97.2,415.3-28.5"/>
              <path class="st2" d="M-223.4-481.2c117.7,48.8,225.8,116.6,340.1,172.3c46.9,22.9,101,37.1,141,71.4
                c43.2,37.1,75.5,86,107.9,132.2c32.7,46.6,64.9,95.7,107.8,133.8C515.3,66,573.4,88,629.7,88.3c142.9,0.8,274.3-105.8,416-37.5
                "/>
              <path class="st2" d="M-224.3-474.6c117.8,48.9,225.7,117.4,339.9,173.5c47.1,23.1,101.2,37.5,141,72.5
                c43.3,38.2,75.4,88.3,107.6,135.7c65,95.4,135.2,198.6,264,196.9c143-1.9,273.7-114.3,416.5-46.5"/>
              <path class="st2" d="M-225.1-468c117.9,49.1,225.5,118.2,339.8,174.7c47.2,23.3,101.5,37.8,141,73.6
                C299.2-180.4,331-129,363.1-80.5C427,16.1,496.6,124,627,119.7C770.1,115,900.1-3.2,1044,64.1"/>
              <path class="st2" d="M-225.9-461.3C-108-412-0.5-342.3,113.7-285.5c47.4,23.6,101.7,38.2,141.1,74.7
                C298.4-170.3,330.1-117.6,362-68c62.8,97.6,131.9,210.3,263.8,203.4c143-7.4,272.4-131.5,417.4-64.7"/>
              <path class="st2" d="M-226.8-454.7c118.1,49.5,225.3,119.9,339.5,177c47.6,23.8,102,38.5,141.2,75.8
                c43.7,41.7,75.2,95.4,107,146.2C422.6,42.8,491.2,160.5,624.6,151c143-10.1,271.7-140,417.7-73.7"/>
              <path class="st2" d="M-227.6-448.1c118.2,49.6,225.1,120.7,339.3,178.2C159.6-245.8,214-231,253-193
                c43.9,42.9,75.2,97.8,106.9,149.7c60.6,99.3,128.9,222.1,263.7,210c142.8-12.8,271-148.5,418-82.7"/>
              <path class="st2" d="M-228.4-441.5c118.3,49.8,225,121.5,339.2,179.4c48,24.3,102.6,39.2,141.3,78
                c44,44,75.2,100.1,106.7,153.2c59.5,100,127.5,228,263.7,213.2C765,166.8,892.7,25.4,1040.6,90.6"/>
              <path class="st2" d="M-229.2-434.8c118.4,50,224.9,122.4,339,180.5c48.3,24.6,102.8,39.6,141.5,79.1
                c44.2,45.1,75.2,102.4,106.6,156.6c58.5,100.6,126.2,234,263.7,216.5C763.8,179.8,891,32.7,1039.8,97.3"/>
              <path class="st2" d="M-230.1-428.2C-111.7-378.1-5.4-305,108.8-246.5c48.5,24.9,103.1,40,141.6,80.1
                c44.4,46.2,75.2,104.7,106.6,160c57.4,101.2,125,239.9,263.8,219.6C762.5,192.6,889.4,39.7,1039,103.9"/>
              <path class="st2" d="M-230.9-421.6c119.4,50.7,226.2,125.1,341.4,184.2c45.5,23.4,97.3,37.6,134.1,74.5
                C292-115.2,323.8-52.5,356.2,5.8C412,106.4,478.5,249.7,616.8,229.1c143-21.3,269.6-182.7,421.3-118.6"/>
              <path class="st2" d="M-231.7-415c119.5,50.9,226.1,125.9,341.2,185.3c45.7,23.6,97.5,37.9,134.2,75.4
                c47.7,48.7,79.4,112.7,111.7,172.1c54.8,101,121.3,249.8,260.8,226.5C758.7,220.5,885,53.4,1037.4,117"/>
              <path class="st2" d="M-232.6-408.4c119.7,51,226,126.7,341.1,186.5c45.9,23.8,97.8,38.2,134.4,76.3
                c47.9,49.8,79.5,115,111.7,175.5c53.9,101.2,120.4,255.5,261,229.6c141.9-26.2,267.9-198.9,421-135.8"/>
              <path class="st2" d="M-233.4-401.7c119.8,51.2,225.8,127.5,340.9,187.6c46.1,24.1,98.1,38.6,134.6,77.1
                c48.2,50.9,79.7,117.3,111.8,178.8C406.9,143.2,473.5,303,615,274.5C756.3,246,882,67.7,1035.7,130.4"/>
              <path class="st2" d="M-234.2-395.1c119.9,51.4,225.7,128.3,340.8,188.7c46.3,24.3,98.3,38.9,134.8,77.9
                c48.4,52,79.8,119.6,111.9,182.1c52.1,101.6,118.9,266.9,261.3,235.7c73.6-16.1,137-66.4,200-105
                C881.9,143,956,105,1034.9,136.9"/>
              <path class="st2" d="M-235.1-388.5c120,51.5,225.6,129.1,340.6,189.8c46.5,24.5,98.6,39.2,135,78.7
                C289.3-67,320.6,1.7,352.7,65.3C403.9,166.9,471,337.7,614.2,304c73.9-17.4,137.1-69.4,199.8-109.4
                c66.6-42.5,140.7-82.7,220.1-51"/>
              <path class="st2" d="M-235.9-381.9C-115.8-330.2-10.4-252,104.6-191c46.7,24.8,98.9,39.5,135.3,79.5
                c49,53.9,80.2,123.8,112.2,188.4C402.5,178.5,470,354.7,614,318.5c74.2-18.6,137-72.4,199.5-113.8
                c65.8-43.6,140-86.1,219.7-54.5"/>
              <path class="st2" d="M-236.7-375.2c120.2,51.8,225.3,130.6,340.4,192c46.8,25,99.2,39.7,135.5,80.1
                c49.3,54.8,80.4,125.8,112.5,191.4C401.2,189.9,469,371.4,613.8,332.7c74.5-19.9,137-75.4,199.2-118.1
                c65.1-44.7,139.3-89.5,219.4-57.8"/>
              <path class="st2" d="M-237.5-368.6c120.3,51.9,225.2,131.4,340.2,193c47,25.2,99.5,40,135.8,80.8C288-39.1,319.2,33,351.2,99.6
                C400,201,468.3,387.8,613.7,346.8c74.7-21.1,137-78.2,198.9-122.2c64.4-45.7,138.5-92.7,219-61.1"/>
              <path class="st2" d="M-238.4-362C-118-309.9-13.3-229.9,101.7-168c47.1,25.4,99.7,40.2,136.1,81.4
                c49.9,56.6,81,129.7,113,197.1c48.2,101.3,117,293.1,262.9,249.7c74.9-22.3,136.8-80.9,198.5-126.1
                c63.6-46.6,137.7-95.7,218.5-64.1"/>
              <path class="st2" d="M-239.2-355.4c120.5,52.1,224.9,132.7,339.9,194.9c47.4,25.6,99.8,40.7,136.2,82.3
                c50,57.1,81.2,130.6,113.5,198.4c48.2,101.2,117.7,296.1,264,250.3c74.8-23.4,136.4-82.2,198-127.9
                c63-46.7,136.8-96.7,217.5-65.9"/>
              <path class="st2" d="M-240-348.8C-119.5-296.6-15.3-215.5,99.7-153c47.5,25.9,99.9,41,136.4,82.9
                c50.1,57.5,81.6,131.2,114.2,199.4c48.3,101.2,118.6,298.4,265.1,250.6c74.6-24.3,135.8-83.2,197.5-129.3
                c62.4-46.7,135.9-97.4,216.3-67.3"/>
              <path class="st2" d="M-240.9-342.1c120.6,52.2,224.6,133.8,339.5,196.6c47.7,26,100,41.3,136.5,83.4
                c50.2,57.8,82,131.6,114.8,200c48.5,101.1,119.6,300,266.3,250.6c74.4-25.1,135.3-83.9,196.9-130.3
                c61.9-46.6,135.1-97.8,215.2-68.2"/>
              <path class="st2" d="M-241.7-335.5C-121-283.2-17.2-201.1,97.6-138.1c47.8,26.2,100.1,41.6,136.8,83.8
                C284.8,3.6,316.8,77.5,349.9,146c48.8,101,120.8,301.2,267.5,250.3c74.2-25.7,134.7-84.5,196.2-131
                c61.4-46.5,134.2-97.9,213.9-68.7"/>
              <path class="st2" d="M-242.5-328.9c120.8,52.4,224.3,135,339.1,198.3c47.8,26.4,100.2,41.8,137,84.1
                c50.5,58,82.8,131.8,116.3,200.4C399,254.6,472,455.6,618.6,403.5c73.9-26.2,134.1-84.8,195.4-131.3
                c60.9-46.2,133.4-97.8,212.6-68.9"/>
              <path class="st2" d="M-243.3-322.3c120.9,52.4,224.2,135.6,338.9,199.2c47.8,26.5,100.3,42,137.2,84.2
                C283.5,19,316.1,92.8,349.9,161.2C399.5,261.8,473.4,463.1,620,410.1c73.5-26.6,133.5-84.9,194.6-131.4
                c60.4-46,132.5-97.5,211.2-68.8"/>
              <path class="st2" d="M-244.2-315.7c121,52.5,224,136.2,338.7,200C142.4-89,194.9-73.5,232-31.4c51,57.8,83.9,131.4,118,199.7
                c50,100.3,125,301.6,271.4,247.9c73.2-26.8,132.8-84.8,193.8-131.3c59.9-45.6,131.7-97.1,209.8-68.5"/>
              <path class="st2" d="M-245-309c121.1,52.6,223.9,136.8,338.5,200.9C141.3-81.4,194-65.9,231.3-24
                c51.1,57.6,84.4,131,118.8,199.1c50.6,100.1,126.6,301,272.8,246.8c72.8-27,132.1-84.7,192.9-130.9
                c59.5-45.2,130.9-96.4,208.3-67.9"/>
              <path class="st2" d="M-245.8-302.4C-124.6-249.8-22.1-165,92.5-100.7c47.8,26.9,100.7,42.4,138.2,84.3
                c51.4,57.4,85,130.7,119.8,198.8c51.1,99.8,128.4,301,274.3,245.9c72.4-27.3,131.3-84.7,191.9-130.9
                c58.9-44.9,129.9-96,206.7-67.6"/>
              <path class="st2" d="M-246.7-295.8c121.3,52.7,223.6,138,338.1,202.6c48,27.1,100.9,42.7,138.6,84.7
                c51.7,57.6,85.8,131,121,199.2c51.4,99.5,130.2,302.3,276,245.7c71.9-27.9,130.4-85.4,190.7-131.7
                c58.1-44.7,128.6-96.3,204.8-68.3"/>
              <path class="st2" d="M-247.5-289.2C-126.2-236.4-24.1-150.6,90.4-85.7c48.1,27.2,101.1,43,139,85.1
                c52,57.7,86.5,131.2,122.1,199.5c35.9,68.8,70.3,142.3,122,200.9c42.2,47.8,94.4,68.8,155.6,44.5
                c71.5-28.5,129.6-85.9,189.6-132.4c57.3-44.6,127.3-96.5,203-68.9"/>
              <path class="st2" d="M-248.3-282.5C-126.9-229.7-25.1-143.4,89.4-78.3C137.6-50.9,190.7-35,228.7,7.1
                C281,65,315.8,138.5,351.8,206.9c36.3,68.8,71,142.3,123,201c42.5,47.9,94.8,68.9,156,44c71.1-29,128.8-86.4,188.5-132.9
                c56.8-44.3,126.3-96.5,201.5-69.3"/>
              <path class="st2" d="M-249.2-275.9C-127.6-223-26.1-136.2,88.4-70.8C136.6-43.2,189.8-27.3,228,14.9
                c52.5,57.9,87.7,131.4,124.1,199.8c36.6,68.8,71.7,142.4,124,201c42.8,48,95.1,69,156.5,43.5c70.8-29.4,128.1-86.8,187.6-133.4
                c56.2-44.1,125.3-96.5,199.9-69.6"/>
              <path class="st2" d="M-250-269.3c121.6,53,223,140.3,337.3,206c48.3,27.7,101.5,43.8,139.9,85.9
                c52.7,57.9,88.2,131.4,124.9,199.9c36.9,68.8,72.3,142.3,124.8,200.9c43.1,48.1,95.5,69,157,43
                c70.4-29.8,127.5-87.1,186.8-133.8c55.6-43.8,124.4-96.4,198.5-69.8"/>
              <path class="st2" d="M-250.8-262.7C-129.1-209.6-28-121.8,86.3-55.9C134.7-28,188-11.9,226.4,30.3
                c52.9,58,88.6,131.4,125.7,199.9C389.3,299,425,372.4,477.7,431c43.3,48.2,95.9,69,157.5,42.6c70.1-30.1,126.9-87.4,186-134.1
                c55.1-43.6,123.6-96.2,197.2-70"/>
              <path class="st2" d="M-251.6-256.1C-129.8-202.9-29-114.6,85.3-48.4C133.7-20.3,187-4.2,225.6,38c53,58,89,131.4,126.4,199.9
                c37.5,68.7,73.4,142.1,126.3,200.7c43.6,48.3,96.2,69,158,42.1c69.8-30.5,126.4-87.7,185.4-134.4
                c54.7-43.3,122.8-96.1,196-70.1"/>
              <path class="st2" d="M-252.5-249.4C-130.6-196.2-30-107.3,84.3-40.9C132.7-12.7,186,3.5,224.7,45.7
                c53.1,58,89.4,131.4,126.9,199.9c37.7,68.7,73.9,142.1,126.9,200.5c43.9,48.4,96.6,69,158.5,41.6c69.6-30.8,126-88,184.8-134.7
                c54.3-43.1,122.1-95.9,194.9-70.3"/>
              <path class="st2" d="M-253.3-242.8C-131.3-189.6-31-100.1,83.2-33.4C131.7-5.1,185,11.2,223.8,53.5
                c53.2,58.1,89.6,131.4,127.5,199.9c37.9,68.6,74.3,142,127.4,200.4c44.2,48.6,97,69,159,41.1c69.3-31.2,125.6-88.3,184.4-135
                C876,317,943.6,264.1,1016,289.5"/>
              <path class="st2" d="M-254.1-236.2C-132.1-182.9-32-92.9,82.2-26c48.5,28.4,101.9,44.8,140.7,87.1
                c53.3,58.1,89.9,131.5,127.9,199.9c38.1,68.6,74.6,141.9,127.8,200.4c44.5,48.9,97.3,69,159.5,40.6
                c69.1-31.5,125.3-88.6,184-135.4c53.6-42.7,121-95.7,193-70.7"/>
              <path class="st2" d="M-255-229.6C-132.8-176.2-33-85.7,81.2-18.5c48.5,28.6,101.9,45,140.7,87.4c53.3,58.2,90,131.6,128.2,200
                c38.3,68.6,74.9,141.9,128.2,200.4c44.7,49.1,97.7,69,160.1,40.1c69-31.9,125-89,183.7-135.9c53.2-42.5,120.5-95.7,192.2-71"/>
              <path class="st2" d="M-255.8-223C-133.5-169.5-33.9-78.5,80.1-11C128.7,17.7,182,34.1,220.9,76.7
                C274.2,135.1,311,208.5,349.4,277c38.4,68.6,75.2,141.9,128.4,200.5c19,20.8,41.4,41,68.5,50.4c30.9,10.7,63.5,2.7,92.1-10.7
                c68.9-32.3,124.8-89.5,183.6-136.4c53-42.4,120-95.7,191.5-71.4"/>
              <path class="st2" d="M-256.6-216.3C-134.3-162.8-34.9-71.3,79.1-3.5c48.6,28.8,101.8,45.4,140.7,88.1
                c53.3,58.6,90.2,132,128.6,200.6c38.5,68.7,75.3,142,128.6,200.7c18.9,20.8,41.3,41.1,68.4,50.5c31.2,10.7,64,2.3,92.7-11.3
                c68.8-32.7,124.7-90,183.5-137.1c52.7-42.3,119.6-95.8,190.9-72"/>
              <path class="st2" d="M-257.5-209.7C-135-156-35.9-64.1,78.1,3.8c48.6,29,101.8,45.6,140.7,88.5c53.2,58.8,90.2,132.3,128.7,201
                c38.6,68.8,75.5,142.1,128.7,201c18.9,20.8,41.2,41.4,68.3,50.7c31.4,10.8,64.5,2,93.3-11.9c68.7-33.1,124.7-90.6,183.5-137.8
                c52.5-42.2,119.3-96,190.5-72.8"/>
              <path class="st2" d="M-258.3-203.1C-136.5-148.4-36.8-57.8,77.2,9.9c48.6,28.9,102,46,140.8,88.8
                c53.3,58.7,90.4,132.2,128.9,200.9c38.6,68.8,75.4,142.1,128.6,201c18.9,20.9,41.2,41.4,68.2,51c31.4,11,64.5,2.7,93.5-11
                c68.8-32.5,125.2-89.6,183.6-137c52.3-42.4,118.7-97.2,190.1-74.5"/>
              <path class="st2" d="M-259.1-196.5C-138-140.9-37.6-51.8,76.4,15.7c48.6,28.8,102.1,46.3,141,88.9
                c53.4,58.7,90.5,132.2,129.1,200.9c38.6,68.8,75.4,142,128.4,201.1c18.8,21,41.1,41.6,68.1,51.3c31.3,11.3,64.5,3.5,93.7-10
                c68.9-31.9,125.6-88.7,183.7-136.1c52.1-42.6,118.1-98.3,189.8-76.1"/>
              <path class="st2" d="M-259.9-189.8c120.4,56.3,221.5,144,335.6,211c48.7,28.6,102.3,46.5,141.1,89.1
                c53.5,58.7,90.7,132.2,129.3,201s75.4,142,128.2,201.3c18.8,21.1,41,41.7,68,51.7c31.3,11.6,64.4,4.3,93.8-9
                c69-31.3,126.1-87.6,183.8-135.2c52-42.8,117.6-99.3,189.4-77.6"/>
              <path class="st2" d="M-260.8-183.2c118.8,56.6,219.9,141.7,333,208C124.7,55.6,181,74.9,221.9,122
                c50.4,58,86.4,128.1,123.7,194.7c37.3,66.5,72.9,136.4,122.4,195c46.8,55.4,101.4,81.2,171,48.9C707.2,529,763.8,474,821,426.8
                c51.5-42.6,116-98.9,187.5-77.8"/>
              <path class="st2" d="M-261.6-176.6c118.2,57.1,220,140.7,333.1,206.4c52.6,30.5,109,50.3,149.9,97.2
                c50.5,58,86.5,128.2,123.9,194.8c37.3,66.6,72.9,136.5,122.2,195.2c46.6,55.4,101.2,82.1,171.1,50.5
                c68.2-30.9,125.2-85.4,182-132.8c51.4-42.7,115.5-99.9,187.1-79.2"/>
              <path class="st2" d="M-262.4-170c117.5,57.6,220,139.5,333.2,204.5c52.7,30.3,109.2,50.3,150.1,97.2
                c50.6,58,86.6,128.3,124,195.1c37.3,66.6,72.8,136.6,122.1,195.5c46.3,55.3,101,83,171.1,52.1
                c68.3-30.2,125.6-84.3,182.1-131.6c51.2-42.9,115-100.8,186.7-80.5"/>
              <path class="st2" d="M-263.3-163.3c116.9,57.9,220.2,138,333.4,202.2c52.9,30,109.4,50.4,150.3,97.2
                c50.7,58.1,86.7,128.5,124.1,195.3c37.3,66.7,72.8,136.7,121.9,195.8c46,55.3,100.8,84,171.1,53.7
                c68.4-29.5,125.9-83.1,182.2-130.4c51.1-43,114.4-101.6,186.3-81.7"/>
              <path class="st2" d="M-264.1-156.7C-147.8-98.6-43.8-20.2,69.5,43.1c53.1,29.7,109.6,50.3,150.5,97.2
                c50.7,58.1,86.9,128.6,124.3,195.6C381.6,402.7,417,472.8,466,532.1c45.7,55.3,100.6,85,171.1,55.4
                c68.5-28.7,126.3-81.8,182.3-129.2c51-43.1,113.9-102.4,185.9-82.8"/>
              <path class="st2" d="M-264.9-150.1C-149.3-91.8-44.4-15.3,68.9,47c53.3,29.3,109.8,50.3,150.7,97.1
                c50.8,58.2,87,128.8,124.4,195.9c37.3,66.9,72.7,137.1,121.5,196.6c45.3,55.3,100.3,86,171.1,57.2
                c68.5-28,126.7-80.5,182.3-127.8c50.8-43.2,113.4-103.2,185.5-83.9"/>
              <path class="st2" d="M-265.8-143.5C-150.7-85-45.1-10.6,68.3,50.8c53.5,28.9,110,50.1,150.9,97
                c50.9,58.3,87.1,129.1,124.5,196.3C381,411,416.3,481.3,465,541.1c45,55.3,100.1,87.1,171,59c68.6-27.2,127-79.2,182.4-126.4
                c50.7-43.3,112.9-103.9,185.1-84.9"/>
              <path class="st2" d="M-266.6-136.9C-152.1-78.4-45.7-6,67.8,54.3c53.7,28.5,110.2,49.9,151.1,96.8
                c50.9,58.4,87.2,129.3,124.6,196.7c37.3,67.2,72.6,137.5,121.1,197.5c44.7,55.4,99.8,88.2,171,60.8
                C704.3,579.7,763,528.3,818,481.2c50.6-43.4,112.3-104.5,184.7-85.8"/>
              <path class="st2" d="M-267.4-130.2C-153.5-71.8-46.2-1.5,67.2,57.6c54,28.1,110.5,49.7,151.3,96.6
                c51,58.6,87.3,129.6,124.7,197.1c37.3,67.3,72.5,137.7,120.9,198c44.4,55.4,99.5,89.3,171,62.7
                c68.7-25.5,127.7-76.3,182.4-123.4c50.5-43.4,111.8-105.2,184.2-86.7"/>
              <path class="st2" d="M-268.2-123.6C-154.9-65.3-46.8,2.8,66.8,60.6C121,88.3,177.4,110.1,218.3,157
                c51.1,58.7,87.4,129.9,124.9,197.6c37.3,67.4,72.5,138,120.7,198.6c44.1,55.4,99.3,90.4,171,64.7
                c68.8-24.6,128-74.8,182.4-121.8c50.4-43.5,111.3-105.7,183.8-87.4"/>
              <path class="st2" d="M-269.1-117C-156.2-58.9-47.3,6.9,66.3,63.5c54.6,27.2,110.9,49.1,151.8,96.1
                c51.1,58.8,87.5,130.2,125,198c37.3,67.6,72.4,138.2,120.4,199.1c43.8,55.4,99,91.5,170.9,66.7
                c68.8-23.8,128.3-73.2,182.5-120.1c50.3-43.5,110.8-106.3,183.3-88.1"/>
              <path class="st2" d="M-269.9-110.4C-157.5-52.5-47.8,10.9,65.8,66.2c54.9,26.7,111.1,48.7,152,95.8
                c51.2,59,87.6,130.5,125.1,198.5c37.3,67.8,72.3,138.5,120.2,199.7c43.5,55.5,98.7,92.7,170.9,68.7
                c68.8-22.8,128.6-71.6,182.5-118.4c50.2-43.5,110.3-106.7,182.9-88.7"/>
              <path class="st2" d="M-270.7-103.8C-158.8-46.2-48.3,14.8,65.4,68.7c55.2,26.1,111.3,48.3,152.2,95.5
                c51.3,59.1,87.7,130.8,125.2,199c37.3,67.9,72.3,138.8,120,200.3c43.2,55.5,98.4,93.8,170.8,70.8
                c68.8-21.9,128.8-69.9,182.5-116.5c50.1-43.5,109.8-107.2,182.4-89.3"/>
              <path class="st2" d="M-271.6-97.1C-160.1-39.9-48.8,18.6,65,71c55.6,25.6,111.5,47.8,152.5,95.1
                c51.3,59.3,87.8,131.2,125.3,199.6c37.3,68.1,72.2,139.1,119.8,200.9c42.9,55.6,98.1,95,170.8,72.9
                c68.8-20.9,129.1-68.2,182.4-114.7c50-43.5,109.3-107.6,181.9-89.8"/>
              <path class="st2" d="M-272.4-90.5C-161.4-33.7-49.2,22.3,64.7,73.1c55.9,25,111.8,47.3,152.7,94.7
                c51.4,59.5,87.9,131.5,125.4,200.1c37.3,68.3,72.2,139.5,119.6,201.5c42.6,55.7,97.8,96.2,170.7,75.1
                c68.9-19.9,129.3-66.4,182.4-112.7c49.9-43.5,108.8-107.9,181.4-90.2"/>
              <path class="st2" d="M-273.2-83.9C-162.6-27.5-49.7,25.8,64.3,75.1c56.3,24.4,112,46.8,153,94.3
                c51.5,59.6,88,131.9,125.5,200.6c37.3,68.4,72.1,139.8,119.3,202.1c42.3,55.7,97.6,97.3,170.6,77.3
                c68.9-18.9,129.5-64.6,182.4-110.7C865,495.3,923.4,430.6,996,448.3"/>
              <path class="st2" d="M-274.1-77.3C-163.7-21.3-50.1,29.2,64,76.9c56.7,23.7,112.2,46.2,153.2,93.8
                C268.7,230.6,305.3,303,342.7,372c37.2,68.6,72,140.1,119.1,202.7c42,55.8,97.3,98.5,170.6,79.5
                c68.8-17.8,129.7-62.7,182.4-108.6c49.8-43.4,107.8-108.4,180.4-90.7"/>
              <path class="st2" d="M-274.9-70.7C-164.9-15.2-50.5,32.5,63.7,78.6c57.1,23.1,112.4,45.6,153.5,93.4
                c51.6,60,88.2,132.6,125.6,201.8c37.2,68.8,72,140.4,118.9,203.4c41.7,55.9,97,99.7,170.5,81.8
                c68.8-16.8,129.9-60.7,182.3-106.5c49.7-43.4,107.3-108.6,179.9-90.9"/>
              <path class="st2" d="M-275.7-64C-166-9.1-50.9,35.7,63.4,80.1C120.9,102.5,176,125,217.1,173c51.7,60.2,88.3,133,125.7,202.3
                c37.2,69,71.9,140.8,118.7,204c41.4,56,96.7,100.9,170.4,84.1c68.8-15.7,130-58.7,182.3-104.2c49.6-43.3,106.8-108.8,179.3-91"
                />
              <path class="st2" d="M-276.5-57.4C-167.1-3.1-51.3,38.8,63.1,81.5c58,21.7,112.8,44.2,154,92.3
                c51.7,60.3,88.4,133.3,125.8,202.9c37.2,69.2,71.8,141.1,118.4,204.7c41.1,56.1,96.5,102.1,170.3,86.4
                c68.8-14.6,130.2-56.6,182.2-102c49.6-43.2,106.3-108.9,178.8-91"/>
              <path class="st2" d="M-277.4-50.8C-168.1,3-51.7,41.8,62.8,82.8c58.4,20.9,113,43.5,154.3,91.7
                C268.9,235,305.6,308.1,343,377.9c37.2,69.4,71.8,141.4,118.2,205.3c40.9,56.2,96.2,103.2,170.3,88.8
                c68.7-13.4,130.3-54.5,182.1-99.6c49.5-43.1,105.8-109,178.2-91"/>
              <path class="st2" d="M-278.2-44.2C-169.1,9-52.1,44.7,62.6,83.9c58.8,20.1,113.2,42.7,154.6,91.1c51.8,60.7,88.6,134,126,204
                c37.2,69.5,71.7,141.8,118,205.9c40.6,56.3,96,104.4,170.2,91.2c68.7-12.2,130.3-52.3,182-97.2c49.4-43,105.3-109,177.7-90.8"
                />
              <path class="st2" d="M-279-37.6C-170,15-52.4,47.5,62.3,84.9c59.3,19.3,113.5,41.9,154.8,90.4
                c51.9,60.9,88.7,134.4,126.1,204.5c37.2,69.7,71.6,142.1,117.8,206.5c40.4,56.4,95.8,105.6,170.1,93.6
                c68.6-11.1,130.4-50,181.9-94.7c49.4-42.8,104.8-109,177.1-90.6"/>
              <path class="st2" d="M-279.9-31c109,52,227,81.2,342,116.8c59.7,18.5,113.7,41.1,155.1,89.7c52,61,88.8,134.7,126.2,205.1
                C380.6,450.5,415,523,461,587.8c40.2,56.5,95.5,106.7,170,96c68.5-9.8,130.4-47.7,181.9-92.2c49.3-42.7,104.3-108.9,176.5-90.4
                "/>
              <path class="st2" d="M-280.7-24.3c109,51.3,227.5,77.2,342.6,111c60.2,17.7,113.9,40.2,155.4,89c52,61.2,88.9,135,126.3,205.6
                C380.8,451.3,415.1,524,461,589c40,56.7,95.3,107.9,169.9,98.5c68.4-8.6,130.5-45.3,181.7-89.6c49.3-42.5,103.8-108.8,176-90"
                />
              <path class="st2" d="M-281.5-17.7c109,50.7,228,73.2,343.2,105.1c60.6,16.8,114.1,39.3,155.7,88.3
                c52.1,61.3,89,135.4,126.4,206.1c37.2,70.2,71.5,143,117.1,208.3c39.8,56.8,95.1,109,169.8,101c68.3-7.3,130.5-42.9,181.6-86.9
                c49.3-42.3,103.3-108.6,175.4-89.6"/>
              <path class="st2" d="M-282.3-11.1C-173.3,38.9-53.9,58,61.5,88c61,15.9,114.3,38.4,156,87.5C269.7,237,306.6,311.2,344,382.1
                c37.1,70.4,71.4,143.3,116.9,208.8c39.6,57,95,110.1,169.7,103.5c68.2-6.1,130.4-40.4,181.5-84.2
                C861.3,568,915,501.8,986.9,521.1"/>
              <path class="st2" d="M-283.2-4.5c109.2,49.4,229,65,344.5,93.1c61.5,15,114.5,37.4,156.3,86.7c52.2,61.6,89.2,136,126.6,207
                c37.1,70.5,71.3,143.6,116.7,209.3c39.4,57.1,94.8,111.2,169.6,106c68-4.8,130.4-37.8,181.4-81.4
                c49.2-42,102.4-108.1,174.1-88.6"/>
              <path class="st2" d="M-284,2.1c109.4,48.7,229.4,60.7,345.2,87c61.9,14,114.7,36.4,156.6,85.9
                c52.3,61.7,89.4,136.2,126.8,207.4c37,70.6,71.2,143.8,116.4,209.8c39.2,57.3,94.7,112.3,169.5,108.6
                c67.9-3.4,130.3-35.2,181.2-78.5c49.2-41.8,101.9-107.8,173.5-87.9"/>
              <path class="st2" d="M-284.8,8.7C-175.2,56.8-54.9,65.2,61,89.5c62.3,13.1,114.9,35.4,156.9,85
                c52.4,61.8,89.5,136.5,126.9,207.8c37.1,70.8,71.2,144.1,116.3,210.3c39.1,57.5,94.6,113.4,169.4,111.1
                c67.7-2.1,130.1-32.5,181.1-75.6c49.2-41.5,101.4-107.4,172.9-87.3"/>
              <path class="st2" d="M-285.7,15.4C-174.8,63.2-53.3,67.3,63.8,90.5c57.8,11.5,108.6,32,148.4,76.7
                c55.8,62.7,94.3,141.1,132.9,214.9c38.5,73.5,73.6,150.2,121.3,218.4C503.8,654,557.5,706.2,627,706.6
                c68.1,0.4,131.5-28.4,183-71.4c49.6-41.4,101.8-108.3,173.7-87.7"/>
              <path class="st2" d="M-286.5,22C-175.3,69.2-53.7,69.5,63.6,90.8c58.1,10.6,108.8,31,148.7,75.8
                c55.8,62.7,94.4,141.3,133,215.3c38.5,73.7,73.6,150.4,121.1,218.8c37.3,53.8,91,106.8,160.5,108.6
                c67.9,1.7,131.3-25.5,182.8-68.3c49.6-41.1,101.3-107.9,173-86.8"/>
              <path class="st2" d="M-287.3,28.6C-175.7,75.1-54,71.6,63.5,91c58.5,9.6,109,30,149,74.9c55.9,62.8,94.5,141.5,133.2,215.6
                c38.5,73.8,73.5,150.5,120.9,219.2c37.3,54,90.9,107.9,160.4,111.1c67.6,3.1,131.1-22.6,182.6-65.1
                c49.6-40.8,100.8-107.4,172.4-85.9"/>
              <path class="st2" d="M-288.1,35.2c112,45.9,233.8,38.6,351.5,56c58.8,8.7,109.1,29,149.3,74C268.7,228,307.4,306.9,346,381
                c38.5,73.9,73.5,150.7,120.7,219.5c37.2,54.3,90.9,108.9,160.3,113.6c67.4,4.5,130.8-19.6,182.4-61.8
                c49.6-40.6,100.3-106.8,171.7-84.9"/>
              <path class="st2" d="M-289,41.8C-176.5,87-54.7,75.9,63.2,91.4c59.1,7.8,109.3,27.9,149.6,73c56.1,62.9,94.8,141.8,133.5,216
                c38.5,73.9,73.4,150.8,120.5,219.8c37.2,54.6,90.8,110,160.2,116.2c67.1,6,130.5-16.6,182.3-58.5
                c49.6-40.3,99.9-106.2,171-83.9"/>
              <path class="st2" d="M-289.8,48.4C-176.8,93-55.1,78,63.1,91.6c59.4,6.8,109.5,26.9,149.9,72c56.2,62.9,95,141.9,133.6,216.2
                c38.5,74,73.4,150.9,120.3,220.1c37.1,54.8,90.8,111,160.1,118.6c66.8,7.4,130.2-13.5,182.1-55.2
                c49.7-39.9,99.4-105.5,170.3-82.7"/>
              <path class="st2" d="M-290.6,55C-177.1,98.9-55.4,80.1,63,91.7c59.7,5.9,109.7,25.8,150.2,71.1c56.3,62.9,95.1,142,133.8,216.3
                c38.5,74,73.4,151,120.1,220.4c37.1,55.1,90.8,112,160,121.2c66.4,8.8,129.9-10.3,181.9-51.8c49.7-39.6,98.9-104.9,169.6-81.6"
                />
              <path class="st2" d="M-291.5,61.7C-177.4,105-55.8,82.2,62.8,92c60,4.9,109.9,24.7,150.5,70.1
                c56.3,62.9,95.2,142.1,133.9,216.5c38.5,74.1,73.3,151.1,119.9,220.7c37.1,55.4,90.8,113,159.9,123.7
                c66.1,10.2,129.5-7.3,181.7-48.5c49.8-39.3,98.4-104.2,168.9-80.5"/>
              <path class="st2" d="M-292.3,68.3c114.7,42.6,236.1,16,355,23.9c60.2,4,110.1,23.7,150.8,69.2c56.4,63,95.4,142.2,134.1,216.8
                c38.5,74.2,73.3,151.3,119.7,221c37.1,55.8,90.8,114.1,159.8,126.2c65.7,11.5,129.2-4.2,181.5-45.2
                c49.8-39,97.9-103.7,168.3-79.5"/>
              <path class="st2" d="M-293.1,74.9c115.3,42,236.6,11.5,355.7,17.5c60.5,3,110.3,22.7,151.1,68.2c56.5,63,95.5,142.4,134.2,217
                c38.5,74.3,73.2,151.5,119.5,221.4c37.2,56.1,90.8,115.1,159.7,128.7c65.3,12.9,128.8-1.2,181.3-42.1
                c49.8-38.8,97.4-103.1,167.6-78.6"/>
              <path class="st2" d="M-293.9,81.5c116,41.4,237.1,7.1,356.4,11.1c60.8,2.1,110.4,21.7,151.4,67.4
                c56.6,63.1,95.6,142.6,134.3,217.4c38.5,74.4,73.2,151.7,119.3,221.8c37.2,56.6,90.7,116.2,159.6,131.2
                c65,14.2,128.5,1.7,181.2-38.9c49.9-38.5,96.9-102.6,167-77.8"/>
              <path class="st2" d="M-294.8,88.1C-178.1,129-57.2,90.8,62.3,93c61.1,1.1,110.6,20.7,151.7,66.5
                c56.7,63.2,95.8,142.9,134.5,217.7c38.5,74.5,73.2,151.9,119.1,222.3c37.2,57,90.7,117.2,159.5,133.6
                c64.6,15.4,128.1,4.7,181-35.9c49.9-38.2,96.4-102.2,166.3-77"/>
              <path class="st2" d="M-295.6,94.7c117.3,40.3,238-1.7,357.8-1.4c61.3,0.2,110.8,19.7,152,65.7
                c56.7,63.3,95.9,143.1,134.6,218.1c38.5,74.7,73.1,152.2,118.9,222.8C505,657.4,558.3,718.2,627.1,736
                c64.2,16.6,127.8,7.5,180.9-32.9c49.9-38,95.8-101.8,165.7-76.3"/>
              <path class="st2" d="M-296.4,101.3c116.9,39.4,236.6-5,355.4-7.5c66.9-1.4,117.5,20.3,161.3,71.7
                c53.4,62.8,91.2,138.9,128.7,211.7c37.1,72.1,70.6,146.5,113.6,215.3c38.6,61.8,94.5,128,167.8,147.4
                c63.2,16.7,126.2,8.3,178.7-31.9c49.5-37.8,94.5-100.3,163.7-74.6"/>
              <path class="st2" d="M-297.3,108c117.6,38.9,237.1-9.3,356.1-13.5c67.2-2.4,117.7,19.3,161.6,70.9
                c53.5,62.9,91.3,139.2,128.8,212.1c37.1,72.2,70.5,146.8,113.4,215.8c38.7,62.3,94.4,129.1,167.6,150
                c62.8,17.9,125.8,11,178.6-29.1c49.5-37.6,94-100,163.1-74"/>
              <path class="st2" d="M-298.1,114.6C-179.8,153-60.5,101.1,58.6,95.1c67.5-3.4,117.9,18.3,161.9,70.1
                c53.5,63,91.4,139.5,128.9,212.6c37.1,72.4,70.5,147.1,113.2,216.4C501.3,657,557,724.4,630.1,746.7
                c62.4,19,125.5,13.7,178.5-26.4c49.5-37.4,93.5-99.7,162.5-73.6"/>
              <path class="st2" d="M-298.9,121.2c119,37.9,238.1-17.7,357.4-25.4c27.2-1.8,55.1-0.3,81.2,7.9c32.9,10.3,59.1,35.8,81,61.5
                c53.6,63.2,91.6,139.8,129,213.1c37,72.5,70.4,147.4,113,216.9c38.8,63.4,94.3,131.3,167.4,155c62,20.1,125.2,16.3,178.4-23.7
                c49.5-37.3,92.9-99.5,161.9-73.1"/>
              <path class="st2" d="M-299.8,127.8C-180,165.3-61.2,106,58.2,96.6c27.2-2.1,55.2-1,81.4,7c33,10.2,59.3,35.9,81.1,61.6
                c53.7,63.3,91.7,140.1,129.1,213.6c37,72.7,70.4,147.7,112.8,217.5c38.8,64,94.3,132.3,167.3,157.4
                c61.6,21.2,124.9,18.9,178.3-21.1c49.5-37.1,92.4-99.4,161.3-72.8"/>
              <path class="st2" d="M-300.6,134.4c120.4,37,239.1-25.8,358.6-36.9c27.2-2.5,55.3-1.8,81.6,6.2c33,10,59.4,36,81.1,61.8
                C274.5,229,312.5,306,349.9,379.7c37,72.9,70.3,148,112.5,218.1c38.9,64.5,94.2,133.4,167.2,159.9
                c61.2,22.2,124.6,21.5,178.2-18.5c49.5-37,91.9-99.3,160.7-72.5"/>
              <path class="st2" d="M-301.4,141c121.2,36.7,239.6-29.8,359.2-42.5c27.2-2.9,55.4-2.5,81.8,5.5c33.1,9.9,59.5,36.2,81.2,61.9
                c53.8,63.7,91.9,140.8,129.3,214.7c37,73,70.2,148.4,112.3,218.7c38.9,65.1,94.2,134.5,167.2,162.3
                c60.7,23.2,124.3,24,178.1-16.1c49.5-36.8,91.3-99.2,160.1-72.3"/>
              <path class="st2" d="M-302.2,147.7c121.8,36.3,240-33.7,359.7-48c27.2-3.2,55.5-3.1,82,4.7c33.1,9.8,59.6,36.3,81.3,62
                c53.9,63.8,92,141.2,129.4,215.2c37,73.2,70.2,148.7,112.1,219.4c39,65.7,94.1,135.5,167.1,164.7c60.3,24.1,124,26.4,178-13.7
                c49.5-36.7,90.8-99.2,159.6-72.2"/>
              <path class="st2" d="M-303.1,154.3c122.5,36,240.6-37.5,360.3-53.3c27.2-3.6,55.6-3.8,82.1,4c33.2,9.7,59.7,36.5,81.4,62.2
                c53.9,64,92.1,141.5,129.5,215.8c37,73.4,70.1,149.1,111.9,220c39.1,66.3,94.1,136.6,167,167c59.9,25,123.7,28.8,177.9-11.3
                c49.5-36.6,90.2-99.3,159-72.2"/>
              <path class="st2" d="M-303.9,160.9c123.2,35.7,241.1-41.3,360.9-58.5c27.2-3.9,55.7-4.4,82.3,3.3c33.2,9.6,59.9,36.6,81.5,62.3
                c54,64.2,92.2,141.9,129.6,216.3c37,73.6,70,149.5,111.6,220.6c39.1,66.9,94,137.6,166.9,169.4c59.5,25.9,123.5,31.2,177.9-9
                c49.5-36.5,89.6-99.4,158.5-72.2"/>
              <path class="st2" d="M-304.7,167.5c123.9,35.5,241.6-44.9,361.4-63.6c27.2-4.2,55.8-5.1,82.5,2.6c33.2,9.5,60,36.8,81.6,62.5
                c54,64.3,92.3,142.2,129.7,216.9c37,73.8,70,149.8,111.4,221.3c39.2,67.6,94,138.6,166.8,171.7c59,26.8,123.2,33.5,177.8-6.8
                c49.4-36.5,89.1-99.6,157.9-72.3"/>
              <path class="st2" d="M-305.6,174.1c124.5,35.3,242.1-48.5,361.9-68.6c27.2-4.6,55.9-5.7,82.7,1.9c33.3,9.4,60.1,36.9,81.6,62.6
                c54.1,64.5,92.4,142.6,129.8,217.5c36.9,74,69.9,150.2,111.2,221.9c39.3,68.2,93.9,139.7,166.7,173.9
                c58.6,27.6,123,35.8,177.8-4.7c49.4-36.4,88.5-99.8,157.4-72.5"/>
              <path class="st2" d="M-306.4,180.7c125.1,35.1,242.6-51.9,362.4-73.4c27.2-4.9,56-6.2,82.9,1.3c33.3,9.4,60.2,37.1,81.7,62.8
                c54.2,64.7,92.5,143,129.9,218c36.9,74.2,69.8,150.5,111,222.5c39.3,68.8,93.9,140.7,166.7,176.2c58.2,28.4,122.7,38,177.7-2.6
                c49.4-36.4,87.9-100,156.9-72.7"/>
              <path class="st2" d="M-307.2,187.4c125.7,35,243.1-55.3,362.9-78c27.2-5.2,56.1-6.8,83,0.7c33.4,9.3,60.3,37.2,81.8,62.9
                c54.2,64.9,92.6,143.3,130,218.6c36.9,74.3,69.8,150.9,110.7,223.2c39.4,69.5,93.8,141.7,166.6,178.4
                c57.8,29.1,122.5,40.1,177.7-0.6c49.3-36.4,87.3-100.3,156.4-73"/>
              <path class="st2" d="M-308,194c126.3,35,243.5-58.6,363.3-82.5c27.2-5.4,56.2-7.3,83.2,0.2c33.4,9.3,60.4,37.3,81.9,63.1
                c54.3,65,92.7,143.7,130.1,219.1c69,139.3,129.4,327.4,277.1,404.3c57.4,29.9,122.3,42.3,177.7,1.4
                c49.2-36.4,86.7-100.7,155.9-73.4"/>
              <path class="st2" d="M-308.9,200.6c126.8,35,244-61.7,363.8-86.8c27.2-5.7,56.3-7.8,83.4-0.3c33.4,9.2,60.5,37.5,82,63.3
                c54.3,65.2,92.8,144,130.2,219.6c69.3,140.4,129.3,327.9,276.8,407c57,30.6,122.2,44.3,177.7,3.3
                c49.2-36.4,86.2-101.1,155.4-73.9"/>
              <path class="st2" d="M-309.7,207.2c127.3,35.1,244.5-64.7,364.2-90.9c27.2-6,56.3-8.3,83.5-0.8c33.5,9.2,60.5,37.6,82,63.4
                c54.5,65.3,93,144.3,130.3,220.1C420,540.6,479.6,727.4,626.9,808.7c56.6,31.2,122,46.3,177.7,5.1
                c49.1-36.4,85.6-101.6,154.9-74.4"/>
              <path class="st2" d="M-310.5,213.8c127.8,35.2,245-67.6,364.6-94.9c27.2-6.2,56.4-8.8,83.7-1.3c33.5,9.2,60.6,37.7,82.1,63.5
                c54.5,65.5,93.1,144.7,130.4,220.6c70,142.6,129.2,328.8,276.3,412.3c56.2,31.9,121.8,48.3,177.7,6.8
                c49-36.4,85-102.1,154.5-75"/>
              <path class="st2" d="M-311.4,220.5c128.2,35.4,245.4-70.4,364.9-98.6c27.2-6.4,56.5-9.2,83.8-1.7c33.6,9.2,60.7,37.8,82.2,63.7
                C274,249.5,312.7,328.9,350,405c70.4,143.7,129.2,329.3,276,414.9c55.8,32.5,121.7,50.2,177.7,8.5c49-36.5,84.4-102.6,154-75.7
                "/>
              <path class="st2" d="M-312.2,227.1c128.6,35.7,245.9-73,365.3-102.2c27.2-6.6,56.6-9.6,83.9-2c33.6,9.3,60.8,37.9,82.3,63.8
                c54.6,65.8,93.3,145.2,130.6,221.6C420.7,553,479.1,738.1,625.7,825.6c55.4,33.1,121.6,52.1,177.7,10.1
                c48.9-36.6,83.8-103.3,153.6-76.5"/>
              <path class="st2" d="M-313,233.7c128.9,36,246.3-75.5,365.6-105.5c27.2-6.8,56.6-9.9,84.1-2.3c33.6,9.3,60.8,38,82.4,63.9
                c54.7,65.9,93.4,145.5,130.7,222C420.8,557.5,479,742,625.3,831.6c55,33.7,121.5,53.9,177.7,11.7
                c48.8-36.7,83.1-103.9,153.1-77.3"/>
              <path class="st2" d="M-313.9,240.3C-184.6,276.8-67.1,162.4,52,131.7c27.2-7,56.7-10.2,84.2-2.6c33.7,9.4,60.9,38,82.5,64.1
                c54.7,66,93.5,145.8,130.8,222.4c71.4,146.7,129.2,330.6,275.3,422.1c54.6,34.2,121.4,55.7,177.8,13.1
                c48.7-36.8,82.5-104.7,152.7-78.3"/>
              <path class="st2" d="M-314.7,247c129.5,36.9,247.2-80.1,366.1-111.5c27.2-7.2,56.8-10.5,84.3-2.8c33.7,9.4,60.9,38.1,82.5,64.2
                c54.8,66.1,93.7,146,130.9,222.8C421,567.2,478.6,750.6,624.4,843.8c54.3,34.7,121.4,57.4,177.8,14.6
                c48.6-36.9,81.9-105.4,152.3-79.3"/>
              <path class="st2" d="M-315.5,253.6c129.7,37.5,247.6-82.1,366.4-114.2c27.3-7.4,56.9-10.8,84.5-3c33.7,9.5,61,38.1,82.6,64.2
                c54.9,66.2,93.8,146.2,131.1,223.1C421.2,572.2,478.4,755.1,624,850.2c53.9,35.2,121.3,59.1,177.9,15.9
                c48.4-37,81.3-106.3,151.9-80.3"/>
              <path class="st2" d="M-316.3,260.2c129.9,38.2,248-84,366.6-116.6c27.3-7.5,56.9-11,84.6-3.1c33.8,9.6,61,38.1,82.7,64.3
                c55,66.3,93.9,146.4,131.2,223.4C421.2,577.6,478.2,760,623.5,856.7c53.6,35.7,121.3,60.7,177.9,17.2
                c48.3-37.2,80.7-107.2,151.5-81.5"/>
              <path class="st2" d="M-317.2,266.8c65.9,19.7,129.7-6.6,188.4-35.8c58.3-29,115.1-65.4,178.3-83c27.3-7.6,57-11.2,84.7-3.2
                c33.8,9.8,61,38.1,82.8,64.4c55,66.3,94,146.5,131.3,223.6C421,583.1,477.8,764.9,622.8,863.3c53.3,36.2,121.3,62.3,178,18.4
                c48.2-37.4,80-108.1,151.1-82.7"/>
              <path class="st2" d="M-318,273.4c66.5,20.3,130.9-7.2,190-37.1c58.7-29.8,116-67.3,180.1-84.5c27.5-7.4,57.6-10.5,85.1-1.2
                c29.2,9.9,53.3,33.3,73.2,56.1c58.6,67,99.2,151.4,137.6,231C420.6,588,476.5,767,619.1,867.9c53,37.5,122.3,67,180.1,22.7
                c48.7-37.3,80.1-110.2,151.9-85"/>
              <path class="st2" d="M-318.8,280.1c66.6,20.8,131.1-7.2,190-37.5c58.7-30.2,116-68.3,180.3-85.8c65-17.7,115.3,5.6,158.5,54.9
                c58.7,67.1,99.3,151.5,137.8,231.2C420.7,594,476.4,772.4,618.7,874.8c52.7,37.9,122.3,68.5,180.2,23.8
                c48.6-37.5,79.4-111.2,151.6-86.4"/>
              <path class="st2" d="M-319.7,286.7c66.6,21.3,131.2-7.2,189.9-37.8s116-69.2,180.4-86.9c65-17.9,115.5,5.8,158.6,55
                c58.8,67.1,99.5,151.6,138,231.3C420.4,600.1,476,778,618,881.8c52.4,38.3,122.4,70,180.3,24.9
                c48.5-37.8,78.8-112.3,151.2-87.8"/>
              <path class="st2" d="M-320.5,293.3c66.6,21.9,131.2-7,189.9-37.9s115.9-70.1,180.5-87.9c64.9-18,115.7,6.1,158.8,55.1
                c58.8,67,99.7,151.6,138.1,231.4c73.5,152.5,128.9,329.8,270.7,435c52.1,38.7,122.5,71.4,180.4,25.8
                c48.3-38,78.1-113.5,150.9-89.4"/>
              <path class="st2" d="M-321.3,299.9c66.5,22.5,131.3-6.8,189.8-37.9c58.7-31.2,115.9-70.8,180.5-88.8
                c64.8-18,115.9,6.4,159,55.3c58.9,67,99.8,151.6,138.3,231.4C420.1,613,475.4,789.8,616.8,896.4c51.8,39,122.6,72.8,180.5,26.7
                c48.2-38.3,77.5-114.7,150.5-91"/>
              <path class="st2" d="M-322.2,306.6c66.5,23.2,131.3-6.4,189.7-37.8c58.6-31.5,115.8-71.5,180.6-89.5
                c64.7-18,116.2,6.9,159.1,55.5c59,66.9,100,151.5,138.5,231.3C419.9,619.9,475.1,796,616.2,903.9
                c51.5,39.4,122.7,74.1,180.6,27.5c48-38.6,76.8-115.9,150.2-92.7"/>
              <path class="st2" d="M-323,313.2c66.5,23.5,131.4-5.9,190-37.3s115.7-71.3,180.5-89.1c64.4-17.7,116,7.3,158.8,55.6
                c59,66.6,99.8,150.9,138.3,230.4c74,152.9,129.7,327.7,270.2,435c51.4,39.3,122.5,74.2,180.7,28.5
                c48.5-38.1,77.5-114.1,150.6-90.9"/>
              <path class="st2" d="M-323.8,319.8c66.6,23.7,131.6-5.6,190.4-36.9c58.5-31.3,115.7-71.1,180.3-88.7
                c64.1-17.4,115.8,7.7,158.5,55.7c58.9,66.2,99.7,150.2,138.1,229.4c73.8,152,130.1,325.5,269.8,432
                c51.3,39.1,122.3,74.1,180.8,29.4c49.1-37.5,78.3-112,151.2-88.8"/>
              <path class="st2" d="M-324.6,326.5c66.7,23.9,131.9-5.3,190.7-36.7c58.5-31.2,115.6-71,180.2-88.4
                c63.8-17.1,115.6,8.1,158.1,55.8c58.9,65.9,99.5,149.7,137.9,228.5c73.6,151.2,130.4,323.4,269.5,429.2
                c51.2,39,122.1,74.1,180.9,30.4c49.6-36.9,79.1-109.9,151.7-86.7"/>
              <path class="st2" d="M-325.5,333.1c66.8,24,132.1-5.1,191.1-36.6c58.4-31.1,115.5-71,180.2-88.2
                c63.6-16.9,115.4,8.4,157.8,55.8c58.8,65.7,99.3,149.1,137.7,227.8C414.6,642.3,472,813.3,610.4,918.5
                c51.1,38.9,121.9,74.1,181,31.4c50.1-36.2,79.9-107.8,152.3-84.6"/>
              <path class="st2" d="M-326.3,339.7c67,24.2,132.3-5,191.5-36.5c58.4-31.1,115.4-71,180.1-88.1c63.4-16.7,115.1,8.7,157.5,55.9
                c58.7,65.4,99.1,148.7,137.4,227.1c73.1,149.6,131,319.5,268.7,424c51,38.7,121.7,74.1,181.1,32.3
                c50.6-35.6,80.7-105.8,152.8-82.6"/>
              <path class="st2" d="M-327.1,346.3c67.1,24.2,132.6-5,191.9-36.6c58.3-31.1,115.4-71.1,180-88c63.2-16.5,114.9,9,157.1,55.9
                c58.7,65.2,98.9,148.2,137.2,226.5C411.9,653,470.3,821.7,607.5,925.6c51,38.6,121.5,74.1,181.2,33.3
                c51.1-34.9,81.5-103.7,153.4-80.5"/>
              <path class="st2" d="M-327.9,352.9c67.3,24.3,132.9-5,192.4-36.7c58.3-31.1,115.3-71.2,179.9-88.1c63-16.4,114.7,9.2,156.8,56
                c58.6,65,98.7,147.9,136.9,225.9c72.6,148.2,131.3,315.9,268,419.2c50.9,38.5,121.3,74,181.3,34.3
                c51.6-34.3,82.3-101.6,153.9-78.4"/>
              <path class="st2" d="M-328.8,359.6c67.5,24.3,133.2-5.1,192.8-36.9c58.3-31.1,115.2-71.4,179.9-88.1
                c62.9-16.3,114.5,9.4,156.5,56C258.9,355.5,298.9,438.1,337,516c72.3,147.6,131.4,314.2,267.5,416.9
                c50.8,38.4,121.2,74,181.4,35.2c52.1-33.6,83.1-99.5,154.4-76.3"/>
              <path class="st2" d="M-329.6,366.2c67.6,24.4,133.5-5.2,193.2-37.1c58.2-31.2,115.2-71.6,179.8-88.3
                c62.7-16.2,114.2,9.6,156.1,56.1C258,361.6,297.9,444,336,521.7c72,147,131.5,312.5,267.1,414.6c50.8,38.2,121,74,181.5,36.2
                c52.6-32.9,83.9-97.4,155-74.2"/>
              <path class="st2" d="M-330.4,372.8c67.8,24.4,133.8-5.3,193.6-37.4c58.2-31.2,115.1-71.8,179.8-88.5
                c62.6-16.1,114,9.8,155.8,56.1c58.3,64.6,98.1,146.9,136.1,224.5C406.6,673.9,466.5,838.4,601.6,940
                c50.7,38.1,120.8,73.9,181.6,37.1c53.1-32.1,84.7-95.3,155.5-72.1"/>
              <path class="st2" d="M-331.3,379.4C-197.4,427.3-79.1,284.2,42.6,253c62.5-16,113.8,9.9,155.5,56.1
                c58.2,64.5,97.9,146.7,135.8,224.1C405.3,679,465.5,842.5,600.2,943.5c50.7,38,120.7,73.9,181.6,38.1
                c53.6-31.4,85.5-93.2,156.1-70"/>
              <path class="st2" d="M-332.1,386.1C-197.8,433.9-79.8,290.3,42.2,259c62.3-16,113.5,10.1,155.2,56.1
                c58.2,64.4,97.7,146.4,135.5,223.7C404,684.1,464.5,846.7,598.7,947.1c50.7,37.9,120.5,73.8,181.7,39.1
                c54-30.7,86.3-91.1,156.6-68"/>
              <path class="st2" d="M-332.9,392.7C-198.2,440.5-80.5,296.3,41.8,265c62.2-15.9,113.3,10.2,154.8,56.2
                c58.1,64.3,97.4,146.2,135.3,223.4c70.9,144.7,131.7,306.3,265.4,406.2c50.7,37.8,120.3,73.8,181.8,40
                c54.5-29.9,87.1-89.1,157.2-65.9"/>
              <path class="st2" d="M-333.7,399.3c135.2,47.8,252.5-97,375.1-128.4c62.1-15.9,113.1,10.3,154.5,56.2
                c58,64.2,97.2,145.9,135,223c70.6,144.1,131.7,304.8,265,404.1c50.7,37.8,120.2,73.7,181.9,41c54.9-29.1,88-87,157.7-63.8"/>
              <path class="st2" d="M-334.6,405.9C-199,453.7-82,308.3,40.9,276.9c62-15.8,112.8,10.5,154.2,56.2
                c57.9,64.1,97,145.7,134.7,222.6c70.3,143.6,131.7,303.2,264.5,402c50.6,37.7,120,73.7,182,41.9
                c55.3-28.3,88.8-84.9,158.2-61.7"/>
              <path class="st2" d="M-335.4,412.5c136,47.7,252.7-98.3,375.9-129.6c61.9-15.8,112.6,10.6,153.8,56.3
                c57.8,64,96.8,145.5,134.4,222.3c70,143.1,131.7,301.7,264.1,400c50.6,37.6,119.9,73.6,182.1,42.9
                c55.8-27.5,89.6-82.8,158.8-59.6"/>
              <path class="st2" d="M-336.2,419.2c136.7,47.2,253-99.8,376.5-131.7c61.9-16,112.4,10.5,153.5,56.2
                c57.7,64.1,96.5,145.6,134,222.5C397.5,709.1,459.3,867.1,591.4,965c50.7,37.5,119.7,73.5,182.2,43.9
                c56.2-26.7,90.4-80.7,159.3-57.5"/>
              <path class="st2" d="M-337,425.8c137.3,46.7,253.2-101.3,377-133.5c61.9-16.1,112.1,10.4,153.2,56.2
                c57.6,64.2,96.3,145.7,133.7,222.6c69.3,142.5,131.2,300,263,397.4c50.7,37.4,119.6,73.5,182.3,44.8
                c56.5-25.8,91.3-78.6,159.9-55.4"/>
              <path class="st2" d="M-337.9,432.4C-200,478.8-84.5,329.9,39.7,297.3c61.9-16.2,111.8,10.3,152.9,56.2
                c57.5,64.3,96,145.7,133.4,222.7c69,142.1,131,298.9,262.5,395.9c50.7,37.4,119.5,73.4,182.4,45.8
                c56.9-25,92.1-76.5,160.4-53.4"/>
              <path class="st2" d="M-338.7,439c138.5,46.1,253.6-103.6,378.1-136.4c61.9-16.3,111.6,10.4,152.5,56.2
                c57.4,64.3,95.8,145.7,133,222.6c68.7,141.7,130.8,297.7,262,394.2c50.7,37.3,119.3,73.3,182.5,46.7
                c57.3-24.1,92.9-74.3,161-51.3"/>
              <path class="st2" d="M-339.5,445.7c139,45.9,253.8-104.4,378.6-137.4c61.8-16.3,111.4,10.4,152.2,56.3
                C248.6,428.9,286.8,510.2,324,587c68.4,141.3,130.7,296.4,261.5,392.4c50.8,37.2,119.2,73.3,182.6,47.7
                c24.8-10,45.6-26.8,68.6-39.9c31-17.7,59-20.8,92.9-9.3"/>
              <path class="st2" d="M-340.4,452.3c139.4,45.9,253.9-105,379-138c61.7-16.3,111.1,10.6,151.9,56.3
                c57.2,64.2,95.3,145.3,132.5,222c68.1,140.8,130.7,294.8,261.1,390.3c50.8,37.2,119.1,73.2,182.7,48.7
                c24.8-9.6,45.8-25.9,69-38.7c31.2-17.2,59.3-20,93.1-8.4"/>
              <path class="st2" d="M-341.2,458.9c139.8,46,253.9-105.3,379.3-138.2C99.6,304.6,149,331.5,189.6,377
                c57.1,64,95.1,144.9,132.2,221.5c67.9,140.2,130.8,293,260.7,387.9c50.9,37.1,119,73.1,182.7,49.6
                c24.9-9.2,45.9-25.3,69.3-37.4c31.7-16.5,59.4-19.3,93.3-7.6"/>
              <path class="st2" d="M-342,465.5c140,46.3,253.9-105.3,379.6-137.9c61.3-15.9,110.7,11.1,151.2,56.4
                c57,63.7,94.9,144.4,132,220.7c67.7,139.5,130.9,291,260.3,385.3c50.9,37.1,118.9,73,182.8,50.6c24.9-8.8,46.2-24.4,69.6-36.1
                c31.8-15.9,59.7-18.4,93.5-6.8"/>
              <path class="st2" d="M-342.8,472.1C-202.6,518.9-89,367.2,37,335.1c61-15.6,110.5,11.5,150.9,56.5
                c57,63.4,94.8,143.8,131.8,219.8C387.2,750,450.8,900,579.7,993.6c51,37,118.8,73,182.9,51.6c25-8.3,46.4-23.5,69.9-34.9
                c32-15.4,60-17.6,93.7-6"/>
              <path class="st2" d="M-343.7,478.8c140.4,47.4,253.7-104.2,380-135.7c60.7-15.1,110.4,12,150.6,56.5
                c56.9,63,94.6,142.9,131.6,218.5C385.9,756,450,904.2,578.2,997.1c51.1,37,118.7,72.9,183,52.5c25-7.9,46.6-22.7,70.3-33.6
                c32.2-14.9,60.3-16.8,93.9-5.2"/>
              <path class="st2" d="M-344.5,485.4c140.4,48.3,253.4-103,380.1-133.7c60.2-14.6,110.1,12.7,150.2,56.7
                c56.9,62.4,94.5,141.9,131.4,217.1c67.4,137,132,283.2,259.5,375.3c51.1,37,118.6,72.8,183.1,53.5
                c25.1-7.5,46.9-21.8,70.6-32.3c32.4-14.4,60.6-16,94.2-4.3"/>
              <path class="st2" d="M-345.3,492c140.3,49.2,253.1-101.3,380-130.9c59.7-13.9,109.9,13.4,149.9,56.8
                C241.4,479.7,279,558.6,316,633.2c67.4,135.9,132.7,279.9,259.4,371.2c51.2,36.9,118.5,72.7,183.2,54.4
                c25.1-7.1,47.1-20.9,70.9-31.1c32.5-13.8,60.9-15.2,94.4-3.5"/>
              <path class="st2" d="M-346.1,498.6c139.6,48.9,252.4-97.7,379.5-126C92.9,359.3,143,386.3,183,429.4
                c56.7,60.9,94.4,138.7,131.7,212.3C382.6,776,448.6,918,574.6,1008.2c51.4,36.7,118,72.1,182.8,54.8
                c25.1-6.7,47.2-20,71.1-29.7c32.7-13.2,61.1-14.2,94.5-2.5"/>
              <path class="st2" d="M-347,505.3c138.9,48.7,251.6-94,378.8-121c59.4-12.6,109.4,14.1,149.6,56.7
                c56.5,60,94.5,136.7,131.9,209.3C381.9,783,448.6,923,573.9,1011.9c51.4,36.5,117.6,71.5,182.4,55.3
                c25.1-6.3,47.4-19.1,71.3-28.3c32.8-12.6,61.3-13.2,94.6-1.5"/>
              <path class="st2" d="M-347.8,511.9c138.2,48.5,250.9-90.2,378.2-115.7c59.2-11.9,109.1,14.5,149.4,56.7
                c56.4,59,94.5,134.5,132.2,206.1c69.1,131.1,136.6,268.8,261.1,356.6c51.5,36.4,117.2,71,182,55.7c25-5.9,47.5-18.2,71.6-26.9
                c32.9-11.9,61.6-12.2,94.7-0.5"/>
              <path class="st2" d="M-348.6,518.5c137.4,48.4,250.2-86.2,377.5-110.1c144-27.1,227,157.7,281.8,259.6
                c69.7,129.4,138.1,264.8,261.7,351.4c51.6,36.2,116.8,70.4,181.5,56.2c25-5.5,47.7-17.3,71.8-25.5c33-11.3,61.8-11.2,94.8,0.5"
                />
              <path class="st2" d="M-349.5,525.1C-212.9,573.5-100,443,27.4,420.7c143.1-25,226.1,154.6,282,256.2
                C379.7,804.6,449,937.6,571.7,1023c51.7,36,116.4,69.9,181.1,56.7c25-5.1,47.8-16.3,72-24.1c33.1-10.6,62-10.3,94.9,1.6"/>
              <path class="st2" d="M-350.3,531.8c135.8,48.5,248.6-78.1,376.1-98.4C168.1,410.6,250.8,584.6,308,686
                c71,125.9,141.1,256.4,262.9,340.7c51.7,35.8,116,69.4,180.7,57.2c25-4.7,48-15.4,72.2-22.7c33.2-10,62.3-9.3,94.9,2.6"/>
              <path class="st2" d="M-351.1,538.4c134.9,48.6,247.9-73.7,375.3-92.3c141.4-20.6,224.1,148.2,282.4,249.2
                c71.6,124,142.7,252.1,263.6,335.1c52,35.7,115.5,68.6,180.3,57.7c25-4.2,48.2-14.5,72.4-21.2c33.3-9.3,62.5-8.3,95,3.7"/>
              <path class="st2" d="M-352,545c134,48.7,247.2-69.3,374.6-86C163,440.6,245.7,604,305.3,704.5
                c72.3,122.1,144.3,247.6,264.2,329.4c52,35.6,115.1,68.1,179.9,58.3c25-3.8,48.3-13.5,72.6-19.8c33.4-8.6,62.7-7.2,95.1,4.7"/>
              <path class="st2" d="M-352.8,551.6c133,48.9,246.5-64.7,373.8-79.5c139.4-16.2,222.1,141.8,282.9,241.8
                c73,120.1,146,243,264.9,323.7c52.1,35.4,114.7,67.6,179.5,58.8c25-3.4,48.5-12.6,72.9-18.4c33.5-8,62.9-6.2,95.2,5.8"/>
              <path class="st2" d="M-353.6,558.2c132.2,49.2,245.6-60.5,373-72.9c138.5-13.5,220.9,138.3,283.1,238.1
                c73.7,118.1,147.7,238.3,265.6,317.8c52.2,35.2,114.3,67.1,179.1,59.3c25-3,48.6-11.6,73.1-17c33.6-7.3,63.1-5.2,95.3,6.8"/>
              <path class="st2" d="M-354.5,564.9c131.1,49.5,245-55.7,372.1-66.2C155.1,487.4,237.5,633.9,301,732.9
                c74.5,116.1,149.4,233.6,266.3,311.9c52.3,35,114,66.6,178.7,59.9c25-2.6,48.8-10.7,73.3-15.5c33.6-6.6,63.3-4.2,95.4,7.9"/>
              <path class="st2" d="M-355.3,571.5c130.1,49.8,244.4-51,371.3-59.3c136.2-9,218.7,132.1,283.6,230.4
                c75.2,114,151.2,228.8,267,305.8c52.4,34.9,113.6,66.1,178.3,60.4c25-2.2,48.9-9.7,73.5-14.1c33.7-6,63.4-3.2,95.5,8.9"/>
              <path class="st2" d="M-356.1,578.1c129,50.1,243.8-46.1,370.5-52.4c135.1-6.7,217.6,129,283.9,226.5
                c76,111.8,153,223.9,267.7,299.7c52.4,34.7,113.3,65.6,177.8,61c25-1.8,49.1-8.8,73.7-12.6c33.8-5.3,63.6-2.2,95.6,10"/>
              <path class="st2" d="M-356.9,584.7c127.9,50.4,243.2-41.2,369.6-45.4c133.8-4.4,216.5,125.9,284.1,222.5
                c76.7,109.6,154.8,218.9,268.4,293.6c52.5,34.5,113,65.1,177.4,61.5c25-1.4,49.2-7.8,73.9-11.2c33.8-4.6,63.8-1.2,95.7,11"/>
              <path class="st2" d="M-357.8,591.4c126.8,50.7,242.7-36.2,368.7-38.2c132.6-2.1,215.4,123,284.4,218.6
                c77.5,107.4,156.6,213.9,269.1,287.4c52.6,34.3,112.7,64.7,177,62c25-1,49.4-6.9,74.1-9.8c33.8-4,64-0.2,95.8,12.1"/>
              <path class="st2" d="M-358.6,598c125.7,51.1,242.1-31.2,367.8-31.1c131.3,0.1,214.3,120,284.6,214.6
                c78.2,105.2,158.4,208.9,269.9,281.2c52.7,34.2,112.4,64.2,176.6,62.6c60.1-1.5,111.2-18.3,170.2,4.8"/>
              <path class="st2" d="M-359.4,604.6c124.6,51.4,241.6-26.2,367-23.9c130,2.4,213.2,117.2,284.9,210.6
                c79,102.9,160.2,203.9,270.6,275c52.8,34,112.1,63.7,176.2,63.1c60.1-0.6,111.8-15.8,170.5,7.3"/>
              <path class="st2" d="M-360.3,611.2c123.5,51.8,241.1-21.1,366.1-16.6c128.7,4.6,212,114.4,285.2,206.6
                c79.8,100.5,161.9,198.8,271.3,268.7c52.8,33.8,111.8,63.2,175.8,63.6c60,0.4,112.4-13.4,170.8,9.8"/>
              <path class="st2" d="M-361.1,617.9c122.5,52.1,240.7-16.1,365.2-9.4C131.4,615.3,215,720.2,289.5,811
                c80.5,98.2,163.7,193.7,272.1,262.5c52.9,33.6,111.6,62.7,175.4,64.2c60,1.3,113-11,171.1,12.3"/>
              <path class="st2" d="M-361.9,624.5c121.4,52.5,240.3-11,364.3-2.1c125.9,9,209.8,109.2,285.7,198.6
                c81.3,95.8,165.5,188.6,272.8,256.3c53,33.4,111.3,62.3,175,64.7c60,2.2,113.6-8.6,171.4,14.7"/>
              <path class="st2" d="M-362.8,631.1c120.4,52.8,239.8-5.9,363.4,5.1c124.6,11.1,208.8,106.8,286,194.6
                c82.1,93.4,167.2,183.5,273.5,250c53.1,33.2,111.1,61.8,174.6,65.2c59.9,3.2,114.1-6.2,171.8,17.2"/>
              <path class="st2" d="M-363.6,637.7c119.4,53.2,239.5-0.8,362.5,12.3C122,663.2,206.6,754.5,285.1,840.7
                c82.8,91,168.9,178.4,274.2,243.8c53.1,33,110.9,61.5,174.1,65.6c59.8,4,114.7-3.8,172.1,19.6"/>
              <path class="st2" d="M-364.4,644.4c118.4,53.5,239.1,4.2,361.6,19.5C118.8,679,203.9,766,283.7,850.6
                c83.6,88.6,170.6,173.3,275,237.7c53.1,32.8,110.7,61,173.7,66.1c59.8,4.9,115.2-1.5,172.4,22"/>
              <path class="st2" d="M-365.2,651c116.5,53.4,236.8,10.3,358,26.2c121,15.9,208.1,99.9,289.4,183.3
                c84.8,86.9,173.9,170,278.3,233.2c53,32.1,109.8,59.2,172.1,65.1c59.3,5.6,114.7,0.9,171.3,24.3"/>
              <path class="st2" d="M-366.1,657.6c115.6,53.7,236.5,15.2,357.2,33.2c119.6,17.9,207.1,98,289.6,179.5
                c85.5,84.5,175.5,164.9,279,227.1c53,31.8,109.6,58.8,171.7,65.6c59.3,6.5,115.2,3.2,171.6,26.7"/>
              <path class="st2" d="M-366.9,664.2c114.8,54,236.1,20,356.3,40.1c118.1,19.7,206.2,96.1,289.9,175.8
                c86.2,82.1,177.1,159.9,279.7,221.1c53.1,31.6,109.4,58.3,171.3,66.1c59.3,7.4,115.6,5.5,171.9,29"/>
              <path class="st2" d="M-367.7,670.8c114.1,54.3,235.8,24.8,355.5,46.9c116.6,21.5,205.3,94.4,290.1,172.2
                c87,79.7,178.6,155,280.4,215.1c53.1,31.4,109.3,58,170.9,66.5c59.2,8.2,116,7.7,172.3,31.3"/>
              <path class="st2" d="M-368.6,677.5C-255.2,732-133.1,707-13.9,731.1c115.1,23.2,204.5,92.9,290.3,168.6
                c42.3,37.3,84.6,74.9,130,108.4c24.9,18.4,52.3,33.1,77.1,51.3c24,17.6,48.3,34.5,74,49.6c53.1,31.1,109.2,57.6,170.5,66.9
                c59.2,9,116.4,9.9,172.6,33.6"/>
              <path class="st2" d="M-369.4,684.1c112.8,54.8,235.1,34.2,353.9,60.2C98.1,769.1,188.2,835.7,275,909.4
                c42.5,36.1,85.1,72.5,130.6,104.9c25,17.8,52.5,31.9,77.3,49.8c24,17.3,48.2,34,73.9,48.8c53.2,30.9,109.1,57.1,170.1,67.3
                c59.2,9.9,116.8,12.1,172.9,35.9"/>
              <path class="st2" d="M-370.2,690.7c112.2,55,234.8,38.8,353.1,66.6C95,783.7,185.8,847.4,273.6,919
                c42.8,34.9,85.6,70.1,131.2,101.4c25.1,17.3,52.7,30.8,77.5,48.4c24,17,48.2,33.4,73.7,48.1c53.2,30.6,109,56.7,169.7,67.7
                c59.2,10.8,117.1,14.3,173.2,38.2"/>
              <path class="st2" d="M-371.1,697.3c111.7,55.2,234.4,43.3,352.3,72.9C92,798.1,183.5,859.1,272.1,928.6
                c43.1,33.8,86.2,67.7,131.8,98c25.2,16.7,52.9,29.8,77.6,47c24,16.7,48.2,32.9,73.6,47.3c53.3,30.3,108.9,56.2,169.4,68.1
                c59.2,11.6,117.4,16.4,173.6,40.4"/>
              <path class="st2" d="M-371.9,704c111.3,55.4,234.1,47.7,351.6,79c109.4,29.2,201.6,87.7,291,155.1
                c43.3,32.6,86.7,65.5,132.4,94.7c25.3,16.2,53.1,28.8,77.8,45.7c24,16.4,48.1,32.3,73.5,46.6c53.3,30.1,108.8,55.8,169,68.4
                c59.2,12.4,117.7,18.5,173.9,42.6"/>
              <path class="st2" d="M-372.7,710.6c110.9,55.5,233.8,51.9,350.8,84.9c25.1,7.1,48.2,17.1,72,27.4
                c27.2,11.7,55.2,20.2,81.7,33.9c48.9,25.3,92.9,58.5,137.4,90.7c43.6,31.5,87.2,63.3,133,91.5c25.4,15.7,53.3,27.8,78,44.4
                c24,16.1,48.1,31.7,73.4,45.8c53.4,29.8,108.7,55.3,168.6,68.7c59.2,13.2,118,20.5,174.3,44.8"/>
              <path class="st2" d="M-373.6,717.2c110.6,55.6,233.5,56,350.1,90.7c24.9,7.4,48.4,17.2,71.9,28c26.8,12.2,55.1,20,81.7,33.3
                c48.8,24.5,93,56.5,137.7,87.7c43.8,30.5,87.7,61.1,133.5,88.4c25.5,15.2,53.4,26.9,78.1,43.1c24,15.8,48.1,31.1,73.2,45
                c53.4,29.5,108.7,54.9,168.2,69c59.2,14,118.3,22.6,174.6,46.9"/>
              <path class="st2" d="M-374.4,723.8c110.4,55.7,233.2,60,349.5,96.2c24.8,7.7,48.3,17.8,71.9,28.6
                c26.6,12.2,55.1,19.8,81.6,32.7c48.7,23.8,93.1,54.7,138,84.7c44,29.4,88.1,59,134.1,85.4c25.6,14.7,53.5,26,78.3,42
                c24,15.4,48,30.5,73.1,44.2c53.5,29.2,108.6,54.4,167.9,69.2c59.2,14.8,118.5,24.6,175,49"/>
              <path class="st2" d="M-375.2,730.4c110.3,55.8,232.8,63.8,348.8,101.5c24.7,8,48.2,18.3,71.8,29.1c26.5,12.2,55,19.6,81.5,32.3
                c48.5,23.1,93.2,52.9,138.3,81.9c44.3,28.4,88.6,57,134.6,82.6c25.6,14.3,53.6,25.2,78.4,40.8c24,15.1,48,29.9,73,43.4
                c53.5,28.8,108.6,53.9,167.5,69.4C778,1227,837.4,1238,894,1262.5"/>
              <path class="st2" d="M-376,737.1c110.2,55.7,232.5,67.5,348.2,106.5c24.7,8.3,48.1,18.7,71.7,29.6
                c26.4,12.2,54.9,19.5,81.4,31.8c48.4,22.5,93.3,51.2,138.6,79.2c44.5,27.5,89,55.1,135.1,79.8c25.7,13.8,53.7,24.5,78.5,39.8
                c24,14.8,48,29.3,72.9,42.6c53.6,28.5,108.5,53.4,167.1,69.6c59.3,16.4,118.9,28.5,175.7,53.1"/>
              <path class="st2" d="M-376.9,743.7c110.2,55.7,232.2,71,347.6,111.3c24.6,8.6,48,19.1,71.6,30c26.3,12.1,54.8,19.4,81.3,31.5
                c48.3,21.9,93.3,49.6,138.8,76.7c44.7,26.6,89.5,53.2,135.6,77.2c25.8,13.4,53.8,23.8,78.6,38.8c24,14.4,47.9,28.7,72.8,41.7
                c53.6,28.1,108.5,52.9,166.8,69.8c59.4,17.2,119.1,30.4,176,55.1"/>
              <path class="st2" d="M-377.7,750.3c110.2,55.6,231.9,74.3,347.1,115.9c24.5,8.9,47.8,19.9,71.6,30.4
                c26.5,11.6,54.6,19.4,81.2,31.2c48.1,21.4,93.4,48.1,139,74.2c44.9,25.7,89.9,51.4,136.1,74.8c25.9,13.1,53.8,23.2,78.8,37.8
                c24,14.1,47.9,28.1,72.6,40.9c53.7,27.8,108.5,52.4,166.4,69.9c59.4,17.9,119.3,32.2,176.4,57.1"/>
              <path class="st2" d="M-378.5,756.9C-268.2,812.3-147,834.3-32,877c24.5,9.1,47.7,20.3,71.5,30.7c26.4,11.6,54.5,19.4,81.1,30.9
                c48,20.9,93.4,46.7,139.2,72c45.1,24.9,90.3,49.8,136.6,72.4c25.9,12.7,53.9,22.6,78.9,37c24,13.8,47.9,27.4,72.5,40
                c53.7,27.4,108.5,51.9,166.1,69.9c59.5,18.6,119.5,34.1,176.8,59"/>
              <path class="st2" d="M-379.4,763.5c110.5,55.1,231.3,80.4,346.1,124.1c24.4,9.3,47.7,20.5,71.5,31c26.4,11.6,54.4,19.4,81,30.8
                c47.9,20.5,93.5,45.4,139.4,69.8c45.3,24.1,90.7,48.2,137,70.3c26,12.4,53.9,22.2,79,36.2c24,13.4,47.9,26.8,72.4,39.1
                c53.7,27,108.5,51.3,165.7,70c59.5,19.4,119.6,35.9,177.2,60.9"/>
              <path class="st2" d="M-380.2,770.1c110.8,54.8,231,83.1,345.6,127.7c24.3,9.5,47.6,20.7,71.4,31.2
                c26.3,11.6,54.2,19.5,80.8,30.7c47.7,20.1,93.5,44.3,139.6,67.9c45.5,23.3,91.1,46.7,137.5,68.3
                c51.1,23.8,100.8,48.7,151.3,73.7c53.8,26.6,108.5,50.8,165.4,70c59.6,20.1,119.8,37.6,177.5,62.7"/>
              <path class="st2" d="M-381,776.8c111.1,54.4,230.7,85.5,345.3,131c98.9,39.3,196.4,80.7,291.8,128.1
                c45.7,22.7,91.4,45.3,137.9,66.4c51.1,23.2,100.8,47.6,151.3,72.1c53.8,26.2,108.5,50.2,165.1,69.9
                c59.7,20.8,119.9,39.3,177.9,64.5"/>
              <path class="st2" d="M-381.9,783.4c111.4,53.9,230.4,87.8,344.9,134C61.4,957.1,159,998,254.8,1044
                c96.1,46.2,193.4,89.4,289.5,135.4c111.6,53.4,229.8,87.1,343.1,136.1"/>
              <path class="st2" d="M-382.7,790c112.7,53.6,232,90.4,347.2,137.7c97.1,39.9,193.9,79.8,289.1,124.2
                c95.4,44.6,191.9,86.4,287.2,131.3c112.8,53.1,231.5,89.6,345.8,138.9"/>
              <path class="st2" d="M-383.5,796.6c113.1,52.9,231.7,92.2,347,140c96.7,40.1,193.6,79.7,289,123.1
                c95.6,43.5,192.1,84.7,287.5,128.7c113.3,52.3,231.4,91.1,345.9,140.3"/>
              <path class="st2" d="M-384.3,803.2c113.6,52.1,231.5,93.7,346.7,141.9c96.5,40.3,193.2,79.8,288.8,122.2
                c95.8,42.5,192.2,83.2,287.7,126.4c113.7,51.4,231.3,92.6,346,141.6"/>
              <path class="st2" d="M-385.2,809.8c209.4,93.7,424.8,173.6,635.2,265c95.9,41.7,192.3,82,287.9,124.3
                c114.2,50.5,231.2,94,346.1,142.9"/>
              <path class="st2" d="M-386,816.4C-175.7,908,37.9,992,248.9,1082s423,177.3,634.3,266.6"/>
              <path class="st2" d="M-386.8,823.1c422.7,178.4,846.3,354.6,1269.2,532.2"/>
            </g>
          </g>
        </g>
      </g>
      <g>
        <defs>
          <rect id="SVGID_3_" x="-137.6" y="-272.2" width="985.7" height="1202.3"/>
        </defs>
        <clipPath id="SVGID_4_">
          <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
        </clipPath>
        <!-- <g class="st3">
            <radialGradient id="SVGID_5_" cx="-10.0082" cy="601.936" r="165.6532" gradientTransform="matrix(0.3203 -0.6562 -2.0028 -3.2709 1578.9139 2390.6169)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#FFFFFF"/>
            <stop  offset="9.678400e-02" style="stop-color:#C4C2C2;stop-opacity:0.9032"/>
            <stop  offset="0.277" style="stop-color:#7E7B7A;stop-opacity:0.723"/>
            <stop  offset="0.4511" style="stop-color:#4F4D4C;stop-opacity:0.5489"/>
            <stop  offset="0.6148" style="stop-color:#312F2F;stop-opacity:0.3852"/>
            <stop  offset="0.7654" style="stop-color:#1A1A1A;stop-opacity:0.2346"/>
            <stop  offset="0.8985" style="stop-color:#080908;stop-opacity:0.1015"/>
            <stop  offset="1" style="stop-color:#010101;stop-opacity:0"/>
          </radialGradient>
          <path class="st4" d="M422.7,309.1c189.7,309.8,318.7,611.8,288.2,674.4S501.8,845.7,312.1,535.9S-6.6-76,23.9-138.6
            C54.5-201.2,233-0.8,422.7,309.1z"/>
          
            <radialGradient id="SVGID_6_" cx="12.1764" cy="502.6615" r="165.6396" gradientTransform="matrix(0.1692 -0.8324 -2.5163 -1.7119 1813.3237 1566.3064)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#FFFFFF"/>
            <stop  offset="2.449507e-02" style="stop-color:#D4D2D2;stop-opacity:0.9755"/>
            <stop  offset="7.498580e-02" style="stop-color:#9C9999;stop-opacity:0.925"/>
            <stop  offset="0.1311" style="stop-color:#716E6E;stop-opacity:0.8689"/>
            <stop  offset="0.1918" style="stop-color:#524F4F;stop-opacity:0.8082"/>
            <stop  offset="0.2582" style="stop-color:#3B3938;stop-opacity:0.7418"/>
            <stop  offset="0.3324" style="stop-color:#292827;stop-opacity:0.6676"/>
            <stop  offset="0.4177" style="stop-color:#1A1919;stop-opacity:0.5823"/>
            <stop  offset="0.521" style="stop-color:#0D0D0D;stop-opacity:0.479"/>
            <stop  offset="0.661" style="stop-color:#040404;stop-opacity:0.339"/>
            <stop  offset="1" style="stop-color:#010101;stop-opacity:0"/>
          </radialGradient>
          <path class="st5" d="M576.1,548.2c238.4,162.2,418.5,358,402.4,437.4C962.3,1065,756,997.9,517.6,835.7s-418.5-358-402.4-437.4
            C131.4,319,337.7,386.1,576.1,548.2z"/>
          
            <radialGradient id="SVGID_7_" cx="683.952" cy="667.8347" r="165.6416" gradientTransform="matrix(2.050000e-02 -0.8696 -2.6822 -0.2111 1841.3042 829.1501)" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#FFFFFF"/>
            <stop  offset="2.449507e-02" style="stop-color:#D4D2D2;stop-opacity:0.9755"/>
            <stop  offset="7.498580e-02" style="stop-color:#9C9999;stop-opacity:0.925"/>
            <stop  offset="0.1311" style="stop-color:#716E6E;stop-opacity:0.8689"/>
            <stop  offset="0.1918" style="stop-color:#524F4F;stop-opacity:0.8082"/>
            <stop  offset="0.2582" style="stop-color:#3B3938;stop-opacity:0.7418"/>
            <stop  offset="0.3324" style="stop-color:#292827;stop-opacity:0.6676"/>
            <stop  offset="0.4177" style="stop-color:#1A1919;stop-opacity:0.5823"/>
            <stop  offset="0.521" style="stop-color:#0D0D0D;stop-opacity:0.479"/>
            <stop  offset="0.661" style="stop-color:#040404;stop-opacity:0.339"/>
            <stop  offset="1" style="stop-color:#010101;stop-opacity:0"/>
          </radialGradient>
          <path class="st6" d="M63.4-58.1c254.1,20,458.5,103.4,456.5,186.4c-2,82.9-209.5,134-463.6,114S-402.2,138.9-400.2,55.9
            C-398.2-27.1-190.6-78.1,63.4-58.1z"/>
        </g> -->
      </g>
    </g>
    <g>
      <g>
        <rect x="797.6" y="-3.5" class="st7" width="200.8" height="81"/>
      </g>
    </g>
    <rect x="418.1" y="30.8" class="st7" width="198.8" height="87"/>
    <g>
      <g class="st8">
        <path d="M153.3,628.8h-3.7l-0.8,1.8h-0.7l3-6.6h0.7l3,6.6h-0.7L153.3,628.8z M153,628.2l-1.6-3.5l-1.6,3.5H153z"/>
        <path d="M156.5,630.2c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9
          c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.8,0.1,1.1,0.2s0.6,0.4,0.8,0.7l-0.5,0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.3-0.1-0.5-0.2-0.8-0.2
          c-0.4,0-0.7,0.1-1,0.2s-0.5,0.4-0.7,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.2,1,0.2
          c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.6-0.5l0.5,0.3c-0.2,0.3-0.5,0.6-0.8,0.7c-0.3,0.2-0.7,0.2-1.1,0.2
          C157.3,630.6,156.8,630.5,156.5,630.2z"/>
        <path d="M161.7,630.2c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9
          c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.8,0.1,1.1,0.2s0.6,0.4,0.8,0.7l-0.5,0.3c-0.2-0.2-0.4-0.4-0.6-0.5c-0.3-0.1-0.5-0.2-0.8-0.2
          c-0.4,0-0.7,0.1-1,0.2s-0.5,0.4-0.7,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.2,1,0.2
          c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.6-0.5l0.5,0.3c-0.2,0.3-0.5,0.6-0.8,0.7c-0.3,0.2-0.7,0.2-1.1,0.2
          C162.5,630.6,162,630.5,161.7,630.2z"/>
        <path d="M167.5,625.8c0.3-0.2,0.7-0.3,1.1-0.3v0.6l-0.2,0c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.3v2.5h-0.7v-4.9h0.6v1
          C167,626.2,167.2,626,167.5,625.8z"/>
        <path d="M174.1,628.3H170c0,0.5,0.2,0.9,0.6,1.2c0.4,0.3,0.8,0.5,1.4,0.5c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.7-0.5l0.4,0.4
          c-0.2,0.3-0.5,0.5-0.8,0.6s-0.7,0.2-1.1,0.2c-0.5,0-1-0.1-1.3-0.3c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3
          c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9s0.8-0.3,1.2-0.3s0.9,0.1,1.2,0.3c0.4,0.2,0.6,0.5,0.9,0.9
          c0.2,0.4,0.3,0.8,0.3,1.3L174.1,628.3z M170.6,626.6c-0.3,0.3-0.5,0.7-0.5,1.2h3.5c0-0.5-0.2-0.9-0.5-1.2
          c-0.3-0.3-0.7-0.5-1.2-0.5C171.3,626.1,170.9,626.3,170.6,626.6z"/>
        <path d="M180,623.6v7h-0.6v-1c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.1,0.3c-0.5,0-0.9-0.1-1.3-0.3
          c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.3-0.3
          c0.4,0,0.8,0.1,1.1,0.2s0.6,0.4,0.8,0.7v-3H180z M178.5,629.7c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-1
          c-0.2-0.3-0.4-0.5-0.7-0.7s-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-0.9,0.2s-0.5,0.4-0.7,0.7c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.7,0.2,1
          c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.2,0.9,0.2C177.9,630,178.2,629.9,178.5,629.7z"/>
        <path d="M181.9,624.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.3,0.1s0.1,0.2,0.1,0.3
          c0,0.1,0,0.3-0.1,0.3s-0.2,0.1-0.3,0.1C182.1,624.5,181.9,624.5,181.9,624.4z M181.9,625.6h0.7v4.9h-0.7V625.6z"/>
        <path d="M187,630.2c-0.1,0.1-0.3,0.2-0.5,0.3s-0.4,0.1-0.6,0.1c-0.5,0-0.8-0.1-1.1-0.4s-0.4-0.6-0.4-1.1v-3h-0.9v-0.6h0.9v-1.1
          h0.7v1.1h1.5v0.6h-1.5v2.9c0,0.3,0.1,0.5,0.2,0.7s0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2L187,630.2z"/>
        <path d="M191.4,626c0.3,0.3,0.5,0.8,0.5,1.4v3.1h-0.6v-0.8c-0.1,0.3-0.4,0.5-0.7,0.6s-0.6,0.2-1,0.2c-0.6,0-1-0.1-1.3-0.4
          s-0.5-0.6-0.5-1c0-0.4,0.2-0.8,0.5-1c0.3-0.3,0.8-0.4,1.4-0.4h1.6v-0.3c0-0.4-0.1-0.7-0.4-1c-0.2-0.2-0.6-0.3-1-0.3
          c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.2-0.7,0.4l-0.3-0.5c0.3-0.2,0.6-0.4,0.9-0.5c0.4-0.1,0.7-0.2,1.1-0.2
          C190.6,625.5,191.1,625.7,191.4,626z M190.7,629.8c0.3-0.2,0.5-0.4,0.6-0.7v-0.8h-1.5c-0.8,0-1.3,0.3-1.3,0.9
          c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.2,0.9,0.2C190.1,630,190.4,629.9,190.7,629.8z"/>
        <path d="M196.3,630.2c-0.1,0.1-0.3,0.2-0.5,0.3s-0.4,0.1-0.6,0.1c-0.5,0-0.8-0.1-1.1-0.4s-0.4-0.6-0.4-1.1v-3H193v-0.6h0.9v-1.1
          h0.7v1.1h1.5v0.6h-1.5v2.9c0,0.3,0.1,0.5,0.2,0.7s0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2L196.3,630.2z"/>
        <path d="M197.5,624.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.3,0.1s0.1,0.2,0.1,0.3
          c0,0.1,0,0.3-0.1,0.3s-0.2,0.1-0.3,0.1C197.7,624.5,197.6,624.5,197.5,624.4z M197.5,625.6h0.7v4.9h-0.7V625.6z"/>
        <path d="M200.8,630.2c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9
          c0.4-0.2,0.8-0.3,1.3-0.3s0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.3,0.8,0.3,1.3c0,0.5-0.1,0.9-0.3,1.3
          c-0.2,0.4-0.5,0.7-0.9,0.9c-0.4,0.2-0.8,0.3-1.3,0.3S201.2,630.5,200.8,630.2z M203,629.7c0.3-0.2,0.5-0.4,0.7-0.7s0.2-0.6,0.2-1
          c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.7-0.7s-0.6-0.2-0.9-0.2s-0.7,0.1-0.9,0.2s-0.5,0.4-0.7,0.7c-0.2,0.3-0.2,0.6-0.2,1
          c0,0.4,0.1,0.7,0.2,1c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.2,0.9,0.2S202.7,629.9,203,629.7z"/>
        <path d="M209.9,626.1c0.4,0.4,0.5,0.9,0.5,1.6v2.9h-0.7v-2.8c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.4-1.1-0.4
          c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3v2.6h-0.7v-4.9h0.6v0.9c0.2-0.3,0.4-0.5,0.8-0.7c0.3-0.2,0.7-0.2,1.1-0.2
          C209.1,625.5,209.6,625.7,209.9,626.1z"/>
      </g>
      <g class="st8">
        <path d="M173.7,633.9v6.6h-0.6l-4.2-5.3v5.3h-0.7v-6.6h0.6l4.2,5.3v-5.3H173.7z"/>
        <path d="M180.1,635.6v4.9h-0.6v-0.9c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.7,0.2-1.1,0.2c-0.6,0-1.2-0.2-1.5-0.5
          c-0.4-0.4-0.6-0.9-0.6-1.6v-2.9h0.7v2.8c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.6,0.4,1.1,0.4c0.5,0,0.9-0.2,1.2-0.5
          c0.3-0.3,0.5-0.8,0.5-1.3v-2.6H180.1z"/>
        <path d="M189.6,636.1c0.4,0.4,0.5,0.9,0.5,1.6v2.9h-0.7v-2.8c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1-0.4
          c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.3v2.6h-0.7v-2.8c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1.1-0.4
          c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.3v2.6H182v-4.9h0.6v0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.7-0.2,1.1-0.2
          c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.4,0.7,0.8c0.2-0.3,0.4-0.6,0.8-0.8s0.7-0.3,1.2-0.3C188.8,635.5,189.2,635.7,189.6,636.1z"
          />
        <path d="M195.7,635.9c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.3,0.8,0.3,1.3c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-0.9,0.9
          c-0.4,0.2-0.8,0.3-1.3,0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8v1h-0.6v-7h0.7v3c0.2-0.3,0.5-0.6,0.8-0.7
          c0.3-0.2,0.7-0.2,1.1-0.2C194.9,635.5,195.4,635.6,195.7,635.9z M195.4,639.7c0.3-0.2,0.5-0.4,0.7-0.7s0.2-0.6,0.2-1
          c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-0.9,0.2s-0.5,0.4-0.7,0.7
          c-0.2,0.3-0.2,0.6-0.2,1c0,0.4,0.1,0.7,0.2,1s0.4,0.5,0.7,0.7s0.6,0.2,0.9,0.2C194.8,640,195.1,639.9,195.4,639.7z"/>
        <path d="M202.6,638.3h-4.1c0,0.5,0.2,0.9,0.6,1.2c0.4,0.3,0.8,0.5,1.4,0.5c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.7-0.5l0.4,0.4
          c-0.2,0.3-0.5,0.5-0.8,0.6s-0.7,0.2-1.1,0.2c-0.5,0-1-0.1-1.3-0.3c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.8-0.3-1.3
          c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-0.9s0.8-0.3,1.2-0.3s0.9,0.1,1.2,0.3c0.4,0.2,0.6,0.5,0.9,0.9
          c0.2,0.4,0.3,0.8,0.3,1.3L202.6,638.3z M199,636.6c-0.3,0.3-0.5,0.7-0.5,1.2h3.5c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5
          C199.7,636.1,199.3,636.3,199,636.6z"/>
        <path d="M205.3,635.8c0.3-0.2,0.7-0.3,1.1-0.3v0.6l-0.2,0c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.3v2.5H204v-4.9h0.6v1
          C204.8,636.2,205,636,205.3,635.8z"/>
      </g>
      <text transform="matrix(1 0 0 1 147.2075 660.8691)" class="st9 st10">{{ token }}</text>
    </g>
    <text transform="matrix(1 0 0 1 47.415 461.0693)" class="st9 st11" v-if="location"> {{ location.toUpperCase() }}</text>
    <g class="st8">
      <path d="M67.1,251.9H53.5l-2.9,6.5h-2.7L59,234h2.5l11.1,24.4H70L67.1,251.9z M66.1,249.8l-5.9-13.1l-5.9,13.1H66.1z"/>
      <path d="M109,234l-8.2,24.4H98l-7.1-20.8l-7.2,20.8H81L72.8,234h2.6l7,21l7.3-21h2.4l7.2,21.1l7.1-21.1H109z"/>
      <path d="M128.2,251.9h-13.6l-2.9,6.5H109l11.1-24.4h2.5l11.1,24.4h-2.7L128.2,251.9z M127.3,249.8l-5.9-13.1l-5.9,13.1H127.3z"/>
      <path d="M154.5,258.4l-5.6-7.8c-0.6,0.1-1.3,0.1-2,0.1h-6.5v7.7h-2.6V234h9.1c3.1,0,5.5,0.7,7.3,2.2c1.8,1.5,2.6,3.5,2.6,6.1
        c0,1.9-0.5,3.5-1.4,4.8s-2.3,2.3-4.1,2.8l6,8.4H154.5z M152.5,246.9c1.3-1.1,1.9-2.6,1.9-4.5c0-2-0.6-3.5-1.9-4.5
        c-1.3-1.1-3.1-1.6-5.5-1.6h-6.5v12.2h6.5C149.4,248.5,151.2,247.9,152.5,246.9z"/>
      <path d="M163.1,234h9.9c2.6,0,4.9,0.5,6.8,1.5c2,1,3.5,2.5,4.6,4.3c1.1,1.8,1.6,4,1.6,6.3c0,2.4-0.5,4.5-1.6,6.3
        c-1.1,1.8-2.6,3.3-4.6,4.3c-2,1-4.2,1.5-6.8,1.5h-9.9V234z M172.9,256.2c2.1,0,4-0.4,5.6-1.3c1.6-0.8,2.8-2,3.7-3.5
        c0.9-1.5,1.3-3.2,1.3-5.2c0-2-0.4-3.7-1.3-5.2c-0.9-1.5-2.1-2.7-3.7-3.5c-1.6-0.8-3.5-1.3-5.6-1.3h-7.2v19.9H172.9z"/>
      <path d="M209.1,256.2v2.2h-17.2V234h16.7v2.2h-14.1v8.7H207v2.2h-12.6v9.1H209.1z"/>
      <path d="M215.1,234h9.9c2.6,0,4.9,0.5,6.8,1.5c2,1,3.5,2.5,4.6,4.3c1.1,1.8,1.6,4,1.6,6.3c0,2.4-0.5,4.5-1.6,6.3
        c-1.1,1.8-2.6,3.3-4.6,4.3c-2,1-4.2,1.5-6.8,1.5h-9.9V234z M224.9,256.2c2.1,0,4-0.4,5.6-1.3c1.6-0.8,2.8-2,3.7-3.5
        c0.9-1.5,1.3-3.2,1.3-5.2c0-2-0.4-3.7-1.3-5.2c-0.9-1.5-2.1-2.7-3.7-3.5c-1.6-0.8-3.5-1.3-5.6-1.3h-7.2v19.9H224.9z"/>
      <path d="M256.1,235.3c-0.5,0.6-0.8,1.4-0.8,2.6v2.2h5.6v2.1h-5.6v16.2h-2.5v-16.2h-3.3v-2.1h3.3v-2.3c0-1.7,0.5-3,1.4-4
        c1-1,2.3-1.5,4.1-1.5c0.7,0,1.4,0.1,2,0.3c0.6,0.2,1.2,0.5,1.6,0.9l-0.8,1.8c-0.7-0.6-1.6-0.9-2.7-0.9
        C257.5,234.5,256.7,234.8,256.1,235.3z"/>
      <path d="M266.7,257.4c-1.4-0.8-2.5-1.9-3.3-3.3c-0.8-1.4-1.2-3-1.2-4.8c0-1.8,0.4-3.4,1.2-4.8c0.8-1.4,1.9-2.5,3.3-3.3
        c1.4-0.8,3-1.2,4.8-1.2c1.8,0,3.4,0.4,4.8,1.2c1.4,0.8,2.5,1.9,3.3,3.3c0.8,1.4,1.2,3,1.2,4.8c0,1.8-0.4,3.4-1.2,4.8
        c-0.8,1.4-1.9,2.5-3.3,3.3c-1.4,0.8-3,1.2-4.8,1.2C269.7,258.6,268.1,258.2,266.7,257.4z M275,255.5c1-0.6,1.8-1.4,2.4-2.5
        c0.6-1.1,0.9-2.3,0.9-3.7c0-1.4-0.3-2.6-0.9-3.7c-0.6-1.1-1.4-1.9-2.4-2.5c-1-0.6-2.2-0.9-3.5-0.9c-1.3,0-2.5,0.3-3.5,0.9
        c-1,0.6-1.8,1.4-2.4,2.5c-0.6,1.1-0.9,2.3-0.9,3.7c0,1.4,0.3,2.6,0.9,3.7c0.6,1.1,1.4,1.9,2.4,2.5c1,0.6,2.2,0.9,3.5,0.9
        C272.8,256.4,273.9,256.1,275,255.5z"/>
      <path d="M290.8,240.9c1.1-0.6,2.6-0.9,4.3-0.9v2.4l-0.6,0c-1.9,0-3.4,0.6-4.5,1.8c-1.1,1.2-1.6,2.8-1.6,5v9.3h-2.5v-18.3h2.4v3.6
        C288.8,242.4,289.6,241.5,290.8,240.9z"/>
    </g>
    <g class="st8">
      <path d="M53.5,281.1h-5.6v-1.4h12.8v1.4h-5.6v14.4h-1.7V281.1z"/>
      <path d="M76.6,279.7v15.8h-1.7v-7.3H65v7.3h-1.7v-15.8H65v7.1h9.9v-7.1H76.6z"/>
      <path d="M92.9,294v1.4H81.7v-15.8h10.9v1.4h-9.2v5.6h8.2v1.4h-8.2v5.9H92.9z"/>
      <path d="M104.5,281.1v6.2h8.2v1.4h-8.2v6.7h-1.7v-15.8h10.9v1.4H104.5z"/>
      <path d="M119.6,294.6c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.9,1.1-4.1c0.7-1.2,1.7-2.2,3-2.9
        c1.3-0.7,2.7-1.1,4.3-1.1s3,0.3,4.2,1s2.3,1.7,3,2.9s1.1,2.6,1.1,4.1s-0.4,2.9-1.1,4.1s-1.7,2.2-3,2.9s-2.7,1-4.2,1
        S120.8,295.3,119.6,294.6z M127.2,293.3c1-0.6,1.8-1.3,2.4-2.4c0.6-1,0.9-2.1,0.9-3.4s-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.8-2.4-2.4
        c-1-0.6-2.1-0.8-3.4-0.8s-2.4,0.3-3.4,0.8s-1.8,1.3-2.4,2.4c-0.6,1-0.9,2.1-0.9,3.4s0.3,2.4,0.9,3.4c0.6,1,1.4,1.8,2.4,2.4
        c1,0.6,2.2,0.8,3.4,0.8S126.2,293.8,127.2,293.3z"/>
      <path d="M135.9,279.7h1.7V294h8.9v1.4h-10.5V279.7z"/>
      <path d="M149.2,279.7h1.7V294h8.9v1.4h-10.5V279.7z"/>
      <path d="M164.9,294.6c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.9,1.1-4.1c0.7-1.2,1.7-2.2,3-2.9
        c1.3-0.7,2.7-1.1,4.3-1.1s3,0.3,4.2,1s2.3,1.7,3,2.9s1.1,2.6,1.1,4.1s-0.4,2.9-1.1,4.1s-1.7,2.2-3,2.9s-2.7,1-4.2,1
        S166.1,295.3,164.9,294.6z M172.5,293.3c1-0.6,1.8-1.3,2.4-2.4c0.6-1,0.9-2.1,0.9-3.4s-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.8-2.4-2.4
        c-1-0.6-2.1-0.8-3.4-0.8s-2.4,0.3-3.4,0.8s-1.8,1.3-2.4,2.4c-0.6,1-0.9,2.1-0.9,3.4s0.3,2.4,0.9,3.4c0.6,1,1.4,1.8,2.4,2.4
        c1,0.6,2.2,0.8,3.4,0.8S171.5,293.8,172.5,293.3z"/>
      <path d="M202.4,279.7l-5.3,15.8h-1.8l-4.6-13.5l-4.7,13.5h-1.7l-5.3-15.8h1.7l4.6,13.6l4.7-13.6h1.6l4.7,13.7l4.6-13.7H202.4z"/>
      <path d="M205.5,279.7h1.7v15.8h-1.7V279.7z"/>
      <path d="M225.5,279.7v15.8h-1.4L214,282.6v12.8h-1.7v-15.8h1.4l10.2,12.8v-12.8H225.5z"/>
      <path d="M241.8,287.6h1.6v6c-0.7,0.6-1.6,1.1-2.6,1.5c-1,0.3-2.1,0.5-3.2,0.5c-1.6,0-3-0.3-4.3-1s-2.3-1.7-3-2.9s-1.1-2.6-1.1-4.1
        s0.4-2.9,1.1-4.1s1.7-2.2,3-2.9s2.7-1,4.3-1c1.2,0,2.3,0.2,3.3,0.6c1,0.4,1.9,1,2.6,1.7l-1,1.1c-1.3-1.2-2.8-1.9-4.8-1.9
        c-1.3,0-2.4,0.3-3.5,0.8s-1.8,1.3-2.4,2.4c-0.6,1-0.9,2.1-0.9,3.4s0.3,2.4,0.9,3.3c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.2,0.9,3.5,0.9
        c1.6,0,3-0.4,4.1-1.2V287.6z"/>
      <path d="M264.8,281.1c1.1,1,1.7,2.3,1.7,4s-0.6,3-1.7,4c-1.1,1-2.7,1.4-4.7,1.4h-4.3v5h-1.7v-15.8h5.9
        C262,279.7,263.6,280.1,264.8,281.1z M263.6,288c0.8-0.7,1.2-1.7,1.2-2.9c0-1.3-0.4-2.3-1.2-3c-0.8-0.7-2-1-3.6-1h-4.2v7.9h4.2
        C261.5,289,262.7,288.7,263.6,288z"/>
      <path d="M280.9,295.5l-3.6-5.1c-0.4,0-0.8,0.1-1.3,0.1h-4.3v5h-1.7v-15.8h5.9c2,0,3.6,0.5,4.7,1.4c1.1,1,1.7,2.3,1.7,4
        c0,1.2-0.3,2.3-0.9,3.1s-1.5,1.5-2.7,1.8l3.9,5.4H280.9z M279.6,288c0.8-0.7,1.2-1.7,1.2-2.9c0-1.3-0.4-2.3-1.2-3
        c-0.8-0.7-2-1-3.6-1h-4.2v7.9h4.2C277.5,289,278.7,288.7,279.6,288z"/>
      <path d="M289.1,294.6c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.9,1.1-4.1c0.7-1.2,1.7-2.2,3-2.9
        c1.3-0.7,2.7-1.1,4.3-1.1s3,0.3,4.2,1s2.3,1.7,3,2.9s1.1,2.6,1.1,4.1s-0.4,2.9-1.1,4.1s-1.7,2.2-3,2.9s-2.7,1-4.2,1
        S290.4,295.3,289.1,294.6z M296.8,293.3c1-0.6,1.8-1.3,2.4-2.4c0.6-1,0.9-2.1,0.9-3.4s-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.8-2.4-2.4
        c-1-0.6-2.1-0.8-3.4-0.8s-2.4,0.3-3.4,0.8s-1.8,1.3-2.4,2.4c-0.6,1-0.9,2.1-0.9,3.4s0.3,2.4,0.9,3.4c0.6,1,1.4,1.8,2.4,2.4
        c1,0.6,2.2,0.8,3.4,0.8S295.8,293.8,296.8,293.3z"/>
      <path d="M305.4,279.7h6.4c1.7,0,3.2,0.3,4.4,1c1.3,0.7,2.3,1.6,3,2.8c0.7,1.2,1.1,2.6,1.1,4.1c0,1.5-0.4,2.9-1.1,4.1
        c-0.7,1.2-1.7,2.1-3,2.8c-1.3,0.7-2.8,1-4.4,1h-6.4V279.7z M311.8,294c1.4,0,2.6-0.3,3.6-0.8c1-0.5,1.8-1.3,2.4-2.3
        c0.6-1,0.9-2.1,0.9-3.4c0-1.3-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.7-2.4-2.3c-1-0.6-2.3-0.8-3.6-0.8h-4.7V294H311.8z"/>
      <path d="M325.7,293.9c-1.1-1.2-1.7-2.9-1.7-5.1v-9.1h1.7v9c0,1.8,0.4,3.2,1.2,4.1c0.8,0.9,2,1.4,3.6,1.4c1.6,0,2.8-0.5,3.6-1.4
        c0.8-0.9,1.2-2.3,1.2-4.1v-9h1.6v9.1c0,2.2-0.6,4-1.7,5.1c-1.1,1.2-2.7,1.8-4.8,1.8S326.8,295,325.7,293.9z"/>
      <path d="M344.6,294.6c-1.3-0.7-2.2-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.9,1.1-4.1c0.7-1.2,1.7-2.2,3-2.9c1.3-0.7,2.7-1,4.3-1
        c1.2,0,2.3,0.2,3.3,0.6c1,0.4,1.8,1,2.5,1.7l-1.1,1.1c-1.2-1.3-2.8-1.9-4.7-1.9c-1.3,0-2.4,0.3-3.4,0.9c-1,0.6-1.8,1.4-2.4,2.4
        c-0.6,1-0.9,2.1-0.9,3.3s0.3,2.4,0.9,3.3c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.2,0.9,3.4,0.9c1.9,0,3.5-0.6,4.7-1.9l1.1,1.1
        c-0.7,0.8-1.5,1.3-2.5,1.7c-1,0.4-2.1,0.6-3.3,0.6C347.3,295.6,345.9,295.3,344.6,294.6z"/>
      <path d="M361.1,281.1h-5.6v-1.4h12.8v1.4h-5.6v14.4h-1.7V281.1z"/>
    </g>
    <g class="st8">
      <path d="M47.5,500.2h-4.8V499h11v1.2H49v12.4h-1.4V500.2z"/>
      <path d="M67.4,499v13.6H66v-6.3h-8.5v6.3H56V499h1.4v6.1H66V499H67.4z"/>
      <path d="M71.8,499h1.4v13.6h-1.4V499z"/>
      <path d="M78.6,512.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C80.5,512.7,79.5,512.6,78.6,512.2z"/>
      <path d="M94.8,499h1.4v13.6h-1.4V499z"/>
      <path d="M101.5,512.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C103.4,512.7,102.4,512.6,101.5,512.2z"/>
      <path d="M120.3,500.2h-4.8V499h11v1.2h-4.8v12.4h-1.4V500.2z"/>
      <path d="M130.9,511.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S132,512.4,130.9,511.8z M137.5,510.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2s-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9
        c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S136.6,511.2,137.5,510.7z"/>
      <path d="M152.3,511.8c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1.1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9c1,0,1.9,0.2,2.8,0.5c0.9,0.3,1.6,0.8,2.2,1.5l-0.9,0.9c-1.1-1.1-2.4-1.6-4-1.6c-1.1,0-2.1,0.2-2.9,0.7
        c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.9,0.7,2.9,0.7c1.6,0,3-0.5,4-1.6
        l0.9,0.9c-0.6,0.6-1.3,1.1-2.2,1.5c-0.9,0.3-1.8,0.5-2.8,0.5C154.6,512.7,153.4,512.4,152.3,511.8z"/>
      <path d="M173.6,511.4v1.2H164V499h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H173.6z"/>
      <path d="M186.3,512.6l-3.1-4.4c-0.4,0-0.7,0.1-1.1,0.1h-3.7v4.3H177V499h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H186.3z M185.2,506.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C183.4,507.1,184.5,506.8,185.2,506.2z"/>
      <path d="M193.5,500.2h-4.8V499h11v1.2H195v12.4h-1.4V500.2z"/>
      <path d="M202,499h1.4v13.6H202V499z"/>
      <path d="M209.3,500.2v5.3h7v1.2h-7v5.8h-1.4V499h9.3v1.2H209.3z"/>
      <path d="M224.9,507.9v4.7h-1.4v-4.7L218,499h1.5l4.7,7.6l4.7-7.6h1.4L224.9,507.9z"/>
      <path d="M240.3,500.2h-4.8V499h11v1.2h-4.8v12.4h-1.4V500.2z"/>
      <path d="M260.2,499v13.6h-1.4v-6.3h-8.5v6.3h-1.4V499h1.4v6.1h8.5V499H260.2z"/>
      <path d="M273.1,509h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L273.1,509z M272.6,507.8l-3.3-7.3l-3.3,7.3H272.6z"/>
      <path d="M280.5,500.2h-4.8V499h11v1.2h-4.8v12.4h-1.4V500.2z"/>
      <path d="M302.6,509h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L302.6,509z M302.1,507.8l-3.3-7.3l-3.3,7.3H302.1z"/>
      <path d="M326,499l-4.6,13.6h-1.5l-4-11.6l-4,11.6h-1.5l-4.6-13.6h1.5l3.9,11.7l4.1-11.7h1.3l4,11.8l4-11.8H326z"/>
      <path d="M336.8,509h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L336.8,509z M336.2,507.8l-3.3-7.3l-3.3,7.3H336.2z"/>
      <path d="M351.5,512.6l-3.1-4.4c-0.3,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V499h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H351.5z M350.3,506.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        s-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C348.6,507.1,349.6,506.8,350.3,506.2z"/>
      <path d="M356.2,499h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4s0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5s-1.5,1.8-2.6,2.4
        c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V499z M361.7,511.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2c0.5-0.8,0.7-1.8,0.7-2.9
        c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H361.7z"/>
      <path d="M381.9,511.4v1.2h-9.6V499h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H381.9z"/>
      <path d="M385.3,499h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V499z M390.8,511.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H390.8z"/>
      <path d="M415.6,500.2c1,0.8,1.5,2,1.5,3.4c0,1.5-0.5,2.6-1.5,3.4c-1,0.8-2.3,1.2-4.1,1.2h-3.7v4.3h-1.4V499h5.1
        C413.3,499,414.6,499.4,415.6,500.2z M414.6,506.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6
        v6.8h3.6C412.8,507,413.9,506.7,414.6,506.2z"/>
      <path d="M429.5,512.6l-3.1-4.4c-0.4,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V499h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7s-1.3,1.3-2.3,1.6l3.3,4.7H429.5z M428.3,506.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C426.6,507.1,427.6,506.8,428.3,506.2z"/>
      <path d="M436.5,511.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S437.6,512.4,436.5,511.8z M443.1,510.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2c-0.9-0.5-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9
        s0.3,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S442.2,511.2,443.1,510.7z"/>
      <path d="M450.6,499h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V499z M456,511.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H456z"/>
      <path d="M468,511.2c-1-1-1.5-2.5-1.5-4.4V499h1.4v7.7c0,1.6,0.4,2.8,1.1,3.5c0.7,0.8,1.7,1.2,3.1,1.2c1.4,0,2.4-0.4,3.1-1.2
        c0.7-0.8,1.1-2,1.1-3.5V499h1.4v7.8c0,1.9-0.5,3.4-1.5,4.4s-2.3,1.5-4.1,1.5C470.3,512.7,469,512.2,468,511.2z"/>
      <path d="M484.3,511.8c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1.1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9c1,0,1.9,0.2,2.8,0.5c0.9,0.3,1.6,0.8,2.2,1.5l-0.9,0.9c-1.1-1.1-2.4-1.6-4-1.6c-1.1,0-2.1,0.2-2.9,0.7
        c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.9,0.7,2.9,0.7c1.6,0,3-0.5,4-1.6
        l0.9,0.9c-0.6,0.6-1.3,1.1-2.2,1.5c-0.9,0.3-1.8,0.5-2.8,0.5C486.6,512.7,485.4,512.4,484.3,511.8z"/>
      <path d="M498.5,500.2h-4.8V499h11v1.2h-4.8v12.4h-1.4V500.2z"/>
    </g>
    <g class="st8">
      <path d="M47.2,539.8c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1.1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9c1,0,1.9,0.2,2.8,0.5c0.9,0.3,1.6,0.8,2.2,1.5l-0.9,0.9c-1.1-1.1-2.4-1.6-4-1.6c-1.1,0-2.1,0.2-2.9,0.7
        c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.9,0.7,2.9,0.7c1.6,0,3-0.5,4-1.6
        l0.9,0.9c-0.6,0.6-1.3,1.1-2.2,1.5c-0.9,0.3-1.8,0.5-2.8,0.5C49.4,540.7,48.2,540.4,47.2,539.8z"/>
      <path d="M60.9,539.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S61.9,540.4,60.9,539.8z M67.4,538.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2s-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9
        c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S66.6,539.2,67.4,538.7z"/>
      <path d="M89,527v13.6h-1.4v-10.9l-5.3,9.2h-0.7l-5.3-9.1v10.8h-1.4V527h1.2l5.9,10.1l5.9-10.1H89z"/>
      <path d="M102.6,528.2c1,0.8,1.5,2,1.5,3.4c0,1.5-0.5,2.6-1.5,3.4c-1,0.8-2.3,1.2-4.1,1.2h-3.7v4.3h-1.4V527h5.1
        C100.3,527,101.6,527.4,102.6,528.2z M101.6,534.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6
        v6.8h3.6C99.8,535,100.9,534.7,101.6,534.2z"/>
      <path d="M107.2,527h1.4v12.4h7.6v1.2h-9.1V527z"/>
      <path d="M118.7,527h1.4v13.6h-1.4V527z"/>
      <path d="M134.1,539.4v1.2h-9.6V527h9.3v1.2H126v4.8h7v1.2h-7v5.1H134.1z"/>
      <path d="M138.4,540.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C140.3,540.7,139.3,540.6,138.4,540.2z"/>
      <path d="M173.2,527l-4.6,13.6h-1.5l-4-11.6l-4,11.6h-1.5l-4.6-13.6h1.5l3.9,11.7l4.1-11.7h1.3l4,11.8l4-11.8H173.2z"/>
      <path d="M175.9,527h1.4v13.6h-1.4V527z"/>
      <path d="M184.4,528.2h-4.8V527h11v1.2h-4.8v12.4h-1.4V528.2z"/>
      <path d="M204.3,527v13.6h-1.4v-6.3h-8.5v6.3h-1.4V527h1.4v6.1h8.5V527H204.3z"/>
      <path d="M213.8,527h1.4v13.6h-1.4V527z"/>
      <path d="M228.2,537h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L228.2,537z M227.7,535.8l-3.3-7.3l-3.3,7.3H227.7z"/>
      <path d="M233.6,527h1.4v13.6h-1.4V527z"/>
      <path d="M239.5,527h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V527z M244.9,539.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H244.9z"/>
      <path d="M255.5,527h1.4v12.4h7.6v1.2h-9.1V527z"/>
      <path d="M272.9,540.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C274.8,540.7,273.8,540.6,272.9,540.2z"/>
      <path d="M286.5,528.2h-4.8V527h11v1.2h-4.8v12.4h-1.4V528.2z"/>
      <path d="M302.8,537h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L302.8,537z M302.3,535.8l-3.3-7.3l-3.3,7.3H302.3z"/>
      <path d="M319.6,527v13.6h-1.2l-8.7-11v11h-1.4V527h1.2l8.8,11v-11H319.6z"/>
      <path d="M324,527h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9H324V527z M329.5,539.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H329.5z"/>
      <path d="M348.4,537h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L348.4,537z M347.9,535.8l-3.3-7.3l-3.3,7.3H347.9z"/>
      <path d="M363.1,540.6l-3.1-4.4c-0.3,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V527h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H363.1z M362,534.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        s-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C360.2,535.1,361.3,534.8,362,534.2z"/>
      <path d="M367.9,527h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V527z M373.4,539.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H373.4z"/>
      <path d="M384.8,540.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3
        c-1.2,0-2.1,0.2-2.7,0.7c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6
        c1,0.2,1.8,0.5,2.4,0.7c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3
        c-0.8,0.3-1.7,0.5-2.8,0.5C386.7,540.7,385.7,540.6,384.8,540.2z"/>
      <path d="M409.5,537h-7.6l-1.6,3.6h-1.5L405,527h1.4l6.2,13.6h-1.5L409.5,537z M409,535.8l-3.3-7.3l-3.3,7.3H409z"/>
      <path d="M426.3,527v13.6h-1.2l-8.7-11v11h-1.4V527h1.2l8.8,11v-11H426.3z"/>
      <path d="M430.7,527h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4s0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5s-1.5,1.8-2.6,2.4
        c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V527z M436.2,539.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2c0.5-0.8,0.7-1.8,0.7-2.9
        c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H436.2z"/>
      <path d="M453.2,539.2c-1-1-1.5-2.5-1.5-4.4V527h1.4v7.7c0,1.6,0.4,2.8,1.1,3.5c0.7,0.8,1.7,1.2,3.1,1.2c1.4,0,2.4-0.4,3.1-1.2
        c0.7-0.8,1.1-2,1.1-3.5V527h1.4v7.8c0,1.9-0.5,3.4-1.5,4.4s-2.3,1.5-4.1,1.5S454.2,540.2,453.2,539.2z"/>
      <path d="M468.1,540.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9c0.4-0.6,0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C470,540.7,469,540.6,468.1,540.2z"/>
      <path d="M488.8,539.4v1.2h-9.6V527h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H488.8z"/>
      <path d="M492.2,527h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4s0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5s-1.5,1.8-2.6,2.4
        c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V527z M497.7,539.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2c0.5-0.8,0.7-1.8,0.7-2.9
        c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H497.7z"/>
    </g>
    <g class="st8">
      <path d="M47.5,556.2h-4.8V555h11v1.2H49v12.4h-1.4V556.2z"/>
      <path d="M67.4,555v13.6H66v-6.3h-8.5v6.3H56V555h1.4v6.1H66V555H67.4z"/>
      <path d="M81.5,567.4v1.2h-9.6V555h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H81.5z"/>
      <path d="M98.5,565h-7.6l-1.6,3.6h-1.5L94,555h1.4l6.2,13.6h-1.5L98.5,565z M98,563.8l-3.3-7.3l-3.3,7.3H98z"/>
      <path d="M103.9,555h1.4v13.6h-1.4V555z"/>
      <path d="M123.4,565h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6H125L123.4,565z M122.8,563.8l-3.3-7.3l-3.3,7.3H122.8z"/>
      <path d="M140.1,555v13.6H139l-8.7-11v11h-1.4V555h1.2l8.8,11v-11H140.1z"/>
      <path d="M144.6,555h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V555z M150,567.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H150z"/>
      <path d="M164,552.2h1.3l-6.4,18.3h-1.3L164,552.2z"/>
      <path d="M168.7,567.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S169.7,568.4,168.7,567.8z M175.2,566.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2s-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9
        c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S174.4,567.2,175.2,566.7z"/>
      <path d="M192,568.6l-3.1-4.4c-0.4,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V555h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H192z M190.8,562.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C189.1,563.1,190.1,562.8,190.8,562.2z"/>
      <path d="M203.3,556.2v5.3h7v1.2h-7v5.8h-1.4V555h9.3v1.2H203.3z"/>
      <path d="M215.5,567.2c-1-1-1.5-2.5-1.5-4.4V555h1.4v7.7c0,1.6,0.4,2.8,1.1,3.5c0.7,0.8,1.7,1.2,3.1,1.2c1.4,0,2.4-0.4,3.1-1.2
        c0.7-0.8,1.1-2,1.1-3.5V555h1.4v7.8c0,1.9-0.5,3.4-1.5,4.4s-2.3,1.5-4.1,1.5C217.8,568.7,216.5,568.2,215.5,567.2z"/>
      <path d="M231.9,556.2h-4.8V555h11v1.2h-4.8v12.4h-1.4V556.2z"/>
      <path d="M241.5,567.2c-1-1-1.5-2.5-1.5-4.4V555h1.4v7.7c0,1.6,0.4,2.8,1.1,3.5c0.7,0.8,1.7,1.2,3.1,1.2c1.4,0,2.4-0.4,3.1-1.2
        c0.7-0.8,1.1-2,1.1-3.5V555h1.4v7.8c0,1.9-0.5,3.4-1.5,4.4s-2.3,1.5-4.1,1.5C243.9,568.7,242.5,568.2,241.5,567.2z"/>
      <path d="M264.8,568.6l-3.1-4.4c-0.4,0-0.7,0.1-1.1,0.1H257v4.3h-1.4V555h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H264.8z M263.7,562.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9H257v6.8h3.6C261.9,563.1,263,562.8,263.7,562.2z"/>
      <path d="M279.2,567.4v1.2h-9.6V555h9.3v1.2H271v4.8h7v1.2h-7v5.1H279.2z"/>
      <path d="M301.8,555v13.6h-1.4v-10.9l-5.3,9.2h-0.7l-5.3-9.1v10.8h-1.4V555h1.2l5.9,10.1l5.9-10.1H301.8z"/>
      <path d="M314.8,565h-7.6l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L314.8,565z M314.2,563.8l-3.3-7.3l-3.3,7.3H314.2z"/>
      <path d="M331.5,555v13.6h-1.2l-8.7-11v11h-1.4V555h1.2l8.8,11v-11H331.5z"/>
      <path d="M344.5,565h-7.6l-1.6,3.6h-1.5L340,555h1.4l6.2,13.6h-1.5L344.5,565z M344,563.8l-3.3-7.3l-3.3,7.3H344z"/>
      <path d="M359.3,561.8h1.4v5.2c-0.6,0.6-1.4,1-2.3,1.3s-1.8,0.4-2.7,0.4c-1.4,0-2.6-0.3-3.7-0.9c-1.1-0.6-1.9-1.4-2.6-2.5
        c-0.6-1.1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1.1,1.5-1.9,2.6-2.5c1.1-0.6,2.3-0.9,3.7-0.9c1,0,2,0.2,2.9,0.5
        c0.9,0.3,1.6,0.8,2.2,1.5l-0.9,0.9c-1.1-1.1-2.4-1.6-4.1-1.6c-1.1,0-2.1,0.2-3,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.8,1.8-0.8,2.9
        s0.3,2,0.8,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.9,0.7,3,0.7c1.4,0,2.6-0.3,3.6-1V561.8z"/>
      <path d="M378.9,555v13.6h-1.4v-10.9l-5.3,9.2h-0.7l-5.3-9.1v10.8h-1.4V555h1.2l5.9,10.1l5.9-10.1H378.9z"/>
      <path d="M392.9,567.4v1.2h-9.6V555h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H392.9z"/>
      <path d="M407.7,555v13.6h-1.2l-8.7-11v11h-1.4V555h1.2l8.8,11v-11H407.7z"/>
      <path d="M414.8,556.2H410V555h11v1.2h-4.8v12.4h-1.4V556.2z"/>
      <path d="M431,556.2h-4.8V555h11v1.2h-4.8v12.4H431V556.2z"/>
      <path d="M441.6,567.8c-1.1-0.6-2-1.4-2.6-2.5s-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5s1.5-1.9,2.6-2.5c1.1-0.6,2.3-0.9,3.7-0.9
        s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9
        S442.7,568.4,441.6,567.8z M448.2,566.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2
        s-1.8-0.7-2.9-0.7c-1.1,0-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.3,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2
        c0.9,0.5,1.8,0.7,2.9,0.7C446.3,567.4,447.3,567.2,448.2,566.7z"/>
      <path d="M457.9,567.8c-1.1-0.6-1.9-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S459,568.4,457.9,567.8z M464.5,566.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2s-1.8-0.7-2.9-0.7c-1.1,0-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9
        s0.2,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7C462.7,567.4,463.6,567.2,464.5,566.7z"/>
      <path d="M471.9,555h1.4v12.4h7.6v1.2h-9.1V555z"/>
      <path d="M484.1,568.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9c0.4-0.6,0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C486,568.7,485,568.6,484.1,568.2z"/>
      <path d="M500.3,555h1.4v13.6h-1.4V555z"/>
      <path d="M517.5,555v13.6h-1.2l-8.7-11v11h-1.4V555h1.2l8.8,11v-11H517.5z"/>
    </g>
    <g class="st8">
      <path d="M44.9,583h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V583z M50.3,595.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H50.3z"/>
      <path d="M60.9,583h1.4v13.6h-1.4V583z"/>
      <path d="M68.3,584.2v5.3h7v1.2h-7v5.8h-1.4V583h9.3v1.2H68.3z"/>
      <path d="M80.6,584.2v5.3h7v1.2h-7v5.8h-1.4V583h9.3v1.2H80.6z"/>
      <path d="M101,595.4v1.2h-9.6V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H101z"/>
      <path d="M113.7,596.6l-3.1-4.4c-0.4,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V583h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H113.7z M112.6,590.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C110.8,591.1,111.9,590.8,112.6,590.2z"/>
      <path d="M128.1,595.4v1.2h-9.6V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H128.1z"/>
      <path d="M142.8,583v13.6h-1.2l-8.7-11v11h-1.4V583h1.2l8.8,11v-11H142.8z"/>
      <path d="M149.9,584.2h-4.8V583h11v1.2h-4.8v12.4h-1.4V584.2z"/>
      <path d="M172.7,584.2c1,0.8,1.5,2,1.5,3.4c0,1.5-0.5,2.6-1.5,3.4c-1,0.8-2.3,1.2-4.1,1.2H165v4.3h-1.4V583h5.1
        C170.4,583,171.7,583.4,172.7,584.2z M171.7,590.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-1.7-0.9-3.1-0.9H165
        v6.8h3.6C169.9,591,171,590.7,171.7,590.2z"/>
      <path d="M188.7,583v13.6h-1.4v-6.3h-8.5v6.3h-1.4V583h1.4v6.1h8.5V583H188.7z"/>
      <path d="M201.6,593H194l-1.6,3.6h-1.5l6.2-13.6h1.4l6.2,13.6h-1.5L201.6,593z M201.1,591.8l-3.3-7.3l-3.3,7.3H201.1z"/>
      <path d="M207.9,596.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C209.8,596.7,208.8,596.6,207.9,596.2z"/>
      <path d="M228.6,595.4v1.2H219V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H228.6z"/>
      <path d="M232.8,596.2c-0.9-0.3-1.6-0.8-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,2,1.2s1.7,0.5,2.5,0.5c1.2,0,2.1-0.2,2.7-0.7
        c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
        c-0.6-0.2-1.1-0.6-1.6-1.1c-0.4-0.5-0.7-1.2-0.7-2c0-0.7,0.2-1.3,0.5-1.9s0.9-1,1.7-1.4c0.8-0.3,1.7-0.5,2.8-0.5
        c0.8,0,1.5,0.1,2.3,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.7-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2.1,0.2-2.7,0.7
        c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.8c0.5,0.2,1.2,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
        c0.6,0.2,1.1,0.6,1.6,1.1c0.4,0.5,0.6,1.1,0.6,2c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3s-1.7,0.5-2.8,0.5
        C234.7,596.7,233.8,596.6,232.8,596.2z"/>
      <path d="M251.3,595.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S252.4,596.4,251.3,595.8z M257.9,594.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2s-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7s-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9
        c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S257,595.2,257.9,594.7z"/>
      <path d="M266.8,584.2v5.3h7v1.2h-7v5.8h-1.4V583h9.3v1.2H266.8z"/>
      <path d="M291.9,584.2c1,0.8,1.5,2,1.5,3.4c0,1.5-0.5,2.6-1.5,3.4c-1,0.8-2.3,1.2-4.1,1.2h-3.7v4.3h-1.4V583h5.1
        C289.6,583,290.9,583.4,291.9,584.2z M290.9,590.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6
        v6.8h3.6C289.1,591,290.2,590.7,290.9,590.2z"/>
      <path d="M305.8,596.6l-3.1-4.4c-0.3,0-0.7,0.1-1.1,0.1h-3.7v4.3h-1.4V583h5.1c1.7,0,3.1,0.4,4.1,1.2c1,0.8,1.5,2,1.5,3.4
        c0,1.1-0.3,2-0.8,2.7c-0.5,0.7-1.3,1.3-2.3,1.6l3.3,4.7H305.8z M304.6,590.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5
        c-0.7-0.6-1.7-0.9-3.1-0.9h-3.6v6.8h3.6C302.9,591.1,303.9,590.8,304.6,590.2z"/>
      <path d="M312.8,595.8c-1.1-0.6-2-1.4-2.6-2.5c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5
        c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9S313.9,596.4,312.8,595.8z M319.4,594.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9
        s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2c-0.9-0.5-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9
        s0.3,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.8,0.7,2.9,0.7S318.5,595.2,319.4,594.7z"/>
      <path d="M325.9,596.2c-0.7-0.3-1.2-0.8-1.6-1.4l0.9-1c0.8,1.1,1.7,1.7,2.9,1.7c1.7,0,2.5-1,2.5-3v-8.2h-5V583h6.4v9.4
        c0,1.4-0.3,2.5-1,3.2c-0.7,0.7-1.7,1.1-3,1.1C327.2,596.7,326.5,596.5,325.9,596.2z"/>
      <path d="M345.9,595.4v1.2h-9.6V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H345.9z"/>
      <path d="M351.5,595.8c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5c0.6-1.1,1.5-1.9,2.6-2.5
        c1.1-0.6,2.3-0.9,3.7-0.9c1,0,1.9,0.2,2.8,0.5c0.9,0.3,1.6,0.8,2.2,1.5l-0.9,0.9c-1.1-1.1-2.4-1.6-4-1.6c-1.1,0-2.1,0.2-2.9,0.7
        s-1.6,1.2-2.1,2s-0.7,1.8-0.7,2.9s0.3,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2c0.9,0.5,1.9,0.7,2.9,0.7c1.6,0,3-0.5,4-1.6l0.9,0.9
        c-0.6,0.6-1.3,1.1-2.2,1.5c-0.9,0.3-1.8,0.5-2.8,0.5C353.8,596.7,352.6,596.4,351.5,595.8z"/>
      <path d="M365.7,584.2h-4.8V583h11v1.2h-4.8v12.4h-1.4V584.2z"/>
      <path d="M379.3,583h5.5c1.4,0,2.7,0.3,3.8,0.9c1.1,0.6,2,1.4,2.6,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.5-0.9,3.5
        c-0.6,1-1.5,1.8-2.6,2.4c-1.1,0.6-2.4,0.9-3.8,0.9h-5.5V583z M384.7,595.4c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2.1-2
        c0.5-0.8,0.7-1.8,0.7-2.9c0-1.1-0.2-2.1-0.7-2.9s-1.2-1.5-2.1-2c-0.9-0.5-1.9-0.7-3.1-0.7h-4v11.1H384.7z"/>
      <path d="M405,595.4v1.2h-9.6V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H405z"/>
      <path d="M417.5,584.2c1,0.8,1.5,2,1.5,3.4c0,1.5-0.5,2.6-1.5,3.4c-1,0.8-2.3,1.2-4.1,1.2h-3.7v4.3h-1.4V583h5.1
        C415.2,583,416.5,583.4,417.5,584.2z M416.5,590.2c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5s-1.7-0.9-3.1-0.9h-3.6v6.8h3.6
        C414.7,591,415.8,590.7,416.5,590.2z"/>
      <path d="M422.1,583h1.4v12.4h7.6v1.2h-9.1V583z"/>
      <path d="M435.6,595.8c-1.1-0.6-2-1.4-2.6-2.5s-0.9-2.2-0.9-3.5s0.3-2.5,0.9-3.5s1.5-1.9,2.6-2.5s2.3-0.9,3.7-0.9s2.6,0.3,3.6,0.9
        c1.1,0.6,1.9,1.4,2.6,2.5c0.6,1.1,0.9,2.2,0.9,3.5s-0.3,2.5-0.9,3.5c-0.6,1.1-1.5,1.9-2.6,2.5s-2.3,0.9-3.6,0.9
        S436.7,596.4,435.6,595.8z M442.1,594.7c0.9-0.5,1.5-1.2,2-2c0.5-0.9,0.7-1.8,0.7-2.9s-0.2-2-0.7-2.9c-0.5-0.9-1.2-1.5-2-2
        c-0.9-0.5-1.8-0.7-2.9-0.7s-2.1,0.2-2.9,0.7c-0.9,0.5-1.6,1.2-2.1,2c-0.5,0.9-0.7,1.8-0.7,2.9s0.2,2,0.7,2.9c0.5,0.9,1.2,1.5,2.1,2
        c0.9,0.5,1.8,0.7,2.9,0.7S441.3,595.2,442.1,594.7z"/>
      <path d="M453.7,591.9v4.7h-1.4v-4.7l-5.4-8.9h1.5l4.7,7.6l4.7-7.6h1.4L453.7,591.9z"/>
      <path d="M475.2,583v13.6h-1.4v-10.9l-5.3,9.2h-0.7l-5.3-9.1v10.8h-1.4V583h1.2l5.9,10.1L474,583H475.2z"/>
      <path d="M489.2,595.4v1.2h-9.6V583h9.3v1.2h-7.9v4.8h7v1.2h-7v5.1H489.2z"/>
      <path d="M504,583v13.6h-1.2l-8.7-11v11h-1.4V583h1.2l8.8,11v-11H504z"/>
      <path d="M511.1,584.2h-4.8V583h11v1.2h-4.8v12.4h-1.4V584.2z"/>
      <path d="M518.1,596.4c-0.2-0.2-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7
        s-0.1,0.6-0.3,0.8s-0.5,0.3-0.7,0.3S518.3,596.6,518.1,596.4z"/>
    </g>
    <g class="st8">
      <path d="M169.3,689.2c-0.7-0.3-1.3-0.6-1.7-1l0.4-0.9c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.3,0.4,2,0.4c0.9,0,1.6-0.2,2.1-0.5
        c0.5-0.3,0.7-0.8,0.7-1.3c0-0.4-0.1-0.8-0.4-1s-0.6-0.4-0.9-0.6c-0.4-0.1-0.9-0.3-1.5-0.4c-0.8-0.2-1.4-0.4-1.9-0.6
        s-0.9-0.5-1.2-0.8s-0.5-0.9-0.5-1.5c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.7-0.8,1.3-1c0.6-0.3,1.3-0.4,2.2-0.4c0.6,0,1.2,0.1,1.8,0.2
        c0.6,0.2,1.1,0.4,1.5,0.7l-0.4,0.9c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.1-1-0.2-1.5-0.2c-0.9,0-1.6,0.2-2.1,0.5
        c-0.5,0.4-0.7,0.8-0.7,1.4c0,0.4,0.1,0.8,0.4,1s0.6,0.5,1,0.6c0.4,0.1,0.9,0.3,1.6,0.4c0.8,0.2,1.4,0.4,1.9,0.6
        c0.5,0.2,0.9,0.5,1.2,0.8s0.5,0.9,0.5,1.5c0,0.5-0.1,1-0.4,1.4c-0.3,0.4-0.7,0.8-1.3,1c-0.6,0.3-1.3,0.4-2.2,0.4
        C170.7,689.6,170,689.5,169.3,689.2z"/>
      <path d="M177.8,679h1.1v10.5h-1.1V679z"/>
      <path d="M189.7,684.3h1.1v4c-0.5,0.4-1.1,0.8-1.7,1c-0.7,0.2-1.4,0.3-2.1,0.3c-1,0-2-0.2-2.8-0.7s-1.5-1.1-2-1.9
        c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7s1.1-1.5,2-1.9c0.8-0.5,1.8-0.7,2.9-0.7c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1
        l-0.7,0.7c-0.8-0.8-1.9-1.2-3.2-1.2c-0.9,0-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6s-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2s0.9,1.2,1.6,1.6
        s1.4,0.6,2.3,0.6c1.1,0,2-0.3,2.7-0.8V684.3z"/>
      <path d="M202.7,679v10.5h-0.9l-6.8-8.5v8.5h-1.1V679h0.9l6.8,8.5V679H202.7z"/>
      <path d="M213.6,688.6v1h-7.4V679h7.2v1h-6.1v3.7h5.4v0.9h-5.4v3.9H213.6z"/>
      <path d="M216.2,679h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7
        c-0.5,0.8-1.1,1.4-2,1.9c-0.9,0.4-1.8,0.7-2.9,0.7h-4.3V679z M220.4,688.6c0.9,0,1.7-0.2,2.4-0.5c0.7-0.4,1.2-0.9,1.6-1.5
        c0.4-0.6,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.1-1.6-1.5s-1.5-0.5-2.4-0.5h-3.1v8.6H220.4z"/>
      <path d="M234.3,688.9c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.4,2-1.9
        c0.8-0.5,1.8-0.7,2.8-0.7s2,0.2,2.8,0.7c0.8,0.5,1.5,1.1,2,1.9s0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7c-0.5,0.8-1.1,1.5-2,1.9
        s-1.8,0.7-2.8,0.7S235.1,689.4,234.3,688.9z M239.3,688.1c0.7-0.4,1.2-0.9,1.6-1.6c0.4-0.7,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2
        c-0.4-0.7-0.9-1.2-1.6-1.6s-1.4-0.6-2.3-0.6s-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2
        c0.4,0.7,0.9,1.2,1.6,1.6s1.4,0.6,2.3,0.6S238.7,688.4,239.3,688.1z"/>
      <path d="M253.9,679v10.5h-0.9l-6.8-8.5v8.5h-1.1V679h0.9l6.8,8.5V679H253.9z"/>
      <path d="M269.4,685c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.6-1,2.1s-1.6,0.7-2.9,0.7h-4.8V679h4.5c1.2,0,2.1,0.2,2.7,0.7s1,1.1,1,2
        c0,0.6-0.1,1.1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.9C268.5,684.2,269,684.5,269.4,685z M262.3,679.9v3.8h3.3c0.8,0,1.5-0.2,2-0.5
        c0.5-0.3,0.7-0.8,0.7-1.4s-0.2-1.1-0.7-1.4c-0.5-0.3-1.1-0.5-2-0.5H262.3z M268.2,688.1c0.5-0.3,0.7-0.8,0.7-1.5c0-1.3-1-2-2.9-2
        h-3.7v3.9h3.7C267,688.6,267.7,688.4,268.2,688.1z"/>
      <path d="M279.9,688.6v1h-7.4V679h7.2v1h-6.1v3.7h5.4v0.9h-5.4v3.9H279.9z"/>
      <path d="M291.3,679v10.5h-1.1v-4.8h-6.6v4.8h-1.1V679h1.1v4.7h6.6V679H291.3z"/>
      <path d="M301.3,686.7h-5.9l-1.3,2.8h-1.2l4.8-10.5h1.1l4.8,10.5h-1.2L301.3,686.7z M300.9,685.8l-2.5-5.6l-2.5,5.6H300.9z"/>
      <path d="M305.5,679h1.1v9.5h5.9v1h-7V679z"/>
      <path d="M315.4,680v4.1h5.4v1h-5.4v4.5h-1.1V679h7.2v1H315.4z"/>
      <path d="M329.5,688.9c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.4,2-1.9s1.8-0.7,2.8-0.7
        s2,0.2,2.8,0.7c0.8,0.5,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7c-0.5,0.8-1.1,1.5-2,1.9s-1.8,0.7-2.8,0.7
        S330.4,689.4,329.5,688.9z M334.6,688.1c0.7-0.4,1.2-0.9,1.6-1.6s0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2s-0.9-1.2-1.6-1.6
        s-1.4-0.6-2.3-0.6s-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2c0.4,0.7,0.9,1.2,1.6,1.6
        s1.4,0.6,2.3,0.6S333.9,688.4,334.6,688.1z"/>
      <path d="M341.5,680v4.1h5.4v1h-5.4v4.5h-1.1V679h7.2v1H341.5z"/>
      <path d="M353.8,679h1.1v10.5h-1.1V679z"/>
      <path d="M364.9,686.7H359l-1.3,2.8h-1.2l4.8-10.5h1.1l4.8,10.5h-1.2L364.9,686.7z M364.5,685.8l-2.5-5.6l-2.5,5.6H364.5z"/>
      <path d="M369.1,679h1.1v10.5h-1.1V679z"/>
      <path d="M373.6,679h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9s0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7s-1.1,1.4-2,1.9
        c-0.8,0.4-1.8,0.7-2.9,0.7h-4.3V679z M377.8,688.6c0.9,0,1.7-0.2,2.4-0.5c0.7-0.4,1.2-0.9,1.6-1.5c0.4-0.6,0.6-1.4,0.6-2.2
        s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.1-1.6-1.5c-0.7-0.4-1.5-0.5-2.4-0.5h-3.1v8.6H377.8z"/>
      <path d="M386,679h1.1v9.5h5.9v1h-7V679z"/>
      <path d="M398.7,679h1.1v9.5h5.9v1h-7V679z"/>
      <path d="M407.3,689.3c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        s0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2C407.7,689.6,407.5,689.5,407.3,689.3z"/>
      <path d="M411.2,679h1.1v9.5h5.9v1h-7V679z"/>
      <path d="M419.8,689.3c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        s0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2C420.1,689.6,420,689.5,419.8,689.3z"/>
      <path d="M425.1,688.9c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.5,2-1.9
        c0.8-0.5,1.8-0.7,2.8-0.7c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1l-0.7,0.7c-0.8-0.8-1.9-1.2-3.1-1.2
        c-0.8,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2c0.4,0.7,0.9,1.2,1.6,1.6
        c0.7,0.4,1.4,0.6,2.3,0.6c1.3,0,2.3-0.4,3.1-1.3l0.7,0.7c-0.5,0.5-1,0.9-1.7,1.1c-0.7,0.3-1.4,0.4-2.2,0.4
        C426.9,689.6,426,689.4,425.1,688.9z"/>
    </g>
    <g class="st8">
      <path d="M260.8,727h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7
        c-0.5,0.8-1.1,1.4-2,1.9c-0.9,0.4-1.8,0.7-2.9,0.7h-4.3V727z M265,736.6c0.9,0,1.7-0.2,2.4-0.5c0.7-0.4,1.2-0.9,1.6-1.5
        c0.4-0.6,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.1-1.6-1.5s-1.5-0.5-2.4-0.5h-3.1v8.6H265z"/>
      <path d="M272.9,727.7c-0.2-0.2-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
        c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2C273.3,727.9,273.1,727.8,272.9,727.7z M273,729.6h1.1
        v7.9H273V729.6z"/>
      <path d="M279.1,730c0.5-0.3,1.1-0.4,1.8-0.4v1l-0.3,0c-0.8,0-1.5,0.3-2,0.8s-0.7,1.2-0.7,2.1v4H277v-7.9h1v1.5
        C278.3,730.6,278.6,730.2,279.1,730z"/>
      <path d="M289.7,733.9h-6.6c0.1,0.8,0.4,1.5,0.9,2c0.6,0.5,1.3,0.8,2.2,0.8c0.5,0,0.9-0.1,1.4-0.3c0.4-0.2,0.8-0.4,1.1-0.8l0.6,0.7
        c-0.4,0.4-0.8,0.7-1.3,1s-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.2-0.5c-0.6-0.3-1.1-0.8-1.5-1.4c-0.4-0.6-0.5-1.3-0.5-2.1
        c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.2-0.5,2-0.5s1.4,0.2,2,0.5c0.6,0.3,1,0.8,1.4,1.4
        c0.3,0.6,0.5,1.3,0.5,2.1L289.7,733.9z M284,731.2c-0.5,0.5-0.8,1.1-0.9,1.9h5.6c-0.1-0.8-0.4-1.4-0.9-1.9
        c-0.5-0.5-1.2-0.7-1.9-0.7C285.1,730.5,284.5,730.7,284,731.2z"/>
      <path d="M293.1,737.1c-0.6-0.3-1.1-0.8-1.4-1.4c-0.4-0.6-0.5-1.3-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1c0.4-0.6,0.8-1.1,1.4-1.4
        c0.6-0.3,1.3-0.5,2.1-0.5c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2l-0.8,0.5c-0.3-0.4-0.6-0.7-1-0.9s-0.8-0.3-1.3-0.3
        c-0.6,0-1.1,0.1-1.5,0.4s-0.8,0.6-1.1,1.1c-0.3,0.5-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.3,0.5,0.6,0.8,1.1,1.1
        c0.5,0.3,1,0.4,1.5,0.4c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,1-0.9l0.8,0.5c-0.3,0.5-0.7,0.9-1.3,1.2s-1.1,0.4-1.8,0.4
        C294.4,737.6,293.7,737.4,293.1,737.1z"/>
      <path d="M304.6,737c-0.2,0.2-0.4,0.3-0.7,0.4s-0.6,0.1-0.9,0.1c-0.7,0-1.3-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.7v-4.8h-1.4v-0.9h1.4
        v-1.7h1.1v1.7h2.4v0.9h-2.4v4.7c0,0.5,0.1,0.8,0.4,1.1c0.2,0.2,0.6,0.4,1,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3
        L304.6,737z"/>
      <path d="M307.4,737.1c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1c0.4-0.6,0.8-1.1,1.4-1.4
        c0.6-0.3,1.3-0.5,2.1-0.5s1.4,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.4,1.4c0.3,0.6,0.5,1.3,0.5,2.1c0,0.8-0.2,1.5-0.5,2.1
        c-0.3,0.6-0.8,1.1-1.4,1.4c-0.6,0.3-1.3,0.5-2.1,0.5S308,737.4,307.4,737.1z M311,736.3c0.4-0.3,0.8-0.6,1-1.1s0.4-1,0.4-1.6
        c0-0.6-0.1-1.1-0.4-1.6s-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.4-1.5-0.4c-0.6,0-1.1,0.1-1.5,0.4c-0.4,0.3-0.8,0.6-1.1,1.1
        c-0.3,0.5-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.3,0.5,0.6,0.8,1.1,1.1c0.4,0.3,0.9,0.4,1.5,0.4C310,736.6,310.5,736.5,311,736.3z
        "/>
      <path d="M317.8,730c0.5-0.3,1.1-0.4,1.8-0.4v1l-0.3,0c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.7,1.2-0.7,2.1v4h-1.1v-7.9h1v1.5
        C316.9,730.6,317.3,730.2,317.8,730z"/>
    </g>
    <text transform="matrix(1 0 0 1 47.8027 439.1592)" class="st12 st13" v-if="company">{{ company.toUpperCase() }}</text>
    <text transform="matrix(1 0 0 1 47.5532 369.7144)" class="st12 st14" v-if="product">{{ product.toUpperCase() }}</text>
    <g>
      <text transform="matrix(1 0 0 1 281.5576 656.7676)" class="st9 st15">{{ date }}</text>
      <g class="st8">
        <path d="M277.6,627.9l-3.4,7.7h-0.8l-3.4-7.7h0.9l3,6.7l3-6.7H277.6z"/>
        <path d="M283.3,633.5H279l-0.9,2.1h-0.8l3.5-7.7h0.8l3.5,7.7h-0.9L283.3,633.5z M283,632.9l-1.9-4.1l-1.9,4.1H283z"/>
        <path d="M286.4,627.9h0.8v7h4.3v0.7h-5.1V627.9z"/>
        <path d="M292.9,627.9h0.8v7.7h-0.8V627.9z"/>
        <path d="M296.2,627.9h3.1c0.8,0,1.5,0.2,2.2,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.3,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2
          c-0.3,0.6-0.8,1-1.5,1.4c-0.6,0.3-1.3,0.5-2.2,0.5h-3.1V627.9z M299.3,634.9c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.6,1.2-1.1
          c0.3-0.5,0.4-1,0.4-1.6s-0.1-1.2-0.4-1.6c-0.3-0.5-0.7-0.8-1.2-1.1c-0.5-0.3-1.1-0.4-1.8-0.4H297v6.3H299.3z"/>
        <path d="M309,628.6v3h4v0.7h-4v3.3h-0.8v-7.7h5.3v0.7H309z"/>
        <path d="M320.5,635.6l-1.8-2.5c-0.2,0-0.4,0-0.6,0H316v2.4h-0.8v-7.7h2.9c1,0,1.8,0.2,2.3,0.7s0.8,1.1,0.8,1.9
          c0,0.6-0.2,1.1-0.5,1.5c-0.3,0.4-0.7,0.7-1.3,0.9l1.9,2.6H320.5z M319.8,631.9c0.4-0.3,0.6-0.8,0.6-1.4c0-0.6-0.2-1.1-0.6-1.4
          c-0.4-0.3-1-0.5-1.8-0.5H316v3.9h2.1C318.8,632.4,319.4,632.3,319.8,631.9z"/>
        <path d="M324.5,635.1c-0.6-0.3-1.1-0.8-1.5-1.4c-0.3-0.6-0.5-1.3-0.5-2s0.2-1.4,0.5-2c0.3-0.6,0.8-1.1,1.5-1.4s1.3-0.5,2.1-0.5
          c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.5,1.3,0.5,2s-0.2,1.4-0.5,2c-0.4,0.6-0.8,1.1-1.5,1.4c-0.6,0.3-1.3,0.5-2.1,0.5
          C325.8,635.6,325.1,635.5,324.5,635.1z M328.2,634.5c0.5-0.3,0.9-0.7,1.2-1.1c0.3-0.5,0.4-1,0.4-1.6s-0.1-1.1-0.4-1.6
          c-0.3-0.5-0.7-0.9-1.2-1.1c-0.5-0.3-1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.1c-0.3,0.5-0.4,1-0.4,1.6
          s0.1,1.1,0.4,1.6c0.3,0.5,0.7,0.9,1.2,1.1c0.5,0.3,1,0.4,1.7,0.4C327.2,634.9,327.7,634.8,328.2,634.5z"/>
        <path d="M340.4,627.9v7.7h-0.8v-6.2l-3,5.2h-0.4l-3-5.2v6.1h-0.8v-7.7h0.7l3.4,5.7l3.3-5.7H340.4z"/>
      </g>
    </g>
    <g class="st8">
      <path d="M123.5,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M131.7,790.4v5.6h-0.6v-2.6h-3.5v2.6H127v-5.6h0.6v2.5h3.5v-2.5H131.7z"/>
      <path d="M133.5,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M136.3,795.9c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C137.1,796.1,136.7,796,136.3,795.9z"/>
      <path d="M143.9,795.7c-0.4-0.2-0.8-0.6-1-1s-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5s0.6-0.8,1.1-1c0.4-0.2,0.9-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4
        c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C144.8,796.1,144.3,796,143.9,795.7z"/>
      <path d="M152.7,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H152.7z"/>
      <path d="M157.9,796l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8H154v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H157.9z M157.4,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C156.7,793.8,157.1,793.6,157.4,793.4z"/>
      <path d="M160.8,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M164.3,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M167.3,791v2.2h2.9v0.5h-2.9v2.4h-0.6v-5.6h3.8v0.5H167.3z"/>
      <path d="M171.8,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M175.2,795.7c-0.4-0.2-0.8-0.6-1-1s-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5s0.6-0.8,1.1-1c0.4-0.2,0.9-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4
        c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C176.1,796.1,175.6,796,175.2,795.7z"/>
      <path d="M183.4,794.6h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6H184L183.4,794.6z M183.1,794.1l-1.3-3l-1.3,3H183.1z"/>
      <path d="M186.4,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M193.9,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H193.9z"/>
      <path d="M197.3,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M200.1,795.9c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C200.9,796.1,200.5,796,200.1,795.9z"/>
      <path d="M210.3,794.6h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6H211L210.3,794.6z M210.1,794.1l-1.3-3l-1.3,3H210.1z"/>
      <path d="M218.4,791c0.4,0.3,0.6,0.8,0.6,1.4c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.5-1.7,0.5h-1.5v1.8h-0.6v-5.6h2.1
        C217.4,790.4,218,790.6,218.4,791z M218,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5
        C217.2,793.8,217.7,793.6,218,793.4z"/>
      <path d="M224.1,796l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H224.1z M223.6,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C222.9,793.8,223.3,793.6,223.6,793.4z"/>
      <path d="M227,795.7c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1s1-0.4,1.5-0.4
        s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.4,0.2-0.9,0.4-1.5,0.4S227.4,796,227,795.7z M229.7,795.3c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2s-0.1-0.8-0.3-1.2
        c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.8-0.3,1.2
        s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3C228.9,795.6,229.3,795.5,229.7,795.3z"/>
      <path d="M236.5,791c0.4,0.3,0.6,0.8,0.6,1.4c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.5-1.7,0.5h-1.5v1.8h-0.6v-5.6h2.1
        C235.6,790.4,236.1,790.6,236.5,791z M236.1,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8
        h1.5C235.4,793.8,235.8,793.6,236.1,793.4z"/>
      <path d="M242.4,795.5v0.5h-4v-5.6h3.8v0.5H239v2h2.9v0.5H239v2.1H242.4z"/>
      <path d="M247.6,796l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H247.6z M247.1,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C246.4,793.8,246.8,793.6,247.1,793.4z"/>
      <path d="M250.6,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M256,794.1v1.9h-0.6v-1.9l-2.2-3.7h0.6l1.9,3.1l1.9-3.1h0.6L256,794.1z"/>
      <path d="M262.2,795.7c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1s1-0.4,1.5-0.4
        s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.4,0.2-0.9,0.4-1.5,0.4S262.6,796,262.2,795.7z M264.9,795.3c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2s-0.1-0.8-0.3-1.2
        c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.8-0.3,1.2
        s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3C264.1,795.6,264.5,795.5,264.9,795.3z"/>
      <path d="M268.5,791v2.2h2.9v0.5h-2.9v2.4h-0.6v-5.6h3.8v0.5H268.5z"/>
      <path d="M275.1,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M281,794.6h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L281,794.6z M280.8,794.1l-1.3-3l-1.3,3H280.8z"/>
      <path d="M283.2,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M285.7,790.4h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1s0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.5,0.2-1,0.4-1.6,0.4h-2.3V790.4z M287.9,795.5c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.7,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3h-1.7v4.6H287.9z"/>
      <path d="M292.3,790.4h0.6v5.1h3.1v0.5h-3.7V790.4z"/>
      <path d="M299.1,790.4h0.6v5.1h3.1v0.5h-3.7V790.4z"/>
      <path d="M303.7,796c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
        c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1C303.8,796.1,303.7,796,303.7,796z"/>
      <path d="M305.7,790.4h0.6v5.1h3.1v0.5h-3.7V790.4z"/>
      <path d="M310.3,796c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1S310.4,796,310.3,796z"/>
      <path d="M313.1,795.7c-0.4-0.2-0.8-0.6-1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.4-0.2,0.9-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.9,0.8c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4c-0.2,0.3-0.5,0.5-0.9,0.6s-0.7,0.2-1.2,0.2C314.1,796.1,313.6,796,313.1,795.7z"/>
      <path d="M323.6,794.6h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L323.6,794.6z M323.3,794.1l-1.3-3l-1.3,3H323.3z"/>
      <path d="M330.5,790.4v5.6H330l-3.6-4.5v4.5h-0.6v-5.6h0.5l3.6,4.5v-4.5H330.5z"/>
      <path d="M332.3,790.4h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.2-1,0.4-1.6,0.4h-2.3V790.4z M334.5,795.5c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8
        c0.2-0.3,0.3-0.7,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8s-0.8-0.3-1.3-0.3h-1.7v4.6H334.5z"/>
      <path d="M346.8,790.4v5.6h-0.6v-4.5l-2.2,3.8h-0.3l-2.2-3.7v4.5H341v-5.6h0.5l2.4,4.2l2.4-4.2H346.8z"/>
      <path d="M349.2,795.5c-0.4-0.4-0.6-1-0.6-1.8v-3.2h0.6v3.2c0,0.7,0.1,1.1,0.4,1.5c0.3,0.3,0.7,0.5,1.3,0.5c0.6,0,1-0.2,1.3-0.5
        s0.4-0.8,0.4-1.5v-3.2h0.6v3.2c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-1,0.6-1.7,0.6S349.6,795.9,349.2,795.5z"/>
      <path d="M355.3,795.9c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4s0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C356.1,796.1,355.7,796,355.3,795.9z"/>
      <path d="M360.9,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M370.9,793.6c0.2,0.2,0.3,0.5,0.3,0.9c0,0.5-0.2,0.8-0.5,1.1s-0.9,0.4-1.6,0.4h-2.6v-5.6h2.4c0.6,0,1.1,0.1,1.5,0.4
        c0.3,0.3,0.5,0.6,0.5,1.1c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.2-0.4,0.4-0.6,0.5C370.4,793.2,370.7,793.4,370.9,793.6z M367.1,790.9v2
        h1.8c0.5,0,0.8-0.1,1-0.3c0.2-0.2,0.4-0.4,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-1-0.3H367.1z M370.2,795.3
        c0.3-0.2,0.4-0.4,0.4-0.8c0-0.7-0.5-1-1.5-1h-2v2.1h2C369.6,795.6,369.9,795.5,370.2,795.3z"/>
      <path d="M376.5,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H376.5z"/>
      <path d="M383.8,796l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8H380v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H383.8z M383.3,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C382.6,793.8,383,793.6,383.3,793.4z"/>
      <path d="M389.7,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H389.7z"/>
      <path d="M392.2,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M396.2,795.5c-0.4-0.4-0.6-1-0.6-1.8v-3.2h0.6v3.2c0,0.7,0.1,1.1,0.4,1.5c0.3,0.3,0.7,0.5,1.3,0.5c0.6,0,1-0.2,1.3-0.5
        c0.3-0.3,0.4-0.8,0.4-1.5v-3.2h0.6v3.2c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-1,0.6-1.7,0.6C397.1,796.1,396.6,795.9,396.2,795.5z"/>
      <path d="M405.8,796l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H405.8z M405.3,793.4c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C404.6,793.8,405,793.6,405.3,793.4z"/>
      <path d="M412.4,790.4v5.6h-0.5l-3.6-4.5v4.5h-0.6v-5.6h0.5l3.6,4.5v-4.5H412.4z"/>
      <path d="M418.2,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H418.2z"/>
      <path d="M419.6,790.4h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.2-1,0.4-1.6,0.4h-2.3V790.4z M421.8,795.5c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8
        c0.2-0.3,0.3-0.7,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8s-0.8-0.3-1.3-0.3h-1.7v4.6H421.8z"/>
      <path d="M428.3,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M436.5,790.4v5.6h-0.6v-4.5l-2.2,3.8h-0.3l-2.2-3.7v4.5h-0.6v-5.6h0.5l2.4,4.2l2.4-4.2H436.5z"/>
      <path d="M444.1,790.4v5.6h-0.6v-4.5l-2.2,3.8h-0.3l-2.2-3.7v4.5h-0.6v-5.6h0.5l2.4,4.2l2.4-4.2H444.1z"/>
      <path d="M449.9,795.5v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H449.9z"/>
      <path d="M451.3,790.4h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.2-1,0.4-1.6,0.4h-2.3V790.4z M453.6,795.5c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8
        c0.2-0.3,0.3-0.7,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8s-0.8-0.3-1.3-0.3h-1.7v4.6H453.6z"/>
      <path d="M457.9,790.4h0.6v5.6h-0.6V790.4z"/>
      <path d="M463.9,794.6h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L463.9,794.6z M463.6,794.1l-1.3-3l-1.3,3H463.6z"/>
      <path d="M466.9,791h-2v-0.5h4.5v0.5h-2v5.1h-0.6V791z"/>
      <path d="M474.4,795.5v0.5h-4v-5.6h3.8v0.5H471v2h2.9v0.5H471v2.1H474.4z"/>
      <path d="M475.7,790.4h0.6v5.1h3.1v0.5h-3.7V790.4z"/>
      <path d="M481.8,794.1v1.9h-0.6v-1.9l-2.2-3.7h0.6l1.9,3.1l1.9-3.1h0.6L481.8,794.1z"/>
    </g>
    <g class="st8">
      <path d="M136.4,805.1c-0.4-0.4-0.6-1-0.6-1.8V800h0.6v3.2c0,0.7,0.1,1.1,0.4,1.5s0.7,0.5,1.3,0.5c0.6,0,1-0.2,1.3-0.5
        c0.3-0.3,0.4-0.8,0.4-1.5V800h0.6v3.2c0,0.8-0.2,1.4-0.6,1.8s-1,0.6-1.7,0.6C137.3,805.7,136.8,805.5,136.4,805.1z"/>
      <path d="M145.9,800.6c0.4,0.3,0.6,0.8,0.6,1.4c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.5-1.7,0.5h-1.5v1.8h-0.6V800h2.1
        C145,800,145.5,800.2,145.9,800.6z M145.5,803c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5
        C144.8,803.4,145.2,803.2,145.5,803z"/>
      <path d="M148.8,805.3c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1s1-0.4,1.5-0.4
        s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.4,0.2-0.9,0.4-1.5,0.4S149.3,805.6,148.8,805.3z M151.5,804.9c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8
        c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3C150.8,805.2,151.2,805.1,151.5,804.9z
        "/>
      <path d="M159.3,800v5.6h-0.5l-3.6-4.5v4.5h-0.6V800h0.5l3.6,4.5V800H159.3z"/>
      <path d="M167,805.6l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6V800h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H167z M166.6,803c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C165.8,803.4,166.3,803.2,166.6,803z"/>
      <path d="M172.9,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H172.9z"/>
      <path d="M179.9,806.1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.2-0.6-0.4-1-0.8
        c-0.5,0-1-0.1-1.5-0.4c-0.4-0.3-0.8-0.6-1-1c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1s1-0.4,1.5-0.4
        s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-0.8,1
        c-0.3,0.3-0.8,0.4-1.2,0.5c0.4,0.4,0.8,0.6,1.2,0.6c0.4,0,0.8-0.2,1.1-0.5L179.9,806.1z M174.7,804c0.2,0.4,0.5,0.6,0.8,0.8
        c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.7,0.3-1.2s-0.1-0.8-0.3-1.2
        c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.7-0.3,1.2
        S174.5,803.7,174.7,804z"/>
      <path d="M181.6,805.1c-0.4-0.4-0.6-1-0.6-1.8V800h0.6v3.2c0,0.7,0.1,1.1,0.4,1.5s0.7,0.5,1.3,0.5c0.6,0,1-0.2,1.3-0.5
        c0.3-0.3,0.4-0.8,0.4-1.5V800h0.6v3.2c0,0.8-0.2,1.4-0.6,1.8s-1,0.6-1.7,0.6C182.6,805.7,182,805.5,181.6,805.1z"/>
      <path d="M191.3,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H191.3z"/>
      <path d="M193.1,805.5c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C193.8,805.7,193.4,805.6,193.1,805.5z"/>
      <path d="M198.6,800.6h-2V800h4.5v0.5h-2v5.1h-0.6V800.6z"/>
      <path d="M201.5,805.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
        c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1C201.7,805.7,201.6,805.6,201.5,805.6z"/>
      <path d="M206.8,800.6h-2V800h4.5v0.5h-2v5.1h-0.6V800.6z"/>
      <path d="M211.1,805.3c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1s1-0.4,1.5-0.4
        s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.4,0.2-0.9,0.4-1.5,0.4S211.6,805.6,211.1,805.3z M213.8,804.9c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8
        c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3C213.1,805.2,213.5,805.1,213.8,804.9z
        "/>
      <path d="M219.9,805.3c-0.4-0.2-0.8-0.6-1-1s-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5s0.6-0.8,1.1-1c0.4-0.2,0.9-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4
        c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C220.9,805.7,220.4,805.6,219.9,805.3z"/>
      <path d="M229.4,800v5.6h-0.6v-2.6h-3.5v2.6h-0.6V800h0.6v2.5h3.5V800H229.4z"/>
      <path d="M235.2,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H235.2z"/>
      <path d="M237.5,805.3c-0.4-0.2-0.8-0.6-1-1s-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5s0.6-0.8,1.1-1c0.4-0.2,0.9-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4
        c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C238.5,805.7,238,805.6,237.5,805.3z"/>
      <path d="M244.1,803l-1.1,1.2v1.5h-0.6V800h0.6v3.4l3.3-3.4h0.7l-2.4,2.5l2.6,3.1h-0.7L244.1,803z"/>
      <path d="M250.1,800h0.6v5.6h-0.6V800z"/>
      <path d="M253.6,800.6h-2V800h4.5v0.5h-2v5.1h-0.6V800.6z"/>
      <path d="M257.4,805.5c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C258.2,805.7,257.8,805.6,257.4,805.5z"/>
      <path d="M268.7,800l-2.5,5.6h-0.6l-2.5-5.6h0.6l2.1,4.9l2.2-4.9H268.7z"/>
      <path d="M272.9,804.2h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L272.9,804.2z M272.6,803.7l-1.3-3l-1.3,3H272.6z"/>
      <path d="M275.1,800h0.6v5.1h3.1v0.5h-3.7V800z"/>
      <path d="M279.8,800h0.6v5.6h-0.6V800z"/>
      <path d="M282.2,800h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1s0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.5,0.2-1,0.4-1.6,0.4h-2.3V800z M284.5,805.1c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.7,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3h-1.7v4.6H284.5z"/>
      <path d="M290.5,803.7v1.9h-0.6v-1.9l-2.2-3.7h0.6l1.9,3.1l1.9-3.1h0.6L290.5,803.7z"/>
      <path d="M299.5,800.6c0.4,0.3,0.6,0.8,0.6,1.4c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.5-1.7,0.5h-1.5v1.8h-0.6V800h2.1
        C298.6,800,299.1,800.2,299.5,800.6z M299.1,803c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5
        C298.4,803.4,298.8,803.2,299.1,803z"/>
      <path d="M301.4,800h0.6v5.1h3.1v0.5h-3.7V800z"/>
      <path d="M310.1,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H310.1z"/>
      <path d="M315,804.2h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L315,804.2z M314.8,803.7l-1.3-3l-1.3,3H314.8z"/>
      <path d="M317.6,805.5c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4s0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C318.3,805.7,317.9,805.6,317.6,805.5z"/>
      <path d="M326.1,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H326.1z"/>
      <path d="M337.3,800l-1.9,5.6h-0.6l-1.6-4.8l-1.6,4.8h-0.6L329,800h0.6l1.6,4.8l1.7-4.8h0.6l1.6,4.8l1.6-4.8H337.3z"/>
      <path d="M342.2,805.6l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6V800h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H342.2z M341.7,803c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C341,803.4,341.4,803.2,341.7,803z"/>
      <path d="M344.1,800h0.6v5.6h-0.6V800z"/>
      <path d="M347.6,800.6h-2V800h4.5v0.5h-2v5.1h-0.6V800.6z"/>
      <path d="M355.1,805.1v0.5h-4V800h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H355.1z"/>
      <path d="M359.7,800.6h-2V800h4.5v0.5h-2v5.1h-0.6V800.6z"/>
      <path d="M364,805.3c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.5-0.2,1-0.4,1.5-0.4s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.4,0.2-0.9,0.4-1.5,0.4S364.5,805.6,364,805.3z M366.8,804.9c0.4-0.2,0.6-0.5,0.8-0.8
        c0.2-0.4,0.3-0.8,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        C366,805.2,366.4,805.1,366.8,804.9z"/>
      <path d="M371.9,800h0.6v5.6h-0.6V800z"/>
      <path d="M377.8,804.2h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L377.8,804.2z M377.6,803.7l-1.3-3l-1.3,3H377.6z"/>
      <path d="M380.1,800h0.6v5.6h-0.6V800z"/>
      <path d="M382.5,800h2.3c0.6,0,1.1,0.1,1.6,0.4s0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.5,0.2-1,0.4-1.6,0.4h-2.3V800z M384.7,805.1c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.7,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3h-1.7v4.6H384.7z"/>
      <path d="M389.1,800h0.6v5.1h3.1v0.5h-3.7V800z"/>
      <path d="M397,800v5.6h-0.6v-5.1h-1.3V800H397z"/>
      <path d="M401.6,802.5c0.3,0.1,0.5,0.3,0.7,0.6c0.2,0.3,0.2,0.5,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.6
        c-0.3,0.1-0.6,0.2-1,0.2c-0.7,0-1.3-0.2-1.7-0.7c-0.4-0.5-0.6-1.2-0.6-2.1c0-0.6,0.1-1.2,0.3-1.6c0.2-0.4,0.5-0.8,0.9-1
        c0.4-0.2,0.8-0.3,1.3-0.3c0.6,0,1,0.1,1.3,0.3l-0.2,0.5c-0.3-0.2-0.6-0.3-1.1-0.3c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.5,1-0.5,1.7
        c0,0.1,0,0.3,0,0.5c0.1-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.7-0.3,1-0.3C401,802.3,401.3,802.4,401.6,802.5z M401.6,804.9
        c0.3-0.2,0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9s-0.6-0.3-1-0.3c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4s-0.2,0.4-0.2,0.6
        c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.2,0.8,0.2C401,805.2,401.3,805.1,401.6,804.9z"/>
      <path d="M404.2,805.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.5s0.1-1.1,0.3-1.5c0.2-0.4,0.5-0.8,0.8-1s0.7-0.3,1.2-0.3
        c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1
        c-0.3,0.2-0.7,0.3-1.2,0.3C405,805.7,404.6,805.6,404.2,805.4z M406.3,804.9c0.2-0.2,0.4-0.4,0.6-0.8s0.2-0.8,0.2-1.3
        c0-0.5-0.1-0.9-0.2-1.3s-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.6,0.8s-0.2,0.8-0.2,1.3
        c0,0.5,0.1,0.9,0.2,1.3s0.3,0.6,0.6,0.8s0.5,0.3,0.9,0.3S406,805.1,406.3,804.9z"/>
      <path d="M411.6,802.9c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.2,0.9s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
        c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.6-0.3-0.9-0.5l0.3-0.5c0.2,0.2,0.4,0.3,0.7,0.5s0.6,0.2,1,0.2c0.5,0,0.8-0.1,1.1-0.3
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3h-0.4v-0.4l1.6-1.9h-2.9V800h3.6v0.4l-1.6,2
        C410.9,802.5,411.3,802.6,411.6,802.9z"/>
      <path d="M416.4,805.3c-0.4-0.2-0.8-0.6-1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.4-0.2,0.9-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.9,0.8c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C417.4,805.7,416.9,805.6,416.4,805.3z"/>
      <path d="M423.8,801.8c0.3,0.3,0.4,0.7,0.4,1.2v2.6h-0.5V805c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.9,0.2
        c-0.5,0-0.8-0.1-1.1-0.3s-0.4-0.5-0.4-0.9c0-0.4,0.1-0.6,0.4-0.9c0.3-0.2,0.7-0.3,1.2-0.3h1.3V803c0-0.4-0.1-0.6-0.3-0.8
        s-0.5-0.3-0.9-0.3c-0.3,0-0.5,0-0.8,0.1s-0.5,0.2-0.6,0.4l-0.3-0.4c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,0.9-0.1
        C423.1,801.4,423.5,801.5,423.8,801.8z M423.1,805c0.2-0.1,0.4-0.4,0.5-0.6v-0.7h-1.3c-0.7,0-1.1,0.3-1.1,0.8
        c0,0.2,0.1,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2C422.6,805.2,422.9,805.2,423.1,805z"/>
      <path d="M429,801.7c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.4,0.6-0.7,0.8
        c-0.3,0.2-0.7,0.3-1.1,0.3c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.6v2.3h-0.6v-5.8h0.5v0.8c0.2-0.3,0.4-0.5,0.7-0.6
        s0.6-0.2,0.9-0.2C428.3,801.4,428.7,801.5,429,801.7z M428.7,805c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.3,0.2-0.5,0.2-0.9
        c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.6s-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6
        s-0.2,0.5-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9s0.3,0.4,0.6,0.6c0.2,0.1,0.5,0.2,0.8,0.2C428.2,805.2,428.5,805.1,428.7,805z"/>
      <path d="M431.2,800.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
        c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1C431.4,800.5,431.3,800.5,431.2,800.4z M431.2,801.4h0.6v4.2h-0.6
        V801.4z"/>
      <path d="M435.6,805.4c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3,0.1-0.5,0.1c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-2.5h-0.8v-0.5
        h0.8v-0.9h0.6v0.9h1.3v0.5H434v2.5c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1s0.2-0.1,0.3-0.2
        L435.6,805.4z"/>
      <path d="M437.1,805.4c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1s0.4-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.1-0.3
        s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.4,0.6-0.8,0.8
        c-0.3,0.2-0.7,0.3-1.1,0.3S437.4,805.6,437.1,805.4z M439,805c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.3,0.2-0.5,0.2-0.9
        c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.3-0.4-0.6-0.6s-0.5-0.2-0.8-0.2s-0.6,0.1-0.8,0.2s-0.4,0.3-0.6,0.6c-0.1,0.3-0.2,0.5-0.2,0.9
        c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.4,0.6,0.6s0.5,0.2,0.8,0.2S438.7,805.1,439,805z"/>
      <path d="M441.5,799.7h0.6v5.9h-0.6V799.7z"/>
      <path d="M449.3,804.2h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6H450L449.3,804.2z M449.1,803.7l-1.3-3l-1.3,3H449.1z"/>
      <path d="M454.8,801.4l-1.9,4.2h-0.6l-1.9-4.2h0.6l1.6,3.6l1.6-3.6H454.8z"/>
      <path d="M459.1,803.7h-3.5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.3,0.4
        c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1
        c0-0.4,0.1-0.8,0.3-1.1s0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.3c0.3,0.2,0.6,0.4,0.7,0.8c0.2,0.3,0.3,0.7,0.3,1.1
        L459.1,803.7z M456.1,802.3c-0.3,0.3-0.4,0.6-0.5,1h3c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.6-0.4-1-0.4
        C456.7,801.9,456.3,802,456.1,802.3z"/>
      <path d="M460,805.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1S460.1,805.6,460,805.6z"/>
    </g>
    <g class="st8">
      <path d="M146.9,815.1c-0.4-0.1-0.7-0.3-0.9-0.5l0.2-0.5c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.7,0.2,1,0.2c0.5,0,0.9-0.1,1.1-0.3
        c0.3-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.2c-0.4-0.1-0.8-0.2-1-0.3
        c-0.3-0.1-0.5-0.2-0.6-0.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.2,1.2-0.2
        c0.3,0,0.6,0,0.9,0.1s0.6,0.2,0.8,0.4l-0.2,0.5c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.1,0.3
        c-0.2,0.2-0.4,0.4-0.4,0.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.7,0.2,1,0.3
        c0.3,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.2,0.8s-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        C147.6,815.3,147.2,815.2,146.9,815.1z"/>
      <path d="M155.1,811v4.2h-0.5v-0.8c-0.1,0.3-0.4,0.5-0.6,0.6s-0.6,0.2-0.9,0.2c-0.5,0-1-0.2-1.3-0.5s-0.5-0.8-0.5-1.3V811h0.6v2.4
        c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V811H155.1z"/>
      <path d="M156.7,810c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
        c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1C156.9,810.1,156.8,810.1,156.7,810z M156.7,811h0.6v4.2h-0.6V811z"
        />
      <path d="M161.1,815c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3,0.1-0.5,0.1c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.3-0.5-0.3-0.9v-2.5h-0.8V811h0.8
        v-0.9h0.6v0.9h1.3v0.5h-1.3v2.5c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1s0.2-0.1,0.3-0.2L161.1,815z"/>
      <path d="M165.6,813.3h-3.5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.3,0.4
        c-0.2,0.2-0.4,0.4-0.7,0.5s-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1
        c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.6,0.4,0.7,0.8
        c0.2,0.3,0.3,0.7,0.3,1.1L165.6,813.3z M162.6,811.9c-0.3,0.3-0.4,0.6-0.5,1h3c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.6-0.4-1-0.4
        C163.2,811.5,162.8,811.6,162.6,811.9z"/>
      <path d="M171.7,812.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
        c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.6-0.3-0.9-0.5l0.3-0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.6,0.2,1,0.2c0.5,0,0.8-0.1,1.1-0.3
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3h-0.4v-0.4l1.6-1.9h-2.9v-0.5h3.6v0.4l-1.6,2
        C171,812.1,171.4,812.2,171.7,812.5z"/>
      <path d="M174.6,809.6v5.6H174v-5.1h-1.3v-0.5H174.6z"/>
      <path d="M177,815c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.5s0.1-1.1,0.3-1.5c0.2-0.4,0.5-0.8,0.8-1c0.3-0.2,0.7-0.3,1.2-0.3
        s0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1
        c-0.3,0.2-0.7,0.3-1.2,0.3S177.3,815.2,177,815z M179,814.5c0.2-0.2,0.4-0.4,0.6-0.8s0.2-0.8,0.2-1.3c0-0.5-0.1-0.9-0.2-1.3
        s-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.6,0.8s-0.2,0.8-0.2,1.3c0,0.5,0.1,0.9,0.2,1.3
        s0.3,0.6,0.6,0.8s0.5,0.3,0.9,0.3S178.7,814.7,179,814.5z"/>
      <path d="M187.3,813.8h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6H188L187.3,813.8z M187.1,813.3l-1.3-3l-1.3,3H187.1z"/>
      <path d="M192.3,812.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
        c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.6-0.3-0.9-0.5l0.3-0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.6,0.2,1,0.2c0.5,0,0.8-0.1,1.1-0.3
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3h-0.4v-0.4l1.6-1.9H189v-0.5h3.6v0.4l-1.6,2
        C191.6,812.1,192,812.2,192.3,812.5z"/>
      <path d="M196.8,812.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
        c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.6-0.3-0.9-0.5l0.3-0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.6,0.2,1,0.2c0.5,0,0.8-0.1,1.1-0.3
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3h-0.4v-0.4l1.6-1.9h-2.9v-0.5h3.6v0.4l-1.6,2
        C196.1,812.1,196.5,812.2,196.8,812.5z"/>
      <path d="M201.3,812.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
        c-0.4,0-0.8-0.1-1.1-0.2c-0.4-0.1-0.6-0.3-0.9-0.5l0.3-0.5c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.6,0.2,1,0.2c0.5,0,0.8-0.1,1.1-0.3
        c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3h-0.4v-0.4l1.6-1.9h-2.9v-0.5h3.6v0.4l-1.6,2
        C200.5,812.1,201,812.2,201.3,812.5z"/>
      <path d="M206.1,814.9c-0.4-0.2-0.8-0.6-1-1s-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5s0.6-0.8,1.1-1c0.4-0.2,0.9-0.4,1.5-0.4
        c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4
        c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C207,815.3,206.5,815.2,206.1,814.9z"/>
      <path d="M214.2,811.5c0.3,0.3,0.5,0.8,0.5,1.3v2.4h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.5,0-0.8,0.1-1.1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2h-0.6v-5.9h0.6v2.5c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.1,0.6-0.2,0.9-0.2
        C213.5,811,213.9,811.2,214.2,811.5z"/>
      <path d="M219.9,813.3h-3.5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.3,0.4
        c-0.2,0.2-0.4,0.4-0.7,0.5s-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1
        c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.6,0.4,0.7,0.8
        c0.2,0.3,0.3,0.7,0.3,1.1L219.9,813.3z M216.8,811.9c-0.3,0.3-0.4,0.6-0.5,1h3c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.6-0.4-1-0.4
        C217.4,811.5,217.1,811.6,216.8,811.9z"/>
      <path d="M224.5,811l-2.1,4.7c-0.2,0.4-0.4,0.7-0.6,0.8c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        l0.3-0.4c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.3,0.4-0.5l0.2-0.4l-1.9-4.2h0.6l1.6,3.6l1.6-3.6H224.5z"/>
      <path d="M228.8,813.3h-3.5c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.3,0.4
        c-0.2,0.2-0.4,0.4-0.7,0.5s-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1
        c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.6,0.4,0.7,0.8
        c0.2,0.3,0.3,0.7,0.3,1.1L228.8,813.3z M225.8,811.9c-0.3,0.3-0.4,0.6-0.5,1h3c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.6-0.4-1-0.4
        C226.4,811.5,226.1,811.6,225.8,811.9z"/>
      <path d="M233.4,811.5c0.3,0.3,0.5,0.8,0.5,1.3v2.4h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.5,0-0.8,0.1-1.1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2H230V811h0.5v0.8c0.2-0.3,0.4-0.5,0.6-0.6c0.3-0.1,0.6-0.2,1-0.2C232.7,811,233.1,811.2,233.4,811.5z
        "/>
      <path d="M238.8,811.5c0.3,0.3,0.5,0.8,0.5,1.3v2.4h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.5,0-0.8,0.1-1.1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2h-0.6V811h0.5v0.8c0.2-0.3,0.4-0.5,0.6-0.6c0.3-0.1,0.6-0.2,1-0.2C238.1,811,238.5,811.2,238.8,811.5
        z"/>
      <path d="M244.5,813.3H241c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.3,0.4
        c-0.2,0.2-0.4,0.4-0.7,0.5s-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1
        c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.6,0.4,0.7,0.8
        c0.2,0.3,0.3,0.7,0.3,1.1L244.5,813.3z M241.5,811.9c-0.3,0.3-0.4,0.6-0.5,1h3c0-0.4-0.2-0.8-0.5-1c-0.3-0.3-0.6-0.4-1-0.4
        C242.1,811.5,241.8,811.6,241.5,811.9z"/>
      <path d="M246.1,814.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3l-0.3,1.1h-0.4l0.3-1.2
        c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1S246,814.5,246.1,814.5z"/>
      <path d="M257.3,809.6l-1.9,5.6h-0.6l-1.6-4.8l-1.6,4.8h-0.6l-1.9-5.6h0.6l1.6,4.8l1.7-4.8h0.6l1.6,4.8l1.6-4.8H257.3z"/>
      <path d="M261.8,811l-2.1,4.7c-0.2,0.4-0.4,0.7-0.6,0.8c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
        l0.3-0.4c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.3,0.4-0.5l0.2-0.4l-1.9-4.2h0.6l1.6,3.6l1.6-3.6H261.8z"/>
      <path d="M263.1,815c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1s0.4-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.1-0.3
        c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.3,1.1s-0.4,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.1,0.3
        C263.8,815.3,263.4,815.2,263.1,815z M265,814.6c0.2-0.1,0.4-0.3,0.6-0.6s0.2-0.5,0.2-0.9c0-0.3-0.1-0.6-0.2-0.9s-0.3-0.4-0.6-0.6
        s-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2s-0.4,0.3-0.6,0.6c-0.1,0.3-0.2,0.5-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9
        c0.1,0.3,0.3,0.4,0.6,0.6s0.5,0.2,0.8,0.2C264.5,814.8,264.8,814.7,265,814.6z"/>
      <path d="M274,811.5c0.3,0.3,0.5,0.8,0.5,1.3v2.4h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2h-0.6V811h0.5v0.8c0.1-0.3,0.4-0.5,0.6-0.6s0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,0.9,0.2s0.5,0.4,0.6,0.7
        c0.2-0.3,0.4-0.5,0.7-0.7s0.6-0.2,1-0.2C273.3,811,273.7,811.2,274,811.5z"/>
      <path d="M276,810c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
        c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1C276.1,810.1,276,810.1,276,810z M276,811h0.6v4.2H276V811z"/>
      <path d="M281.5,811.5c0.3,0.3,0.5,0.8,0.5,1.3v2.4h-0.6v-2.4c0-0.4-0.1-0.8-0.3-1s-0.5-0.3-0.9-0.3c-0.5,0-0.8,0.1-1.1,0.4
        c-0.3,0.3-0.4,0.6-0.4,1.1v2.2h-0.6V811h0.5v0.8c0.2-0.3,0.4-0.5,0.6-0.6c0.3-0.1,0.6-0.2,1-0.2C280.8,811,281.2,811.2,281.5,811.5
        z"/>
      <path d="M287.4,811v3.7c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.3-0.9,0.5-1.6,0.5c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5l0.3-0.4
        c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.1,1.2-0.4c0.3-0.2,0.4-0.6,0.4-1.2v-0.5c-0.2,0.3-0.4,0.5-0.7,0.6
        c-0.3,0.1-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.7,0.3-1.1
        c0.2-0.3,0.4-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.4,0.7,0.6V811H287.4z M286.1,814.4
        c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.2,0.2-0.5,0.2-0.8s-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5s-0.5-0.2-0.8-0.2
        c-0.3,0-0.6,0.1-0.8,0.2s-0.4,0.3-0.6,0.5c-0.1,0.2-0.2,0.5-0.2,0.8s0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.6,0.5
        c0.2,0.1,0.5,0.2,0.8,0.2C285.6,814.6,285.9,814.5,286.1,814.4z"/>
      <path d="M294.8,812.8c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.4,0.4-0.8,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
        c-0.7,0-1.2-0.1-1.6-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.8-0.5c-0.3-0.1-0.5-0.3-0.7-0.5
        c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.5,0.2-0.8,0.5-1.1c0.4-0.3,0.8-0.4,1.4-0.4c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.7,0.5
        c0.2,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.4-0.7,0.5C294.3,812.4,294.6,812.6,294.8,812.8z M294.1,814.5
        c0.3-0.2,0.4-0.5,0.4-0.8s-0.1-0.6-0.4-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,0.8
        c0,0.4,0.1,0.6,0.4,0.8s0.7,0.3,1.2,0.3S293.8,814.7,294.1,814.5z M291.9,811.8c0.2,0.2,0.6,0.3,1,0.3s0.8-0.1,1-0.3
        s0.4-0.4,0.4-0.7c0-0.3-0.1-0.6-0.4-0.7c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.8,0.1-1,0.3c-0.2,0.2-0.4,0.4-0.4,0.7
        C291.5,811.4,291.6,811.6,291.9,811.8z"/>
      <path d="M299.7,814.7v0.5h-3.9v-0.4l2.3-2.3c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.8
        c-0.2-0.2-0.6-0.3-1-0.3c-0.7,0-1.2,0.2-1.5,0.6l-0.4-0.4c0.2-0.3,0.5-0.5,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.6,0,1,0.1,1.4,0.4
        c0.3,0.3,0.5,0.6,0.5,1.1c0,0.3-0.1,0.6-0.2,0.8s-0.4,0.6-0.7,0.9l-1.9,1.9H299.7z"/>
      <path d="M301.5,815c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.5s0.1-1.1,0.3-1.5c0.2-0.4,0.5-0.8,0.8-1
        c0.3-0.2,0.7-0.3,1.2-0.3c0.4,0,0.8,0.1,1.2,0.3s0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1
        s-0.7,0.3-1.2,0.3C302.2,815.3,301.8,815.2,301.5,815z M303.5,814.5c0.2-0.2,0.4-0.4,0.6-0.8s0.2-0.8,0.2-1.3
        c0-0.5-0.1-0.9-0.2-1.3s-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.6,0.8s-0.2,0.8-0.2,1.3
        c0,0.5,0.1,0.9,0.2,1.3s0.3,0.6,0.6,0.8s0.5,0.3,0.9,0.3S303.2,814.7,303.5,814.5z"/>
      <path d="M306.7,815c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.5s0.1-1.1,0.3-1.5c0.2-0.4,0.5-0.8,0.8-1
        c0.3-0.2,0.7-0.3,1.2-0.3c0.4,0,0.8,0.1,1.2,0.3s0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.3,1.5s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1
        s-0.7,0.3-1.2,0.3C307.5,815.3,307.1,815.2,306.7,815z M308.8,814.5c0.2-0.2,0.4-0.4,0.6-0.8s0.2-0.8,0.2-1.3
        c0-0.5-0.1-0.9-0.2-1.3s-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.3-0.9-0.3s-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.4-0.6,0.8s-0.2,0.8-0.2,1.3
        c0,0.5,0.1,0.9,0.2,1.3s0.3,0.6,0.6,0.8s0.5,0.3,0.9,0.3S308.5,814.7,308.8,814.5z"/>
      <path d="M312.5,809.6v5.6h-0.6v-5.1h-1.3v-0.5H312.5z"/>
      <path d="M317.4,814.9c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.5-0.2,1-0.4,1.5-0.4s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.4,0.2-0.9,0.4-1.5,0.4S317.8,815.2,317.4,814.9z M320.1,814.5c0.4-0.2,0.6-0.5,0.8-0.8
        c0.2-0.4,0.3-0.8,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        C319.3,814.8,319.7,814.7,320.1,814.5z"/>
      <path d="M327,815.2l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H327z M326.5,812.6c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C325.8,813,326.2,812.8,326.5,812.6z"/>
      <path d="M334.5,813.8h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L334.5,813.8z M334.3,813.3l-1.3-3l-1.3,3H334.3z"/>
      <path d="M337.6,814.9c-0.4-0.2-0.8-0.6-1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.4-0.2,0.9-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.9,0.8c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C338.6,815.3,338.1,815.2,337.6,814.9z"/>
      <path d="M343.2,814.9c-0.4-0.2-0.8-0.6-1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.4-0.2,0.9-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.9,0.8c-0.2,0.4-0.3,0.7-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        c0.7,0,1.2-0.2,1.7-0.7l0.4,0.4c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.2,0.2C344.2,815.3,343.7,815.2,343.2,814.9z"/>
      <path d="M351.9,815.2l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H351.9z M351.4,812.6c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C350.7,813,351.1,812.8,351.4,812.6z"/>
      <path d="M357.8,814.7v0.5h-4v-5.6h3.8v0.5h-3.2v2h2.9v0.5h-2.9v2.1H357.8z"/>
      <path d="M359.2,809.6h2.3c0.6,0,1.1,0.1,1.6,0.4s0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5c-0.3,0.4-0.6,0.8-1.1,1
        c-0.5,0.2-1,0.4-1.6,0.4h-2.3V809.6z M361.4,814.7c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.7,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.8-0.3-1.3-0.3h-1.7v4.6H361.4z"/>
      <path d="M365.8,809.6h0.6v5.6h-0.6V809.6z"/>
      <path d="M369.3,810.2h-2v-0.5h4.5v0.5h-2v5.1h-0.6V810.2z"/>
      <path d="M376,813.8h-3.1l-0.7,1.5h-0.6l2.6-5.6h0.6l2.6,5.6h-0.6L376,813.8z M375.8,813.3l-1.3-3l-1.3,3H375.8z"/>
      <path d="M379.1,810.2h-2v-0.5h4.5v0.5h-2v5.1h-0.6V810.2z"/>
      <path d="M382.6,809.6h0.6v5.6h-0.6V809.6z"/>
      <path d="M385.9,814.9c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.5-0.2,1-0.4,1.5-0.4s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.4,0.2-0.9,0.4-1.5,0.4S386.4,815.2,385.9,814.9z M388.6,814.5c0.4-0.2,0.6-0.5,0.8-0.8
        c0.2-0.4,0.3-0.8,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3
        c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.2,0.3
        C387.9,814.8,388.3,814.7,388.6,814.5z"/>
      <path d="M396.4,809.6v5.6h-0.5l-3.6-4.5v4.5h-0.6v-5.6h0.5l3.6,4.5v-4.5H396.4z"/>
      <path d="M403.4,810c0.6,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.8c0,0.7-0.1,1.2-0.4,1.6c-0.2,0.4-0.6,0.6-1,0.6c-0.3,0-0.5-0.1-0.6-0.2
        c-0.2-0.2-0.3-0.4-0.3-0.6c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-0.9,0.2c-0.4,0-0.7-0.1-1-0.3s-0.5-0.4-0.7-0.7
        c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.7,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.7s0.6-0.3,1-0.3c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.4,0.7,0.6
        v-0.8h0.5v3c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.4-0.1,0.6-0.4s0.2-0.7,0.2-1.2c0-0.6-0.1-1.2-0.4-1.6
        s-0.7-0.8-1.2-1.1c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.6-1.2,1.1s-0.4,1-0.4,1.7s0.1,1.2,0.4,1.7
        c0.3,0.5,0.7,0.9,1.2,1.1c0.5,0.3,1.1,0.4,1.7,0.4c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.5-0.1,0.7-0.3l0.1,0.4c-0.2,0.1-0.5,0.2-0.8,0.3
        c-0.3,0.1-0.6,0.1-0.9,0.1c-0.7,0-1.4-0.2-1.9-0.5c-0.6-0.3-1-0.7-1.3-1.3c-0.3-0.6-0.5-1.2-0.5-1.9c0-0.7,0.2-1.3,0.5-1.9
        s0.8-1,1.3-1.3c0.6-0.3,1.2-0.5,1.9-0.5C402.2,809.6,402.8,809.7,403.4,810z M402.2,814.6c0.2-0.1,0.4-0.3,0.5-0.6
        c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2
        c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.1,0.5,0.2,0.8,0.2
        C401.7,814.8,401.9,814.8,402.2,814.6z"/>
      <path d="M406.5,809.6h0.6v5.6h-0.6V809.6z"/>
      <path d="M412.4,813.8h-3.1l-0.7,1.5H408l2.6-5.6h0.6l2.6,5.6h-0.6L412.4,813.8z M412.2,813.3l-1.3-3l-1.3,3H412.2z"/>
      <path d="M414.6,809.6h0.6v5.6h-0.6V809.6z"/>
      <path d="M417,809.6h2.3c0.6,0,1.1,0.1,1.6,0.4c0.5,0.2,0.8,0.6,1.1,1c0.3,0.4,0.4,0.9,0.4,1.5s-0.1,1-0.4,1.5
        c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.2-1,0.4-1.6,0.4H417V809.6z M419.3,814.7c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8
        c0.2-0.3,0.3-0.7,0.3-1.2s-0.1-0.8-0.3-1.2c-0.2-0.3-0.5-0.6-0.9-0.8s-0.8-0.3-1.3-0.3h-1.7v4.6H419.3z"/>
      <path d="M423.7,809.6h0.6v5.1h3.1v0.5h-3.7V809.6z"/>
      <path d="M428.2,815.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1S428.3,815.2,428.2,815.2z"/>
      <path d="M431.1,814.9c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1
        c0.5-0.2,1-0.4,1.5-0.4s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1.1,1s0.4,0.9,0.4,1.5c0,0.5-0.1,1-0.4,1.5s-0.6,0.8-1.1,1
        c-0.4,0.2-0.9,0.4-1.5,0.4S431.5,815.2,431.1,814.9z M433.8,814.5c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.2
        s-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.6-0.8-0.8c-0.4-0.2-0.8-0.3-1.2-0.3s-0.8,0.1-1.2,0.3c-0.4,0.2-0.6,0.5-0.8,0.8
        c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.8,0.8s0.8,0.3,1.2,0.3S433.4,814.7,433.8,814.5z"/>
      <path d="M440.7,815.2l-1.3-1.8c-0.1,0-0.3,0-0.4,0h-1.5v1.8h-0.6v-5.6h2.1c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4
        c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.4,1.9H440.7z M440.2,812.6c0.3-0.2,0.4-0.6,0.4-1c0-0.5-0.1-0.8-0.4-1
        c-0.3-0.2-0.7-0.4-1.3-0.4h-1.5v2.8h1.5C439.5,813,439.9,812.8,440.2,812.6z"/>
      <path d="M446.6,812.4h0.6v2.1c-0.3,0.2-0.6,0.4-0.9,0.5c-0.4,0.1-0.7,0.2-1.1,0.2c-0.6,0-1.1-0.1-1.5-0.4c-0.4-0.2-0.8-0.6-1.1-1
        c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.5,0.1-1,0.4-1.5c0.3-0.4,0.6-0.8,1.1-1c0.4-0.2,1-0.4,1.5-0.4c0.4,0,0.8,0.1,1.2,0.2
        c0.4,0.1,0.7,0.3,0.9,0.6l-0.4,0.4c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-0.9,0.8
        c-0.2,0.4-0.3,0.8-0.3,1.2s0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.2,0.3c0.6,0,1.1-0.1,1.5-0.4V812.4z"/>
    </g>
    <g>
      <text transform="matrix(1 0 0 1 402.2734 656.7666)" class="st9 st15">{{ dateTo }}</text>
      <g class="st8">
        <path d="M404,627.9l-3.4,7.7h-0.8l-3.4-7.7h0.9l3,6.7l3-6.7H404z"/>
        <path d="M409.8,633.5h-4.3l-0.9,2.1h-0.8l3.5-7.7h0.8l3.5,7.7h-0.9L409.8,633.5z M409.5,632.9l-1.9-4.1l-1.9,4.1H409.5z"/>
        <path d="M412.8,627.9h0.8v7h4.3v0.7h-5.1V627.9z"/>
        <path d="M419.3,627.9h0.8v7.7h-0.8V627.9z"/>
        <path d="M422.6,627.9h3.1c0.8,0,1.5,0.2,2.2,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.3,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2
          c-0.3,0.6-0.8,1-1.5,1.4c-0.6,0.3-1.3,0.5-2.2,0.5h-3.1V627.9z M425.7,634.9c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.6,1.2-1.1
          c0.3-0.5,0.4-1,0.4-1.6s-0.1-1.2-0.4-1.6c-0.3-0.5-0.7-0.8-1.2-1.1c-0.5-0.3-1.1-0.4-1.8-0.4h-2.3v6.3H425.7z"/>
        <path d="M436.1,628.6h-2.7v-0.7h6.2v0.7h-2.7v7h-0.8V628.6z"/>
        <path d="M442.1,635.1c-0.6-0.3-1.1-0.8-1.5-1.4c-0.3-0.6-0.5-1.3-0.5-2s0.2-1.4,0.5-2s0.8-1.1,1.5-1.4s1.3-0.5,2.1-0.5
          c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.5,1.3,0.5,2s-0.2,1.4-0.5,2c-0.4,0.6-0.8,1.1-1.5,1.4c-0.6,0.3-1.3,0.5-2.1,0.5
          C443.5,635.6,442.8,635.5,442.1,635.1z M445.9,634.5c0.5-0.3,0.9-0.7,1.2-1.1c0.3-0.5,0.4-1,0.4-1.6s-0.1-1.1-0.4-1.6
          c-0.3-0.5-0.7-0.9-1.2-1.1s-1-0.4-1.7-0.4c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.1c-0.3,0.5-0.4,1-0.4,1.6s0.1,1.1,0.4,1.6
          c0.3,0.5,0.7,0.9,1.2,1.1c0.5,0.3,1,0.4,1.7,0.4C444.8,634.9,445.4,634.8,445.9,634.5z"/>
      </g>
    </g>
    <g>
      <path class="st16" d="M304,778.1h-68.5c-1.7,0-3-1.4-3-3v-23.5c0-1.7,1.4-3,3-3H304c1.7,0,3,1.4,3,3V775
        C307,776.7,305.7,778.1,304,778.1z M232.8,751.9v22.6c0,1.7,1.4,3,3,3h67.8c1.7,0,3-1.4,3-3v-22.6c0-1.7-1.4-3-3-3h-67.8
        C234.2,748.9,232.8,750.3,232.8,751.9z"/>
      <g>
        <path class="st16" d="M247.5,758.2v17h-5.3v-17H247.5z"/>
        <path class="st16" d="M260.9,772.7h-5.7l-0.8,2.6h-5.6l6.2-17h6.2l6.2,17h-5.6L260.9,772.7z M259.6,768.7l-1.5-4.7l-1.5,4.7H259.6
          z"/>
        <path class="st16" d="M273.9,758.2v17h-5.3v-17H273.9z"/>
        <path class="st16" d="M291.1,771.1c-0.7,1.3-1.8,2.3-3.1,3.1c-1.3,0.7-2.9,1.1-4.7,1.1h-7.1v-17h7.1c1.8,0,3.3,0.4,4.7,1.1
          s2.4,1.7,3.1,3s1.1,2.7,1.1,4.4C292.2,768.3,291.8,769.8,291.1,771.1z M285.7,769.5c0.7-0.7,1.1-1.6,1.1-2.8s-0.4-2.2-1.1-2.8
          c-0.7-0.7-1.7-1-2.9-1h-1.3v7.6h1.3C284.1,770.5,285,770.2,285.7,769.5z"/>
        <path class="st16" d="M299.5,771.2h5.1v4h-10.5v-17h5.3v13H299.5z"/>
      </g>
      <g>
        <g>
          <path class="st16" d="M236.7,766.8c0.3-0.3,0.3-0.7,0.2-1.1l1.9-1.9c0.1-0.1,0.3-0.2,0.5-0.2h0.9v0.6c0,0.1,0,0.3,0,0.4
            c0,0.2,0.2,0.3,0.4,0.2c0.2,0,0.3-0.2,0.2-0.4c0-0.1,0-0.2,0-0.2v-6.9c0-0.5,0.4-1,1-1h6.9c0.1,0,0.2,0,0.2,0
            c0.2,0,0.3-0.1,0.4-0.2c0-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.3,0-0.4,0h-0.6v-2.6c0.4-0.1,0.6-0.5,0.6-0.9c0-0.5-0.4-0.9-0.9-0.9
            s-0.9,0.4-0.9,0.9c0,0.4,0.3,0.7,0.6,0.9v2.6h-0.7v-1.3c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v1.3h-0.7V751
            c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v4.7h-0.7v-1.3c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v1.3h-0.7V755
            c0-0.3-0.1-0.6-0.4-0.9l-1.9-1.9c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3
            c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1l1.9,1.9
            c0.1,0.1,0.2,0.3,0.2,0.5v0.9h-0.6c-0.8,0-1.5,0.7-1.5,1.5v0.6h-2.6c-0.1-0.4-0.5-0.6-0.9-0.6c-0.5,0-0.9,0.4-0.9,0.9
            s0.4,0.9,0.9,0.9c0.4,0,0.7-0.3,0.9-0.6h2.6v0.7h-1.3c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3h1.3v0.7h-4.7
            c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3h4.7v0.7h-1.3c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3h1.3v0.7h-0.9
            c-0.3,0-0.6,0.1-0.9,0.4l-1.9,1.9c-0.3-0.2-0.8-0.1-1.1,0.2c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6
            C235.8,767.2,236.4,767.2,236.7,766.8z M247.3,752.4c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
            C247.4,752.8,247.3,752.6,247.3,752.4z M239.6,752.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1
            c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1C239.7,752.2,239.6,752.1,239.6,752.1z
            M236.7,758.7c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3C237,758.6,236.9,758.7,236.7,758.7z M235.8,766.4
            c-0.1-0.1-0.1-0.3,0-0.5c0.1-0.1,0.3-0.1,0.5,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2C236.2,766.5,236,766.5,235.8,766.4z
            "/>
        </g>
      </g>
      <g>
        <path class="st16" d="M313,751v5.3h-1.6V751H313z"/>
        <path class="st16" d="M318.8,756.2h-1.6l-1.7-2.6v2.6h-1.6V751h1.6l1.7,2.7V751h1.6V756.2z"/>
        <path class="st16" d="M323.6,751v1.3h-1.4v3.9h-1.6v-3.9h-1.4V751H323.6z"/>
        <path class="st16" d="M325.7,752.3v0.7h1.6v1.2h-1.6v0.7h1.9v1.3h-3.5V751h3.5v1.3H325.7z"/>
        <path class="st16" d="M330.9,756.2l-1-1.9v1.9h-1.6V751h2.4c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.4,0.7,0.6
          c0.2,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.6l1.1,2H330.9z M329.9,753.3h0.7c0.2,0,0.3,0,0.4-0.1
          s0.1-0.2,0.1-0.3s0-0.2-0.1-0.3s-0.2-0.1-0.4-0.1h-0.7V753.3z"/>
        <path class="st16" d="M338.2,756.2h-1.6l-1.7-2.6v2.6h-1.6V751h1.6l1.7,2.7V751h1.6V756.2z"/>
        <path class="st16" d="M342.3,755.4h-1.8l-0.3,0.8h-1.7l1.9-5.3h1.9l1.9,5.3h-1.7L342.3,755.4z M341.9,754.2l-0.5-1.5l-0.5,1.5
          H341.9z"/>
        <path class="st16" d="M348.8,751v1.3h-1.4v3.9h-1.6v-3.9h-1.4V751H348.8z"/>
        <path class="st16" d="M350.9,751v5.3h-1.6V751H350.9z"/>
        <path class="st16" d="M355.6,751.2c0.4,0.2,0.7,0.5,1,1s0.4,0.9,0.4,1.4s-0.1,1-0.4,1.4c-0.2,0.4-0.6,0.7-1,1
          c-0.4,0.2-0.9,0.3-1.4,0.3s-0.9-0.1-1.4-0.3s-0.7-0.6-1-1c-0.2-0.4-0.4-0.9-0.4-1.4s0.1-1,0.4-1.4c0.2-0.4,0.6-0.7,1-1
          c0.4-0.2,0.9-0.3,1.4-0.3S355.2,751,355.6,751.2z M353.4,752.7c-0.2,0.2-0.3,0.5-0.3,0.9s0.1,0.6,0.3,0.8s0.4,0.3,0.8,0.3
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.4-0.1-0.6-0.3-0.9c-0.2-0.2-0.4-0.3-0.8-0.3C353.9,752.4,353.6,752.5,353.4,752.7z"/>
        <path class="st16" d="M362.5,756.2h-1.6l-1.7-2.6v2.6h-1.6V751h1.6l1.7,2.7V751h1.6V756.2z"/>
        <path class="st16" d="M366.6,755.4h-1.8l-0.3,0.8h-1.7l1.9-5.3h1.9l1.9,5.3h-1.7L366.6,755.4z M366.2,754.2l-0.5-1.5l-0.5,1.5
          H366.2z"/>
        <path class="st16" d="M370.7,755h1.6v1.2H369V751h1.6v4H370.7z"/>
        <path class="st16" d="M319,767.1h-3.8l-0.6,1.7H311l4.1-11.3h4.1l4.1,11.3h-3.7L319,767.1z M318.2,764.4l-1-3.1l-1,3.1H318.2z"/>
        <path class="st16" d="M327.6,757.5v11.3h-3.5v-11.3H327.6z"/>
        <path class="st16" d="M315.9,773.8c-0.2,0.4-0.5,0.7-1,0.9c-0.4,0.2-0.9,0.3-1.4,0.3h-2.2v-5.3h2.2c0.5,0,1,0.1,1.4,0.3
          s0.7,0.5,1,0.9c0.2,0.4,0.3,0.8,0.3,1.3C316.3,772.9,316.2,773.4,315.9,773.8z M314.3,773.3c0.2-0.2,0.3-0.5,0.3-0.9
          s-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3H313v2.3h0.4C313.8,773.6,314.1,773.5,314.3,773.3z"/>
        <path class="st16" d="M319.5,775.1l-1-1.9v1.9h-1.6v-5.3h2.4c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.4,0.7,0.6
          c0.2,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.6l1.1,2L319.5,775.1L319.5,775.1z M318.5,772.1h0.7
          c0.2,0,0.3,0,0.4-0.1s0.1-0.2,0.1-0.3s0-0.2-0.1-0.3s-0.2-0.1-0.4-0.1h-0.7V772.1z"/>
        <path class="st16" d="M323.5,769.8v5.3h-1.6v-5.3H323.5z"/>
        <path class="st16" d="M325.6,769.8l1.1,3.6l1.1-3.6h1.8l-1.8,5.3h-2.1l-1.8-5.3H325.6z"/>
        <path class="st16" d="M331.5,769.8v5.3h-1.6v-5.3H331.5z"/>
        <path class="st16" d="M337.3,775.1h-1.6l-1.7-2.6v2.6h-1.6v-5.3h1.6l1.7,2.7v-2.7h1.6V775.1z"/>
        <path class="st16" d="M342.2,770.3c0.4,0.3,0.7,0.8,0.8,1.3h-1.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
          c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3s0.7-0.2,0.9-0.5h-1.3v-1.2h2.7
          v1.6c-0.2,0.4-0.5,0.7-0.9,1s-0.9,0.4-1.5,0.4c-0.5,0-1-0.1-1.4-0.3s-0.7-0.5-0.9-0.9s-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4
          c0.2-0.4,0.5-0.7,0.9-0.9s0.9-0.3,1.4-0.3C341.2,769.8,341.8,770,342.2,770.3z"/>
        <path class="st16" d="M346.5,773.8h1.6v1.2h-3.2v-5.3h1.6V773.8z"/>
        <path class="st16" d="M350.3,769.8v5.3h-1.6v-5.3H350.3z"/>
        <path class="st16" d="M354.7,770c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.7,0.4,1.1h-1.7c-0.1-0.2-0.2-0.3-0.3-0.4
          c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.3s-0.2,0.5-0.2,0.8c0,0.4,0.1,0.6,0.2,0.8c0.2,0.2,0.4,0.3,0.7,0.3
          c0.2,0,0.4,0,0.5-0.1s0.3-0.2,0.3-0.4h1.7c-0.1,0.4-0.2,0.8-0.4,1.1c-0.2,0.3-0.5,0.6-0.9,0.7c-0.4,0.2-0.8,0.3-1.2,0.3
          c-0.5,0-1-0.1-1.4-0.3s-0.7-0.5-0.9-0.9c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4c0.2-0.4,0.5-0.7,0.9-0.9s0.8-0.3,1.4-0.3
          C353.9,769.8,354.3,769.9,354.7,770z"/>
        <path class="st16" d="M358.3,771.1v0.7h1.6v1.2h-1.6v0.7h1.9v1.3h-3.5v-5.3h3.5v1.3L358.3,771.1L358.3,771.1z"/>
        <path class="st16" d="M365.8,775.1h-1.6l-1.7-2.6v2.6h-1.6v-5.3h1.6l1.7,2.7v-2.7h1.6V775.1z"/>
        <path class="st16" d="M370.3,774.4c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.6,0.2-1.1,0.2c-0.6,0-1.1-0.1-1.6-0.4
          c-0.4-0.3-0.6-0.7-0.7-1.3h1.8c0,0.3,0.2,0.5,0.4,0.5c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.3
          s-0.3-0.2-0.6-0.2c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.2-0.9c0-0.3,0.1-0.6,0.3-0.9
          c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,1-0.2c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.6,0.7,0.6,1.3h-1.8c0-0.3-0.1-0.4-0.3-0.4
          c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.3s0.3,0.2,0.6,0.2c0.3,0.1,0.6,0.2,0.8,0.4
          c0.2,0.1,0.4,0.3,0.6,0.5s0.2,0.5,0.2,0.9C370.6,773.8,370.5,774.1,370.3,774.4z"/>
        <path class="st16" d="M372.8,771.1v0.7h1.6v1.2h-1.6v0.7h1.9v1.3h-3.5v-5.3h3.5v1.3L372.8,771.1L372.8,771.1z"/>
      </g>
    </g>
    <!-- <g>
      <g>
        
          <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="97.8" y1="695.552" x2="97.8" y2="825.861" gradientTransform="matrix(1 0 0 -1 0 844)">
          <stop  offset="0" style="stop-color:#B75C27"/>
          <stop  offset="4.703292e-02" style="stop-color:#BB6229"/>
          <stop  offset="0.1216" style="stop-color:#C37332"/>
          <stop  offset="0.2144" style="stop-color:#D38E44"/>
          <stop  offset="0.3208" style="stop-color:#ECBB66"/>
          <stop  offset="0.3758" style="stop-color:#FFD97C"/>
          <stop  offset="0.4816" style="stop-color:#FFDB7D"/>
          <stop  offset="0.5429" style="stop-color:#FFE081"/>
          <stop  offset="0.5879" style="stop-color:#FDE888"/>
          <stop  offset="0.6238" style="stop-color:#FFE081"/>
          <stop  offset="0.6705" style="stop-color:#FFDB7D"/>
          <stop  offset="0.7636" style="stop-color:#FFD97C"/>
          <stop  offset="0.8466" style="stop-color:#E1A858"/>
          <stop  offset="1" style="stop-color:#B75C27"/>
        </linearGradient>
        <path class="st17" d="M137.2,133.8l-0.9-0.2c-2-0.3-4,1-4.4,3.1l-0.2,0.9c-0.5,2.3-2.9,3.7-5.1,2.9l-0.9-0.3
          c-1.9-0.7-4.1,0.2-4.9,2.2l-0.4,0.9c-0.9,2.2-3.6,3.1-5.6,1.8l-0.8-0.5c-1.8-1.1-4.1-0.6-5.2,1.2l-0.5,0.8c-1.3,2-4.1,2.3-5.8,0.7
          l-0.7-0.6c-1.5-1.4-3.9-1.4-5.3,0.1l-0.7,0.7c-1.7,1.7-4.5,1.5-5.8-0.5l-0.6-0.8c-1.2-1.7-3.5-2.1-5.3-0.9l-0.8,0.5
          c-2,1.3-4.7,0.6-5.6-1.6l-0.4-0.9c-0.9-1.9-3.1-2.7-5-1.9l-0.9,0.4c-2.2,0.9-4.7-0.3-5.2-2.7l-0.2-0.9c-0.5-2-2.5-3.3-4.5-2.9
          l-0.9,0.2c-2.3,0.5-4.5-1.3-4.6-3.6V131c-0.1-2.1-1.8-3.7-3.9-3.7h-0.9c-2.4,0-4.2-2.1-3.8-4.5l0.2-0.9c0.3-2.1-1-4-3.1-4.4
          l-0.9-0.2c-2.3-0.5-3.7-2.9-2.9-5.1l0.3-0.9c0.7-1.9-0.2-4.1-2.2-4.9l-0.9-0.4c-2.2-0.9-3.1-3.6-1.8-5.6l0.5-0.8
          c1.1-1.8,0.6-4.1-1.2-5.2l-0.8-0.5c-2-1.3-2.3-4.1-0.7-5.8l0.6-0.7c1.4-1.5,1.4-3.9-0.1-5.3l-0.7-0.7c-1.7-1.7-1.5-4.5,0.5-5.8
          L35,75c1.7-1.2,2.1-3.5,0.9-5.3l-0.5-0.8c-1.3-2-0.6-4.7,1.6-5.6l0.9-0.4c1.9-0.9,2.7-3.1,1.9-5L39.4,57c-0.9-2.2,0.3-4.7,2.7-5.2
          l0.9-0.2c2-0.5,3.3-2.5,2.9-4.5l-0.2-0.9c-0.5-2.3,1.3-4.5,3.6-4.6h0.9c2.1-0.1,3.7-1.8,3.7-3.9v-0.9c0-2.4,2.1-4.2,4.5-3.8
          l0.9,0.2c2.1,0.3,4-1,4.4-3.1l0.2-0.9c0.5-2.3,2.9-3.7,5.1-2.9l0.9,0.3c1.9,0.7,4.1-0.2,4.9-2.2l0.4-0.9c0.9-2.2,3.6-3.1,5.6-1.8
          l0.8,0.5c1.8,1.1,4.1,0.6,5.2-1.2l0.5-0.8c1.3-2,4.1-2.3,5.8-0.7l0.7,0.6c1.5,1.4,3.9,1.4,5.3-0.1l0.7-0.7
          c1.7-1.7,4.5-1.5,5.8,0.5l0.6,0.8c1.2,1.7,3.5,2.1,5.3,0.9l0.8-0.5c2-1.3,4.7-0.6,5.6,1.6l0.4,0.9c0.9,1.9,3.1,2.7,5,1.9l0.9-0.4
          c2.2-0.9,4.7,0.3,5.2,2.7l0.2,0.9c0.5,2,2.5,3.3,4.5,2.9l0.9-0.2c2.3-0.5,4.5,1.3,4.6,3.6v0.9c0.1,2.1,1.8,3.7,3.9,3.7h0.9
          c2.4,0,4.2,2.1,3.8,4.5l-0.2,0.9c-0.3,2.1,1,4,3.1,4.4l0.9,0.2c2.3,0.5,3.7,2.9,2.9,5.1l-0.3,0.9c-0.7,1.9,0.2,4.1,2.2,4.9
          l0.9,0.4c2.2,0.9,3.1,3.6,1.8,5.6l-0.5,0.8c-1.1,1.8-0.6,4.1,1.2,5.2l0.8,0.5c2,1.3,2.3,4.1,0.7,5.8l-0.6,0.7
          c-1.4,1.5-1.4,3.9,0.1,5.4l0.7,0.7c1.7,1.7,1.5,4.5-0.5,5.8l-0.8,0.6c-1.7,1.2-2.1,3.5-0.9,5.3l0.5,0.8c1.3,2,0.6,4.7-1.6,5.6
          l-0.9,0.4c-1.9,0.9-2.7,3.1-1.9,5l0.4,0.9c0.9,2.2-0.3,4.7-2.7,5.2l-0.9,0.2c-2,0.5-3.3,2.5-2.9,4.5l0.2,0.9
          c0.5,2.3-1.3,4.5-3.6,4.6h-0.9c-2.1,0.1-3.7,1.8-3.7,3.9v0.9C141.7,132.4,139.6,134.2,137.2,133.8z"/>
        <g class="st18">
          <g>
            <defs>
              <path id="SVGID_9_" d="M136.8,133.7L136.8,133.7c-2.4-0.4-4.5,1.1-4.9,3.4v0.1c-0.5,2.6-3.2,4.1-5.6,3.2h-0.1
                c-2.1-0.8-4.5,0.3-5.4,2.4v0.1c-1,2.4-3.9,3.4-6.2,2h-0.1c-1.9-1.2-4.5-0.6-5.8,1.3l-0.1,0.1c-1.4,2.2-4.5,2.6-6.4,0.8
                l-0.1-0.1c-1.7-1.6-4.3-1.5-5.9,0.1l-0.1,0.1c-1.8,1.9-4.9,1.6-6.5-0.5l-0.1-0.1c-1.3-1.9-3.9-2.3-5.8-1l-0.1,0.1
                c-2.2,1.5-5.1,0.6-6.2-1.7v-0.1c-1-2.1-3.4-3-5.5-2.1h-0.1c-2.4,1-5.2-0.4-5.8-2.9v-0.1c-0.5-2.2-2.7-3.6-5-3.2h-0.1
                c-2.6,0.5-5-1.4-5.1-4v-0.1c-0.1-2.3-2-4.1-4.3-4.1h-0.1c-2.6,0-4.6-2.3-4.2-4.9v-0.1c0.4-2.3-1.1-4.4-3.4-4.8h-0.1
                c-2.6-0.5-4.1-3.2-3.2-5.6v-0.1c0.8-2.1-0.3-4.5-2.4-5.4H38c-2.4-1-3.4-3.9-2-6.2v-0.1c1.2-1.9,0.6-4.5-1.3-5.8l-0.1-0.1
                c-2.2-1.4-2.6-4.5-0.8-6.4l0.1-0.1c1.6-1.7,1.5-4.3-0.1-5.9l-0.1-0.1c-1.9-1.8-1.6-4.9,0.5-6.5l0.1-0.1c1.9-1.3,2.3-3.9,1-5.8
                l-0.1-0.1c-1.5-2.2-0.6-5.1,1.7-6.2H37c2.1-1,3-3.4,2.1-5.5v-0.1c-1-2.4,0.4-5.2,2.9-5.8h0.1c2.2-0.5,3.6-2.7,3.2-5v-0.1
                c-0.5-2.6,1.4-5,4-5.1h0.1c2.3-0.1,4.1-2,4.1-4.3v-0.1c0-2.6,2.3-4.6,4.9-4.2h0.1c2.3,0.4,4.4-1.1,4.8-3.4v-0.1
                c0.5-2.6,3.2-4.1,5.6-3.2H69c2.1,0.8,4.5-0.3,5.4-2.4v-0.1c1-2.4,3.9-3.4,6.2-2h0.1c1.9,1.2,4.5,0.6,5.8-1.3l0.1-0.1
                c1.4-2.2,4.5-2.6,6.4-0.8l0.1,0.1c1.7,1.6,4.3,1.5,5.9-0.1l0.1-0.1c1.8-1.9,4.9-1.6,6.5,0.5l0.1,0.1c1.3,1.9,3.9,2.3,5.8,1
                l0.1-0.1c2.2-1.5,5.1-0.6,6.2,1.7v0.1c1,2.1,3.4,3,5.5,2.1h0.1c2.4-1,5.2,0.4,5.8,2.9v0.1c0.5,2.2,2.7,3.6,5,3.2h0.1
                c2.6-0.5,5,1.4,5.1,4v0.1c0.1,2.3,2,4.1,4.3,4.1h0.1c2.6,0,4.6,2.3,4.2,4.9v0.1c-0.4,2.3,1.1,4.4,3.4,4.8h0.1
                c2.6,0.5,4.1,3.2,3.2,5.6v0.1c-0.8,2.1,0.3,4.5,2.4,5.4h0.1c2.4,1,3.4,3.9,2,6.2v0.1c-1.2,1.9-0.6,4.5,1.3,5.8l0.1,0.1
                c2.2,1.4,2.6,4.5,0.8,6.4l-0.1,0.1c-1.6,1.7-1.5,4.3,0.1,5.9l0.1,0.1c1.9,1.8,1.6,4.9-0.5,6.5l-0.1,0.1c-1.9,1.3-2.3,3.9-1,5.8
                l0.1,0.1c1.5,2.2,0.6,5.1-1.7,6.2h-0.1c-2.1,1-3,3.4-2.1,5.5v0.1c1,2.4-0.4,5.2-2.9,5.8h-0.1c-2.2,0.5-3.6,2.7-3.2,5v0.1
                c0.5,2.6-1.4,5-4,5.1h-0.1c-2.3,0.1-4.1,2-4.1,4.3v0.1C141.7,132.1,139.4,134.1,136.8,133.7z"/>
            </defs>
            <clipPath id="SVGID_10_">
              <use xlink:href="#SVGID_9_"  style="overflow:visible;"/>
            </clipPath>
            
          </g>
        </g>
        <g>
          
            <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="42.6" y1="760.7" x2="153" y2="760.7" gradientTransform="matrix(1 0 0 -1 0 844)">
            <stop  offset="0.1758" style="stop-color:#DB953B"/>
            <stop  offset="0.3758" style="stop-color:#FFD97C"/>
            <stop  offset="0.4816" style="stop-color:#FFDB7D"/>
            <stop  offset="0.5429" style="stop-color:#FFE081"/>
            <stop  offset="0.5879" style="stop-color:#FDE888"/>
            <stop  offset="0.6238" style="stop-color:#FFE081"/>
            <stop  offset="0.6705" style="stop-color:#FFDB7D"/>
            <stop  offset="0.7636" style="stop-color:#FFD97C"/>
          </linearGradient>
          <path class="st22" d="M97.8,28.1c-30.5,0-55.2,24.7-55.2,55.2s24.7,55.2,55.2,55.2S153,113.8,153,83.3S128.3,28.1,97.8,28.1z
            M97.8,137c-29.7,0-53.7-24-53.7-53.7s24-53.7,53.7-53.7s53.7,24,53.7,53.7C151.5,112.9,127.5,137,97.8,137z"/>
        </g>
        <g class="st23">
          <g>
            <path class="st24" d="M143.4,88.3c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,1,2.5,0c1-1.2,1.7-1.2,2.2-1.2c-1-0.9-1.8-1-2.5-0.9
              s-1.4,0.6-2.1,0.9C144.9,88.5,144.5,88.5,143.4,88.3z"/>
            <path class="st24" d="M143.7,87.8c0,0,1.3-0.4,2-0.4c1.1-0.1,2-0.5,1.9-1.7c-0.2-1.6,0.4-2,0.8-2.4c-2.6,0.3-2.7,1.8-3.3,3.2
              C144.8,87,144.6,87.3,143.7,87.8z"/>
          </g>
          <g>
            <path class="st24" d="M142.3,94.5c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.6,1.2,2.5,0.4c1.1-1.1,1.8-1,2.4-0.9c-0.9-1-1.7-1.3-2.4-1.2
              c-0.7,0-1.5,0.4-2.2,0.6C143.7,94.8,143.3,94.8,142.3,94.5z"/>
            <path class="st24" d="M142.7,94c0,0.1,1.3-0.2,2.1-0.1c1.1,0.1,2-0.2,2.1-1.5c0.1-1.6,0.6-2,1.1-2.3c-2.6-0.1-2.9,1.4-3.7,2.8
              C143.9,93.4,143.6,93.6,142.7,94z"/>
          </g>
          <g>
            <path class="st24" d="M140.4,100.5c0,0.1,1,0.8,1.5,1.5c0.6,0.9,1.4,1.4,2.4,0.7c1.3-0.9,1.9-0.7,2.5-0.5
              c-0.7-1.1-1.5-1.5-2.2-1.5c-0.7-0.1-1.5,0.2-2.3,0.3C141.7,101,141.3,100.9,140.4,100.5z"/>
            <path class="st24" d="M140.8,100c0,0.1,1.3,0,2.1,0.2c1.1,0.2,2,0.1,2.3-1.2c0.3-1.5,0.9-1.9,1.4-2.1c-2.6-0.4-3.1,1-4.1,2.2
              C142.1,99.5,141.8,99.8,140.8,100z"/>
          </g>
          <g>
            <path class="st24" d="M137.6,106.1c0,0,0.9,1,1.3,1.6c0.5,1,1.2,1.6,2.3,1.1c1.4-0.7,2-0.4,2.5-0.2c-0.6-1.2-1.3-1.7-2-1.8
              c-0.7-0.2-1.5,0-2.3,0C138.9,106.8,138.5,106.6,137.6,106.1z"/>
            <path class="st24" d="M138.1,105.7c0,0.1,1.3,0.2,2,0.4c1,0.4,2,0.3,2.4-0.8c0.5-1.5,1.1-1.7,1.7-1.9c-2.5-0.8-3.2,0.6-4.3,1.6
              C139.5,105.4,139.1,105.6,138.1,105.7z"/>
          </g>
          <g>
            <path class="st24" d="M134.2,111.3c0,0,0.8,1.1,1,1.8c0.4,1,1,1.8,2.1,1.4c1.5-0.6,2.1-0.2,2.5,0.2c-0.4-1.3-1-1.8-1.7-2.1
              c-0.7-0.3-1.5-0.2-2.3-0.3C135.3,112.1,135,112,134.2,111.3z"/>
            <path class="st24" d="M134.7,111c0,0.1,1.3,0.4,1.9,0.7c1,0.5,1.9,0.6,2.5-0.5c0.7-1.4,1.4-1.5,1.9-1.6c-2.4-1.1-3.2,0.1-4.5,1
              C136.1,110.9,135.7,111,134.7,111z"/>
          </g>
          <g>
            <path class="st24" d="M130,116c0,0,0.6,1.2,0.8,1.9c0.2,1.1,0.7,1.9,1.9,1.6c1.5-0.3,2.1,0.1,2.5,0.5c-0.2-1.3-0.8-1.9-1.4-2.3
              c-0.7-0.3-1.4-0.4-2.2-0.6C131.1,117,130.7,116.7,130,116z"/>
            <path class="st24" d="M130.6,115.7c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,0.9,2.5-0.2c0.9-1.3,1.6-1.3,2.1-1.4
              c-2.2-1.4-3.2-0.3-4.6,0.4C132,115.8,131.6,115.9,130.6,115.7z"/>
          </g>
          <g>
            <path class="st24" d="M125.3,120.1c0,0,0.4,1.3,0.5,2c0.1,1.1,0.5,2,1.7,1.9c1.6-0.1,2,0.4,2.4,0.8c0-1.3-0.5-2-1.1-2.5
              c-0.6-0.4-1.4-0.6-2.1-0.9C126.2,121.2,125.9,120.9,125.3,120.1z"/>
            <path class="st24" d="M125.8,119.9c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.7,1.1,2.5,0.2c1-1.2,1.7-1.1,2.3-1.1
              c-2-1.7-3.2-0.8-4.6-0.2C127.2,120.2,126.8,120.2,125.8,119.9z"/>
          </g>
          <g>
            <path class="st24" d="M120,123.4c0,0,0.3,1.3,0.2,2.1c-0.1,1.1,0.2,2,1.4,2.1c1.6,0.1,2,0.7,2.2,1.1c0.2-1.3-0.2-2.1-0.7-2.6
              s-1.3-0.8-2-1.2C120.7,124.7,120.5,124.4,120,123.4z"/>
            <path class="st24" d="M120.6,123.4c0,0.1,1,0.8,1.5,1.4c0.7,0.9,1.5,1.3,2.5,0.5c1.2-1,1.9-0.9,2.4-0.8c-1.7-2-3-1.2-4.6-0.8
              C121.9,123.8,121.5,123.8,120.6,123.4z"/>
          </g>
          <g>
            <path class="st24" d="M114.3,126.1c0,0,0.1,1.3-0.1,2.1c-0.2,1.1-0.1,2,1.1,2.3c1.6,0.3,1.8,0.9,2.1,1.4
              c0.3-1.3,0.1-2.1-0.4-2.7c-0.5-0.6-1.2-1-1.8-1.5C114.9,127.4,114.7,127.1,114.3,126.1z"/>
            <path class="st24" d="M114.9,126.1c0,0.1,0.9,1,1.3,1.6c0.6,1,1.3,1.5,2.4,0.9c1.3-0.9,2-0.6,2.5-0.4c-1.5-2.2-2.8-1.6-4.4-1.5
              C116.2,126.7,115.8,126.6,114.9,126.1z"/>
          </g>
          <g>
            <path class="st24" d="M108.4,127.9c-0.1,0-0.1,1.3-0.3,2c-0.4,1-0.4,2,0.8,2.4c1.5,0.5,1.7,1.2,1.8,1.7c0.5-1.2,0.4-2.1,0-2.7
              c-0.4-0.6-1-1.1-1.6-1.7C108.7,129.3,108.6,129,108.4,127.9z"/>
            <path class="st24" d="M108.9,128c0,0,0.8,1.1,1.1,1.8c0.4,1,1.1,1.7,2.2,1.2c1.4-0.7,2-0.3,2.5-0.1c-1.1-2.4-2.6-2-4.2-2
              C110.1,128.8,109.7,128.7,108.9,128z"/>
          </g>
          <g>
            <path class="st24" d="M102.2,128.9c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.6,1.9,0.5,2.5c1.4,0.7,1.5,1.4,1.6,1.9c0.7-1.2,0.6-2,0.4-2.7
              c-0.3-0.7-0.9-1.2-1.3-1.9C102.4,130.4,102.2,130,102.2,128.9z"/>
            <path class="st24" d="M102.8,129.1c0,0,0.6,1.2,0.8,1.9c0.3,1.1,0.9,1.8,2.1,1.5c1.5-0.5,2.1-0.1,2.5,0.3
              c-0.8-2.5-2.3-2.3-3.8-2.6C103.8,130.1,103.4,129.9,102.8,129.1z"/>
          </g>
          <g>
            <path class="st24" d="M95.9,129.1c-0.1,0-0.4,1.3-0.9,1.9c-0.6,0.9-0.9,1.8,0.1,2.5c1.3,0.9,1.3,1.6,1.3,2.1
              c0.8-1.1,0.9-1.9,0.7-2.6c-0.2-0.7-0.7-1.3-1.1-2C95.9,130.6,95.8,130.1,95.9,129.1z"/>
            <path class="st24" d="M96.5,129.3c0,0,0.4,1.3,0.5,2c0.1,1.1,0.6,1.9,1.8,1.7c1.5-0.3,2,0.2,2.4,0.6c-0.5-2.6-2-2.6-3.5-3.1
              C97.4,130.4,97,130.2,96.5,129.3z"/>
          </g>
          <g>
            <path class="st24" d="M89.7,128.4c-0.1,0-0.6,1.2-1.1,1.7c-0.8,0.8-1.1,1.7-0.2,2.5c1.2,1.1,1.1,1.7,1,2.3
              c1-0.9,1.2-1.7,1.1-2.5c-0.1-0.7-0.5-1.4-0.8-2.2C89.5,129.9,89.5,129.4,89.7,128.4z"/>
            <path class="st24" d="M90.2,128.7c-0.1,0,0.3,1.3,0.3,2.1c0,1.1,0.4,2,1.6,2c1.6-0.1,2,0.5,2.3,0.9c-0.1-2.6-1.6-2.8-3-3.5
              C90.9,129.9,90.7,129.6,90.2,128.7z"/>
          </g>
          <g>
            <path class="st24" d="M83.6,126.9c-0.1,0-0.8,1.1-1.3,1.6c-0.9,0.7-1.3,1.5-0.6,2.5c1,1.2,0.8,1.9,0.7,2.4
              c1.1-0.8,1.4-1.6,1.4-2.3s-0.3-1.5-0.5-2.3C83.2,128.3,83.3,127.9,83.6,126.9z"/>
            <path class="st24" d="M84.1,127.3c-0.1,0,0.1,1.3,0,2.1c-0.2,1.1,0.1,2,1.3,2.2c1.6,0.2,1.9,0.8,2.2,1.2c0.3-2.6-1.2-3-2.5-3.9
              C84.7,128.6,84.4,128.2,84.1,127.3z"/>
          </g>
          <g>
            <path class="st24" d="M77.8,124.6c0,0-0.9,1-1.5,1.4c-0.9,0.6-1.5,1.3-0.9,2.4c0.8,1.3,0.6,2,0.4,2.5c1.2-0.6,1.6-1.4,1.7-2.1
              c0.1-0.7-0.1-1.5-0.2-2.3C77.2,125.9,77.3,125.5,77.8,124.6z"/>
            <path class="st24" d="M78.2,125c-0.1,0-0.1,1.3-0.3,2c-0.3,1.1-0.2,2,1,2.3c1.5,0.4,1.8,1,2,1.5c0.6-2.6-0.8-3.2-1.9-4.2
              C78.6,126.3,78.4,126,78.2,125z"/>
          </g>
          <g>
            <path class="st24" d="M72.4,121.4c0,0-1,0.8-1.7,1.1c-1,0.4-1.7,1.1-1.2,2.2c0.6,1.4,0.3,2,0,2.5c1.3-0.5,1.8-1.1,2-1.8
              c0.2-0.7,0.1-1.5,0.2-2.3C71.6,122.7,71.8,122.3,72.4,121.4z"/>
            <path class="st24" d="M72.7,121.9c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.5,2,0.7,2.4c1.5,0.6,1.6,1.3,1.8,1.8c1-2.5-0.3-3.2-1.3-4.4
              C72.9,123.3,72.8,122.9,72.7,121.9z"/>
          </g>
          <g>
            <path class="st24" d="M67.4,117.6c0,0-1.1,0.7-1.9,0.9c-1.1,0.3-1.8,0.9-1.5,2c0.4,1.5,0,2.1-0.3,2.5c1.3-0.3,1.9-0.9,2.2-1.6
              s0.3-1.5,0.5-2.3C66.5,118.7,66.7,118.4,67.4,117.6z"/>
            <path class="st24" d="M67.7,118.1c-0.1,0-0.4,1.3-0.8,1.9c-0.6,0.9-0.7,1.9,0.3,2.5c1.4,0.8,1.4,1.5,1.5,2
              c1.3-2.3,0.1-3.2-0.7-4.6C67.7,119.6,67.6,119.2,67.7,118.1z"/>
          </g>
          <g>
            <path class="st24" d="M63.1,113.2c0,0-1.2,0.5-2,0.6c-1.1,0.2-1.9,0.6-1.8,1.8c0.2,1.6-0.3,2-0.7,2.4c1.3-0.1,2-0.6,2.4-1.2
              s0.5-1.4,0.8-2.2C62,114.1,62.2,113.8,63.1,113.2z"/>
            <path class="st24" d="M63.3,113.7c-0.1,0-0.6,1.2-1.1,1.8c-0.7,0.8-1,1.8,0,2.5c1.2,1,1.2,1.6,1.2,2.2c1.6-2.1,0.5-3.2-0.1-4.6
              C63.1,115.1,63,114.7,63.3,113.7z"/>
          </g>
          <g>
            <path class="st24" d="M59.3,108.1c0,0-1.3,0.4-2,0.4c-1.1,0-2,0.3-2,1.6c0,1.6-0.5,2-1,2.3c1.3,0.1,2.1-0.3,2.5-0.9
              c0.5-0.6,0.7-1.3,1.1-2C58.1,109,58.4,108.7,59.3,108.1z"/>
            <path class="st24" d="M59.4,108.7c-0.1,0-0.8,1.1-1.3,1.6c-0.8,0.7-1.2,1.6-0.4,2.5c1.1,1.1,1,1.8,0.9,2.3
              c1.9-1.9,1-3.1,0.5-4.6C59.1,110.1,59.1,109.7,59.4,108.7z"/>
          </g>
          <g>
            <path class="st24" d="M56.3,102.7c0,0-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.1-2.2,1.3c-0.2,1.6-0.8,1.9-1.3,2.1
              c1.3,0.2,2.1-0.1,2.6-0.6s0.9-1.2,1.3-1.9C55,103.3,55.3,103.1,56.3,102.7z"/>
            <path class="st24" d="M56.3,103.3c-0.1,0-0.9,1-1.5,1.4c-0.9,0.6-1.4,1.4-0.7,2.4c0.9,1.3,0.7,1.9,0.6,2.5
              c2.1-1.6,1.4-2.9,1.2-4.5C55.8,104.6,55.8,104.2,56.3,103.3z"/>
          </g>
          <g>
            <path class="st24" d="M54,96.8c0-0.1-1.3,0-2.1-0.2c-1.1-0.3-2-0.2-2.3,1c-0.4,1.5-1,1.8-1.6,2c1.3,0.4,2.1,0.2,2.7-0.2
              c0.6-0.4,1-1.1,1.6-1.7C52.7,97.3,53,97.1,54,96.8z"/>
            <path class="st24" d="M54,97.4c-0.1,0-1,0.8-1.7,1.2c-1,0.5-1.6,1.2-1,2.3c0.8,1.4,0.5,2,0.2,2.5c2.3-1.3,1.8-2.7,1.8-4.3
              C53.3,98.6,53.4,98.2,54,97.4z"/>
          </g>
          <g>
            <path class="st24" d="M52.6,90.7c0-0.1-1.3-0.2-2-0.5c-1-0.4-2-0.5-2.5,0.6c-0.6,1.5-1.3,1.6-1.8,1.7c1.2,0.6,2,0.5,2.7,0.2
              s1.2-0.9,1.8-1.5C51.2,91,51.6,90.9,52.6,90.7z"/>
            <path class="st24" d="M52.5,91.3c0,0-1.1,0.7-1.8,0.9c-1.1,0.4-1.8,1-1.3,2.2c0.6,1.5,0.2,2-0.1,2.5c2.4-1,2.1-2.5,2.3-4
              C51.6,92.4,51.8,92,52.5,91.3z"/>
          </g>
          <g>
            <path class="st24" d="M52,84.5c0-0.1-1.3-0.4-1.9-0.7c-0.9-0.6-1.9-0.8-2.5,0.3c-0.8,1.4-1.5,1.4-2,1.5c1.1,0.8,1.9,0.8,2.6,0.5
              c0.7-0.2,1.3-0.8,2-1.2C50.6,84.6,51,84.5,52,84.5z"/>
            <path class="st24" d="M51.8,85.1c0,0-1.2,0.5-2,0.7c-1.1,0.2-1.9,0.8-1.6,2c0.4,1.5-0.1,2.1-0.4,2.5c2.6-0.6,2.4-2.1,2.9-3.7
              C50.8,86,51,85.7,51.8,85.1z"/>
          </g>
          <g>
            <path class="st24" d="M52.3,78.2c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-1-2.5,0c-1,1.2-1.7,1.2-2.2,1.2c1,0.9,1.8,1,2.5,0.9
              s1.4-0.6,2.1-0.9C50.8,78.1,51.2,78.1,52.3,78.2z"/>
            <path class="st24" d="M52,78.8c0,0-1.3,0.4-2,0.4c-1.1,0.1-2,0.5-1.9,1.7c0.2,1.6-0.4,2-0.8,2.4c2.6-0.3,2.7-1.8,3.3-3.2
              C50.9,79.6,51.1,79.3,52,78.8z"/>
          </g>
          <g>
            <path class="st24" d="M53.4,72.1c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.6-1.2-2.5-0.4c-1.1,1.1-1.8,1-2.4,0.9c0.9,1,1.7,1.3,2.4,1.2
              c0.7,0,1.4-0.4,2.2-0.6C52,71.8,52.4,71.8,53.4,72.1z"/>
            <path class="st24" d="M53,72.6c0-0.1-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.2-2.1,1.4c-0.1,1.6-0.6,2-1.1,2.3c2.6,0.1,2.9-1.4,3.7-2.8
              C51.8,73.2,52.1,73,53,72.6z"/>
          </g>
          <g>
            <path class="st24" d="M55.3,66.1c0-0.1-1-0.8-1.5-1.5c-0.6-0.9-1.4-1.4-2.4-0.7c-1.3,0.9-1.9,0.7-2.5,0.5
              c0.7,1.1,1.5,1.5,2.2,1.5c0.7,0.1,1.5-0.2,2.3-0.3C54,65.6,54.4,65.7,55.3,66.1z"/>
            <path class="st24" d="M54.9,66.6c0-0.1-1.3,0-2.1-0.2c-1.1-0.2-2-0.1-2.3,1.2c-0.3,1.5-0.9,1.9-1.4,2.1c2.6,0.4,3.1-1,4.1-2.2
              C53.6,67.1,53.9,66.8,54.9,66.6z"/>
          </g>
          <g>
            <path class="st24" d="M58,60.5c0,0-0.9-1-1.3-1.6c-0.5-1-1.2-1.6-2.3-1.1c-1.4,0.7-2,0.4-2.5,0.2c0.6,1.2,1.3,1.7,2,1.8
              c0.7,0.2,1.5,0,2.3,0C56.8,59.8,57.2,59.9,58,60.5z"/>
            <path class="st24" d="M57.6,60.9c0-0.1-1.3-0.2-2-0.4c-1-0.4-2-0.3-2.4,0.8c-0.5,1.5-1.1,1.7-1.7,1.9c2.5,0.8,3.2-0.6,4.3-1.6
              C56.2,61.2,56.6,61,57.6,60.9z"/>
          </g>
          <g>
            <path class="st24" d="M61.5,55.3c0,0-0.8-1.1-1-1.8c-0.4-1-1-1.8-2.1-1.4c-1.5,0.6-2.1,0.2-2.5-0.2c0.4,1.3,1,1.8,1.7,2.1
              s1.5,0.2,2.3,0.3C60.4,54.4,60.7,54.6,61.5,55.3z"/>
            <path class="st24" d="M61,55.6c0-0.1-1.3-0.4-1.9-0.7c-1-0.5-1.9-0.6-2.5,0.5c-0.7,1.4-1.4,1.5-1.9,1.6c2.4,1.1,3.2-0.1,4.5-1
              C59.6,55.7,60,55.6,61,55.6z"/>
          </g>
          <g>
            <path class="st24" d="M65.7,50.6c0,0-0.6-1.2-0.8-1.9c-0.2-1.1-0.7-1.9-1.9-1.6c-1.5,0.3-2.1-0.1-2.5-0.5
              c0.2,1.3,0.8,1.9,1.4,2.3c0.7,0.3,1.4,0.4,2.2,0.6C64.6,49.6,65,49.8,65.7,50.6z"/>
            <path class="st24" d="M65.1,50.9c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-0.9-2.5,0.2c-0.9,1.3-1.6,1.3-2.1,1.4
              c2.2,1.4,3.2,0.3,4.6-0.4C63.7,50.8,64.1,50.7,65.1,50.9z"/>
          </g>
          <g>
            <path class="st24" d="M70.4,46.5c0,0-0.5-1.3-0.5-2c-0.1-1.1-0.5-2-1.7-1.9c-1.6,0.1-2-0.4-2.4-0.8c0,1.3,0.5,2,1.1,2.5
              c0.6,0.4,1.4,0.6,2.1,0.9C69.5,45.4,69.8,45.7,70.4,46.5z"/>
            <path class="st24" d="M69.9,46.7c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.7-1.1-2.5-0.2c-1,1.2-1.7,1.1-2.3,1.1c2,1.7,3.2,0.8,4.6,0.2
              C68.5,46.4,68.9,46.4,69.9,46.7z"/>
          </g>
          <g>
            <path class="st24" d="M75.7,43.1c0,0-0.3-1.3-0.2-2c0.1-1.1-0.2-2-1.4-2.1c-1.6-0.1-2-0.7-2.2-1.1c-0.2,1.3,0.2,2.1,0.7,2.6
              s1.3,0.8,2,1.2C75,41.9,75.2,42.2,75.7,43.1z"/>
            <path class="st24" d="M75.1,43.2c0-0.1-1-0.8-1.5-1.4c-0.7-0.9-1.5-1.3-2.5-0.5c-1.2,1-1.9,0.9-2.4,0.8c1.7,2,3,1.2,4.6,0.8
              C73.8,42.8,74.2,42.8,75.1,43.2z"/>
          </g>
          <g>
            <path class="st24" d="M81.4,40.5c0,0-0.1-1.3,0.1-2.1c0.2-1.1,0.1-2-1.1-2.3c-1.6-0.3-1.8-0.9-2.1-1.4c-0.3,1.3-0.1,2.1,0.4,2.7
              c0.5,0.6,1.2,1,1.8,1.5C80.8,39.2,81,39.5,81.4,40.5z"/>
            <path class="st24" d="M80.8,40.5c0-0.1-0.9-1-1.3-1.6c-0.6-1-1.3-1.5-2.4-0.9c-1.3,0.9-2,0.6-2.5,0.4c1.5,2.2,2.8,1.6,4.4,1.5
              C79.5,39.9,79.9,40,80.8,40.5z"/>
          </g>
          <g>
            <path class="st24" d="M87.3,38.7c0.1,0,0.1-1.3,0.3-2c0.4-1,0.4-2-0.8-2.4c-1.5-0.5-1.7-1.2-1.8-1.7c-0.5,1.2-0.4,2.1,0,2.7
              c0.4,0.6,1,1.1,1.6,1.7C87,37.3,87.1,37.6,87.3,38.7z"/>
            <path class="st24" d="M86.8,38.6c0,0-0.8-1.1-1.1-1.8c-0.4-1-1.1-1.7-2.2-1.2c-1.4,0.7-2,0.3-2.5,0.1c1.1,2.4,2.6,2,4.2,2
              C85.6,37.8,86,37.9,86.8,38.6z"/>
          </g>
          <g>
            <path class="st24" d="M93.5,37.6c0.1,0,0.3-1.3,0.6-2c0.5-1,0.6-1.9-0.5-2.5c-1.4-0.7-1.5-1.4-1.6-1.9c-0.7,1.2-0.6,2-0.4,2.7
              c0.3,0.7,0.9,1.2,1.3,1.9C93.3,36.2,93.5,36.6,93.5,37.6z"/>
            <path class="st24" d="M92.9,37.5c0,0-0.6-1.2-0.8-1.9c-0.3-1.1-0.9-1.8-2.1-1.5c-1.5,0.5-2.1,0.1-2.5-0.3
              c0.8,2.5,2.3,2.3,3.8,2.6C91.9,36.5,92.3,36.7,92.9,37.5z"/>
          </g>
          <g>
            <path class="st24" d="M99.8,37.5c0.1,0,0.4-1.3,0.9-1.9c0.6-0.9,0.9-1.8-0.1-2.5c-1.3-0.9-1.3-1.6-1.3-2.1
              c-0.8,1.1-0.9,1.9-0.7,2.6c0.2,0.7,0.7,1.3,1.1,2C99.8,36,99.9,36.4,99.8,37.5z"/>
            <path class="st24" d="M99.2,37.2c0,0-0.4-1.3-0.5-2c-0.1-1.1-0.6-1.9-1.8-1.7c-1.5,0.3-2-0.2-2.4-0.6c0.5,2.6,2,2.6,3.5,3.1
              C98.3,36.2,98.7,36.4,99.2,37.2z"/>
          </g>
          <g>
            <path class="st24" d="M106,38.2c0.1,0,0.6-1.2,1.1-1.7c0.8-0.8,1.1-1.7,0.2-2.5c-1.2-1.1-1.1-1.7-1-2.3c-1,0.9-1.2,1.7-1.1,2.5
              c0.1,0.7,0.5,1.4,0.8,2.2C106.2,36.7,106.2,37.1,106,38.2z"/>
            <path class="st24" d="M105.5,37.9c0.1,0-0.3-1.3-0.3-2.1c0-1.1-0.4-2-1.6-2c-1.6,0.1-2-0.5-2.3-0.9c0.1,2.6,1.6,2.8,3,3.5
              C104.7,36.7,105,36.9,105.5,37.9z"/>
          </g>
          <g>
            <path class="st24" d="M112.1,39.7c0.1,0,0.8-1.1,1.3-1.6c0.9-0.7,1.3-1.5,0.6-2.5c-1-1.2-0.8-1.9-0.7-2.4
              c-1.1,0.8-1.4,1.6-1.4,2.3s0.3,1.5,0.5,2.3C112.5,38.3,112.4,38.7,112.1,39.7z"/>
            <path class="st24" d="M111.6,39.3c0.1,0-0.1-1.3,0-2.1c0.2-1.1-0.1-2-1.3-2.2c-1.6-0.2-1.9-0.8-2.2-1.2c-0.3,2.6,1.2,3,2.5,3.9
              C111,38,111.3,38.4,111.6,39.3z"/>
          </g>
          <g>
            <path class="st24" d="M117.9,42c0,0,0.9-1,1.5-1.4c0.9-0.6,1.5-1.3,0.9-2.4c-0.8-1.3-0.6-2-0.4-2.5c-1.2,0.6-1.6,1.4-1.7,2.1
              c-0.1,0.7,0.1,1.5,0.2,2.3C118.5,40.7,118.4,41.1,117.9,42z"/>
            <path class="st24" d="M117.5,41.6c0.1,0,0.1-1.3,0.3-2c0.3-1.1,0.2-2-1-2.3c-1.5-0.4-1.8-1-2-1.5c-0.6,2.6,0.8,3.2,1.9,4.2
              C117.1,40.2,117.3,40.6,117.5,41.6z"/>
          </g>
          <g>
            <path class="st24" d="M123.3,45.1c0,0,1-0.8,1.7-1.1c1-0.4,1.7-1.1,1.2-2.2c-0.6-1.4-0.3-2,0-2.5c-1.3,0.5-1.8,1.1-2,1.8
              s-0.1,1.5-0.2,2.3C124.1,43.9,123.9,44.3,123.3,45.1z"/>
            <path class="st24" d="M123,44.7c0.1,0,0.3-1.3,0.6-2c0.5-1,0.5-2-0.7-2.4c-1.5-0.6-1.6-1.3-1.8-1.8c-1,2.5,0.3,3.2,1.3,4.4
              C122.8,43.3,122.9,43.6,123,44.7z"/>
          </g>
          <g>
            <path class="st24" d="M128.3,49c0,0,1.1-0.7,1.9-0.9c1.1-0.3,1.8-0.9,1.5-2c-0.4-1.5,0-2.1,0.3-2.5c-1.3,0.3-1.9,0.9-2.2,1.6
              c-0.3,0.7-0.3,1.5-0.5,2.3C129.2,47.9,129,48.2,128.3,49z"/>
            <path class="st24" d="M128,48.4c0.1,0,0.4-1.3,0.8-1.9c0.6-0.9,0.7-1.9-0.3-2.5c-1.4-0.8-1.4-1.5-1.5-2
              c-1.3,2.3-0.1,3.2,0.7,4.6C128,47,128.1,47.4,128,48.4z"/>
          </g>
          <g>
            <path class="st24" d="M132.6,53.4c0,0,1.2-0.5,2-0.6c1.1-0.2,1.9-0.6,1.8-1.8c-0.2-1.6,0.3-2,0.7-2.4c-1.3,0.1-2,0.6-2.4,1.2
              c-0.4,0.6-0.5,1.4-0.8,2.2C133.7,52.5,133.5,52.8,132.6,53.4z"/>
            <path class="st24" d="M132.4,52.9c0.1,0,0.6-1.2,1.1-1.8c0.7-0.8,1-1.8,0-2.5c-1.2-1-1.2-1.6-1.2-2.2c-1.6,2.1-0.5,3.2,0.1,4.6
              C132.6,51.5,132.7,51.9,132.4,52.9z"/>
          </g>
          <g>
            <path class="st24" d="M136.4,58.4c0,0,1.3-0.4,2-0.4c1.1,0,2-0.3,2-1.6c0-1.6,0.5-2,1-2.3c-1.3-0.1-2.1,0.3-2.5,0.9
              c-0.5,0.6-0.7,1.3-1.1,2C137.6,57.6,137.3,57.9,136.4,58.4z"/>
            <path class="st24" d="M136.3,57.9c0.1,0,0.8-1.1,1.3-1.6c0.8-0.7,1.2-1.6,0.4-2.5c-1.1-1.1-1-1.8-0.9-2.3
              c-1.9,1.9-1,3.1-0.5,4.6C136.6,56.5,136.6,56.9,136.3,57.9z"/>
          </g>
          <g>
            <path class="st24" d="M139.4,63.9c0,0,1.3-0.2,2.1-0.1c1.1,0.1,2-0.1,2.2-1.3c0.2-1.6,0.8-1.9,1.3-2.1c-1.3-0.2-2.1,0.1-2.6,0.6
              s-0.9,1.2-1.3,1.9C140.7,63.3,140.4,63.5,139.4,63.9z"/>
            <path class="st24" d="M139.4,63.3c0.1,0,0.9-1,1.5-1.4c0.9-0.6,1.4-1.4,0.7-2.4c-0.9-1.3-0.7-1.9-0.6-2.5
              c-2.1,1.6-1.4,2.9-1.2,4.5C139.9,62,139.9,62.4,139.4,63.3z"/>
          </g>
          <g>
            <path class="st24" d="M141.7,69.8c0,0.1,1.3,0,2.1,0.2c1.1,0.3,2,0.2,2.3-1c0.4-1.5,1-1.8,1.6-2c-1.3-0.4-2.1-0.2-2.7,0.2
              c-0.6,0.4-1,1.1-1.6,1.7C143,69.3,142.7,69.5,141.7,69.8z"/>
            <path class="st24" d="M141.7,69.2c0.1,0,1-0.8,1.7-1.2c1-0.5,1.6-1.2,1-2.3c-0.8-1.4-0.5-2-0.2-2.5c-2.3,1.3-1.8,2.7-1.8,4.3
              C142.4,68,142.3,68.3,141.7,69.2z"/>
          </g>
          <g>
            <path class="st24" d="M143.1,75.9c0,0.1,1.3,0.2,2,0.5c1,0.4,2,0.5,2.5-0.6c0.6-1.5,1.3-1.6,1.8-1.7c-1.2-0.6-2-0.5-2.7-0.2
              s-1.2,0.9-1.8,1.5C144.5,75.6,144.1,75.7,143.1,75.9z"/>
            <path class="st24" d="M143.2,75.3c0,0,1.1-0.7,1.8-0.9c1.1-0.4,1.8-1,1.3-2.2c-0.6-1.5-0.2-2,0.1-2.5c-2.4,1-2.1,2.5-2.3,4
              C144.1,74.2,143.9,74.5,143.2,75.3z"/>
          </g>
          <g>
            <path class="st24" d="M143.7,82.1c0,0.1,1.3,0.4,1.9,0.7c0.9,0.6,1.9,0.8,2.5-0.3c0.8-1.4,1.5-1.4,2-1.5
              c-1.1-0.8-1.9-0.8-2.6-0.5c-0.7,0.2-1.3,0.8-2,1.2C145.1,82,144.7,82.1,143.7,82.1z"/>
            <path class="st24" d="M143.9,81.5c0,0,1.2-0.5,2-0.7c1.1-0.2,1.9-0.8,1.6-2c-0.4-1.5,0.1-2.1,0.4-2.5c-2.6,0.6-2.4,2.1-2.9,3.7
              C144.9,80.6,144.7,80.9,143.9,81.5z"/>
          </g>
        </g>
      </g>
      <g>
        <path d="M52.7,80.1l0.1-1l4.5,0.4l-0.1,1L52.7,80.1z"/>
        <path d="M53.6,73.7l4.4,0.8l-0.1,0.8L54.8,77l2.7,0.5l-0.2,1l-4.4-0.8l0.1-0.9l3.1-1.7l-2.7-0.5L53.6,73.7z"/>
        <path d="M55,71.6L54.6,73l-0.8-0.2l1.1-3.8l0.8,0.2l-0.4,1.4l3.5,1l-0.3,1L55,71.6z"/>
        <path d="M60,66.2l0.8,0.3l-1.3,3.2L55.3,68l1.3-3.2l0.8,0.3l-0.9,2.2l0.9,0.4l0.8-1.9l0.8,0.3L58.2,68l1,0.4L60,66.2z"/>
        <path d="M62.4,63.3l-1.5,0.2l0,0l-0.4,0.8l1.1,0.6l-0.6,1l-4-2.1l1-1.8c0.2-0.4,0.4-0.6,0.7-0.8s0.5-0.3,0.8-0.3s0.6,0,0.9,0.2
          c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.3,0.2,0.5,0.2,0.9l1.8-0.2L62.4,63.3z M59.2,62c-0.2,0.1-0.4,0.3-0.5,0.5l-0.4,0.7l1.4,0.7
          l0.4-0.8c0.1-0.3,0.2-0.5,0.2-0.7s-0.2-0.4-0.4-0.5C59.6,61.9,59.4,61.9,59.2,62z"/>
        <path d="M61.9,55.9l3.7,2.6l-0.5,0.7l-3.5,0.3l2.2,1.6l-0.6,0.8l-3.7-2.6l0.5-0.7l3.5-0.3l-2.2-1.6L61.9,55.9z"/>
        <path d="M67.5,54.8l-1.4,1.6l0.5,0.9l-0.7,0.8l-2-4.5l0.7-0.8l4.7,1.5l-0.7,0.8L67.5,54.8z M66.7,54.6L64.9,54l0.8,1.7L66.7,54.6z
          "/>
        <path d="M67.6,51l-1.1,1l-0.6-0.6l2.9-2.6l0.6,0.6l-1.1,1l2.5,2.7L70,53.7L67.6,51z"/>
        <path d="M69.5,48.2l0.8-0.6l2.8,3.5l-0.8,0.6L69.5,48.2z"/>
        <path d="M74.5,49.8c-0.4,0-0.8,0-1.2-0.2c-0.4-0.2-0.7-0.5-0.9-0.8C72.1,48.4,72,48,72,47.6s0.1-0.8,0.3-1.2
          c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.3,0.8-0.4,1.2-0.4c0.4,0,0.8,0,1.2,0.2s0.7,0.5,0.9,0.8s0.4,0.8,0.4,1.2s-0.1,0.8-0.3,1.2
          c-0.2,0.4-0.5,0.7-0.9,0.9C75.3,49.6,74.9,49.8,74.5,49.8z M75.7,48.1c0.1-0.2,0.2-0.5,0.1-0.7c0-0.3-0.1-0.5-0.3-0.7
          c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c0,0.3,0,0.5,0,0.7
          c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2s0.5-0.1,0.7-0.2C75.5,48.5,75.6,48.3,75.7,48.1z"/>
        <path d="M80.1,41.8l2,4.1l-0.8,0.4l-3.2-1.5l1.2,2.5l-0.9,0.4l-2-4.1l0.8-0.4l3.2,1.5l-1.2-2.5L80.1,41.8z"/>
        <path d="M85.4,43.6l-2,0.7l-0.1,1l-1,0.3l0.5-4.9l1-0.3l3.4,3.6l-1,0.4L85.4,43.6z M84.9,43l-1.2-1.4l-0.1,1.9L84.9,43z"/>
        <path d="M86.6,39.6l1-0.2l0.8,3.6l2.2-0.5l0.2,0.8L87.6,44L86.6,39.6z"/>
        <path d="M95.9,41.8l-2.1,0.1l-0.3,1L92.4,43l1.7-4.6l1-0.1l2.3,4.4l-1.1,0.1L95.9,41.8z M95.5,41l-0.8-1.7l-0.6,1.8L95.5,41z"/>
        <path d="M97.9,38.2h1l-0.1,4.5h-1L97.9,38.2z"/>
        <path d="M102.3,38.4l2,0.3c0.5,0.1,0.9,0.2,1.2,0.5c0.3,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.2,0.8,0.1,1.2s-0.2,0.8-0.5,1.1
          s-0.6,0.5-1,0.7c-0.4,0.1-0.8,0.2-1.3,0.1l-2-0.3L102.3,38.4z M103.8,42.3c0.4,0.1,0.8,0,1.1-0.2c0.3-0.2,0.5-0.5,0.5-1
          c0.1-0.4,0-0.8-0.3-1.1c-0.2-0.3-0.6-0.5-1-0.5l-0.9-0.1l-0.4,2.8L103.8,42.3z"/>
        <path d="M109.6,44.4l-0.5-1.4l0,0l-0.9-0.2l-0.3,1.2l-1-0.3l1.2-4.4l1.9,0.5c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.4,0.4,0.5,0.7
          s0.1,0.6,0,0.9c-0.1,0.3-0.2,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.4l0.6,1.7L109.6,44.4z M110.2,41c-0.1-0.2-0.3-0.3-0.6-0.4
          l-0.8-0.2l-0.4,1.5l0.8,0.2c0.3,0.1,0.6,0.1,0.7,0c0.2-0.1,0.3-0.3,0.4-0.5C110.4,41.4,110.3,41.2,110.2,41z"/>
        <path d="M112.8,40.8l1,0.4l-1.5,4.2l-1-0.4L112.8,40.8z"/>
        <path d="M119,43.4l-3.7,3.3l-0.9-0.4l0.1-4.9l1,0.5l-0.1,3.5L118,43L119,43.4z"/>
        <path d="M119.5,43.8l0.9,0.5l-2.2,3.9l-0.9-0.5L119.5,43.8z"/>
        <path d="M125.1,47.3l-2.5,3.7l-0.7-0.5l-0.3-3.5l-1.5,2.3l-0.9-0.6l2.5-3.7l0.7,0.5l0.3,3.5l1.5-2.3L125.1,47.3z"/>
        <path d="M127.1,51.8l0.7,0.6l-1.2,1.4c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.4-0.3-0.6-0.7-0.8-1.1
          c-0.1-0.4-0.2-0.8-0.1-1.2s0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.2,0.6
          c0.3,0.3,0.5,0.5,0.7,0.9c0.2,0.3,0.2,0.6,0.2,1H128c0-0.5-0.2-0.9-0.5-1.2c-0.2-0.2-0.4-0.3-0.7-0.4c-0.2-0.1-0.5,0-0.7,0
          s-0.5,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.2,0,0.5,0.1,0.7s0.2,0.5,0.4,0.6c0.2,0.2,0.5,0.3,0.7,0.4L127.1,51.8z"/>
        <path d="M131.5,53.2l0.7,0.8l-2.8,2.4l1.5,1.7l-0.7,0.5l-2.1-2.5L131.5,53.2z"/>
        <path d="M134.1,56.5l0.6,0.8l-3.6,2.7l-0.6-0.8L134.1,56.5z"/>
        <path d="M132.3,61.4c0-0.4,0.1-0.8,0.3-1.2s0.5-0.7,0.9-0.9s0.8-0.4,1.2-0.4s0.8,0.1,1.2,0.3s0.7,0.5,0.9,0.9
          c0.2,0.3,0.3,0.7,0.4,1c0,0.3,0,0.7-0.1,1l-0.9-0.3c0.1-0.4,0.1-0.9-0.1-1.2c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.7-0.2
          c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7c0.2,0.4,0.6,0.6,1,0.7l-0.2,0.9
          c-0.3,0-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.5-0.7-0.8C132.5,62.2,132.4,61.8,132.3,61.4z"/>
        <path d="M136.3,67.5l-0.8,0.4l-1.5-3.2l4.1-1.9l1.4,3.1l-0.8,0.4l-1-2.1l-0.9,0.4l0.9,1.9l-0.7,0.3l-0.9-1.9l-0.9,0.4L136.3,67.5z
          "/>
        <path d="M141.4,71l-4.3,1.4l-0.3-0.8l1.9-3l-2.6,0.9l-0.3-1l4.3-1.4l0.3,0.8l-1.9,3l2.6-0.9L141.4,71z"/>
        <path d="M137.5,73.8c0-0.3,0.1-0.6,0.2-0.9l0.8,0.2c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0,0.5,0,0.8c0.1,0.3,0.2,0.5,0.3,0.6
          s0.2,0.2,0.4,0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.6c0-0.4,0-0.6,0.1-0.9c0-0.2,0.1-0.4,0.3-0.6
          c0.1-0.2,0.4-0.3,0.7-0.4s0.5,0,0.7,0.1s0.4,0.3,0.6,0.5c0.2,0.3,0.3,0.6,0.4,1c0.1,0.3,0.1,0.6,0.1,0.8s-0.1,0.5-0.1,0.8
          l-0.8-0.1c0.1-0.5,0.2-0.9,0.1-1.3c-0.1-0.3-0.2-0.5-0.3-0.6s-0.3-0.2-0.4-0.1c-0.2,0-0.2,0.1-0.3,0.3c0,0.2-0.1,0.4-0.1,0.8
          s0,0.6-0.1,0.9c0,0.2-0.1,0.4-0.3,0.6c-0.1,0.2-0.4,0.3-0.7,0.4s-0.5,0-0.7-0.1s-0.4-0.3-0.6-0.5c-0.2-0.3-0.3-0.6-0.4-1
          C137.5,74.5,137.5,74.2,137.5,73.8z"/>
        <path d="M139.3,80.7l-0.8,0.1l-0.4-3.5l4.5-0.5l0.4,3.4l-0.8,0.1l-0.3-2.4l-1,0.1l0.2,2.1l-0.8,0.1l-0.2-2.1l-1,0.1L139.3,80.7z"
          />
      </g>
      <g>
        <path d="M69.5,68.9h3.3v14.4h-3.3V68.9z"/>
        <path d="M85.7,80.2H79l-1.3,3.1h-3.4l6.4-14.4H84l6.4,14.4H87L85.7,80.2z M84.7,77.7l-2.3-5.5l-2.3,5.5H84.7z"/>
        <path d="M92,68.9h3.3v14.4H92V68.9z"/>
        <path d="M98.7,68.9h6.5c1.6,0,2.9,0.3,4.1,0.9s2.1,1.4,2.8,2.5c0.7,1.1,1,2.3,1,3.8c0,1.4-0.3,2.7-1,3.8c-0.7,1.1-1.6,1.9-2.8,2.5
          s-2.6,0.9-4.1,0.9h-6.5V68.9z M105.1,80.6c1.4,0,2.6-0.4,3.4-1.2c0.9-0.8,1.3-1.9,1.3-3.3s-0.4-2.5-1.3-3.3s-2-1.2-3.4-1.2h-3v8.9
          L105.1,80.6L105.1,80.6z"/>
        <path d="M115.7,68.9h3.3v11.7h7.2v2.7h-10.5V68.9z"/>
      </g>
      <polygon points="97.8,48.3 99.8,52.3 104.3,52.9 101.1,56.1 101.8,60.5 97.8,58.4 93.9,60.5 94.6,56.1 91.4,52.9 95.9,52.3 	"/>
      <g>
        <g>
          <polygon points="108.5,56.9 109.8,59.4 112.5,59.8 110.5,61.7 111,64.4 108.5,63.1 106.1,64.4 106.6,61.7 104.6,59.8 107.3,59.4 
                  "/>
          <polygon points="87.2,56.9 88.4,59.4 91.1,59.8 89.1,61.7 89.6,64.4 87.2,63.1 84.7,64.4 85.2,61.7 83.2,59.8 85.9,59.4 			"/>
        </g>
      </g>
      <g class="st8">
        <text transform="matrix(1 0 0 1 62 113)" class="st9 st10">{{ token }}</text>
      </g>
      
      <g class="st8">
        <path d="M72.4,100.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L72.4,100.7z M72.1,99.9l-0.5-1.3L71,99.9H72.1z"/>
        <path d="M75.1,101.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
          s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
          c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
          c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.6,0.2-1,0.2C75.8,101.6,75.4,101.5,75.1,101.3z"/>
        <path d="M79.3,101.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
          s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
          c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
          c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.1-0.6,0.2-1,0.2C80,101.6,79.6,101.5,79.3,101.3z"/>
        <path d="M84.5,100.4h-0.6v1.1h-1.2v-4.1h1.9c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.6,0.5s0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8
          s-0.3,0.4-0.6,0.5l0.9,1.3h-1.2L84.5,100.4z M85.1,98.5c-0.1-0.1-0.3-0.2-0.5-0.2h-0.6v1.2h0.6c0.2,0,0.4-0.1,0.5-0.2
          c0.1-0.1,0.2-0.3,0.2-0.4S85.2,98.6,85.1,98.5z"/>
        <path d="M90.3,100.6v0.9H87v-4.1h3.2v0.9h-2.1V99H90v0.9h-1.8v0.7H90.3z"/>
        <path d="M91,97.4h1.9c0.4,0,0.8,0.1,1.2,0.3s0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.3,1.1
          c-0.2,0.3-0.5,0.5-0.8,0.7s-0.7,0.3-1.2,0.3H91V97.4z M92.9,100.6c0.4,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8
          s-0.5-0.3-0.8-0.3h-0.7v2.2H92.9z"/>
        <path d="M95.8,97.4h1.2v4.1h-1.2V97.4z"/>
        <path d="M98.6,98.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V98.3z"/>
        <path d="M104,100.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L104,100.7z M103.7,99.9l-0.5-1.3l-0.5,1.3H103.7z"/>
        <path d="M106.5,98.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V98.3z"/>
        <path d="M109.3,97.4h1.2v4.1h-1.2V97.4z"/>
        <path d="M112.2,101.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
          s0.7-0.3,1.2-0.3s0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.1,0.8-0.3,1.1
          c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.2,0.3S112.6,101.5,112.2,101.3z M113.9,100.5c0.2-0.1,0.3-0.2,0.4-0.4
          c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1
          c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
          S113.8,100.6,113.9,100.5z"/>
        <path d="M120.1,97.4v4.1h-1l-1.8-2.2v2.2h-1.1v-4.1h1l1.8,2.2v-2.2H120.1z"/>
        <path d="M125.6,99l-0.1,0.8h0.7v0.8h-0.8l-0.1,0.9h-0.8l0.1-0.9h-0.7l-0.1,0.9H123l0.1-0.9h-0.7v-0.8h0.8l0.1-0.8h-0.7v-0.8h0.8
          l0.1-0.9h0.8l-0.1,0.9h0.7l0.1-0.9h0.8l-0.1,0.9h0.7V99H125.6z M124.8,99h-0.7l-0.1,0.8h0.7L124.8,99z"/>
      </g>
    </g> -->
    <g class="st1505">
		<g>
			<linearGradient id="SVGID_147_" gradientUnits="userSpaceOnUse" x1="107.8553" y1="168.4606" x2="107.8553" y2="38.1516">
				<stop  offset="0" style="stop-color:#B75C27"/>
				<stop  offset="4.703292e-02" style="stop-color:#BB6229"/>
				<stop  offset="0.1216" style="stop-color:#C37332"/>
				<stop  offset="0.2144" style="stop-color:#D38E44"/>
				<stop  offset="0.3208" style="stop-color:#ECBB66"/>
				<stop  offset="0.3758" style="stop-color:#FFD97C"/>
				<stop  offset="0.4816" style="stop-color:#FFDB7D"/>
				<stop  offset="0.5429" style="stop-color:#FFE081"/>
				<stop  offset="0.5879" style="stop-color:#FDE888"/>
				<stop  offset="0.6238" style="stop-color:#FFE081"/>
				<stop  offset="0.6705" style="stop-color:#FFDB7D"/>
				<stop  offset="0.7636" style="stop-color:#FFD97C"/>
				<stop  offset="0.8466" style="stop-color:#E1A858"/>
				<stop  offset="1" style="stop-color:#B75C27"/>
			</linearGradient>
			<path class="st1506" d="M147.2,153.8l-0.9-0.2c-2-0.3-4,1-4.4,3.1l-0.2,0.9c-0.5,2.3-2.9,3.7-5.1,2.9l-0.9-0.3
				c-1.9-0.7-4.1,0.2-4.9,2.2l-0.4,0.9c-0.9,2.2-3.6,3.1-5.6,1.8l-0.8-0.5c-1.8-1.1-4.1-0.6-5.2,1.2l-0.5,0.8
				c-1.3,2-4.1,2.3-5.8,0.7l-0.7-0.6c-1.5-1.4-3.9-1.4-5.3,0.1l-0.7,0.7c-1.7,1.7-4.5,1.5-5.8-0.5l-0.6-0.8
				c-1.2-1.7-3.5-2.1-5.3-0.9l-0.8,0.5c-2,1.3-4.7,0.6-5.6-1.6l-0.4-0.9c-0.9-1.9-3.1-2.7-5-1.9l-0.9,0.4c-2.2,0.9-4.7-0.3-5.2-2.7
				l-0.2-0.9c-0.5-2-2.5-3.3-4.5-2.9l-0.9,0.2c-2.3,0.5-4.5-1.3-4.6-3.6l0-0.9c-0.1-2.1-1.8-3.7-3.9-3.7l-0.9,0
				c-2.4,0-4.2-2.1-3.8-4.5l0.2-0.9c0.3-2.1-1-4-3.1-4.4l-0.9-0.2c-2.3-0.5-3.7-2.9-2.9-5.1l0.3-0.9c0.7-1.9-0.2-4.1-2.2-4.9
				l-0.9-0.4c-2.2-0.9-3.1-3.6-1.8-5.6l0.5-0.8c1.1-1.8,0.6-4.1-1.2-5.2l-0.8-0.5c-2-1.3-2.3-4.1-0.7-5.8l0.6-0.7
				c1.4-1.5,1.4-3.9-0.1-5.3l-0.7-0.7c-1.7-1.7-1.5-4.5,0.5-5.8l0.8-0.6c1.7-1.2,2.1-3.5,0.9-5.3l-0.5-0.8c-1.3-2-0.6-4.7,1.6-5.6
				l0.9-0.4c1.9-0.9,2.7-3.1,1.9-5l-0.4-0.9c-0.9-2.2,0.3-4.7,2.7-5.2l0.9-0.2c2-0.5,3.3-2.5,2.9-4.5l-0.2-0.9
				c-0.5-2.3,1.3-4.5,3.6-4.6l0.9,0c2.1-0.1,3.7-1.8,3.7-3.9l0-0.9c0-2.4,2.1-4.2,4.5-3.8l0.9,0.2c2.1,0.3,4-1,4.4-3.1L74,49
				c0.5-2.3,2.9-3.7,5.1-2.9l0.9,0.3c1.9,0.7,4.1-0.2,4.9-2.2l0.4-0.9c0.9-2.2,3.6-3.1,5.6-1.8l0.8,0.5c1.8,1.1,4.1,0.6,5.2-1.2
				l0.5-0.8c1.3-2,4.1-2.3,5.8-0.7l0.7,0.6c1.5,1.4,3.9,1.4,5.3-0.1l0.7-0.7c1.7-1.7,4.5-1.5,5.8,0.5l0.6,0.8
				c1.2,1.7,3.5,2.1,5.3,0.9l0.8-0.5c2-1.3,4.7-0.6,5.6,1.6l0.4,0.9c0.9,1.9,3.1,2.7,5,1.9l0.9-0.4c2.2-0.9,4.7,0.3,5.2,2.7l0.2,0.9
				c0.5,2,2.5,3.3,4.5,2.9l0.9-0.2c2.3-0.5,4.5,1.3,4.6,3.6l0,0.9c0.1,2.1,1.8,3.7,3.9,3.7l0.9,0c2.4,0,4.2,2.1,3.8,4.5l-0.2,0.9
				c-0.3,2.1,1,4,3.1,4.4l0.9,0.2c2.3,0.5,3.7,2.9,2.9,5.1l-0.3,0.9c-0.7,1.9,0.2,4.1,2.2,4.9l0.9,0.4c2.2,0.9,3.1,3.6,1.8,5.6
				l-0.5,0.8c-1.1,1.8-0.6,4.1,1.2,5.2l0.8,0.5c2,1.3,2.3,4.1,0.7,5.8l-0.6,0.7c-1.4,1.5-1.4,3.9,0.1,5.4l0.7,0.7
				c1.7,1.7,1.5,4.5-0.5,5.8l-0.8,0.6c-1.7,1.2-2.1,3.5-0.9,5.3l0.5,0.8c1.3,2,0.6,4.7-1.6,5.6l-0.9,0.4c-1.9,0.9-2.7,3.1-1.9,5
				l0.4,0.9c0.9,2.2-0.3,4.7-2.7,5.2l-0.9,0.2c-2,0.5-3.3,2.5-2.9,4.5l0.2,0.9c0.5,2.3-1.3,4.5-3.6,4.6l-0.9,0
				c-2.1,0.1-3.7,1.8-3.7,3.9l0,0.9C151.7,152.4,149.6,154.2,147.2,153.8z"/>
			<g class="st1507">
				<defs>
					<path id="SVGID_148_" class="st1507" d="M146.8,153.7L146.8,153.7c-2.4-0.4-4.5,1.1-4.9,3.4l0,0.1c-0.5,2.6-3.2,4.1-5.6,3.2
						l-0.1,0c-2.1-0.8-4.5,0.3-5.4,2.4l0,0.1c-1,2.4-3.9,3.4-6.2,2l-0.1,0c-1.9-1.2-4.5-0.6-5.8,1.3l-0.1,0.1
						c-1.4,2.2-4.5,2.6-6.4,0.8l-0.1-0.1c-1.7-1.6-4.3-1.5-5.9,0.1l-0.1,0.1c-1.8,1.9-4.9,1.6-6.5-0.5l-0.1-0.1
						c-1.3-1.9-3.9-2.3-5.8-1l-0.1,0.1c-2.2,1.5-5.1,0.6-6.2-1.7l0-0.1c-1-2.1-3.4-3-5.5-2.1l-0.1,0c-2.4,1-5.2-0.4-5.8-2.9l0-0.1
						c-0.5-2.2-2.7-3.6-5-3.2l-0.1,0c-2.6,0.5-5-1.4-5.1-4l0-0.1c-0.1-2.3-2-4.1-4.3-4.1l-0.1,0c-2.6,0-4.6-2.3-4.2-4.9l0-0.1
						c0.4-2.3-1.1-4.4-3.4-4.8l-0.1,0c-2.6-0.5-4.1-3.2-3.2-5.6l0-0.1c0.8-2.1-0.3-4.5-2.4-5.4l-0.1,0c-2.4-1-3.4-3.9-2-6.2l0-0.1
						c1.2-1.9,0.6-4.5-1.3-5.8l-0.1-0.1c-2.2-1.4-2.6-4.5-0.8-6.4l0.1-0.1c1.6-1.7,1.5-4.3-0.1-5.9l-0.1-0.1
						c-1.9-1.8-1.6-4.9,0.5-6.5l0.1-0.1c1.9-1.3,2.3-3.9,1-5.8l-0.1-0.1c-1.5-2.2-0.6-5.1,1.7-6.2l0.1,0c2.1-1,3-3.4,2.1-5.5l0-0.1
						c-1-2.4,0.4-5.2,2.9-5.8l0.1,0c2.2-0.5,3.6-2.7,3.2-5l0-0.1c-0.5-2.6,1.4-5,4-5.1l0.1,0c2.3-0.1,4.1-2,4.1-4.3l0-0.1
						c0-2.6,2.3-4.6,4.9-4.2l0.1,0c2.3,0.4,4.4-1.1,4.8-3.4l0-0.1c0.5-2.6,3.2-4.1,5.6-3.2l0.1,0c2.1,0.8,4.5-0.3,5.4-2.4l0-0.1
						c1-2.4,3.9-3.4,6.2-2l0.1,0c1.9,1.2,4.5,0.6,5.8-1.3l0.1-0.1c1.4-2.2,4.5-2.6,6.4-0.8l0.1,0.1c1.7,1.6,4.3,1.5,5.9-0.1l0.1-0.1
						c1.8-1.9,4.9-1.6,6.5,0.5l0.1,0.1c1.3,1.9,3.9,2.3,5.8,1l0.1-0.1c2.2-1.5,5.1-0.6,6.2,1.7l0,0.1c1,2.1,3.4,3,5.5,2.1l0.1,0
						c2.4-1,5.2,0.4,5.8,2.9l0,0.1c0.5,2.2,2.7,3.6,5,3.2l0.1,0c2.6-0.5,5,1.4,5.1,4l0,0.1c0.1,2.3,2,4.1,4.3,4.1l0.1,0
						c2.6,0,4.6,2.3,4.2,4.9l0,0.1c-0.4,2.3,1.1,4.4,3.4,4.8l0.1,0c2.6,0.5,4.1,3.2,3.2,5.6l0,0.1c-0.8,2.1,0.3,4.5,2.4,5.4l0.1,0
						c2.4,1,3.4,3.9,2,6.2l0,0.1c-1.2,1.9-0.6,4.5,1.3,5.8l0.1,0.1c2.2,1.4,2.6,4.5,0.8,6.4l-0.1,0.1c-1.6,1.7-1.5,4.3,0.1,5.9
						l0.1,0.1c1.9,1.8,1.6,4.9-0.5,6.5l-0.1,0.1c-1.9,1.3-2.3,3.9-1,5.8l0.1,0.1c1.5,2.2,0.6,5.1-1.7,6.2l-0.1,0
						c-2.1,1-3,3.4-2.1,5.5l0,0.1c1,2.4-0.4,5.2-2.9,5.8l-0.1,0c-2.2,0.5-3.6,2.7-3.2,5l0,0.1c0.5,2.6-1.4,5-4,5.1l-0.1,0
						c-2.3,0.1-4.1,2-4.1,4.3l0,0.1C151.7,152.1,149.4,154.2,146.8,153.7z"/>
				</defs>
				<clipPath id="SVGID_149_">
					<use xlink:href="#SVGID_148_"  style="overflow:visible;"/>
				</clipPath>
			</g>
			<g>
				<linearGradient id="SVGID_150_" gradientUnits="userSpaceOnUse" x1="52.6772" y1="103.306" x2="163.033" y2="103.306">
					<stop  offset="0.1758" style="stop-color:#DB953B"/>
					<stop  offset="0.3758" style="stop-color:#FFD97C"/>
					<stop  offset="0.4816" style="stop-color:#FFDB7D"/>
					<stop  offset="0.5429" style="stop-color:#FFE081"/>
					<stop  offset="0.5879" style="stop-color:#FDE888"/>
					<stop  offset="0.6238" style="stop-color:#FFE081"/>
					<stop  offset="0.6705" style="stop-color:#FFDB7D"/>
					<stop  offset="0.7636" style="stop-color:#FFD97C"/>
				</linearGradient>
				<path class="st1510" d="M107.9,48.1c-30.5,0-55.2,24.7-55.2,55.2c0,30.5,24.7,55.2,55.2,55.2s55.2-24.7,55.2-55.2
					C163,72.8,138.3,48.1,107.9,48.1z M107.9,157c-29.7,0-53.7-24-53.7-53.7c0-29.7,24-53.7,53.7-53.7c29.7,0,53.7,24,53.7,53.7
					C161.5,133,137.5,157,107.9,157z"/>
			</g>
			<g class="st221">
				<g>
					<path class="st1511" d="M153.4,108.4c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,1,2.5,0c1-1.2,1.7-1.2,2.2-1.2c-1-0.9-1.8-1-2.5-0.9
						c-0.7,0.1-1.4,0.6-2.1,0.9C154.9,108.5,154.5,108.5,153.4,108.4z"/>
					<path class="st1511" d="M153.7,107.8c0,0,1.3-0.4,2-0.4c1.1-0.1,2-0.5,1.9-1.7c-0.2-1.6,0.4-2,0.8-2.4
						c-2.6,0.3-2.7,1.8-3.3,3.2C154.9,107,154.6,107.3,153.7,107.8z"/>
				</g>
				<g>
					<path class="st1511" d="M152.3,114.5c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.6,1.2,2.5,0.4c1.1-1.1,1.8-1,2.4-0.9
						c-0.9-1-1.7-1.3-2.4-1.2c-0.7,0-1.5,0.4-2.2,0.6C153.7,114.8,153.3,114.8,152.3,114.5z"/>
					<path class="st1511" d="M152.7,114c0,0.1,1.3-0.2,2.1-0.1c1.1,0.1,2-0.2,2.1-1.5c0.1-1.6,0.6-2,1.1-2.3
						c-2.6-0.1-2.9,1.4-3.7,2.8C153.9,113.4,153.6,113.6,152.7,114z"/>
				</g>
				<g>
					<path class="st1511" d="M150.4,120.5c0,0.1,1,0.8,1.5,1.5c0.6,0.9,1.4,1.4,2.4,0.7c1.3-0.9,1.9-0.7,2.5-0.5
						c-0.7-1.1-1.5-1.5-2.2-1.5c-0.7-0.1-1.5,0.2-2.3,0.3C151.7,121,151.3,120.9,150.4,120.5z"/>
					<path class="st1511" d="M150.8,120c0,0.1,1.3,0,2.1,0.2c1.1,0.2,2,0.1,2.3-1.2c0.3-1.5,0.9-1.9,1.4-2.1c-2.6-0.4-3.1,1-4.1,2.2
						C152.1,119.5,151.8,119.8,150.8,120z"/>
				</g>
				<g>
					<path class="st1511" d="M147.7,126.1c0,0,0.9,1,1.3,1.6c0.5,1,1.2,1.6,2.3,1.1c1.4-0.7,2-0.4,2.5-0.2c-0.6-1.2-1.3-1.7-2-1.8
						c-0.7-0.2-1.5,0-2.3,0C148.9,126.8,148.5,126.7,147.7,126.1z"/>
					<path class="st1511" d="M148.1,125.7c0,0.1,1.3,0.2,2,0.4c1,0.4,2,0.3,2.4-0.8c0.5-1.5,1.1-1.7,1.7-1.9
						c-2.5-0.8-3.2,0.6-4.3,1.6C149.5,125.4,149.1,125.6,148.1,125.7z"/>
				</g>
				<g>
					<path class="st1511" d="M144.2,131.3c0,0,0.8,1.1,1,1.8c0.4,1,1,1.8,2.1,1.4c1.5-0.6,2.1-0.2,2.5,0.2c-0.4-1.3-1-1.8-1.7-2.1
						c-0.7-0.3-1.5-0.2-2.3-0.3C145.3,132.2,145,132,144.2,131.3z"/>
					<path class="st1511" d="M144.7,131c0,0.1,1.3,0.4,1.9,0.7c1,0.5,1.9,0.6,2.5-0.5c0.7-1.4,1.4-1.5,1.9-1.6
						c-2.4-1.1-3.2,0.1-4.5,1C146.1,130.9,145.7,131,144.7,131z"/>
				</g>
				<g>
					<path class="st1511" d="M140,136c0,0,0.6,1.2,0.8,1.9c0.2,1.1,0.7,1.9,1.9,1.6c1.5-0.3,2.1,0.1,2.5,0.5
						c-0.2-1.3-0.8-1.9-1.4-2.3c-0.7-0.3-1.4-0.4-2.2-0.6C141.1,137,140.7,136.8,140,136z"/>
					<path class="st1511" d="M140.6,135.7c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,0.9,2.5-0.2c0.9-1.3,1.6-1.3,2.1-1.4
						c-2.2-1.4-3.2-0.3-4.6,0.4C142,135.8,141.6,135.9,140.6,135.7z"/>
				</g>
				<g>
					<path class="st1511" d="M135.3,140.1c0,0,0.4,1.3,0.5,2c0.1,1.1,0.5,2,1.7,1.9c1.6-0.1,2,0.4,2.4,0.8c0-1.3-0.5-2-1.1-2.5
						c-0.6-0.4-1.4-0.6-2.1-0.9C136.2,141.2,135.9,140.9,135.3,140.1z"/>
					<path class="st1511" d="M135.8,139.9c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.7,1.1,2.5,0.2c1-1.2,1.7-1.1,2.3-1.1
						c-2-1.7-3.2-0.8-4.6-0.2C137.2,140.2,136.8,140.2,135.8,139.9z"/>
				</g>
				<g>
					<path class="st1511" d="M130,143.5c0,0,0.3,1.3,0.2,2.1c-0.1,1.1,0.2,2,1.4,2.1c1.6,0.1,2,0.7,2.2,1.1c0.2-1.3-0.2-2.1-0.7-2.6
						c-0.5-0.5-1.3-0.8-2-1.2C130.7,144.7,130.5,144.4,130,143.5z"/>
					<path class="st1511" d="M130.6,143.4c0,0.1,1,0.8,1.5,1.4c0.7,0.9,1.5,1.3,2.5,0.5c1.2-1,1.9-0.9,2.4-0.8c-1.7-2-3-1.2-4.6-0.8
						C131.9,143.8,131.5,143.8,130.6,143.4z"/>
				</g>
				<g>
					<path class="st1511" d="M124.3,146.1c0,0,0.1,1.3-0.1,2.1c-0.2,1.1-0.1,2,1.1,2.3c1.6,0.3,1.8,0.9,2.1,1.4
						c0.3-1.3,0.1-2.1-0.4-2.7c-0.5-0.6-1.2-1-1.8-1.5C124.9,147.4,124.7,147.1,124.3,146.1z"/>
					<path class="st1511" d="M124.9,146.1c0,0.1,0.9,1,1.3,1.6c0.6,1,1.3,1.5,2.4,0.9c1.3-0.9,2-0.6,2.5-0.4
						c-1.5-2.2-2.8-1.6-4.4-1.5C126.2,146.7,125.8,146.6,124.9,146.1z"/>
				</g>
				<g>
					<path class="st1511" d="M118.4,147.9c-0.1,0-0.1,1.3-0.3,2c-0.4,1-0.4,2,0.8,2.4c1.5,0.5,1.7,1.2,1.8,1.7
						c0.5-1.2,0.4-2.1,0-2.7c-0.4-0.6-1-1.1-1.6-1.7C118.7,149.3,118.6,149,118.4,147.9z"/>
					<path class="st1511" d="M119,148c0,0,0.8,1.1,1.1,1.8c0.4,1,1.1,1.7,2.2,1.2c1.4-0.7,2-0.3,2.5-0.1c-1.1-2.4-2.6-2-4.2-2
						C120.1,148.8,119.7,148.7,119,148z"/>
				</g>
				<g>
					<path class="st1511" d="M112.2,149c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.6,1.9,0.5,2.5c1.4,0.7,1.5,1.4,1.6,1.9
						c0.7-1.2,0.6-2,0.4-2.7c-0.3-0.7-0.9-1.2-1.3-1.9C112.4,150.4,112.2,150,112.2,149z"/>
					<path class="st1511" d="M112.8,149.1c0,0,0.6,1.2,0.8,1.9c0.3,1.1,0.9,1.8,2.1,1.5c1.5-0.5,2.1-0.1,2.5,0.3
						c-0.8-2.5-2.3-2.3-3.8-2.6C113.8,150.1,113.4,149.9,112.8,149.1z"/>
				</g>
				<g>
					<path class="st1511" d="M105.9,149.1c-0.1,0-0.4,1.3-0.9,1.9c-0.6,0.9-0.9,1.8,0.1,2.5c1.3,0.9,1.3,1.6,1.3,2.1
						c0.8-1.1,0.9-1.9,0.7-2.6c-0.2-0.7-0.7-1.3-1.1-2C105.9,150.6,105.8,150.2,105.9,149.1z"/>
					<path class="st1511" d="M106.5,149.4c0,0,0.4,1.3,0.5,2c0.1,1.1,0.6,1.9,1.8,1.7c1.5-0.3,2,0.2,2.4,0.6c-0.5-2.6-2-2.6-3.5-3.1
						C107.4,150.4,107,150.2,106.5,149.4z"/>
				</g>
				<g>
					<path class="st1511" d="M99.7,148.4c-0.1,0-0.6,1.2-1.1,1.7c-0.8,0.8-1.1,1.7-0.2,2.5c1.2,1.1,1.1,1.7,1,2.3
						c1-0.9,1.2-1.7,1.1-2.5c-0.1-0.7-0.5-1.4-0.8-2.2C99.5,149.9,99.5,149.5,99.7,148.4z"/>
					<path class="st1511" d="M100.2,148.7c-0.1,0,0.3,1.3,0.3,2.1c0,1.1,0.4,2,1.6,2c1.6-0.1,2,0.5,2.3,0.9c-0.1-2.6-1.6-2.8-3-3.5
						C101,149.9,100.7,149.7,100.2,148.7z"/>
				</g>
				<g>
					<path class="st1511" d="M93.6,146.9c-0.1,0-0.8,1.1-1.3,1.6c-0.9,0.7-1.3,1.5-0.6,2.5c1,1.2,0.8,1.9,0.7,2.4
						c1.1-0.8,1.4-1.6,1.4-2.3c0-0.7-0.3-1.5-0.5-2.3C93.2,148.3,93.3,147.9,93.6,146.9z"/>
					<path class="st1511" d="M94.1,147.3c-0.1,0,0.1,1.3,0,2.1c-0.2,1.1,0.1,2,1.3,2.2c1.6,0.2,1.9,0.8,2.2,1.2
						c0.3-2.6-1.2-3-2.5-3.9C94.7,148.6,94.4,148.2,94.1,147.3z"/>
				</g>
				<g>
					<path class="st1511" d="M87.8,144.6c0,0-0.9,1-1.5,1.4c-0.9,0.6-1.5,1.3-0.9,2.4c0.8,1.3,0.6,2,0.4,2.5
						c1.2-0.6,1.6-1.4,1.7-2.1c0.1-0.7-0.1-1.5-0.2-2.3C87.2,145.9,87.3,145.5,87.8,144.6z"/>
					<path class="st1511" d="M88.2,145c-0.1,0-0.1,1.3-0.3,2c-0.3,1.1-0.2,2,1,2.3c1.5,0.4,1.8,1,2,1.5c0.6-2.6-0.8-3.2-1.9-4.2
						C88.6,146.4,88.4,146,88.2,145z"/>
				</g>
				<g>
					<path class="st1511" d="M82.4,141.5c0,0-1,0.8-1.7,1.1c-1,0.4-1.7,1.1-1.2,2.2c0.6,1.4,0.3,2,0,2.5c1.3-0.5,1.8-1.1,2-1.8
						c0.2-0.7,0.1-1.5,0.2-2.3C81.6,142.7,81.8,142.3,82.4,141.5z"/>
					<path class="st1511" d="M82.8,141.9c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.5,2,0.7,2.4c1.5,0.6,1.6,1.3,1.8,1.8c1-2.5-0.3-3.2-1.3-4.4
						C83,143.3,82.8,143,82.8,141.9z"/>
				</g>
				<g>
					<path class="st1511" d="M77.5,137.6c0,0-1.1,0.7-1.9,0.9c-1.1,0.3-1.8,0.9-1.5,2c0.4,1.5,0,2.1-0.3,2.5
						c1.3-0.3,1.9-0.9,2.2-1.6c0.3-0.7,0.3-1.5,0.5-2.3C76.5,138.7,76.7,138.4,77.5,137.6z"/>
					<path class="st1511" d="M77.7,138.2c-0.1,0-0.4,1.3-0.8,1.9c-0.6,0.9-0.7,1.9,0.3,2.5c1.4,0.8,1.4,1.5,1.5,2
						c1.3-2.3,0.1-3.2-0.7-4.6C77.7,139.6,77.6,139.2,77.7,138.2z"/>
				</g>
				<g>
					<path class="st1511" d="M73.1,133.2c0,0-1.2,0.5-2,0.6c-1.1,0.2-1.9,0.6-1.8,1.8c0.2,1.6-0.3,2-0.7,2.4c1.3-0.1,2-0.6,2.4-1.2
						c0.4-0.6,0.5-1.4,0.8-2.2C72,134.1,72.2,133.8,73.1,133.2z"/>
					<path class="st1511" d="M73.3,133.7c-0.1,0-0.6,1.2-1.1,1.8c-0.7,0.8-1,1.8,0,2.5c1.2,1,1.2,1.6,1.2,2.2
						c1.6-2.1,0.5-3.2-0.1-4.6C73.1,135.1,73,134.7,73.3,133.7z"/>
				</g>
				<g>
					<path class="st1511" d="M69.3,128.2c0,0-1.3,0.4-2,0.4c-1.1,0-2,0.3-2,1.6c0,1.6-0.5,2-1,2.3c1.3,0.1,2.1-0.3,2.5-0.9
						c0.5-0.6,0.7-1.3,1.1-2C68.1,129,68.4,128.7,69.3,128.2z"/>
					<path class="st1511" d="M69.4,128.7c-0.1,0-0.8,1.1-1.3,1.6c-0.8,0.7-1.2,1.6-0.4,2.5c1.1,1.1,1,1.8,0.9,2.3
						c1.9-1.9,1-3.1,0.5-4.6C69.1,130.1,69.1,129.7,69.4,128.7z"/>
				</g>
				<g>
					<path class="st1511" d="M66.3,122.7c0,0-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.1-2.2,1.3c-0.2,1.6-0.8,1.9-1.3,2.1
						c1.3,0.2,2.1-0.1,2.6-0.6c0.5-0.5,0.9-1.2,1.3-1.9C65,123.3,65.3,123.1,66.3,122.7z"/>
					<path class="st1511" d="M66.3,123.3c-0.1,0-0.9,1-1.5,1.4c-0.9,0.6-1.4,1.4-0.7,2.4c0.9,1.3,0.7,1.9,0.6,2.5
						c2.1-1.6,1.4-2.9,1.2-4.5C65.8,124.6,65.8,124.2,66.3,123.3z"/>
				</g>
				<g>
					<path class="st1511" d="M64,116.8c0-0.1-1.3,0-2.1-0.2c-1.1-0.3-2-0.2-2.3,1c-0.4,1.5-1,1.8-1.6,2c1.3,0.4,2.1,0.2,2.7-0.2
						c0.6-0.4,1-1.1,1.6-1.7C62.7,117.3,63,117.1,64,116.8z"/>
					<path class="st1511" d="M64,117.4c-0.1,0-1,0.8-1.7,1.2c-1,0.5-1.6,1.2-1,2.3c0.8,1.4,0.5,2,0.2,2.5c2.3-1.3,1.8-2.7,1.8-4.3
						C63.3,118.6,63.4,118.3,64,117.4z"/>
				</g>
				<g>
					<path class="st1511" d="M62.6,110.7c0-0.1-1.3-0.2-2-0.5c-1-0.4-2-0.5-2.5,0.6c-0.6,1.5-1.3,1.6-1.8,1.7c1.2,0.6,2,0.5,2.7,0.2
						c0.7-0.3,1.2-0.9,1.8-1.5C61.2,111,61.6,110.9,62.6,110.7z"/>
					<path class="st1511" d="M62.5,111.3c0,0-1.1,0.7-1.8,0.9c-1.1,0.4-1.8,1-1.3,2.2c0.6,1.5,0.2,2-0.1,2.5c2.4-1,2.1-2.5,2.3-4
						C61.6,112.4,61.8,112.1,62.5,111.3z"/>
				</g>
				<g>
					<path class="st1511" d="M62,104.5c0-0.1-1.3-0.4-1.9-0.7c-0.9-0.6-1.9-0.8-2.5,0.3c-0.8,1.4-1.5,1.4-2,1.5
						c1.1,0.8,1.9,0.8,2.6,0.5c0.7-0.2,1.3-0.8,2-1.2C60.6,104.6,61,104.5,62,104.5z"/>
					<path class="st1511" d="M61.8,105.1c0,0-1.2,0.5-2,0.7c-1.1,0.2-1.9,0.8-1.6,2c0.4,1.5-0.1,2.1-0.4,2.5
						c2.6-0.6,2.4-2.1,2.9-3.7C60.8,106,61,105.7,61.8,105.1z"/>
				</g>
				<g>
					<path class="st1511" d="M62.3,98.3c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-1-2.5,0c-1,1.2-1.7,1.2-2.2,1.2c1,0.9,1.8,1,2.5,0.9
						c0.7-0.1,1.4-0.6,2.1-0.9C60.8,98.1,61.2,98.1,62.3,98.3z"/>
					<path class="st1511" d="M62,98.8c0,0-1.3,0.4-2,0.4c-1.1,0.1-2,0.5-1.9,1.7c0.2,1.6-0.4,2-0.8,2.4c2.6-0.3,2.7-1.8,3.3-3.2
						C60.9,99.6,61.1,99.3,62,98.8z"/>
				</g>
				<g>
					<path class="st1511" d="M63.4,92.1c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.6-1.2-2.5-0.4c-1.1,1.1-1.8,1-2.4,0.9
						c0.9,1,1.7,1.3,2.4,1.2c0.7,0,1.4-0.4,2.2-0.6C62,91.8,62.4,91.8,63.4,92.1z"/>
					<path class="st1511" d="M63,92.6c0-0.1-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.2-2.1,1.4c-0.1,1.6-0.6,2-1.1,2.3
						c2.6,0.1,2.9-1.4,3.7-2.8C61.8,93.2,62.1,93,63,92.6z"/>
				</g>
				<g>
					<path class="st1511" d="M65.3,86.1c0-0.1-1-0.8-1.5-1.5c-0.6-0.9-1.4-1.4-2.4-0.7c-1.3,0.9-1.9,0.7-2.5,0.5
						c0.7,1.1,1.5,1.5,2.2,1.5c0.7,0.1,1.5-0.2,2.3-0.3C64,85.6,64.4,85.7,65.3,86.1z"/>
					<path class="st1511" d="M64.9,86.6c0-0.1-1.3,0-2.1-0.2c-1.1-0.2-2-0.1-2.3,1.2c-0.3,1.5-0.9,1.9-1.4,2.1
						c2.6,0.4,3.1-1,4.1-2.2C63.6,87.1,63.9,86.8,64.9,86.6z"/>
				</g>
				<g>
					<path class="st1511" d="M68.1,80.5c0,0-0.9-1-1.3-1.6c-0.5-1-1.2-1.6-2.3-1.1c-1.4,0.7-2,0.4-2.5,0.2c0.6,1.2,1.3,1.7,2,1.8
						c0.7,0.2,1.5,0,2.3,0C66.8,79.8,67.2,80,68.1,80.5z"/>
					<path class="st1511" d="M67.6,80.9c0-0.1-1.3-0.2-2-0.4c-1-0.4-2-0.3-2.4,0.8c-0.5,1.5-1.1,1.7-1.7,1.9
						c2.5,0.8,3.2-0.6,4.3-1.6C66.2,81.2,66.6,81,67.6,80.9z"/>
				</g>
				<g>
					<path class="st1511" d="M71.5,75.3c0,0-0.8-1.1-1-1.8c-0.4-1-1-1.8-2.1-1.4c-1.5,0.6-2.1,0.2-2.5-0.2c0.4,1.3,1,1.8,1.7,2.1
						c0.7,0.3,1.5,0.2,2.3,0.3C70.4,74.5,70.7,74.6,71.5,75.3z"/>
					<path class="st1511" d="M71,75.6c0-0.1-1.3-0.4-1.9-0.7c-1-0.5-1.9-0.6-2.5,0.5c-0.7,1.4-1.4,1.5-1.9,1.6
						c2.4,1.1,3.2-0.1,4.5-1C69.6,75.7,70,75.6,71,75.6z"/>
				</g>
				<g>
					<path class="st1511" d="M75.7,70.6c0,0-0.6-1.2-0.8-1.9c-0.2-1.1-0.7-1.9-1.9-1.6c-1.5,0.3-2.1-0.1-2.5-0.5
						c0.2,1.3,0.8,1.9,1.4,2.3c0.7,0.3,1.4,0.4,2.2,0.6C74.6,69.6,75,69.9,75.7,70.6z"/>
					<path class="st1511" d="M75.1,70.9c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-0.9-2.5,0.2c-0.9,1.3-1.6,1.3-2.1,1.4
						c2.2,1.4,3.2,0.3,4.6-0.4C73.7,70.8,74.1,70.7,75.1,70.9z"/>
				</g>
				<g>
					<path class="st1511" d="M80.4,66.5c0,0-0.5-1.3-0.5-2c-0.1-1.1-0.5-2-1.7-1.9c-1.6,0.1-2-0.4-2.4-0.8c0,1.3,0.5,2,1.1,2.5
						c0.6,0.4,1.4,0.6,2.1,0.9C79.5,65.4,79.9,65.7,80.4,66.5z"/>
					<path class="st1511" d="M79.9,66.7c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.7-1.1-2.5-0.2c-1,1.2-1.7,1.1-2.3,1.1
						c2,1.7,3.2,0.8,4.6,0.2C78.5,66.4,78.9,66.4,79.9,66.7z"/>
				</g>
				<g>
					<path class="st1511" d="M85.7,63.2c0,0-0.3-1.3-0.2-2c0.1-1.1-0.2-2-1.4-2.1c-1.6-0.1-2-0.7-2.2-1.1c-0.2,1.3,0.2,2.1,0.7,2.6
						c0.5,0.5,1.3,0.8,2,1.2C85,61.9,85.2,62.2,85.7,63.2z"/>
					<path class="st1511" d="M85.1,63.2c0-0.1-1-0.8-1.5-1.4c-0.7-0.9-1.5-1.3-2.5-0.5c-1.2,1-1.9,0.9-2.4,0.8c1.7,2,3,1.2,4.6,0.8
						C83.8,62.8,84.2,62.8,85.1,63.2z"/>
				</g>
				<g>
					<path class="st1511" d="M91.4,60.5c0,0-0.1-1.3,0.1-2.1c0.2-1.1,0.1-2-1.1-2.3c-1.6-0.3-1.8-0.9-2.1-1.4
						c-0.3,1.3-0.1,2.1,0.4,2.7c0.5,0.6,1.2,1,1.8,1.5C90.8,59.2,91,59.5,91.4,60.5z"/>
					<path class="st1511" d="M90.8,60.5c0-0.1-0.9-1-1.3-1.6c-0.6-1-1.3-1.5-2.4-0.9c-1.3,0.9-2,0.6-2.5,0.4
						c1.5,2.2,2.8,1.6,4.4,1.5C89.5,59.9,89.9,60,90.8,60.5z"/>
				</g>
				<g>
					<path class="st1511" d="M97.3,58.7c0.1,0,0.1-1.3,0.3-2c0.4-1,0.4-2-0.8-2.4c-1.5-0.5-1.7-1.2-1.8-1.7c-0.5,1.2-0.4,2.1,0,2.7
						c0.4,0.6,1,1.1,1.6,1.7C97,57.3,97.2,57.6,97.3,58.7z"/>
					<path class="st1511" d="M96.8,58.6c0,0-0.8-1.1-1.1-1.8c-0.4-1-1.1-1.7-2.2-1.2c-1.4,0.7-2,0.3-2.5,0.1c1.1,2.4,2.6,2,4.2,2
						C95.6,57.8,96,57.9,96.8,58.6z"/>
				</g>
				<g>
					<path class="st1511" d="M103.5,57.7c0.1,0,0.3-1.3,0.6-2c0.5-1,0.6-1.9-0.5-2.5c-1.4-0.7-1.5-1.4-1.6-1.9
						c-0.7,1.2-0.6,2-0.4,2.7c0.3,0.7,0.9,1.2,1.3,1.9C103.3,56.2,103.5,56.6,103.5,57.7z"/>
					<path class="st1511" d="M102.9,57.5c0,0-0.6-1.2-0.8-1.9c-0.3-1.1-0.9-1.8-2.1-1.5c-1.5,0.5-2.1,0.1-2.5-0.3
						c0.8,2.5,2.3,2.3,3.8,2.6C101.9,56.5,102.3,56.7,102.9,57.5z"/>
				</g>
				<g>
					<path class="st1511" d="M109.8,57.5c0.1,0,0.4-1.3,0.9-1.9c0.6-0.9,0.9-1.8-0.1-2.5c-1.3-0.9-1.3-1.6-1.3-2.1
						c-0.8,1.1-0.9,1.9-0.7,2.6c0.2,0.7,0.7,1.3,1.1,2C109.8,56,109.9,56.5,109.8,57.5z"/>
					<path class="st1511" d="M109.2,57.3c0,0-0.4-1.3-0.5-2c-0.1-1.1-0.6-1.9-1.8-1.7c-1.5,0.3-2-0.2-2.4-0.6c0.5,2.6,2,2.6,3.5,3.1
						C108.3,56.2,108.7,56.4,109.2,57.3z"/>
				</g>
				<g>
					<path class="st1511" d="M116,58.2c0.1,0,0.6-1.2,1.1-1.7c0.8-0.8,1.1-1.7,0.2-2.5c-1.2-1.1-1.1-1.7-1-2.3
						c-1,0.9-1.2,1.7-1.1,2.5c0.1,0.7,0.5,1.4,0.8,2.2C116.2,56.7,116.2,57.2,116,58.2z"/>
					<path class="st1511" d="M115.5,57.9c0.1,0-0.3-1.3-0.3-2.1c0-1.1-0.4-2-1.6-2c-1.6,0.1-2-0.5-2.3-0.9c0.1,2.6,1.6,2.8,3,3.5
						C114.8,56.7,115,57,115.5,57.9z"/>
				</g>
				<g>
					<path class="st1511" d="M122.1,59.7c0.1,0,0.8-1.1,1.3-1.6c0.9-0.7,1.3-1.5,0.6-2.5c-1-1.2-0.8-1.9-0.7-2.4
						c-1.1,0.8-1.4,1.6-1.4,2.3c0,0.7,0.3,1.5,0.5,2.3C122.5,58.3,122.4,58.7,122.1,59.7z"/>
					<path class="st1511" d="M121.6,59.3c0.1,0-0.1-1.3,0-2.1c0.2-1.1-0.1-2-1.3-2.2c-1.6-0.2-1.9-0.8-2.2-1.2
						c-0.3,2.6,1.2,3,2.5,3.9C121,58,121.3,58.4,121.6,59.3z"/>
				</g>
				<g>
					<path class="st1511" d="M127.9,62c0,0,0.9-1,1.5-1.4c0.9-0.6,1.5-1.3,0.9-2.4c-0.8-1.3-0.6-2-0.4-2.5c-1.2,0.6-1.6,1.4-1.7,2.1
						c-0.1,0.7,0.1,1.5,0.2,2.3C128.5,60.7,128.4,61.1,127.9,62z"/>
					<path class="st1511" d="M127.5,61.6c0.1,0,0.1-1.3,0.3-2c0.3-1.1,0.2-2-1-2.3c-1.5-0.4-1.8-1-2-1.5c-0.6,2.6,0.8,3.2,1.9,4.2
						C127.1,60.3,127.3,60.6,127.5,61.6z"/>
				</g>
				<g>
					<path class="st1511" d="M133.3,65.2c0,0,1-0.8,1.7-1.1c1-0.4,1.7-1.1,1.2-2.2c-0.6-1.4-0.3-2,0-2.5c-1.3,0.5-1.8,1.1-2,1.8
						c-0.2,0.7-0.1,1.5-0.2,2.3C134.1,63.9,133.9,64.3,133.3,65.2z"/>
					<path class="st1511" d="M133,64.7c0.1,0,0.3-1.3,0.6-2c0.5-1,0.5-2-0.7-2.4c-1.5-0.6-1.6-1.3-1.8-1.8c-1,2.5,0.3,3.2,1.3,4.4
						C132.8,63.3,132.9,63.7,133,64.7z"/>
				</g>
				<g>
					<path class="st1511" d="M138.3,69c0,0,1.1-0.7,1.9-0.9c1.1-0.3,1.8-0.9,1.5-2c-0.4-1.5,0-2.1,0.3-2.5c-1.3,0.3-1.9,0.9-2.2,1.6
						c-0.3,0.7-0.3,1.5-0.5,2.3C139.2,67.9,139,68.2,138.3,69z"/>
					<path class="st1511" d="M138,68.5c0.1,0,0.4-1.3,0.8-1.9c0.6-0.9,0.7-1.9-0.3-2.5c-1.4-0.8-1.4-1.5-1.5-2
						c-1.3,2.3-0.1,3.2,0.7,4.6C138,67,138.1,67.4,138,68.5z"/>
				</g>
				<g>
					<path class="st1511" d="M142.7,73.4c0,0,1.2-0.5,2-0.6c1.1-0.2,1.9-0.6,1.8-1.8c-0.2-1.6,0.3-2,0.7-2.4c-1.3,0.1-2,0.6-2.4,1.2
						c-0.4,0.6-0.5,1.4-0.8,2.2C143.7,72.5,143.5,72.8,142.7,73.4z"/>
					<path class="st1511" d="M142.5,72.9c0.1,0,0.6-1.2,1.1-1.8c0.7-0.8,1-1.8,0-2.5c-1.2-1-1.2-1.6-1.2-2.2
						c-1.6,2.1-0.5,3.2,0.1,4.6C142.6,71.5,142.7,71.9,142.5,72.9z"/>
				</g>
				<g>
					<path class="st1511" d="M146.4,78.5c0,0,1.3-0.4,2-0.4c1.1,0,2-0.3,2-1.6c0-1.6,0.5-2,1-2.3c-1.3-0.1-2.1,0.3-2.5,0.9
						c-0.5,0.6-0.7,1.3-1.1,2C147.6,77.6,147.3,77.9,146.4,78.5z"/>
					<path class="st1511" d="M146.3,77.9c0.1,0,0.8-1.1,1.3-1.6c0.8-0.7,1.2-1.6,0.4-2.5c-1.1-1.1-1-1.8-0.9-2.3
						c-1.9,1.9-1,3.1-0.5,4.6C146.6,76.5,146.6,76.9,146.3,77.9z"/>
				</g>
				<g>
					<path class="st1511" d="M149.4,83.9c0,0,1.3-0.2,2.1-0.1c1.1,0.1,2-0.1,2.2-1.3c0.2-1.6,0.8-1.9,1.3-2.1
						c-1.3-0.2-2.1,0.1-2.6,0.6c-0.5,0.5-0.9,1.2-1.3,1.9C150.7,83.3,150.4,83.5,149.4,83.9z"/>
					<path class="st1511" d="M149.4,83.3c0.1,0,0.9-1,1.5-1.4c0.9-0.6,1.4-1.4,0.7-2.4c-0.9-1.3-0.7-1.9-0.6-2.5
						c-2.1,1.6-1.4,2.9-1.2,4.5C149.9,82,149.9,82.4,149.4,83.3z"/>
				</g>
				<g>
					<path class="st1511" d="M151.7,89.8c0,0.1,1.3,0,2.1,0.2c1.1,0.3,2,0.2,2.3-1c0.4-1.5,1-1.8,1.6-2c-1.3-0.4-2.1-0.2-2.7,0.2
						c-0.6,0.4-1,1.1-1.6,1.7C153.1,89.3,152.7,89.5,151.7,89.8z"/>
					<path class="st1511" d="M151.7,89.2c0.1,0,1-0.8,1.7-1.2c1-0.5,1.6-1.2,1-2.3c-0.8-1.4-0.5-2-0.2-2.5c-2.3,1.3-1.8,2.7-1.8,4.3
						C152.4,88,152.3,88.4,151.7,89.2z"/>
				</g>
				<g>
					<path class="st1511" d="M153.1,95.9c0,0.1,1.3,0.2,2,0.5c1,0.4,2,0.5,2.5-0.6c0.6-1.5,1.3-1.6,1.8-1.7c-1.2-0.6-2-0.5-2.7-0.2
						c-0.7,0.3-1.2,0.9-1.8,1.5C154.5,95.6,154.1,95.7,153.1,95.9z"/>
					<path class="st1511" d="M153.2,95.3c0,0,1.1-0.7,1.8-0.9c1.1-0.4,1.8-1,1.3-2.2c-0.6-1.5-0.2-2,0.1-2.5c-2.4,1-2.1,2.5-2.3,4
						C154.1,94.2,153.9,94.6,153.2,95.3z"/>
				</g>
				<g>
					<path class="st1511" d="M153.7,102.1c0,0.1,1.3,0.4,1.9,0.7c0.9,0.6,1.9,0.8,2.5-0.3c0.8-1.4,1.5-1.4,2-1.5
						c-1.1-0.8-1.9-0.8-2.6-0.5c-0.7,0.2-1.3,0.8-2,1.2C155.1,102,154.7,102.1,153.7,102.1z"/>
					<path class="st1511" d="M153.9,101.5c0,0,1.2-0.5,2-0.7c1.1-0.2,1.9-0.8,1.6-2c-0.4-1.5,0.1-2.1,0.4-2.5
						c-2.6,0.6-2.4,2.1-2.9,3.7C154.9,100.6,154.7,100.9,153.9,101.5z"/>
				</g>
			</g>
		</g>
		<g>
			<path d="M62.7,100.2l0.1-1l4.5,0.4l-0.1,1L62.7,100.2z"/>
			<path d="M63.6,93.7l4.4,0.8l-0.1,0.8L64.8,97l2.7,0.5l-0.2,1l-4.4-0.8l0.1-0.9l3.1-1.7l-2.7-0.5L63.6,93.7z"/>
			<path d="M65.1,91.6L64.7,93l-0.8-0.2l1.1-3.8l0.8,0.2l-0.4,1.4l3.5,1l-0.3,1L65.1,91.6z"/>
			<path d="M70,86.2l0.8,0.3l-1.3,3.2l-4.2-1.7l1.3-3.2l0.8,0.3l-0.9,2.2l0.9,0.4l0.8-1.9l0.8,0.3l-0.8,1.9l1,0.4L70,86.2z"/>
			<path d="M72.4,83.4l-1.5,0.2l0,0l-0.4,0.8l1.1,0.6L71,85.9l-4-2.1L68,82c0.2-0.4,0.4-0.6,0.7-0.8s0.5-0.3,0.8-0.3
				c0.3,0,0.6,0,0.9,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.3,0.2,0.5,0.2,0.9l1.8-0.2L72.4,83.4z M69.2,82c-0.2,0.1-0.4,0.3-0.5,0.5
				l-0.4,0.7l1.4,0.7l0.4-0.8c0.1-0.3,0.2-0.5,0.2-0.7s-0.2-0.4-0.4-0.5C69.6,81.9,69.4,81.9,69.2,82z"/>
			<path d="M71.9,75.9l3.7,2.6l-0.5,0.7l-3.5,0.3l2.2,1.6l-0.6,0.8l-3.7-2.6l0.5-0.7l3.5-0.3l-2.2-1.6L71.9,75.9z"/>
			<path d="M77.5,74.9l-1.4,1.6l0.5,0.9l-0.7,0.8l-2-4.5l0.7-0.8l4.7,1.5l-0.7,0.8L77.5,74.9z M76.7,74.6l-1.8-0.6l0.8,1.7
				L76.7,74.6z"/>
			<path d="M77.6,71l-1.1,1l-0.6-0.6l2.9-2.6l0.6,0.6l-1.1,1l2.5,2.7L80,73.7L77.6,71z"/>
			<path d="M79.5,68.2l0.8-0.6l2.8,3.5l-0.8,0.6L79.5,68.2z"/>
			<path d="M84.5,69.8c-0.4,0-0.8,0-1.2-0.2c-0.4-0.2-0.7-0.5-0.9-0.8C82.1,68.4,82,68,82,67.6c0-0.4,0.1-0.8,0.3-1.2
				c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.3,0.8-0.4,1.2-0.4c0.4,0,0.8,0,1.2,0.2c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.4,0.4,0.8,0.4,1.2
				c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.4-0.5,0.7-0.9,0.9C85.3,69.6,84.9,69.8,84.5,69.8z M85.7,68.1c0.1-0.2,0.2-0.5,0.1-0.7
				c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				C83,67,83,67.2,83,67.4c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.5-0.1,0.7-0.2
				C85.5,68.5,85.6,68.3,85.7,68.1z"/>
			<path d="M90.1,61.8l2,4.1l-0.8,0.4l-3.2-1.5l1.2,2.5l-0.9,0.4l-2-4.1l0.8-0.4l3.2,1.5l-1.2-2.5L90.1,61.8z"/>
			<path d="M95.4,63.6l-2,0.7l-0.1,1l-1,0.3l0.5-4.9l1-0.3l3.4,3.6l-1,0.4L95.4,63.6z M94.9,63l-1.2-1.4l-0.1,1.9L94.9,63z"/>
			<path d="M96.6,59.6l1-0.2l0.8,3.6l2.2-0.5l0.2,0.8L97.6,64L96.6,59.6z"/>
			<path d="M105.9,61.8l-2.1,0.1l-0.3,1l-1.1,0.1l1.7-4.6l1-0.1l2.3,4.4l-1.1,0.1L105.9,61.8z M105.5,61l-0.8-1.7l-0.6,1.8L105.5,61
				z"/>
			<path d="M107.9,58.2l1,0l-0.1,4.5l-1,0L107.9,58.2z"/>
			<path d="M112.3,58.4l2,0.3c0.5,0.1,0.9,0.2,1.2,0.5c0.3,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.2,0.8,0.1,1.2c-0.1,0.4-0.2,0.8-0.5,1.1
				c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.1-0.8,0.2-1.3,0.1l-2-0.3L112.3,58.4z M113.8,62.3c0.4,0.1,0.8,0,1.1-0.2c0.3-0.2,0.5-0.5,0.5-1
				c0.1-0.4,0-0.8-0.3-1.1c-0.2-0.3-0.6-0.5-1-0.5l-0.9-0.1l-0.4,2.8L113.8,62.3z"/>
			<path d="M119.6,64.5l-0.5-1.4l0,0l-0.9-0.2l-0.3,1.2l-1-0.3l1.2-4.4l1.9,0.5c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.4,0.4,0.5,0.7
				c0.1,0.3,0.1,0.6,0,0.9s-0.2,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.4l0.6,1.7L119.6,64.5z M120.2,61c-0.1-0.2-0.3-0.3-0.6-0.4
				l-0.8-0.2l-0.4,1.5l0.8,0.2c0.3,0.1,0.6,0.1,0.7,0c0.2-0.1,0.3-0.3,0.4-0.5C120.4,61.4,120.3,61.2,120.2,61z"/>
			<path d="M122.8,60.8l1,0.4l-1.5,4.2l-1-0.4L122.8,60.8z"/>
			<path d="M129,63.4l-3.7,3.3l-0.9-0.4l0.1-4.9l1,0.5l-0.1,3.5L128,63L129,63.4z"/>
			<path d="M129.5,63.8l0.9,0.5l-2.2,3.9l-0.9-0.5L129.5,63.8z"/>
			<path d="M135.1,67.3l-2.5,3.7l-0.7-0.5l-0.3-3.5l-1.5,2.3l-0.9-0.6l2.5-3.7l0.7,0.5l0.3,3.5l1.5-2.3L135.1,67.3z"/>
			<path d="M137.1,71.9l0.7,0.6l-1.2,1.4c-0.3,0-0.6-0.1-0.9-0.2s-0.6-0.3-0.8-0.5c-0.4-0.3-0.6-0.7-0.8-1.1
				c-0.1-0.4-0.2-0.8-0.1-1.2s0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.2,0.6
				c0.3,0.3,0.5,0.5,0.7,0.9c0.2,0.3,0.2,0.6,0.2,1l-0.9,0c0-0.5-0.2-0.9-0.5-1.2c-0.2-0.2-0.4-0.3-0.7-0.4c-0.2-0.1-0.5,0-0.7,0
				s-0.5,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.2,0.5,0.3,0.7,0.4L137.1,71.9
				z"/>
			<path d="M141.5,73.3l0.7,0.8l-2.8,2.4l1.5,1.7l-0.7,0.5l-2.1-2.5L141.5,73.3z"/>
			<path d="M144.1,76.5l0.6,0.8l-3.6,2.7l-0.6-0.8L144.1,76.5z"/>
			<path d="M142.3,81.4c0-0.4,0.1-0.8,0.3-1.2s0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.4,1.2-0.4c0.4,0,0.8,0.1,1.2,0.3
				c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.3,0.3,0.7,0.4,1c0,0.3,0,0.7-0.1,1l-0.9-0.3c0.1-0.4,0.1-0.9-0.1-1.2c-0.1-0.2-0.3-0.4-0.5-0.5
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.4,0.6,0.6,1,0.7l-0.2,0.9c-0.3,0-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.5-0.7-0.8C142.5,82.2,142.4,81.8,142.3,81.4z"/>
			<path d="M146.3,87.5l-0.8,0.4l-1.5-3.2l4.1-1.9l1.4,3.1l-0.8,0.4l-1-2.1l-0.9,0.4l0.9,1.9l-0.7,0.3l-0.9-1.9l-0.9,0.4L146.3,87.5
				z"/>
			<path d="M151.4,91l-4.3,1.4l-0.3-0.8l1.9-3l-2.6,0.9l-0.3-1l4.3-1.4l0.3,0.8l-1.9,3l2.6-0.9L151.4,91z"/>
			<path d="M147.5,93.8c0-0.3,0.1-0.6,0.2-0.9l0.8,0.2c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0,0.5,0,0.8c0.1,0.3,0.2,0.5,0.3,0.6
				c0.1,0.1,0.2,0.2,0.4,0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.6c0-0.4,0-0.6,0.1-0.9
				c0-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5,0,0.7,0.1c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.3,0.3,0.6,0.4,1
				c0.1,0.3,0.1,0.6,0.1,0.8s-0.1,0.5-0.1,0.8l-0.8-0.1c0.1-0.5,0.2-0.9,0.1-1.3c-0.1-0.3-0.2-0.5-0.3-0.6c-0.1-0.1-0.3-0.2-0.4-0.1
				c-0.2,0-0.2,0.1-0.3,0.3c0,0.2-0.1,0.4-0.1,0.8c0,0.4,0,0.6-0.1,0.9c0,0.2-0.1,0.4-0.3,0.6c-0.1,0.2-0.4,0.3-0.7,0.4
				c-0.3,0.1-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.3-0.3-0.6-0.4-1C147.5,94.5,147.5,94.2,147.5,93.8z"/>
			<path d="M149.4,100.7l-0.8,0.1l-0.4-3.5l4.5-0.5l0.4,3.4l-0.8,0.1l-0.3-2.4l-1,0.1l0.2,2.1l-0.8,0.1l-0.2-2.1l-1,0.1L149.4,100.7
				z"/>
		</g>
		<g>
			<path d="M79.5,88.9h3.3v14.4h-3.3V88.9z"/>
			<path d="M95.7,100.2H89l-1.3,3.1h-3.4l6.4-14.4H94l6.4,14.4H97L95.7,100.2z M94.7,97.7l-2.3-5.5l-2.3,5.5H94.7z"/>
			<path d="M102,88.9h3.3v14.4H102V88.9z"/>
			<path d="M108.7,88.9h6.5c1.6,0,2.9,0.3,4.1,0.9c1.2,0.6,2.1,1.4,2.8,2.5c0.7,1.1,1,2.3,1,3.8c0,1.4-0.3,2.7-1,3.8
				c-0.7,1.1-1.6,1.9-2.8,2.5c-1.2,0.6-2.6,0.9-4.1,0.9h-6.5V88.9z M115.1,100.6c1.4,0,2.6-0.4,3.4-1.2c0.9-0.8,1.3-1.9,1.3-3.3
				s-0.4-2.5-1.3-3.3c-0.9-0.8-2-1.2-3.4-1.2h-3v8.9H115.1z"/>
			<path d="M125.7,88.9h3.3v11.7h7.2v2.7h-10.5V88.9z"/>
		</g>
		<polygon points="107.9,68.3 109.8,72.3 114.3,72.9 111.1,76.1 111.8,80.5 107.9,78.4 103.9,80.5 104.6,76.1 101.4,72.9 
			105.9,72.3 		"/>
		<g>
			<g>
				<polygon points="118.5,76.9 119.8,79.4 122.5,79.8 120.5,81.7 121,84.4 118.5,83.1 116.1,84.4 116.6,81.7 114.6,79.8 
					117.3,79.4 				"/>
				<polygon points="97.2,76.9 98.4,79.4 101.1,79.8 99.1,81.7 99.6,84.4 97.2,83.1 94.7,84.4 95.2,81.7 93.2,79.8 95.9,79.4 				
					"/>
			</g>
		</g>
		<text transform="matrix(1 0 0 1 73.1289 134.6962)" class="st1512 st1513">{{ token }}</text>
		<g>
			<path d="M82.4,120.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L82.4,120.7z M82.1,119.9l-0.5-1.3l-0.5,1.3H82.1z"/>
			<path d="M85.1,121.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
				c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
				c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6s-0.6,0.2-1,0.2C85.8,121.6,85.4,121.5,85.1,121.3z"/>
			<path d="M89.3,121.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
				c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
				c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6s-0.6,0.2-1,0.2C90,121.6,89.7,121.5,89.3,121.3z"/>
			<path d="M94.5,120.4h-0.6v1.1h-1.2v-4.1h1.9c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.6,0.5s0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8
				s-0.3,0.4-0.6,0.5l0.9,1.3h-1.2L94.5,120.4z M95.1,118.5c-0.1-0.1-0.3-0.2-0.5-0.2h-0.6v1.2h0.6c0.2,0,0.4-0.1,0.5-0.2
				c0.1-0.1,0.2-0.3,0.2-0.4S95.2,118.6,95.1,118.5z"/>
			<path d="M100.3,120.6v0.9h-3.3v-4.1h3.2v0.9h-2.1v0.7h1.8v0.9h-1.8v0.7H100.3z"/>
			<path d="M101,117.4h1.9c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.7s0.3,0.7,0.3,1.1s-0.1,0.8-0.3,1.1s-0.5,0.5-0.8,0.7
				c-0.3,0.2-0.7,0.3-1.2,0.3H101V117.4z M102.9,120.6c0.4,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				c-0.2-0.2-0.5-0.3-0.8-0.3h-0.7v2.2H102.9z"/>
			<path d="M105.8,117.4h1.2v4.1h-1.2V117.4z"/>
			<path d="M108.6,118.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V118.3z"/>
			<path d="M114,120.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L114,120.7z M113.7,119.9l-0.5-1.3l-0.5,1.3H113.7z"/>
			<path d="M116.5,118.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V118.3z"/>
			<path d="M119.4,117.4h1.2v4.1h-1.2V117.4z"/>
			<path d="M122.2,121.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3
				s0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1s-0.1,0.8-0.3,1.1s-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.2,0.3
				S122.6,121.5,122.2,121.3z M123.9,120.5c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6
				s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1S123.8,120.6,123.9,120.5z"/>
			<path d="M130.1,117.4v4.1h-1l-1.8-2.2v2.2h-1.1v-4.1h1l1.8,2.2v-2.2H130.1z"/>
			<path d="M135.6,119.1l-0.1,0.8h0.7v0.8h-0.8l-0.1,0.9h-0.8l0.1-0.9h-0.7l-0.1,0.9H133l0.1-0.9h-0.7v-0.8h0.8l0.1-0.8h-0.7v-0.8
				h0.8l0.1-0.9h0.8l-0.1,0.9h0.7l0.1-0.9h0.8l-0.1,0.9h0.7v0.8H135.6z M134.8,119.1h-0.7l-0.1,0.8h0.7L134.8,119.1z"/>
		</g>
	</g>
    <g>
      <path class="st25" d="M242.8,709.6c-1.9,0.8-5.2,1.8-6.8,0c-2.7-3.1,5.1-6.5,6.9-7.2c3.2-1.3,6.6-2.2,9.8-3.2
        c3-0.9,6.1-1.8,9.2-2.6c1-0.3,2-0.5,3-0.7c0.2,0,0.5-0.1,0.7-0.1c-0.5,0-0.1-0.4-0.3-0.1c-0.2,0.1-0.3,0.3-0.5,0.4
        c-2.3,2-4.5,4-6.5,6.2c-5.4,5.6-10.2,11.9-15.5,17.6c-2.9,3.1-6,6.1-9.1,9c-2.6,2.3-5.3,4.7-8.4,6.4c-0.4,0.2-1.6,1-2.1,0.5
        c-0.5-0.4,0.4-1.7,0.6-2c2-3.2,5.3-5.6,8.2-7.9c10.7-8.4,22.1-15.4,33.6-22.7c4.7-3,9.5-6,14-9.3c0.6-0.4,2.5-1.5,2.6-2.4
        c0-0.9-2.2,0.5-2.4,0.7c-3.9,2.6-7.7,5.6-11.3,8.6c-2.1,1.7-3.9,3.8-5.8,5.8c-4.8,5-9.4,10.1-14.1,15.3c-3.8,4.2-7.7,8.4-11.1,12.9
        c-0.3,0.4,0.9,0.2,1.1,0c3.2-3.6,6.4-7,9.8-10.4c2.5-2.5,5-4.9,7.7-7.2c3.1-2.6,6.4-5.2,10-7.1c0.2-0.1,0.4-0.2,0.6-0.3
        c-0.3,0.1-0.2-0.2-0.3,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,0.8-1.1,1.6-1.6,2.4c-1.7,2.3-3.5,4.6-5.3,6.8c-1.9,2.3-3.8,4.5-5.8,6.7
        c-0.9,0.9-1.8,1.8-2.7,2.7c-0.3,0.3-1.8,1.4-1.6,2.1c0.3,1.1,3.4-1.5,3.6-1.7c1.4-1.3,2.9-2.3,4.4-3.4c6-4.4,12.3-8.4,18.9-11.9
        c3.2-1.7,6.8-3.6,10.4-4.1c0.1,0,1.2-0.1,1,0.3c-0.4,0.6-1.4,0.6-2,0.7c-4.2,0.3-1.1-6.3,0-7.7c1.6-2,3.7-3.6,5.6-5.2
        c1.9-1.5,3.8-3,5.9-4.4c0.4-0.3,3.7-2.5,3.8-1.9c0.2,0.7-0.5,1.7-0.8,2.3c-0.6,1.3-1.5,2.5-2.3,3.7c-1.8,2.7-3.9,5.2-6.1,7.6
        c-3.7,4-6.8,8.4-10.2,12.7c-3.8,4.9-7.8,10.2-13.1,13.6c0.3,0,0.7,0,1,0.1c2.5-4.5,8.1-7.4,12.5-10c5.2-3.1,10.3-6.3,15.2-9.9
        c1-0.7,8-5.4,6.2-7.3c-0.9-1-2.9-0.1-3.8,0.4c-2.3,1.1-4.3,3-6.2,4.8c-4,3.8-8.2,8.2-10.7,13.1c-0.6,1.1-1.5,3.2-0.5,4.4
        c1,1.1,3.2,0,4.2-0.5c3.2-1.7,5.9-4.4,8.5-6.9c2.8-2.6,5.7-5.2,8.5-7.8c4.5-4.1,8.9-8.2,13.4-12.2c0.3-0.2,0.9-0.9,1-0.9
        c-0.3,0-0.6,0.1-0.9,0.1c-0.3-0.1,0-0.3-0.3,0c-0.2,0.2-0.5,0.5-0.7,0.7c-0.8,0.9-1.7,1.7-2.5,2.5c-2.1,2.1-4.1,4.1-6.2,6.2
        c-3.1,3.1-5.9,6.5-8.6,9.9c-3,3.7-6.3,7.5-8.5,11.7c-0.3,0.7-0.6,1.6,0.5,1.3c0.9-0.3,1.7-1.1,2.4-1.7
        c11.2-9.9,20.5-21.9,32.4-31.1c-0.4,0-0.7,0-1.1-0.1c-3.7,4.4-7.8,8.4-11.6,12.7c-4.5,5.2-9,10.4-13.5,15.6
        c-2.1,2.3-4.1,4.6-6.3,6.8c-1.6,1.6-3.2,3.1-5.1,4.3c-1.4,0.9-3.4,2-5,1.3c-2.3-0.9-0.6-4.8,0.1-6.3c1.2-2.6,3.1-4.9,5.2-6.9
        c6.2-5.8,14.4-7.7,22.5-9c5.4-0.9,10.8-1,16.3-0.5c0.3,0,1.4-0.5,0.8-0.6c-5.3-0.5-10.6-0.4-15.9,0.4c-7.8,1.1-15.7,2.9-22.2,7.6
        c-4.1,3-7.9,7.4-9,12.4c-0.4,2.1,0.5,3.7,2.7,3.7c2.9,0.1,5.8-2,7.9-3.7c3.3-2.7,6.1-6.1,8.9-9.2c3.2-3.6,6.4-7.3,9.6-11
        c5.2-6,10.7-11.6,15.8-17.6c0.4-0.4-0.9-0.2-1.1-0.1c-4.2,3.2-8,7-11.8,10.7c-4.5,4.4-8.9,8.9-13.3,13.3c-2.8,2.8-5.5,5.9-8.7,8.3
        c-0.5,0.3,0-0.2,0.3,0.1c-0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.7,0.7-1.4,1.1-2c1.3-2.1,2.8-4.1,4.3-6c3.2-4.3,6.5-8.6,10.3-12.5
        c2.2-2.3,4.5-4.5,6.8-6.8c1-1,2-2,3-3c0.4-0.5,1.1-0.9,1.4-1.5c0.1-0.1,0.1-0.2-0.1-0.3l0,0c-0.2-0.1-0.7,0-0.9,0.1
        c-1,0.6-1.9,1.6-2.8,2.4c-2.2,2-4.4,4-6.6,6c-5.6,5.1-11.2,10.3-16.8,15.4c-1.5,1.3-3.1,2.6-4.8,3.7c-0.7,0.4-2.1,1.4-2.9,0.7
        c-1.3-1.1,0.7-4.4,1.3-5.4c3-5.1,7.5-9.7,11.9-13.5c1.3-1.1,3.2-3,5-3.1c1.6-0.1,0.5,1.7,0,2.2c-2.9,3.5-7.4,6.1-11.1,8.6
        c-4.2,2.7-8.4,5.3-12.7,7.8c-3.5,2-7.5,4.8-9.5,8.5c-0.2,0.3,0.9,0.1,1,0.1c5.6-3.7,9.9-9.4,14-14.7c2.1-2.7,4.1-5.4,6.2-8
        c1.8-2.3,3.9-4.4,5.8-6.6c2.1-2.6,4.2-5.3,5.8-8.3c0.3-0.6,2-3.4,0.4-3.5c-1.5-0.1-3.1,1.1-4.3,1.8c-2.3,1.5-4.6,3.2-6.7,4.9
        c-3.4,2.7-9.2,6.9-8.2,12.1c0.4,2.2,3.1,2,4.7,1.5c0.3-0.1,2.4-0.9,1.7-1.6c-0.9-0.9-3.5,0-4.4,0.3c-3.5,1-6.9,2.7-10.2,4.5
        c-6.3,3.4-12.4,7.4-18.2,11.6c-1.5,1.1-3,2.1-4.4,3.4c-0.4,0.4-0.8,0.7-1.3,1c-0.6,0.4,0.1,0,0,0.3c0-0.2,0.2-0.4,0.4-0.5
        c0.9-1.1,2.1-2,3.1-3.1c4.1-4.2,7.7-8.8,11.3-13.4c0.9-1.2,2.2-2.6,2.8-4.1c0.4-1.1-2.7,0.3-2.8,0.4c-6.3,3.5-11.9,8.6-17,13.7
        c-3.6,3.6-7,7.3-10.4,11c0.4,0,0.8,0,1.1,0c2.6-3.5,5.6-6.7,8.5-10c4-4.5,8-8.9,12.1-13.3c2.8-3,5.6-6.1,8.5-9
        c2.6-2.6,5.8-4.8,8.8-7c1.6-1.2,3.3-2.5,5-3.6c0.4-0.2-0.4-0.3-0.6,0c-0.1,0.2-0.3,0.3-0.5,0.5c-0.7,0.6-1.4,1.1-2.2,1.7
        c-4.7,3.4-9.6,6.5-14.5,9.6c-11.5,7.3-23,14.4-33.6,22.9c-2.7,2.1-5.5,4.4-7.4,7.3c-0.4,0.6-1.5,2.4-0.1,2.7c1.7,0.3,3.6-1,4.9-1.8
        c3-1.9,5.7-4.3,8.3-6.7c5.9-5.4,11.3-11.3,16.5-17.4c2.5-3,5-6,7.7-8.8c2-2.1,4.2-4,6.4-6c0.6-0.6,0.5-0.8-0.4-0.7
        c-1.5,0.2-3.1,0.7-4.6,1.1c-5,1.4-10,2.8-15,4.4c-2.9,0.9-5.8,2.1-8.4,3.7c-1.5,1-3.6,2.6-3.4,4.6
        C234.4,712.4,240.9,710.5,242.8,709.6C244.1,709.7,243.4,709.4,242.8,709.6L242.8,709.6"/>
      <path class="st25" d="M312.9,717.8c4.5-1,9-2.4,13.1-4.6c2.5-1.4,4.8-3.3,6.4-5.7c1.2-1.9-1.2-1.4-2.2-0.9c-3.1,1.3-5.9,3.7-8.4,6
        c-3,2.7-5.7,5.8-8.1,9.1c-1.5,2.1-3.6,4.8-3.9,7.5c-0.2,1.7,1.7,1.2,2.7,0.8c2.2-1,4.1-2.7,5.9-4.4c2.3-2.1,4.5-4.3,6.7-6.4
        c4.2-3.9,8.6-7.8,13-11.5c0.6-0.5,1.2-1,1.8-1.5c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.1,0.1-0.2-0.5-0.1c0,0.1-0.2,0.4-0.2,0.4
        c-1.6,2.8-4.5,5.3-6.8,7.5c-5.3,5.2-10.1,10.9-14.5,16.9c-0.3,0.4,0.9,0.1,1,0c2.3-1.7,4.3-3.7,6.1-5.9c1.9-2.4,4.2-4.5,6.4-6.6
        c5.8-5.5,12.2-10,19.1-14c0.3-0.2,0.7-0.4,1-0.5c-0.7,0.3-0.3,0-0.8,0.6c-0.9,1.3-2,2.5-3.1,3.7c-2.7,3-5.3,6-7.8,9.1
        c-3.3,4-7,7.8-11.4,10.7c-0.3,0.2-0.7,0.7-0.8,0.2c-0.1-0.3,0.2-0.7,0.4-1c0.8-1.1,2-1.9,3.2-2.6c3.4-2.2,7.2-3.6,10.9-5
        c5.9-2.3,12-4.7,17.5-7.9c0.9-0.5,3-1.5,3-2.8c0.1-1.8-3.6-0.4-4.2-0.1c-6.7,3-12,8.9-16.6,14.4c-1.7,2-3.5,4.1-4.7,6.5
        c-0.5,1-0.1,1.6,1,1.2c1-0.4,1.9-1.2,2.7-1.9c1.6-1.3,3.1-2.7,4.6-4.1c4-3.7,7.9-7.4,11.9-11.1c2.8-2.6,5.6-5.5,8.7-7.7
        c0.5-0.4-0.2-0.2-0.3,0c-0.2,0.5-0.6,0.9-0.9,1.3c-1.3,1.7-2.8,3.3-4.2,4.9c-4.7,5.4-9.2,10.8-13.4,16.6c-0.3,0.4,0.9,0.1,1.1-0.1
        c2.2-2,4.2-4.2,6.2-6.2c3.1-3.2,6-6.5,9.4-9.3c0.9-0.7,1.8-1.4,2.7-1.9c0.3-0.2,0.6-0.2,0.4,0.2c-0.1,0.5-0.5,0.8-0.9,1.2
        c-0.9,0.9-1.8,1.7-2.7,2.5c-2.1,2-4.3,4.1-5.9,6.5c-0.8,1.2-1.8,2.9,0.2,3.2c2.3,0.4,5.1-0.3,7.3-0.8c3.6-0.8,7.1-1.8,10.6-3.2
        c0.8-0.3,0-0.5-0.4-0.3c-3.4,1.3-6.9,2.3-10.5,3.1c-1,0.2-6.6,1.7-7,0c-0.2-0.9,0.9-2.1,1.3-2.7c0.9-1.3,2-2.4,3.1-3.5
        c1.7-1.8,3.7-3.4,5.4-5.2c0.3-0.4,1.2-1.4,0.3-1.7c-1.1-0.4-2.7,0.8-3.5,1.3c-3.1,2.1-5.7,5-8.3,7.7c-2.2,2.3-4.5,4.6-6.7,6.9
        c-0.7,0.7-1.5,1.5-2.2,2.2c0.4,0,0.8,0,1.1-0.1c4.9-6.8,10.3-13.1,15.8-19.4c0.8-0.9,2.6-2.4,2.8-3.7c0.1-0.7-1.7,0.1-1.9,0.2
        c-3,1.9-5.6,4.8-8.3,7.2c-4,3.7-7.9,7.5-11.9,11.1c-1.6,1.5-3.2,2.9-4.9,4.3c-0.5,0.5-1.1,0.9-1.7,1.3c-0.2,0.1-0.4,0.3-0.6,0.4
        c-0.4,0.3,0.3,0.1,0.2-0.1c-0.1-0.6,0.7-1.6,1-2.1c0.8-1.4,1.8-2.6,2.8-3.9c2.2-2.8,4.6-5.4,7.1-7.9c3-3,6.4-5.9,10.3-7.6
        c0.5-0.2,2.5-0.6,1.9,0.6c-0.4,0.7-1.4,1.3-2.1,1.7c-5.5,3.5-11.9,5.7-17.9,8.1c-3.4,1.3-6.8,2.6-10,4.5c-1.6,0.9-4.4,2.4-4.9,4.4
        c-0.4,1.6,2.3,0.3,2.8,0c2.4-1.5,4.6-3.5,6.7-5.4c4.3-4,7.8-8.9,11.7-13.3c1.2-1.4,2.5-2.8,3.7-4.2c0.3-0.4,1-1.1,1-1.6
        c0-0.8-3.2,1.1-3.4,1.1c-3.1,1.8-6.2,3.7-9.1,5.7c-3.3,2.3-6.3,5-9.3,7.7c-2.2,2.1-4.4,4.2-6.4,6.5c-1.9,2.2-3.9,4.4-6.3,6.2
        c0.3,0,0.7,0,1,0c2.9-3.9,5.9-7.7,9.1-11.3c2.9-3.2,6.1-6.1,9-9.3c1.1-1.2,2.2-2.4,3.1-3.7c0.2-0.3,0.7-1-0.1-1
        c-1.4,0.1-2.7,1.6-3.7,2.4c-5.3,4.4-10.5,9-15.5,13.8c-2.9,2.7-5.5,5.7-8.9,7.8c-3.3,2.1-0.8-3-0.2-4.1c2.4-4.3,5.8-8.1,9.2-11.5
        c1.7-1.7,3.6-3.3,5.6-4.7c1.1-0.8,2.2-1.5,3.4-2c0.9-0.4,0.5,0.4,0.3,0.9c-2.2,3.8-6.6,5.9-10.5,7.4c-2.4,0.9-5.3,1.8-7.6,2.3
        C312.6,717.4,312,718,312.9,717.8L312.9,717.8"/>
    </g>
    <rect x="246.9" y="616.5" class="st7" width="1.5" height="52.9"/>
    <rect x="363.7" y="616.5" class="st7" width="1.5" height="52.9"/>
    <g>
      <g>
        <path class="st26" d="M557.4,91.8H440.8c-1.7,0-3-1.4-3-3V46.2c0-1.7,1.4-3,3-3h116.5c1.7,0,3,1.4,3,3v42.6
          C560.4,90.4,559.1,91.8,557.4,91.8z M438.4,46.9V88c0,1.7,1.4,3,3,3h115.3c1.7,0,3-1.4,3-3V46.9c0-1.7-1.4-3-3-3H441.5
          C439.8,43.8,438.4,45.2,438.4,46.9z"/>
        <g>
          <path class="st26" d="M462.6,59.2v28h-8.8v-28C453.8,59.2,462.6,59.2,462.6,59.2z"/>
          <path class="st26" d="M484.5,82.9h-9.3l-1.4,4.2h-9.2l10.2-28H485l10.2,28H486L484.5,82.9z M482.4,76.3l-2.5-7.8l-2.5,7.8H482.4z
            "/>
          <path class="st26" d="M505.9,59.2v28h-8.8v-28C497.1,59.2,505.9,59.2,505.9,59.2z"/>
          <path class="st26" d="M534.3,80.3c-1.2,2.1-2.9,3.8-5.1,5c-2.2,1.2-4.8,1.8-7.7,1.8h-11.7v-28h11.7c2.9,0,5.5,0.6,7.7,1.8
            s3.9,2.8,5.1,4.9c1.2,2.1,1.8,4.5,1.8,7.2C536.1,75.8,535.5,78.2,534.3,80.3z M525.4,77.7c1.2-1.1,1.7-2.6,1.7-4.6
            s-0.6-3.5-1.7-4.6c-1.2-1.1-2.7-1.6-4.7-1.6h-2.1v12.5h2.1C522.7,79.4,524.3,78.8,525.4,77.7z"/>
          <path class="st26" d="M548,80.5h8.5v6.6h-17.2v-28h8.8L548,80.5L548,80.5z"/>
        </g>
        <g>
          <g>
            <path class="st26" d="M444.9,73.3c0.5-0.5,0.6-1.2,0.3-1.7l3.2-3.2c0.2-0.2,0.5-0.3,0.7-0.3h1.4v1.1c0,0.2,0,0.4,0.1,0.6
              c0.1,0.3,0.3,0.4,0.6,0.3c0.3-0.1,0.4-0.3,0.3-0.6c0-0.1,0-0.3,0-0.4V57.9c0-0.9,0.7-1.6,1.6-1.6h11.3c0.1,0,0.3,0,0.4,0
              c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.1-0.6-0.1h-1.1v-4.2c0.6-0.2,1-0.8,1-1.4
              c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5c0,0.7,0.4,1.2,1,1.4v4.2h-1.1v-2.1c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v2.1h-1.1
              v-7.7c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v7.7h-1.1v-2.1c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v2.1H455v-1.4
              c0-0.5-0.2-1-0.6-1.4l-3.2-3.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4s-0.8,0.2-1.1,0.4
              c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.5-0.1,0.7-0.2l3.2,3.2
              c0.2,0.2,0.3,0.5,0.3,0.7v1.4H453c-1.4,0-2.5,1.1-2.5,2.5v1.1h-4.2c-0.2-0.6-0.8-1-1.4-1c-0.8,0-1.5,0.7-1.5,1.5
              s0.7,1.5,1.5,1.5c0.7,0,1.2-0.4,1.4-1h4.2V61h-2.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.1v1h-7.7c-0.3,0-0.5,0.2-0.5,0.5
              s0.2,0.5,0.5,0.5h7.7v1.1h-2.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.1v1.1H449c-0.5,0-1,0.2-1.4,0.6l-3.2,3.2
              c-0.6-0.3-1.3-0.2-1.7,0.3c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.1C443.3,73.9,444.3,73.9,444.9,73.3z M462.2,49.7
              c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5C462.5,50.2,462.2,50,462.2,49.7z M449.6,49c-0.1-0.1-0.2-0.2-0.2-0.4
              s0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4
              c-0.1,0.1-0.2,0.2-0.4,0.2C449.8,49.2,449.7,49.1,449.6,49z M444.8,60c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
              C445.4,59.7,445.1,60,444.8,60z M443.4,72.6c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0c0.1,0.1,0.2,0.2,0.2,0.4
              s-0.1,0.3-0.2,0.4C444,72.8,443.6,72.8,443.4,72.6z"/>
          </g>
        </g>
      </g>
      <g>
        <path class="st26" d="M507.3,102.7v2.7h-2.6V97h3.7c1,0,1.8,0.3,2.4,0.8s0.8,1.2,0.8,2.1c0,0.5-0.1,1-0.4,1.4
          c-0.2,0.4-0.6,0.8-1.1,1c-0.5,0.3-1.1,0.4-1.7,0.4C508.4,102.7,507.3,102.7,507.3,102.7z M508.9,99.9c0-0.5-0.3-0.8-0.8-0.8h-0.7
          v1.5h0.7C508.6,100.6,508.9,100.4,508.9,99.9z"/>
        <path class="st26" d="M516.6,105.4l-1.6-3v3h-2.6V97h3.9c0.7,0,1.3,0.1,1.7,0.4c0.5,0.2,0.8,0.6,1.1,1c0.2,0.4,0.4,0.9,0.4,1.4
          c0,0.6-0.2,1.1-0.5,1.5s-0.8,0.7-1.3,0.9l1.8,3.2H516.6z M515,100.6h1c0.3,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
          s-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.6-0.2h-1V100.6z"/>
        <path class="st26" d="M526.5,97.4c0.7,0.4,1.2,0.9,1.5,1.5s0.6,1.4,0.6,2.2s-0.2,1.6-0.6,2.2s-0.9,1.2-1.6,1.5
          c-0.7,0.4-1.4,0.6-2.2,0.6s-1.5-0.2-2.2-0.6s-1.2-0.9-1.6-1.5s-0.6-1.4-0.6-2.2s0.2-1.6,0.6-2.2s0.9-1.2,1.6-1.5
          c0.7-0.4,1.4-0.5,2.2-0.5C525.1,96.9,525.8,97.1,526.5,97.4z M523.1,99.8c-0.3,0.3-0.4,0.8-0.4,1.4s0.1,1,0.4,1.3
          c0.3,0.3,0.7,0.5,1.2,0.5s0.9-0.2,1.2-0.5s0.4-0.8,0.4-1.3c0-0.6-0.1-1-0.4-1.4c-0.3-0.3-0.7-0.5-1.2-0.5S523.4,99.5,523.1,99.8z"
          />
        <path class="st26" d="M536.8,103.3c-0.4,0.6-0.9,1.1-1.5,1.5c-0.7,0.4-1.4,0.5-2.3,0.5h-3.5V97h3.5c0.9,0,1.6,0.2,2.3,0.5
          c0.7,0.4,1.2,0.8,1.5,1.5s0.5,1.3,0.5,2.1C537.4,102,537.2,102.7,536.8,103.3z M534.2,102.5c0.3-0.3,0.5-0.8,0.5-1.4
          s-0.2-1.1-0.5-1.4s-0.8-0.5-1.4-0.5h-0.6v3.7h0.6C533.4,103,533.8,102.9,534.2,102.5z"/>
        <path class="st26" d="M540.9,97v4.7c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.8,0.3s0.7-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9V97
          h2.6v4.7c0,0.8-0.2,1.5-0.5,2c-0.3,0.6-0.8,1-1.4,1.3s-1.2,0.4-1.9,0.4s-1.3-0.1-1.9-0.4s-1-0.7-1.3-1.3c-0.3-0.6-0.5-1.2-0.5-2
          V97H540.9z"/>
        <path class="st26" d="M552.6,97.3c0.6,0.3,1,0.7,1.4,1.1c0.4,0.5,0.6,1.1,0.7,1.7h-2.8c-0.1-0.3-0.3-0.5-0.5-0.6
          c-0.2-0.2-0.5-0.2-0.8-0.2c-0.4,0-0.8,0.2-1,0.5c-0.3,0.3-0.4,0.8-0.4,1.3c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,1,0.5
          c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.5-0.6h2.8c-0.1,0.7-0.4,1.2-0.7,1.7c-0.4,0.5-0.8,0.9-1.4,1.1s-1.2,0.4-1.9,0.4
          c-0.8,0-1.6-0.2-2.2-0.5c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.2s0.2-1.6,0.5-2.2s0.8-1.1,1.4-1.5
          c0.6-0.4,1.4-0.5,2.2-0.5C551.4,96.9,552,97.1,552.6,97.3z"/>
        <path class="st26" d="M562.2,97v2.1H560v6.3h-2.6v-6.3h-2.2V97H562.2z"/>
      </g>
    </g>
    </svg>



  </div>
</template>

<script>
import db from '@/fb';


export default {
  props: {
    certificate:{},
    company:{},
    location:{},
    token:{},
    date:{},
    dateTo:{},
    product:{},
  },
  data() {
    return {
      discipline: 'Iadil international standards',
    }
  },
  methods: {
    
  },
  computed: {
    companyFont(){
      if(this.$props.company){
        let a = (26 / (this.$props.company.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 31
        return a
      }
    },
    productFont(){
      if(this.$props.company){
        let a = (55 / (this.$props.product.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 10
        return a
      }
    },
    companyLeft(){
      if(this.$props.company){
        let a = (14 / (this.$props.company.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 300
        a = 60
        return a
      }
    },
    locationFont(){
      if(this.$props.location){
        let a = (13 / (this.$props.location.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 24
        let b = this.$props.location.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length < 14 ? 24 : a
        b = 24
        return b
      }
    },
    locationLeft(){
      let a = 180
      return a
    },
  }, 
  watch: {
    
  },
  mounted() {
      
  }
}
</script>
