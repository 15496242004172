<template>
  <div>
    <v-card  class="mb-0">
      <v-img
        class="white--text"
        height="100px"
        src="https://portal.iaidl.org/tback.jpg"
      >
        <v-container fill-height fluid>
          <v-layout fill-height>
            <v-flex xs12 align-end flexbox class="text-xs-center">
              <span class="font-weight-black headline">AIMA</span>
              <span><sup>®</sup></span>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>
      <v-layout wrap>
        <v-flex xs12 sm12 px-4 mt-4>
          <v-text-field
            label="Token"
            readonly
            v-model="token"
            solo-inverted
            suffix="Token"
            :loading="tokenLoading"
            disabled
            light
            class="tokenField"
            color="white"
            background-color="primary"
          ></v-text-field>
        </v-flex>


        <v-flex xs12 md12 px-1>


          <v-card>
            <v-layout row wrap ma-2 pa-2>
              <v-flex xs4 sm4 md4>
                <div class="caption grey--text ">Service</div>
                <v-text-field
                    label="Service Name"
                    v-model="serviceName"
                    solo
                    clearable
                    required
                    prepend-inner-icon="account_balance"
                    :disabled="noToken"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 sm4 md4>
                <div class="caption grey--text ">Service 4.0</div>
                <v-text-field
                    label="Service 4.0"
                    v-model="serv4"
                    solo
                    clearable
                    required
                    prepend-inner-icon="account_balance"
                    :disabled="noToken"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 sm4 md4>
                <div class="caption grey--text ">Tool</div>
                <v-autocomplete
                    :items="toolsAI"
                    item-text="name"
                    v-model="serviceTool"
                    label="Tool"
                    solo
                    prepend-inner-icon="turned_in"
                    :disabled="noToken"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <div class="caption grey--text ">objectives</div>
                <v-textarea
                    label="Details"
                    v-model="serviceObjective"
                    solo
                    clearable
                    required
                    multi-line
                    :disabled="noToken"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card>
            <v-layout row wrap ma-2 pa-2>
              <v-flex xs12 sm12 md12>

                <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="addService()"  large>
                  Add Service
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>


        </v-flex>








        <v-flex xs12 md12 px-1>
          <v-card v-for="(asset, i) in assets" :key="i">
            <v-layout row wrap ma-2 pa-2>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">Service</div>
                <div >{{ asset.name }}</div>
              </v-flex>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">Service 4.0</div>
                <div >{{ asset.serv4 }}</div>
              </v-flex>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">objectives</div>
                <div >{{ asset.objectives }}</div>
              </v-flex>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">Tool</div>
                <div >{{ asset.tool }}</div>
              </v-flex>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">Priority</div>
                <div >{{ asset.priority }}</div>
              </v-flex>
              <v-flex xs4 sm3 md3>
                <div class="caption grey--text ">Loader</div>
                <div >{{ asset.loader }}</div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>





      </v-layout>
    </v-card>

  </div>
</template>

<script>

import {db} from "@/fb";

export default {
  props: ['userName'],
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      toolsAI:["None", "AI Cyber Security", "AI", "Big Data", "Robots", "AR", "Blockchain", "VR", "Drones", "IOT", "3D Printing"],
      isLoading:false,
      countries: [],
      serviceName:null,
      serv4:null,
      serviceObjective:"NA",
      nameCorporate:null,
      nameDivision:null,
      country:null,
      revenue:null,
      serviceTool:null,
      numberEmployees:null,
      industry:null,
      markets:null,
      brands:null,
      initialLevel:null,
      organisationType:null,
      title:null,
      contactName:null,
      contactEmail:null,
      contactPhone:null,
      tokenLoading:true,
      stepperValue:null,
      aima:[],
      aimaAns:[],
      aimaCats:[],
      image:null,
      uploadProgress:null,
      file:null,
      loadedAimaApp:false,
      token:null,
      tokenKey:null,
      result:null,
      assets: [],
      myArray: [],
      uO:[]
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    async addService() {
      this.assets = []
      this.tokenLoading = true
      let servName = this.serviceName
      let serv4 = this.serv4
      let servObj = this.serviceObjective
      console.log(servName)
      console.log("====================")
      let servTool = this.serviceTool
      console.log(servTool)
      console.log("====================")
      let servTkn = this.token
      console.log(servTkn)

      let priority = 0
      let loader = 0

      if (servName != null && servTool != null && servTkn != null && servObj != null) {


// -------------------------------------------------------------------------------
//define priority based on tool
        //get total marks
        let strategy       = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/AI_AND_FMT_STRATEGY/unweighted`).once('value').then(x => x.val())
        let finance        = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/AI_AND_FMT_FINANCE_AND_INVESTMENT/unweighted`).once('value').then(x => x.val())
        let toolsMaturity  = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/AI_AND_FMT_TOOLS_MATURITY/unweighted`).once('value').then(x => x.val())
        let techInno       = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/INTELLIGENCE_AND_TECH_INNOVATION_IMPOSITION/unweighted`).once('value').then(x => x.val())
        let agility        = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/PROCEDURES_AGILITY/unweighted`).once('value').then(x => x.val())
        let enablers       = await db.ref(`aima/${this.token}/aima-result/Aggregate_Group_Scores/TRANSFORMATION_ENABLERS/unweighted`).once('value').then(x => x.val())

        //define average
        let avg =  ( strategy + finance + toolsMaturity + techInno + agility + enablers ) / 6
        avg = avg.toFixed(3)

        let AICpriority, AIpriority, BDpriority, ROpriority, BCpriority , DRpriority, AVpriority, D3priority, IOpriority = 0

        if( strategy > avg && enablers > avg && finance > avg ){
          AICpriority = 1
          AIpriority = 2
          BDpriority = 3
          ROpriority = 4
          BCpriority = 5
          DRpriority = 6
          AVpriority = 7
          D3priority = 8
          IOpriority = 9
        } else if( techInno > avg && agility > avg && toolsMaturity > avg ){
          AICpriority = 1
          BDpriority = 2
          ROpriority = 3
          BCpriority = 4
          DRpriority = 5
          AVpriority = 6
          D3priority = 7
          IOpriority = 8
          AIpriority = 9
        } else if( strategy > avg && agility > avg && enablers > avg ){
          AICpriority = 1
          ROpriority = 2
          BCpriority = 3
          DRpriority = 4
          AVpriority = 5
          D3priority = 6
          IOpriority = 7
          AIpriority = 8
          BDpriority = 9
        } else if( agility > avg && strategy > avg && toolsMaturity > avg ){
          AICpriority = 1
          BCpriority = 2
          DRpriority = 3
          AVpriority = 4
          D3priority = 5
          IOpriority = 6
          AIpriority = 7
          BDpriority = 8
          ROpriority = 9
        } else if( toolsMaturity > avg && finance > avg && techInno > avg && enablers > avg ){
          AICpriority = 1
          DRpriority = 2
          AVpriority = 3
          D3priority = 4
          IOpriority = 5
          AIpriority = 6
          BDpriority = 7
          ROpriority = 8
          BCpriority = 9
        } else if( techInno > avg && enablers > avg ){
          AICpriority = 1
          AVpriority = 2
          D3priority = 3
          IOpriority = 4
          AIpriority = 5
          BDpriority = 6
          ROpriority = 7
          BCpriority = 8
          DRpriority = 9
        } else if( toolsMaturity > avg && finance > avg ){
          AICpriority = 1
          D3priority = 2
          IOpriority = 3
          AIpriority = 4
          BDpriority = 5
          ROpriority = 6
          BCpriority = 7
          DRpriority = 8
          AVpriority = 9
        } else if( finance > avg && toolsMaturity > avg && techInno > avg && enablers > avg ){
          AICpriority = 1
          IOpriority = 2
          AIpriority = 3
          BDpriority = 4
          ROpriority = 5
          BCpriority = 6
          DRpriority = 7
          AVpriority = 8
          D3priority = 9
        } else {
          AICpriority = 1
          AIpriority = 2
          BDpriority = 3
          ROpriority = 4
          BCpriority = 5
          DRpriority = 6
          AVpriority = 7
          D3priority = 8
          IOpriority = 9
        }
// -------------------------------------------------------------------------------
//"AI", "Big Data", "Robots", "AR", "Blockchain", "VR", "Drones", "IOT", "3D Printing"
        if( servTool == 'AI' ) {
          priority = AIpriority
        } else if( servTool == 'AI Cyber Security' ) {
          priority = AICpriority
        } else if( servTool == 'Big Data' ) {
          priority = BDpriority
        } else if( servTool == 'Robots' ) {
          priority = ROpriority
        } else if( servTool == 'AR' ) {
          priority = AVpriority
        } else if( servTool == 'Blockchain' ) {
          priority = BCpriority
        } else if( servTool == 'VR' ) {
          priority = AVpriority
        } else if( servTool == 'Drones' ) {
          priority = DRpriority
        } else if( servTool == 'IOT' ) {
          priority = IOpriority
        } else if( servTool == '3D Printing' ) {
          priority = D3priority
        } else {
          priority = 0
        }




        loader = 10 - priority
        let servicer = {
          name: servName,
          objectives: servObj,
          tool: servTool,
          priority: priority,
          serv4: serv4,
          loader: loader
        }
        db.ref('aima/' + servTkn + '/aimaServices').push(servicer)
            .then(x => {
              this.serviceName = null
              this.serviceTool = null
              this.serviceObjective = "NA"
              this.tokenLoading = false
              this.loadServices(servTkn)
            })


      } else {
        alert("Please check your inputs !!")
      }


    },
    loadServices(tkn){
      
      db.ref( 'aima/' + tkn + '/aimaServices')
          .once('value')
          .then(snap=>{
            let candidates = snap.val()
            for (let key in candidates) {
              // console.log(key)
              this.tokenLoading = true
              db.ref('aima/' + tkn + '/aimaServices/' + key).orderByChild("loader")
                  .once('value').then(subSnap=>{
                this.tokenLoading = false
                let candidate = subSnap.val()
                if(candidate) {
                  console.log(candidate)
                  this.assets.push(candidate)
                }
              })
            }
          });
          //rearrange assets
          //this.myArray.sort((a,b)=> (a.loader > b.loader ? 1 : -1));
         // this.assets = this.myArray;
    }
  },

  watch: {
  },
  computed: {
  },
  async created() {
    if(this.$route.params.token) {
      this.token = this.$route.params.token
      this.loadServices(this.token)
      this.tokenLoading = false
		} else {
      this.goTo('/aima')
    }



  },
  mounted(){

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
