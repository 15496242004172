<template>
  <div class="dashboard">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Certificate for {{ company }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <img class="elevation-4 ma-1" width="99%" src="../assets/DEMO.jpg" alt=""> -->
            <div
              v-on:dragstart="getRight()" 
              @click.right="getRight()" 
              @click.left="getRight()"
            >
              
              <SVGPRODUCTcertificate
              v-if="certificate"
              class="certContainer elevation-4 ma-1 pa-0"
              :company="company"
              :location="location"
              :token="token"
              :date="date"
              :product="product"
              :dateTo="dateTo"
              />
            </div>
            <v-divider></v-divider>
            <v-layout row wrap>
              <v-flex xs12 ma-0>
                <v-card class="elevation-8">
                  <v-layout row wrap>
                    <v-flex class="pa-3 text-xs-left" xs12 sm6 px-1 my-1>
                      <!-- <v-btn 
                        @click="downloadImg()"
                        color="primary" dark>
                        <v-icon left>image</v-icon>
                        Download Image
                      </v-btn> -->
                      <v-btn 
                        :loading="pdfLoading"
                        @click="downloadPDF()"
                        color="primary" dark>
                        <v-icon left>picture_as_pdf</v-icon>
                        Generate & Download PDF
                      </v-btn>
                      <!-- <v-btn 
                        :loading="printLoading"
                        @click="imgPrint()"
                        color="primary" dark>
                        <v-icon left>print</v-icon>
                        Print
                      </v-btn> -->
                      <h3 class="pa-3 text-xs-left">Uniqe Certificate Link:</h3>
                      <v-text-field
                        label="Unique Link"
                        solo
                        px-3
                        v-model="certificateShortLink"
                        readonly
                      ></v-text-field>
                      <v-btn @click="previewVerification()" color="primary" dark>Preview Verification Page</v-btn>
                      <v-btn 
                        v-clipboard="certificateShortLink"
                        :v-clipboard="certificateShortLink"
                        v-clipboard:success="clipboardSuccessHandler"
                        v-clipboard:error="clipboardErrorHandler"
                        color="primary" dark>
                        <v-icon left>file_copy</v-icon>
                        Copy Link
                      </v-btn>
                      <v-btn 
                        color="primary" 
                        dark
                        @click="downloadQR()"
                        >
                        <v-icon left>image</v-icon>
                        Download QR code as an image
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 px-1 my-1>
                      <h3 class="pa-3">Uniqe Certificate QR:</h3>
                      <vue-qr 
                        bgSrc='/pp.png' 
                        :text="certificateShortLink"
                        :callback="getQR" 
                        :size="300"
                        dotScale="0.35"
                        autoColor="true"
                        whiteMargin="false"
                        margin="5"
                      ></vue-qr>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import printJS from 'print-js'
import VueQr from 'vue-qr'
import SVGcertificate from '../components/SVGcertificate';
import SVGPRODUCTcertificate from '../components/SVGPRODUCTcertificate';
import SVGcard from '../components/SVGcard';
import MiniStatistic from '@/components/MiniStatistic';
import canvg from 'canvg'
import axios from 'axios';
import download from 'downloadjs'

export default {
  components: {VueQr, SVGcertificate, SVGPRODUCTcertificate, SVGcard, MiniStatistic},
  props: ['isLogin'],
  data: () => ({
    level:null,
    candidateName:'DR. JOHN DOE',
    date:'00/00/00',
    ttt:null,
    qrImage:null,
    pdfLoading:false,
    printLoading:false,
    code:null,
    views:null,
    countries:null,
    detailedViews:[],
    detailedViewsHeaders:[],
    certificate:null,
    company:null,
    location:null,
    token:null,
    date:null,
    product:null,
    dateTo:null,
    certificateShortLink:'',
  }),
  computed:{
    
  },
  watch: {

  },
  methods: {
    async getCertificateDetails(t){
      let m = await db.ref(`productCertificates/${t}`).once('value').then(x=>x.val())
      console.log(m)
      this.certificate = m 
      this.certificate.token = t
      this.company = this.certificate.company
      this.location = this.certificate.location
      this.product = this.certificate.product
      this.token = this.certificate.token
      this.date = this.certificate.date
      this.dateTo = this.certificate.dateTo
      this.certificateShortLink = this.certificate.certificateShortLink
    },
    previewVerification() {
      this.goTo('/product-verification/' + this.token + '?preview=true')
    },
    goTo(path) {
      this.$router.push(path)
    },
    downloadQR () {
      download(this.qrImage, "certificate_QR.png", "image/png");
    },
    getQR(dataUrl,id) {
      this.qrImage = dataUrl
    },
    downloadPDF(url) {
      this.getPdf()
    },
    clipboardSuccessHandler ({ value, event }){
      console.log('success', value)
      this.$emit('snackbarNotification', `${this.certificateShortLink} copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      console.log('error', value)
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    getPdf() {
      this.pdfLoading = true
      this.$emit('snackbarNotification', `Generating ${this.company} Certificate...`, 'success')
      
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/pdfProduct?company=${this.company}&token=${this.token}&date=${this.date}&location=${this.location}&dateTo=${this.dateTo}&product=${this.product}`)
      .then(response => { 
        window.open(response.data, 'Certificate Downloader')
        console.log('------------------------------------');
        console.log(response.data);
        console.log('------------------------------------');
        this.pdfLoading = false
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
  },
  created(){
    if(this.$route.params.token) {
      this.getCertificateDetails(this.$route.params.token)
    } else {
      this.goTo('/')
    }
  },
  mounted(){
    
    
  }
}

</script>

<style>
.certContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
