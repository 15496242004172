<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      
      <v-card 
      v-for="(invoice,i) in invoices"
      :key="i"
      >
        <v-layout row wrap ma-2 pa-2>


          <v-flex xs6 sm6 md6>
            <div class="caption grey--text ">Center Name</div>
            <div >{{ invoice.center_name }}</div>
          </v-flex>

          <v-flex xs6 sm6 md6>
            <div class="caption grey--text ">Token</div>
            <div >{{ invoice.invoice_id }}</div>
          </v-flex>
          
          <v-flex xs6 sm4 md4>
            <div class="caption grey--text ">Invoice Date</div>
            <div >{{ invoice.invoice_date }}</div>
          </v-flex>
          
          <v-flex xs6 sm4 md4>
            <div class="caption grey--text ">Invoice Amount</div>
            <div >{{ invoice.invoice_amount }}</div>
          </v-flex>
          
          <v-flex xs6 sm4 md4>
            <div class="caption grey--text ">Invoice Status</div>
            <div >{{ invoice.invoice_status }}</div>
          </v-flex>
          
            <v-card-actions dense>
              <v-spacer></v-spacer>
              <a :href="'https://portal.iaidl.org/checkout/print_invoice.php?id=' +  invoice.invoice_id + '&center_uid=' + invoice.center_uid " target="_blank"><v-btn color="primary" dark small @click="console.log(invoice)">Printable Copy</v-btn></a>
            </v-card-actions>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import axios from 'axios';
export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allinvoices: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      question:null,
      filteredInvoices:[],
      paginateInvoices: [],
      invoices: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      userUid:null,
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    
    
  },
  
  created() {
    this.loading = true;

      auth.onAuthStateChanged(user => {
        if (user) {
          //9pbBmDg79sfdDlENp3unfzNvf3E3
          

    let thsLevel = user.uid

          axios
          .post('https://portal.iaidl.org/checkout/app_api/invoices/get_invoice_portal_a.php',
          { 'center_uid' :thsLevel }
          )
          .then( responseData => ( this.invoices = responseData.data.invoices ),  this.loading = false )
        .catch(err => {
          // Manage the state of the application if the request 
          // has failed    
          alert("GENERAL ERROR"),  this.loading = false
        });


         
          
        } else {
          this.$router.push('/login')
        }
      })
    //this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
