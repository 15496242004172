<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card>
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Add / Edit Questions</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
        <v-layout wrap ma-3>
          <v-flex xs12 sm12 px-1 my-1>
            <v-text-field
              label="Question Code"
              readonly
              v-model="tokenKey"
              solo-inverted
              suffix="Question Code"
              disabled
              light
              class="tokenField"
              color="white"
              background-color="primary"
            ></v-text-field>
          </v-flex>
            <v-flex xs12 md6 px-1 my-1>
              <v-select
                :items="courseSelect"
                v-model="selectedCourse"
                label="Select a course"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex  xs12 md6 px-1 my-1>
              <v-select
                :items="categorySelect"
                v-model="selectedcategory"
                label="Select a category"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex  xs12 px-1 my-1>
              <v-select
                :items="typeSelect"
                v-model="selectedType"
                label="Select a Type"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-select
                :items="questionSelect"
                v-model="selectedQuestion"
                label="Select a Question"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-autocomplete
                :items="['ar', 'en'].map(x => x.toUpperCase())"
                item-text="name"
                v-model="questionLanguage"
                label="Question Language"
                solo
                prepend-inner-icon="public"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12  px-1 my-1>
              <v-text-field
                label="Question"
                v-model="questionText"
                solo
                clearable
                required
                :error-messages="questionErrors"
                @blur="$v.questionText.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex v-for="(answer, i) in answers" :key="answer.key" xs12 px-1 my-1>
              <v-text-field
                :label="'Answer ' + parseInt(i + 1)"
                v-model="answer.text"
                solo
                clearable
                required
                :prepend-icon="(correctAnswer == i +1 ) ? 'check_circle': 'highlight_off'"
                append-outer-icon="close"
                @click:append-outer="removeAnswer(i)"
                @click:prepend="markTrue(i)"
                :error-messages="answerErrors[i]"
                @blur="checkAnswers(i)"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <span class="red--text">{{ twoAnsError }}</span>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-btn color="primary" @click="addAnswer()" dark>Add an answer</v-btn>
            </v-flex>
            <v-flex xs12 px-5>
              <v-subheader class="pl-0">Question Allowed Time (seconds)</v-subheader>
              <v-slider
                v-model="questionTime"
                color="primary"
                always-dirty
                max="600"
                thumb-size="28"
                thumb-color="red"
                thumb-label="always"
                @change="startTimer()"
              ></v-slider>
            </v-flex>
            <v-flex xs12 ma-3>
              <v-card class="elevation-4">
                <v-layout row wrap pa-3>
                  <v-flex xs6 class="primary white--text pa-2 mb-3">
                    <h2>Preview</h2><span> please choose correct answer here</span>
                  </v-flex>
                  <v-flex xs6 class="primary white--text pa-2 mb-3 text-xs-right">
                    <countdown
                    :left-time="timePreview*1000"
                    ref="vac2"
                    :auto-start="false"
                    > 
                      <span
                        slot="process"
                        slot-scope="{ timeObj }"
                        >
                        <v-progress-circular
                          :rotate="360"
                          :size="20"
                          :width="5"
                          :value="timeObj.ceil.s*100/timePreview"
                          :color="((timeObj.ceil.s*100/timePreview)>30) ? 'white' : 'error'"
                        ></v-progress-circular>
                        Auto Next in  {{ timeObj.ceil.s }}s
                        </span>
                      <span slot="finish">Finished</span>
                    </countdown>
                  </v-flex>
                  <v-flex xs12>
                    <h3>{{ questionText }}</h3>
                  </v-flex>
                  <v-flex xs12>
                    <v-radio-group v-model="correctAnswer">
                      <v-radio 
                      v-for="answer in answers" 
                      :key="answer.key" 
                      :name="answer.text"
                      :label="answer.text" 
                      :value="answer.number" 
                      color="primary"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pa-2>
              <v-flex class="error--text text-xs-right" v-for="(err,i) in submitErrors" :key="i">{{ err }}</v-flex>
            </v-flex>
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" outline :loading="isLoading" :dark="false" @click="deleteQuestion()"  large>
                  Delete {{ tokenKey }} Question
                </v-btn>
                <v-btn color="primary" :loading="isLoading" :dark="false" @click="saveQuestion()"  large>
                  Save {{ tokenKey }} Question
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha, alphaNum } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString, getRandomInt } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { ge } from '@/qexam';

import { Printd } from 'printd'
import { helpers } from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'

export default {
  mixins: [validationMixin],
  props: ['userName'], 
  components: {
    draggable,
  },
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      uploadingLoad:false,
      drag:false,
      dialog:false,
      isLoading:false,
      loading:false,
      answers:[],
      answerKey:0,
      correctAnswer:null,
      twoAnsError: null,
      answersErrors:[],
      tokenKey:null,
      tokenLoading:true,
      questionTime:null,
      selectedQuestion:[],
      questionSelect:[],
      typeSelect:[{text:'preassessment', value:'preassessment'}, {text:'exam', value:'exam'}],
      selectedType:null,
      loadedquestionText:null,
      questionLanguage:null,
      selectedCourse:null,
      courseSelect:null,
      questionText:null,
      courses:[],
      courseId:null,
      selectedcategory:null,
      manuals:[],
      categorySelect:[],
      manualName:null,
      manualShort:null,
      manualId:null,
      urlsManual:[],
      submitErrors:[]
    }
  },
  methods: {
    loadQestion(){
      if(this.$route.params.token){
        this.tokenKey = this.$route.params.token
        db.ref('questions/' + this.tokenKey)
        .once('value')
        .then(snap=>{
          let qustion = snap.val()
          console.log(qustion)
          this.selectedType = {text:qustion.type, value:qustion.type}
          this.selectedCourse = {text:qustion.course, value:qustion.course}
          this.selectedcategory = {text:qustion.category, value:qustion.category}
          this.selectedQuestion.questText = qustion.text        
          this.selectedQuestion.questionTime = qustion.questionTime
          this.selectedQuestion.answers = qustion.answers
          this.selectedQuestion.correctAnswer = qustion.correctAnswer
          this.selectedQuestion.language = qustion.language
          this.selectedQuestion.value = this.tokenKey
          this.selectedQuestion.query = qustion.query
          this.selectedQuestion.id = 1
          this.selectedQuestion.text = `${this.tokenKey} - ${qustion.text}`
          this.questionText = qustion.text        
          this.questionLanguage = qustion.language
          this.questionTime = qustion.questionTime
          this.answers = qustion.answers
          this.correctAnswer = qustion.correctAnswer
        })
      } else {
        this.tokenKey = `IAIDL_Q_${randomString(8, 'aA#')}`
      }
    },
    addAnswer(){
      this.twoAnsError = ``
      this.answers.push({
        number:this.answers.length + 1,
        text:null,
        key:this.answerKey
      })
      this.answerKey += 1
    },
    removeAnswer(i){
      if (this.answers.length <= 2) {
        this.twoAnsError = `Question can have at least 2 answers`
        return
      }
      this.correctAnswer = null
      this.answers.splice(i, 1)
    },
    markTrue(i){
      this.correctAnswer = i + 1  
    },
    getselectedcategory(){
      console.log(this.selectedcategory)
    },
    getCourses(){
      this.loading = true
      db.ref('manuals')
      .on('value', snap=>{
        this.courses = snap.val()
        let mS = []
        let id = 0
        for (const key in this.courses) {
          if (this.courses.hasOwnProperty(key)) {
            const course = this.courses[key];
            mS.push({
              text:course.level,
              value:course.level,
              id
            })
            id++
          }
        }
        mS.push({
          text:"AIMA QUESTIONS",
          value:"aimaassessor",
          id
        })
        this.courseSelect = mS
        this.courseId = this.courseSelect.length
        this.loadQestion()
        this.loading = false
      })
    },
    saveQuestion() {
      this.submitErrors = []
      if (!this.correctAnswer) {
        this.submitErrors.push('Please choose a correct answer in preview')
      }
      if (this.questionTime == 0 || !this.questionTime) {
        this.submitErrors.push('Please choose a correct question time')
      }
      if (!this.selectedCourse) {
        this.submitErrors.push('Please choose a course')
      }
      if (!this.selectedcategory) {
        this.submitErrors.push('Please choose a module')
      }
      if (!this.questionText) {
        this.submitErrors.push('Please enter question text')
      }
      if (!this.selectedType) {
        this.submitErrors.push('Please select a question type')
      }
      if (this.submitErrors.length > 0) {
        return
      }
      let question = {
        [this.tokenKey]:{
          course:this.selectedCourse.value,
          category:this.selectedcategory.value,
          text: this.questionText,
          answers:this.answers,
          language:this.questionLanguage,
          type:this.selectedType.value,
          query:`${this.selectedCourse.value}_${this.selectedcategory.value}_${this.selectedType.value}`,
          correctAnswer:this.correctAnswer,
          questionTime:this.questionTime
        }
      }
      db.ref('questions').update(question)
      .then(x=>{
        this.goTo('/questions-overview')
      })
    },
    deleteQuestion() {
      db.ref('questions').update({[this.tokenKey]:null})
      .then(x=>{
        this.goTo('/questions-overview')
      })
    },
    checkAnswers(i){
      if (!this.answers[i].text) { this.answersErrors.push('Cant be empty') ; return}
      if (this.answers[i].text.length < 1) { this.answersErrors.push(['Cant be empty'])}
    },
    goTo(path) {
      this.$router.push(path)
    },
    startTimer(){
      this.$refs.vac2.startCountdown(true)
    }
  },
  watch: {
    selectedCourse(){
      this.loadedquestionText = this.selectedCourse.value
      console.log('------------------------------------');
      console.log(this.courses);
      console.log('------------------------------------');
      this.manuals = []
      for (const key in this.courses) {
        if (this.courses.hasOwnProperty(key)) {
          const course = this.courses[key];
          this.manuals.push(course)
        }
      }
      let mS = []
      let id = 0
      this.manuals.forEach(m=>{
        mS.push({
          text:m.short,
          value:m.short,
          id
        })
        id++
      })
      console.log('------------------------------------');
      console.log(this.manuals);
      console.log('------------------------------------');
      this.categorySelect = mS
      this.manualId = this.manuals.length
    },
    selectedcategory(){
      if(!this.$route.params.token){
        this.questionSelect = []
        this.questionText = null
        this.questionTime = null
        this.questionLanguage = null
        this.answers = []
        this.addAnswer()
        this.addAnswer()
        this.tokenKey = `IAIDL_Q_${randomString(8, 'aA#')}`
        this.correctAnswer = null
      }
      let s = this.manuals.filter(manual=> manual.short == this.selectedcategory.value)
      this.manualName = s[0].name
      this.manualShort = s[0].category
      this.urlsManual = s[0].urls
      this.manualId = this.selectedcategory.id
    },
    selectedType(){
      db.ref('questions')
      .orderByChild('query')
      .equalTo(`${this.selectedCourse.value}_${this.selectedcategory.value}_${this.selectedType.value}`)
      .once('value')
      .then(snap=>{
        let questions = snap.val()
        let qs = []
        let id = 0
        for (const key in questions) {
          if (questions.hasOwnProperty(key)) {
            const question = questions[key];
            qs.push({
              text:`${key} - ${question.text}`,
              questText:question.text,
              value:key,
              language:question.language,
              answers:question.answers,
              course:question.course,
              type:question.type,
              category:question.category,
              query:question.query,
              correctAnswer:question.correctAnswer,
              questionTime:question.questionTime,
              id
            })
            id++
          }
        }
        this.questionSelect = qs 
      })
    },
    selectedQuestion(){
      this.questionText = this.selectedQuestion.questText
      this.questionTime = this.selectedQuestion.questionTime
      this.questionLanguage = this.selectedQuestion.language
      this.answers = this.selectedQuestion.answers
      this.tokenKey = this.selectedQuestion.value
      this.correctAnswer = this.selectedQuestion.correctAnswer
    },
  },
  computed: {
    answerErrors(){
      const errors = []
      return errors
    },
    questionErrors () {
      const errors = []
      if (!this.$v.questionText.$dirty) return errors
      !this.$v.questionText.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.questionText.required && errors.push('Name is required')
      return errors
    },
    timePreview() {
      let m = this.questionTime
      return parseInt(m)
    }
  },
  validations: {
    questionText: {
      required,
      minLength: minLength(4),
    },
  },
  created() {
    //db.ref('manuals').update(this.$store.state.manuals)
    // db.ref('manuals/basic').once('value')
    // .then(snap=>{
    //   let courses = ['ar', 'vr', 'ai', 'iot', 'threedprinting', 'drones', 'bigData', 'robots']
    //   courses.forEach(course=>{
    //     let ans = getRandomInt(1,5)
    //     let types = ['preassessment', 'exam','preassessment', 'exam']
    //     let ntype = types[getRandomInt(0, 2)]
    //     let question = {
    //     [`IAIDL_Q_${randomString(8, 'aA#')}`]:{
    //         course:'basic',
    //         category:course,
    //         text: `Question ans:${ans}`,
    //         language:'EN',
    //         answers:[
    //           {number: 1, text:'Answer 1'},
    //           {number: 2, text:'Answer 2'},
    //           {number: 3, text:'Answer 3'},
    //           {number: 4, text:'Answer 4'},
    //           {number: 5, text:'Answer 5'},
    //         ],
    //         type:ntype,
    //         query:`basic_${course}_${ntype}`,
    //         correctAnswer:ans,
    //         questionTime:getRandomInt(20,25)
    //       }
    //     }
    //     console.log('------------------------------------');
    //     console.log(question);
    //     console.log('------------------------------------');
    //     db.ref('questions').update(question)
    //   })
    // })
    this.getCourses()
    this.addAnswer()
    this.addAnswer()
    ge()
    // db.ref('questions')
    // .orderByChild('query')
    // .equalTo('basic_blockchain_exam')
    // .once('value')
    // .then(async x=>{
    //   let e = []
    //   let q = x.val()
    //   for (const key in q) {
    //     if (q.hasOwnProperty(key)) {
    //       const qs = q[key];
    //       // await db.ref('questions')
    //       // .update({
    //       //   [key]:null
    //       // })
    //       e.push(qs)
    //     }
    //   }
    //   console.log(e.length)
    // })
  }
}
</script>

