<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start @load="getManuals">
        <v-flex xs12 md12 px-1>
          
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Guided Learning Materials</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
            <v-layout v-if="getMaterialAccess" wrap row justify-start ma-3>
              <v-flex xs12 md12 pa-2>
                <v-card @load="getMaterialProgress">
                  <v-card-title>
                    <div class="layout row ma-0 justify-space-between pb-1">
                      <div class="subheading">IAIDL<sup>®</sup> Progress {{ parseInt(getOverallProgress) }}%</div>
                      <div class="icon">
                        <v-icon color="primary">class</v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-progress-linear color="primary" height="12" v-model="getOverallProgress"></v-progress-linear>
                  <v-card-text>
                    <v-alert
                      value="true"
                      type="warning"
                    >
                      Manuals should be viewed and studied with Trainer
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout v-if="getMaterialAccess" wrap row justify-start ma-3>
              <v-flex v-for="manual in manuals" :key="manual.name" xs12 md4 pa-2>
                <v-card>
                  <v-img
                    class="white--text"
                    height="100px"
                    :src="manual.cover"
                  >
                    <v-container fill-height fluid>
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <span class="headline">{{ manual.name }}</span>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-img>
                  <v-card-text>
                    <div class="justify-center row layout ma-0">
                      <v-progress-circular
                        :size="150"
                        :width="15"
                        :rotate="-90"
                        :value="manual.progress.progress"
                        color="primary"
                      >
                        <v-icon  v-if="manual.progress.progress == 100" x-large color="primary">done</v-icon>
                        <div v-if="manual.progress.progress == 100" class="headline" text-color="primary">Done</div>
                        <v-btn v-if="!manual.progress.started" outline @click="goTo('/manual/' + level + '/' + manual.short)" color="primary" dark>start</v-btn>
                        <v-btn v-if="manual.progress.started && manual.progress.progress != 100" outline @click="goTo('/manual/' + level + '/' + manual.short)" color="primary" dark>continue</v-btn>
                      </v-progress-circular>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                      <v-btn 
                        outline 
                        @click="goTo('/manual/' + level + '/' + manual.short)" 
                        color="primary" 
                        dark>
                        {{ manual.name }} Manual
                      </v-btn>
                  </v-card-actions>
                </v-card> 
              </v-flex>
            </v-layout>
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
     
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  props:{
    uName:{
      required:true
    },
    uEmail:{
      required:true
    },
    isLogin:{
      required:true
    },
    uid:{
      required:true
    },
  },
  data: ()=> ({
      loadedManuals:null,
      manuals:null,
      loading:null,
      level:null,
      modulesDone: 0
  }),
  computed:{
    getManuals() {
      if(!this.loadedManuals) return true
      this.loading = true
      this.manuals = this.loadedManuals.filter(x=> x.level == this.level && this.$store.state.userData && x.language == this.$store.state.userData.language )
      this.loading = false
      return true
    },
    getMaterialAccess(){
      if( this.level == 'basic' ){
        return this.$store.state.userData ? this.$store.state.userData.access.basic.material : false
      } else if( this.level == 'AIMA' ){
        return this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : false
      } else if( this.level == 'AIMA_Supportive' ){
        return this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : false
      } else {
        this.$router.push("/")
      }

    },
    async getMaterialProgress() {
      if (!this.manuals) return 'true'
      let materialProgress = this.$store.state.userData ? this.$store.state.userData.material.basic : null
      if( this.level == 'basic' ){
        materialProgress = this.$store.state.userData ? this.$store.state.userData.material.basic : null
      } else if( this.level == 'AIMA' ){
        materialProgress = this.$store.state.userData ? this.$store.state.userData.material.aimaassessor : null
      } else if( this.level == 'AIMA_Supportive' ){
        materialProgress = this.$store.state.userData ? this.$store.state.userData.material.aimaassessor : null
      }


      if (!materialProgress) return true

      //let mans = await db.ref('candidates/' + this.$store.state.userData.uid + 'material/basic')

      this.manuals.forEach(manual => {
        let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false
        let progress = parseFloat(materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0)
        if (isNaN(progress)) {
          progress = 0
        }

        manual.progress = {
          started,
          progress
        }
      });
      return true
    },
    getOverallProgress () {
      if (!this.manuals) return 'true'
      let materialProgress = this.$store.state.userData ? this.$store.state.userData.material.basic : false
      let progress = 0
      this.manuals.forEach(manual => {
        progress += manual.progress.progress
      });
      return progress / this.manuals.length
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    LoadManuals() {
      this.loading = true
      db.ref('manuals')
      .once('value') 
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        this.loadedManuals = fms
        console.log(this.loadedManuals)
        this.loading = false
      })
      return true
    },
  },
  watch: {
    $route (to, from){
      this.level = this.$route.params.level
    }
  },
  created() {
    let thsLevel = this.$route.params.level
    this.level = this.$route.params.level
    // console.log("DDD===")
    // console.log(this.$store.state.userData.material.basic)
    // console.log(this.$store.state.userData.material.AIMA)
    // console.log(this.$store.state.userData.material.AIMA_Supportive)

    this.LoadManuals()
  }
  
}

</script>