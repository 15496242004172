<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card>
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Add / Edit Manuals</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>

        <v-layout wrap ma-3>
          <v-flex xs12 sm12 px-1 my-1>
            <v-text-field
              label="Manual Code"
              readonly
              v-model="tokenKey"
              solo-inverted
              suffix="Manual Code"
              disabled
              light
              class="tokenField"
              color="white"
              background-color="primary"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 px-1 my-1>
              <v-select
                :items="courseSelect"
                v-model="selectedCourse"
                label="Select a course"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex  xs12 md6 px-1 my-1>
              <v-select
                :items="manualsSelect"
                v-model="selectedManual"
                label="Select a manual"
                solo
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <v-text-field
                label="Course Name"
                v-model="courseName"
                solo
                readonly
                required
                :error-messages="courseErrors"
                @blur="$v.courseName.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <v-text-field
                label="Manual Name"
                v-model="manualName"
                solo
                clearable
                required
                :error-messages="nameErrors"
                @blur="$v.manualName.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <v-text-field
                label="Manual Short Name"
                v-model="manualShort"
                solo
                clearable
                required
                :error-messages="shortErrors"
                @blur="$v.manualShort.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <v-autocomplete
                :items="['ar', 'en'].map(x => x.toUpperCase())"
                v-model="manualLanguage"
                label="Manual Language"
                solo
                required
                :error-messages="languageErrors"
                @blur="$v.manualLanguage.$touch()"
                prepend-inner-icon="public"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <input
                type="file"
                style="display: none"
                ref="file"
                accept="image/*"
                @change="onFilePicked"
              >
              <v-text-field
                label="Manual Cover Image"
                v-model="manualCover"
                solo
                clearable
                required
                prepend-icon="cloud_upload"
                @click:prepend="pickFile('logo')"
                :error-messages="coverErrors"
                @blur="$v.manualCover.$touch()"
              ></v-text-field>
              <v-progress-linear  height="10"  v-if="upload>0 && upload<100" color="green darken-3" v-model="upload"></v-progress-linear>
            </v-flex>
            <v-flex xs6 sm6 px-1 my-1>
              <v-btn color="primary" @click="dialog = !dialog" dark>Add an image</v-btn>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <span v-if="urlsManual && urlsManual.length != 0" md4 pa-2>Drag and Drop to reorder</span>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <draggable ghost-class="ghost" v-model="urlsManual" @start="drag=true" @end="drag=false">
                <v-btn v-for="(url, i) in urlsManual" :key="i" outline color="primary" dark> Image {{ i+1 }}</v-btn>
              </draggable>
            </v-flex>
            <v-flex v-for="(url, i) in urlsManual" class="text-md-center" :key="i"  xs6 md4 pa-2>
              <v-card class="elevation-6">
                <v-img
                  class="white--text"
                  height="auto"
                  :src="url"
                >
                </v-img>
                <span class="primary--text" ma-3>Image {{i+1}}</span>
                <v-btn outline color="error" @click="deleteImage(i)" small dark>delete</v-btn>
              </v-card>
            </v-flex> 
            <v-dialog v-model="dialog" persistent max-width="320">
              <v-card>
                <v-card-title class="headline">Upload a new image</v-card-title>
                <v-card-title class="text-xs-center">
                  <croppa 
                    class="elevation-6"
                    v-model="croppa"
                    accept=".jpg,.png"
                    :width="283"
                    :height="400"
                    :quality="6"
                    :show-loading="true"
                    :show-remove-button="true"
                    :disable-rotation="true"
                    :prevent-white-space="true"
                    :zoom-speed="10"
                    initial-size="cover"
                    initial-position="center"
                  >
                  </croppa>
                </v-card-title>
                <v-card-actions>
                  <v-progress-circular v-if="uploadingLoad" indeterminate color="primary"></v-progress-circular>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" flat @click="dialog = false">cancel</v-btn>
                  <v-btn color="green darken-1" 
                  flat 
                  :loading="uploadingLoad"
                  @click="download()"
                  >upload</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="error" outline :loading="isLoading" :dark="false" @click="deleteCourse()"  large>
                  Delete {{ courseName }} Course
                </v-btn> -->
                <v-btn color="error" outline :loading="isLoading" :dark="false" @click="deleteManual()"  large>
                  Delete {{ manualName }} Manual
                </v-btn>
                <v-btn color="primary" :loading="isLoading" :dark="false" @click="saveManuals()"  large>
                  Save {{ manualName }} Manual
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha, alphaNum } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { Printd } from 'printd'
import { helpers } from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'

export default {
  mixins: [validationMixin],
  props: ['userName'], 
  components: {
    draggable,
  },
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      isLoading:false,
      uploadingLoad:false,
      drag:false,
      dialog:false,
      loading:false,
      tokenKey:null,
      loadedCourseName:null,
      selectedCourse:["basic", "advanced", "AIMA"],
      courseSelect:["basic", "advanced", "AIMA"],
      courseName:["test course"],
      courses:[],
      courseId:null,
      selectedManual:null,
      manuals:[],
      manualsSelect:[],
      manualName:null,
      manualShort:null,
      manualId:null,
      manualLanguage:null,
      manualCover:null,
      urlsManual:[],
      fileUploading:null,
      file:null,
      upload:null,
      croppa:{},
    }
  },
  methods: {
    pickFile (f) {
      this.$refs.file.click ()
      this.fileUploading = f
    },
    loadFile(file){
      var file = file
      var metadata = {
        contentType: 'image/jpeg'
      };
      var uploadTask = storageRef.child('images/' + randomString(8, 'Aa#') + '.jpg').put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.upload = progress
                }, (error) =>{
          console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log('File available at', downloadURL);
          if(this.fileUploading == 'logo') {
            this.manualCover = downloadURL
          }
          this.loading = false
        });
      });
    },
    onFilePicked (e) {
      this.loading = true
			const files = e.target.files
			if(files[0] !== undefined) {
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.loadFile(this.file)
				})
			} else {
				this.name = null
				this.fule = null
				this.uploadURL = null
			}
    },
    download(type, compressionRate) {
      // this.$v.$touch()
      // if (this.$v.$invalid) return
      this.uploadingLoad = true
      this.croppa.generateBlob((blob) => {
        var file = blob
        var metadata = {
          contentType: 'image/jpeg'
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('manuals/' + this.manualShort +'-' + randomString(8, 'aA#') + '.jpg').put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot)=> {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.upload = progress
                  }, (error) =>{

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;

            case 'storage/canceled':
              // User canceled the upload
              break;


            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },() => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
            console.log('File available at', downloadURL);
            if(!this.urlsManual) this.urlsManual = []
            this.urlsManual.push(downloadURL)
            this.uploadingLoad = false
            this.dialog = false

          });
        });
      }, type, compressionRate)
    },
    loadManual(){
      if(this.$route.params.token){
        this.tokenKey = this.$route.params.token
        db.ref('manuals/' + this.tokenKey)
        .once('value')
        .then(snap=>{
          
        })
      } else {
        this.tokenKey = `IAIDL_M_${randomString(8, 'aA#')}`
      }
    },
    getSelectedManual(){
      console.log(this.selectedManual)
    },
    getCourses(){
      this.loading = true
      db.ref('manuals')
      .once('value')
      .then(snap=>{
        console.log(snap.val())
        this.courses = []

        let mS = []
        let id = 0
        for (const key in snap.val()) {
          if (snap.val().hasOwnProperty(key)) {
            const course = snap.val()[key];
            mS.push({
              text:course.level,
              value:course.level,
              id
            })
            id++
            course.key = key
            this.courses.push(course)
          }
        }
        this.courseSelect = mS
        this.courseId = this.courseSelect.length
        this.loadManual()
        this.loading = false
      })
    },
    deleteImage(i) {
      this.urlsManual.splice(i, 1)
    },
    // deleteCourse(){
    //   this.$v.$touch()
    //   if (this.$v.$invalid) return
    //   this.isLoading = true
    //   if (this.loadedCourseName !== this.courseName) {
    //     db.ref('manuals/').set({[this.loadedCourseName]:null})
    //     .then(x=>{
    //       this.isLoading = false
    //       this.goTo('/manuals-overview')
    //     })
    //   } else {
    //     db.ref('manuals/').set({[this.courseName]:null})
    //     .then(x=>{
    //       this.goTo('/manuals-overview')
    //     })
    //   }
    // },
    // moveCourse() {
    //   this.$v.$touch()
    //   if (this.$v.$invalid) return
    //   this.isLoading = true
    //   if (this.loadedCourseName !== this.courseName) {
    //     db.ref('manuals/').set({[this.loadedCourseName]:null})
    //     .then(x=>{
    //       this.isLoading = false
    //       this.goTo('/manuals-overview')
    //     })
    //   }
    // },
    deleteManual(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isLoading = true
      this.manuals.splice(this.manualId, 1)
      console.log(this.manuals)
      let x = {}
      // db.ref('manuals').set({x:'x'})
      //this.moveCourse()
      db.ref('manuals').update({
        [this.tokenKey]:null
      })
      .then(x=>{
        this.isLoading = false
        this.goTo('/manuals-overview')
      })
    },
    saveManuals(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      let newManual = {
        name:this.manualName,
        short:this.manualShort,
        language:this.manualLanguage,
        cover:this.manualCover,
        level:this.courseName,
        urls:this.urlsManual,
        pages:this.urlsManual.length,
        filter:`${this.courseName}_${this.manualShort}_${this.manualLanguage}`
      }
      this.manuals.splice(this.manualId, 1, newManual)
      db.ref('manuals').update({
        [this.tokenKey]:newManual
      })
      .then(x=>{
        this.goTo('/manuals-overview/' + this.selectedCourse.value)
      })
    },
    goTo(path) {
      this.$router.push(path)
    }
  },
  watch: {
    selectedCourse(){
      this.courseName = this.selectedCourse.value
      this.loadedCourseName = this.selectedCourse.value
      this.manuals = this.courses.filter(x=> x.level = this.selectedCourse.value)
      console.log(this.manuals)
      let mS = []
      let id = 0
      this.manuals.forEach(m=>{
        mS.push({
          text:m.name,
          value:m.short,
          id
        })
        id++
      })
      this.manualsSelect = mS
      this.manualId = this.manuals.length
    },
    selectedManual(){
      let s = this.manuals.filter(manual=> manual.short == this.selectedManual.value)
      console.log(s)
      this.manualName = s[0].name
      this.manualShort = s[0].short
      this.urlsManual = s[0].urls
      this.manualLanguage = s[0].language
      this.manualCover = s[0].cover
      this.manualId = this.selectedManual.id
      this.tokenKey = s[0].key
    }
  },
  computed: {
    shortErrors(){
      const errors = []
      if (!this.$v.manualShort.$dirty) return errors
      !this.$v.manualShort.alpha && errors.push('Short Name should only contain letters and numbers')
      !this.$v.manualShort.minLength && errors.push('Short Name must be at least 2 characters long')
      !this.$v.manualShort.maxLength && errors.push('Short Name must be not longer than 20 characters')
      !this.$v.manualShort.required && errors.push('Short Name is required')
      return errors
    },
    nameErrors () {
      const errors = []
      if (!this.$v.manualName.$dirty) return errors
      !this.$v.manualName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.manualName.required && errors.push('Name is required')
      return errors
    },
    courseErrors () {
      const errors = []
      if (!this.$v.courseName.$dirty) return errors
      !this.$v.courseName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.courseName.required && errors.push('Name is required')
      return errors
    },
    coverErrors () {
      const errors = []
      if (!this.$v.manualLanguage.$dirty) return errors
      !this.$v.manualLanguage.required && errors.push('Cover is required')
      return errors
    },
    languageErrors () {
      const errors = []
      if (!this.$v.manualCover.$dirty) return errors
      !this.$v.manualCover.required && errors.push('Language is required')
      return errors
    },
  },
  validations: {
    manualName: {
      required,
      minLength: minLength(4),
    },
    courseName: {
      required,
      minLength: minLength(4),
    },
    manualShort:{
      required,
      alpha,
      minLength: minLength(2),
      maxLength:maxLength(20)
    },
    manualLanguage: {
      required,
    },
    manualCover: {
      required,
    },
  },
  created() {
    // db.ref('manuals').update(this.$store.state.manuals)
    //db.ref('manuals/basic').set(this.$store.state.manuals)
    this.getCourses()
    // db.ref('manuals')
    // .once('value')
    // .then(x=>{
    //   db.ref('manual').update(x.val())
    // })
  }
}
</script>

