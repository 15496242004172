import Vue from 'vue'
import Vuex from 'vuex'
import {firebase, db, auth} from '@/fb'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    manuals: [],
    aimamanuals: [],
    menu: {
      candidate:[
        { icon: 'dashboard', text: 'Dashboard', path:'/' },
        { icon: 'person', text: 'My Account', path:'/account' },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Material',
          model: false,
          children: [
            { icon: 'view_agenda', text: 'Basic Learning Materials', path:'/manuals/basic' },
            { icon: 'view_agenda', text: 'AIMA Assessor Materials', path:'/manuals/AIMA' },
            { icon: 'view_agenda', text: 'AIMA Supportive Materials', path:'/manuals/AIMA_Supportive' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'AIMA Test Report',
          model: false,
          children: [
            { icon: 'pages', text: 'Add AIMA Reports', path:'/aima-candidate' },
            { icon: 'assignment', text: 'My AIMA Report', path:'/aima-result-candidate' },

          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Exams',
          model: false,
          children: [
            { icon: 'assessment', text: 'Pre-Assessment', path:'/exam/preassessment/basic/exam' },
            { icon: 'assignment', text: 'Certification', path:'/exam/exam/basic/exam' },
          ]
        },
        { icon: 'tab', text: 'Project', path:'/submit-project' },
        { icon: 'view_headline', text: 'References', path:'/references' },
        { icon: 'video_library', text: 'Tutorials' , path:'/tutorials-candidates' },
        { icon: 'chat_bubble', text: 'Send feedback' , path:'/feedback' },
        // { icon: 'help', text: 'Help' },
      ],
      aimaview:[
        { icon: 'dashboard', text: 'Dashboard', path:'/' },
        { icon: 'pages', text: 'AIMA Reports', path:'/aima-user-report' },
      ],
      ///////// CENTER MENU /////////////
      center: [
        { icon: 'dashboard', text: 'Dashboard', path:'/' },
        { icon: 'work', text: 'Corporate Account', path:'/corporate-account' },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Corporate Financials',
          model: false,
          children: [
            { icon: 'list', text: 'Invoices List', path:'/invoice-report' }
          ]
        },

        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Courses Manuals',
          model: false,
          children: [
            { icon: 'view_agenda', text: 'Basic Guided Learning Materials', path:'/manuals-overview/basic' },
            { icon: 'view_agenda', text: 'Advanced  Guided Learning Materials', path:'/manuals-overview/advanced' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Candidates',
          model: false,
          children: [
            { icon: 'add', text: 'Register a candidate', path:'/register-candidate' },
            { icon: 'add', text: 'Transfer-in a candidate', path:'/transfer-candidate' },
            { icon: 'assignment', text: 'Report', path:'/report' },
            { icon: 'shopping_cart', text: 'Order Tokens', path:'/order-tokens' },
            

          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Trainers',
          model: false,
          children: [
            { icon: 'add', text: 'Trainer', path:'/trainer' },
            { icon: 'assignment', text: 'Approval Report', path:'/trainer-report' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Exams',
          model: false,
          children: [
            { icon: 'add', text: 'Sessions', path:'/exam-session' },
            { icon: 'assignment', text: 'Approval Report', path:'/exam-session-report' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'AI Lab Accreditation',
          model: false,
          children: [
            { icon: 'add', text: 'New Application', path:'/lab-accreditation' },
            { icon: 'assignment', text: 'Lab Applications', path:'/lab-applications' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Product Accreditation',
          model: false,
          children: [
            { icon: 'add', text: 'New Application', path:'/product-accreditation' },
            { icon: 'assignment', text: 'Product Applications', path:'/product-applications' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Center Presentations',
          model: false,
          children: [
            { icon: 'present_to_all', text: 'Submit a presentation', path:'/presentation' },
            { icon: 'assignment', text: 'Approval Report', path:'/presentations-report' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'AIMA',
          model: false,
          children: [
            { icon: 'pages', text: 'New AIMA', path:'/aima' },
            { icon: 'assignment', text: 'Past AIMA Reports', path:'/aima-reports' },
            { icon: 'add', text: 'Add Aima View User', path:'/aima-new-user' },
            { icon: 'list', text: 'Aima Users list', path:'/aima-users-list' },
          ]
        },
        // { icon: 'settings', text: 'Settings', path:'/settings' },
        { icon: 'subject', text: 'Submit a news article', path:'/news' },
        { icon: 'picture_as_pdf', text: 'References', path:'/references' },
        { icon: 'cloud_done', text: 'Assets', path:'/assets' },
        { icon: 'video_library', text: 'Tutorials' , path:'/tutorials' },
        { icon: 'chat_bubble', text: 'Send feedback' , path:'/feedback' },
        //{ icon: 'help', text: 'Help', path:'/help' },
      ],
      ///////// admin MENU /////////////
      admin: [
        { icon: 'dashboard', text: 'Dashboard', path:'/' },
        { icon: 'work', text: 'Corporate Account', path:'/corporate-account' },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Corporate Financials',
          model: false,
          children: [
            { icon: 'list', text: 'Invoices List', path:'/invoice-report-all' }
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Courses Manuals',
          model: false,
          children: [
            { icon: 'edit', text: 'Edit Manuals', path:'/edit-manuals' },
            { icon: 'view_agenda', text: 'Basic Manuals Overview', path:'/manuals-overview/basic' },
            { icon: 'view_agenda', text: 'Advanced Manuals Overview', path:'/manuals-overview/advanced' },
            { icon: 'view_agenda', text: 'AIMA Manuals Overview', path:'/manuals-overview/AIMA' },
            { icon: 'view_agenda', text: 'AIMA Supportive Manuals Overview', path:'/manuals-overview/AIMA_Supportive' },
            { icon: 'view_headline', text: 'References', path:'/references' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Tutorials',
          model: false,
          children: [
            { icon: 'video_library', text: 'Students Tutorials' , path:'/tutorials-candidates' },
            { icon: 'video_library', text: 'Centers Tutorials' , path:'/tutorials' },
            { icon: 'add', text: 'New Center Tutorial', path:'/register-center-tutorials' },
            { icon: 'add', text: 'New Candidate Tutorial', path:'/register-candidate-tutorials' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Assests',
          model: false,
          children: [
            { icon: 'cloud_done', text: 'Assets List', path:'/assets' },
            { icon: 'add', text: 'New Asset', path:'/register-asset' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'References',
          model: false,
          children: [
            { icon: 'view_headline', text: 'References', path:'/references' },
            { icon: 'add', text: 'New Reference', path:'/register-ref' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Announcments',
          model: false,
          children: [
            { icon: 'add', text: 'New Announcment', path:'/register-announcments' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Exams Questions',
          model: false,
          children: [
            { icon: 'add', text: 'Add Questions', path:'/edit-questions' },
            { icon: 'edit', text: 'Edit Questions', path:'/edit-questions' },
            { icon: 'view_agenda', text: 'Questions Overview', path:'/questions-overview' },
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Clients',
          model: false,
          children: [
            { icon: 'person', text: 'Candidates', path:'/candidates-all-centers' },
            { icon: 'arrow_right', text: 'Candidates Transfers', path:'/candidate-transfers' },
            { icon: 'add', text: 'Register a center', path:'/register-center' },
            { icon: 'work', text: 'Corporates', path:'/corporatereport' },
            { icon: 'work', text: 'Token Orders', path:'/token-orders' },
            { icon: 'person', text: 'Trainers', path:'/trainers-review-report' },
            { icon: 'assignment', text: 'Exam Sessions', path:'/exam-session-approval' },
            { icon: 'present_to_all', text: 'Presentations', path:'/presentations-approval' },
            { icon: 'present_to_all', text: 'Lab Applications', path:'/lab-applications-admin' },
            { icon: 'present_to_all', text: 'Product Applications', path:'/product-applications-admin' },
            { icon: 'list', text: 'Aima Users list', path:'/aima-users-list-admin' },

          ]
        },
        { icon: 'settings', text: 'Settings', path:'/settings'  },
        { icon: 'chat_bubble', text: 'Send feedback' },
        { icon: 'help', text: 'Help' },
      ],
    },
    preassessment:{
      questionsNumber:10,
      questions:[
        {
          number:1,
          text: 'Question1?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:2,
          text: 'Question2?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:3,
          text: 'Question3?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:4,
          text: 'Question4?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:5,
          text: 'Question5?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:6,
          text: 'Question6?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:7,
          text: 'Question7?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:8,
          text: 'Question8?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:9,
          text: 'Question9?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:10,
          text: 'Question10?',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        }
      ]
    },
    exam:{
      questionsNumber:10,
      questions:[
        {
          number:1,
          text: 'Question1? 2',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:2,
          text: 'Question2? 3',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:3,
          text: 'Question3? 1',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:4,
          text: 'Question4? 1',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:5,
          text: 'Question5? 2',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:6,
          text: 'Question6? 2',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:7,
          text: 'Question7? 3',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:8,
          text: 'Question8? 2',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:9,
          text: 'Question9? 1',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        },
        {
          number:10,
          text: 'Question10? 5',
          answers: [
            {number: 1, text:'Answer 1'},
            {number: 2, text:'Answer 2'},
            {number: 3, text:'Answer 3'},
            {number: 4, text:'Answer 4'},
            {number: 5, text:'Answer 5'},
          ]
        }
      ]
    },
    
    role:null,
    user:null,
    candidates:[],
    centerData:null,
    userData:null,
    tokens:null,
    triggerStore: false,
    triggerStoree: false
  },
  getters: {
    
  },
  mutations: {
      
  },
  actions: {
    
  },
})