<template>
  <div>
    <v-card  class="mb-0">
      <v-img
        class="white--text"
        height="100px"
        src="https://portal.iaidl.org/tback.jpg"
      >
        <v-container fill-height fluid>
          <v-layout fill-height style="flex-wrap: wrap;">
            <v-flex xs12 lg12 align-end flexbox class="text-xs-center">
              <span class="font-weight-black headline">AIMA</span>
              <span><sup>®</sup></span>
            </v-flex>
            <br>
            <v-flex xs12 lg12 align-end flexbox class="text-xs-center" style="display: block;">
              <span>You Can Generate AIMA Report As a Candidate Once</span>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>
      <v-layout wrap>
        <v-flex xs12 sm12 px-4 mt-4>
          <v-text-field
            label="Token"
            readonly
            v-model="token"
            solo-inverted
            suffix="Token"
            :loading="tokenLoading"
            disabled
            light
            class="tokenField"
            color="white"
            background-color="primary"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 px-4>
          <v-text-field
            label="Name of the Company / Organization"
            v-model="nameCorporate"
            outline
            clearable
            @blur="updateValueAIMA(nameCorporate)"
            required
            prepend-inner-icon="account_balance"
            :disabled="loadedAimaApp"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 mb-3 class="text-xs-center" v-if="!loadedAimaApp">
          <v-btn color="primary" @click="bookandStart()" large dark>Book token and start application</v-btn>
        </v-flex>
        <v-flex xs12 v-if="loadedAimaApp">
          <v-stepper v-model="stepperValue">
            <v-stepper-header>
              <v-stepper-step step="1" :complete="stepperValue > 1">Organisation Details</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" :complete="stepperValue > 2">AIMA</v-stepper-step>
              
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-layout row wrap>
                  <v-flex xs12 px-1>
                    <v-text-field
                      label="Name of the Division"
                      v-model="nameDivision"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 px-1 my-1>
                    <v-autocomplete
                      :items="countries"
                      item-text="name"
                      v-model="country"
                      label="Location of Company / Division"
                      outline
                      prepend-inner-icon="public"
                      :disabled="noToken"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4 px-1>
                    <v-text-field
                      label="Annual Revenue"
                      v-model="revenue"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 px-1>
                    <v-text-field
                      label="Number of Employees"
                      v-model="numberEmployees"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 px-1>
                    <v-autocomplete
                      :items="industries"
                      v-model="industry"
                      label="Industry"
                      outline
                      clearable
                      item-text="Industry"
                      item-value="Industry"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 px-1>
                    <v-text-field
                      label="Markets"
                      v-model="markets"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 px-1>
                    <v-text-field
                      label="Major Brands Owned by Company / Division"
                      v-model="brands"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 px-1>
                    <v-text-field
                      label="Initially believed level of Innovation Maturity  —  Originator / Champion / Leader"
                      v-model="initialLevel"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 px-1>
                    <v-text-field
                      label="Does the organization lean more toward 'traditional' or 'progressive' in its approach to the market?"
                      v-model="organisationType"
                      outline
                      clearable
                      required
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 px-1>
                    <v-text-field
                      v-model="image"
                      label="Logo"
                      outline
                      disabled
                      :loading="uploadProgress"
                      append-outer-icon="cloud_upload"
                      @click:append-outer="$refs.file.click()"
                    ></v-text-field>
                    <img v-if="image" :src="image" width="300" alt="">
                    <input
                      type="file"
                      style="display: none"
                      ref="file"
                      accept="csv/*"
                      @change="onFilePicked"
                    >
                  </v-flex>
                  <v-flex xs6 px-1>
                    <v-btn outline color="primary" @click="$refs.file.click()" large dark>upload logo</v-btn>
                  </v-flex>

                  <v-flex xs12 sm12 px-1 pb-3>
                    <span class="title">Contact Person</span>
                  </v-flex>
                  <v-flex xs4 sm2 px-1>
                    <v-autocomplete
                      :items="titles"
                      item-text="name"
                      v-model="title"
                      label="Title"
                      outline
                      prepend-inner-icon="turned_in"
                      :disabled="noToken"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs8 sm4 px-1>
                    <v-text-field
                      label="Full Name"
                      v-model="contactName"
                      outline
                      clearable
                      required
                      prepend-inner-icon="person"
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 px-1>
                    <v-text-field
                      label="Email"
                      v-model="contactEmail"
                      outline
                      clearable
                      required
                      hint="Please enter a valid email"
                      prepend-inner-icon="email"
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 px-1>
                    <v-text-field
                      label="Phone Number"
                      v-model="contactPhone"
                      outline
                      clearable
                      required
                      hint="Please use following format 00491728319076"
                      prepend-inner-icon="phone"
                      :disabled="noToken"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click.native="submit()">Continue</v-btn>
                </v-card-actions>
              </v-stepper-content>

              

              <v-stepper-content step="2">
                <v-layout row wrap>
                  <v-flex xs12 class="text-xs-center" py-5>
                    <span style="font-text:22px;font-weight:900;">Transformation Elements</span>
                  </v-flex>
                  <v-flex xs12 md3 pa-2 v-for="cat in aimaCats" :key="cat.text">
                    <v-card>
                      <v-card-title primary-title class="text-xs-center" style="font-text:14px;font-weight:900;">
                        {{cat.text.replace(/_/g, ' ').toUpperCase()}}
                      </v-card-title>
                      <div class="justify-center row layout ma-0">
                        <v-progress-circular
                          :size="80"
                          :width="10"
                          :rotate="-90"
                          :value="(cat.done/cat.total*100).toFixed(1)"
                          color="primary"
                        >
                          <div 
                            class="text-md-center" 
                            text-color="primary">
                            {{ (cat.done/cat.total*100).toFixed(0) }}</div>
                        </v-progress-circular>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" small flat @click="goTo(`/aima-cat-candidate/${token}/${cat.text}`)">{{ cat.text.replace(/_/g, ' ')}}</v-btn>
                      </v-card-actions>
                    </v-card>

                    
                  </v-flex>
                  <v-flex xs12>
                    <v-btn color="success" block large @click="generateAimaResult()">Generate AIMA Result</v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn color="primary" block large @click="goTo(`/aima-result/${token}`)">AIMA REPORT</v-btn>
                  </v-flex>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">No available Tokens, Order?</v-card-title>
          <v-card-text>You have no available AIMA tokens, do you want to place an order now?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" flat @click="dialog = false">Later</v-btn>
            <v-btn color="green darken-1" flat @click="dialog = false">Place an Order</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import industries from '@/industries'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { Printd } from 'printd'
import axios from 'axios';



export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      titles:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      nameCorporate:null,
      nameDivision:null,
      country:null,
      revenue:null,
      numberEmployees:null,
      industry:null,
      markets:null,
      brands:null,
      initialLevel:null,
      organisationType:null,
      title:null,
      contactName:null,
      contactEmail:null,
      contactPhone:null,
      tokenLoading:false,
      stepperValue:null,
      aima:[],
      aimaAns:[],
      aimaCats:[],
      image:"defaultAima.jpg",
      uploadProgress:null,
      file:null,
      loadedAimaApp:false,
      token:'testSingle_'+auth.currentUser.uid,
      industries:industries,
      tokenKey:null,
      aimah:[],
    }
  },
  methods: {
    updateValueAIMA(g, i, k, v) {
      // console.log(g)
      // console.log(i)
      // console.log(k)
      // console.log(v)
    },
    async getAimaCats(){
      let fcats = {}
      let cats = await db.ref(`settings/aimaCategories`).once('value').then(x=>x.val());
      await (async x => {
        for (let i = 0; i < cats.length; i++) {
          const cat = cats[i];
          fcats[cat] = {text: cat}
          let oAIMA = await db.ref(`aima/${this.token}/aima/${cat}`).once('value').then(x => x.val())
          let aimacat = []
          if (oAIMA) {
            for (let i = 0; i < oAIMA.length; i++) {
              const o = oAIMA[i];
              for (const k in o) {
                if (o.hasOwnProperty(k)) {
                  const val = o[k];
                  if (!aimacat[i]) aimacat[i] = {}
                  aimacat[i][k] = val
                }
              }
            }
            fcats[cat].total = this.aimah[cat].length
            fcats[cat].done = aimacat.filter(x => x.rating).length

            // console.log(aimacat)
            // console.log(aimacat.length)
            // console.log(aimacat.filter(x => x.rating).length)
          } else {
            fcats[cat].total = this.aimah[cat].length
            fcats[cat].done = 0
          }
        }
        // console.log('fcats', fcats)
        // console.log(this.aimah)
        this.aimaCats = fcats
      })()
    },
    validate(c) {
      let v = true
      for (const k in c) {
        if (c.hasOwnProperty(k)) {
          const val = c[k];
          if(!val) {
            this.$emit('snackbarNotification', `${k.toUpperCase().replace(/_/g, ' ')} is registered successfully`, 'error')
            v = false
          }
        }
      }
      return v
    },
    async submit() {
      // console.log('submit!')
      let c = {
        division:this.nameDivision,
        country:this.country,
        revenue:this.revenue,
        title:this.title,
        logo:this.image,
        number_employees:this.numberEmployees,
        industry:this.industry,
        markets:this.markets,
        brands:this.brands,
        initial_level:this.initialLevel,
        organisation_type:this.organisationType,
        contact_name:this.contactName,
        contact_email:this.contactEmail,
        contact_phone:this.contactPhone,
      }
      if (!this.validate(c)) {
        return
      }
      await db.ref(`aima/${this.token}`).update(c)
      this.stepperValue = 2
    },
    async bookandStart(){
      let c = {
        company:this.nameCorporate,
        token:this.token,
        date:this.date
      }

      await db.ref(`aima/${this.token}`).push(c)
      await db.ref(`candidates/${auth.currentUser.uid}/aima/${this.token}`).update(c)
      // await db.ref(`candidates/${auth.currentUser.uid}/tokens/${this.tokenKey}`).update({
      //   expired:true
      // })
      this.loadedAimaApp = true
      this.goTo(`/aima-candidate/${this.token}`)
    },
    goTo(path) {
      this.$router.push(path)
		},
    loadFile(filer, type, ext){
      let file = filer
      let metadata = {
        contentType: type
      };
      let uploadTask = storageRef.child('images/cf' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.uploadProgress = true
                }, (error) =>{
          console.log(error);
        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log('File available at', downloadURL);
          this.image = downloadURL
					this.loading = false
					this.uploadProgress = false
        });
      });
    },
    onFilePicked (e) {
      this.loading = true
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          // console.log(this.file)
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } else {
        this.name = null
        this.fileName = null
      }
		},
    async getToken() {
      // console.log('get token')
      // auth.onAuthStateChanged(async u=>{
      //   let uid = u.uid
      //   let tokens = await db.ref(`centers/${uid}/tokens`).once('value').then(x=>x.val())
      //   console.log(tokens)
      //   let v = Object.values(tokens).map((x, i)=>{return {...x, key:Object.keys(tokens)[i]}}).filter(token=>!token.expired && token.type == 'aima')
      //   let vtoken = v[0]
      //   console.log(vtoken)
      //   if (!vtoken) {
      //     this.noToken = true
      //     this.tokenLoading = false
      //     this.dialog = true
      //     return 'NO AVAILABLE TOKENS'
      //   }
      //   this.token = vtoken.code
      //   this.tokenKey = vtoken.key
      //   this.tokenLoading = false
      // })
    },
    async loadAIMA(token){
      this.tokenLoading = false
      this.loadedAimaApp = true
      let oldAima = await db.ref(`aima/${token}`).once('value').then(x=>x.val())
      this.nameCorporate = oldAima.company
      this.nameDivision = oldAima.division
      this.country = oldAima.country
      this.revenue = oldAima.revenue
      this.title = oldAima.title
      this.image = oldAima.logo
      this.numberEmployees = oldAima.number_employees
      this.industry = oldAima.industry
      this.markets = oldAima.markets
      this.brands = oldAima.brands
      this.initialLevel = oldAima.initial_level
      this.organisationType = oldAima.organisation_type
      this.title = oldAima.title
      this.contactName = oldAima.contact_name
      this.contactEmail = oldAima.contact_email
      this.contactPhone = oldAima.contact_phone
    },
    async generateAimaResult(){
      let m = await axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/aima?token=${this.token}`).then(x=>{
        // console.log(x.data)
        this.goTo(`/aima-result-candidate/${this.token}`)
      })
    }

  },
  watch: {
  },
  computed: {
  },
  async created() {





    //check if this report generated or noasync
    let inputer = await db.ref(`aima/${this.token}`).once('value', function(snapshot) {
      if (snapshot.hasChild("aima")) {
        console.log("have REP")
        this.goTo('aima-result-candidate')
      } else {
        console.log("have NOOOOOOO REP")
      }})

    if(this.$route.params.token) {
      this.token = this.$route.params.token
      console.log(this.token)
      this.loadAIMA(this.token)

		} else {
      this.getToken()
    }


    let thsA

    //check if user has access
    let thsAccess =  db.ref('candidates/' + this.$store.uid + 'access/AIMA/material').once('value', function(sn){

      console.log("dDDDDD=================")
      let thsA = sn.val()

      if(thsA == true){
        console.log("TRRR=================")
      } else {
        console.log("FFFFFFFFFFFFFFF=================")

        goToDash()
      }
    })

    console.log(thsA)



    this.getAimaCats()
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    })

    let aimaO = [
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsStrategy definition, alignment, and ownership.",
        "ITEMNO": 1,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What concisely defined Ai and Future Tools Strategy has been developed and is in place?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 9,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Definition / Ownership"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsStrategy definition, alignment, and ownership.",
        "ITEMNO": 2,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "At what level in the organization is the Ai and Future Tools Strategy being created/owned and reviewed? Who is this person? CTO?CEO?EVPs?",
        "METHOD\n OF\n ASSESSMENT": "Leadership Interview",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Definition / Ownership"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Alignment",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsStrategy definition, alignment, and ownership.",
        "ITEMNO": 3,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is the Ai and Future Tools Strategy aligned with the broader Corporate Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Definition / Ownership"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Communication",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Broad communication of, stakeholder alignment with, and provision of necessary resources for, executing the Ai and Future ToolsStrategy throughout the organization.",
        "ITEMNO": 4,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, has the Ai and Future Tools Strategy been communicated broadly to leaders throughout the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Communication / Use"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Broad communication of, stakeholder alignment with, and provision of necessary resources for, executing the Ai and Future ToolsStrategy throughout the organization.",
        "ITEMNO": 5,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the stakeholders in the internal ecosystem aligned with the Ai and Future Tools Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Resources",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Broad communication of, stakeholder alignment with, and provision of necessary resources for, executing the Ai and Future ToolsStrategy throughout the organization.",
        "ITEMNO": 6,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the stakeholders in the internal ecosystem being provided with the resources they need to pursue the Ai and Future Tools Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Planning",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Frequency at which Strategies are revisited and refined in keeping with market shifts.",
        "ITEMNO": 7,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "How often is the broader Corporate Strategy revisited and refined in keeping with market evolution?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Planning"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Planning",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Frequency at which Strategies arerevisitedandrefinedin keeping with market shifts.",
        "ITEMNO": 8,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "How oftenis the broaderCorporate Strategy revisitedandrefinedin keeping withmarket evolution?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Planning",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which organization is usinganagile/responsiveapproach tostrategic planningand is constantly monitoring forstructural changesin its markets.",
        "ITEMNO": 9,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the organization using an agile / responsive approach to strategic planning?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Planning",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which organization is usinganagile/responsiveapproach tostrategic planningand is constantly monitoring forstructural changesin its markets.",
        "ITEMNO": 10,
        "READINESS": "X",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the organization monitoring for structural changes in its markets? What \"early warning\" mechanisms are in place?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 11,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What focused 'X-Works' ( A-F team projects ) type Ai and Future Tools efforts does the defined Ai and Future Tools Strategy involve?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Vehicles - Presnce"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 12,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What external partnerships and Open Ai and Future Tools efforts does the defined Ai and Future Tools Strategy involve?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 13,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What investment transformation decision such as Corporate Venturing or M&A does the defined Ai and Future Tools Strategy involve?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 14,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated Continuous Improvement Group does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 15,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated Centre of Excellence does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 16,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated R&D Group does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 17,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated Group does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 18,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated X-Works (A-F team projects) AI and Future Tools Group does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 19,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated Partnership Development Office does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 20,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What dedicated Corporate Development Office does the organization have? How many?",
        "METHOD\n OF\n ASSESSMENT": "Organizational Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 9,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 21,
        "READINESS": "",
        "TIME FRAME": "Now",
        "QUESTION": "What portion of the Continuous Improvement Group's work relates to Sustaining Ai and Future Tools vs Margin Optimization?",
        "METHOD\n OF\n ASSESSMENT": "CI Group Project Records",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Vehicles - Use"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 22,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the organization's Centre of Excellence succeeding at assimilating and maturing new technologies?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 23,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the organization's dedicated R&D Group's work being led by Technology Managers using Technology Roadmaps?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Operations Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 9,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 24,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the organization's dedicated NPD Group's work being led by Product Managers using Product Roadmaps?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Operations Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 25,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the organization's dedicated X-Works Ai and Future Tools Group's work being led from a coherent Ai and Future Tools Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Operations Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 26,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the organization's dedicated Partnership Development Office focused on finding new partner capabilities to help the organization pursue new innovations?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Operations Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The organization's use of dedicated centers of innovation.",
        "ITEMNO": 27,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the organization's dedicated Corporate Development Office focused on finding new M&A opportunities to help the organization pursue new innovations?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Operations Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Pathways",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pursuit of new Value CaptureandValue Creation pathways-\n [ Perpetuation / Penetration / Broadening ]versus[ Escalation / Expansion / Creation ].",
        "ITEMNO": 28,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the organization pursuing clear new Value Capture pathways (Perpetuation / Penetration / Broadening)?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Pathways"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Pathways",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pursuit of new Value CaptureandValue Creation pathways-\n [ Perpetuation / Penetration / Broadening ]versus[ Escalation / Expansion / Creation ].",
        "ITEMNO": 29,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the organization pursuing clear new Value Creation pathways (Escalation / Expansion / Creation)?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Portfolio",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsStrategy's portfolio mix (of H1 / H2 / H3 initiatives) and balance with respect to the intended corporate strategy / Ai and Future ToolsStrategy / portfolio mix.",
        "ITEMNO": 30,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What portfolio mix of H1 / H2 / H3 initiatives does the defined Ai and Future Tools Strategy involve?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 9,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Portfolio"
      },
      {
        "GROUP": "STRATEGY",
        "CAT\n NO": 1,
        "Transformation\n ELEMENT": "Strategy",
        "WT": 14,
        "Transformation\n SUB-ELEMENT": "Portfolio",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsStrategy's portfolio mix (of H1 / H2 / H3 initiatives) and balance with respect to the intended corporate strategy / Ai and Future ToolsStrategy / portfolio mix.",
        "ITEMNO": 31,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the organization's portfolio mix appropriately balanced in accordance with its intended Ai and Future Tools Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Sponsorship",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 32,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "At what level in the organization is the Ai and Future management Tools initiatives being \"owned\" (sponsored / endorsed / promoted)? Who is this person?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Sponsorship & Leadership - Presence"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 33,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "At what level in the organization is the Ai and Future management Tools initiatives being led, and to whom does the position report? How far removed is it from the BoD? Who is this person?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Sponsorship",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 34,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What is the Board of Directors' position on, and involvement in, the Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Strategy Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Sponsorship",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 35,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What mandate for Ai and Future Tools has the organization's top leadership clearly defined?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Sponsorship",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 36,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What pledge of support for the Ai and Future management Tools initiatives and the people carrying it out has the organization's top leadership openly made?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive stated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 37,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What level of support and buy-in exists  from the other executive leaders and business unit leaders?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Mandate",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive demonstrated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 38,
        "READINESS": "",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, has the organization's top leadership issued its mandate for Ai and Future Tools and communicated its Cascading throughout the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "S & L - Use"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 2,
        "Transformation\n ELEMENT": "Sponsorship & Leadership",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Support",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Board & Executive demonstrated sponsorship / leadership of the Ai and Future ToolsProgram across the organization.",
        "ITEMNO": 39,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization's top leadership demonstrated its support for the Transformation and the people carrying it out?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 9,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Resilience",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Statedorganizationalbeliefsregarding innovation.",
        "ITEMNO": 40,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What are the stated organizational beliefs regarding the need for incremental / breakthrough / disruptive / transformative Ai and Future Tools for H1 / H2 / H3?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Philosophies & Beliefs - Presence"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Stated organizational beliefs regarding innovation.",
        "ITEMNO": 41,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What are the stated organizational beliefs regarding Ai and Future Tools responsibility throughout the organization (i.e. who's job is it?)?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Approach",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Statedorganizationalbeliefsregarding innovation.",
        "ITEMNO": 42,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What are the stated organizational beliefs regarding how to pursue Ai and Future Tools(team size and make up)?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Resilience",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Demonstratedorganizationalbeliefsregarding innovation.",
        "ITEMNO": 43,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What are the demonstrated organizational beliefs regarding the need for incremental /transformative Ai and Future Tools for H1 / H2 / H3?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Philosophies & Beliefs - Use"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Demonstrated organizational beliefs regarding innovation.",
        "ITEMNO": 44,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What are the demonstrated organizational beliefs regarding Ai and Future Tools responsibility throughout the organization (i.e. 'who's job is it?')?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 3,
        "Transformation\n ELEMENT": "Philosophy / Beliefs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Approach",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Demonstratedorganizationalbeliefsregarding innovation.",
        "ITEMNO": 45,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What are the demonstrated organizational beliefs regarding how to pursue Ai and Future Tools (team size and make up)?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relevance",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organization's embraceofrelevance,resilience,andmarketleadership.",
        "ITEMNO": 46,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways has the organization embraced the concept of \"relevance\"-of accepting constant change and the need to perpetually AI renew?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Values - Presence"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Resilience",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organization's embraceofrelevance,resilience,andmarketleadership.",
        "ITEMNO": 47,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways are the organization's values ones that will compel it to strive toward H3 investments so that it will remain resilient for the long-term?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Market Leadership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organization's embraceofrelevance,resilience,andmarketleadership.",
        "ITEMNO": 48,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What do the organization's values reflect in regards to its attitude toward market leadership? Is market leadership a priority?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relevance",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which the organization has put its values into practice to embrace \"relevance\", to promote a market orientation (market pull), to promote a design orientation (seeking to reframe problems via empathy), and to institute clear methods for smart Ai and Future Toolsand minimizing risk.",
        "ITEMNO": 49,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the embrace of 'relevance' been reflected in the organization's values? Are they explicitly reflected in a statement of values?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Values - Use"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Orientation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which the organization has put its values into practice to embrace \"relevance\", to promote a market orientation (market pull), to promote a design orientation (seeking to reframe problems via empathy), and to institute clear methods for smart Ai and Future Toolsand minimizing risk.",
        "ITEMNO": 50,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is the organization promoting a market orientation, in terms of operating out of a market-pull?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Orientation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which the organization has put its values into practice to embrace \"relevance\", to promote a market orientation (market pull), to promote a design orientation (seeking to reframe problems via empathy), and to institute clear methods for smart Ai and Future Toolsand minimizing risk.",
        "ITEMNO": 51,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is the organization promoting a design orientation, in terms of constantly seeking to reframe problems?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Approach",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which the organization has put its values into practice to embrace \"relevance\", to promote a market orientation (market pull), to promote a design orientation (seeking to reframe problems via empathy), and to institute clear methods for smart Ai and Future Toolsand minimizing risk.",
        "ITEMNO": 52,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent has the organization instituted clear methods toward smart Ai and Future Tools and minimizing risk?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Approach",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ways and extent to which discovery skillsandbusiness experimentation(e.g. 5x5 teams) are beingpromotedin the organization.",
        "ITEMNO": 53,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are tools fetching skills being promoted within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 4,
        "Transformation\n ELEMENT": "Values",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Approach",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ways and extent to which discovery skillsandbusiness experimentation(e.g. 5x5 teams) are beingpromotedin the organization.",
        "ITEMNO": 54,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is business experimentation being promoted within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Orientation",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organization's value focuspriorities-customer, staff, investors-stated & demonstrated.",
        "ITEMNO": 55,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, has the organization clarified its value focus priorities?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Culture & Environment - Presence"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Orientation",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organization's value focuspriorities-customer, staff, investors-stated & demonstrated.",
        "ITEMNO": 56,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the organization's value focus priorities appropriate for its situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Community",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Presence of explicit \"meaning making\"in the organization.E.g. a culture code / declaration of purpose.",
        "ITEMNO": 57,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is 'meaning making' explicitly established or pursued within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Core",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Established culture of Ai and Future Tools& culture of relevance, and alignment to these cultures.",
        "ITEMNO": 58,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to establish a culture of tech innovation and Transformation? How many are aligned to this??",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Culture & Environment - Outcomes & Results"
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Core",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Established culture of Ai and Future Tools& culture of relevance, and alignment to these cultures.",
        "ITEMNO": 59,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to establish a culture of relevance? How many are aligned to this?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Agility",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Creation of cultural agility[ culture code / flatness / diversity / openness / experimenting ]andmovement towardindividual autonomyin how the organization engages and treats people.",
        "ITEMNO": 60,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to create cultural agility?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Engagement",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Creation of cultural agility[ culture code / flatness / diversity / openness / experimenting ]andmovement towardindividual autonomyin how the organization engages and treats people.",
        "ITEMNO": 61,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to move toward individual Competency autonomy in how it engages and treats its people?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Politics",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Presence or absence of culturally-inhibitingpolitical strugglesin organization.",
        "ITEMNO": 62,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are political struggles occurring within the organization that are holding back its culture?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Corp Mandates",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Success at minimizing push for efficiency and standardization in front-end Ai and Future Toolsefforts.",
        "ITEMNO": 63,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to minimize the push for efficiency and standardization in front-end Ai and Future Tools efforts?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Leverage",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Success at leveraging cultureto establish common acommon languageand acore set of valuesaround innovation.",
        "ITEMNO": 64,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to leverage culture to establish a common set of core values around tech innovation and transformation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FOUNDATION",
        "CAT\n NO": 5,
        "Transformation\n ELEMENT": "Culture & Environment",
        "WT": 4,
        "Transformation\n SUB-ELEMENT": "Leverage",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Success at leveraging cultureto establish common acommon languageand acore set of valuesaround innovation.",
        "ITEMNO": 65,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to leverage culture to establish a common language for Ai and Future Tools within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 66,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What structures are in place for executing the Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Structures & Relationships - Definition & Fluidity"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 67,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the structures for executing the Transformation appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 68,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent does everyone in the organization understand the structure in place for Innovation? To what extent is the structure well publicized?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Fluidity",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 69,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent does the structure allow for unstructured interaction across many levels -up, down, and across?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 70,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is the current structure promoting good stake holder alignment up and down and across all stakeholders in the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 71,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the approach to hand-off execution(to downstream receivers) achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Organizational structures governing the Ai and Future ToolsProgram.",
        "ITEMNO": 72,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the structures in place achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ambidexterity",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Approach to ambidexterity for innovation.",
        "ITEMNO": 73,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways has the organization established an ambidextrous approach to innovation? Is its structural or contextual ambidexterity?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Ambidexterity"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ambidexterity",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Approach to ambidexterityfor innovation.",
        "ITEMNO": 74,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the organization's approach to ambidexterity appropriate for its situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ambidexterity",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Approach to ambidexterityfor innovation.",
        "ITEMNO": 75,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the organization's approach to ambidexterity achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Localization",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Localizationof \"core innovation\" function-centralized, decentralized, or hybrid.",
        "ITEMNO": 76,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What approach has the organization defined for localizing its 'core innovation' function - centralized, decentralized, or hybrid?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Localization"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Localization",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Localizationof \"core innovation\" function-centralized, decentralized, or hybrid.",
        "ITEMNO": 77,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the organization's approach to 'core innovation' localization appropriate for its situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Localization",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Localizationof \"core innovation\" function-centralized, decentralized, or hybrid.",
        "ITEMNO": 78,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the organization's approach to 'core innovation' localization achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Localization",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Localizationof \"core innovation\" function-centralized, decentralized, or hybrid.",
        "ITEMNO": 79,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is Ai and Future Tools being drawn out of all areas of the organization - CI / CoE / R&D / BU NPD / X-Works / PxD / CV / CorpDev / etc.?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 6,
        "Transformation\n ELEMENT": "Structure& Relationships",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Working structure for facilitating Ai and Future ToolsManagement and Business Unit Interfaces.",
        "ITEMNO": 80,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What working structure has the organization defined and put into place for facilitating Ai and Future Tools Management and BU Interfaces.",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Relationships"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Governance processes for both Ai and Future ToolsManagement and the overall Transformation.",
        "ITEMNO": 81,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What governance processes are in place for both Ai and Future Tools Management and the overall Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Governance Processs - Definition"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Governance processes for both Ai and Future ToolsManagement and the overall Transformation.",
        "ITEMNO": 82,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the governance processes in use appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Governance processes for both Ai and Future ToolsManagement and the overall Transformation.",
        "ITEMNO": 83,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the governance processes being used effectively to support the Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Governance processes for both Ai and Future ToolsManagement and the overall Transformation.",
        "ITEMNO": 84,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the governance processes achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Objectivity",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ground rules for Ai and Future Toolsgovernance - agreed-upon and widely-disseminated.",
        "ITEMNO": 85,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are agreed-upon and widely-disseminated ground rules in place for Ai and Future Tools governance?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Objectivity"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline owner- person designated to oversee all of toll-gate events, or at least oversee consistent use of toll-gate events.",
        "ITEMNO": 86,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What designated pipeline owner is identified and in place(the person who oversees all of the toll-gate events, or at least their consistent use)?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Leadership"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline owner- person designated to oversee all of toll-gate events, or at least oversee consistent use of toll-gate events.",
        "ITEMNO": 87,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the designated pipeline owner appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline owner- person designated to oversee all of toll-gate events, or at least oversee consistent use of toll-gate events.",
        "ITEMNO": 88,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the designated pipeline owner effectively overseeing all of the toll-gate events, or at least their consistent use?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Phases & Gates",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline phasesandtoll-gates.",
        "ITEMNO": 89,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What pipeline phases and toll-gates are defined and in place to accomplish their desired intent?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Phases & Gates"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Phases & Gates",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline phasesandtoll-gates.",
        "ITEMNO": 90,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the defined pipeline phases and toll-gates appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Phases & Gates",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pipeline phasesandtoll-gates.",
        "ITEMNO": 91,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are all pipeline phases and toll-gates being used effectively and managed uniformly to manage the progression of projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Phases & Gates",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Pipeline phasesandtoll-gates.",
        "ITEMNO": 92,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the pipeline phases and toll-gates achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Reviews",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project reviewsfor keeping stakeholders engaged and synchronized with project progress / health.",
        "ITEMNO": 93,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What regularly scheduled project reviews are in place to keep stakeholders engaged and synchronized with project progress / health?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Project Reviews"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Reviews",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project reviewsfor keeping stakeholders engaged and synchronized with project progress / health.",
        "ITEMNO": 94,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the regularly scheduled project reviews in place appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Reviews",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project reviewsfor keeping stakeholders engaged and synchronized with project progress / health.",
        "ITEMNO": 95,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are regularly scheduled project reviews being used effectively to keep stakeholders engaged and synchronized with project progress / health?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Reviews",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Project reviewsfor keeping stakeholders engaged and synchronized with project progress / health.",
        "ITEMNO": 96,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the use of regularly scheduled project reviews achieving its intended objectives??",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capture",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms are for capturing, cataloging, and managingnew ideas & opportunities-in the Capture Phase.",
        "ITEMNO": 97,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What mechanisms are in place for capturing, cataloging, and managing new ideas & opportunities?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Capture"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capture",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms are for capturing, cataloging, and managingnew ideas & opportunities-in the Capture Phase.",
        "ITEMNO": 98,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the mechanisms in place for capturing, cataloging, and managing new ideas & opportunities appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capture",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms are for capturing, cataloging, and managingnew ideas & opportunities-in the Capture Phase.",
        "ITEMNO": 99,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the mechanisms for capturing, cataloging, and managing new ideas & opportunities being used and managed effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capture",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mechanisms are for capturing, cataloging, and managingnew ideas & opportunities-in the Capture Phase.",
        "ITEMNO": 100,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the mechanisms for capturing, cataloging, and managing new ideas & opportunities achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Process-for evaluating new ideas and selecting those for promotion to projects-in the Evaluation Phase.",
        "ITEMNO": 101,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What process sin place for evaluating new ideas and selecting those to promote?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Evaluation"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Process-for evaluating new ideas and selecting those for promotion to projects-in the Evaluation Phase.",
        "ITEMNO": 102,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the Evaluation Process clearly defined, well documented, broadly communicated, and fully transparent?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Process-for evaluating new ideas and selecting those for promotion to projects-in the Evaluation Phase.",
        "ITEMNO": 103,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the Evaluation Process appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Process-for evaluating new ideas and selecting those for promotion to projects-in the Evaluation Phase.",
        "ITEMNO": 104,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the Evaluation Process being used effectively to evaluate ideas and select those for promotion to projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Outcomes",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Evaluation Process-for evaluating new ideas and selecting those for promotion to projects-in the Evaluation Phase.",
        "ITEMNO": 105,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the Evaluation Process achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Initiation",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Initiation Process- forinitiatingandincubatingnew projectsat the point ofinitiation- what happens when ideas get promoted into pipeline /who takes ownership of them to drive them.",
        "ITEMNO": 106,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What process is in place for initiating and incubating new projects once they have been selected?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Initiation"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Initiation",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Initiation Process- forinitiatingandincubatingnew projectsat the point ofinitiation- what happens when ideas get promoted into pipeline /who takes ownership of them to drive them.",
        "ITEMNO": 107,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the Project Initiation Process appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Initiation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Initiation Process- forinitiatingandincubatingnew projectsat the point ofinitiation- what happens when ideas get promoted into pipeline /who takes ownership of them to drive them.",
        "ITEMNO": 108,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the Project Initiation Process being used and managed effectively to initiate and incubate new projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Initiation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Project Initiation Process- forinitiatingandincubatingnew projectsat the point ofinitiation- what happens when ideas get promoted into pipeline /who takes ownership of them to drive them.",
        "ITEMNO": 109,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the Project Initiation Process achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Mid Zone",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for pursuingresearchand developinggo-to-market strategies.",
        "ITEMNO": 110,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What processes are in place for pursuing research and developing go-to-market strategies?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Mid Zone"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Mid Zone",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for pursuingresearchand developinggo-to-market strategies.",
        "ITEMNO": 111,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the processes for pursuing research and developing go-to-market strategies appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Mid Zone",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for pursuingresearchand developinggo-to-market strategies.",
        "ITEMNO": 112,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are sound processes being used and managed effectively for pursuing research and developing go-to-market strategies?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Mid Zone",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Processes-in the mid zone-for pursuing research and developing go-to-market strategies.",
        "ITEMNO": 113,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the processes for pursuing research and developing go-to-market strategies achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for engagingoutside partiesto develop projects.",
        "ITEMNO": 114,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What processes are in place for engaging outside parties to help develop projects?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Development Processes"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for engagingoutside partiesto develop projects.",
        "ITEMNO": 115,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the processes for engaging outside parties to develop projects appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Processes-in the mid zone-for engagingoutside partiesto develop projects.",
        "ITEMNO": 116,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are sound processes being used and managed effectively for engaging outside parties to develop projects??",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Processes-in the mid zone-for engagingoutside partiesto develop projects.",
        "ITEMNO": 117,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the processes for engaging outside parties to develop projects achieving their intended objectives??",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms-on the back end-for funneling development into either:Route 1(internal development, via internal NPD)Route 2(joint development, via NPD partnership)Route 3(external development, via contracted NPD)",
        "ITEMNO": 118,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What mechanisms rein place for funneling development into Route 1, 2, or 3?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Development Routing"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms-on the back end-for funneling development into either:Route 1(internal development, via internal NPD)Route 2(joint development, via NPD partnership)Route 3(external development, via contracted NPD)",
        "ITEMNO": 119,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the mechanisms for funneling development into Route 1, 2, or 3 appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms-on the back end-for funneling development into either:Route 1(internal development, via internal NPD)Route 2(joint development, via NPD partnership)Route 3(external development, via contracted NPD)",
        "ITEMNO": 120,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the most appropriate Routes being taken in most cases?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms-on the back end-for funneling development into either:Route 1(internal development, via internal NPD)Route 2(joint development, via NPD partnership)Route 3(external development, via contracted NPD)",
        "ITEMNO": 121,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the mechanisms for funneling development into Route 1, 2, or 3beingusedandmanaged effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mechanisms-on the back end-for funneling development into either:Route 1(internal development, via internal NPD)Route 2(joint development, via NPD partnership)Route 3(external development, via contracted NPD)",
        "ITEMNO": 122,
        "READINESS": "X",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, are the mechanisms in place for funneling development into Route 1, 2, or 3achievingtheir intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Tie-inswith organizational units are-fordownstream receiverhand-offs.",
        "ITEMNO": 123,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What set of tie-ins with organizational units are in place for downstream receiver hand-offs?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "BU Relationships"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Tie-inswith organizational units are-fordownstream receiverhand-offs.",
        "ITEMNO": 124,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is this set of tie-ins with organizational units appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Relationships",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Tie-inswith organizational units are-fordownstream receiverhand-offs.",
        "ITEMNO": 125,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the set of tie-ins with organizational units being used and managed effectively for production collaboration?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Pilot",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pilot Process-for testing out new concepts prior to full market commercialization - for the Pilot Phase.",
        "ITEMNO": 126,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What pilot process is in place?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Pilot Process"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Pilot",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pilot Process-for testing out new concepts prior to full market commercialization - for the Pilot Phase.",
        "ITEMNO": 127,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the Pilot Process appropriate to the Transformation's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Pilot",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Pilot Process-for testing out new concepts prior to full market commercialization - for the Pilot Phase.",
        "ITEMNO": 128,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are pilot projects being used effectively to helped-risk new launches? To what extent do these reflect a Lean Startup approach?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Pilot",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Pilot Process-for testing out new concepts prior to full market commercialization - for the Pilot Phase.",
        "ITEMNO": 129,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is the Pilot Process achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Commercialize",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Commercialization ProcessesIncludingresourcesandpracticesfor drivingfollow-throughandexecution into the market-for the Scale Phase on the back end.",
        "ITEMNO": 130,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What commercialization processes are in place?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Commercialization Process"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Commercialize",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Commercialization ProcessesIncludingresourcesandpracticesfor drivingfollow-throughandexecution into the market-for the Scale Phase on the back end.",
        "ITEMNO": 131,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the commercialization processes appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Commercialize",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Commercialization ProcessesIncludingresourcesandpracticesfor drivingfollow-throughandexecution into the market-for the Scale Phase on the back end.",
        "ITEMNO": 132,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are sound commercialization processes being used and managed effectively to commercialize new offerings?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Commercialize",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Commercialization ProcessesIncludingresourcesandpracticesfor drivingfollow-throughandexecution into the market-for the Scale Phase on the back end.",
        "ITEMNO": 133,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "n what ways, and to what extent, are the commercialization processes achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Creativity",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Incorporation of right-brain practices[ discovery, empathy, imagination, creativity ], \n methods fororganizational velocity[ rapid doing / rapid learning ],andmethods forbusiness experimentation[ experiments / skunkworks / lean startup ].",
        "ITEMNO": 134,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to incorporate right-brain practices?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Corporate Mandates"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Velocity",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Incorporation of right-brain practices[ discovery, empathy, imagination, creativity ], \n methods fororganizational velocity[ rapid doing / rapid learning ],andmethods forbusiness experimentation[ experiments / skunkworks / lean startup ].",
        "ITEMNO": 135,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to incorporate methods for organizational velocity?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 7,
        "Transformation\n ELEMENT": "Governance Processes",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Experimentation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Incorporation of right-brain practices[ discovery, empathy, imagination, creativity ], \n methods fororganizational velocity[ rapid doing / rapid learning ],andmethods forbusiness experimentation[ experiments / skunkworks / lean startup ].",
        "ITEMNO": 136,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to incorporate methods for business experimentation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General Metrics & KPIs for driving effectiveness of the Ai and Future ToolsProgram.",
        "ITEMNO": 137,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What general Metrics & KPIs are in place for driving overall effectiveness of the Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Transformation Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Governance Metrics - Definition"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General Metrics & KPIs for driving effectiveness of the Ai and Future ToolsProgram.",
        "ITEMNO": 138,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the general Metrics and KPIs appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General Metrics & KPIs for driving effectiveness of the Ai and Future ToolsProgram.",
        "ITEMNO": 139,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the general Metrics & KPIs being used and managed effectively to drive effectiveness of the Transformation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Definition",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "General Metrics & KPIs for driving effectiveness of the Ai and Future ToolsProgram.",
        "ITEMNO": 140,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, is use of the general Metrics and KPIs achieving its intended objectives for Transformation effectiveness?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving Executive Leadership's ownership of, and engagement in, the Ai and Future ToolsProgram.",
        "ITEMNO": 141,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for driving Executive Leadership's ownership of, and engagement in, the Ai and Future Tools Program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Leadership"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving Executive Leadership's ownership of, and engagement in, the Ai and Future ToolsProgram.",
        "ITEMNO": 142,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for driving Executive Leadership's ownership of, and engagement in, the program appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Leadership",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving Executive Leadership's ownership of, and engagement in, the Ai and Future ToolsProgram.",
        "ITEMNO": 143,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for driving Executive Leadership's ownership of, and engagement in, the program being used and managed effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Environment",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of an environment conducive to Ai and Future Tools -cultural transformation.",
        "ITEMNO": 144,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for driving ongoing development of an environment conducive to innovation?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Environment"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Environment",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of an environment conducive to Ai and Future Tools -cultural transformation.",
        "ITEMNO": 145,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for driving ongoing development of this environment appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Environment",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of an environment conducive to Ai and Future Tools -cultural transformation.",
        "ITEMNO": 146,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for driving ongoing development of this environment being used and managed effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Competence",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of Ai and Future Toolscompetencies within the organization.",
        "ITEMNO": 147,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for driving ongoing development of Ai and Future Tools competencies within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Competence"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Competence",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of Ai and Future Toolscompetencies within the organization.",
        "ITEMNO": 148,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for driving ongoing development of Ai and Future Tools competencies appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Competence",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metrics for driving ongoing development of Ai and Future Toolscompetencies within the organization.",
        "ITEMNO": 149,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for driving ongoing development of Ai and Future Tools competencies being used and managed effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Inputs",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking inputsinto the program.",
        "ITEMNO": 150,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for tracking inputs into the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Inputs"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Inputs",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking inputsinto the program.",
        "ITEMNO": 151,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for tracking inputs appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Inputs",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking inputsinto the program.",
        "ITEMNO": 152,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for tracking inputs being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Throughputs",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking throughputsin the program.",
        "ITEMNO": 153,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for tracking throughputs in the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Throughputs"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Throughputs",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking throughputsin the program.",
        "ITEMNO": 154,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for tracking throughputs appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Throughputs",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking throughputsin the program.",
        "ITEMNO": 155,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for tracking through puts being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Outputs",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking outputsout of the program.",
        "ITEMNO": 156,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for tracking outputs out of the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Outputs"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Outputs",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking outputsout of the program.",
        "ITEMNO": 157,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for tracking outputs appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Outputs",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking outputsout of the program.",
        "ITEMNO": 158,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for tracking outputs being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Efficiency",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking efficiencyof the program.",
        "ITEMNO": 159,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for tracking the efficiency of the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Efficiency"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Efficiency",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking efficiencyof the program.",
        "ITEMNO": 160,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for tracking efficiency appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Efficiency",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking efficiencyof the program.",
        "ITEMNO": 161,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for tracking efficiency being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Balance",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking balancein the program.",
        "ITEMNO": 162,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for tracking balance in the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Balance"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Balance",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking balancein the program.",
        "ITEMNO": 163,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for tracking balance appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Balance",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsfortracking balancein the program.",
        "ITEMNO": 164,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for tracking balance being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingindividualideasandconceptsunder consideration for the pipeline.",
        "ITEMNO": 165,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics rein place for evaluating individual ideas and projects under consideration for the pipeline?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Evaluation"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingindividualideasandconceptsunder consideration for the pipeline.",
        "ITEMNO": 166,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for evaluating individual ideas and projects appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingindividualideasandconceptsunder consideration for the pipeline.",
        "ITEMNO": 167,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for evaluating individual ideas and projects being used and managed effectively to ensure the best ideas are selected?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Research",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatinginsightsgained frommarket research.",
        "ITEMNO": 168,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for evaluating insights gained from market research?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Research"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Research",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatinginsightsgained frommarket research.",
        "ITEMNO": 169,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for evaluating insights gained from market research appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Research",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatinginsightsgained frommarket research.",
        "ITEMNO": 170,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for evaluating insights gained from market research being used and managed support projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Testing / Pilots",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingthelearningsfrom in-markettestingand overallpilotactivity.",
        "ITEMNO": 171,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for evaluating the learnings from in-market testing and overall pilot activity?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Testing / Pilots"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Testing / Pilots",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingthelearningsfrom in-markettestingand overallpilotactivity.",
        "ITEMNO": 172,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for evaluating the learnings from in-market testing and overall pilot activity appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Testing / Pilots",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Metricsforevaluatingthelearningsfrom in-markettestingand overallpilotactivity.",
        "ITEMNO": 173,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for evaluating the learnings from in-market testing and overall pilot activity being used and managed effectively to support projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "CV",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "MetricsforCorporate Venturingdecision-making.",
        "ITEMNO": 174,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What metrics are in place for Corporate Venturing decision-making?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 2,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Corporate Venturing"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "CV",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "MetricsforCorporate Venturingdecision-making.",
        "ITEMNO": 175,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the metrics for Corporate Venturing decision-making appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 2,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "CV",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "MetricsforCorporate Venturingdecision-making.",
        "ITEMNO": 176,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the metrics for Corporate Venturing decision-making being used and managed effectively to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 2,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 8,
        "Transformation\n ELEMENT": "Governance Metrics",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Program",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Activeuse of metrics (fordrivingeffectiveness)versusPassiveuse of metrics (forgaugingeffectiveness).",
        "ITEMNO": 177,
        "READINESS": "",
        "TIME FRAME": "past 2 Years",
        "QUESTION": "To what extent are metrics being used actively to drive program effectiveness versus passively to gauge program effectiveness?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Program"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "CInO",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Chief Ai and Future ToolsOfficer",
        "ITEMNO": 178,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What IAIDL is in place?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "CInO"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "CInO",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Chief Ai and Future ToolsOfficer",
        "ITEMNO": 179,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the role of the IAIDL well defined?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "CInO",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Chief Ai and Future ToolsOfficer",
        "ITEMNO": 180,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the role of the IAIDL (Advisor, Catalyst, Expander, Renewer) appropriate to the stage of Ai and Future Tools maturity of the organization?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "CInO",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Chief Ai and Future ToolsOfficer",
        "ITEMNO": 181,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent does the IAIDL have the appropriate skills to drive the program? Are they a Certified IAIDL?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "CInO",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Chief Ai and Future ToolsOfficer",
        "ITEMNO": 182,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is the IAIDL function (not the person) able to effectively own and drive the Ai and Future Tools Program toward achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Managers",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsManagers",
        "ITEMNO": 183,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What Ai and Future Tools Managers are in place to own and drive the Ai and Future Tools Management process?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Managers"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Managers",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsManagers",
        "ITEMNO": 184,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are Ai and Future Tools Managers able to effectively own and drive the Ai and Future Tools Management process?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Managers",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsManagers",
        "ITEMNO": 185,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What Project Leaders are in place to own, advocate for, and otherwise drive Ai and Future Tools projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Leaders",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Leaders",
        "ITEMNO": 186,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent do Project Leaders have the appropriate skills to drive the projects? What percentage are Certified Ai and Future Tools Managers or Strategists?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Project Leaders"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Leaders",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Leaders",
        "ITEMNO": 187,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are Project Leaders able to effectively own, advocate for, and otherwise drive projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Project Leaders",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Project Leaders",
        "ITEMNO": 188,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What Evaluation Teams are in place?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation Teams",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Teams",
        "ITEMNO": 189,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the people serving on Evaluation Teams experienced business leaders who have been properly trained and authorized to execute the role?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Evaluation Teams"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation Teams",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Teams",
        "ITEMNO": 190,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are Evaluation Teams applying the evaluation process as communicated, openly, transparently, and objectively, with good judgment?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Evaluation Teams",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Evaluation Teams",
        "ITEMNO": 191,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are an adequate number of appropriately trained & experienced Ai and Future Tools Facilitators being provided to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Facilitators",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsFacilitators",
        "ITEMNO": 192,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are Ai and Future Tools Facilitators able to fulfill all the requested needs for their facilitation assistance?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Facilitators"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Facilitators",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsFacilitators",
        "ITEMNO": 193,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are an adequate number of Acting Ai and Future Tools Teams in place to support the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsTeams",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Acting Ai and Future ToolsTeams",
        "ITEMNO": 194,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent do Ai and Future Tools Professionals have the appropriate skills to execute the program? What percentage, overall, are Certified Ai and Future Tools Professionals?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Ai and Future ToolsTeams"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsTeams",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Acting Ai and Future ToolsTeams",
        "ITEMNO": 195,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are Acting Ai and Future Tools Teams staffed with the necessary cross-functional team membership?",
        "METHOD\n OF\n ASSESSMENT": "Training & Certification Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsTeams",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Acting Ai and Future ToolsTeams",
        "ITEMNO": 196,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are Project Leaders, Ai and Future Tools Facilitators, and AIT Members properly proportioned in Tools fetching skills?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Team Skills",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Skills in Project Leaders, Ai and Future ToolsFacilitators, and AIT Members.",
        "ITEMNO": 197,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What MarCom roles are in place to drive PR, awareness, and engagement for the program?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Team Skills"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "MarCom",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Marketing Communications Specialists",
        "ITEMNO": 198,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the PR activity adequately staffed with appropriately skilled MarCom staff to properly support the program?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "MarCom"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "MarCom",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Marketing Communications Specialists",
        "ITEMNO": 199,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What Executive Pipeline Champions are in place to help own and drive new projects getting initiated in the pipeline so as to ensure they get off the ground?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Exec Champions",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "ExecutivePipelineChampions",
        "ITEMNO": 200,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the designated Executive Pipeline Champions appropriate to the program's needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Executive Pipeline Champions"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Exec Champions",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "ExecutivePipelineChampions",
        "ITEMNO": 201,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are Executive Pipeline Champions able to effectively own and drive new projects in the pipeline as they are being initiated?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Exec Champions",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "ExecutivePipelineChampions",
        "ITEMNO": 202,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of Executive Pipeline Champions achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Exec Champions",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "ExecutivePipelineChampions",
        "ITEMNO": 203,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What BU Point-Persons rein place for each business unit?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Point Persons",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Business Unit Point-Persons",
        "ITEMNO": 204,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are BU Point-Persons able to function effectively as a bridge between the Ai and Future Tools Group and respective business units?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "BU Point Persons"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Point Persons",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Business Unit Point-Persons",
        "ITEMNO": 205,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of BU Point-Persons achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Point Persons",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Business Unit Point-Persons",
        "ITEMNO": 206,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What BU Champions rein place for each business unit to help usher in new innovations into their business units?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "BU Champions",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Business Unit Champions",
        "ITEMNO": 207,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are BU Champions able to engage effectively as gate-keepers and sponsors of projects passing into their business units?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "BU Champions"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "BU Champions",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Business Unit Champions",
        "ITEMNO": 208,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of BU Champions achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "BU Champions",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Business Unit Champions",
        "ITEMNO": 209,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What special X-Teams are in place to pursue Horizon 2 & 3 Ai and Future Tools projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "X Teams",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special X-Teams",
        "ITEMNO": 210,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are special X-Teams staffed with the right mix of Design, and Execution skills? What percentage are Certified Ai and Future Tools Professionals?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "X Teams"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "X Teams",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special X-Teams",
        "ITEMNO": 211,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, are special X-Teams being used to pursue Horizon 2 & 3 Ai and Future Tools projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "X Teams",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special X-Teams",
        "ITEMNO": 212,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What special teams are in place to drive special groups within the program (e.g. OI, CVC)?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Special Teams",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special teams- for special groups in the program(e.g. OI, CVC).",
        "ITEMNO": 213,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are special teams staffed appropriately with the right skills?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Special Teams"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Special Teams",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special teams- for special groups in the program(e.g. OI, CVC).",
        "ITEMNO": 214,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are special teams able to effectively drive special groups within the program (e.g. OI, CVC)?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Special Teams",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Special teams- for special groups in the program(e.g. OI, CVC).",
        "ITEMNO": 215,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to move toward team autonomy in how it builds and uses team?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 3,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Autonomy",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Movement toward team autonomyin building and using teams.",
        "ITEMNO": 216,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to shift toward individual autonomy for professional career progression?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Autonomy & Career Dev"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 9,
        "Transformation\n ELEMENT": "Roles & Responsibilities",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Development",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Shift towardindividual autonomyfor professional career progression.",
        "ITEMNO": 217,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "Is the individual(s) serving in the role of the Chief Ai and Future Tools Officer formally certified for this role by a recognized certifying body?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 10,
        "Transformation\n ELEMENT": "Role Certification",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The presence of certified individuals in each of the key roles of the Ai and Future Toolsprogram.",
        "ITEMNO": 218,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of the individuals serving in roles equivalent to Ai and Future Tools Strategist are formally certified for this role by a recognized certifying body?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Role Certification"
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 10,
        "Transformation\n ELEMENT": "Role Certification",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The presence of certified individuals in each of the key roles of the Ai and Future Toolsprogram.",
        "ITEMNO": 219,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of the individuals serving in the role of Ai and Future Tools Manager are formally certified for this role by a recognized certifying body?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 10,
        "Transformation\n ELEMENT": "Role Certification",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The presence of certified individuals in each of the key roles of the Ai and Future Toolsprogram.",
        "ITEMNO": 220,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of the individuals serving in the role of Ai and Future Tools Facilitator are formally certified for this role by a recognized certifying body (e.g. certified in an Ai and Future Tools specialty such as Design Thinking or Insights Research)?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 10,
        "Transformation\n ELEMENT": "Role Certification",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "The presence of certified individuals in each of the key roles of the Ai and Future Toolsprogram.",
        "ITEMNO": 221,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of the individuals serving as frontline Ai and Future Tools Professionals are formally certified for this role by a recognized certifying body?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "PROCEDURES",
        "CAT\n NO": 10,
        "Transformation\n ELEMENT": "Role Certification",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "The presence of certified individuals in each of the key roles of the Ai and Future Toolsprogram.",
        "ITEMNO": 222,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What is the current annual budget for the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 11,
        "Transformation\n ELEMENT": "Funding",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Funding Level",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Sizing and allocation of the budget relative to the Ai and Future ToolsStrategy and needs of the Ai and Future ToolsProgram.",
        "ITEMNO": 223,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the budget size matched to the Ai and Future Tools Strategy, and appropriate to the growth organizational competencies being pursued?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Funding"
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 11,
        "Transformation\n ELEMENT": "Funding",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Funding Level",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Sizing and allocation of the budget relative to the Ai and Future ToolsStrategy and needs of the Ai and Future ToolsProgram.",
        "ITEMNO": 224,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is the funding spread around so as to fund initiatives in each part of the Ai and Future Tools Strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 11,
        "Transformation\n ELEMENT": "Funding",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Funding Level",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Sizing and allocation of the budget relative to the Ai and Future ToolsStrategy and needs of the Ai and Future ToolsProgram.",
        "ITEMNO": 225,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent can additional funds be secured if needed to pursue strategically important new opportunities  not previously foreseen?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 11,
        "Transformation\n ELEMENT": "Funding",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Funding Level",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Sizing and allocation of the budget relative to the Ai and Future ToolsStrategy and needs of the Ai and Future ToolsProgram.",
        "ITEMNO": 226,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are enough Ai and Future Tools projects receiving the level of funding they need to succeed and make the overall AI and Future management Tools initiatives successful?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 11,
        "Transformation\n ELEMENT": "Funding",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Funding Level",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Sizing and allocation of the budget relative to the Ai and Future ToolsStrategy and needs of the Ai and Future ToolsProgram.",
        "ITEMNO": 227,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is the AI and Future management Tools initiatives’ Leadership given the authority to manage the AI and Future management Tools initiatives budget autonomously, without interference?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 12,
        "Transformation\n ELEMENT": "Budget Management",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Autonomy",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLeadership's autonomy and flexibility in managing the Ai and Future ToolsProgram's budget.",
        "ITEMNO": 228,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the approach being used for budget management flexible, e.g. a 3-year rolling budget with quarterly reviews?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Budget Management"
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 12,
        "Transformation\n ELEMENT": "Budget Management",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Management",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLeadership's autonomy and flexibility in managing the Ai and Future ToolsProgram's budget.",
        "ITEMNO": 229,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent can funding be easily shifted around from one endeavor to another?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 12,
        "Transformation\n ELEMENT": "Budget Management",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Management",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLeadership's autonomy and flexibility in managing the Ai and Future ToolsProgram's budget.",
        "ITEMNO": 230,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What mechanisms are in place- e.g. a separate legal / financial business entity for making Investment transformation decisions?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 13,
        "Transformation\n ELEMENT": "External Investment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Investments",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms- e.g. aseparate legal / financial business entitysuch as aCVC arm- formaking external investment decisions.",
        "ITEMNO": 231,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the mechanisms for investment transformation decisions appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "External Investment"
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 13,
        "Transformation\n ELEMENT": "External Investment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Investments",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms- e.g. aseparate legal / financial business entitysuch as aCVC arm- formaking external investment decisions.",
        "ITEMNO": 232,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the mechanisms for investment transformation decisions being used and managed effectively to make good decisions?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 13,
        "Transformation\n ELEMENT": "External Investment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Investments",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Mechanisms- e.g. aseparate legal / financial business entitysuch as aCVC arm- formaking external investment decisions.",
        "ITEMNO": 233,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of these mechanisms for investment transformation decisions achieving their intended outcomes?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "FINANCING",
        "CAT\n NO": 13,
        "Transformation\n ELEMENT": "External Investment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Investments",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mechanisms- e.g. aseparate legal / financial business entitysuch as aCVC arm- formaking external investment decisions.",
        "ITEMNO": 234,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What Ai and Future Tools training programs are in place for Practitioners, Managers, and Executives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Training",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsTraining Programs",
        "ITEMNO": 235,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the organization's AI and Future Tools training programs appropriate to the AI and Future management Tools initiatives' needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Internal Training"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Training",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsTraining Programs",
        "ITEMNO": 236,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the organization's Ai and Future Tools training programs being used effectively to train Practitioners, Managers, and Executives? What level of usage?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Training",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsTraining Programs",
        "ITEMNO": 237,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are the Ai and Future Tools training programs achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Training",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ai and Future ToolsTraining Programs",
        "ITEMNO": 238,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is the organization making additional outside training courses available to members? What are these?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Training",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Additional outside trainingcourses and programs.",
        "ITEMNO": 239,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is it straightforward for members to get approved for additional outside training courses and programs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "External Training"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Training",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Additional outside trainingcourses and programs.",
        "ITEMNO": 240,
        "READINESS": "",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, are tools fetching skills being taught within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery Skills",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which Discovery Skillsare beingtaughtin the organization.",
        "ITEMNO": 241,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is business experimentation being taught within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Discovery Skills"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Experimentation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which business experimentationis beingtaughtin the organization (e.g. 5x5 teams).",
        "ITEMNO": 242,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What additional tools and resources are members being provided to carry out the Ai and Future Tools work? Coaching? Insights? Mentors? Places? Time? Other?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Experimentation"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Tools & Resources",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Other tools and resourcesfor supporting the program -Insights/Mentors/Places/Time/ Other",
        "ITEMNO": 243,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the additional tools and resources provided appropriate to the AI and Future management Tools initiatives needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Tools & Resources"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Tools & Resources",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Other tools and resourcesfor supporting the program -Insights/Mentors/Places/Time/ Other",
        "ITEMNO": 244,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the additional tools and resources provided being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Tools & Resources",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Other tools and resourcesfor supporting the program -Insights/Mentors/Places/Time/ Other",
        "ITEMNO": 245,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are the additional tools and resources achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Tools & Resources",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Other tools and resourcesfor supporting the program -Insights/Mentors/Places/Time/ Other",
        "ITEMNO": 246,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What key Ai and Future Tools skills has the organization developed and mastered at this point?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsSkills",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Skill development for Ai and Future Toolsskills.",
        "ITEMNO": 247,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are the organization's Ai and Future Tools skills appropriate to its situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Ai and Future ToolsSkills"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsSkills",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Skill development for Ai and Future Toolsskills.",
        "ITEMNO": 248,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are these Ai and Future Tools skills being used effectively to achieve the AI and Future management Tools initiatives’ intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsSkills",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Skill development for Ai and Future Toolsskills.",
        "ITEMNO": 249,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, has the organization been able to shift skill development & utilization toward individual autonomy(self-directed)?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 14,
        "Transformation\n ELEMENT": "Training & Resources",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsSkills",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Shifting skill development and utilization toward individual autonomy.",
        "ITEMNO": 250,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What processes are in place to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Processes",
        "ITEMNO": 251,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the organization's processes appropriate to its situation and strategy? To what extent is it adequately forward-looking?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Discovery Process"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Processes",
        "ITEMNO": 252,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the organization using and managing its processes effectively to support projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Processes",
        "ITEMNO": 253,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent are ongoing market environment trends and insights being openly shared within and across the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Processes",
        "ITEMNO": 254,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are the processes achieving their intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Discovery",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Discovery Processes",
        "ITEMNO": 255,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent are the professionals employed appropriately skilled in their respective types of research? ",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Professionals",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Discovery Professionals",
        "ITEMNO": 256,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What market research, user research, and/or design research resources and practices are in place and made broadly available to all Ai and Future Tools teams?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Professional Staffing"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Market Research",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of market research.",
        "ITEMNO": 257,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the organization's market research, user research, and/or design research resources and practices appropriate to its situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Market Research"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Market Research",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of market research.",
        "ITEMNO": 258,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the organization's market research, user research, and/or design research resources and practices being used effectively and broadly across the entire AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Market Research",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of market research.",
        "ITEMNO": 259,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of market research achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Market Research",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Use of market research.",
        "ITEMNO": 260,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What outside advisors has the organization made readily available to help avoid myopia in the process?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Outside Advisors",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of outside advisors-to help avoid myopia in its discovery processes.",
        "ITEMNO": 261,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are outside advisors being  used effectively to help the organization avoid myopia in its processes?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Outside Advisors"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Outside Advisors",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of outside advisors-to help avoid myopia in its discovery processes.",
        "ITEMNO": 262,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of  outside advisors achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Outside Advisors",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Use of outside advisors-to help avoid myopia in its discovery processes.",
        "ITEMNO": 263,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What analytics are in place to support the Ai and Future Tools decision-making process?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Analytics",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of analytics-to support the Ai and Future Toolsdecision-making processes.",
        "ITEMNO": 264,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are analytics being used effectively to support the Ai and Future Tools decision-making process?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Analytics"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Analytics",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Use of analytics-to support the Ai and Future Toolsdecision-making processes.",
        "ITEMNO": 265,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of analytics in the Ai and Future Tools decision-making process achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Analytics",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Use of analytics-to support the Ai and Future Toolsdecision-making processes.",
        "ITEMNO": 266,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, has the organization succeeded in developing an overall orientation toward market acuity?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 3,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 15,
        "Transformation\n ELEMENT": "Discovery & Insights",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Methods",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Development of an overall orientation toward market acuity(listening, tasting, feeling, seeing, sniffing).",
        "ITEMNO": 267,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What technology tools are in place to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Methods"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Technology Tools",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Technology Tools(software, etc.) the organization has provided.",
        "ITEMNO": 268,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the technology tools adequate and appropriate to the AI and Future management Tools initiatives’ needs? Are there major technology gaps hampering the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Technology Tools"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Technology Tools",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Technology Tools(software, etc.) the organization has provided.",
        "ITEMNO": 269,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the technology tools being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Technology Tools",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Technology Tools(software, etc.) the organization has provided.",
        "ITEMNO": 270,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of these technology tools achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Technology Tools",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Technology Tools(software, etc.) the organization has provided.",
        "ITEMNO": 271,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What capital equipment does the organization own or have access to in order to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capital Eqpmt",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Capital Equipmentthe organizationownsorhas access to.",
        "ITEMNO": 272,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is this capital equipment adequate and appropriate to the AI and Future management Tools initiatives' needs? Are there major capital equipment gaps hampering the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Capital Equipment"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capital Eqpmt",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Capital Equipmentthe organizationownsorhas access to.",
        "ITEMNO": 273,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is this capital equipment being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capital Eqpmt",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Capital Equipmentthe organizationownsorhas access to.",
        "ITEMNO": 274,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of this capital equipment achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Capital Eqpmt",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Capital Equipmentthe organizationownsorhas access to.",
        "ITEMNO": 275,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What general infrastructures in place to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Infrastructure",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General infrastructurethe organization has provided.",
        "ITEMNO": 276,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the general infrastructure adequate and appropriate to the AI and Future management Tools initiatives' needs? Are there major infrastructure gaps hampering the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Infrastructure"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Infrastructure",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General infrastructurethe organization has provided.",
        "ITEMNO": 277,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the general infrastructure being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Infrastructure",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "General infrastructurethe organization has provided.",
        "ITEMNO": 278,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of this general infrastructure achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Infrastructure",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "General infrastructurethe organization has provided.",
        "ITEMNO": 279,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What Ai and Future Tools common spaces are in place to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Common Spaces",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsCommon Spaces the organization has made available.",
        "ITEMNO": 280,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the Ai and Future Tools common spaces well-designated, well-designed, and accessible to all to support good individual and team Ai and Future Tools work? Are they adequate and appropriate to its needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Common Spaces"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Common Spaces",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsCommon Spaces the organization has made available.",
        "ITEMNO": 281,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the Ai and Future Tools common spaces being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Common Spaces",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsCommon Spaces the organization has made available.",
        "ITEMNO": 282,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of these Ai and Future Tools common spaces achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Common Spaces",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ai and Future ToolsCommon Spaces the organization has made available.",
        "ITEMNO": 283,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What Ai and Future Tools labs are in place to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsLabs",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLabs the organization has made available.",
        "ITEMNO": 284,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the Ai and Future Tool slabs firmly established, appropriately-designed, and accessible to the teams who need them to carry out Ai and Future Tools specific work? Are they adequate and appropriate to its needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Ai and Future ToolsLabs"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsLabs",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLabs the organization has made available.",
        "ITEMNO": 285,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the Ai and Future Tools labs in place being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsLabs",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Ai and Future ToolsLabs the organization has made available.",
        "ITEMNO": 286,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of these Ai and Future Tool slabs achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 3,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 16,
        "Transformation\n ELEMENT": "Technology & Infrastructure",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Ai and Future ToolsLabs",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ai and Future ToolsLabs the organization has made available.",
        "ITEMNO": 287,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What networks of peer organizations and individuals are in place to call upon to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 3,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Peer Networks",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Networksand data of peer organizations and individuals available to call upon.",
        "ITEMNO": 288,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the networks of peer organizations and individuals appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Peer Networks"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Peer Networks",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Networksand data of peer organizations and individuals available to call upon.",
        "ITEMNO": 289,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these networks of peer organizations and individuals being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Peer Networks",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Networksand data of peer organizations and individuals available to call upon.",
        "ITEMNO": 290,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of these networks achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Peer Networks",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Networksand data of peer organizations and individuals available to call upon.",
        "ITEMNO": 291,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What internal ecosystem of Ai and Future Tools supporters are in place to call upon to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 3,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Ecosystem",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Internal ecosystemof supporters available to call upon.",
        "ITEMNO": 292,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the internal ecosystem of Ai and Future Tools supporters appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Internal Ecosystems"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Ecosystem",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Internal ecosystemof supporters available to call upon.",
        "ITEMNO": 293,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the internal ecosystem of Ai and Future Tools supporters being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Ecosystem",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Internal ecosystemof supporters available to call upon.",
        "ITEMNO": 294,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, are all of the stakeholders in the internal eco system fully engaged with the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Ecosystem",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Internal ecosystemof supporters available to call upon.",
        "ITEMNO": 295,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of its internal ecosystem of Ai and Future Tools supporters achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Internal Ecosystem",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Internal ecosystemof supporters available to call upon.",
        "ITEMNO": 296,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What external ecosystem of Ai and Future Tools partners are in place to call upon to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Ecosystem",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "External ecosystem of Ai and Future Toolspartners available to call upon.",
        "ITEMNO": 297,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the external ecosystem of Ai and Future Tools partners appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "External Ecosystems"
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Ecosystem",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "External ecosystem of Ai and Future Toolspartners available to call upon.",
        "ITEMNO": 298,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the external ecosystem of Ai and Future Tools partners being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Ecosystem",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "External ecosystem of Ai and Future Toolspartners available to call upon.",
        "ITEMNO": 299,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of its external ecosystem of Ai and Future Tools partners achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "ENABLERS",
        "CAT\n NO": 17,
        "Transformation\n ELEMENT": "Networks & DATA",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "External Ecosystem",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "External ecosystem of Ai and Future Toolspartners available to call upon.",
        "ITEMNO": 300,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What portfolio of engagement mechanisms rein place to engage individuals in the AI and Future management Tools initiatives? What percentages Low / Med / High / Red Line?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Mechanisms",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Portfolio of engagement mechanisms - for engaging the organization in the Ai and Future ToolsProgram and Data quailty , Data capbiltiies , avialblility of Data .",
        "ITEMNO": 301,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the portfolio of engagement mechanisms adequate and appropriate for the AI and Future management Tools initiatives’ needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Mechanisms of Engagement"
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Mechanisms",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Portfolio of engagement mechanisms - for engaging the organization in the Ai and Future ToolsProgram and Data quailty , Data capbiltiies , avialblility of Data .",
        "ITEMNO": 302,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the portfolio of engagement mechanisms being used effectively to support the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Mechanisms",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Portfolio of engagement mechanisms - for engaging the organization in the Ai and Future ToolsProgram and Data quailty , Data capbiltiies , avialblility of Data .",
        "ITEMNO": 303,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent is the portfolio of engagement mechanisms capturing a good balance/mix of individuals from different levels and functional areas of the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Mechanisms",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Portfolio of engagement mechanisms - for engaging the organization in the Ai and Future ToolsProgram and Data quailty , Data capbiltiies , avialblility of Data .",
        "ITEMNO": 304,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of this portfolio of engagement mechanisms achieving its intended objectives? To what extent is it adequately feeding the Ai and Future Tools pipeline?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Mechanisms",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Portfolio of engagement mechanisms - for engaging the organization in the Ai and Future ToolsProgram and Data quailty , Data capbiltiies , avialblility of Data .",
        "ITEMNO": 305,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "At what level in the organization is the success of the engagement campaign being 'owned'?  Who is this person?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Individualwithin the organization who\"owns\"success of theengagementcampaign.",
        "ITEMNO": 306,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the individual who is ultimately 'owning' engagement success the appropriate person for the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Ownership of Engagement"
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Individualwithin the organization who\"owns\"success of theengagementcampaign.",
        "ITEMNO": 307,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is the individual who is 'owning' engagement success able to drive engagement effectively?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Individualwithin the organization who\"owns\"success of theengagementcampaign.",
        "ITEMNO": 308,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of an 'engagement success owner' achieving its intended objectives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Ownership",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Individualwithin the organization who\"owns\"success of theengagementcampaign.",
        "ITEMNO": 309,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What Tools are in place for tracking the level of engagement and striving to improve it?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Success",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Devicesin place fortrackingandimprovingorganizationalengagementin the program.",
        "ITEMNO": 310,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these Tools for tracking the level of engagement appropriate to the service's needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Success Tracking of Engagement"
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Success",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Devicesin place fortrackingandimprovingorganizationalengagementin the program.",
        "ITEMNO": 311,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the Tools for tracking the level of engagement being used effectively to support the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Success",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Devicesin place fortrackingandimprovingorganizationalengagementin the program.",
        "ITEMNO": 312,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the organization able to cultivate and empower its natural of digital transformation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Intrapreneurship",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Extent to which the program is promoting intrapreneurship.",
        "ITEMNO": 313,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is Technology engagement working overall? How broadly and how deeply are the organization's people engaged in the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Intrapreneurship"
      },
      {
        "GROUP": "AI ENGAGEMENT",
        "CAT\n NO": 18,
        "Transformation\n ELEMENT": "Engagement of Data",
        "WT": 10,
        "Transformation\n SUB-ELEMENT": "Success",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Extent to which engagement is workingoverall.How broadly and deeply people are ultimately engaged in the program.",
        "ITEMNO": 314,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What means for recognition are in place?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Overall Success"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Recognition",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Recognition- for individuals & teams.",
        "ITEMNO": 315,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these means for recognition appropriate to the AI and Future management Tools initiatives’ needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Recognition"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Recognition",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Recognition- for individuals & teams.",
        "ITEMNO": 316,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is recognition being used effectively to drive engagement in the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Recognition",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Recognition- for individuals & teams.",
        "ITEMNO": 317,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of recognition achieving its intended objectives? How well is it driving engagement?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Recognition",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Recognition- for individuals & teams.",
        "ITEMNO": 318,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What incentives are in place?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Incentives",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Incentives- for individuals&teams.",
        "ITEMNO": 319,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these incentives appropriate to the AI and Future management Tools initiatives’ needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Incentives"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Incentives",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Incentives- for individuals&teams.",
        "ITEMNO": 320,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are incentives being used effectively to drive engagement in the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Incentives",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Incentives- for individuals&teams.",
        "ITEMNO": 321,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of incentives achieving its intended objectives? How well is it driving engagement?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Incentives",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Incentives- for individuals&teams.",
        "ITEMNO": 322,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What rewards are in place?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Rewards",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Rewards- for individuals & teams.",
        "ITEMNO": 323,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these rewards appropriate to the AI and Future management Tools initiatives’ needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Rewards"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Rewards",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Rewards- for individuals & teams.",
        "ITEMNO": 324,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are rewards being used effectively to drive engagement in the AI and Future management Tools initiatives?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Rewards",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Rewards- for individuals & teams.",
        "ITEMNO": 325,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of rewards achieving its intended objectives? How well is it driving engagement?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Rewards",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Rewards- for individuals & teams.",
        "ITEMNO": 326,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What choices and flexibility exist for recipients in regards to the nature of the rewards they are given?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Flexibility",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Choicesandflexibilityin the rewards participants are offered.",
        "ITEMNO": 327,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these choices and flexibility appropriate to the AI and Future management Tools initiatives’ needs?",
        "METHOD\n OF\n ASSESSMENT": "Program Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Flexibility"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Flexibility",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Choicesandflexibilityin the rewards participants are offered.",
        "ITEMNO": 328,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the choices and flexibility being used effectively to drive engagement in the Service",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Flexibility",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Choicesandflexibilityin the rewards participants are offered.",
        "ITEMNO": 329,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of choices and flexibility achieving its intended objectives? How well is it driving An engagement?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Flexibility",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Choicesandflexibilityin the rewards participants are offered.",
        "ITEMNO": 330,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "In what ways, and to what extent, do incentives and rewards take in to account the unique motivators of each individual and team?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Motivations",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Unique motivatorsof individuals and teams.",
        "ITEMNO": 331,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are the ways in which these incentives and rewards take into account the unique AI motivators adequate to the service needs?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Motivation Consideration"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 19,
        "Transformation\n ELEMENT": "Recognition & Rewards",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Motivations",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Unique motivatorsof individuals and teams.",
        "ITEMNO": 332,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What internal communications are in place to highlight people, projects, and results so as to promote engagement in the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal ICT & PR",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 333,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these internal communications appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Internal ICT & PR"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal ICT & PR",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 334,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are internal communications being used effectively to drive AI engagement in the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal ICT & PR",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 335,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent has the organization succeeded at spotlighting its Priority of Future Tools 'internally?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 336,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent have internal communications succeeded at conveying a sense of purpose and mission that compels the organization to press forward with the 'new Future Tools'?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal ICT & PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 337,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of internal communications achieving its intended objectives? How well is it driving AI Engagement??",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "Internal ICT & PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal PR Activity-internal communications for promoting Service engagement.",
        "ITEMNO": 338,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What external communications are in place to publicly highlight endeavors and results to as to promote support for the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 4,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 339,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are these external communications appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "External ICT & PR"
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 340,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent are external communications being used effectively to drive support for the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 341,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent do external communications to Technology expert and similar stakeholders send an effective message of a commitment to AI and FMT transformation and long-term resilience?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 342,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "To what extent do external communications to the public drive home an effective message of Ai and Future Tools and progressiveness that builds the Government entity/Enterprise(s)?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 343,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "In what ways, and to what extent, is the use of external communications achieving its intended objectives? How well is it driving support?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "External ICT & PR",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Formal ICT & PR Activity-external communications for ICT & PRomoting Service support.",
        "ITEMNO": 344,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is the ICT activity viewed as a legitimate engagement marketing effort / tool within the organization?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "DIGITAL REINFORCEMENT",
        "CAT\n NO": 20,
        "Transformation\n ELEMENT": "ICT & PR",
        "WT": 5,
        "Transformation\n SUB-ELEMENT": "ICT & PR Value",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Extent to which ICT & PRactivity perceived aslegitimate engagement marketing tool.",
        "ITEMNO": 345,
        "READINESS": "X",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What are organizational perceptions of executive support for the Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "PR Value"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 21,
        "Transformation\n ELEMENT": "Leadership",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Support",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Executive support for, and time committed to, the Ai and Future ToolsProgram and its projects.",
        "ITEMNO": 346,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What percentage of executive time has been spent on nurturing Ai and Future Tools projects?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Leadership"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 21,
        "Transformation\n ELEMENT": "Leadership",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Time Commitment",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Executive support for, and time committed to, the Ai and Future ToolsProgram and its projects.",
        "ITEMNO": 347,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What strides have been made in gaining alignment and support from other organizational groups?",
        "METHOD\n OF\n ASSESSMENT": "Executive Time Tracking",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 22,
        "Transformation\n ELEMENT": "Environment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Alignment",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Creating an environment for promoting and nurturing innovation, \n removing obstacles to Ai and Future Toolsand change, and gaining alignment and support from other organizational groups (being a help rather than a hindrance).",
        "ITEMNO": 348,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What progress has been made in continuing to remove obstacles to Ai and Future Tools and change?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Environment"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 22,
        "Transformation\n ELEMENT": "Environment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Flow",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Creating an environment for promoting and nurturing innovation, \n removing obstacles to Ai and Future Toolsand change, and gaining alignment and support from other organizational groups (being a help rather than a hindrance).",
        "ITEMNO": 349,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What are organizational perceptions of the general character of the environment for promoting and nurturing innovation?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 22,
        "Transformation\n ELEMENT": "Environment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Character",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Creating an environment for promoting and nurturing innovation, \n removing obstacles to Ai and Future Toolsand change, and gaining alignment and support from other organizational groups (being a help rather than a hindrance).",
        "ITEMNO": 350,
        "READINESS": "",
        "TIME FRAME": "Past 1 Year",
        "QUESTION": "What is the level of participant engagement in, and satisfaction with, the overall Service?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 22,
        "Transformation\n ELEMENT": "Environment",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Engagement",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Participant engagement in, and satisfaction with, the Service.",
        "ITEMNO": 351,
        "READINESS": "",
        "TIME FRAME": "Now",
        "QUESTION": "What level of commitment exists to cultivating and using new, emerging Ai and Future Tools methods and skills?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Commitment",
        "ASSESSMENT\n CATEGORY": "Presence / Definition",
        "EXISTENCE": "X",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 352,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent is this level of commitment to cultivating and using new Ai and Future Tools methods and skills appropriate to the organization's situation and strategy?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Competence"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Commitment",
        "ASSESSMENT\n CATEGORY": "Appropriateness",
        "EXISTENCE": "",
        "APPROPIRATE": "X",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 353,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "To what extent has the organization been able to build increasing Ai and Future Tools capabilities and prowess?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Capabilities",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 354,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent has the organization been able to access a growing portfolio of growth organization competency?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Vehicles",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 355,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent has the right number and mix of individuals been trained and engaged in the Service?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Training",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 356,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What percentage of participants have been trained in AI and FMT?",
        "METHOD\n OF\n ASSESSMENT": "Service Review",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Training",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 357,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of participants have been certified in IAIDL?",
        "METHOD\n OF\n ASSESSMENT": "Training & Certification Tracking",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Certification",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 358,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What percentage of participants have been assessed for competency in the Ai and Future Tools methods and tools?",
        "METHOD\n OF\n ASSESSMENT": "Training & Certification Tracking",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Evaluation",
        "ASSESSMENT\n CATEGORY": "Use / Application",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "X",
        "AISERVICES(IMPLMENTED)": "",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 359,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "What is the average / typical rating on competency?",
        "METHOD\n OF\n ASSESSMENT": "Training & Certification Tracking",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Rating",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 360,
        "READINESS": "X",
        "TIME FRAME": "Now",
        "QUESTION": "In what ways, and to what extent, is competency in these methods proving effective at achieving the Service's intended outcomes?",
        "METHOD\n OF\n ASSESSMENT": "Training & Certification Tracking",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 23,
        "Transformation\n ELEMENT": "Competence",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Capabilities",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Growing Ai and Future Toolscapabilities & competencies.",
        "ITEMNO": 361,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the number of ideas generated internally and externally in each quarter? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 24,
        "Transformation\n ELEMENT": "Inputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Idea Generation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Idea generation volumeandidea generation quality / caliber- internally and externally.",
        "ITEMNO": 362,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "What has been the quality and calibre of ideas generated by the Service? To what extent is this adequate or improving?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 8,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Inputs"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 24,
        "Transformation\n ELEMENT": "Inputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Idea Generation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Idea generation volumeandidea generation quality / caliber- internally and externally.",
        "ITEMNO": 363,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "How much participant time is being invested in the Service each quarter? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 24,
        "Transformation\n ELEMENT": "Inputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Time Investment",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Participant timeinvestment.",
        "ITEMNO": 364,
        "READINESS": "",
        "TIME FRAME": "Past 2 Years",
        "QUESTION": "How much capital is being invested into the Service each quarter? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Participant Time Tracking",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 24,
        "Transformation\n ELEMENT": "Inputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Capital Investment",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Capital invested into the Service",
        "ITEMNO": 365,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the number of POC's passing screening in each quarter? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Capital Expenditure Review",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 25,
        "Transformation\n ELEMENT": "Throughputs",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Concept Selection",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Concept selection volumeandconcept selection quality / caliber- internally and externally.",
        "ITEMNO": 366,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the quality and caliber of concepts passing screening in each quarter? To what extent is this adequate or improving?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Throughputs"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 25,
        "Transformation\n ELEMENT": "Throughputs",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Concept Selection",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Concept selection volumeandconcept selection quality / caliber- internally and externally.",
        "ITEMNO": 367,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the financial value of the pipeline by stage? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 25,
        "Transformation\n ELEMENT": "Throughputs",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Valuation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Pipeline Valuation",
        "ITEMNO": 368,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "How much time is being required for projects to move through the pipeline from concept to reality? To what extent is this adequate or shrinking?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 25,
        "Transformation\n ELEMENT": "Throughputs",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "Speed",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Project throughput speed.",
        "ITEMNO": 369,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the number of concepts commercialized in each quarter? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 26,
        "Transformation\n ELEMENT": "Outputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Size",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Concept commercialization volume.",
        "ITEMNO": 370,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "How many design or utility patents has the organization been is sued over this time period? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Outputs"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 26,
        "Transformation\n ELEMENT": "Outputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Patents",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Patents",
        "ITEMNO": 371,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent has brand value increased due to new Ai and Future Tools (for any given brand)? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Patent Log",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 26,
        "Transformation\n ELEMENT": "Outputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Brand",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Brand Impact",
        "ITEMNO": 372,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What have been the impacts on the broader corporate strategy each year? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Market Research on Brand Lift",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 26,
        "Transformation\n ELEMENT": "Outputs",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Strategy",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Corporate Strategy Impact",
        "ITEMNO": 373,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the ratio of general Service outputs to inputs? To what extent is this improving?",
        "METHOD\n OF\n ASSESSMENT": "Targeted Interviews / 360 Surveys",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 27,
        "Transformation\n ELEMENT": "Efficiency",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "OI Ratio",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ratios-Outputs / Inputs,Successes / Failures, andCapital Efficiency(revenue & margin lift versus capital invested).",
        "ITEMNO": 374,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the ratio of 'market successes' to 'market failures'? To what extent is this improving?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Efficiency"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 27,
        "Transformation\n ELEMENT": "Efficiency",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "SF Ratio",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ratios-Outputs / Inputs,Successes / Failures, andCapital Efficiency(revenue & margin lift versus capital invested).",
        "ITEMNO": 375,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the rate of capital efficiency? To what extent is this improving?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 27,
        "Transformation\n ELEMENT": "Efficiency",
        "WT": 1,
        "Transformation\n SUB-ELEMENT": "CapEff",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Ratios-Outputs / Inputs,Successes / Failures, andCapital Efficiency(revenue & margin lift versus capital invested).",
        "ITEMNO": 376,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the mix of Ai and Future Tools types from around the organization model? To what extent is this appropriate or changing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 28,
        "Transformation\n ELEMENT": "Balance",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Type Mix",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mixes-Ai and Future ToolsType (Offerings / Markets / Channels / etc.),Risk Level (Core Markets / Adjacent Markets / New Markets), and Time Horizon (Short / Medium / Long Term).",
        "ITEMNO": 377,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the mix of risk levels in use? To what extent is this appropriate or changing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Balance"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 28,
        "Transformation\n ELEMENT": "Balance",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Risk Mix",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mixes-Ai and Future ToolsType (Offerings / Markets / Channels / etc.),Risk Level (Core Markets / Adjacent Markets / New Markets), and Time Horizon (Short / Medium / Long Term).",
        "ITEMNO": 378,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the mix of time horizons in use? To what extent is this appropriate or changing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 28,
        "Transformation\n ELEMENT": "Balance",
        "WT": 2,
        "Transformation\n SUB-ELEMENT": "Time Mix",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Mixes-Ai and Future ToolsType (Offerings / Markets / Channels / etc.),Risk Level (Core Markets / Adjacent Markets / New Markets), and Time Horizon (Short / Medium / Long Term).",
        "ITEMNO": 379,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the quarterly incremental revenue growth attributable directly to new innovations? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Biannually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Revenue",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Revenue Generation",
        "ITEMNO": 380,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What percentage of the organization's revenues are attributable to new innovations launched in this time frame? To what extent does this meet goals?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Financials"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Revenue",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Revenue Generation",
        "ITEMNO": 381,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the quarterly incremental margin gains attributable to new innovations? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Margin",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Margin Generation",
        "ITEMNO": 382,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the annualized percentage profit growth in this time frame attributable directly to new innovations? To what extent does this meet goals?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Margin",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Margin Generation",
        "ITEMNO": 383,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the quarterly incremental value of new markets entered or created  through innovation? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Valuation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Pipeline Valuation",
        "ITEMNO": 384,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the quarterly incremental value of new markets entered or created through innovation? To what extent is this adequate or growing?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Quarterly",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 29,
        "Transformation\n ELEMENT": "Financials",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Valuation",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Pipeline Valuation",
        "ITEMNO": 385,
        "READINESS": "",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "What has been the cumulative incremental value of new markets entered or created through innovation? To what extent does this meet goals?",
        "METHOD\n OF\n ASSESSMENT": "Inno Mgmt Tracking System",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 5,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 30,
        "Transformation\n ELEMENT": "Overall Success",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Service Success",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Service Success - Overall Goals",
        "ITEMNO": 386,
        "READINESS": "X",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent is the organization's Service succeeding in achieving its stated goals?",
        "METHOD\n OF\n ASSESSMENT": "CInO Annual Report",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 7,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": "Overall Success"
      },
      {
        "GROUP": "OUTCOMES",
        "CAT\n NO": 30,
        "Transformation\n ELEMENT": "Overall Success",
        "WT": 3,
        "Transformation\n SUB-ELEMENT": "Service Success",
        "ASSESSMENT\n CATEGORY": "Outcomes / Results",
        "EXISTENCE": "",
        "APPROPIRATE": "",
        "UTLIZATION": "",
        "AISERVICES(IMPLMENTED)": "X",
        "SUBJECT AREA": "Service Success - Overall Goals",
        "ITEMNO": 387,
        "READINESS": "X",
        "TIME FRAME": "Past 3 Years",
        "QUESTION": "To what extent has the organization been able to consistently raise the bar on its Ai and Future Tools goals each year? By what percentage?",
        "METHOD\n OF\n ASSESSMENT": "CInO Annual Report",
        "ASSESSMENT\n PERIOD": "Annually",
        "ANSWERS / EVIDENCE": "",
        "TARGET\n RATING": "",
        "RATING": 6,
        "SUB-ELEMENT CLUSTER\n \n FOR AIMA": ""
      }
    ]
    let nAima = {}
    this.aimaAns = []
    let abc = 0
    let efg = 0
    aimaO.forEach(q=>{
      for (const key in q) {
        if (q.hasOwnProperty(key)) {
          const val = q[key];
          
          q[key.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')] = val
          q.rating = 0
          delete q[key]
          if(key.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_') == 'group'){
            if(!nAima[val.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]){
              
              nAima[val.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')] = []
              nAima[val.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')].push(q)
            } else {
              q.rating = 0
              nAima[val.toLowerCase().replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')].push(q)
            }
          }
        }
      }
    })
    let ss = {
      "item1":9, "item2":8, "item3":7, "item4":8, "item5":8, "item6":7, "item7":7, "item8":8, "item9":7,
      "item10":6, "item11":7, "item12":6, "item13":5, "item14":4, "item15":6, "item16":5, "item17":6,
      "item18":7, "item19":8, "item20":9, "item21":8, "item22":8, "item23":9, "item24":8, "item25":7,
      "item26":7, "item27":7, "item28":6, "item29":8, "item30":9, "item31":8, "item32":7, "item33":6,
      "item34":7, "item35":6, "item36":8, "item37":7, "item38":8, "item39":9, "item40":7, "item41":7,
      "item42":6, "item43":8, "item44":7, "item45":6, "item46":8, "item47":7, "item48":7, "item49":7,
      "item50":6, "item51":6, "item52":7, "item53":6, "item54":6, "item55":7, "item56":6, "item57":5,
      "item58":8, "item59":7, "item60":6, "item61":6, "item62":6, "item63":6, "item64":8, "item65":8,
      "item66":8, "item67":7, "item68":6, "item69":7, "item70":7, "item71":6, "item72":6, "item73":8,
      "item74":7, "item75":6, "item76":8, "item77":8, "item78":7, "item79":7, "item80":8, "item81":7,
      "item82":7, "item83":7, "item84":6, "item85":8, "item86":7, "item87":7, "item88":6, "item89":7,
      "item90":7, "item91":8, "item92":7, "item93":7, "item94":8, "item95":7, "item96":6, "item97":7,
      "item98":7, "item99":7, "item100":8, "item101":7, "item102":7, "item103":8, "item104":7,
      "item105":7, "item106":7, "item107":6, "item108":6, "item109":6,"item110":7, "item111":7,
      "item112":6, "item113":6, "item114":8, "item115":8, "item116":6, "item117":6, "item118":8,
      "item119":7, "item120":6, "item121":5, "item122":6, "item123":6, "item124":5, "item125":5,
      "item126":6, "item127":6, "item128":5, "item129":5, "item130":6, "item131":6, "item132":5,
      "item133":5, "item134":7, "item135":6, "item136":6, "item137":7, "item138":7, "item139":7,
      "item140":7, "item141":7, "item142":6, "item143":7, "item144":6, "item145":6, "item146":6, 
      "item147":7, "item148":7, "item149":6, "item150":8, "item151":8, "item152":7, "item153":7,
      "item154":7, "item155":6, "item156":6, "item157":6, "item158":5, "item159":5, "item160":6,
      "item161":6, "item162":4, "item163":4, "item164":4, "item165":7, "item166":6, "item167":6,
      "item168":6, "item169":5, "item170":5, "item171":7, "item172":7, "item173":6, "item174":2,
      "item175":2, "item176":2, "item177":5, "item178":7, "item179":6, "item180":7, "item181":7,
      "item182":6, "item183":7, "item184":7, "item185":6, "item186":6, "item187":5, "item188":4,
      "item189":6, "item190":6, "item191":5, "item192":5, "item193":5, "item194":6, "item195":5,
      "item196":5, "item197":6, "item198":6, "item199":6, "item200":7, "item201":7, "item202":6,
      "item203":6, "item204":7, "item205":6, "item206":5, "item207":6, "item208":6, "item209":5,
      "item210":6, "item211":5, "item212":4, "item213":4, "item214":4, "item215":3, "item216":7,
      "item217":7, "item218":5, "item219":5, "item220":5, "item221":5, "item222":5, "item223":8,
      "item224":8, "item225":8, "item226":8, "item227":8, "item228":7, "item229":6, "item230":6,
      "item231":6, "item232":6, "item233":5, "item234":5, "item235":7, "item236":7, "item237":6,
      "item238":6, "item239":7, "item240":6, "item241":7, "item242":5, "item243":7, "item244":7,
      "item245":6, "item246":6, "item247":7, "item248":7, "item249":7, "item250":6, "item251":7,
      "item252":7, "item253":7, "item254":6, "item255":6, "item256":7, "item257":6, "item258":6,
      "item259":6, "item260":5, "item261":8, "item262":7, "item263":7, "item264":4, "item265":4,
      "item266":3, "item267":7, "item268":5, "item269":5, "item270":5, "item271":5, "item272":6,
      "item273":6, "item274":6, "item275":6, "item276":6, "item277":6, "item278":6, "item279":6,
      "item280":6, "item281":5, "item282":5, "item283":5, "item284":4, "item285":4, "item286":3,
      "item287":3, "item288":4, "item289":4, "item290":4, "item291":3, "item292":7, "item293":7,
      "item294":6, "item295":6, "item296":6, "item297":5, "item298":4, "item299":4, "item300":4,
      "item301":6, "item302":6, "item303":6, "item304":6, "item305":5, "item306":6, "item307":6,
      "item308":5, "item309":5, "item310":6, "item311":5, "item312":5, "item313":6, "item314":5,
      "item315":7, "item316":6, "item317":6, "item318":5, "item319":7, "item320":6, "item321":6,
      "item322":5, "item323":7, "item324":6, "item325":5, "item326":5, "item327":5, "item328":4,
      "item329":4, "item330":4, "item331":4, "item332":4, "item333":5, "item334":5, "item335":5,
      "item336":5, "item337":5, "item338":4, "item339":6, "item340":6, "item341":6, "item342":5,
      "item343":5, "item344":5, "item345":5, "item346":7, "item347":7, "item348":7, "item349":6,
      "item350":7, "item351":6, "item352":7, "item353":8, "item354":8, "item355":7, "item356":7,
      "item357":6, "item358":7, "item359":6, "item360":6, "item361":7, "item362":8, "item363":7,
      "item364":7, "item365":7, "item366":7, "item367":7, "item368":6, "item369":5, "item370":7,
      "item371":5, "item372":6, "item373":6, "item374":6, "item375":7, "item376":7, "item377":6,
      "item378":6, "item379":6, "item380":7, "item381":6, "item382":7, "item383":6, "item384":7,
      "item385":5, "item386":7, "item387":6, "weights" : {"Strategy":14,"Sponsorship & Leadership":3,"Philosophy / Beliefs":2,"Values":3, "Culture & Environment":4,"Structure  & Relationships":3,"Governance Processes":3, "Governance Metrics":2,"Roles & Responsibilities":3,"Role Certification":1,"Funding":5, "Budget Management":3,"External Investment":2,"Training & Resources":3,"Discovery & Insights":3, "Technology & Infrastructure":3,"Networks & DATA":3,"Engagement of Data":10,"Recognition & Rewards":5, "ICT & PR" :5,"Leadership":2,"Environment":2,"Competence":2,"Inputs":2,"Throughputs":1,
      "Outputs":2,"Efficiency":1,"Balance":2,"Financials":3,"Overall Success":3}
		}
    let aimaToken = `IAIDL_AIMA_4dD8aDw5Qr`
    let input = await db.ref(`aima/${aimaToken}/aima`).once('value').then(x=>x.val())
    let a = [].concat(...Object.values(input))
    // console.log(a)
    for (const key in ss) {
      if (ss.hasOwnProperty(key) && key.includes('item')) {
        const val = ss[key];
        if(a.filter(x=>x.itemno == key.replace('item', ''))[0] 
          && a.filter(x=>x.itemno == key.replace('item', ''))[0].rating) {
          ss[key] = parseInt(a.filter(x=>x.itemno == key.replace('item', ''))[0].rating)
        } else {
          ss[key] = 0
        }
      }
    }

    this.aimah = nAima
    // await db.ref(`settings/aima`).update(this.aima)
    // db.ref(`settings/aimaCategories`).update(Object.keys(this.aima))
  }
}
function goToDash(){
  window.location.href = '/'
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
