import {
  firebase,
  db,
  auth,
  createFirebase,
  storageRef
} from '@/fb';

function randomString(length, chars) {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  var result = '';
  for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
}

export function ge() {

  let m = ``
  let robotsQs = `1.	أي من الميزات التالية تميز الروبوتات عن الأجهزة الأخرى؟
الحركة.	○
الاستشعار عن بعد.	○
الذكاء.	○
كل ما ذكر.	●

2.	أي مما يلي هو مكون أساسي في الروبوتات؟
المحول البرمجي.	○
المتحكم.	●
الدماغ.	○
غير ذلك.	○

3.	يتم تزويد الروبوت دائمًا بأجهزة استشعار تسمح للروبوت بتقدير ما حوله.
صح	●
خطأ	○



4.	أي مما يلي يمكن أن يساعد الروبوتات على اكتشاف بيئتهم؟
كل الخيارات.	○
أجهزة التحكم.	○
محول البرمجيات.	○
أجهزة الاستشعار.	●

5.	يمتلك الروبوت القدرة على تحريك ونقل المنتجات من خلال عجلات أو أرجل.
صح	●
خطأ	○



6.	لا تعمل الروبوتات بشكل جيد في أداء المهام المتكررة (الوظائف الروتينية).
صح	○
خطأ	●



7.	يعود تاريخ روبوتات الجيل الأول إلى سبعينيات القرن العشرين، حيث كانت تتألف من أجهزة كهروميكانيكية ثابتة وغير قابلة للبرمجة بدون أجهزة استشعار. 
صح	●
خطأ	○



8.	أي من الروبوتات التالية يمكن أن تعمل كنظام مستقل وكامل مع وحدة التحكم الخاصة بها؟
روبوت بأرجل.	○
روبوت منزلي.	○
روبوت مستقل.	●
كل ما ذكر.	○

9.	أي مما يلي هو التعريف الصحيح للروبوت؟
لا شيء مما يلي.	○
آلة مصممة لتنفيذ مهمة واحدة أو أكثر تلقائيًا بسرعة ودقة.	●
آلة مصممة لتنفيذ مهمة واحدة فقط تلقائيًا بسرعة ودقة.	○
آلة مصممة لتنفيذ العديد من المهام تلقائيًا ببطء وبدون دقة.	○

10.	يمكن للروبوت الذكي التعلم من بيئته وتجربته والبناء على قدراته بناءً على المعرفة.
صح	●
خطأ	○



11.	أي من الكيانات التالية يمكن أن تحاكي الأعمال البشرية؟
كل ما يلي.	○
تجهيز السيارات الروبوتية (RPA)	○
أتمتة العمليات الآلية (RPA)	●
أتمتة البرمجة الروبوتية (RPA)	○

12.	أي مما يلي هو أحد أنواع الروبوتات بحسب الحركة؟
الروبوتات الصناعية.	○
روبوتات الفضاء.	○
الروبوتات المكعبة.	●
الروبوتات المنزلية.	○

13.	يقوم الذكاء الاصطناعي تعليم الآلات والروبوتات كيفية التفاعل واتخاذ القرارات التي من شأنها وضع ذكاء البشر في أجسام الروبوتات.
صح	●
خطأ	○



14.	يمكن للروبوتات أن تستشعر البيئة عن طريق أجهزة استشعار متكاملة أو من رؤية الحاسوب.
صح	●
خطأ	○



15.	أي من التالي يعرف بأنه انضباط الروبوتات الاجتماعية؟
كل ما يلي.	○
التفاعل بين الروبوتات والبيئة	●
لغات البرمجة.	○
الإدراك الذاتي.	○

16.	كم مبدأ يوجد للذكاء الاصطناعي والتعلم الآلي في الروبوتات؟
لا شيء مما يلي.	○
3	○
4	●
5	○

17.	أي من مبادئ الذكاء الاصطناعي التالية تساعد الروبوتات على التفاعل مع العقبات وتجنب العوائق؟
الرؤية.	○
التحكم بالحركة	●
مجموعة البيانات.	○
غير ذلك.	○

18.	أي من الخيارات التالية يمكن أن تساعد الروبوتات على تحديد الأشياء وتحديد أفضل موقف واتجاه لها؟
الرؤية.	●
التحكم بالحركة	○
الإدراك.	○
غير ذلك.	○

19.	بواسطة أي مما يلي يمكن للروبوتات اكتشاف بيئتها؟
الإدراك.	○
التحكم في الحركة.	○
الرؤية.	●
كل ما ذكر.	○

20.	يلتقط جهاز التعلم العميق الروبوتي صورًا ثلاثية الأبعاد لمستخدم ينفذ المهمة معينة على شيء معين.
صح	●
خطأ	○



21.	أي مما يلي يُمكّن الروبوتات من اكتساب المهارات بشكل مستقل، ولا سيما المهارات التي تتطلب تعلُّمًا حسيا حركيا؟
تعلم الآلة.	○
التعلم العميق.	●
بدون تعلم.	○
غير ذلك.	○

22.	يعد سانبوت مزيجًا مثاليًا من الإنسان والآلة والذي يتضمن تصميمًا مريحًا يلائم أحدث تقنيات الروبوت.
صح	●
خطأ	○



23.	يمكن لسانبوت فهم البيئة الخارجية والتواصل معها من خلال الكاميرات.
صح	●
خطأ	○



24.	أول من صاغ مصطلح روبوت هو؟
نوربرت وينر في كتابه "علم التحكم الآلي".	○
ماري شيلي في كتابها "فرانكشتاين".	○
إسحاق أسيموف في كتابه الأول "روبوت".	○
كاريل تشابيك في مسرحيته "رجال روسوم الآلية العالمية"	●

25.	الروبوت المستقل يختلف عن المركبات التي تعمل عن بعد تحت الماء (ROV) وذلك لأن:
ROV يُتحكم بها عن بعد	○
تحكم الروبوت المستقل داخلي	●
ليس هناك فرق	○
الإجابات "1" و "2"	○

26.	ما هو اسم الأجهزة التي تجعل المناول الروبوتي يتحرك؟
متحكمات المناول	○
تحكم المناول	○
المحركات	●
بتفعيل الإنسان لها	○

27.	ما اسم ذراع الروبوت؟
المتحكم	○
المعصم	○
الكوع	○
المناول (Manipulator)	●

28.	أي من الخيارات التالية يتحكم في الطريقة التي يعمل بها نظام الحاسوب ويوفر وسيلة يمكن للمستخدمين من خلالها التفاعل مع معه؟
نظام التشغيل	●
تطبيق البرمجيات	○
اللوحة الأم	○
المنصة	○

29.	أي من البيانات التالية هي البيانات التي تم تنظيمها أو تقديمها بجدوى؟
عملية	○
البرمجيات	○
المعلومات	●
التخزين	○

30.	أي من التالي لا يعد من الأجزاء الخمسة الأساسية للروبوت؟
النهاية العاملة للروبوت	○
الأدوات الملحقة	●
المستشعر	○
المتحكم	○

31.	إذا كان بإمكان الروبوت تغيير مساره الخاص استجابة للظروف الخارجية، فسيتم اعتباره:
روبوتات Non-Servo	○
بحلقة مفتوحة	○
روبوت ذكيا	●
متحركا	○

32.	ما الحس الذي تمتلكه الروبوتات؟
الانساني	●
الذوق	○
الموضة	○
الشم	○

33.	ما اسم المساحة التي تعمل داخلها وحدة الروبوت؟
البيئة	○
منطقة الخطر	○
قاعدة مكانية	○
غلاف العمل	●

34.	أي من الخصائص التالية تحدد الروبوت؟
تعدد وظائفها	○
إمكانية البرمجة	○
التشغيل الذاتي	○
كل ما ذكر	●

35.	قد تستخدم التروس في محركات الحركة لـ:
تغيير سرعة الدوران.	○
زيادة عزم الدوران.	○
تغيير اتجاه الدوران.	○
كل ما ذكر.	●

36.	إن اتخاذ الإجراءات خطوة بخطوة واستخدام لغة البرمجة المنطقية من خلال تسلسل الأحداث المنطقي بتخطيط المهمة بأكملها في البداية هو؟ 
كل ما يلي.	○
الخوارزميات والبرمجيات.	●
الأجهزة والبرمجيات.	○
البرمجيات فقط.	○

37.	الروبوتات قادرة على اتخاذ قرار بشأن قدرتهم الخاصة وتنفيذ المهام التي قاموا ببرمجتها.
صح	○
خطأ	●



38.	أي مما يلي يعتبر حدا للروبوتات؟
كل ما يلي.	○
التكامل المستحيل مع الأدوات المستقبلية الأخرى.	○
التكامل المحتمل مع الأدوات المستقبلية الأخرى.	●
التكامل الجزئي Pratley  مع الأدوات المستقبلية الأخرى.	○

39.	أي مما يلي عبارة عن مجموعة من الأنشطة، أو سلسلة من الأنشطة، تؤدي مهمة ذات مغزى؟
كل ما يلي.	○
التسلسل.	●
المربع.	○
الاحتياط.	○

40.	أي مما يلي يساعد المستخدم على العمل على أي نظام وأتمتة أي مهام؟
لا شيء مما يلي.	○
عملية المعاملات.	○
تحسينات عملية العميل (APIs)	●
إطار عمل الروبوتات.	○

41.	أي مما يلي يمثل أي مهمة تقوم بها مثل النقر على الزر الأيمن للفأرة لتلصق النص؟
كل ما يلي.	○
الأنشطة	●
المتواليات.	○
المتحكمات.	○

42.	لدى الروبوت مجموعة متنوعة من أجهزة الاستشعار التي تدعم وظائفها وتحركاتها في الأرجاء.
صح	●
خطأ	○



43.	أي من اللغات التالية هي أشهر لغات البرمجة للروبوتات؟
C++	○
جافا سكريبت.	○
بايثون (Python)	●
غير ذلك	○

44.	أي من الروبوتات التالية لها تشابه سطحي مع البشر؟
الروبوتات البشرية (Androids)	●
الروبوتات الصناعية.	○
الروبوتات الطبية.	○
غير ذلك.	○

45.	الروبوتات هي الآلات القادرة على أداء المهام والأنشطة المبرمجة مسبقًا.
صح	●
خطأ	○



46.	يتم دمج الذكاء الاصطناعي المعروف أيضًا باسم الذكاء الآلي في الروبوتات لمنحهم القدرة على إظهار السلوك البشري المشابه.
صح	●
خطأ	○



47.	أي من الأجزاء التالية من الروبوتات يمكنها معالجة البيانات والتفكير في الإجراءات التي ينبغي اتخاذها؟
لا شيء مما يلي.	○
الأذرع.	○
وحدة المعالجة المركزية CPU.	●
أجهزة الاستشعار.	○

48.	يتم إعطاء ميزة التعرف على الوجه والصوت للروبوتات بمساعدة؟
كل ما يلي.	○
الكاميرا والميكروفون.	●
الواي فاي والبلوتوث.	○
المجسات والمتحكمات.	○

49.	صنع الروبوتات ليس عملية سهلة ويتطلب قدرا كبيرا من المعرفة والصبر والقوة.
صح	●
خطأ	○



50.	أي من الخيارات التالية تستخدم لتخطيط وخلق شعور مصطنع بالوعي الذاتي في الروبوت؟
الإدراك.	●
التنبؤ.	○
التفتيش.	○
غير ذلك	○
`

  let iotQs = `1.	يقوم إنترنت الأشياء بربط الأشياء اليومية المضمنة بالإلكترونيات والبرامج وأجهزة الاستشعار بالإنترنت مما يتيح لهم جمع البيانات وتبادلها 
صح	●
خطأ	○



2.	من أي المصادر التالية تجمع البيانات في تقنيات إنترنت الأشياء؟
كل ما يلي	●
إشارات المرور.	○
الهواتف الذكية.	○
أنظمة تحديد المواقع العالمية.	○

3.	تعتمد بروتوكولات الاتصال والشبكات والاتصالات المستخدمة مع هذه الأجهزة التي تدعم الويب إلى حد كبير على تطبيقات إنترنت الأشياء المحددة المنشورة.
صح	●
خطأ	○



4.	يمكن إدارة أضواء الشوارع باستخدام المستشعرات وعمليات النشر.
صح	●
خطأ	○



5.	لدى إنترنت الأشياء حماية كبيرة للخصوصية والأمان.
صح	○
خطأ	●



6.	ما نوع المخاطر التي سيتعرض لها المستخدمون أثناء التعامل مع إنترنت الأشياء؟
التعرض للقرصنة	○
اعتبارات الثقة	○
حماية البيانات	○
كل ما تم ذكره	●

7.	كيف يمكن للمؤسسات أن تتأكد من أن جميع البيانات لم تُخترق ولم يتم اعتراضها؟
باستخدام اسم المستخدم وكلمة المرور لحواسيب الموظفين المنزلية.	○
بإغلاق الحواسيب بعد انتهاء الموظفين من العمل.	○
من خلال الجمع بين جميع أساليب الأمن والحماية في التكنولوجيا	●
غير ذلك	○

8.	أي مما يلي هي المخاطر الناتجة فيما يتعلق بإنترنت الأشياء؟
التلاعب بالجهاز	○
تزوير البيانات	○
سرقة IP ، والتلاعب بالشبكة ، وجرائم الإنترنت الأخرى	○
كل ما تم ذكره	●

9.	تعد كيفية حماية البيانات الضخمة -الناتجة عن إنترنت الأشياء- من مجرمي الإنترنت أحد أكبر التحديات التي نواجهها 
صح	●
خطأ	○



10.	ستقوم الأجهزة المتصلة بطريقة آلة إلى آلة بتوصيل العديد من العمليات الصناعية بالشبكات لأول مرة بفضل بطاقات SIM المدمجة.
صح	●
خطأ	○



11.	معظم الملابس اللياقة الذكية لديها 
بطارية	○
بلوتوث	○
أجهزة استشعار	●
إنترنت	○

12.	يتم اقتناء أجهزة التوجيه في المنازل دون تثبيت الأمان في معظمها، وبالتالي تكون معرضة جدًا للقرصنة والتهديدات الإلكترونية. 
صح	●
خطأ	○



13.	أي مما يلي يعتبر تهديدا للخصوصية في إنترنت الأشياء وتعتبر مصدر قلق كبير لمستخدمي إنترنت الأشياء؟
كل ما يلي	○
الجواسيس	○
القراصنة	●
حصان طروادة	○

14.	أي مما يلي يعتبر فائدة إنترنت الأشياء في المباني الذكية؟
لا شيء مما يلي.	○
تخفيض تكاليف الطاقة.	●
تقليل استهلاك الغاز.	○
دعم إجراءات اللياقة البدنية.	○

15.	يمكن أن يساعد إنترنت الأشياء المستشفيات والمؤسسات الطبية على القيام بـ ؟
جمع بيانات المرضى وتحليلها للحصول على خدمات أفضل.	●
أخذ الأشعة السينية مع المعدات المستقبلية.	○
حفظ معلومات الممرضين.	○
تقليل تكاليف المعدات الطبية.	○

16.	أي مما يلي سمة من سمات إنترنت الأشياء؟
كل الخيارات	●
الاتصال.	○
التحليل.	○
الدمج.	○

17.	تبلغ جميع تطبيقات هذه التكنولوجيا أوجها بتوفير راحة أكثر ومواءمة وإدارة أفضل ، وبالتالي سنحصل على جودة حياة محسنة.
صح	●
خطأ	○



18.	فيما يتعلق بمزايا إنترنت الأشياء، أي مما يلي يعد ميزة له؟
توفير الوقت.	○
توفير المال.	○
جودة حياة أعلى	○
جميع ما تم ذكره	●

19.	يتيح إنترنت الأشياء منصة مشتركة لجميع الأجهزة بتفريغ بياناتها.
صح	●
خطأ	○



20.	تتم مشاركة نتائج إنترنت الأشياء مع الأجهزة الأخرى لتحسين أتمتة تجربة المستخدم وتحسين الكفاءة.
صح	●
خطأ	○



21.	أي مما يلي يحتاج إلى إنترنت الأشياء في مجتمعاتنا؟
كل ما يلي.	○
توسيع الترابط بين البشر	●
تقليل وحدات التخزين للبيانات.	○
زيادة اتصالات الإنترنت.	○

22.	في إنترنت الأشياء، يتم إنشاء التوصيلات الرئيسية بين أجهزة مختلفة، أي مما يلي هو اسم هذه التوصيلات؟
ند لند (Peer to Peer)	○
آلة لآلة (Machine to Machine)	●
جهاز لجهاز (Computer to Computer)	○
لا شيء مما ذكر	○

23.	أي مما يلي هو فائدة إنترنت الأشياء للبشر؟
كل ما يلي.	○
تقليل الجهود البشرية.	●
زيادة مصادر الطاقة.	○
تقليل استهلاك المياه.	○

24.	تسمى عملية التكامل بين النماذج لتحسين تجارب المستخدمين؟
تحليل.	○
الاتصال.	○
الدمج.	●
جميع ما ذكر	○

25.	في اتصال إنترنت الأشياء، تكون المراسلة عالية السرعة بين الجهاز والسحابة لأن كل اتصال يجب أن يكون موثوقًا به.
صح	●
خطأ	○



26.	أي من العمليات التالية توفر تحليلًا التحليل في الوقت الفعلي لتدفق البيانات الواردة مع تجميع الأحداث، وتنقيتها، وربطها؟
كل ما يلي.	○
المعالجة الخلفية.	○
معالجة السيول	●
تجهيز الجهاز.	○

27.	أي مما يلي يثري تدفقات البيانات بالمعلومات السياقية ويولد تدفقات مركبة من المعلومات؟
إثراء البيانات.	●
ثروة البيانات.	○
مخزن البيانات.	○
لا شيء مما ذكر.	○

28.	أي مما يلي صحيح حول اتصال المؤسسة؟
لا شيء مما يلي.	○
نقل بيانات وأحداث إنترنت الأشياء المهمة ديناميكيًا إلى التطبيقات وتدفقات المعالجة 	●
تمكين المؤسسات ذات المخازن عالية السعة.	○
تطوير متطلبات تكنولوجيا المعلومات والاتصالات للمؤسسات.	○

29.	أي مما يلي يمكنه إرسال رسائل إلى الأجهزة من تطبيقات المؤسسات والأجهزة المحمولة، بغض النظر عن اتصال الجهاز؟
كل ما يلي.	○
التحكم الصوتي.	○
القيادة والتحكم	●
نظام تشغيل الحاسوب.	○

30.	عندما توضع أجهزة الاستشعار التي لها عنوان IP في شارع مزدحم، على سبيل المثال، يمكن لمسؤولي المدينة تنبيه السائقين بشأن أي تأخير مقبل أو عن حوادث واقعة 
صح	●
خطأ	○



31.	من خلال تتبع البيانات حول استخدام الطاقة ومستويات المخزون، يمكن للشركة تخفيض تكاليفها الإجمالية بشكل كبير.
صح	●
خطأ	○



32.	يتكون نظام انترنت الأشياء البيئي من الأجهزة الذكية المدعومة على شبكة الإنترنت والتي تستخدم المعالجات المدمجة وأجهزة الاستشعار وأجهزة الاتصالات لجمع البيانات التي يحصلون عليها من بيئاتهم وإرسالها والتصرف بها. 
صح	●
خطأ	○



33.	في الحفاظ على البيئة، كيف يمكن لإنترنت الأشياء الحد من التلوث؟
إرسال البيانات عن امتلاء حاويات القمامة	●
غير ذلك	○
إرسال معلومات عن أضواء الشوارع.	○
إرسال معلومات عن إشارات المرور.	○

34.	يمكن أن يكون تتبع المخزون مفيدًا للشركات، فما الذي يمكن أن يفعله إنترنت الأشياء؟
تتبع مستوى المخزون.	○
تتبع سلسلة التوريد.	○
تتبع الأوامر.	○
كل ما ذكر.	●

35.	من خلال تتبع سلوك المستهلك داخل متجر ما، يمكن لبائع التجزئة نظريا ً أن يقدم توصيات لمنتج مصممة خصيصًا لتزيد من الحجم الإجمالي للبيع.
صح	●
خطأ	○



36.	أي من الأجهزة التالية تستخدم أيضًا للسلامة العامة؟
الأجهزة التي يمكن ارتداؤها.	●
الملابس التقليدية.	○
المركبات الذكية.	○
غير ذلك	○

37.	أي من التقنيات التالية تضبط درجة الحرارة تلقائياً كتطبيق منزلي أو للسيارة؟
لا شيء مما يلي	○
إنترنت الأفكار	○
انترنت الأشياء	●
إنترنت التهديدات	○

38.	يمكن أن تساعد الأنظمة الذكية القائمة على إنترنت الأشياء باستخدام أجهزة الاستشعار على رصد الضوء ودرجة الحرارة ورطوبة الجو والتربة في حقول المحاصيل.
صح	●
خطأ	○



39.	كيف يتم حماية البيانات الضخمة الناتجة عن إنترنت الأشياء من مجرمي الإنترنت
من خلال تطبيق إجراءات السلامة.	○
من خلال تطبيق جميع إجراءات تكنولوجيا المعلومات والاتصالات لحماية البيانات.	●
عن طريق إنشاء دعم على نفس الحواسيب.	○
غير ذلك	○

40.	قال خبراء البيانات إن إنترنت الأشياء سيواصل بازدياده لفترة طويلة من الزمن 
صح	●
خطأ	○



41.	تعرضت شبكة الأجهزة مفتوحة الوصول المتصلة بإنترنت الأشياء للعديد من التهديدات والهجمات، ما هو أعلى مستوى من الهجمات؟
كل ما يلي.	○
هجمات DDoS.	●
جوائز DDoS.	○
هدايا DDoS.	○

42.	يتوفر لدى Norton كل شيء، بدءًا من نظام أسماء النطاقات (DNS) إلى تشفير البيانات وتحديثات الأمان التي يتم إجراؤها تلقائيًا بانتظام.
صح	●
خطأ	○



43.	أي مما يلي سيكون ضرورة لاستخدامه في كل الصناعات؟
كل ما يلي.	○
إنترنت الأشياء (IoT).	●
أشياء الإنترنت (TOI).	○
الذكاء الاصطناعي.	○

44.	تمثل مسألة الأمن مشكلة يواجهها الكثيرون نظرًا لعدد التطورات التي يتم إجراؤها في هذه الفترة القصيرة من الوقت.
صح	●
خطأ	○



45.	سيتم توصيل السيارات بالإنترنت، أي من التقنيات التالية سوف تدعم هذه الاتجاهات المستقبلية؟
القيادة الذاتية.	●
المركبات الكهربائية.	○
السيارات فائقة الذكاء	○
غير ذلك	○

46.	الخصوصية / الأمان: مع إرسال جميع بيانات إنترنت الأشياء هذه، يزداد خطر فقدان الخصوصية. 
صح	●
خطأ	○



47.	لدى إنترنت الأشياء جانب مظلما وكذلك أمن البيانات. نظرًا لعدد التطورات التي يتم إجراؤها في هذه الفترة القصيرة من الوقت.
صح	●
خطأ	○



48.	أي مما يلي هو التحدي الأكبر لتكنولوجيا إنترنت الأشياء؟
حماية البيانات الضخمة من مجرمي الإنترنت.	●
منع الموظفين من استخدام الشبكات الاجتماعية خلال ساعات العمل.	○
منع المستخدمين من التقاط الصور في المناطق المحظورة.	○
غير ذلك	○

49.	أي مما يلي يمثل جانبًا فعالًا في إنترنت الأشياء؟
لا شيء مما يلي	○
اعتبارات الثقة	●
حماية البيانات	○
التعرض للقرصنة	○

50.	أي مما يلي سيجعلك عرضة للقرصنة؟
كل ما يلي.	○
التشفير الضعيف والأبواب الخلفية.	●
كلمات المرور القوية.	○
نقاط الضعف في إشارة Wi-Fi.	○
`
  let dronesQs = `1.	ماذا يعني UAV في الذكاء الاصطناعي؟
جميع ما ذكر	○
مركبة جوية بدون طيار.	●
محاو مركبة بدون طيار.	○
السيارات الأمريكية المتحدة.	○

2.	أصبحت الطائرات بدون طيار الأداة المهمة الوحيدة لتكنولوجيا الذكاء الاصطناعي.
صح	○
خطأ	●



3.	ما الذي ستحتاجه الطائرة المسيّرة لاكتشاف المناطق الجغرافية التي لا توجد فيها بنية تحتية؟
كل الخيارات	○
كاميرا عالية الدقة	●
ميكروفون.	○
نظام تحديد المواقع.	○

4.	أول طائرة تم تصميمها كانت مزودة بآلية للتحكم بالراديو من النوع القابل لإعادة الاستخدام 
صح	●
خطأ	○



5.	تم تصميم أول طائرة في الثلاثينيات وأصبحت نموذجًا أساسيًا لجميع التطورات الجديدة في عالم اليوم.
صح	●
خطأ	○



6.	بناءً على عدد المراوح، لدينا:
كل ما ذكر	○
كوادكوبتر (Quadcopter).	●
طائرة ثابتة	○
طائرة مهجنة	○

7.	كلما زاد وزن الطائرة المسيّرة، كانت مدة الرحلة أطول.
صح	○
خطأ	●



8.	لا يمكن تثبيت الطائرات المسيّرة في الهواء بسبب اتجاه الرياح.
صح	○
خطأ	●



9.	تسمى القوة الميكانيكية التي يطبقها أي سائل لمعارضة حركة أي كائن؟
الدفع	○
الشد	○
الإسقاط	○
السحب	●

10.	أي مما يلي هو القوة التي تساعد على التغلب على مقاومة السحب؟
كل ما ذكر	○
السحب	○
الدفع	●
الإسقاط	○

11.	أي من القوى التالية المسؤولة عن رفع الطائرة المسيّرة في الهواء ضد ثقل الطائرة؟
لا شيء مما يلي	○
السقوط	○
الرفع	●
السحب	○

12.	أي مما يلي مسؤول عن تحريك الطائرة للخلف أو للأمام
كل الخيارات	○
الانحدار	●
الرفع	○
السقوط.	○

13.	الطائرة النموذجية المسيّرة مصنوعة من مواد مركبة خفيفة لغرض تخفيف الوزن وزيادة القدرة على المناورة.
صح	●
خطأ	○



14.	أي مما يلي يتناسب مع الطائرة المسيّرة صغيرة
Wi-Fi	●
القرص الصلب	○
خزان الماء	○
غير ذلك	○

15.	لا يقبل مجال التصوير استخدام الطائرات المسيّرة بسبب الارتفاع.
صح	○
خطأ	●



16.	أي مما يلي يمكن استخدام الطائرات المسيّرة للقيام به في مجال الزراعة:
نشر المياه في جميع أنحاء المنطقة	○
دعم الرعاية الصحة للمزارعين	○
مراقبة سلامة المحاصيل	●
كل ما ذكر.	○

17.	يمكن للطائرات المسيّرة تحمل أي وزن وإيصال الطرود لأي مكان.
صح	○
خطأ	●



18.	استخدم مركز ميريلاند الطبي DJI M600 Pro لنقل الكلى البشرية من مكان إلى آخر.
صح	●
خطأ	○



19.	لم يستخدم نظام MAPPER؟
جميع ما ذكر	○
تصوير الأحداث في الهواء الطلق.	○
في رسم الخرائط والمسح التصويري 	●
التقاط صور سيلفي.	○

20.	بموجب أي النظام توفر الطائرات المسيّرة مجموعة من أنظمة رسم الخرائط وتقلل من وقت الفعلي للأداء؟
كل المذكور أدناه	○
Reducer 	○
Mapper	●
Tector 	○

21.	ما هو اسم المركبات المتصلة بالإنترنت وذاتية الدفع؟
المركبات الطائرة.	○
مركبة ذاتية القيادة	●
طائرات مسيّرة.	○
لا شيء مما ذكر.	○

22.	تعتبر السيارات الكهربائية نوعًا من المركبات ذاتية القيادة.
صح	○
خطأ	●



23.	أي من المجالات التالية يمكن أن يتم تبني استخدام الطائرات المسيّرة؟
مراقبة الحياة البرية.	●
الخدمات اللوجستية.	○
القطاع المالي.	○
لا شيء مما ذكر.	○

24.	قد تبدو الطائرة المسيّرة بسيطة للغالبية ولكن الجهاز يحمل في الواقع تكنولوجيا معقدة من الداخل.
صح	●
خطأ	○



25.	يمكن تمديد وقت الطيران بدون طيار باستخدام بطاريات قوية في التصميم.
صح	●
خطأ	○



27.	يستخدم نطاق التردد 2.4 جيجا هرتز في معظم الطائرات المسيّرة لاتخاذ قرارات نشطة بشأن التحركات.
صح	●
خطأ	○



28.	يتم التحكم في عملية الهبوط أيضًا بواسطة نظام المروحة الموجود في الداخل وتتخذ المستشعرات قرارات بشأن سرعتها وارتفاعها وتدويرها وما إلى ذلك
صح	●
خطأ	○



29.	أي واحد مما يلي يمثل استخدام الطائرات المسيّرة في الولايات المتحدة الأمريكية؟
للمراقبة	○
للقتل	○
للشرطة	○
جميع ما ذكر	●

30.	متى بدأت اليابان باستخدام طائرات المسيّرة؟
منذ 13 سنة.	○
منذ 14 سنة.	○
منذ 15 سنة.	●
قبل 16 سنة.	○

31.	الطائرات المسيّرة تقلع وتهبط عموديا وتطير ببطء في ارتفاعات منخفضة وتحمّل أقل.
صح	●
خطأ	○



32.	ما هي شروط استخدام الطائرات المسيّرة في صناعات النقل والشحن؟
كل ما يلي.	○
الحمل والسعة والمدة.	○
السرعة والأداء والدقة	●
المسافة والمساحة والأداء الوظيفي.	○

33.	أي مما يلي من خصائص الطائرات المسيّرة؟
التقنيات المستخدمة في الطائرات المسيّرة.	○
مستوى الحكم الذاتي.	○
حجم الطائرة المسيّرة.	○
كل ما ذكر.	●

34.	أي مما يلي هو تصنيف الطائرات المسيّرة على أساس الحجم؟
جميع الخيارات التالية	●
صغير جدا	○
متوسط.	○
كبير.	○

35.	أي مما يلي يعتبر جزءا من الطائرة المسيّرة؟
البطاريات التقليدية.	○
 التحكم الإلكتروني بالسرعة	●
العجلات الثقيلة.	○
غير ذلك	○

36.	ما هو المصطلح المستخدم للحركة الجانبية للطائرة، حيث يتم التحكم في سرعة المحركات اليمنى بالنسبة لسرعة المحركات اليسرى كل ما يلي.
السحب	○
الالتفاف	●
الانعراج	○
كل ما تم ذكره	○

37.	يتم التحكم في الطائرات المسيّرة بواسطة أنظمة التحكم عن بعد الأرضية (GSC) ويشار إليها أيضًا باسم قمرة القيادة الأرضية.
صح	●
خطأ	○



38.	أي مما يلي يساعد على امتصاص الاهتزاز؟
لا شيء من المذكور	○
المواد الهندسية الجيدة	●
مواد بناء.	○
مواد الغوص.	○

39.	أي مما يلي ضروري للحصول على رخصة الطائرة المسيّرة؟
التدريب لمدة 150 إلى 200 ساعة.	○
التقدم بطلب للحصول على شهادة الطيران عن بعد.	●
تحديث الترخيص كل 5 سنوات.	○
لا شيء مما ذكر.	○

40.	أي نظام مما يلي يعتبر من الأنظمة المتكاملة للطائرة المسيّرة؟
LiDAR 	○
MAPPER 	○
TECTOR 	○
كل ما ذكر.	●

41.	أي من الأنظمة التالية تستخدم للتخطيط ، و الطيران  و معالجة وتصور البيئة؟
LiDAR 	●
MAPPER 	○
TECTOR 	○
كل ما ذكر.	○

42.	حاليا، يتم تطبيق قوانين المركبات بموجب معاهدات دولية متعددة لضمان اتباع نهج ثابت تجاه المسؤولية.
صح	●
خطأ	○



43.	إلى ماذا نحتاج لتنظيم استخدام الطائرات المسيّرة؟
كل ما يلي.	○
واي فاي وإيثرنت.	○
البنية التحتية والاتصالات.	●
مسارات جوية عالية	○

44.	يخضع النقل الجوي للعديد من اللوائح والإشراف الإضافيين من قبل كل دولة.
صح	●
خطأ	○



45.	تحظى الطائرات بشعبية في الذكاء الاصطناعي والصناعات المختلفة مثل السينما والتصوير الفوتوغرافي.
صح	●
خطأ	○



46.	ماذا يحدث عندما نجمع بين مزايا استخدام طائرة بدون طيار في الذكاء الاصطناعي؟
لا شيء	○
تكامل تكنولوجي.	●
تسهيل وظائف.	○
زيادة الدخل.	○

47.	الطائرات المسيّرة التي تمتلك عددًا كبيرًا من المحركات بداخلها قادرة على التحكم بشكل أكبر في ارتفاعها، وبالتالي يمكنها استيعاب المزيد من الأحمال أثناء الرحلة. 
صح	●
خطأ	○



48.	أي مما يلي يلعب دورًا مهمًا في آلية الطيران؟
المتحكم.	●
المراوح.	○
المحركات.	○
كل ما ذكر.	○

49.	تعمل الطائرة المسيّرة كوحدة هواء الذكية ويمكنها أن تغطي مسافة كبيرة عند استخدامها ببطاريات قوية
صح	●
خطأ	○



50.	يعد وضع Gesture رائعًا لإنتاج أفلام خالية من جهاز التحكم عن بُعد.
صح	○
خطأ	●


`

  let bigDataQs = `1.	إن الدور الرئيسي لعالم البيانات في أي مؤسسة هو وصف الأنماط المسبقة باستخدام خوارزمية الذكاء الاصطناعي وخوارزمية تعلم الآلة. 
صح	○
خطأ	●



2.	أي مما يلي يعبر عن الخصائص الخمسة للبيانات الضخمة 5Vs ؟
الحجم ، التنوع ، الموثوقية ، السرعة والقيمة	●
المتجهات، متنوعة، الموثوقية، فايبر والقيمة	○
الحجم ، التصوير ، الموثوقية ، المتجهات والقيمة	○
غير ذلك	○

3.	البيانات الضخمة عبارة عن مصطلح يصف الحجم الضخم للبيانات، المهيكلة، شبه المهيكلة وغير المهيكلة على حد سواء، والتي تغمر عمل ما على أساس يومي.
صح	●
خطأ	○



4.	أي مما يلي يعتبر من فوائد البيانات الضخمة؟
تسهيل اتخاذ قرارات أفضل تعتمد على تحليلات البيانات في كافة مجالات الأعمال	●
تقليل تكاليف إدارة المعاملات وتعزيز القرارات في كل مجال من مجالات الأعمال.	○
السيطرة على نمو الأعمال وتخزين البيانات.	○
غير ذلك	○

5.	تُستخدم البيانات المخزنة لإنشاء إعلانات مستهدفة عبر الإنترنت تتيح للشركات استهداف عملائها من خلال تحليلات البيانات الضخمة.
صح	●
خطأ	○



6.	أي مما يلي يعد مصدرا للبيانات الضخمة؟
وسائل التواصل الاجتماعي	●
سجلات الموظفين.	○
تجارة التجزئة.	○
غير ذلك	○

7.	مع ظهور البيانات الضخمة، أصبح Hadoop، وهو إطار متخصص في عمليات البيانات الضخمة، شائعًا أيضًا.
صح	●
خطأ	○



8.	أي من الطرق التالية سوف تساعد على زيادة إيرادات الأعمال؟
يوفر تحليل البيانات الضخمة توصيات ومقترحات مخصصة للشركات.	●
يعمل تحليل البيانات الضخمة على توسيع سوق الأسهم في صناعة الاتصالات.	○
سوف يقلل تحليل البيانات من الأرباح والتكاليف للشركات.	○
غير ذلك	○

9.	يأخذ Hadoop دورا رئيسيا في قدراته بـ:
التخزين	○
المعالجة	○
جمع البيانات	○
كل ما تم ذكر	●

10.	تتضمن عملية تصنيف مصادر البيانات الضخمة أحد الإجراءات التالية:
سجلات المخزون من الصناعة اللوجستية فقط.	○
السجلات التعليمية من الجامعات المحلية فقط.	○
المعاملات التجارية والمعاملات ذات الصلة بين كيانات الأعمال.	●
غير ذلك	○

11.	أي مما يلي صحيح حول طرق عرض البيانات؟
تمثيل رسومي للبيانات	●
طباعة ثلاثية الأبعاد للبيانات.	○
موجودة على شاشة عرض باور بوينت.	○
غير ذلك	○

12.	أي مما يلي يعد نوعا من أنواع عرض البيانات؟
صور.	○
فيديو.	○
لوحات (Dashboards)	●
شاشات.	○

13.	أي مما يلي هو التسلسل الصحيح لدورة إدارة البيانات الضخمة؟
الدمج، التنظيم، الالتقاط، التصرف والتحليل.	○
الالتقاط، التنظيم، الدمج، التحليل والتصرف	●
التصرف والتحليل والتكامل والتنظيم والقبض.	○
الالتقاط ، التصرف ، التنظيم ، الدمج والتحليل.	○

14.	أي من البيانات التالية يسمح Hadoop بتخزينها على الخوادم:
البيانات المنظمة فقط.	○
البيانات مهيكلة وغير مهيكلة وشبه مهيكلة.	●
فقط السجلات والحقول.	○
جميع ما ذكر	○

15.	الوصول إلى البيانات المسجلة في Hadoop متاح حتى بعد فشل الأجهزة
صح	●
خطأ	○



16.	تسمح لك Clusters Hadoop بتخزين أي بيانات ثم تحديد كيفية رغبتك في استخدامها لاحقًا دون الحاجة إلى إعادة تهيئة البيانات المذكورة.
صح	●
خطأ	○



17.	لا يتيح Hadoop معالجة البيانات التي يتم توزيعها عبر التجمع في نموذج موازٍ.
صح	○
خطأ	●



18.	أي مما يلي يعتبر من مزايا الـ Hadoop؟
قابلية التوسع.	○
استرجاع البيانات.	○
محلية البيانات.	○
كل ما تم ذكر	●

19.	يعتبر Hadoop إطار متخصص في عمليات البيانات الضخمة.
صح	●
خطأ	○



20.	ما هي الخطوات اللازمة لنشر حل البيانات الضخمة؟
استيعاب البيانات.	○
تخزين البيانات.	○
معالجة البيانات.	○
جميع ما ذكر	●

21.	أي مما يلي يعتبر تطبيق الزمن الحقيقي لـ  Hadoop؟
الوكالات المالية.	○
الدفاع والأمن السيبراني.	○
إدارة المشاركات على وسائل التواصل الاجتماعي.	○
جميع ما ذكر	●

22.	إن توفر البيانات الضخمة يجعل من تدريب نماذج التعلم الآلي أمرا ممكنا.
صح	●
خطأ	○



23.	يتحمل علماء البيانات مسؤولية التنبؤ بالسيناريوهات والحوادث المستقبلية استنادًا إلى الأنماط المسبقة باستخدام خوارزميات الذكاء الاصطناعي وتعلم الآلة.
صح	●
خطأ	○



24.	ما هي الأدوار الرئيسية لتحليل البيانات في المنظمات؟
ادخال بيانات.	○
فحص وتنظيف و تحويل و نمذجة البيانات.	●
وصف الأحداث.	○
غير ذلك	○

25.	يعد تحليل البيانات في الأعمال مسؤولاً عن إجابة جميع الأسئلة التي تطرحها الأعمال حول البيانات المسبقة.
صح	●
خطأ	○



26.	 أي مما يلي يعتبر فائدة للبيانات الضخمة في المؤسسات؟
إعداد البيانات لتخزينها على خوادم Hadoop فقط.	○
تعزيز ثقافة التعاون والاستقصاء والابتكار داخل المؤسسة بأكملها.	●
الاحتفاظ بسجلات لسجل المؤسسة وجميع المعاملات السابقة.	○
جميع ما ذكر	○

27.	أيا مما يلي نحتاج إلى القيام به في إدارة البيانات الضخمة وإنشاء خطط متسارعة؟
السماح للمستخدمين بالغوص بشكل حدسي في البيانات التي يرونها مناسبة، دون الحاجة إلى الاعتماد على تقنية المعلومات لتشغيل الاستعلامات وإنشاء التقارير.	●
منع المستخدمين من الغوص في البيانات دون خلفية تكنولوجيا المعلومات والاتصالات.	○
السماح فقط لأفراد تكنولوجيا المعلومات بالعمل على البيانات الضخمة.	○
غير ذلك	○

28.	كمية كبيرة من فحص البيانات وإنتاج أنماط ومعلومات مفيدة تعرف باسم:
فحص البيانات	○
تحليل المعلومات	○
تحليلات البيانات الضخمة	●
تحليل البيانات	○

29.	أي مما يلي يعد ميزة لـ Hadoop؟
مفتوح المصدر	○
مسترجع للبيانات	○
قابل التوسعة	○
كل ما ذكر	●

30.	أي من الخطوات التالية لم يتم تضمينها في خطوات نشر حلول البيانات الضخمة؟
استيعاب البيانات	○
معالجة البيانات	○
أنشطة البيانات	●
تخزين البيانات	○

31.	البيانات الضخمة هي بيانات ذات كمية هائلة متسارعة ومتنوعة تتطلب طرقًا مبتكرة لفهمها بشكل أعمق لاستخدامها بشكل أفضل في عملية صنع القرار.
صح	●
خطأ	○



32.	باستخدام البيانات الضخمة ، يمكن لشركات التأمين اكتشاف مطالبة احتيالية محتملة والتحقق من قدرة مراجعة المطالبات أو الموافقة عليها أو رفضها.
صح	●
خطأ	○



33.	لماذا توفر أنظمة تكنولوجيا المعلومات والاتصالات التقليدية أسس التكامل مع تقنيات البيانات الضخمة مثل Hadoop ؟
إدارة البيانات الضخمة وتنقيب البيانات.	●
تخزين البيانات وذكاء الأعمال.	○
إدارة تجمعات Hadoop.	○
جمع وتخزين البيانات غير المهيكلة.	○

34.	أي من التالي لا يصف Hadoop؟
مصدر مفتوح.	○
ذو زمن فعلي	●
منهج حوسبة موزع.	○
قائم على جافا	○

35.	أي مما يلي لديه أكبر تجمع Hadoop؟
دراماتكس Dramatics	○
أبل Apple	○
فيس بوكFacebook	●
غير ذلك	○

36.	أي مما يلي يعد من المكونات الرئيسة للبيانات الضخمة:
مابريديوس.	○
HDFS.	○
YARN.	○
جميع ما ذكر	●

37.	أي مما يلي يعتبر ميزة من ميزات تحليلات البيانات الضخمة؟
قابلية التوسع..	○
استرجاع البيانات.	○
ذو مصدر مفتوح	○
جميع ما ذكر	●

38.	كيف يعالج الـ Facebook قاعدة البيانات الضخمة باستخدام Hadoop؟
عن طريق تكييف Prism.	○
باستخدام Project Prism.	●
باستخدام Project Data.	○
باستخدام Project Bid.	○

39.	هناك مجموعة كاملة من أساليب عرض وتقديم البيانات بطرق فعالة ومثيرة للاهتمام. أي من الأساليب التالية يمكن استخدامها؟
الخرائط	○
الرسوم البيانية	○
الرسوم البيانية للمعلومات	○
جميع ما ذكر	●

40.	تعد صيانة مجموعات تجمعات Hadoop  بمثابة بداية للمصارف والمؤسسات المالية لجذب مصادر البيانات مثل سجلات مكالمات العملاء والدردشة وسجلات الويب والبريد الإلكتروني وغير ذلك.
صح	●
خطأ	○



41.	أي مما يلي هو المبدأ الأساسي لاستخدام الأجهزة في كمية كبيرة من البيانات؟
شراء خوادم منتظمة مع الأقراص الصلبة باهظة الثمن جدا.	○
شراء خوادم منتظمة مع وحدات المعالجة المركزية القوية والأقراص الصلبة العادية.	○
شراء خوادم ذات سعة ذاكرة RAM كبيرة بدلاً من الأقراص الصلبة لتخزين الملفات.	○
لا شيء من المذكورة.	●

42.	استلهم مشروع Hadoop من عمل Google على نظام ملفات جوجل (الموزعة) (GFS) ونموذج برمجة MapReduce
صح	●
خطأ	○



43.	أي مما يلي يمثل غرض استخدام البيانات الضخمة في القطاع المالي؟
منع الاحتيال.	○
تحديد سمات العملاء	○
تصميم الخدمات	○
جميع ما ذكر	●

44.	في القطاع المصرفي، تستخدم البنوك البيانات المالية وبيانات الدفع لتقييم جدارة ائتمان المستهلك.
صح	●
خطأ	○



45.	ما هي المخاوف الرئيسة المحتملة حول استخدام البيانات الضخمة؟
وصول المستهلكين إلى المنتجات / الخدمات.	○
ممارسات التسعير العادل.	○
المحدودية والأخطاء.	○
جميع ما ذكر	●

46.	أي الوظائف التالية يمكن أن يؤديها عالم البيانات؟
تحديد الأسئلة.	○
إنشاء رموز استنساخيه.	○
تحدي النتائج.	○
جميع ما ذكر	●

47.	تصوير البيانات هو تنظيم المعلومات وفقا لمواصفات محددة مسبقا.
صح	○
خطأ	●



48.	في البيانات الضخمة، يركز تنظيف البيانات على التنبؤ، استنادًا إلى الخصائص المعروفة المستفادة من بيانات التدريب.
صح	●
خطأ	○



49.	أي مما يلي يعتبر خاصية نسبية للبيانات الضخمة التي تهتم أكثر بعلوم البيانات؟
التسارع	○
التنوع	●
الحجم	○
غير ذلك	○

50.	بعد الحصول على البيانات، ما الخطوة التالية التي يجب أن يقوم بها علماء البيانات؟
تنظيف البيانات	●
تكامل البيانات	○
النسخ المتماثل للبيانات	○
كل ما ذكر	○
`

  let aiQs = `1.	أي مما يلي يعد اختلافا بين الذكاء الاصطناعي والذكاء البشري؟
لا توجد اختلافات.	○
يتعلم البشر من خلال الأنماط، و الآلات تتعلم من خلال القواعد.	●
ينتقي الذكاء الاصطناعي المعلومات من خلال الأنماط، والذكاء البشري من القواعد.	○
جميع ما ذكر	○

2.	أي مما يلي يعد من تصنيفات الذكاء الاصطناعي؟
الذكاء البصري	○
الذكاء اللغوي	●
ذكاء الحاسوب	○
غير ذلك	○

3.	أي مما يلي يعد نوعا من أنواع الذكاء الاصطناعي؟
جميع ما يلي	●
الذكاء الاصطناعي المحدود	○
الذكاء الاصطناعي الشامل	○
الذكاء الاصطناعي الخارق	○

4.	يتطلب الذكاء الاصطناعي إمكانية حوسبة عالية الأداء.
صح	●
خطأ	○



5.	أي مما يلي يتكيف مع تطبيقات الذكاء الاصطناعي؟
جميع ما ذكر.	○
محرك بحث Google التنبؤي.	●
ألعاب Snowball  للهواتف المتحركة.	○
مراقبة سلسلة التوريد بالتجزئة.	○

6.	إن نموذج التعلم الآلي - المكون الأخير في عملية التعلم الآلي- مبرمج باستخدام خوارزمية التعلم الآلي.
صح	○
خطأ	●



7.	أي مما يلي هو الخطوة الأولى لحل مشكلة في خوارزمية التعلم الآلي؟
جميع ما ذكر	○
تصنيف المشكلة.	○
فهم وتحليل المشكلة	●
تنظيف البيانات وإعدادها	○

8.	بصدد تصنيف مشاكل التعلم الآلي، يشغل تنظيف البيانات وإعدادها حوالي 80٪ من الدورة.
صح	●
خطأ	○



9.	أي مما يلي يعتبر نوعا من أنواع التعلم الآلي؟
جميع ما يلي	●
تعليم الآلة الخاضع للإشراف.	○
تعليم الآلة بدون إشراف.	○
تعليم الآلة المعزز.	○

10.	أي مما يلي صحيح حول التعلم الآلي دون إشراف؟
جميع ما ذكر	○
التعلم باستخدام مجموعة بيانات جيدة من المسميات	○
التعلم باستخدام مجموعة البيانات ذات المسميات الواضحة	●
التعلم من خلال التجارب التراكمية	○

11.	أي مما يلي هو النهج الصحيح للتعلم الآلي الخاضع للإشراف؟
جميع ما ذكر.	○
خريطة مدخلات ذات مسميات كاملة تؤدي إلى مخرجات معروفة	●
فهم الأنماط واكتشاف المخرجات	○
تتبع طريقة المحاولة والخطأ	○

12.	الخوارزميات الرئيسية للتعلم الآلي تحت الإشراف هي: الانحدار الخطي، الانحدار اللوجستي، آلة داعم المتجهات، إلخ.
صح	●
خطأ	○



13.	تعلم الآلة بدون إشراف هو تدريب الآلة باستخدام البيانات الغير مسماة، والسماح للخوارزمية بالتصرف بناءً على تلك المعلومات دون توجيه.
صح	●
خطأ	○



14.	أي مما يلي يعتبر تقنية نستخدمها لندرس أو ندرب الآلة عن طريق البيانات ذات المسميات الكاملة بشكل جيد؟
جميع ما ذكر	○
تعلم الآلة المعزز	○
تعلم الآلة الخاضع للإشراف	●
تعلم الآلة بدون إشراف.	○

15.	أي مما يلي صحيح حول تعلم الآلة المعزز (Reinforcement Learning)
جميع ما ذكر	○
لا يوجد أي بيانات مدخلة إلى الجهاز، عليه استكشاف كل شيء من البداية.	●
يتم إدخال جميع البيانات إلى الجهاز للتعلم.	○
يتم إدخال 50٪ من البيانات، ويميز الجهاز البيانات المتبقية.	○

16.	من حدود التعلم الآلي أنه لا يمكنه التعامل مع البيانات متعددة الأبعاد، حيث يزداد التعقيد مع تجاوز عدد الأبعاد عن البعدين.
صح	●
خطأ	○



17.	أي مما يلي صحيح حول التعلم العميق؟
جميع ما ذكر	○
مجموعة فرعية من الذكاء الاصطناعي تستخدم الشبكات العصبية لمحاكاة عملية صنع القرار لدى الإنسان.	●
يتحكم في نموذج تفكير الآلة لاتخاذ القرار من خلال برامج.	○
لا علاقة له بالذكاء الاصطناعي على الإطلاق.	○

18.	أي مما يلي صحيح حول الشبكات العصبية؟
جميع ما ذكر	○
يمكننا أن نقول أن العصبون أو الخلية العصبية هي خلية تحتوي على قيمة رقم واحد فقط 	●
لا يوجد شيء يسمى بالشبكات العصبية.	○
شبكة محلية عالية السرعة للمؤسسات.	○

19.	يلعب الذكاء الاصطناعي دورًا حيويًا في الصناعات المختلفة، مثل البنوك، والصناعة الطبية ، والصناعات الثقيلة، والنقل ، والألعاب ، ودعم الأنشطة البشرية.
صح	●
خطأ	○



20.	أي مما يلي صحيح حول تعلم الآلة المعزز (Reinforcement Learning)؟
جميع ما ذكر.	○
التعلم من خلال التجارب التراكمية.	●
التعلم عن طريق البيانات ذات المسميات الجيدة	○
التعلم عن طريق الأوامر الصوتية.	○

21.	يستخدم التعلم العميق الشبكات العصبية لحل التحديات المعقدة.
صح	●
خطأ	○



22.	في الشبكات العصبية، تعمل الطبقة الأولى كطبقة مدخلات، والطبقة الأخيرة كطبقة مخرجات، وجميع الطبقات الموجودة بينها ليست طبقات مخفية.
صح	○
خطأ	●



23.	أي من آلات الذكاء الاصطناعي هذه يمكنها أن تتكيف اجتماعيا وتدرك مشاعر الإنسان جيدا:
جميع ما ذكر.	○
الذكاء الاصطناعي المحدود.	○
الذكاء الاصطناعي الشامل.	○
الذكاء الاصطناعي الخارق.	●

24.	أي مما يلي يعد استخداما للذكاء الاصطناعي في Twitter؟
تحديد أي نوع من خطاب الكراهية واللغة الإرهابية في التغريدات	●
تحديد أي نوع من الاهانات والأذى بين العلاقات.	○
تحديد نوع العلاقة التي تربط الناس ببعضها.	○
جميع ما ذكر	○

25.	ما نوع البيانات المستخدمة في تعلم الآلة بدون إشراف؟
جميع ما ذكر	○
البيانات المسماة.	○
البيانات غير المسماة.	●
بيانات غير معرفة مسبقا.	○

26.	أي مما يلي هو التدريب الصحيح للتعلم الآلي الخاضع للإشراف؟
جميع ما ذكر.	○
الإشراف الخارجي.	●
تدريب بدون إشراف.	○
بإشراف الإنسان.	○

27.	في أي عام ظهرت الشبكات العصبية كوسيلة للتعلم العميق؟
في السبعينات	○
في الثمانينات	●
في التسعينات	○
في ألفية القرن 21	○

28.	في الشبكات العصبية، يمكن تقسيم الطبقات إلى طبقات فرعية أخرى.
صح	●
خطأ	○



29.	أي مما يلي سوف يعزز عملية حساب وتقنيات التعلم العميق؟
القدرة الحسابية	○
قدرات التصور	○
السرعة الحاسوبية	●
غير ذلك.	○

30.	يستخدم الفيسبوك تقنية التحقق من الوجوه حيث يتم استخدام مفهوم التعلم الآلي والتعلم العميق للكشف عن ملامح الوجه وإنشاء إشارات.
صح	●
خطأ	○



31.	صممت Tesla سيارة يمكنها اكتشاف الأشياء والعقبات والقيادة بسلاسة على الطرق.
صح	●
خطأ	○



32.	أي مما يلي يعد لغة برمجة للتعلم الآلي؟
جميع ما يلي	●
Java Scripts 	○
python	○
Lisp	○

33.	أي مما يلي يعد سبباً في أهمية التعلم الآلي؟
زيادة كمية البيانات المنتجة يوميا.	○
تحديد وكشف أنماط البيانات.	○
تعزيز وتحسين آلية صنع القرار.	○
جميع ما ذكر.	●

34.	أي مما يلي يعرف بتقسيم البيانات إلى جزئين – بيانات التدريب (نموذج القطار)، اختبار البيانات (اختبار كفاءة النموذج- بعد تغذية النموذج)؟
اختبار البيانات.	○
تدريب بيانات.	○
ربط البيانات.	●
ادخال البيانات.	○

35.	في أي عام ظهر أول تعريف رسمي لنموذج العصبون الاصطناعي؟
1940	○
1943	●
1946	○
1949	○

36.	يتطلب استخدام التعلم العميق في وسائل التواصل الاجتماعي إدخال صورة لكل فرد يستخدم وسائل التواصل.
صح	●
خطأ	○



37.	ما الذي نحتاجه لتصنيف الصور بمزيد من التفاصيل في التعلم العميق؟
زيادة طبقات المدخلات	○
زيادة طبقات المخرجات	○
زيادة الطبقات المخفية	●
لا تفعل شيئا	○

38.	في أي شبكة يتم تنفيذ التعلم العميق من خلالها؟
الشبكات الخاصة الافتراضية	○
الشبكات العصبية	●
الشبكات المنطقة المحلية	○
الشبكات العريضة	○

39.	الكيانات المستقلة تستخدم أجهزة استشعار لمعرفة ما يجري، ثم تستخدم المشغلات لأداء مهامها أو أهدافها.
صح	●
خطأ	○



40.	من أين أتت فكرة الشبكات العصبية؟
الوظائف بيولوجية.	○
الخلايا العصبية البيولوجية.	●
الأنسجة البيولوجية.	○
جميع ما ذكر	○

41.	أي مما يلي يعتبر هدفا لتعليم الآلات من خلال البيانات؟
جميع ما ذكر	○
حل المشكلات	●
اكتشاف أنماط للكشف عن المزايا	○
التفكير مثل البشر	○

42.	أي مما يلي صحيح حول الذكاء الاصطناعي؟
مصطلح يستخدم للتعبير عن برمجة السيارات ذاتية الحركة.	○
مجال من مجالات علوم الحاسوب التي تعكس اختراع الأجهزة الذكية	●
مصطلح يستخدم لشرح كيفية تعلم البرمجة البشرية.	○
غير ذلك	○

43.	في محاولة الكشف عن عمليات الاحتيال، يطبق الذكاء الاصطناعي خوارزميات تعليم آلية للكشف عن الحالات الشاذة ودراسة الأنماط الخفية في البيانات.
صح	●
خطأ	○



44.	تعتمد عملية التعلم العميق على استخدام الشبكات العصبية الاصطناعية لحل المشكلات المعقدة.
صح	●
خطأ	○



45.	في التعلم العميق، يقلد الجهاز الطريقة التي يعمل بها عقلك ويتعلم من خلال التجارب.
صح	●
خطأ	○



46.	تتكون الشبكة العصبية العميقة من الطبقات التالية
جميع ما يلي	●
طبقة المدخلات.	○
طبقة خفية.	○
طبقة المخرجات.	○

47.	أي مما يلي يعد تقييما يستخدم لاختبار ذكاء الآلة؟
اختبار تورينغ (Turning Test).	●
اختبار تورنينغ (Tourning Test).	○
الاختبار الذاتي (Self-Test).	○
الاختبار الرياضي (Sporting Test).	○

48.	يعتبر TensorFlow برنامجًا مفتوح المصدر يستخدم لبرمجة تدفق وانسياب البيانات.
صح	●
خطأ	○



49.	أي مما يلي صحيح حول تعليم الآلة؟
تعليم الآلة كيفية رسم صورة من خلال الأوامر.	○
طريقة يتم بها تغذية الجهاز بالكثير من البيانات، بحيث يمكنه اتخاذ قراراته الخاصة.	●
التعلم الآلي هي إحدى الطرق لصنع غواصة لمياه عميقة.	○
جميع ما ذكر	○

50.	إن توفر كمية هائلة من البيانات هو أهم شيء لإنشاء نماذج تنبؤية وتقديم نتائج دقيقة من خلال تحليل البيانات.
صح	●
خطأ	○


`

  let threeDQs = `1.	النحت والحفر والطحن واستخدام الإزميل كلها أمثلة على؟
التصنيع التجميعي	○
الصناعات التحويلية	○
القطع	○
تشكيل	●

2.	أي من الحرف التالية من المرجح أن تستخدم القطع كوسيلة تصنيع؟
حرفة الخشب	○
حرف الفراء والجلود 	○
النمذجة ثلاثية الأبعاد	○
جميع الحرف تستخدم القطع كوسيلة تلفيق	●

3.	الغرض من استخدام مواد الدعم في الطباعة ثلاثية الأبعاد؟
زيادة متانة المنتج النهائي	●
 سهولة التجميع والمعالجة بعد الانتهاء	○
تقليل النفايات	○
يدعم الطبقات عند طباعتها، ويعمل كسقالات	○

4.	أي من التقنيات التالية قادرة على طباعة المعادن؟
الصهر بالليزر (LM)	●
النمذجة بترسيب مصهور (FDM)	○
ذوبان الحزمة الإلكترونية (EBM) 	○
نفث المواد الرابطة (BJ)	○

5.	أي من التقنيات التالية تبني أجزاء النموذج في هندسة الطباعة بواسطة البلاستيك؟
النمذجة بترسيب مصهور (FDM)	○
تقنية ربط الصورة بالمادة (Photopolymer jetting)	●
نفث المواد الرابطة (BJ)	○
لا شيء مما ذكر.	○

6.	ما هي الاعتبارات التي يجب عليك الأخذ بها عند اختيار تقنية الطباعة ثلاثية الأبعاد؟
المواد ومتانتها	●
نقطة الانصهار وانتهاء السطح	○
الوقت والتفاصيل	○
لا شيء مما ذكر	○

7.	أي نوع من الشبكات يصنع أسلس النماذج؟
مضلع عالي الكثافة	○
مضلعات أصغر	○
دقة أعلى	○
كل ما ورد أعلاه	●

8.	ما هو الشيء الأكثر أهمية الذي يجب مراعاته عند صنع شيء ما ثلاثي الأبعاد؟
الحجم	●
الجاذبية	○
الأداء الوظيفي	○
الجودة	○

9.	ما هو أول شيء يجب مراعاته عند صنع شيء ما في ثلاثي الأبعاد؟
الجودة	○
الجاذبية	○
الأداء الوظيفي	●
الحجم	○

10.	ما اسم امتداد الملف الذي ندخله لبدء الطباعة؟
OBJ	○
STJ	○
STL	●
SLT	○

11.	ماذا يعني 3D؟
أفلام	○
3 أبعاد	●
مجسمة	○
رسومات الحاسوب	○

12.	ما هو المصطلح المستخدم لوصف الطباعة ثلاثية الأبعاد عند استخدامه لإنشاء نماذج لاختبار تصميم المنتج؟
البناء السريع للنماذج الأولية (RP)	●
التصميم بمساعدة الكمبيوتر (CAD)	○
النمذجة بترسيب مصهور (FDM)	○
لا شيء مما ذكر	○

13.	أي مما يلي صحيح حول سرعة ودقة الطابعات ثلاثية الأبعاد؟
طبقات أرق، دقة أقل	○
دقة أعلى، وعدد أقل من الطبقات المرئية التي يحتاجها.	○
دقة أقل ، وقت أقل	●
يعتمد الوقت المستغرق للطباعة ثلاثية الأبعاد فقط على الدقة.	○

14.	ما نوع البرمجية الذي يمكن استخدامه لتصميم جسم ثلاثي الأبعاد تريد طباعته؟
إنتاج الصور الرسومية	○
نمذجة الصور ثلاثية الأبعاد (3IM)	○
التصميم بمساعدة الكمبيوتر (CAD)	●
جميع ما ذكر	○

15.	أي من مجالات الصناعات التالية استخدمت الطباعة ثلاثية الأبعاد؟
الفن	○
الطب وطب الأسنان	○
التجارة	○
كل ما ذكر	●

16.	يمكن للطابعات ثلاثية الأبعاد طباعة أي شيء أو أي جسم صلب، وهناك بعض الطابعات ثلاثية الأبعاد الشهيرة مثل MakerBot أو طابعات Ulti-maker.
صح	●
خطأ	○



17.	تعد الطباعة ثلاثية الأبعاد تقنية متقدمة تستخدم في التصنيع الحديث
صح	●
خطأ	○



18.	أي مما يلي هو تصنيف لتقنيات الطباعة ثلاثية الأبعاد؟
كل ما يلي	●
الربط الانتقائي (Selective Binding)	○
التجميد الانتقائي (Selective Solidification)	○
الترسيب الانتقائي (Selective Deposition)	○

19.	في الربط الانتقائي، تقوم الطابعات ثلاثية الأبعاد بطباعة كائنات باستخدام المسحوق والمعادن والجبس.
صح	●
خطأ	○



20.	في أي تقنية تستخدم الطابعة مواد صلبة لصنع أجسام للبيع وبعض المواد السائلة؟
كل ما يلي.	○
الربط الانتقائي (Selective Binding)	○
التجميد الانتقائي (ٍSelective Freezing)	●
الترسيب الانتقائي (Selective Deposition)	○

21.	أي مما يلي يعد مكونا أساسيا في الطابعة ثلاثية الأبعاد؟
كل ما يلي	●
المحركات ذات المراحل الخطية (Stepper Engines)	○
منصة البناء (Printing Platform)	○
رأس الطابعة (Printer Head)	○

22.	أي مما يلي هو مادة بولي أميد؟
البلاستيك القوي والمرن	●
متعدد الألوان	○
جامد وهش بعض الشيء	○
لا شيء مما ذكر	○

23.	يعد الذكاء الاصطناعي مع الطباعة ثلاثية الأبعاد وأثرها على المستقبل من أكثر المواضيع إثارة بعالم التكنولوجيا.
صح	●
خطأ	○



24.	أي مما يلي يعد تقنية للقياس في الطباعة ثلاثية الأبعاد؟
ماسح الضوء المهيكلة الثلاثية الأبعاد 	○
ديفليكتوميتري (Deflectometry)	○
المسح الضوئي (Photogrammetry)	○
جميع ما ذكر	●

25.	تعد الطباعة ثلاثية الأبعاد ذات صلة لأن أنظمة البرمجيات تحتاج إلى معرفة كيفية اكتشاف الكويكبات البعيدة جدا لمدى لا تستطيع رؤيته لتتعرف عليه جيدا.
صح	●
خطأ	○



26.	برمجية طباعة ذكية ثلاثية الأبعاد لإدارة الطباعة ثلاثية الأبعاد لإدخال الذكاء إلى المصانع 
صح	●
خطأ	○



27.	أي مما يلي هو الخطوة الأولى في عملية الطباعة ثلاثية الأبعاد؟
كل ما يلي.	○
تحضير نموذج ثلاثي الأبعاد.	●
تحضير المواد	○
إغلاق الطابعة.	○

28.	أي مما يلي هو تصنيف للتقنيات المستخدمة في الطباعة ثلاثية الأبعاد؟
الطباعة بنفث المواد نقطيا	○
الطباعة بالحرارة	○
ترسب الطاقة الموجهة	○
جميع ما ذكر	●

29.	ما هي المواد التالية الأكثر شيوعًا في الطباعة ثلاثية الأبعاد؟
الورق.	○
البلاستيك.	●
البوليلاكتيك.	○
غير ذلك	○

30.	بم تسمى عملية ما قبل إرسال الملف إلى الطابعة للطباعة ثلاثية الأبعاد؟
النمذجة.	○
التقطيع.	●
فك التشفير.	○
غير ذلك	○

31.	في الطباعة ثلاثية الأبعاد، يعمل البرنامج بذكاء وفقًا للمعلومات التي تلقاها من أجهزة الاستشعار.
صح	●
خطأ	○



32.	ما هي التقنيات التي تسمح للغمامة النقطية ثلاثية الأبعاد بعكس ونشر الاجسام متعددة المزايا على سطحها.؟
ماسح الضوء المهيكل الثلاثي الأبعاد 	○
ديفليكتوميتري (Deflectometry)	○
المسح الضوئي (Photogrammetry)	○
جميع ما ذكر	●

33.	قامت الإمارات العربية المتحدة والمملكة العربية السعودية ببناء مباني تعتمد على هذه التقنية الواعدة التي توفر الوقت والمال والقوى العاملة للأفراد والشركات.
صح	●
خطأ	○



34.	أي مما يلي يعد نوعا من المواد المستخدمة للطباعة ثلاثية الأبعاد؟
الفولاذ المقاوم للصدأ.	○
السيراميك.	○
لجبس.	○
جميع ما ذكر	●

35.	أي المواد التالية يمكنك استخدامه في الطباعة ثلاثية الأبعاد؟
بلاستيك.	○
ذهب.	○
الفضة	○
كل ما ذكر.	●

36.	أي التطبيقات التالية تعد أفضل تطبيق للنمذجة ثلاثية الأبعاد؟
أوتوكاد.	○
ماتر-كونترول (Matter Control)	●
مايا.	○
غير ذلك	○

37.	أي مما يلي لا يعد طريقة للطباعة ثلاثية الأبعاد؟
النمذجة بترسيب مصهور (FDM)	○
آلة التحكم العددي بالحاسوب (CNC)	●
البلمرة	○
جميع ما ذكر	○

38.	سيكون صب البلاستيك الساخن مثالًا على عملية الإضافة، بمعنى إضافة المواد طبقة طبقة في كل مرة، لإنشاء النموذج الخاص بك.
صح	●
خطأ	○



39.	في أي من الطرق التالية، يتم إزاحة الصور المحبرة أو نقلها من سطح إلى آخر.
طباعة سريعة	○
طباعة ليثوغرافية مباشرة أو متوازنة	●
الطباعة الحجرية المباشرة	○
الطباعة داخل المصنع	○

40.	يشار إلى صناعة الطباعة ثلاثية الأبعاد بشكل متكرر على أنها أي مما يلي؟
الطباعة والنشر والتعبئة والتغليف	●
تصميم والنشر والتعبئة والتغليف	○
الطباعة، التصميم، التغليف	○
الطباعة والنشر والتوسيم	○

41.	من المتوقع أن تحصل الشركات على خدمة الطباعة بحسب الطلب لتلبي مطالب المنتجات المخصصة بهدف تكييف الطباعة والكفاءة، وهكذا حتى تصبح نسبة الطلب متزايدة 
صح	●
خطأ	○



42.	في أي تقنيات الطباعة ثلاثية الأبعاد توجد طرق عرض متعددة للكاميرا تستخدم لإعادة بناء السطح الثلاثي الأبعاد لكائن ما؟
كل الآتي	○
ماسح الضوء المهيكل الثلاثي الأبعاد 	○
ديفليكتوميتري (Deflectometry)	○
المسح الضوئي (Photogrammetry)	●

43.	ما هي عملية الطباعة المستخدمة للطباعة مباشرة من الحاسوب على الخشب؟
الرقمية	○
الضغط بالحبر   Letterpress	○
الفليكسوغرافي	○
الحفر  Gravure	●

44.	أي من المواد التالية لديه أقصى ليونة؟
الفولاذ الطري	●
نحاس	○
زنك	○
الألومنيوم	○

45.	أي مما يلي هو السطح الذي بنيت عليه المطبوعات؟
التشريح	○
الخيط	○
الطارد	○
لوحة البناء	●

46.	أي مما يلي ليس مثالًا على التصنيع التجميعي/ بالإضافة؟
نفث الإلكترون الرابط	○
نفث المواد الرابطة (BJ)	○
النمذجة بترسيب مصهور (FDM)	○
السبك بالشمع المفقود	●

47.	أي من التقنيات التالية تبني الأجزاء بالتذويب؟
نفث المواد الرابطة (BJ)	●
التجسيم المرئي Stereolithography (SL ، SLA)	○
النفث المادي (MJ ، DOD)	○
PolyJet	○

48.	ما التكنولوجيا التي تطبع باستخدام المواد الرقمية؟
الصهر بالليزر (LM)	○
النمذجة بترسيب مصهور (FDM)	●
النفث المادي (MJ ، DOD)	○
PolyJet	○

49.	غالباً ما ينظر إلى العلاقة بين الطباعة ثلاثية الأبعاد والذكاء الاصطناعي من جهة واحدة.
صح	●
خطأ	○


`

  let vrQs = `1.	ما هو معنى الواقع الافتراضي؟
تقنية جديدة تمنح الشخص تجربة فريدة لا يمكن تجربتها في الواقع الحقيقي.	●
تقنية قديمة لتزويد الأفراد بصور عالية الجودة.	○
التقنية الجديدة التي ستعمل على تطوير صناعة الترفيه.	○
غير ذلك	○

2.	تعرض نظارات الواقع الافتراضي صورة الأفلام والألعاب في عرض ثلاثي الأبعاد بزاوية 360 درجة 
صح	●
خطأ	○



3.	في عالم الواقع الافتراضي، يمكن للمستخدم التحرك بشكل كامل وبحرية كما يحلو له.
صح	○
خطأ	●



4.	أي مما يلي يعتبر نوعا من أنواع الواقع الافتراضي؟
كل الخيارات	●
الواقع الافتراضي الذي يجعل المستخدم في حالة تواجد كامل 	○
واقع محدود الوظيفة والمكان 	○
واقع افتراضي طرفي	○

5.	أي مما يلي يعتبر جهازا للواقع الافتراضي؟
لا شيء مما يلي.	○
كاميرا كشف الحركة.	○
مجسات الحركة.	●
الصور المتحركة.	○

6.	أي مما يلي هو عبارة عن شاشات ونظام ضوئي مجتمعة بصندوق متصل بذراع متعدد التوصيلات؟
لا شيء مما يلي.	○
استشعار الحركة.	○
منظار BOOM متعدد الاتجاهات.	●
الكهف.	○

7.	أي مما يلي هو أحد استخدامات الواقع الافتراضي؟
كل ما يلي.	●
الطب والجراحة.	○
العمارة والبناء.	○
الترفيه والألعاب	○

8.	يُعد الواقع المُعزز أو المحسن نوعًا من الواقع الافتراضي الذي يهدف إلى تكرار البيئة الحقيقية في الحاسوب وتحسينها بالبيانات الافتراضية التي لم تكن جزءًا منها.
صح	●
خطأ	○



9.	أي مما يلي يوسع العالم المادي الذي نعيش فيه ويضيف محتويات رقمية؟
الواقع الافتراضي.	○
الواقع المعزز.	●
واقع مختلط.	○
لا شيء مما ذكر.	○

10.	الحياة الثانية هي لعبة افتراضية، يجب أن يكون لديك اتصال إنترنت عالي السرعة وجهاز كمبيوتر عالي الدقة لتشغيله.
صح	●
خطأ	○



11.	أي مما يلي يعتبر أحد أجهزة الواقع الافتراضي؟
لا شيء مما يلي.	○
أجهزة باهظة الثمن.	○
جهاز النانو مانيبيولاتور أو المعالج النانومتري 	●
نظام المعالجة المركزية.	○

12.	أي مجال مما يلي يعد برمجية للعالم الافتراضي؟
لا شيء مما يلي.	○
برمجية بايثون.	○
محاكاة التدريب العالم الافتراضي	●
منصة وسائل الاعلام الاجتماعية.	○

13.	أي واحد مما يلي هو مفهوم مهم لتتبع الكائنات في الواقع الافتراضي؟
التصفيح.	○
الدوران.	●
الموقف.	○
غير ذلك.	○

14.	إن طريقة عمل سماعات الرأس بسيطة للغاية وهي أن يثبت الشخص السماعة على رأسه ووجهه، فينظر لشاشتين داخليتين لكل عين على حدي، والغرض منها هو عرض الصور الافتراضية.
صح	●
خطأ	○



15.	تم تقديم الواقع الافتراضي لأول مرة في عام 1989، دون استخدام أي مصطلحات أخرى للإشارة إلى هذا المفهوم.
صح	○
خطأ	●



16.	أي مما يلي هو أحد أجهزة الواقع المعزز؟
عدسات لاصقة تدعم الواقع المعزز 	●
سماعة الرأس.	○
نظام المعالجة المركزية.	○
كل ما ذكر.	○

17.	تُظهر تقنية الواقع المحسّن العناصر الرقمية في العالم الواقعي وتسمح لهم بالتفاعل معهم باستخدام هواتفهم أو نظاراتهم الخاصة.
صح	●
خطأ	○



18.	أي واحد مما يلي يمثل طريقة الواقع المعزز؟
كل ما يلي.	○
اعتمادا على صورة ثابتة (مع علامة).	●
الأجهزة غير الإسقاط.	○
تسليط الضوء على الأجهزة.	○

19.	أي مما يلي يعتمد مفهوم الواقع المعزز على أساس التراكب على الاستبدال الكامل أو الجزئي لعنصر موجود مسبقًا أمام الكاميرا؟
لا شيء مما يلي.	○
التفاعل.	○
المخلط.	○
التراكب.	●

20.	الهاتف المحمول: هو الأكثر استخداما في الواقع المعزز.
صح	●
خطأ	○



21.	أي مما يلي هو نظام واقع معزز يعتمد على الموقع أو الموضع؟
واقع معزز دون علامة 	●
علامة الواقع المعزز	○
حركة الواقع المعزز	○
غير ذلك	○

22.	أي مما يلي تم تطويره ليعطي إيحاء بالاستغراق من خلال عرض صور مجسمة على جدران وأرض مكعب بحجم غرفة 
كهف البيئة الآلية.	●
نهر البيئة الآلية.	○
الصحراء البيئة الآلية.	○
كل ما ذكر.	○

23.	أي مما يلي هو خوذة مزودة بشاشة صغيرة أو شاشتين لعرض الأصوات الأحادية أو الستيريوية؟
كل ما يلي.	○
جهاز يلبس بالرأس.	●
نظارات شمسيه.	○
مجسات الحركة.	○

24.	في الواقع الافتراضي الطرفي، يتم النظر إلى العالم الافتراضي والتلاعب به من خلال شاشة الحاسوب مما يحد من الشعور بالتواجد الواقعي في العالم الافتراضي.
صح	●
خطأ	○



25.	أي مما يلي مطلوب للاستشعار عندما تقوم فيها بتحريك رأسك للوجهة التي تتجه لها، وحركة يديك وجسمك المرتبطة بأجهزة PlayStation المعززة بالواقع الافتراضي؟
كل ما يلي.	○
معدل الإطار.	○
مستشعرات تتبع الحركة	●
مجال الرؤية.	○

26.	أي واحد مما يلي مطلوب لجعل الواقع الافتراضي عالي الجودة؟
حاسوب الواقع.	○
محتويات الواقع	●
قياسات الواقع.	○
غير ذلك	○

27.	يلعب البعد الثالث دورًا رئيسيًا في تقنية الواقع الافتراضي، حيث يتم نقل المخرجات إلى نماذج تشبه الواقع ويجعل العميل مندمج تمامًا كما لو كان منغمسًا في نفس بيئة الواقع الحقيقي.
صح	●
خطأ	○



28.	الواقع المعزز هو نوع منفصل عن الواقع يهدف إلى استخدام الحاسوب وتحسينه باستخدام الواقع الافتراضي كجزء مستقل عنه هو أيضا.
صح	○
خطأ	●



29.	يهدف المشهد الافتراضي الذي تم إنشاؤه بواسطة الحاسوب إلى تحسين إدراك العالم الحقيقي الذي يراه المستخدم أو يتفاعل معه.
صح	●
خطأ	○



30.	أي من الشبكات التالية كانت أول المستفيدين من الواقع الافتراضي؟
الخرائط ووسائل التواصل الاجتماعي	●
Facebook و Twitter فقط.	○
Instagram و Google+.	○
غير ذلك	○

31.	أي مما يلي هو أحد أجهزة الواقع المعزز؟
الهواتف الذكية.	○
نظارات الواقع المحسن.	○
العدسات اللاصقة المحسنة.	○
كل ما ذكر.	●

32.	في أي قطاع يمكننا استخدام الواقع الافتراضي؟
كل ما يلي.	●
الطب والجراحة.	○
العمارة والبناء.	○
الترفيه والألعاب.	○

33.	يدعم الواقع المعزز والافتراضي بعض التقنيات من نفس النوع، وكل منهما يهدف لخدمة المستخدم بتجربة معززة أو مخصبة.
صح	●
خطأ	○



34.	أي من الطرق التالية هي أحد الاختلافات بين تقنيات الواقع المعزز والافتراضي؟
كل ما يلي.	○
طريقة النقل.	○
طريقة التقديم.	●
طريقة التجميع	○

35.	تعتبر التغذية الراجعة الحسية - وهي الاهتزاز والإحساس المضافان إلى التفاعل مع الرسومات - كزيادة.
صح	●
خطأ	○



36.	ستصبح تطبيقات الواقع الافتراضي والواقع المعزز أكثر تطوراً، حيث ستزداد قوة الأجهزة وقدرتها على إنشاء صور بصرية عالية الجودة 
صح	●
خطأ	○



37.	أي مما يلي يعتبر جزء من النظارات المستخدمة لعرض الصور بشكل منفصل لكل عين؟
كل ما يلي.	○
قفازات اللمس.	○
شاشة العرض.	●
مجسات الحركة.	○

38.	في أي نوع من أنواع الواقع الافتراضي ينبهر المستخدم بأنه بعدم وجود حاسوب أو عالم حقيقي لأنه لا يستطيع أن يرى أو يشعر بأي شيء سوى هذا العالم المصنّع؟
كل ما يلي.	○
الواقع المعزز.	○
الواقع الافتراضي الذي يجعل المستخدم في حالة تواجد كامل 	●
الواقع المختلط.	○

39.	في أي نوع من أنواع الواقع الافتراضي يهتم المصمم بمحاكاة بعض الخصائص أو الجزيئات في الحياة الحقيقية؟
 واقع محدود الوظيفة والمكان	●
الواقع الافتراضي الذي يجعل المستخدم في حالة تواجد كامل 	○
الواقع المعزز.	○
كل ما ذكر.	○

40.	أي من التقنيات التالية ستمنح الطلاب في المدارس خبرات تعليمية عالية الجودة؟
لا شيء مما يلي.	○
العالم الافتراض والمعزز	●
انترنت الأشياء.	○
قاعدة البيانات التسلسلية.	○

41.	أي مما يلي يمكنه دعم رحلات السائح في المدن الأجنبية والتعامل مع اللغات الصعبة؟
الواقع الافتراضي.	○
الواقع المعزز.	●
واقع مختلط.	○
غير ذلك	○

42.	إن الفرق الواقع الافتراضي VR والواقع المعزز AR هو أن العالم المعزز يختلف عند استخدام في الكاميرا
صح	●
خطأ	○



43.	شاشة عرض الرأس لديها القدرة لتسمح للمستخدم بالاطلاع على ما يسمى التراكب.
صح	○
خطأ	●



44.	يلعب البعد الثالث دورًا رئيسيًا في تقنية الواقع الافتراضي، حيث يقوم بتحويل المخرجات إلى نماذج مشابهة للواقع ويجعل العميل مندمجا تمامًا كما لو كان منغمسًا في نفس بيئة الواقع الحقيقي.
صح	●
خطأ	○



45.	أي مما يلي هو تكرار الصور في مجال الرؤية؟
لا شيء مما يلي.	○
معدل الإطار (Frame Rate)	●
العدسات.	○
تتبع الحركة.	○

46.	ما البرامج التالية التي تتيح للمستخدمين تجربة البيانات التي تم جمعها في بيئة افتراضية؟
تصور الواقع الافتراضي 	○
VR SDK	○
محاكاة التدريب الواقع الافتراضي 	○
كل ما ذكر.	●

47.	أي مما يلي يوفر للمطورين أساسًا لإنشاء تجربة ألعاب فيديو بالواقع الافتراضي ؟
نظم إدارة محتوى الواقع الافتراضي	○
VR SDK	○
محرك ألعاب الواقع الافتراضي	●
كل ما ذكر	○

48.	إن منظار بوم متعدد الاتجاهات عبارة عن شاشات ونظام ضوئي مجتمعة بصندوق متصل بذراع متعدد التوصيلات.
صح	●
خطأ	○



49.	يمكن أن يساعد ارتداء النظارات المجسمة على السير والتجول بحرية في الكهف، ويقوم نظام تتبع حركات الرأس بضبط العرض المجسم بشكل مستمر.
صح	●
خطأ	○



50.	تتنوع الأجهزة المساعدة في الواقع الافتراضي فمنها على سبيل المثال: قضيب التحكم والفأرة ولوحة المفاتيح.
صح	●
خطأ	○


`

  let blockchainQs = `1.	أي مما يلي نحتاج إلى تعريفه قبل إنشاء قاعدة بيانات تسلسلية (البلوك تشين)؟
تعريف حالة الاستخدام المناسبة.	○
تصميم مثال لقاعدة البيانات التسلسلية.	○
تصميم واجهة المستخدم والمسؤول.	○
كل ما ذكر	●

2.	أي مما يلي لا يعد من عيوب قاعدة البيانات التسلسلية (البلوك تشين)؟
قاعدة البيانات التسلسلية العامة معقدة من ناحية التعلم والتطبيق.	○
لا تزال قاعدة البيانات التسلسلية العامة بطيئة للغاية	○
نظرية 51 الضارة: ماذا لو كان لديك نسبة 51٪ من العقد الضارة	○
لا شيء مما ذكر	●

3.	تعني  (POW)
إثبات النوافذ (Proof of Windows)	○
إثبات العمل (Proof of Work)	●
إثبات الويب (Proof of Web)	○
نظير العمل (Peer of Work)	○

4.	تبدأ العقدة (Node) المعاملة من خلال إنشاءها أولاً ثم توقيعها إلكترونيا باستخدام المفتاح الخاص (الذي تم إنشاؤه عبر التشفير).
صح	○
خطأ	●



5.	تحتوي منصة قاعدة البيانات التسلسلية (البلوك تشين) على دفتر أستاذ مركزي، ولديه عقدة واحدة لها القدرة على تحديث دفتر الأستاذ دون إتلافه.
صح	○
خطأ	●



6.	ما هو المقصود بعملية التنقيب داخل قاعدة البيانات التسلسلية (البلوك تشين)؟
طريقة لدفع أموال المنقبين النقدية لحل شفرة الكتل.	○
طريقة لحل الخوارزميات المعقدة من خلال حواسيب GPU وكسب مكافآت للحلول.	●
الغوص في شبكة الشركة للحصول على معلومات مهمة وأرباح شخصية إضافية.	○
لا شيء مما ذكر.	○

7.	تعني P2P:
إثبات إلى ند شبكة الحاسوب Proof 2 Peer Computer Network	○
شبكة إثبات لإثبات Proof to proof network	○
شبكة ند لند Peer to Peer Network	●
شبكة ند لإثبات Peer to Proof Network	○

8.	يمكن تعديل معاملات قاعدة البيانات التسلسلية (البلوك تشين) بعد التحقق منها.
صح	○
خطأ	●



9.	بما تسمى عملية تشغيل قاعدة البيانات التسلسلية (البلوك تشين) لتحريرها من الأخطاء على كل عقدة تقوم بمعالجة المعاملات؟
المتانة (Robustness)	●
السرية (Confidentiality)	○
التوفر (Availability)	○
التحقق (Verification)	○

10.	في معاملات قاعدة البيانات التسلسلية (البلوك تشين)، يتم التحقق من الكتل (Blocks) بواسطة كافة العقد (Nodes) المتواجدة على الشبكة.
صح	●
خطأ	○



11.	أي مما يلي يعتبر ميزة من ميزات قاعدة البيانات التسلسلية (البلوك تشين) العامة؟
قواعد أكثر تعقيدًا لأمان أفضل	●
قواعد أقل تعقيدًا لأداء أسرع	○
يمكن أن تكون العقدة المرخص لها هي المحكم في أي نزاع	○
كل ما ذكر	○

12.	الكتلة الحالية يتم ترميزها بواسطة (هاش) مأخوذ من الكتلة السابقة، ويتم إنشاء رمز جديد (هاش) للكتلة التي تليها. 
صح	●
خطأ	○



13.	مما تتكون قاعدة البيانات التسلسلية (البلوك تشين)؟
عنوان الكتلة (Block Header)	○
معرف كتلة (Block Identifier)	○
شجرة ميركل (Markle Tree)	○
جميع ما ذكر	●

14.	أي مما يلي يجب مراعاته عند استخدام شبكة الند للند (Peer to Peer)؟
التنصل (Repudiation)	○
السرية (Confidentiality)	●
التفويض (Authorization)	○
لا شيء مما ذكر.	○

15.	ما هي العقدة (Node)؟
نوع من العملة المشفرة	○
قاعدة بيانات تسلسلية	○
مبادلة	○
حاسوب في شبكة قاعدة البيانات التسلسلية	●

16.	أي مما يلي يعد نوعا من أنواع التنقيب؟
التنقيب الجماعي (Pool Mining)	●
تنقيب وحدة المعالجة المركزية	○
تنقيب الأقران	○
تنقيب GPU	○

17.	ما هي خوارزمية التجزئة الرئيسية المستخدمة في البيتكوين؟
SHA-256	●
Whirlpool	○
SHA-512	○
MD5	○

18.	جميع قواعد البيانات التسلسلية (البلوك تشين) تستخدم إثبات عمل خوارزمية التنقيب 
صح	●
خطأ	○



19.	أي مما يلي يعتبر جزءا من معاملة قاعدة البيانات التسلسلية (البلوك تشين)؟
المبلغ المراد نقله	○
تجزئة التشفير للمعاملة المنتقلة	○
معرف الحساب الذي سيتسلم الملكية	○
جميع ما ذكر	●

20.	مما تتكون الكتلة (Block) في قاعدة البيانات التسلسلية
الختم الزمني (Timestamp)	○
نقطة التجزئة (Hash point)	○
بيانات المعاملات (Transaction Data)	○
جميع ما ذكر	●

21.	دالة التجزئة (هاش) هي دالة رياضية يمكن استخدامها لتحويل بيانات ذات حجم عشوائي إلى بيانات ذات حجم ثابت باستخدام خوارزمية معينة
صح	●
خطأ	○



22.	أي مما يلي يعتبر قاعدة للتجزئة (هاش)؟
يمكن لأي تغييرات صغيرة أن تحدث تغييرات على قيمة التجزئة (هاش)	○
لا يمكن الحصول على القيمة العكسية	○
التجزئة ليست عملية تشفير لذا لا يمكننا عكس الدورة	○
جميع ما ذكر	●

23.	ما هي قاعدة البيانات التسلسلية (البلوك تشين)؟
تقنية ويب.	○
قاعدة بيانات موزعة.	●
بروتوكولات اتصال	○
تقنية الواجهة الأمامية	○

24.	أي مما يلي هو فائدة من فوائد قاعدة البيانات التسلسلية (البلوك تشين)؟
حماية عالية	○
شفافية	○
ثبات	○
جميع ما ذكر	●

25.	من هو مخترع قاعدة البيانات التسلسلية (البلوك تشين)؟
ساتوشي ناكاموتو.	●
المجتمع.	○
مؤسسة متحدة.	○
فيتاليك بوتيرن.	○

26.	من الذي يتحقق من سلامة الكتل (Blocks) في POS المفوض؟
أصحاب المصالح	○
أي شخص يصل إلى قاعدة البيانات التسلسلية	○
الشهود المصوت لهم من قبل المشاركين	●
المشاركين.	○

27.	لدى قاعدة البيانات التسلسلية (البلوك تشين) القدرة على توزيع المعلومات في وقت قصير بين جميع العقد (Nodes).
صح	●
خطأ	○



28.	أي مما يلي يمثل المنقب (Miner) في قاعدة البيانات التسلسلية (البلوك تشين)؟
الخوارزمية التي تتوقع المعاملة التالية	○
شخص يقوم بعملية حسابية للتحقق من المعاملة	○
حاسوب يقوم بالتحقق من معاملات قاعدة البيانات التسلسلية ومعالجتها	●
نوع من الهارد فورك	○

29.	أي مما يلي لا يدعم العملة المحلية؟
البيتكوين.	○
الاثيريوم	○
هايبرليدجر.	○
جميع ما ذكر	●

30.	يتم تعريف بنية قاعدة البيانات التسلسلية على النحو التالي
قاعدة بيانات الموزعة على شبكة الند للند.	●
سلطة مركزية واحدة	○
غير ذلك	○
قاعدة البيانات مركزية موزعة	○

31.	لا يكون المنقبين (Miners) في قاعدة البيانات التسلسلية مسؤولين عن أي مما يلي: 
إضافة كتلة	○
التحقق من المدخلات والمخرجات من المعاملة	○
إعطاء المكافأة	●
تأكيد المعاملة	○

32.	بما يسمى التشفير الأكثر استخدامًا في قاعدة البيانات التسلسلية (البلوك تشين)
المفتاح غير المتماثل	●
المفتاح متماثل	○
المفتاح العشوائي	○
لا شيء مما ذكر	○

33.	أي مما يلي يعتبر نوعا لقاعدة البيانات التسلسلية (البلوك تشين)؟
عامة (Public).	○
خاصة (Private)	○
اتحادية (Consortium)	○
جميع ما ذكر	●

34.	أي من الخصائص التالية توضح قاعدة البيانات التسلسلية (البلوك تشين)؟
النظم اللامركزية	●
أموال أكثر أمنا وأمانا	○
دفتر الأستاذ المركزي	○
كل ما ذكر	○

35.	أي مما يلي يمثل المفتاح خاص؟
مفتاح في سلسلة المفاتيح الخاصة بك	○
مفتاح يعطى للعامة	○
مفتاح لا يعطى للعامة	●
المفتاح الذي يفتح الباب السري	○

36.	أي واحد مما يلي يستخدم للتشفير غير المتماثل؟
المفاتيح العامة فقط	○
مفاتيح خاصة فقط	○
المفاتيح العامة والخاصة	●
إثبات الحصة	○

37.	متى نحتاج إلى استخدام قاعدة بيانات تسلسلية (البلوك تشين)؟
عندما نحتاج إلى تخزين البيانات مؤقتًا	○
عندما نحتاج إلى تخزين البيانات بصورة دائمة	●
عندما نحتاج إلى لعب الألعاب	○
غير ذلك	○

38.	أي مما يلي يؤمن قاعدة البيانات التسلسلية (البلوك تشين)؟
العديد من العقد الصادقة في الشبكة	●
قوة كلمات المرور	○
حماية الخادم الرئيس للعُقد	○
غير ذلك	○

39.	جميع معاملات الكتل مبنية في هيكل يدعى شجرة ميركل أو شجرة التجزئة الثنائية.
صح	●
خطأ	○



40.	تتميز السلطة في قاعدة البيانات التسلسلية (البلوك تشين) الخاصة بما يلي:
سلطة لامركزية.	○
لا سلطة فيها	○
يمكن لأي عقدة أن المعاملات.	○
غير ذلك	●

41.	يجب أن يكون مستخدمو قاعدة البيانات التسلسلية (البلوك تشين) مؤهلين وذوي مهارات عالية في تكنولوجيا المعلومات.
صح	○
خطأ	●



42.	كلما زادت العقد (Nodes) في شبكة قاعدة البيانات التسلسلية زادت صعوبة وتعقيد تنقيب الكتل.
صح	●
خطأ	○



43.	يعني الثبات أن البيانات قد سجلت في القاعدة التسلسلية، ولا يستطيع مسؤول النظام أو أي أحد تغييرها.
صح	●
خطأ	○



44.	لا يمكن لقاعدة البيانات التسلسلية (البلوك تشين) أن تحل مشاكل الإنفاق المزدوج باستخدام خوارزميات الإجماع وتكنولوجيا دفتر الأستاذ الموزع.
صح	○
خطأ	●



45.	أي مما يلي يعتبر من فوائد قاعدة البيانات التسلسلية (البلوك تشين)؟
زيادة الأمن	○
تتبع أفضل	○
زيادة السرعة والكفاءة	○
جميع ما ذكر	●

46.	أي مما يلي يعتبر من مساوئ قاعدة البيانات التسلسلية (البلوك تشين)؟
تتبع أفضل	○
سرعة الشبكة وتكاليف المعاملات	●
حماية عالية	○
لا شيء مما ذكر	○

47.	كيف يتم إنشاء الكتل (Blocks) في قاعدة البيانات التسلسلية (البلوك تشين)؟
يتم إنشاء الكتل من قبل المنقبين لكسب المزيد من المكافآت	○
يتم إنشاء الكتل تلقائيًا بواسطة قاعدة البيانات التسلسلية عندما يتم التوصل إلى حجم الكتلة الكامل	●
يتم إنشاء كتل من قبل مسؤول الشبكة	○
لا شيء مما ذكر	○

48.	أي من السجلات التالية يمكن الاحتفاظ بها في قاعدة البيانات التسلسلية (البلوك تشين)؟
إدارة الهوية	○
معالجة المعاملات	○
سجلات طبية	○
جميع ما ذكر	●

49.	معرّف الكتلة (Block Identifier) هو قيمة التجزئة (Hash) التي تعمل كمعرف فريد ولا يكون هناك معرفا كتلة متطابقان.
صح	●
خطأ	○


`


  robotsQs
  iotQs
  dronesQs


  bigDataQs
  aiQs


  threeDQs
  vrQs
  blockchainQs
  let category = 'blockchain'
  let language = 'AR'
  let course = 'basic'
  let type = 'preassessment'//'exam'
  m = blockchainQs
  let n = JSON.stringify(
    m.split("\n").map(x => x.replace('\t', '')),
    null,
    0);
  let arr = JSON.parse(n)
  let questions = []

  let s = 0
  let end = 6
  for (let i = 0; i < arr.length / 6; i++) {
    let e = arr.slice(s, end)
    questions.push(e)
    s += 6
    end += 6
  }
  console.log('------------------------------------');
  console.log(questions);
  console.log('------------------------------------');
  let qCopy = questions
  let dbQArr = {}
  qCopy.forEach(async question => {
    question.pop()
    let dbQ = {}
    dbQ.text = question[0].split('.').slice(1, question[0].split('.').length).join(' ')
    dbQ.answers = [...question.slice(1, question.length)]
    dbQ.correctAnswer = dbQ.answers.indexOf(dbQ.answers.filter(x => x.indexOf('●') != -1)[0]) + 1
    dbQ.answers = dbQ.answers.map(x => x.replace('●', '').replace('○', ''))
    let aArr = []
    dbQ.answers.forEach((a, i) => {
      if(a)
      aArr.push({
        text: a,
        number: i + 1,
        length: a.length
      })
    })
    dbQ.answers = aArr
    let lengthsQ = dbQ.text.length + ',' + dbQ.answers.map(x => x.length)
    dbQ.length = 0
    lengthsQ.split(',').forEach(x => {
      dbQ.length += parseInt(x)
    })
    if (!dbQ.correctAnswer) console.log(dbQ)
    dbQ.questionTime = parseInt(dbQ.length * 1.8 / 10) + 20
    if (dbQ.questionTime < 15) dbQ.questionTime += 5
    dbQ.category = category
    dbQ.language = language
    dbQ.course = course
    dbQ.type = type
    dbQ.query = `${course}_${category}_${type}`

    dbQArr[`IAIDL_Q_${randomString(8, 'aA#')}`] = dbQ
    let pquestion = {
      [`IAIDL_Q_${randomString(8, 'aA#')}`]: dbQ
    }
    //console.log(pquestion);
    // await db.ref('questions').update(pquestion)
  });
  console.log('------------------------------------');
  console.log(dbQArr);
}