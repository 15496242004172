<template>
  <nav>
    <v-toolbar color="primary" dark app extended extension-height="1" >
      <v-toolbar-side-icon @click="drawer = !drawer" class="white--text"></v-toolbar-side-icon>
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">IAIDL</span>
        <span><sup>®</sup> Portal</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-chip  color="secondary" text-color="white">
        <v-avatar v-if="userImage">
          <img :src="userImage" :alt="userName">
        </v-avatar>
        {{ userName }}
      </v-chip>
      
      <!-- dropdown menu -->
      <v-menu offset-y>
        <v-btn slot="activator" icon>
          <v-icon color="white">apps</v-icon>
        </v-btn>
          
        <v-list>
          <v-btn @click="logout" flat color="grey">
            <span>Sign Out</span>
            <v-icon right>exit_to_app</v-icon>
          </v-btn>
        </v-list>
      </v-menu>
      <v-avatar size="12px" color="white">
        <v-avatar size="10px" :color="connection">
        </v-avatar>
      </v-avatar>
      <v-progress-linear v-if="progress" slot="extension" color="white" height="3" :indeterminate="true" class="ma-0"></v-progress-linear>  
      <v-progress-linear v-if="scroll" slot="extension" color="white" height="5" v-model="scrollAmount" class="ma-0"></v-progress-linear>
    </v-toolbar>
    
    <v-navigation-drawer 
      app 
      v-model="drawer"
      class="primary"
      floating
      temporary
      >
      <v-layout column align-center>
        <v-flex class="mt-5 text-xs-center">
          <v-avatar v-if="userImage" size="100">
            <img class="text-xs-center" :src="userImage">
          </v-avatar>
          <div></div>
          <div></div>
          <p class="white--text subheading mt-1 text-lg-center">{{ userName }}</p>
        </v-flex>
        <v-flex class="mt-4 mb-3">
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-list dense dark>
            <template v-for="item in items">
              <v-layout
                row
                v-if="item.heading"
                align-center
                :key="item.heading"
              >
                <v-flex xs6>
                  <v-subheader v-if="item.heading">
                    {{ item.heading }}
                  </v-subheader>
                </v-flex>
                <v-flex xs6 class="text-xs-center">
                  <a href="#!" class="body-2 black--text">EDIT</a>
                </v-flex>
              </v-layout>
              <v-list-group
                v-else-if="item.children"
                v-model="item.model"
                :key="item.text"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
              >
                <v-list-tile slot="activator">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ item.text }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  v-for="(child, i) in item.children"
                  :key="i"
                  @click="goTo(child.path)"
                >
                  <v-list-tile-action v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title  >
                      {{ child.text }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <v-list-tile v-else @click="goTo(item.path)" :key="item.text">
                <v-list-tile-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.text }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import Popup from './Popup'
import {firebase, auth, db} from '@/fb';


export default {
  components: { Popup },
  data() {
    return {
      drawer: false,
      snackbar: false
    }
  },
  methods: {
    logout: function() {
      auth.signOut();
      this.$store.state.centerData = null
      this.$store.state.role = null
      this.$store.state.tokens = null
      this.$store.state.user = null
      this.$store.state.candidates = null
      this.$store.state.userData = null
    },
    goTo(path) {
      this.$router.push(path)
    }
  },
  props: {
    connection: {
      required: true,
      default: 'error'
    },
    userName: {
      required: true,
      default: 'IAIDL'
    },
    userImage: {
      required: true,
      default: ''
    },
    progress:{
      default: false
    },
    scroll:{
      default: false
    },
    scrollAmount: {
      default: 0
    },
  },
  computed:{
    items() {
      let role =  this.$store.state.role
      return this.$store.state.menu[role]
    }
  },
  created () {
  }
}
</script>

<style>
.v-toolbar__extension {
    padding: 0 !important;
}
</style>
