import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAG3Jz8Btqwehq7EAJj62jdZ5x867O8T8s",
  authDomain: "astack-8276c.firebaseapp.com",
  databaseURL: "https://astack-8276c.firebaseio.com",
  projectId: "astack-8276c",
  storageBucket: "astack-8276c.appspot.com",
  messagingSenderId: "166878509490"
};
firebase.initializeApp(config);
var createFirebase = firebase.initializeApp(config, "Secondary");

const db = firebase.database();
const auth = firebase.auth();
const storageRef = firebase.storage().ref();

export {
  firebase,
  db,
  auth,
  createFirebase,
  storageRef
};
