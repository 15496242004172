<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start>
        <v-flex xs12 md12>
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Feedback</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Name"
                    v-model="uName"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Email"
                    v-model="uEmail"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    label="Feedback"
                    v-model="msg"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="sendFeedback()" :loading="loading" color="primary" dark>
                submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  data(){
    return {
      name:null,
      email:null,
      msg:null,
      loading:false,
    }
  },
  methods: {
    sendFeedback(){
      this.loading = true
      let fb = {
        name:this.$props.uName,
        email:this.$props.uEmail,
        msg:this.msg
      }
      console.log(fb)
      db.ref('feedback').push(fb)
      .then(x=>{
        this.loading = false
        this.$emit('snackbarNotification', `Sent`, 'success')
        this.goTo('/')
      })
    },
    goTo(path) {
      this.$router.push(path)
    },
  },
  props:{
    uName:{
      required:true
    },
    uEmail:{
      required:true
    },
  },
  computed:{
    
  },
  created(){
    
  }
}
</script>

<style>

</style>
