<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Presentations Submission</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
            <v-flex xs12 sm12 px-1 my-1>
              <v-text-field
                label="Token"
                readonly
                v-model="token"
                solo-inverted
                suffix="Token"
                :loading="tokenLoading"
                disabled
                light
                class="tokenField"
                color="white"
                background-color="primary"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-text-field
                label="Presentation Title"
                v-model="studenName"
                solo
                clearable
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-text-field
                label="Corporate"
                v-model="userName"
                solo
                disabled
                required
                prepend-inner-icon="account_balance"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 px-1 my-1>
              <v-textarea
                label="Note"
                name="name"
                solo
                v-model="qualifications"
              ></v-textarea>
            </v-flex>
            <input
              type="file"
              style="display: none"
              ref="file"
              accept="csv/*"
              @change="onFilePicked"
            >
            <v-flex v-for="file in uploadedFiles" :key="file.name" xs12 sm12 px-1 my-1>
              <v-chip color="success" text-color="white" label>{{file.name}}</v-chip>
              <v-icon color="success">done</v-icon>
            </v-flex>
            <v-flex xs12 sm12 px-1 my-1>
              <v-progress-linear  height="15"  v-if="upload>0 && upload<100" color="green darken-3" v-model="upload"></v-progress-linear>
              <v-btn @click="pickFile()" color="primary" >upload file</v-btn>
            </v-flex>
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <span v-if="dberror" class="red--text">{{ dberror }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="submit()"  large>
                  Submit Presentation
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>      
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { Printd } from 'printd'
import axios from 'axios'

export default {
  mixins: [validationMixin],
  props: ['userName', 'uid', ], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      gender:["Male", "Female"],
      titles:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      studentTitle:null,
      studenName:null,
      studentEmail:null,
      studentPhone:null,
      studentGender:null,
      studentAge:null,
      studentDegree:null,
      studentCorporate:null,
      studentPosition:null,
      studentCountry:null,
      studentPhoto:null,
      dberror:null,
      dbsuccess:null,
      password:null,
      printBtn:true,
      accessMaterial:true,
      accessPre:true,
      accessExam:true,
      tokenKey:null,
      tokenCode:null,
      tokenLoading:true,
      studentLanguage:null,
      qualifications:null,
      uploadedFiles:[],
      upload:null,
    }
  },
  methods: {
    pickFile() {
      this.$refs.file.click()
    },
    loadFile(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('presentations/' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.upload = progress
                }, (error) =>{
          console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log('File available at', downloadURL);
          this.uploadedFiles.push({url:downloadURL, name:file.name})
          this.Uploadloading = false
          this.loading = false
        });
      });
    },
    onFilePicked (e) {
      this.loading = true
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          console.log(this.file)
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } else {
        this.name = null
        this.fileName = null
        this.uploadURL = null
      }
    },
    async submit() {
      this.loading = true
      let p = {
        center:this.$props.uid,
        title:this.studenName,
        center_name:this.$props.userName,
        documents:this.uploadedFiles,
        date:new Date().toISOString(),
        token:this.tokenCode,
        approved:false,
        proccessed:false,
      }
      await db.ref(`presentations/${this.tokenCode}`).update(p)
      await db.ref(`centers/${this.$props.uid}/presentations/${this.tokenCode}`).update(p)
      let center = await db.ref(`centers/${this.$props.uid}`).once('value').then(x=>x.val())


axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=1234&type=new-center-presentaion&etype=new-center-presentaion&level=new-center-presentaion`)
                      .then(response => {
                        this.goTo('/')
                      })


      console.log('------------------------------------');
      console.log(center);
      console.log('------------------------------------');
    },
    goTo(path) {
      this.$router.push(path)
    },
    
  },
  watch: {
    
  },
  computed: {
    token() {
      this.tokenLoading = true
      let vtoken = `IAIDL_PRES_${randomString(8, 'aA#')}`
      this.tokenCode = vtoken
      this.tokenLoading = false
      this.noToken = false
      return vtoken
    },
    
    
    
  },
  
  created() {
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    })

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
