<template>
  <v-app class="grey lighten-4">
    <Navbar 
    v-if="isLogin"
    :userName="userData? userData.displayName ? userData.displayName :  userData.email : null "
    :userImage="userData? userData.photoURL ? userData.photoURL : null : null "
    :progress="progress"
    :uid="uid"
    :scroll="scroll"
    :scrollAmount="scrollAmount"
    :connection="connection"
    />
    
    <v-content>
      <v-alert
        :value="alert"
        :type="type"
        transition="scale-transition"
      >
        {{ alertText }}
      </v-alert>
      <transition :name="transitionName"> 
        <router-view
          :superadmin="superadmin"
          :centerData="centerData"
          :isLogin="isLogin"
          :uEmail="uEmail"
          :uName="uName"
          :uid="uid"
          :userData="userData? userData : null"
          :userName="userData? userData.displayName ? userData.displayName : userData.email : null"
          :userImage="userData? userData.photoURL ? userData.photoURL : null : null"
          @snackbarNotification="getSnack"
          @alertNotification="getAlert"
          @progressBar='getProgress'
          @scrollBar="getScroll"
        ></router-view>
      </transition>
      <v-btn 
        color="primary"
        dark
        small
        absolute
        top
        left
        fab
        v-if="checkRoute()"
        @click="goBack()"
      >
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
    </v-content>
      
      <v-snackbar v-model="snackbar" :color="type" :timeout="4000" bottom >
        <span>{{ snackbarContent }}</span>
        <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-layout row justify-center>
        <v-dialog v-model="regulationdialog" fullscreen transition="dialog-bottom-transition" :overlay="false">
          <!-- <v-btn color="primary" dark slot="activator">Open Dialog</v-btn> -->
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>IAIDL Regulations</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-toolbar-items>
                <v-btn dark flat @click.native="dialog = false">Save</v-btn>
              </v-toolbar-items> -->
            </v-toolbar>
            <regulations />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="acceptRegulations()" x-large color="primary">Accept To Access Portal</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <!-- <Chat /> -->
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/footer'
import regulations from './views/regulations'

import Chat from './components/ChatWindow'
import { firebase, db, auth } from '@/fb'

export default {
  components: { Navbar, Footer, Chat, regulations },
  name: 'App',
  data () {
    return {
      isLogin:false,
      regulationdialog:false,
      transitionName:null,
      userData:null,
      snackbar:false,
      snackbarContent:'',
      alert:false,
      alertText:null,
      type:null,
      progress:null,
      scroll:false,
      connection:null,
      uEmail:null,
      uName:null,
      scrollAmount:0,
      centerData:null,
      uid:null,
      superadmin:false,
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    getSnack (text, type) {
      this.snackbar = true
      this.snackbarContent = text
      this.type = type
    },
    getAlert (text, type) {
      this.alert = true
      this.alertText = text
      this.type = type
      setTimeout(()=>{this.alert = false}, 4000)
    },
    getProgress(val){
      this.progress = val
    },
    getScroll(status, amount) {
      this.scroll=status
      this.scrollAmount=amount
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    statusCheck(){
      var connectedRef = firebase.database().ref(".info/connected");
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          this.getSnack ('You are now connected to portal', 'success')
          this.connection = 'success'
        } else {
          this.getSnack ('You are not connected to portal', 'error')
          this.connection = 'error'
        }
      });
      let updateIndicator = () => {
        if(navigator.onLine) { // true|false
          this.getSnack ('You are now Online', 'success')
          this.connection = 'success'
        } else {
          this.getSnack ('You are now Offline', 'error')
          this.connection = 'error'
        }
      }
      window.addEventListener('online',  updateIndicator);
      window.addEventListener('offline', updateIndicator);
    },
    checkRoute(){
      let x = true
      let noBack = ['dashboard', 'login', 'verification', 'exammode', 'preexam']
      if (noBack.indexOf(this.$router.history.current.name) != -1) {
        x = false
      }
      return x
    },
    acceptRegulations(){
      let uid = this.$store.state.user.uid
      db.ref(`centers/${uid}`)
      .update({
        regulations:true
      })
    },
    tutVideo(user){
      console.log(this.$store.state.videos)
      console.log(this.$router.history.current.name)

    },
    async appendCrisp(){
      ////
      if(!window.$crisp){
        console.log('crisp')
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('type', 'text/javascript')
        recaptchaScript.setAttribute('id', 'iaidl-crisp')
        recaptchaScript.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="f468ac17-4cf7-44d2-9788-7835303fedfa";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`
        document.head.appendChild(recaptchaScript)
      }
      
    }
  }, 
  watch: {
    $route (to, from){
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      this.tutVideo()
    }
  },
  created() {
    // console.log('------------------------------------');
    // console.log(this.$router);
    // console.log('------------------------------------');
    db.ref('manuals')
    .once('value')
    .then(snap=>{
      let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
      this.$store.state.manuals = fms
    })
    auth.onAuthStateChanged(user => {
      if(document.getElementById('iaidl-crisp')) {
        document.getElementById('iaidl-crisp').outerHTML = null
      }
      if (user) {
        this.isLogin = true;
        this.userData = user;
        this.$store.state.user = user
        this.$emit('signedIn', this.userData)
        this.statusCheck() 
        this.uid = user.uid
        var d = new Date();
        var store = Date.parse(d)
        var offset = d.getTimezoneOffset();
        db.ref('centers/' + user.uid)
        .on('value', snap=>{
          if (snap.val()) {
            this.$store.state.centerData = snap.val()
            this.centerData = snap.val()
            if(snap.val() && snap.val().superadmin) {
              this.superadmin = true
            }
            this.$store.state.role = snap.val().role
            if(snap.val() && snap.val().role == 'center') {
              this.appendCrisp()
              if(window.$crisp) {
                $crisp.push(["set", "user:nickname", this.centerData.name]);
                $crisp.push(["set", "user:company", [this.centerData.name]]);
              }
            }
            this.$store.state.tokens = snap.val().tokens
            this.uEmail = snap.val().email
            this.uName = snap.val().name
            this.regulationdialog = !snap.val().regulations
          }
        })
        db.ref('candidates/' + user.uid)
        .on('value', snap=>{
          if (snap.val()) {
            this.$store.state.role = snap.val().role
            this.$store.state.userData = snap.val()
            this.uEmail = snap.val().email
            this.uName = snap.val().name
          }
        })
        db.ref('aima_users/' + user.uid)
        .on('value', snap=>{
          if (snap.val()) {
            console.log("AIMA USER")
            this.$store.state.role = "aimaview"
            this.$store.state.userData = snap.val()
            this.uEmail = snap.val().email
            this.uName = snap.val().company
          }
        })
        
      } else {
        this.isLogin = false;
        this.userData = null;
        let a = this.$router.history.current.name
        if (!a.includes('verification')) {
          this.$router.push('/login')
        } else {
        console.log("WHATEVER-LOGIN")
        }
      }
    });
  },
  mounted() {
    this.tutVideo()
    

  }
}
</script>
<style>
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.22);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #2375b7;
	/* border-radius:  0 0 20px 20px;  */
}
</style>
