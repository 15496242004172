import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';
import Vuelidate from 'vuelidate';
import Clipboard from 'v-clipboard';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import 'vue-croppa/dist/vue-croppa.css';
import Croppa from 'vue-croppa';



Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#2375b7',
    secondary: '#1E88E5',
    success: '#388E3C',
    info: '#ffaa2c',
    error: '#f83e70'
  }
})

Vue.use(vueAwesomeCountdown, 'vac'); // Component name, `countdown` and `vac` by default
Vue.use(Clipboard);
Vue.use(Croppa);
Vue.use(Vuelidate);

Vue.filter('truncate', function (text, length, suffix) {
  return text.substring(0, length) + suffix;
});

Vue.filter('formatDate', function (value) {
  if (value) {
    let d = new Date(value).toLocaleString('en-GB')
    return `${d}`
  }
});

Vue.filter('capitalfirst', str => {
  return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  })
});