<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            label="Show Only"
            multiple
            solo
            chips
            @change="getSelectedStatus"
            hint="Select who are"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Show All But"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md12 px-1>
        <v-text-field
          label="Search By Name/Email"
          solo
          v-model="searchText"
          @input="getSearchString()"
        ></v-text-field>
        </v-flex>
        <v-flex xs12 align-center class="text-xs-center">
          <v-btn @click="exportStudents()" color="primary" dark>Download Candidates Report</v-btn>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>

      <v-card 
      v-for="(student,i) in students"
      :key="i"
      >
        <v-layout v-if="student" row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Candidate Name</div>
            <div >{{ student.name }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Email</div>
            <div >
              <span >{{ student.email | truncate(10, '..') }}</span>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">
              Phone 
            </div>
            <div>{{ student.phone }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Gender</div>
            <div>{{ student.gender }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Age</div>
            <div>{{ student.age }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Corporate</div>
            <div>{{ student.corporate }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Position</div>
            <div>{{ student.position }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Date of registration</div>
            <div>{{ student.registerDate | formatDate | truncate(10, '..') }}</div>
          </v-flex>
          <!-- BASIC START -->
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Pass Basic Exam</div>
            <div v-if="student.exam.basic">
              <v-avatar
                  size="10px"
                  :color="student.exam.basic.pass ? 'success' : 'error' "
                >
                </v-avatar>
              <v-btn v-if="student.exam.basic.pass" 
              depressed 
              small 
              color="primary" 
              @click="getPdf(student.name, student.exam.basic.token.code, student.exam.basic.meta.done, 'basic')"
              dark>Get Certificate</v-btn>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Basic exam score</div>
            <div v-if="student.exam.basic.done">{{ parseFloat(student.exam.basic.score).toFixed(1) }}</div>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">Candidate can access:</div>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">Basic IAIDL:</div>
          </v-flex>
          <v-flex xs6 sm3 md3 v-for="level in Object.keys(student.access.basic)" :key="level">
            <v-chip 
              dark 
              text-color="white" 
              small 
              :class="student.access.basic[level] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'" 
              @click="toggleAccess(student.uid, level, student.access.basic[level])"
              >
              <v-icon v-if="(student.access ? student.access.basic[level] : false)" left>done</v-icon>
              <v-icon v-if="(student.access ? !student.access.basic[level] : false)" left>close</v-icon>
              {{ level.toUpperCase() }}
            </v-chip>
          </v-flex>
          <v-flex xs6 sm3 md3></v-flex>
          <v-flex xs12><hr></v-flex>
          <!-- BASIC END -->










          <!-- AIMA START -->
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Pass AIMA Exam</div>
            <div v-if="student.exam.aimaassessor">
              <v-avatar
                  size="10px"
                  :color="student.exam.aimaassessor.pass ? 'success' : 'error' "
              >
              </v-avatar>
              <v-btn v-if="student.exam.aimaassessor.pass"
                     depressed
                     small
                     color="primary"
                     @click="getPdf(student.name, student.exam.aimaassessor.token.code, student.exam.aimaassessor.meta.done, 'aimaassessor')"
                     dark>Get Certificate</v-btn>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">AIMA exam score</div>
            <div v-if="student.exam.aimaassessor.done">{{ parseFloat(student.exam.aimaassessor.score).toFixed(1) }}</div>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">Candidate can access:</div>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">AIMA Assessor:</div>
          </v-flex>


          <v-flex xs6 sm3 md3>
          <v-chip
              dark
              text-color="white"
              small
              :id="student.idder1"
              :class="student.access.aimaassessor.exam ? 'green darken-2 customPointer' : 'red accent-4 customPointer'"
              @click="toggleAimaAccess(student.uid, 'exam', student.access.aimaassessor.exam, student.idder1)"
          >
            <v-icon v-if="(student.access ? student.access.aimaassessor.exam : false)" left>done</v-icon>
            <v-icon v-if="(student.access ? !student.access.aimaassessor.exam : false)" left>close</v-icon>
            EXAM
          </v-chip>
          </v-flex>
          <v-flex xs6 sm3 md3>
          <v-chip
              dark
              text-color="white"
              small
              :id="student.idder2"
              :class="student.access.aimaassessor.material ? 'green darken-2 customPointer' : 'red accent-4 customPointer'"
              @click="toggleAimaAccess(student.uid, 'material', student.access.aimaassessor.material, student.idder2)"
          >
            <v-icon v-if="(student.access ? student.access.aimaassessor.material : false)" left>done</v-icon>
            <v-icon v-if="(student.access ? !student.access.aimaassessor.material : false)" left>close</v-icon>
            MATERIAL
          </v-chip>
          </v-flex>
          <v-flex xs6 sm3 md3>
          <v-chip
              dark
              text-color="white"
              small
              :id="student.idder3"
              :class="student.access.aimaassessor.preassessment ? 'green darken-2 customPointer' : 'red accent-4 customPointer'"
              @click="toggleAimaAccess(student.uid, 'preassessment', student.access.aimaassessor.preassessment, student.idder3)"
          >
            <v-icon v-if="(student.access ? student.access.aimaassessor.preassessment : false)" left>done</v-icon>
            <v-icon v-if="(student.access ? !student.access.aimaassessor.preassessment : false)" left>close</v-icon>
            PREASSESSMENT
          </v-chip>
          </v-flex>
          <!-- AIMA END -->














        </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelDialog = true" outline color="error" class="mx-2" dark>
              Cancel & Delete Registration
            </v-btn>
            <v-dialog v-model="cancelDialog" persistent max-width="390">
              <v-card>
                <v-card-title class="headline">Are You Sure?</v-card-title>
                <v-card-text>
                  You are about to delete {{ student.name }} registration, are you sure you want to do that?
                  <div class="error white--text pa-2 ma-3">NOTE: all data associated with {{ student.name }} will be deleted completely from our servers, and it will be impossible to restore it</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="cancelDialog = false">cancel</v-btn>
                  <v-btn 
                    color="success" 
                    outline 
                    @click="deleteStudent(student)"
                  >confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog 
              persistent 
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              v-model="dialog.id[student.name]"
              >
                <v-btn slot="activator" @click="getMaterialProgress(student, 'basic')" color="primary" dark>
                  <v-icon left>launch</v-icon>
                  Details & Registration
                </v-btn>
                <v-card>
                  <v-toolbar card dark color="primary">
                    <v-btn icon dark @click="dialog.id[student.name] = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{student.name.toUpperCase()}} Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <!-- <v-btn dark flat @click="dialog.id[step.name+i] = false">Save</v-btn> -->
                    </v-toolbar-items>
                    <!-- <v-menu bottom right offset-y>
                      <v-btn slot="activator" dark icon>
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile v-for="(item, i) in countries" :key="i" >
                          <v-list-tile-title>{{ item }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu> -->
                  </v-toolbar>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-layout row wrap>
                          <v-flex xs6 md4 pa-1 v-for="key in Object.keys(student).filter(x=> {
                            showKey = ['age', 'degree', 'language', 'name', 'phone', 'position', 'title', 'email', 'password']
                            if(typeof student[x] == 'string' &&
                            showKey.indexOf(x) != -1
                              ) {
                                return x
                              }
                            })" :key="key">
                            {{key.replace(/_/g, ' ').toUpperCase()}}
                            <v-text-field
                              v-if="key!='language'"
                              :label="key.replace(/_/g, ' ').toUpperCase()"
                              v-model="student[key]"
                              hide-details
                              solo
                            ></v-text-field>
                            <v-select
                              v-if="key=='language'"
                              :items="['AR', 'EN']"
                              solo
                              hide-details
                              :label="key.replace(/_/g, ' ').toUpperCase()"
                              v-model="student[key]"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark @click="updateStudent(student)">update</v-btn>
                    </v-card-actions>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <div class="caption grey--text ">Candidate can access:</div>
                      </v-flex>
                      <!-- BASIC START -->
                      <v-flex xs12>
                        <div class="caption grey--text ">Basic IAIDL:</div>
                      </v-flex>
                      <v-flex xs6 sm3 md3 v-for="type in Object.keys(student.access.basic)" :key="type">
                        <v-chip 
                          dark 
                          text-color="white" 
                          small 
                          :class="student.access.basic[type] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'" 
                          @click="toggleAccess(student.uid, type, student.access.basic[type])"
                          >
                          <v-icon v-if="(student.access ? student.access.basic[type] : false)" left>done</v-icon>
                          <v-icon v-if="(student.access ? !student.access.basic[type] : false)" left>close</v-icon>
                          {{ type.toUpperCase() }}
                        </v-chip>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Pre-Assessment</div>
                              <div class="icon">
                                <v-icon color="secondary">assessment</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                :size="150"
                                :width="15"
                                :rotate="-90"
                                :value="student.preassessment.basic.score"
                                :color="student.preassessment.basic.started ? student.preassessment.basic.pass ? 'success' : 'error' : ''"
                              >
                                
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.preassessment.basic.started ? student.preassessment.basic.pass ? 'success' : 'error' : ''">
                                {{  parseInt(student.preassessment.basic.score) }}%</div>
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.preassessment.basic.start ? student.preassessment.basic.pass ? 'success' : 'error' : ''">
                                  {{ student.preassessment.basic.started ? student.preassessment.basic.pass ? 'Pass' : 'Fail' : '' }}
                                </div>
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-divider v-if="results(student, 'preassessment', 'basic').examCorrection"></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                          </v-card-actions>
                          <v-expansion-panel v-if="results(student, 'preassessment', 'basic').examCorrection">
                            <v-expansion-panel-content
                            >
                              <template slot="header">
                                <div>Details</div>
                              </template>
                              <v-card>
                                <div v-for="(result, i) in results(student, 'preassessment', 'basic').examCorrection" :key="i">
                                  <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                                    Answer to Question: {{ Object.keys(result)[0] }} was
                                    {{ result[Object.keys(result)[0]] }}
                                    <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn>
                                  </v-alert>
                                </div>
                              </v-card>
                              <v-dialog
                                v-model="Qdialog"
                                :overlay="false"
                                max-width="500px"
                                transition="dialog-transition"
                              >
                                <v-card>
                                  <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                                  <v-card-text v-if="question">
                                    <v-radio-group v-model="question.correctAnswer">
                                      <v-radio 
                                      v-for="answer in question.answers" 
                                      :key="question.key + '_' + answer.number" 
                                      :name="answer.text"
                                      :label="answer.text"
                                      readonly
                                      :value="answer.number" 
                                      color="primary"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" flat @click="Qdialog = false">Dismiss</v-btn>
                                  </v-card-actions>
                                </v-card>
                            </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card>     
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Material</div>
                              <div class="icon">
                                <v-icon color="primary">school</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                :size="150"
                                :width="15"
                                :rotate="-90"
                                :value="bMaterialProgress"
                                color="primary"
                              >
                                {{ parseInt(bMaterialProgress) }}
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <!-- <v-divider></v-divider> -->
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                          </v-card-actions>
                        </v-card>     
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Exam</div>
                              <div class="icon">
                                <v-icon color="secondary">assessment</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                :size="150"
                                :width="15"
                                :rotate="-90"
                                :value="student.exam.basic.score"
                                :color="student.exam.basic.pass ? 'success' : 'error'"
                              >
                                
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.exam.basic.started ? student.exam.basic.pass ? 'success' : 'error' : ''">
                                {{  parseInt(student.exam.basic.score) }}%</div>
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.exam.basic.start ? student.exam.basic.pass ? 'success' : 'error' : ''">
                                  {{ student.exam.basic.started ? student.exam.basic.pass ? 'Pass' : 'Fail' : '' }}
                                </div>
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-divider v-if="results(student, 'exam', 'basic').examCorrection"></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn 
                              outline 
                              @click="goTo('/verification/' + student.exam.basic.token.code + '?preview=true')" 
                              color="primary" 
                              v-if="student.certificate.basic.achieved"
                              dark
                              >certificate</v-btn>
                            </v-card-actions>
                          <v-expansion-panel v-if="results(student, 'exam', 'basic').examCorrection">
                            <v-expansion-panel-content
                            >
                              <template slot="header">
                                <div>Details</div>
                              </template>
                              <v-card>
                                <div v-for="(result, i) in results(student, 'exam', 'basic').examCorrection" :key="i">
                                  <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                                    Answer to Question: {{ Object.keys(result)[0] }} was
                                    {{ result[Object.keys(result)[0]] }}
                                    <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn>
                                  </v-alert>
                                </div>
                              </v-card>
                              <v-dialog
                                v-model="Qdialog"
                                :overlay="false"
                                max-width="500px"
                                transition="dialog-transition"
                              >
                                <v-card>
                                  <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                                  <v-card-text v-if="question">
                                    <v-radio-group v-model="question.correctAnswer">
                                      <v-radio 
                                      v-for="answer in question.answers" 
                                      :key="question.key + '_' + answer.number" 
                                      :name="answer.text"
                                      :label="answer.text"
                                      readonly
                                      :value="answer.number" 
                                      color="primary"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" flat @click="Qdialog = false">Dismiss</v-btn>
                                  </v-card-actions>
                                </v-card>
                            </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card>     
                      </v-flex>
                      <v-flex v-if="student.project" xs12>
                        <v-expansion-panel expand>
                          <v-expansion-panel-content>
                            <div slot="header">Project</div>
                            <v-card>
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex v-for="key in Object.keys(student.project)" :key="key" xs12>
                                    <div v-if="key != 'documents'">
                                      <v-chip text-color="white" color="secondary" label>{{ key.split('_').join(' ').toUpperCase() }}</v-chip>
                                      <div>
                                        <v-chip label>{{ student.project[key] }}</v-chip>
                                      </div>
                                    </div>
                                    <div v-if="key == 'documents'">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <v-chip text-color="white" color="secondary" label>{{ key.split('_').join(' ').toUpperCase() }}</v-chip>
                                        </v-flex>
                                        <v-flex v-for="doc in student.project[key]" :key="doc.url" xs12>
                                          <v-chip color="success" text-color="white" label>{{doc.name}}</v-chip>
                                          <v-btn @click="openDoc(doc.url)" small color="primary">open document</v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
          </v-card-actions>
      </v-card>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import axios from 'axios';
import { Parser } from 'json2csv'

export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      displayText:['age', 'corporate', 'country', 'degree', 'email', 'gender', 'name', 'phone', 'position'],
      editText:['name', 'phone'],
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      cancelDialog:false,
      question:null,
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    async updateStudent(student){
      const headers = {'Accept': 'application/json'}
      let {name, language, phone, position, title, age, degree, email, password } = student
      await db.ref(`candidates/${student.uid}`).update({name, language, phone, position, title, age, degree, email, password })
      let r = await axios.post(`https://us-central1-astack-8276c.cloudfunctions.net/main/adminUpdate`, {uid:student.uid, name, language, phone, position, title, age, degree, email, password } , {headers:headers})
      if(r.data && r.data.success){
        this.$emit('snackbarNotification', `Updated`, 'success')
        console.log('------------------------------------');
        console.log(r);
        console.log('------------------------------------');
      } else {
        this.$emit('snackbarNotification', `Updated Failed`, 'error')
        console.log('------------------------------------');
        console.log(r);
        console.log('------------------------------------');
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        return new Date(b.registerDate) - new Date(a.registerDate);
      })
    },
    deleteStudent(student){
      console.log(student)
    },
    updateVisible() {
      this.students = this.filteredStudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.students.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents
      .filter(student =>  {
        if (this.selectedStatus.length > 0 && this.selectedStatus.length < this.stepsSelector.length) {
          if (student.lead) {
            for (let index = 0; index < this.selectedStatus.length; index++) {
              const element = this.selectedStatus[index];
              if (student.lead[element] === true) {
                return student
              }
            }
          } 
        } else {
          return student
        }
      })
      .filter(student => {
        if (this.nselectedStatus.length > 0 && this.nselectedStatus.length < this.stepsSelector.length) {
          if (!student.lead) {
            return student
          } else {
            for (let index = 0; index < this.nselectedStatus.length; index++) {
              const element = this.nselectedStatus[index];
              if (student.lead[element] !== true) {
                return student
              }
            }
          }
        } else {
          return student
        }
      })
      .filter(student => {
        let name = student.name ? student.name.toLowerCase(): null ;
        let email = student.email? student.email.toLowerCase() : null;
        let search = this.searchText.toLowerCase();
        if (name && email && (name.includes(search) || email.includes(search))) {
          return student
        }
      })
      .filter(student => {
        return student
      })
      this.sortByDate()
    },
    getPagesNumber() {
      this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    exportStudents(){
      let a = this.allstudents.map(s=>{
        return {
          name:s.name,
          email:s.email,
          phone:s.phone,
          center:s.center,
          corporate:s.corporate,
          position: s.position,
          country: s.country,
          registered_date:s.registerDate,
          basic_exam_done:s.exam.basic.done,
          basic_exam_done_date:s.exam.basic.meta? s.exam.basic.meta.done:null,
          basic_exam_score:s.exam.basic.score?parseFloat(s.exam.basic.score).toFixed(1):0,
          language:s.language
        }
      })
      let fields = ['name', 'email', 'phone', 'center', 'corporate', 'country', 'position', 'registered_date', 'basic_exam_done', 'basic_exam_done_date', 'basic_exam_score', 'language']
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(a);
      console.log(csv)
			let content = 'data:text/csv;charset=utf-8,';
      content += csv;
      const data = encodeURI(content);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute("download", "candidates_report_"+ new Date().toLocaleString() + ".csv");
      link.click();
    },
    loadData() {
      let studentsArr = []

      console.log("DATA START");
      //
      // db.ref('/candidates')
      //     .once('value')
      //     .then(snap => {
      //       let candidates = snap.val()
      //       for (const key in candidates) {
      //
      //         if (candidates.hasOwnProperty(key)) {
      //
      //           let candidateEmail = candidates[key];
      //           this.loading = true
      //           db.ref('candidates/' + candidateEmail.uid + 'tokens/exam/').update({
      //             aimaassessor: false
      //           })
      //           //console.log(candidateEmail.email);
      //           this.loading = false
      //
      //         }
      //
      //       }
      //
      //       console.log("DATA END");
      //     })


      db.ref( 'candidates')
      .orderByChild("name")
      .once('value')
      .then(snap=>{
        let candidates = snap.val()
        let stdLocID = 0
        for (const key in candidates) {
          if (candidates.hasOwnProperty(key)) {
            stdLocID++
            const candidate = candidates[key];
           // console.log(candidate)
            this.loading = true
                /*
            let index = this.allstudents.findIndex(x => x.email === candidate.email)
            if(index != -1) this.allstudents.splice(index, 1, candidate);
            if(index === -1){
             this.allstudents.push(candidate)
            }
            */
            let thsUID = candidate.uid
            candidate.idder1 = "ex" + stdLocID
            candidate.idder2 = "mt" + stdLocID
            candidate.idder3 = "pr" + stdLocID
            if(thsUID){

              this.allstudents.push(candidate)
              this.updateCountryFilters()
              this.updateVisible()
              this.getPagesNumber()
              this.loading = false


            }




          }
        }
        this.loading = false
      })
    },
    getPdf(name, token, date, level) {
      let pdate = ``
      this.$emit('snackbarNotification', `Generating ${name} Certificate...`, 'success')
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/api/pdf?name=${name.toUpperCase()}&code=${token}&date=${date}&level=${level}`)
      .then(response => { 
        window.open(response.data, 'Certificate Downloader')
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
    toggleAccess(uid, item, val) {
      db.ref('candidates/' + uid + '/access/basic')
      .update({
        [item]:!val
      })
      if (item == 'material') {
        return
      }
      db.ref('candidates/' + uid + '/' + item +'/basic')
      .update({
        access:!val
      })
    },
    toggleAimaAccess(uid, item, val, idder) {
      let element = document.getElementById(idder);
      db.ref('candidates/' + uid + '/access/aimaassessor')
      .update({
        [item]:!val
      })
      if (item == 'material') {
        return
      }
      db.ref('candidates/' + uid + '/' + item +'/aimaassessor')
      .update({
        access:!val
      })

    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    getMaterialProgress(user, level){
      console.log('------------------------------------');
      console.log(user);
      console.log('------------------------------------');
      let materialProgress = user ? user.material[level] : null
      if (!materialProgress) return true
      db.ref('manuals')
      .once('value')
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        let manuals = fms.filter(x=> x.level == level && user && x.language == user.language )
        console.log(manuals)
        
        manuals.forEach(manual => {
          let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false 
          let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
          manual.progress = {
            started,
            progress
          }
        });
        let progress = 0
        manuals.forEach(manual => {
          progress += manual.progress.progress
        });
        this.bMaterialProgress = progress / manuals.length
      })
    },
    results(user, type, level) {
      let exam = user ? user[type][level].exam : null
      let correctAnswers = user ? user[type][level].correctAnswers : null
      if(!exam) return true
      let examCorrection = []
      let count = 0
      let trueCount = 0
      for (const key in exam) {
        if (exam.hasOwnProperty(key)) {
          const answer = exam[key];
          count++
          if (!correctAnswers) {
            examCorrection.push({[key]:false})
          } else {
            if (correctAnswers.indexOf(key) != -1) {
              examCorrection.push({[key]:true})
              trueCount++
            } else {
              examCorrection.push({[key]:false})
            }
          }
        }
      }
      let score = trueCount / count * 100
      this.$emit('progressBar', false)
      return {examCorrection, score}
    },
    loadQs(key) {
      this.Qdialog = true
      db.ref('questions/' + key)
      .once('value')
      .then(sn=>{
        this.question = sn.val()
      })
    }
  },
  
  created() {
    this.loading = true
   this.loadData()
    //this.monitorChange()
  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
