var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('v-container',{staticClass:"my-5"},[_c('v-layout',{staticClass:"mb-3",attrs:{"wrap":"","row":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","px-1":""}},[_c('v-text-field',{attrs:{"label":"Search By Name/Email","solo":""},on:{"input":function($event){_vm.filterApps()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"text-xs-center"},[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_vm._e(),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.filteredApps.length)+" Results ")]),_vm._v(" "),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pagesNumber,"total-visible":5},on:{"input":_vm.updateVisible},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},_vm._l((_vm.visibleApps),function(app){return _c('v-flex',{key:app.token,attrs:{"xs12":"","sm12":"","md12":"","mb-1":""}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((Object.keys(app).filter(function (x){
                  var show = ['logo', 'title']
                  if(typeof app[x] != 'object'
                    && show.indexOf(x) == -1
                  ) {
                    return x
                  }
                })),function(key){return _c('v-flex',{key:app.key+key,attrs:{"xs3":""}},[_c('div',{staticStyle:{"font-size":"11px","font-weight":"600"}},[_vm._v(_vm._s(key.replace(/_/g, ' ').toUpperCase()))]),_vm._v(" "),_c('div',{staticClass:"subheadline"},[_vm._v(_vm._s(app[key]))])])}))],1),_vm._v(" "),_c('v-card-actions',{attrs:{"dense":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.goTo(("/aima/" + (app.token)))}}},[_vm._v("AIMA application")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.goTo(("/aima-result/" + (app.token)))}}},[_vm._v("AIMA report")])],1)],1)],1)})),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pagesNumber,"total-visible":7},on:{"input":_vm.updateVisible},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }