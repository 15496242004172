<template>
  <v-layout row wrap>
    
    <v-flex xs12 pa-1>
      IM HERE
    </v-flex>
  </v-layout>
</template>

<script>
import {firebase, db, auth} from '@/fb';

export default {
  data: () => ({
    lCands:[]
  }),
  computed: {
    
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  },
  create() {
    console.log('s')
  }
}
</script>