<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-text-field
            label="Search By Name/Email"
            solo
            v-model="searchText"
            @input="filteraima_users()"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredaima_users.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12 v-for="center in visibleaima_users" :key="center.key" mb-1>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs3 v-for="key in Object.keys(center).filter(x=> {
                    let show = ['aima_report_token', 'company', 'email', 'name', 'password', 'registerDate']
                    if(typeof center[x] != 'object'
                      && show.indexOf(x) != -1
                    ) {
                      return x
                    }
                  })" :key="center.key+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.toUpperCase() }}</div>
                  <div class="subheadline">{{ center[key] }}</div>
                </v-flex>
                
                
                

                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">SESSIONS</div>
                  <div v-if="center.sessions">{{ Object.keys(center.sessions).length }}</div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions dense>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark small @click="resetPass(center)">Deactivate User</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import { titleCase, randomString } from '@/customFunction'
import axios from 'axios';
import {Parser} from "json2csv";
export default {
  data() {
    return {
      searchText:'',
      loading:false,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      aima_users:[],
      filteredaima_users:[],
      visibleaima_users:[],
      selectedCenter:null,
      tokensAmount:0,
      addTokensD:false,
      tokensType:null,
      textFields:[

      ]
    }
  },
  methods:{
    resetPass(cData){

      console.log("DATA======")
      let thsUID = cData.uid
      //console.log(thsUID)
      if(thsUID){

            // auth.updateUser(thsUID, {
            //   disabled: true,
            // })
            // .then((userRecord) => {
            //   // See the UserRecord reference doc for the contents of userRecord.
            //   console.log('Successfully updated user', userRecord.toJSON());
            // })
            // .catch((error) => {
            //   console.log('Error updating user:', error);
            // });

alert("User Deactivation Failed")

            // auth.sendPasswordResetEmail(thsEMAIL).then((data) => {
            //   this.resetStat = `Sent successfully to ${this.user.username}`
            //   alert("Password reset link Sent successfully to " + thsEMAIL)
            // }).catch((error) => {
            //   alert(error.message)
            // });
      }


    },
    async addTokens(){
      if(!this.tokensType) {
        this.$emit('snackbarNotification', `Select a type`, 'error')
        return
      }
      this.loading = true;
      (async ()=>{
        for (let i = 0; i < parseInt(this.tokensAmount); i++) {
          let code = `IAIDL_${randomString(8, 'aA#')}`
          let types = ['advanced', 'basic', 'aima', 'lab', 'product', 'aimaassessor']
          let type = this.tokensType.toLowerCase()
          let expired = false
          await db.ref(`aima_users/${this.selectedCenter.uid}/tokens`)
          .push({
            code,
            type,
            expired
          })
        }
        this.addTokensD = false
        this.loading = false;
        this.getAimaUsers()
      })()
    },
    goTo(path) {
      this.$router.push(path)
    },
		updateVisible() {
      this.sortByDate()
      this.visibleaima_users = this.filteredaima_users.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      this.loading = false
    },
    sortByDate(){
      this.filteredaima_users = this.filteredaima_users.sort(function(a,b){
        return new Date(b.issue_date) - new Date(a.issue_date);
      });
    },
    getPagesNumber(){
      if (this.filteredaima_users.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredaima_users.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredaima_users.length / this.pageSize) + 1
      }
		},
		filteraima_users() {
      this.loading = true
			this.filteredaima_users = this.aima_users
			.filter(v=>{
				if(!this.searchText) return v
				for (const key in v) {
					if (v.hasOwnProperty(key)) {
						const val = v[key];
						if(val && typeof val == 'string' && val.toLowerCase().includes(this.searchText.toLowerCase())) return v
					}
				}
			})
      this.getPagesNumber()
      this.currentPage = 1
			this.updateVisible()
		},
		clipboardSuccessHandler ({ value, event }){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async getAimaUsers(cUID){
      this.loading = true
      this.aima_users = []
      
      let aima_users = await db.ref(`aima_users`)
                      .orderByChild('center')
                      .equalTo( cUID )
                      .once('value').then(x=>x.val())
      for (const key in aima_users) {
        if (aima_users.hasOwnProperty(key)) {
          const center = aima_users[key];
          center.key = key
          this.aima_users.push(center)
        }
      }
      console.log(aima_users)
      this.filteraima_users()

    }
  },
  created(){

    let centerUID = this.$store.state.centerData.uid
    console.log( "centerUID=============" )
    console.log( centerUID )
    if( centerUID ){
      this.getAimaUsers( centerUID )
    }
    
  }
}
</script>

<style>

</style>
