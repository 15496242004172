<template>
  <div>
    <div class="fb-page" data-href="https://www.facebook.com/iaidl" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/iaidl" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/iaidl">Facebook</a></blockquote></div>
  </div>
</template>

<script>
export default {
  methods:{
    load(){
      if (!document.getElementById('facebook-jssdk')) {
        function init() {
            FB.api('/765966450455464', function(response) {
              console.log(response);
            });
        }
        window.fbAsyncInit = function() {
          FB.init({
            appId      : '281529088962810',
            xfbml      : true,
            status : true, // check login status
            cookie : true, // enable cookies to allow the server to access the session
            version    : 'v4.0'
          });

          init();
        };
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v4.0';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      }
    }
  },
  watch:{
    $route (to, from){
      this.load()
      FB.XFBML.parse()

    }
  },
  mounted() {
    this.load()
    FB.XFBML.parse()

  },
}
</script>