<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <!-- <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            label="Show Only"
            multiple
            solo
            chips
            @change="getSelectedStatus"
            hint="Select who are"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Show All But"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex> -->
        <!-- <v-flex xs12 md12 px-1>
        <v-text-field
          label="Search By Name/Email"
          solo
          v-model="searchText"
          @input="getSearchString()"
        ></v-text-field>
        </v-flex> -->
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>

      <v-card 
      v-for="(student,i) in students"
      :key="i"
      >
        <v-layout row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Submission Date</div>
            <div >{{ student.date }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">
              Title 
            </div>
            <div v-if="student.title"> 
                {{student.title}}
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Documents</div>
            <div v-if="student.documents">
              <div v-for="document in student.documents" :key="document.url">
								<v-btn @click="openDoc(document.url)" small color="primary">open document</v-btn>
              </div>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Approval Status</div>
            <v-tooltip v-if="!student.proccessed" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Presentation Approved' : 'Under Proccess' }}</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Presentation Approved' : 'Presentation Not Approved' }}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      displayText:['age', 'corporate', 'country', 'degree', 'email', 'gender', 'name', 'phone', 'position'],
      editText:['name', 'phone'],
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      question:null,
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    openDoc(url){
      window.open(url)
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        return new Date(b.registerDate) - new Date(a.registerDate);
      })
    },
    addDayToStudent() {
      
    },
    updateVisible() {
      this.students = this.filteredStudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.students.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents
      
    },
    getPagesNumber() {
      if (this.filteredStudents.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize) + 1
      }
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    async getStudents(students){
      return students
    },
    loadData() {
      let studentsArr = []
      auth.onAuthStateChanged(user => {
        if (user) {
          let centerEmail = user.uid
          db.ref( 'centers/' + centerEmail + '/presentations')
          .once('value')
          .then(snap=>{
            let candidates = snap.val()
            for (const key in candidates) {
              if (candidates.hasOwnProperty(key)) {
                const uid = candidates[key];
                console.log(uid)

                this.loading = true 
                db.ref('presentations/' + uid.token)
                .on('value', subSnap=>{
                  this.loading = true 
                  let candidate = subSnap.val()
                  candidate.uid = candidate.token
                  let index = this.allstudents.findIndex(x => x.uid === candidate.uid)
                  if(index != -1) this.allstudents.splice(index, 1, candidate); 
                  if(index === -1)this.allstudents.push(candidate)
                  this.sortByDate()
                  this.updateCountryFilters()
                  this.updateVisible()
                  this.getPagesNumber()
                  this.loading = false
                })
                
              }
            }
          })
        } else {
          this.$router.push('/login')
        }
      })
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    
    
  },
  
  created() {
    this.loading = true
    this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
