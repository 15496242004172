<template>
  <div class="dashboard">
    <dashboardParalex 
      :userName="userName"
      :userImage="userImage"
    />
    <v-container>
      <v-layout row wrap>
        <candidateDashboard v-if="role === 'candidate'" />
        <centerDashboard v-if="role === 'center'" />
        <aimaViewDashboard v-if="role === 'aimaview'" />
      </v-layout>
      <v-layout row wrap>
        
        <v-flex xs12 md3 pa-1>
          <v-card>
            <v-card
            color="primary"
            dark
            flat
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">Quick Links</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card-title>
              <v-layout row wrap>
                <v-progress-linear v-if="isLoading" :indeterminate="isLoading"></v-progress-linear>
                <v-flex xs12>
                  <v-list dense>
                    <template v-for="item in items">
                      <v-layout
                        row
                        v-if="item.heading"
                        align-center
                        :key="item.heading"
                      >
                        <v-flex xs6>
                          <v-subheader v-if="item.heading">
                            {{ item.heading }}
                          </v-subheader>
                        </v-flex>
                        <v-flex xs6 class="text-xs-center">
                          <a href="#!" class="body-2 black--text">EDIT</a>
                        </v-flex>
                      </v-layout>
                      <v-list-group
                        v-else-if="item.children"
                        v-model="item.model"
                        :key="item.text"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                      >
                        <v-list-tile slot="activator">
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ item.text }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                          v-for="(child, i) in item.children"
                          :key="i"
                          @click="goTo(child.path)"
                        >
                          <v-list-tile-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                          </v-list-tile-action>
                          <v-list-tile-content>
                            <v-list-tile-title  >
                              {{ child.text }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </v-list-tile>
                      </v-list-group>
                      <v-list-tile v-else @click="goTo(item.path)" :key="item.text">
                        <v-list-tile-action>
                          <v-icon>{{ item.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ item.text }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-card-title>
          </v-card>
        </v-flex>


          <v-flex xs12 md9 pa-1>
            <v-card>
              <v-card
                  color="primary"
                  dark
                  flat
              >
                <v-container fill-height fluid>
                  <v-layout fill-height>
                    <v-flex xs12 align-end flexbox>
                      <span class="headline">Announcments</span>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
              <v-card-title>
                <v-layout row wrap>
                  <v-progress-linear v-if="isLoading" :indeterminate="isLoading"></v-progress-linear>
                  <v-flex v-for="item in news" :key="item.title" xs12 ma-2 style="max-height: 150px !important;overflow-y: auto !important;">
                    <v-card color="indigo accent-4" class="white--text">
                      <v-card-title primary-title>
                        <div>
                          <span>{{ item.date | formatDate | truncate(10, '..') }}</span>
                          <div class="headline" style="font-size: 14px !important;line-height: 1.5 !important;">{{ item.title }}</div>
                          <span style="font-size: 14px;">{{ item.details }}</span>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card-title>
            </v-card>
          </v-flex>
          
          <v-flex xs12 md12 pa-1>
            <facebook />
          </v-flex>




      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb';
import facebook from '../components/facebook';
import dashboardParalex from '../components/dashboardParalex';
import MiniStatistic from '@/components/MiniStatistic';
import candidateDashboard from '@/components/candidateDashboard';
import centerDashboard from '@/components/centerDashboard';
import aimaViewDashboard from '@/components/aimaViewDashboard';


export default {
  components:{ dashboardParalex, MiniStatistic, candidateDashboard, centerDashboard, facebook },
  data() {
    return {
      news:[],
      isLoading:false,
      items:[],
      role:null,
    }
  },
  computed:{
    
  },
  watch:{
    async uid(){
      this.setItems()
    },
    $route (to, from){
      if(this.role)
      this.items = this.$store.state.menu[this.role]
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    async setItems(){
      if(this.$props.uid){
        let s = await db.ref(`candidates/${this.$props.uid}/role`).once('value').then(x=>x.val())
        let c = await db.ref(`centers/${this.$props.uid}/role`).once('value').then(x=>x.val())
        let d = await db.ref(`aima_users/${this.$props.uid}/role`).once('value').then(x=>x.val())
        this.role = s ? s : c ? c : 'user'
        if( d == "aima_users" ){
            console.log("AIMA US")
        }
        let thsRule = this.$store.state.role
        console.log("==========" + thsRule)
        this.role = thsRule
        this.items = this.$store.state.menu[this.role]
      }
      
    }
  },
  props: {
   userName: {
    required: true,
    default: 'Green Way'
   },
   userImage: {
    required: true,
    default: ''
   },
   uid: {
    required: true,
   }
  },
  created() {
    this.isLoading = true 
    db.ref('announcements/candidates')
    .once('value')
    .then(snap=>{
      let ann = snap.val()
      let newsArr = []
      for (const key in ann) {
        if (ann.hasOwnProperty(key)) {
          const newsItem = ann[key];
          //this.news.push(newsItem)
          newsArr.push(newsItem)
        }
      }
      this.news = newsArr.sort(function(a,b){ return new Date(b.date) - new Date(a.date) })
      //console.log("DATA NEWS ====================");
      //console.log(this.news)
    })
    .then(snap=>{
      this.isLoading = false
    })
    console.log('Component has been created!');
    this.setItems()
  },
  mounted() {
    console.log('Component has been mounted!');
  },
  destroyed() {
    console.log('Component has been destroyed!');
  }
}
</script>

<style>

</style>
