<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start>
        <v-flex xs12 md12>
          <v-card class="elevation-24 text-md-center">
            <v-img
                class="white--text"
                height="100px"
                src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Tokens Order Form</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                      label="Name"
                      v-model="uName"
                      readonly
                      disabled
                      solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                      label="Email"
                      v-model="uEmail"
                      readonly
                      disabled
                      solo
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                      label="Note"
                      v-model="msg"
                      solo
                  ></v-textarea>
                </v-flex>
                <v-divider></v-divider>

                <v-flex xs2>
                  <div>AIMA Assessor Tokens</div>
                  <div class="headline">{{ aimaAscount }}</div>

                </v-flex>
                <v-flex xs4>
                  <v-slider
                      v-model="aimaAscount"
                      step="1"
                      min="0"
                      max="300"
                      always-dirty thumb-label="always" :thumb-size="26"></v-slider>
                </v-flex>
                <v-flex xs3>
                  <div>Token Price</div>
                  <div>${{ getAimaTokenPrice }}</div>

                </v-flex>
                <v-flex xs3>
                  <div>Total Price</div>
                  <div>${{ getAimaTotal }}</div>
                </v-flex>
                <!--  -->


                <v-flex xs2>
                  <div>Basic Tokens</div>
                  <div class="headline">{{ bcount }}</div>

                </v-flex>
                <v-flex xs4>
                  <v-slider
                      v-model="bcount"
                      step="1"
                      min="0"
                      max="300"
                      always-dirty thumb-label="always" :thumb-size="26"></v-slider>
                </v-flex>
                <v-flex xs3>
                  <div>Token Price</div>
                  <div>${{ getBasicTokenPrice }}</div>

                </v-flex>
                <v-flex xs3>
                  <div>Total Price</div>
                  <div>${{ getBasicTotal }}</div>
                </v-flex>
                <!--  -->
                <v-flex xs2>
                  <div>Basic Online Tokens</div>
                  <div class="headline">{{ bcounto }}</div>

                </v-flex>
                <v-flex xs4>
                  <v-slider
                      v-model="bcounto"
                      step="1"
                      min="0"
                      max="300"
                      always-dirty thumb-label="always" :thumb-size="26"></v-slider>
                </v-flex>
                <v-flex xs3>
                  <div>Token Price</div>
                  <div>${{ getOnlineBasicTokenPrice }}</div>

                </v-flex>
                <v-flex xs3>
                  <div>Total Price</div>
                  <div>${{ getOnlineBasicTotal }}</div>
                </v-flex>
                <!--  -->
                <v-flex xs2>
                  <div>Advanced Tokens</div>
                  <div class="headline">{{ acount }}</div>
                </v-flex>
                <v-flex xs4>
                  <v-slider
                      v-model="acount"
                      step="1"
                      min="0"
                      max="300"
                      always-dirty thumb-label="always" :thumb-size="26"></v-slider>
                </v-flex>
                <v-flex xs3>
                  <div>Token Price</div>
                  <div>${{ getAdvancedTokenPrice }}</div>

                </v-flex>
                <v-flex xs3>
                  <div>Total Price</div>
                  <div>${{ getAdvancedTotal }}</div>
                </v-flex>
                <v-flex xs9>

                </v-flex>
                <v-flex xs3>
                  <div class="headline">Total Order</div>
                  <div class="headline">${{ getTotal(getAimaTotal, getBasicTotal, getAdvancedTotal, getOnlineBasicTotal) }}</div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="submitOrder()" :loading="loading" color="primary" dark>
                submit order
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import { titleCase, randomString, getIAIDLDate } from '@/customFunction'
import axios from 'axios'

export default {
  data(){
    return {
      name:null,
      email:null,
      msg:null,
      loading:false,
      pricing:null,
      bcount:null,
      acount:null,
      bprice:null,
      aprice:null,
      aiprice:null,
      totalOrder:null,
      bcounto:null,
      bpriceo:null,
      aimaAssessor:null,
      aimaAscount:null,

    }
  },
  methods: {
    submitOrder(){
      this.loading = true
      let ordercode = `IAIDL_O_${randomString(8, 'aA#')}`
      let basic_count = parseInt( this.bcount )
      if( isNaN(basic_count) ){basic_count = 0}

      
      let basic_count_o = parseInt( this.bcounto )
      if( isNaN(basic_count_o) ){basic_count_o = 0}
      
      let advanced_count = parseInt( this.acount )
      if( isNaN(advanced_count) ){advanced_count = 0}
      
      let aima_assessor_count = parseInt( this.aimaAscount )
      if( isNaN(aima_assessor_count) ){aima_assessor_count = 0}



      let order = {
        name:this.$props.uName,
        email:this.$props.uEmail,
        note:this.msg,
        pricing:this.pricing,
        basic_count:basic_count,
        basic_online_count:basic_count_o,
        advanced_count:advanced_count,
        aima_assessor_count:aima_assessor_count,
        bprice:this.bprice,
        aprice:this.aprice,
        aiprice:this.aiprice,
        aimaAssessor:this.aimaAssessor,
        totalOrder:this.totalOrder,
        date:getIAIDLDate(),
        code:ordercode,
        center:this.$store.state.centerData.uid
      }
      console.log(order)
      
      db.ref('tokenOrders').push(order)
          .then(x=>{
axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=1234&type=new-token-order&etype=new-token-order&level=new-token-order`)
                      .then(response => { 
            this.loading = false
            this.$emit('snackbarNotification', `Order ${ordercode} Submited`, 'success')
            this.goTo('/')
                      })
          })
          
    },
    goTo(path) {
      this.$router.push(path)
    },
    getPrices(){
    console.log("=======================")
    let country = this.$store.state.centerData.country.toLowerCase()
    country = country.toLowerCase()
    console.log(country)


      //get center country
      
      db.ref('settings/pricing')
          .once('value')
          .then(x=>{
            this.pricing = x.val()
          })
    },
    getTotal(d, b, a, c){
      if(!d &&!b && !a && !c) return 0
      let Total = parseInt(d) + parseInt(b) + parseInt(a) + parseInt(c)
      this.totalOrder = Total
      return Total
    }
  },
  props:{
    uName:{
      required:true
    },
    uEmail:{
      required:true
    },
  },
  computed:{
    getAimaTokenPrice(){
      if(!this.pricing) return 0
      this.aimaAssessor
      if(this.aimaAscount <= 5) {
        this.aimaAssessor = this.pricing.aimaassessor[1]
      } else if(this.aimaAscount < 11) {
        this.aimaAssessor = this.pricing.aimaassessor[5]
      } else if(this.aimaAscount >= 11) {
        this.aimaAssessor = this.pricing.aimaassessor[11]
      }
      return this.aimaAssessor
    },
    getBasicTokenPrice(){
      if(!this.pricing) return 0
      this.bprice
      if(this.bcount <= 15) {
        this.bprice = this.pricing.basic[15]
      } else if(this.bcount <= 50) {
        this.bprice = this.pricing.basic[50]
      } else if(this.bcount <= 100) {
        this.bprice = this.pricing.basic[100]
      } else if(this.bcount > 100) {
        this.bprice = this.pricing.basic[101]
      }
      return this.bprice
    },
    getAimaTotal(){
      if(!this.aimaAscount) return 0
      return this.aimaAssessorTotal = this.aimaAssessor * this.aimaAscount
    },
    getBasicTotal(){
      if(!this.bprice) return 0
      return this.btotal = this.bprice * this.bcount
    },
    getOnlineBasicTokenPrice(){
      if(!this.pricing) return 0
      this.bpriceo
      if(this.bcounto > 0) {
        this.bpriceo = this.pricing.basico[15]
      }
      return this.bpriceo
    },
    getOnlineBasicTotal(){
      if(!this.bpriceo) return 0
      return this.btotalo = this.bpriceo * this.bcounto
    },
    getAdvancedTokenPrice(){
      if(!this.pricing) return 0
      this.aprice
      if(this.acount <= 15) {
        this.aprice = this.pricing.advanced[15]
      } else if(this.acount <= 50) {
        this.aprice = this.pricing.advanced[50]
      } else if(this.acount <= 100) {
        this.aprice = this.pricing.advanced[100]
      } else if(this.acount > 100) {
        this.aprice = this.pricing.advanced[101]
      }
      return this.aprice
    },
    getAdvancedTotal(){
      if(!this.aprice) return 0
      return this.atotal = this.aprice * this.acount
    },

  },
  created(){
    this.getPrices()
  }
}
</script>

<style>

</style>
