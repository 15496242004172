<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <!-- <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            label="Show Only"
            multiple
            solo
            chips
            @change="getSelectedStatus"
            hint="Select who are"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Show All But"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex> -->
        <v-flex xs12 md12 px-1>
        <v-text-field
          label="Search By Name/Email"
          solo
          v-model="searchText"
          @input="getSearchString()"
        ></v-text-field>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>

      <v-card 
      v-for="(student,i) in students"
      :key="i"
      >
        <v-layout row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Trainer Title</div>
            <div >{{ student.title }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Trainer Name</div>
            <div >{{ student.name }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Email</div>
            <div >
              <span >{{ student.email | truncate(10, '..') }}</span>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">
              Phone 
            </div>
            <div>{{ student.phone }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Corporate</div>
            <div>{{ student.corporate }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Date of Submission</div>
            <div>{{ student.registerDate | formatDate | truncate(10, '..') }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Nationality</div>
            <div>{{ student.country }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Approval</div>
            <v-tooltip v-if="!student.proccessed" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  color="error"
                >
                </v-avatar>
              </span>
              <span>Under proccessing</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed && !student.conditional && !student.msg" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Trainer Approved' : 'Trainer Rejected' }}</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed && student.conditional && !student.msg && !student.approved" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.conditional?'warning' : 'error' "
                >
                </v-avatar>
                <span v-if="student.proccessed && student.conditional" style="font-size:9px">Trainer Conditional Approved | Condition: Pass Basic Certification</span>
              </span>
              <span>{{ student.conditional ? 'Trainer Conditional Approved | Condition: Pass Basic Certification' : 'Trainer Not Approved' }}</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed && student.conditional && student.msg && !student.approved" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.conditional?'warning' : 'error' "
                >
                </v-avatar>
                <span v-if="student.proccessed && student.conditional && student.msg && !student.approved" style="font-size:9px">{{ student.msg }}</span>
              </span>
              <span>{{ student.msg }}</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed && student.approved" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Trainer Approved' : 'Trainer Not Approved' }}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs12>
                <v-expansion-panel expand>
                  <v-expansion-panel-content>
                    <div slot="header">Qualifications</div>
                    <v-card>
                      <v-card-text>
                        {{ student.qualifications }}
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
              <v-flex xs12>
                <v-expansion-panel expand>
                  <v-expansion-panel-content>
                    <div slot="header">Documetns</div>
                    <v-card>
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex v-for="doc in student.documents" :key="doc.url" xs12>
                            <v-chip color="success" text-color="white" label>{{doc.name}}</v-chip>
                            <v-btn @click="openDoc(doc.url)" small color="primary">open document</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-layout>
          </v-card-actions>
      </v-card>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      displayText:['age', 'corporate', 'country', 'degree', 'email', 'gender', 'name', 'phone', 'position'],
      editText:['name', 'phone'],
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      question:null,
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    openDoc(url){
      window.open(url)
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        return new Date(b.registerDate) - new Date(a.registerDate);
      })
    },
    addDayToStudent() {
      
    },
    updateVisible() {
      this.students = this.filteredStudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.students.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents
      .filter(student =>  {
        if (this.selectedStatus.length > 0 && this.selectedStatus.length < this.stepsSelector.length) {
          if (student.lead) {
            for (let index = 0; index < this.selectedStatus.length; index++) {
              const element = this.selectedStatus[index];
              if (student.lead[element] === true) {
                return student
              }
            }
          } 
        } else {
          return student
        }
      })
      .filter(student => {
        if (this.nselectedStatus.length > 0 && this.nselectedStatus.length < this.stepsSelector.length) {
          if (!student.lead) {
            return student
          } else {
            for (let index = 0; index < this.nselectedStatus.length; index++) {
              const element = this.nselectedStatus[index];
              if (student.lead[element] !== true) {
                return student
              }
            }
          }
        } else {
          return student
        }
      })
      .filter(student => {
        let name = student.name.toLowerCase();
        let email = student.email.toLowerCase();
        let search = this.searchText.toLowerCase();
        if (name.includes(search) || email.includes(search)) {
          return student
        }
      })
      .filter(student => {
        return student
      })
    },
    getPagesNumber() {
      this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    loadData() {
      let studentsArr = []
      auth.onAuthStateChanged(user => {
        if (user) {
          let centerEmail = user.uid
          db.ref( 'centers/' + centerEmail + '/trainers')
          .once('value')
          .then(snap=>{
            let candidates = snap.val()
            for (const key in candidates) {
              if (candidates.hasOwnProperty(key)) {
                const uid = candidates[key];
                this.loading = true 
                db.ref('trainers/' + uid)
                .on('value', subSnap=>{
                  this.loading = true 
                  let candidate = subSnap.val()
                  candidate.uid = uid
                  let index = this.allstudents.findIndex(x => x.uid === candidate.uid)
                  if(index != -1) this.allstudents.splice(index, 1, candidate); 
                  if(index === -1)this.allstudents.push(candidate)
                  this.sortByDate()
                  this.updateCountryFilters()
                  this.updateVisible()
                  this.getPagesNumber()
                  this.loading = false
                })
                
              }
            }
          })
        } else {
          this.$router.push('/login')
        }
      })
    },
    toggleAccess(uid, item, val) {
      db.ref('candidates/' + uid + '/access/basic')
      .update({
        [item]:!val
      })
      if (item == 'material') {
        return
      }
      db.ref('candidates/' + uid + '/' + item +'/basic')
      .update({
        access:!val
      })
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    getMaterialProgress(user, level){
      console.log('------------------------------------');
      console.log(user);
      console.log('------------------------------------');
      let materialProgress = user ? user.material[level] : null
      if (!materialProgress) return true
      db.ref('manuals')
      .once('value')
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        let manuals = fms.filter(x=> x.level == level && user && x.language == user.language )
        console.log(manuals)
        
        manuals.forEach(manual => {
          let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false 
          let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
          manual.progress = {
            started,
            progress
          }
        });
        let progress = 0
        manuals.forEach(manual => {
          progress += manual.progress.progress
        });
        this.bMaterialProgress = progress / manuals.length
      })
    },
    results(user, type, level) {
      let exam = user ? user[type][level].exam : null
      let correctAnswers = user ? user[type][level].correctAnswers : null
      if(!exam) return true
      let examCorrection = []
      let count = 0
      let trueCount = 0
      for (const key in exam) {
        if (exam.hasOwnProperty(key)) {
          const answer = exam[key];
          count++
          if (!correctAnswers) {
            examCorrection.push({[key]:false})
          } else {
            if (correctAnswers.indexOf(key) != -1) {
              examCorrection.push({[key]:true})
              trueCount++
            } else {
              examCorrection.push({[key]:false})
            }
          }
        }
      }
      let score = trueCount / count * 100
      this.$emit('progressBar', false)
      return {examCorrection, score}
    },
    loadQs(key) {
      this.Qdialog = true
      db.ref('questions/' + key)
      .once('value')
      .then(sn=>{
        this.question = sn.val()
      })
    }
  },
  
  created() {
    this.loading = true
    this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
