<template>
  <v-parallax
    dark
    height="350"
    src="https://portal.iaidl.org/tback.jpg"
    ma-0
  >
    <v-layout
      align-center
      column
      justify-center
    >
      <h1 class="display-2 font-weight-thin mb-3">Hello {{userName}}</h1>
      <h4 class="subheading">wish you a productive day!</h4>
    </v-layout>
  </v-parallax>
</template>
<script>
import {firebase, auth, db} from '@/fb';

export default {
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  props: {
   userName: {
    required: true,
    default: 'GreenWay'
   },
   userImage: {
    required: true,
    default: ''
   }
  },
  created () {
  }
}
</script>

<style>

</style>