<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start >
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24 " @load="getEmail">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox class="text-md-center">
                    <span class="headline">IAIDL<sup>®</sup> 
                      {{ (level === 'basic')? 'Basic' : ''}}
                      {{ (level === 'advanced')? 'Advanced' : ''}}
                      {{ (level === 'aimaassessor')? 'AIMA' : ''}}
                      {{ (type === 'preassessment')? 'Pre-Assessment' : 'Certification'}}
                      {{ (view === 'exam')? 'Exam' : 'Results'}} 
                    </span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-layout wrap row justify-start ma-3>
              <v-flex xs12 md12 pa-1>
                <v-card v-if="examAccess && !examDone && examTime">
                  <v-card-title>
                    <div class="layout row ma-0 justify-space-between pb-1">
                      <div class="subheading">{{ (type === 'preassessment')? 'Pre-Assessment' : 'Certification'}}</div>
                      <div class="icon">
                        <v-icon color="secondary">{{ (type === 'preassessment')? 'assessment' : 'assignment'}}</v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text :class="`${(!done?'pb-0 pt-0':null)}`">
                    <div>
                      <v-layout row wrap my-0>
                        <v-flex xs12>
                          <h1>{{ (type === 'preassessment')? 'Pre-Assessment Exam' : ' Certification Exam'}} Instructions</h1>
                        </v-flex>
                        <v-flex xs12>
                          <span>
                            The Exam is adaptive and uses AI to assess individual competance <br>
                            Questions are random and unique per candidate with different answers set <br>
                            Switching tabs or accessing other websites during exam is detectable and strictly prohibited and will lead to exam exit <br>
                            Any cheating attempt of any type will lead to exam failure and certification revoking <br>
                            Questions are timed with individual timers and on 0 time left you will be taken to next question<br>
                            Please allow the usage of your camera by the system to log your exam, your video will not be stored on our servers and will only be analysed on your machine to observe your exam focus & malpractice possibility <br>
                            When promoted please click allow <br>
                          </span>
                          <v-checkbox color="primary" label="I acknowledge that I read and understand the exam instructions and ready to start the exam" v-model="acknowledge"></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn 
                      color="primary"
                      dark
                      v-if="!done && acknowledge && !prelock"
                      @click="startExam()" 
                    >
                      Start Exam
                      <v-icon right>play_arrow</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-if="examDone">
                  <v-card>
                    <v-card-title v-if="level==='basic'">
                      <div class="layout row ma-0 justify-space-between pb-1">
                        <div class="subheading">{{ (type === 'preassessment')? 'Pre-Assessment' : 'Certification'}}</div>
                        <div class="icon">
                          <v-icon :color="results.score >= 50 ?  'success' : 'error'">{{(type === 'preassessment')? 'assessment' : 'assignment'}}</v-icon>
                        </div>
                      </div>
                    </v-card-title>
                    <v-card-title v-else-if="level==='aimaassessor'">
                      <div class="layout row ma-0 justify-space-between pb-1">
                        <div class="subheading">{{ (type === 'preassessment')? 'Pre-Assessment' : 'Certification'}}</div>
                        <div class="icon">
                          <v-icon :color="results.score >= 70 ?  'success' : 'error'">{{(type === 'preassessment')? 'assessment' : 'assignment'}}</v-icon>
                        </div>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="justify-center row layout ma-0">
                        <v-progress-circular v-if="level==='basic'"
                          :size="150"
                          :width="15"
                          :rotate="-90"
                          :value="results.score"
                          :color="results.score >= 50 ?  'success' : 'error'"
                        >
                          
                          <div 
                            class="headline text-md-center" 
                            text-color="results.score >= 50 ?  'success' : 'error'">
                          {{ parseInt(results.score) }}%</div>
                          <div 
                            class="headline text-md-center" 
                            text-color="results.score >= 50 ?  'success' : 'error'">
                            {{ results.score >= 50 ?  'Pass' : 'Fail' }}
                          </div>
                        </v-progress-circular>
                        <v-progress-circular v-else-if="level==='aimaassessor'"
                          :size="150"
                          :width="15"
                          :rotate="-90"
                          :value="results.score"
                          :color="results.score >= 70 ?  'success' : 'error'"
                        >

                          <div
                            class="headline text-md-center"
                            text-color="results.score >= 70 ?  'success' : 'error'">
                          {{ parseInt(results.score) }}%</div>
                          <div
                            class="headline text-md-center"
                            text-color="results.score >= 70 ?  'success' : 'error'">
                            {{ results.score >= 70 ?  'Pass' : 'Fail' }}
                          </div>
                        </v-progress-circular>
                      </div>
                      <v-layout row wrap>
                        <v-flex xs12 mt-2>
                          <v-alert type="warning" :value="true" v-if="!allowPreRestart.allow && !prePass" my-5>
                            You can restart Pre-Assessment after
                            {{allowPreRestart.allowedDate}}
                          </v-alert>
                        </v-flex>
                        <v-flex xs12 mt-2>
                          <v-alert type="error" :value="true" v-if="type == 'exam' && examDone && results.score < 50" my-5>
                            We are sorry but you failed your IAIDL<sup>®</sup> certification Exam
                          </v-alert>
                        </v-flex>
                      </v-layout>
                    </v-card-text>






                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" v-if="allowPreRestart.allow && !prePass" @click="restartPreassessment()">Restart Pre-Assessment</v-btn>
                      <v-btn color="primary" v-if="prePass && !certificationExam" @click="goToExam()">Start Certification Exam</v-btn>
                      <v-btn color="primary" v-if="type == 'exam' && examDone && results.score >= 50" @click="goToCertificate()">Certificate</v-btn>
                    </v-card-actions>
                  </v-card> 
                  <div v-for="(result, i) in results.examCorrection" :key="i">
                    <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                      Your answer to Question: {{ Object.keys(result)[0] }} was
                      {{ result[Object.keys(result)[0]] }}
                      <!-- <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn> -->
                    </v-alert>
                  </div>
                  <v-dialog
                      v-model="dialog"
                      :overlay="false"
                      max-width="500px"
                      transition="dialog-transition"
                    >
                      <v-card>
                        <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                        <v-card-text v-if="question">
                          <v-radio-group v-model="question.correctAnswer">
                            <v-radio 
                            v-for="answer in question.answers" 
                            :key="question.key + '_' + answer.number" 
                            :name="answer.text"
                            :label="answer.text"
                            readonly
                            :value="answer.number" 
                            color="primary"
                            ></v-radio>
                          </v-radio-group>
                        </v-card-text>
                        <v-card-actions>
                          <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-1" flat @click="dialog = false">Dismiss</v-btn>
                        </v-card-actions>
                      </v-card>
                  </v-dialog>
                </v-card>
                <v-card v-if="type =='exam' && !examAccess && !examDone && user">
                  <v-alert type="error" :value="true">
                      You are not allowed to take exam before you Pass Pre-Assessment exam
                  </v-alert>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="goToPreassessment()">{{allowPreRestart.allow ? 'Re' : ''}}Start Pre-Assessment</v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-if="type =='exam' && examAccess && !examDone && user && !examTime">
                  <v-alert type="error" :value="true">
                      You are outside Official exam session
                  </v-alert>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="goTo('/')">Return to Dashboard</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-flex>
            <!-- <v-divider></v-divider> -->

      </v-layout>
    </v-container>
    <videoTrack :trigger="trigger" v-if="trigger && examAccess && !examDone && examTime"/>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import { launchFullScreen, getIAIDLDate, getRandomInt } from '@/customFunction'
import videoTrack from '../components/videoTrack'

export default {
  props: ['isLogin'],
  components:{ videoTrack },
  data: ()=> ({
    date: new Date().toISOString(),
    done:false,
    dialog:false,
    trigger:false,
    acknowledge:false,
    user:null,
    type:null,
    level:null,
    view:null,
    question:null
  }),
  watch:{
    '$route' (to, from) {
      this.view = this.$route.params.view
      this.type = this.$route.params.type
      this.level = this.$route.params.level
    }
  },
  computed: {
    prelock(){
      return  this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].started : null
    },
    results() {
      let exam = this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].exam : null
      console.log(exam)
      let correctAnswers = this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].correctAnswers : null
      console.log(correctAnswers)
      if(!exam) return true
      let examCorrection = []
      let count = 0
      let trueCount = 0
      for (const key in exam) {
        if (exam.hasOwnProperty(key)) {
          const answer = exam[key];
          count++
          if (!correctAnswers) {
            examCorrection.push({[key]:false})
          } else {
            if (correctAnswers.indexOf(key) != -1) {
              examCorrection.push({[key]:true})
              trueCount++
            } else {
              examCorrection.push({[key]:false})
            }
          }
        }
      }
      let score = this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].score : null
      this.$emit('progressBar', false)
      return {examCorrection, score}
    },
    examDone(){
      let d =  this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].done : null
      let e =  this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].exit : null
      if (d && e) this.goTo(`/exam/${this.$route.params.type}/${this.$route.params.level}/result`)
      return d
    },
    prePass(){
      return this.$store.state.userData ? this.$store.state.userData.preassessment[this.$route.params.level].pass : false
    },
    certificationExam(){
      return this.$store.state.userData ? this.$store.state.userData.exam[this.$route.params.level].done : false
    },
    examAccess(){
      let a = this.$store.state.userData ? this.$store.state.userData[this.$route.params.type][this.$route.params.level].access : null
      if (this.$route.params.type === 'exam') {
        a = this.$store.state.userData ? this.$store.state.userData.preassessment[this.$route.params.level].pass : false
      }
      return a
    },
    examTime(){
      let a = true
      if (this.$route.params.type === 'exam') {
        let curTime = new Date()
        let start = this.$store.state.userData ? this.$store.state.userData.exam[this.$route.params.level].startTime : false
        let end = this.$store.state.userData ? this.$store.state.userData.exam[this.$route.params.level].endTime : false
        if(curTime > start && curTime < end) {
          a = true
        } else {
          a = false
        }
      }
      return a
    },
    allowPreRestart(){
      let a = this.$store.state.userData ? this.$store.state.userData.preassessment[this.$route.params.level] ? this.$store.state.userData.preassessment[this.$route.params.level].meta ? this.$store.state.userData.preassessment[this.$route.params.level].meta.done ? this.$store.state.userData.preassessment[this.$route.params.level].meta.done : false : false : false : false
      let allow = false
      let allowedDate
      if (a) {
        let lastDate = new Date(a)
        let now = new Date()
        let week = 1000*60*60*24*7
        let gap = lastDate + week
        allowedDate = new Date(lastDate.setDate(lastDate.getDate()+7)).toString();
        if (now - lastDate > week) {
          let allow = true
          return {allowedDate, allow}
        }
        return {allowedDate, allow}
      }
      return {allowedDate, allow}
    },
    getEmail() {
      let v = this.$store.state.userData ? this.$store.state.userData : null
      this.user = v
      return true 
    },
    
  },
  methods: {
    restartPreassessment() {
      db.ref('candidates/' + this.user.uid + '/preassessment/' + this.$route.params.level)
      .set({
        access:true,
        score:0,
        started:false,
      })
    },
    goToPreassessment() {
      this.goTo('/exam/preassessment/' + this.$route.params.level + '/exam')
    },
    goToExam() {
      this.goTo('/exam/exam/' + this.$route.params.level + '/exam')
    },
    goToCertificate() {
      this.goTo('/certificate/' + this.$route.params.level)
    },
    startExam() {
      this.markStart()
      // Launch fullscreen for browsers that support it!
      launchFullScreen(document.documentElement); // the whole page
      this.goTo(`/exam-mode/${this.$route.params.type}/${this.$route.params.level}`)
    },
    markStart(){
      if(this.review) return
      db.ref('candidates/' + this.user.uid + `/${this.$route.params.type}/${this.$route.params.level}/`)
      .update({
        started:true,
        done:true,
        meta:{
          start:getIAIDLDate()
        }
      })
    },
    goTo(path) {
      this.$router.push(path)
    },
    loadQs(key) {
      this.dialog = true
      db.ref('questions/' + key)
      .once('value')
      .then(sn=>{
        this.question = sn.val()
      })
    }
  },
  created() {
    this.view = this.$route.params.view
    this.type = this.$route.params.type
    this.level = this.$route.params.level
    this.trigger = true

  },
  beforeCreate(){
    this.$emit('progressBar', true)
  },
  mounted() {
    this.$emit('progressBar', false)
    this.$store.state.triggerStore = true

    

  },
  destroyed(){
    this.trigger = false
    this.$store.state.triggerStore = false

  }
}

</script>