<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Request Help</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
            <v-flex xs12 sm12 px-1 my-1>
              <v-text-field
                label="Ticket #"
                readonly
                v-model="ticket"
                solo-inverted
                suffix="Ticket #"
                :loading="ticketLoading"
                disabled
                light
                class="ticketField"
                color="white"
                background-color="primary"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 sm2 px-1 my-1>
              <v-autocomplete
                :items="subjects"
                v-model="ticketSubject"
                label="Subject"
                solo
                prepend-inner-icon="turned_in"
                :error-messages="subjectErrors"
                @blur="$v.ticketSubject.$touch()"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs8 sm4 px-1 my-1>
              <v-text-field
                label="Full Name"
                v-model="studenName"
                solo
                clearable
                required
                prepend-inner-icon="person"
                :error-messages="nameErrors"
                @blur="$v.studenName.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Email"
                v-model="studentEmail"
                solo
                clearable
                required
                hint="Please enter a valid email"
                prepend-inner-icon="email"
                :error-messages="emailErrors"
                @blur="$v.studentEmail.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Phone Number"
                v-model="studentPhone"
                solo
                clearable
                required
                hint="Please use following format 00491728319076"
                prepend-inner-icon="phone"
                :error-messages="phoneErrors"
                @blur="$v.studentPhone.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-autocomplete
                :items="gender"
                item-text="name"
                v-model="studentGender"
                label="Gender"
                solo
                prepend-inner-icon="wc"
                :error-messages="genderErrors"
                @blur="$v.studentGender.$touch()"
                :disabled="noToken"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Age"
                v-model="studentAge"
                solo
                clearable
                required
                prepend-inner-icon="person"
                :error-messages="ageErrors"
                @blur="$v.studentAge.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Degree"
                v-model="studentDegree"
                solo
                clearable
                required
                prepend-inner-icon="school"
                :error-messages="degreeErrors"
                @blur="$v.studentDegree.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Corporate"
                v-model="studentCorporate"
                solo
                clearable
                required
                prepend-inner-icon="account_balance"
                :error-messages="corporateErrors"
                @blur="$v.studentCorporate.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Position"
                v-model="studentPosition"
                solo
                clearable
                required
                prepend-inner-icon="work"
                :error-messages="positionErrors"
                @blur="$v.studentPosition.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 px-1 my-1>
              <v-autocomplete
                :items="countries"
                item-text="name"
                v-model="studentCountry"
                label="Candidate Country"
                solo
                prepend-inner-icon="public"
                :error-messages="countryErrors"
                @blur="$v.studentCountry.$touch()"
                :disabled="noToken"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 px-1 my-1>
              <span>Give Access to:</span>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-switch
                label="Material"
                color="primary"
                hide-details
                v-model="accessMaterial"
                :disabled="noToken"
              ></v-switch>
              <v-switch
                label="Pre-Assessment"
                color="primary"
                hide-details
                v-model="accessPre"
                :disabled="noToken"
              ></v-switch>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-switch
                label="Exam"
                color="primary"
                hide-details
                v-model="accessExam"
                :disabled="noToken"
              ></v-switch>
              
            </v-flex>
            
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <span v-if="dberror" class="red--text">{{ dberror }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="submit()"  large>
                  Add Student {{ studenName }}
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>
      
      <v-card id="creds" v-if="dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://pngimage.net/wp-content/uploads/2018/06/material-blue-png.png"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Candidate Login Info</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-card-title primary-title>
          <div>
            <div>Email: {{ studentEmail }}</div>
            <div>Password: {{ password }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="printBtn" flat @click="print()" color="primary">Print</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase } from '@/fb';
import { Printd } from 'printd'

export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      gender:["Male", "Female"],
      subjects:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      studentTitle:null,
      studenName:null,
      studentEmail:null,
      studentPhone:null,
      studentGender:null,
      studentAge:null,
      studentDegree:null,
      studentCorporate:null,
      studentPosition:null,
      studentCountry:null,
      dberror:null,
      dbsuccess:null,
      password:null,
      printBtn:true,
      accessMaterial:false,
      accessPre:false,
      accessExam:false,
      tokenKey:null,
      ticketLoading:true,
      ticketID:null,
      ticketSubject:null,
    }
  },
  methods: {
    getTicketID(){
      this.ticketID = randomString(8, 'aA#')
    },
    print() {
      let printd = new Printd()
      const iframe = printd.getIFrame()
      iframe.addEventListener('load', () => console.log('iframe loaded!'))
      const { contentWindow } = iframe
      // contentWindow.addEventListener('beforeprint', () => this.printBtn = false))
      // contentWindow.addEventListener('afterprint', () => this.printBtn = true))
      printd.print(document.getElementById('creds'))
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (noToken) {
        return
      }
      if (this.$v.$invalid) {
        console.log('errors there')
      } else {
        this.isLoading = true 
        this.$emit('progressBar', true)
        db.ref('candidates/' + this.studentEmail.replace(/\./g, '_dot_'))
        .once('value')
        .then(snap=>{
          let student = snap.val()
          if (!student) {
            this.password = randomString(8, 'aA#')
            let student = {
              center:this.$store.state.centerData.email.replace(/\./g, '_dot_'),
              registerDate:this.date,
              title:this.studentTitle,
              name:titleCase(this.studenName),
              email:this.studentEmail,
              phone:this.studentPhone,
              gender:this.studentGender,
              age:this.studentAge,
              degree:this.studentDegree,
              corporate:this.studentCorporate,
              position:this.studentPosition,
              country:this.studentCountry,
              password:this.password,
              role:'candidate',
              access: {
                material:this.accessMaterial,
                preassessment:this.accessPre,
                exam:this.accessExam,
              }
            }
            db.ref('centers/' + this.$store.state.user.email.replace(/\./g, '_dot_') + '/candidates')
            .push(this.studentEmail.replace(/\./g, '_dot_'))
            db.ref('centers/' + this.$store.state.user.email.replace(/\./g, '_dot_') + '/tokens/' + this.tokenKey)
            .update({
              expired:true,
              candidate: this.studentEmail.replace(/\./g, '_dot_')
            })
            db.ref('candidates/' + this.studentEmail.replace(/\./g, '_dot_')).update(student)
            db.ref('centers/' + this.$store.state.user.email.replace(/\./g, '_dot_'))
            .once('value')
            .then(snap=>{
              if (snap.val()) {
                this.$store.state.tokens = snap.val().tokens
              }
            })
            createFirebase.auth().createUserWithEmailAndPassword(this.studentEmail, this.password).then(data => {
              console.log(data)
              data.user.updateProfile({
                displayName: titleCase(this.studenName),
                photoURL: "https://scontent.fnbe1-1.fna.fbcdn.net/v/t1.0-9/52927507_765967403788702_5177032688153919488_n.png?_nc_cat=100&_nc_ht=scontent.fnbe1-1.fna&oh=69b1ec5fd66a9a180e560ef3e57a98ce&oe=5CDA3C5F",
              }).then(function() {
                
              }).catch(function(error) {
                // An error happened.
              });
              this.$emit('snackbarNotification', `${this.studenName} is registered successfully`, 'success')
              this.$emit('alertNotification', `${this.studenName} is registered successfully`, 'success')
              this.dbsuccess= true
              this.isLoading = false
              this.$emit('progressBar', false)
            }).catch(function(error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              this.$emit('snackbarNotification', `${errorCode} ${errorMessage}`, 'error')
              this.$emit('alertNotification', `${errorCode} ${errorMessage}`, 'error')
            });
            
          } else {
            this.dberror = 'A user with this email already exists'
            this.$emit('snackbarNotification', this.dberror, 'error')
          }
        })
      }
    }
  },
  watch: {
    
  },
  computed: {
    ticket() {
      this.ticketLoading = true
      let ticket = this.ticketID + '_' + (this.$store.state.centerData ? this.$store.state.centerData.name : '') 
      this.ticketLoading = false
      return ticket
    },
    nameErrors () {
      const errors = []
      if (!this.$v.studenName.$dirty) return errors
      !this.$v.studenName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.studenName.maxLength && errors.push('Name must be not longer than 20 characters')
      !this.$v.studenName.required && errors.push('Name is required')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.studentEmail.$dirty) return errors
      !this.$v.studentEmail.email && errors.push('Email is incorrect')
      !this.$v.studentEmail.required && errors.push('Email is required')
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.studentPhone.$dirty) return errors
      !this.$v.studentPhone.numeric && errors.push('Only numbers are accepted')
      !this.$v.studentPhone.required && errors.push('Phone is required')
      return errors
    },
    countryErrors () {
      const errors = []
      if (!this.$v.studentCountry.$dirty) return errors
      !this.$v.studentCountry.required && errors.push('Country is required')
      return errors
    },
    genderErrors () {
      const errors = []
      if (!this.$v.studentGender.$dirty) return errors
      !this.$v.studentGender.required && errors.push('Gender is required')
      return errors
    },
    subjectErrors () {
      const errors = []
      if (!this.$v.ticketSubject.$dirty) return errors
      !this.$v.ticketSubject.required && errors.push('Subject is required')
      return errors
    },
    ageErrors () {
      const errors = []
      if (!this.$v.studentAge.$dirty) return errors
      !this.$v.studentAge.numeric && errors.push('Only numbers are accepted')
      !this.$v.studentAge.required && errors.push('Age is required')
      return errors
    },
    degreeErrors () {
      const errors = []
      if (!this.$v.studentDegree.$dirty) return errors
      !this.$v.studentDegree.maxLength && errors.push('Degree must be not longer than 20 characters')
      !this.$v.studentDegree.required && errors.push('Degree is required')
      return errors
    },
    corporateErrors () {
      const errors = []
      if (!this.$v.studentCorporate.$dirty) return errors
      !this.$v.studentCorporate.maxLength && errors.push('Corporate must be not longer than 20 characters')
      !this.$v.studentCorporate.required && errors.push('Corporate is required')
      return errors
    },
    positionErrors () {
      const errors = []
      if (!this.$v.studentPosition.$dirty) return errors
      !this.$v.studentPosition.maxLength && errors.push('Position must be not longer than 20 characters')
      !this.$v.studentPosition.required && errors.push('Position is required')
      return errors
    },
    
    
  },
  validations: {
    studenName: {
      required,
      minLength: minLength(4),
      maxLength:maxLength(20)
    },
    studentEmail: {
      required,
      email
    },
    studentPhone: {
      required,
      numeric
    },
    studentCountry: {
      required
    },
    studentGender: {
      required
    },
    studentTitle: {
      required
    },
    studentAge: {
      required,
      numeric
    },
    studentDegree: {
      required,
      maxLength:maxLength(20)
    },
    studentCorporate: {
      required,
      maxLength:maxLength(20)
    },
    studentPosition: {
      required,
      maxLength:maxLength(20)
    },
  },
  created() {
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    })
    this.getTicketID()

  }
}
</script>
<style>
.ticketField * {
  color:white !important;
}
</style>
