<template>
  <div v-if="isLogin? isLogin: null">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card :class="`elevation-24 ${language == 'AR' ? 'allRTL' : null}`" :style="`${language == 'AR' ? 'direction: rtl !important;' : null}`" @load="getEmail">
      <v-stepper v-if="questions.length != 0" @load="getSteps" v-model="e1">
        <v-toolbar color="primary" dark app extended extension-height="4">
          <countdown
          v-for="n in steps" 
          v-if="e1 === n" 
          :key="n" 
          @onFinish="()=> { nextStep(questions[n-1].key, n)}" 
          :left-time="1000*questions[n-1].questionTime"
          > 
            <span
              slot="process"
              slot-scope="{ timeObj }"
              >
              <v-progress-circular
                :rotate="360"
                :size="20"
                :width="5"
                :value="timeObj.ceil.s*100/questions[n-1].questionTime"
                :color="((timeObj.ceil.s*100/questions[n-1].questionTime)>30) ? 'white' : 'error'"
              ></v-progress-circular>
              {{ timeObj.ceil.s }}s
              </span>
            <span slot="finish">Finished</span>
          </countdown>
          <v-stepper-header class="customStepperHeader" style="overflow: hidden !important;">
            <template v-for="n in steps">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="e1 > n"
                :step="n"
                class="hidden-sm-and-down"
              >
              </v-stepper-step>
              <v-divider
                v-if="n !== steps"
                :key="n"
              ></v-divider>
            </template>
        </v-stepper-header>
        </v-toolbar>
        
        <v-stepper-items v-on:dragstart="getRight()" @click.right="getRight()" @click.left="getRight()">
          <v-stepper-content
            v-for="n in steps"
            :key="`${n}-content`"
            :step="n"
            px-1
          >
          <h3>Question #{{ n }}</h3>
          <h3>{{ questions[n-1].text }}</h3>
          
          <v-layout row wrap>
            <v-flex xs12>
              <!-- <v-checkbox v-for="answer in questions[n-1].answers" :key="answer.number" :label="answer.text" v-model="questions[n-1][answer.number]" color="primary"></v-checkbox> -->
              <v-radio-group v-model="questions[n-1]['studentAnswer']">
                <v-radio 
                v-for="answer in questions[n-1].answers" 
                :key="questions[n-1] + answer.number" 
                :name="questions[n-1] + answer.text"
                :label="answer.text" 
                :value="answer.number" 
                color="primary"
                ></v-radio>
              </v-radio-group>
              
            </v-flex>
          </v-layout>
            <v-btn
              v-if="n!==steps"
              color="primary"
              @click="nextStep(questions[n-1].key, n)"
            >
              Next
            </v-btn>
            <v-btn
              v-if="n==steps"
              color="primary"
              @click="nextStep(questions[n-1].key, n)"
            >
              Finish Exam
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <videoTrack :triggere="triggere" />
  </div>
    

  
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import { closeFullscreen, getIAIDLDate } from '@/customFunction'
import videoTrack from '../components/videoExam'
import axios from 'axios';

export default {
  components:{ videoTrack },
  props: ['isLogin'],
  data: () => ({
      date: new Date().toISOString(),
      questions:[],
      type:null,
      dialog:false,
      level:null,
      e1: 1,
      steps: 0,
      width:0,
      user:null,
      triggere:false,
      language:'EN'

  }),
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getQus(){
      this.type = this.$route.params.type;
      this.level = this.$route.params.level;
      this.triggere = true;

    },
    getManuals() {
      //this.questions = this.$store.state.preassessment.questions
      this.dialog = true
      console.log(this.user)
      auth.onAuthStateChanged(user => {
        if (user) {
          //console.log(user)
          //console.log(`https://us-central1-astack-8276c.cloudfunctions.net/main/exam/?key=${user.uid}&type=${this.type}&level=${this.level}&language=${this.$store.state.userData.language}`)
          axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/exam/?key=${user.uid}&type=${this.type}&level=${this.level}&language=${this.$store.state.userData.language}`)
          .then(response => {
            console.log(response)
            if (response.data.status) {
              this.questions = response.data.questions
              this.language = this.$store.state.userData.language
              // this.questions = [...this.$store.state.userData[this.type][this.level].questions]
              this.dialog = false
            } else {

            }
          })
        }
      })

    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    nextStep (k, n) {
      window.scrollTo(0,0);
      if (n === this.steps) {
        this.finishExam(k, this.questions[n-1].studentAnswer)
      } else {
        this.markAnswer(k, this.questions[n-1].studentAnswer)
        this.e1 = n + 1
      }
    },
    previousStep (n) {
      window.scrollTo(0,0);
      if (n === 1) {
        this.e1 = 1
      } else {
        this.e1 = n - 1
      }
    },
    markAnswer(q, a){
      if (!a) a = 0
      console.log(q)
      let date = getIAIDLDate()
      db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/exam')
      .update({[q]:a})
      db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/meta')
      .update({[q]:date})
    },
    finishExam(q, a) {
      if (!a) a = 0
      this.dialog = true
      console.log(q)
      let date = getIAIDLDate()
      db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/exam')
      .update({[q]:a})
      .then(x=>{
        db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/meta')
        .update({[q]:date})
        .then(x=>{
          db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/meta')
          .update({done:date})
          .then(x=>{
            db.ref('candidates/' + this.user.uid + '/' + this.type +'/' + this.level + '/')
            .update({exit:true})
            .then(x=>{
              axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/result?key=${this.user.uid}&type=${this.type}&level=${this.level}`)
              .then(response => { 
                  console.log(response.data)
                  axios.get(`https://c.iaidl.org/yourls-api.php?signature=0a88314b95&action=shorturl&format=json&url=${response.data.url}&keyword=${response.data.token}`)
                  .then(resp=>{
                    db.ref('candidates/' + this.user.uid + '/certificate/' + this.level + '/')
                    .update({shorturl:resp.data.shorturl})
                    .then(x=>{
                      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=${this.user.uid}&type=result-candidate-email&etype=${this.type}&level=${this.level}`)
                      .then(response => { 
                          console.log(response.data)
                          window.removeEventListener('beforeunload', this.exitExam)
                          window.removeEventListener('blur', this.exitExam)
                          this.goTo(`/exam/${this.type}/${this.level}/result`)
                          closeFullscreen()
                          this.dialog = false
                      })
                    })
                  })
                })
              .catch(e => {
                this.$emit('snackbarNotification', `Generation Error`, 'error')
              })
            })
          })
        })
      })
    },
    exitExam() {
      this.finishExam()
    },
  },
  watch: {
    $route (to, from){
      
      
    },
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    },
    
  },
  computed: {
    prelock(){
      return  this.$store.state.userData ? this.$store.state.userData[this.type][this.level].started : null
    },
    preaccess(){
      return  this.$store.state.userData ? this.$store.state.userData[this.type][this.level].access : null
    },
    getEmail() {
      let v = this.$store.state.userData ? this.$store.state.userData : null
      this.user = v
      if(!v) return true
      
      return true 
    },
    getSteps() {
      this.steps = this.questions.length
      return true
    }
  },
  created() {

    // console.log(this.$store.state.userData.language)
    // console.log(this.$store.state.userData.language)
    this.language = this.$store.state.userData.language
    this.getQus()
    this.manualShort = this.$route.query.manual;
    this.getManuals()

    
    window.addEventListener('beforeunload', this.exitExam)
    window.addEventListener('blur', this.exitExam)
    window.addEventListener('blur', this.exitExam)
    
     this.$emit('startvid', true)
    



  },
  mounted(){
    this.$store.state.triggerStoree = true

  }, 
  destroyed(){
    this.triggere = false
    this.$store.state.triggerStoree = false

  }
  
}

</script>

<style>
.customStepperHeader {
  -webkit-box-shadow:unset !important;
  box-shadow:unset !important;
  display: flex !important;
  flex-wrap: nowrap !important;
}
.allRTL * {
  direction: rtl !important;
}
</style>
