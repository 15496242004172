<script>
import { Radar } from 'vue-chartjs'

export default {
  extends: Radar,
  props:{
    data:{
      default: []
    }
  },
  mounted () {
    this.renderChart(this.$props.data, {
        legend: {
            display: true,
            labels: {
                fontColor: "#000080",
            }
        },
        scale: {
            angleLines: {
                display: false
            },
            pointLabels: {
              fontSize:18,
            },
            ticks: {
                suggestedMin: 0,
                suggestedMax: 10,
                minor:{
                }
            },
            gridLines: {
              color:'#000'
            },
        },
        
    })
  }
}
</script>

<style>

</style>
