<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start >
        <v-flex xs12 md12 px-1>
          
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Guided Learning Materials</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <v-layout wrap row justify-start ma-3>
              <v-flex v-for="module in modules" :key="module.name" xs12 md4 pa-2>
                <v-btn :href="module.pdf" target="_blank" outline color="primary" dark>{{ module.name }}</v-btn>
              </v-flex>
            </v-layout>
            <v-divider></v-divider> -->
            <v-layout wrap row justify-start ma-3>
              <v-flex xs12 md12 pa-2>
                <v-card>
                  <v-card-title class="primary white--text">
                    <div class="layout row ma-0 justify-space-between pb-1">
                      <div class="subheading">IAIDL<sup>®</sup> Notice</div>
                      <div class="icon">
                        <v-icon color="white">warning</v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      value="true"
                      type="warning"
                    >
                      Printing & redistribution of these manuals is not allowed without IAIDL L.L.C Written Permission
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap row justify-start ma-3>
              <v-flex v-for="manual in manuals" :key="manual.name" xs12 md6 pa-2>
                <v-card>
                  <v-img
                    class="white--text"
                    height="100px"
                    :src="manual.cover"
                  >
                    <v-container fill-height fluid>
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <span class="headline">{{ manual.name }}</span>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-img>
                  <!-- <v-card-title>
                    <div class="layout row ma-0 justify-space-between pb-1">
                      <div class="subheading">{{ manual.name }}</div>
                      <div class="icon">
                        <v-icon color="primary">assignment</v-icon>
                      </div>
                    </div>
                  </v-card-title> -->
                  <v-card-text>
                    <div class="justify-center row layout ma-0">
                      <v-btn 
                        outline 
                        @click="goTo('/manual/' + level + '/' + manual.short)" 
                        color="primary" 
                        dark>
                        {{ manual.name }} Manual
                      </v-btn>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card> 
              </v-flex>
            </v-layout>
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
     
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
export default {
  props: ['isLogin'],
  
  data: ()=> ({
      manuals:null,
      level:null,
      modulesDone: (5*100/8)
  }),
  methods: {
    getManuals() {
      db.ref('manuals')
      .on('value', snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        this.manuals = fms.filter(x=> x.level == this.level)
      })
      return true
    },
    goTo(path) {
      this.$router.push(path)
    }
  },
  watch: {
    $route (to, from){
      this.level = this.$route.params.level
      this.getManuals()
    }
  },
  created() {
    this.level = this.$route.params.level
    this.getManuals()
    
  }
  
}

</script>