<template>
  <v-app id="login" class="primary">
    <v-content>
      <v-container>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text class="text-xs-center">
                <img src="../assets/logo.png" width="216" alt="">
                <div class="layout column align-center">
                  <h1 class="flex my-4 primary--text">IAIDL<sup>®</sup> Portal</h1>
                </div>                
                <v-form>
                  <v-text-field append-icon="person" name="email" label="Email" type="text" v-model="user.username"></v-text-field>
                  <v-text-field v-if="!forgetpass" append-icon="lock" name="password" label="Password" id="password" type="password" v-model="user.password"></v-text-field>
                </v-form>
                <span v-if="!forgetpass" class="error--text">{{ error }}</span>
                <span v-if="forgetpass" class="error--text">{{ resetStat }}</span>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span v-if="!forgetpass" @click="forgetpass  = true" style="cursor:pointer" class="mx-2 blue--text">Forget password?</span>
                <span v-if="forgetpass" @click="forgetpass  = false" style="cursor:pointer" class="mx-2 blue--text">Sign in</span>
                <v-btn v-if="!forgetpass" block color="primary" @click="login" :loading="loading">Login</v-btn>
                <v-btn v-if="forgetpass" block color="primary" @click="resetPass" :loading="loading">Send Reset Email</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import {firebase, db, auth} from '@/fb'

export default {
  data: () => ({
    loading: false,
    forgetpass:false,
    error:null,
    resetStat:null,
    user: {
      // ousername: 'info@in-ti.com',
      // opassword: '102030..',
      username: null,
      password: null
    }
  }),

  methods: {
    resetPass(){
      console.log(this.user.username)
      auth.sendPasswordResetEmail(this.user.username).then((data) => {
        this.resetStat = `Sent successfully to ${this.user.username}`
      }).catch((error) => {
        this.resetStat = error.message
      });
    },
    login () {
      this.loading = true;
      
      
      firebase.auth().signInWithEmailAndPassword(this.user.username, this.user.password).then(
          (resp) => {
            


            var d = new Date();
            var store = Date.parse(d)
            var offset = d.getTimezoneOffset();
            // console.log("==============DATA LOGIN==================")
            // console.log(resp)
            db.ref('users/' + resp.user.uid).set(
              {
                name:( resp.user.displayName ? resp.user.displayName : resp.user.email ),
                email:resp.user.email,
                img:resp.user.photoURL,
                country:'',
                lastSeen:store,
                offset:offset,
                uid:resp.user.uid
              }
            ).then(snap=>{
              this.$router.push('/')
            })
          },
          (err) => {
            this.error = err.message
            this.loading = false;
          }
        );
        
        
    },
    
    authlogin () {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      firebase.auth().signInWithPopup(provider).then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    }
    
  },
  created(){
    auth.onAuthStateChanged(user => {
      if (user) {
        this.$router.push('/')
      }
    })
  }

};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
