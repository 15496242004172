<template>
  <div class="dashboard" v-if="certificateAccess">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Certificate for {{ userName }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <img class="elevation-4 ma-1" width="99%" src="../assets/DEMO.jpg" alt=""> -->
            <div
              v-on:dragstart="getRight()" 
              @click.right="getRight()" 
              @click.left="getRight()"
            >
              <SVGcertificate
              v-if="level == 'advanced'"
              class="certContainer elevation-4 ma-1 pa-0"
              :name="userName.toUpperCase()"
              :code="certificateCode"
              :date="certificateDate"
              :online="onlineCertificate"
              />
              <SVGBasiccertificate
              v-if="level == 'basic'"
              class="certContainer elevation-4 ma-1 pa-0"
              :name="userName.toUpperCase()"
              :code="certificateCode"
              :date="certificateDate"
              :online="onlineCertificate"
              />
            </div>
            <v-divider></v-divider>
            <v-layout row wrap>
              <v-flex xs12 ma-0>
                <v-card class="elevation-8">
                  <v-layout row wrap>
                    <v-flex class="pa-3 text-xs-left" xs12 sm6 px-1 my-1>
                      <!-- <v-btn 
                        @click="downloadImg()"
                        color="primary" dark>
                        <v-icon left>image</v-icon>
                        Download Image
                      </v-btn> -->
                      <v-btn 
                        :loading="pdfLoading"
                        @click="downloadPDF()"
                        color="primary" dark>
                        <v-icon left>picture_as_pdf</v-icon>
                        Generate & Download PDF
                      </v-btn>
                      <!-- <v-btn 
                        :loading="printLoading"
                        @click="imgPrint()"
                        color="primary" dark>
                        <v-icon left>print</v-icon>
                        Print
                      </v-btn> -->
                      <h3 class="pa-3 text-xs-left">Uniqe Certificate Link:</h3>
                      <v-text-field
                        label="Unique Link"
                        solo
                        px-3
                        v-model="certificateShortLink"
                        readonly
                      ></v-text-field>
                      <v-btn @click="previewVerification()" color="primary" dark>Preview Verification Page</v-btn>
                      <v-btn 
                        v-clipboard="certificateShortLink"
                        :v-clipboard="certificateShortLink"
                        v-clipboard:success="clipboardSuccessHandler"
                        v-clipboard:error="clipboardErrorHandler"
                        color="primary" dark>
                        <v-icon left>file_copy</v-icon>
                        Copy Link
                      </v-btn>
                      <v-btn 
                        color="primary" 
                        dark
                        @click="downloadQR()"
                        >
                        <v-icon left>image</v-icon>
                        Download QR code as an image
                      </v-btn>
                      <v-btn 
                        color="error" 
                        dark
                        @click="goTo('/card/' + level)"
                        >
                        <v-icon left>crop_landscape</v-icon>
                        Get Membership Card
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 sm6 px-1 my-1>
                      <h3 class="pa-3">Uniqe Certificate QR:</h3>
                      <vue-qr 
                        bgSrc='/pp.png' 
                        :text="certificateShortLink"
                        :callback="getQR" 
                        :size="300"
                        dotScale="0.35"
                        autoColor="true"
                        whiteMargin="false"
                        margin="5"
                      ></vue-qr>
                    </v-flex>
                    <v-flex xs12 pa-3>
                      <v-card >
                        <v-card-title class="headline" primary-title>
                          Certificate verification page statistics
                        </v-card-title>
                        <v-layout wrap>
                          <v-flex xs12 md6 pa-2>
                            <mini-statistic
                              icon="web_asset"
                              :title="views"
                              sub-title="certificate views"
                              color="primary"  
                            >
                            </mini-statistic>
                          </v-flex>
                          <v-flex xs12 md6 pa-2>
                            <mini-statistic
                              icon="public"
                              :title="countries"
                              sub-title="countries"
                              color="secondary"      
                            >
                            </mini-statistic>
                          </v-flex>
                          <v-flex xs12>
                            
                          </v-flex>
                          <v-flex xs12>
                            <v-data-table
                              :headers="detailedViewsHeaders"
                              :items="detailedViews"
                              :loading="false"
                              class="elevation-1"
                            >
                              <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                              <template slot="items" slot-scope="props">
                                <td class="text-xs-left">{{ props.item.ip }}</td>
                                <td class="text-xs-left">{{ props.item.country }}</td>
                                <td class="text-xs-left">{{ props.item.city }}</td>
                                <td class="text-xs-left">{{ props.item.time | formatDate }}</td>
                              </template>
                            </v-data-table>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import printJS from 'print-js'
import VueQr from 'vue-qr'
import SVGcertificate from '../components/SVGcertificate';
import SVGBasiccertificate from '../components/SVGBasiccertificate';
import SVGcard from '../components/SVGcard';
import MiniStatistic from '@/components/MiniStatistic';
import canvg from 'canvg'
import axios from 'axios';
import download from 'downloadjs'

export default {
  components: {VueQr, SVGcertificate, SVGBasiccertificate, SVGcard, MiniStatistic},
  props: ['isLogin'],
  data: () => ({
    level:null,
    candidateName:'DR. JOHN DOE',
    date:'00/00/00',
    ttt:null,
    qrImage:null,
    pdfLoading:false,
    printLoading:false,
    code:null,
    views:null,
    countries:null,
    detailedViews:[],
    detailedViewsHeaders:[],
  }),
  computed:{
    userName() {
      let name = this.$store.state.userData ? this.$store.state.userData.name : ''
      let title = this.$store.state.userData ? this.$store.state.userData.title : ''
      return `${title} ${name}`
    }, 
    certificateAccess(){
      return this.$store.state.userData ? this.$store.state.userData.certificate ? this.$store.state.userData.certificate[this.$route.params.level].access : false : false
    },
    certificateCode () {
      let token = this.$store.state.userData ? this.$store.state.userData.exam ? this.$store.state.userData.exam[this.$route.params.level].token : false : false
      if (!token) return true
      this.code = token.code
      axios.get(`//c.iaidl.org/yourls-api.php?signature=0a88314b95&action=url-stats&format=json&shorturl=${token.code}`)
      .then(resp=>{
        console.log('------------------------------------');
        console.log(resp);
        console.log('------------------------------------');
      })
      return token.code
    },
    onlineCertificate(){
      return this.$store.state.userData ? this.$store.state.userData.exam_type : false
    },
    certificateShortLink() {
      let url = this.$store.state.userData ? this.$store.state.userData.certificate ? this.$store.state.userData.certificate[this.$route.params.level].shorturl : false : false
      if (!url) return true
      return url
    },
    certificateDate() {
      let d = this.$store.state.userData ? this.$store.state.userData.exam ? this.$store.state.userData.exam[this.$route.params.level].meta.done : '' : ''
      if (!d) return ''
      let m = new Date(d).toLocaleString('en-US').split(',')[0]
      this.date = m
      return m
    }
  },
  watch: {

  },
  methods: {
    getCertificateViews(){
      console.log(this.code)
      if (!this.code) return 0
      db.ref('certificates/' + this.code + '/views')
      .once('value')
      .then(snap=>{
        let views = snap.val()
        let v = []
        let countries = []
        let headers = [{text:'IP', value:'ip'}, {text:'Country', value:'country'}, {text:'City', value:'city'}, {text:'Time', value:'time'}]
        for (const key in views) {
          if (views.hasOwnProperty(key)) {
            const view = views[key];
            v.push(view)
            if (countries.indexOf(view.country) == -1) {
              countries.push(view.country)
            }
          }
        }
        this.detailedViews = v
        this.detailedViewsHeaders = headers
        this.views = v.length
        this.countries = countries.length
      })
    },
    previewVerification() {
      this.goTo('/verification/' + this.code + '?preview=true')
    },
    goTo(path) {
      this.$router.push(path)
    },
    downloadQR () {
      download(this.qrImage, "certificate_QR.png", "image/png");
    },
    getQR(dataUrl,id) {
      this.qrImage = dataUrl
    },
    downloadImg() {
      // let svg = document.getElementById('svg-container').innerHTML
      // svg = svg.replace('svg:', '')
      // svg = svg.replace('svg:', '')
      // let canvas = document.createElement('canvas')
      // //let canvas = document.getElementById('canvas')
      // let context = canvas.getContext('2d');
      // var pixelRatio = 5;
      // context.setTransform(pixelRatio,0,0,pixelRatio,0,0);
      // context.drawSvg(svg, 0, 0, 1800, 1800 * 1.41451613)
      // canvg(canvas, svg)
      // var imgData = canvas.toDataURL('image/png');
      // let a = document.createElement('a'); 
      // a.download = this.userName + ' Certificate.jpg'; 
      // a.href = imgData; 
      // a.setAttribute('target', '_blank');
      // document.body.appendChild(a); 
      // a.click(); 
    },
    downloadPDF(url) {
      this.getPdf()
    },
    imgPrint(){
      // this.printLoading = true
      // let svg = document.getElementById('svg-container')
      // let svginn = svg.innerHTML
      // let newSVG = document.createElement('svg')
      // newSVG.setAttribute('id', 'psvg-container')
      // newSVG.innerHTML = svginn
      // newSVG.innerHTML = newSVG.innerHTML.replace('svg:', '')
      // newSVG.innerHTML = newSVG.innerHTML.replace('svg:', '')
      // newSVG.innerHTML = newSVG.innerHTML.replace('style="width:100%"', 'width="6500"')
      // document.body.append(newSVG)
      // printJS({
      //   printable:'psvg-container', 
      //   type:'html', showModal:true,
      //   maxWidth: 'auto',
      //   style: '@page { size: Letter landscape; }', 
      //   onPrintDialogClose:()=>{
      //     newSVG.outerHTML = ''
      //   }
      // })
      // this.printLoading = false

      // this.$emit('snackbarNotification', `Printing ${this.userName} Certificate...`, 'success')
      // axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/api/pdf?name=${this.userName.toUpperCase()}&code=${this.certificateCode}&date=${this.date}`)
      // .then(response => { 
      //   this.printLoading = false
      //   console.log('------------------------------------');
      //   console.log(response.data);
      //   console.log('------------------------------------');
        
      //   })
      // .catch(e => {
      //   this.$emit('snackbarNotification', `Generation Error`, 'error')
      // })
    },
    clipboardSuccessHandler ({ value, event }){
      console.log('success', value)
      this.$emit('snackbarNotification', `${this.certificateShortLink} copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      console.log('error', value)
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    getPdf() {
      this.pdfLoading = true
      this.$emit('snackbarNotification', `Generating ${this.userName} Certificate...`, 'success')
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/api/pdf?name=${this.userName.toUpperCase()}&code=${this.certificateCode}&date=${this.date}&level=${this.level}&online=${this.$store.state.userData.exam_type}`)
      .then(response => { 
        window.open(response.data, 'Certificate Downloader')
        this.pdfLoading = false
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
  },
  created(){
    this.level = this.$route.params.level
    // alert(this.certificateAccess)
  },
  mounted(){
    this.getCertificateViews()
    
  }
}

</script>

<style>
.certContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
