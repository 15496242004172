<template>
  
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  data() {
    return {
    }
  },
  methods: {
    
  },
  watch: {
    
  },
  computed: {
    
    
  },
  
  created() {
    console.log('xx')
    
    
  }
}
</script>
<style>
.ticketField * {
  color:white !important;
}
</style>
