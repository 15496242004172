<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Register AIMA User</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
            <v-flex xs12 sm12 px-1 my-1>
              <v-text-field
                label="Token"
                readonly
                v-model="token"
                solo-inverted
                suffix="Token"
                :loading="tokenLoading"
                disabled
                light
                class="tokenField"
                color="white"
                background-color="primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Email"
                v-model="userEmail"
                solo
                clearable
                required
                hint="Please enter a valid email"
                prepend-inner-icon="email"
                :error-messages="emailErrors"
                @blur="$v.userEmail.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            
            
            <v-flex xs12 sm6 px-1 my-1>
              <v-autocomplete
                :items="aimaAppsAvailable"
                item-text="name"
                v-model="selectedAimaReport"
                label="AIMA Token"
                solo
                return-object
                chips
                deletable-chips
                prepend-inner-icon="people"
                :disabled="noToken || aimaAppsAvailable.length == 0"
              >
              </v-autocomplete>
            </v-flex>
            
            
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <span v-if="dberror" class="red--text">{{ dberror }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="submit()"  large>
                  Add User
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">No available Tokens, Order?</v-card-title>
            <v-card-text>{{ userName }} have used all of purchased tokens, please make a purchase to continue with registering students, do you want to place an order now?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="dialog = false">Later</v-btn>
              <v-btn color="green darken-1" flat @click="()=>{dialog = false; goTo('/order-tokens')}">Place an Order</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-card id="creds" v-if="dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://pngimage.net/wp-content/uploads/2018/06/material-blue-png.png"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">User Login Info</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-card-title primary-title>
          <div>
            <div>Email: {{ userEmail }}</div>
            <div>Password: {{ password }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="printBtn" flat @click="print()" color="primary">Print</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase } from '@/fb';
import { Printd } from 'printd'
import axios from 'axios'

export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      titles:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      userEmail:null,
      studentPhoto:null,
      dberror:null,
      dbsuccess:null,
      password:null,
      printBtn:true,
      accessMaterial:true,
      accessPre:true,
      accessExam:true,
      tokenKey:null,
      tokenCode:null,
      aimaAppsAvailable:[],
      selectedAimaReport:[],
      tokenLoading:true

    }
  },
  methods: {
    
    async getAIMAS(Centerid){
        console.log("AIMA STARTS")
      this.loading = true
      let aimaTokens = await db.ref(`centers/${Centerid}/aima`).once('value').then(x=>x.val())
      let st = [];
      if(aimaTokens) {
        (async ()=>{
          let aimaApps  = []
          for (let i = 0; i < Object.keys(aimaTokens).length; i++) {
            const token = Object.keys(aimaTokens)[i];
            let b = await db.ref(`aima/${token}`).once('value').then(x=>x.val())
            aimaApps.push(b)
            console.log(token)
            let c = []
            c.text = b.company
            c.name = b.company
            c.uid = b.token
            st.push(c)
          }
          this.aimaApps = aimaApps
          this.aimaAppsAvailable = st
        })()
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
    print() {
      let printd = new Printd()
      const iframe = printd.getIFrame()
      iframe.addEventListener('load', () => console.log('iframe loaded!'))
      const { contentWindow } = iframe
      printd.print(document.getElementById('creds'))
    },
    submit() {
      console.log('submit!')
      
      this.$v.$touch()
      if (this.noToken) {
        return
      }
      
      if(!this.selectedAimaReport ){
        this.$emit('snackbarNotification', `AIMA application should be selected`, 'error')
        return
      }
      if(!this.$store.state.centerData.uid ){
        this.$emit('snackbarNotification', `Please logout and login again to continue`, 'error')
        return
      }
      if (this.$v.$invalid) {
        console.log('errors there')
      } else {
        this.isLoading = true 
        this.$emit('progressBar', true)
        this.password = randomString(8, 'aA#');
        this.studentPhoto = 'https://astack-8276c.firebaseapp.com/pp.png'
        
        createFirebase.auth()
        .createUserWithEmailAndPassword(this.userEmail, this.password)
        .then(data => {
          let candidateUID = data.user.uid
          data.user.updateProfile({
            photoURL: this.studentPhoto,
          })
          .then(() => {
            let student = {
              center:this.$store.state.centerData.uid,
              registerDate:this.date,
              uid:candidateUID,
              email:this.userEmail,
              password:this.password,
              photo:this.studentPhoto,
              aima_report_token:this.selectedAimaReport.uid,
              company:this.selectedAimaReport.name,
              role:'aimaview'
            }
            db.ref('centers/' + this.$store.state.user.uid + '/users_aima')
            .push(candidateUID)

            db.ref('aima_users/' + candidateUID).update(student)

            this.dbsuccess= true
            this.isLoading = false
            this.$emit('progressBar', false)



          })
          .catch((error) => {
            // An error happened.
          });
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          this.$emit('snackbarNotification', `${errorCode} ${errorMessage}`, 'error')
          this.$emit('alertNotification', `${errorCode} ${errorMessage}`, 'error')
        });
      }
    },
  },
  watch: {
    
  },
  computed: {
    token() {
      this.tokenLoading = false
      this.noToken = false
      return "Add user to view Aima Report"
    },
    emailErrors () {
      const errors = []
      if (!this.$v.userEmail.$dirty) return errors
      !this.$v.userEmail.email && errors.push('Email is incorrect')
      !this.$v.userEmail.required && errors.push('Email is required')
      return errors
    },
    
    
  },
  validations: {
    userEmail: {
      required,
      email
    },
  },
  created() {
    let centerUID = this.$store.state.centerData.uid
    console.log( centerUID )
    if( centerUID ){
      this.getAIMAS( centerUID )
    }
    

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
