<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-text-field
            label="Search By Name/Email"
            solo
            v-model="searchText"
            @input="filterApps()"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredApps.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12 v-for="(app, i) in visibleApps" :key="app.token" mb-1>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs3 v-for="key in Object.keys(app).filter(x=> {
                    if(x != 'pricing')
                      return x
                  })" :key="app.token+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.replace(/_/g, ' ').toUpperCase() }}</div>
                  <div class="subheadline">{{ app[key] }}</div>
                </v-flex>
                <v-flex xs3 v-for="key in Object.keys(app.pricing).filter(x=> {
                      return x
                  })" :key="app.token+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.replace(/_/g, ' ').toUpperCase() }}</div>
                  <div class="subheadline" v-for="kk in Object.keys(app.pricing[key])" :key="kk+'pp'+i">{{ kk }}: {{ app.pricing[key][kk]}}</div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions dense>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
          <v-layout row justify-center>
            <v-dialog v-model="dialogApproval" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">Conditional Approval Message</v-card-title>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-textarea
                        label="Conditional Approval Message"
                        v-model="msg"
                        solo
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" flat="flat" @click.native="dialogApproval = false">Cancel</v-btn>
                  <v-btn color="primary" flat="flat" @click.native="conditionalApproveLab(app.token)">Approve with message</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </v-flex>
      </v-layout>
      
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import { titleCase, randomString } from '@/customFunction'
import axios from 'axios';
export default {
  data() {
    return {
      searchText:'',
      loading:false,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      labApps:[],
      filteredApps:[],
      visibleApps:[],
      selectedCenter:null,
      dialogApproval:false,
      msg:null,
    }
  },
  props:{
    uid:{
      default:null
    }
  },
  methods:{
    goTo(path) {
      this.$router.push(path)
    },
		updateVisible() {
      this.sortByDate()
      this.visibleApps = this.filteredApps.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      this.loading = false
    },
    sortByDate(){
      this.filteredApps = this.filteredApps.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
    },
    getPagesNumber(){
      if (this.filteredApps.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredApps.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredApps.length / this.pageSize) + 1
      }
		},
		filterApps() {
      this.loading = true
			this.filteredApps = this.labApps
			.filter(v=>{return v
				// if(!this.searchText) return v
				// for (const key in v) {
				// 	if (v.hasOwnProperty(key)) {
				// 		const val = v[key];
				// 		if(val && typeof val == 'string' && val.toLowerCase().includes(this.searchText.toLowerCase())) return v
				// 	}
				// }
			})
      this.getPagesNumber()
      this.currentPage = 1
			this.updateVisible()
		},
		clipboardSuccessHandler ({ value, event }){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async getAIMAS(){
      this.loading = true
      let b = await db.ref(`tokenOrders`).once('value').then(x=>x.val())
      //console.log(b)
      if(b) {
        this.labApps = Object.values(b)
        this.filterApps()
        this.loading = false
      } else {
        this.loading = false
      }
    }
  },
  watch:{
    
  },
  created(){
    this.getAIMAS()
  }
}
</script>

<style>

</style>
