<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start>
        <v-flex xs12 md12 px-1>
          
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Questions Overview</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
            <v-layout wrap row justify-start ma-3>
              <v-flex xs6 md4 px-1 my-1>
                <v-select
                  :items="courses"
                  v-model="selectedCourse"
                  label="Select a course"
                  solo
                  return-object
                  clearable
                  @change="filterQuestions()"
                ></v-select>
              </v-flex>
              <v-flex xs6 md4 px-1 my-1>
                <v-select
                  :items="categories"
                  v-model="selectedCategory"
                  label="Select a category"
                  solo
                  return-object
                  clearable
                  @change="filterQuestions()"
                ></v-select>
              </v-flex>
              <v-flex xs6 md4 px-1 my-1>
                <v-select
                  :items="types"
                  v-model="selectedType"
                  label="Select a type"
                  solo
                  return-object
                  clearable
                  @change="filterQuestions()"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap py-3>
              <v-flex xs12 sm12 md12>
                  <div class="text-xs-center">
                    <v-pagination
                      v-model="currentPage"
                      :length="pagesNumber"
                      :total-visible="5"
                      @input="updateVisible"
                    ></v-pagination>
                  </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex  xs12>
                
                <v-expansion-panel>
                  <v-expansion-panel-content
                    v-for="question in visibleQuestion" :key="question.key"  
                  >
                    <template slot="header">
                      <div >
                        <v-chip>{{ question.key }}</v-chip>
                        
                          {{ question.text}}
                          <v-spacer></v-spacer>
                          <v-chip color="primary white--text">{{ question.type.toUpperCase() }}</v-chip>
                          <v-chip v-if="question.language" color="primary white--text">{{ question.language }}</v-chip>
                        </div>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-card class="elevation-4">
                          <v-layout row wrap pa-3>
                            <v-flex xs6 class="primary white--text pa-2 mb-3">
                              <span class="white--text">Question time: {{question.questionTime}}</span>
                            </v-flex>
                            <v-flex xs6 class="primary white--text pa-2 mb-3 text-xs-right">
                              <countdown
                              :left-time="question.questionTime*1000"
                              :ref="question.key + 'Timer'"
                              :auto-start="true"
                              > 
                                <span
                                  slot="process"
                                  slot-scope="{ timeObj }"
                                  >
                                  <v-progress-circular
                                    :rotate="360"
                                    :size="20"
                                    :width="5"
                                    :value="timeObj.ceil.s*100/question.questionTime"
                                    :color="((timeObj.ceil.s*100/question.questionTime)>30) ? 'white' : 'error'"
                                  ></v-progress-circular>
                                  Auto Next in  {{ timeObj.ceil.s }}s
                                  </span>
                                <span slot="finish">Finished</span>
                              </countdown>
                            </v-flex>
                            <v-flex xs12>
                              <h3>{{ question.text }}</h3>
                            </v-flex>
                            <v-flex xs12>
                              <v-radio-group v-model="question.correctAnswer">
                                <v-radio 
                                v-for="answer in question.answers" 
                                :key="question.key + '_' + answer.number" 
                                :name="answer.text"
                                :label="answer.text"
                                readonly
                                :value="answer.number" 
                                color="primary"
                                ></v-radio>
                              </v-radio-group>
                            </v-flex>
                          </v-layout>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="goTo('/edit-questions/' + question.key )" outline color="primary" dark>Edit Question</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-layout> 
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
     
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  props: ['isLogin'],
  
  data: ()=> ({
      loading:false,
      questions:[],
      courses:[],
      categories:[],
      types:[],
      selectedCourse:null,
      selectedCategory:null,
      selectedType:null,
      filteredQuestions:[],
      visibleQuestion:[],
      currentPage: 1,
      pageSize: 5,
      pagesNumber:0,
  }),
  computed:{
    
  },
  methods: {
    updateVisible() {
      this.visibleQuestion = this.filteredQuestions.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.questions.length == 0 && this.currentPage > 0) {
      }
      console.log(this.visibleQuestion)
    },
    getQuestions(){
      this.loading = true
      db.ref('questions')
      .once('value')
      .then(snap=>{
        let rQs = snap.val()
        for (const key in rQs) {
          if (rQs.hasOwnProperty(key)) {
            const question = rQs[key];
            question.key = key
            this.questions.push(question)
          }
        }
      })
      .then(x=>{
        this.visibleQuestion = this.questions
        this.filteredQuestions = this.questions
        this.getPagesNumber()
        this.updateVisible()
        this.sortQuestions()
      })
    },
    sortQuestions() {
      this.questions.forEach(question=>{
        if(this.courses.indexOf(question.course) == -1 ) this.courses.push(question.course)
        if(this.categories.indexOf(question.category) == -1 ) this.categories.push(question.category)
        if(this.types.indexOf(question.type) == -1 ) this.types.push(question.type)
        this.loading = false
      })
    },
    filterQuestions() {
      this.filteredQuestions = this.questions
      .filter(question=>{
        if(this.selectedCourse == question.course) return question
        if(!this.selectedCourse) return question
      })
      .filter(question=>{
        if(this.selectedCategory == question.category) return question
        if(!this.selectedCategory) return question
      })
      .filter(question=>{
        if(this.selectedType == question.type) return question
        if(!this.selectedType) return question
      })
      this.getPagesNumber()
      this.currentPage = 1
      this.updateVisible()
    },
    getPagesNumber(){
      if (this.filteredQuestions.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredQuestions.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredQuestions.length / this.pageSize) + 1
      }
    },
    startTimer(key){
      this.$refs[key].startCountdown(true)
    },
    goTo(path) {
      this.$router.push(path)
    }
  },
  watch: {
    $route (to, from){

    }
  },
  created() {
    this.getQuestions()
  }
  
}

</script>