<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card>
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Submit Project</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
            <v-flex xs12 sm12 px-1 my-1>
              <v-text-field
                label="Token"
                readonly
                v-model="token"
                solo-inverted
                suffix="Token"
                :loading="tokenLoading"
                disabled
                light
                class="tokenField"
                color="white"
                background-color="primary"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Full Name"
                v-model="studenName"
                solo
                clearable
                required
                prepend-inner-icon="person"
                :error-messages="nameErrors"
                @blur="$v.studenName.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 sm6 px-1 my-1>
              <v-autocomplete
                :items="gender"
                item-text="name"
                v-model="studentGender"
                label="Gender"
                solo
                prepend-inner-icon="wc"
                :error-messages="genderErrors"
                @blur="$v.studentGender.$touch()"
                :disabled="noToken"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Age"
                v-model="studentAge"
                solo
                clearable
                required
                prepend-inner-icon="person"
                :error-messages="ageErrors"
                @blur="$v.studentAge.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Degree"
                v-model="studentDegree"
                solo
                clearable
                required
                prepend-inner-icon="school"
                :error-messages="degreeErrors"
                @blur="$v.studentDegree.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex> -->
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Corporate"
                v-model="corporate"
                solo
                required
                prepend-inner-icon="account_balance"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Project Scope of Work"
                v-model="shortScope"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Project Type"
                v-model="projectType"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Required resources"
                v-model="resources"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Maturity assessment results"
                v-model="maturity"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Other collaborator entities and contact persons"
                v-model="collaborator"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Key success factors"
                v-model="factors"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Team"
                v-model="team"
                solo
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-textarea
                label="Project lead time"
                v-model="time"
                solo
              ></v-textarea>
            </v-flex>
            <!-- <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Position"
                v-model="studentPosition"
                solo
                clearable
                required
                prepend-inner-icon="work"
                :error-messages="positionErrors"
                @blur="$v.studentPosition.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex> -->
            <v-flex xs12 sm12 px-1 my-1>
              <v-textarea
                label="Project Detailed Scope (Up to 150 Words)"
                solo
                rows="10"
                :error-messages="longScopeErrors"
                @blur="$v.longScope.$touch()"
                v-model="longScope"
              ></v-textarea>
            </v-flex>
            <input
              type="file"
              style="display: none"
              ref="file"
              accept="csv/*"
              @change="onFilePicked"
            >
            <v-flex v-for="file in uploadedFiles" :key="file.name" xs12 sm12 px-1 my-1>
              <v-chip color="success" text-color="white" label>{{file.name}}</v-chip>
              <v-icon color="success">done</v-icon>
            </v-flex>
            <v-flex xs12 sm12 px-1 my-1>
              <v-progress-linear  height="15"  v-if="upload>0 && upload<100" color="green darken-3" v-model="upload"></v-progress-linear>
              <v-btn @click="pickFile()" color="primary" >upload documents</v-btn>
            </v-flex>
            <!-- <v-flex xs12 sm12 px-1 my-1>
              <span>Give Access to Basic level:</span>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-switch
                label="Material"
                color="primary"
                hide-details
                v-model="accessMaterial"
                :disabled="noToken"
              ></v-switch>
              <v-switch
                label="Pre-Assessment"
                color="primary"
                hide-details
                v-model="accessPre"
                :disabled="noToken"
              ></v-switch>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-switch
                label="Exam"
                color="primary"
                hide-details
                v-model="accessExam"
                :disabled="noToken"
              ></v-switch>
              
            </v-flex> -->
            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <span v-if="dberror" class="red--text">{{ dberror }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="submit()"  large>
                  Submit Project {{ studenName }}
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { Printd } from 'printd'

export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      gender:["Male", "Female"],
      titles:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      studentTitle:null,
      studenName:null,
      studentEmail:null,
      studentPhone:null,
      studentGender:null,
      studentAge:null,
      studentDegree:null,
      studentCorporate:null,
      studentPosition:null,
      studentCountry:null,
      studentPhoto:null,
      dberror:null,
      dbsuccess:null,
      password:null,
      printBtn:true,
      accessMaterial:true,
      accessPre:true,
      accessExam:true,
      tokenKey:null,
      tokenCode:null,
      tokenLoading:true,
      studentLanguage:null,
      longScope:null,
      uploadedFiles:[],
      upload:null,
      shortScope:null,
      projectType:null,
      resources:null,
      maturity:null,
      collaborator:null,
      factors:null,
      team:null,
      time:null,
      corporate:null,
    }
  },
  methods: {
    pickFile (f) {
      this.$refs.file.click ()
      this.fileUploading = f
    },
    loadFile(file){
      var file = file
      var metadata = {
        contentType: file.type
      };
      var uploadTask = storageRef.child('trainers/' + file.name + '_' + randomString(8, 'Aa#') + '.jpg')
      .put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.upload = progress
                }, (error) =>{
          console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log('File available at', downloadURL);
          this.uploadedFiles.push({url:downloadURL, name:file.name})
          this.Uploadloading = false
        });
      });
    },
    onFilePicked (e) {
      this.Uploadloading = true
			const files = e.target.files
			if(files[0] !== undefined) {
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          console.log('------------------------------------');
          console.log(this.file);
          this.loadFile(this.file)
				})
			} else {
				this.name = null
				this.fule = null
				this.uploadURL = null
			}
    },
    print() {
      let printd = new Printd()
      const iframe = printd.getIFrame()
      iframe.addEventListener('load', () => console.log('iframe loaded!'))
      const { contentWindow } = iframe
      printd.print(document.getElementById('creds'))
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.noToken) {
        return
      }
      if (this.$v.$invalid) {
        console.log('errors there')
      } else {
        this.isLoading = true 
        this.$emit('progressBar', true)
        let project = {
          date:this.date,
          name:titleCase(this.studenName),
          short_scope:this.shortScope,
          project_type:this.projectType,
          resources:this.resources,
          maturity_result:this.maturity,
          detailed_scope:this.longScope,
          collaborators:this.collaborator,
          factors:this.factors,
          team:this.team,
          time:this.time,
          corporate:this.corporate,
          code:this.tokenCode,
          documents:this.uploadedFiles,
        }
        db.ref('candidates/' + this.$store.state.user.uid + '/project')
        .update(project)
        this.$emit('snackbarNotification', `Project is submitted successfully`, 'success')
        this.$emit('alertNotification', `Project is submitted successfully`, 'success')
        // this.dbsuccess= true
        this.goTo('/')
        this.$emit('progressBar', false)
        this.isLoading = false
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
  },
  watch: {
    
  },
  computed: {
    token() {
      this.tokenLoading = true
      let vtoken = `IAIDL_${randomString(8, 'aA#')}`
      this.tokenCode = vtoken
      this.tokenLoading = false
      this.noToken = false
      return vtoken
    },
    nameErrors () {
      const errors = []
      if (!this.$v.studenName.$dirty) return errors
      !this.$v.studenName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.studenName.maxLength && errors.push('Name must be not longer than 20 characters')
      !this.$v.studenName.required && errors.push('Name is required')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.studentEmail.$dirty) return errors
      !this.$v.studentEmail.email && errors.push('Email is incorrect')
      !this.$v.studentEmail.required && errors.push('Email is required')
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.studentPhone.$dirty) return errors
      !this.$v.studentPhone.numeric && errors.push('Only numbers are accepted')
      !this.$v.studentPhone.required && errors.push('Phone is required')
      return errors
    },
    countryErrors () {
      const errors = []
      if (!this.$v.studentCountry.$dirty) return errors
      !this.$v.studentCountry.required && errors.push('Country is required')
      return errors
    },
    languageErrors(){
      const errors = []
      if (!this.$v.studentLanguage.$dirty) return errors
      !this.$v.studentLanguage.required && errors.push('Language is required')
      return errors
    },
    genderErrors () {
      const errors = []
      if (!this.$v.studentGender.$dirty) return errors
      !this.$v.studentGender.required && errors.push('Gender is required')
      return errors
    },
    titleErrors () {
      const errors = []
      if (!this.$v.studentTitle.$dirty) return errors
      !this.$v.studentTitle.required && errors.push('Title is required')
      return errors
    },
    longScopeErrors() {
      const errors = []
      if (!this.$v.longScope.$dirty) return errors
      !this.$v.longScope.required && errors.push('Scope Summary is required')
      return errors
    },
    ageErrors () {
      const errors = []
      if (!this.$v.studentAge.$dirty) return errors
      !this.$v.studentAge.numeric && errors.push('Only numbers are accepted')
      !this.$v.studentAge.required && errors.push('Age is required')
      return errors
    },
    degreeErrors () {
      const errors = []
      if (!this.$v.studentDegree.$dirty) return errors
      !this.$v.studentDegree.maxLength && errors.push('Degree must be not longer than 20 characters')
      !this.$v.studentDegree.required && errors.push('Degree is required')
      return errors
    },
    corporateErrors () {
      const errors = []
      if (!this.$v.studentCorporate.$dirty) return errors
      !this.$v.studentCorporate.maxLength && errors.push('Corporate must be not longer than 20 characters')
      !this.$v.studentCorporate.required && errors.push('Corporate is required')
      return errors
    },
    positionErrors () {
      const errors = []
      if (!this.$v.studentPosition.$dirty) return errors
      !this.$v.studentPosition.maxLength && errors.push('Position must be not longer than 20 characters')
      !this.$v.studentPosition.required && errors.push('Position is required')
      return errors
    },
    
    
  },
  validations: {
    studenName: {
      required,
      minLength: minLength(4),
      maxLength:maxLength(20)
    },
    // studentGender: {
    //   required
    // },
    // studentTitle: {
    //   required
    // },
    userName: {
      required
    },
    longScope: {
      required
    },
    // studentLanguage: {
    //   required
    // },
    // studentAge: {
    //   required,
    //   numeric
    // },
    // studentDegree: {
    //   required,
    //   maxLength:maxLength(20)
    // },
    // studentCorporate: {
    //   required,
    //   maxLength:maxLength(20)
    // },
    // studentPosition: {
    //   required,
    //   maxLength:maxLength(20)
    // },
  },
  created() {
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    })

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
