<template>
  <v-layout row wrap>
    <v-flex xs12 pa-1>
      <v-card color="primary">
        <v-card-title>
          <div class="layout row justify-space-between py-3">
            <div class="headline white--text">Center Code: <span class="font-weight-bold">{{centerCode}}</span></div>
            <div class="icon">
              <v-icon color="white">verified_user</v-icon>
            </div>
          </div>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-flex xs12 pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">Tokens</div>
            <div class="icon">
              <v-icon color="secondary">brightness_1</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                    :size="150"
                    :width="15"
                    :rotate="-90"
                    :value="getBasicTokens"
                    color="primary"
                >
                  <div
                      class="headline text-md-center"
                      text-color="">
                    {{ getBasicTokens }}</div>
                  <div
                      class="headline text-md-center"
                      text-color="">
                  </div>
                </v-progress-circular>

              </div>
              <div class="justify-center row layout mt-2">Basic Tokens Left</div>
            </v-flex>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                    :size="150"
                    :width="15"
                    :rotate="-90"
                    :value="getAimaAssTokens"
                    color="primary"
                >
                  <div
                      class="headline text-md-center"
                      text-color="">
                    {{ getAimaAssTokens }}</div>
                  <div
                      class="headline text-md-center"
                      text-color="">
                  </div>
                </v-progress-circular>

              </div>
              <div class="justify-center row layout mt-2">AIMA Assessor Tokens Left</div>
            </v-flex>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                  :size="150"
                  :width="15"
                  :rotate="-90"
                  :value="getAdvancedTokens"
                  color="primary"
                >
                  <div 
                    class="headline text-md-center" 
                    text-color="">
                    {{ getAdvancedTokens }}</div>
                  <div 
                    class="headline text-md-center" 
                    text-color="">
                  </div>
                </v-progress-circular>
              </div>
              <div class="justify-center row layout mt-2">Advanced Tokens Left</div>
            </v-flex>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                    :size="150"
                    :width="15"
                    :rotate="-90"
                    :value="getAIMATokens"
                    color="primary"
                >
                  <div
                      class="headline text-md-center"
                      text-color="">
                    {{ getAIMATokens }}</div>
                  <div
                      class="headline text-md-center"
                      text-color="">
                  </div>
                </v-progress-circular>
              </div>
              <div class="justify-center row layout mt-2">AIMA Tokens Left</div>
            </v-flex>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                    :size="150"
                    :width="15"
                    :rotate="-90"
                    :value="getProductsTokens"
                    color="primary"
                >
                  <div
                      class="headline text-md-center"
                      text-color="">
                    {{ getProductsTokens }}</div>
                  <div
                      class="headline text-md-center"
                      text-color="">
                  </div>
                </v-progress-circular>
              </div>
              <div class="justify-center row layout mt-2">Product Endorsment Tokens Left</div>
            </v-flex>
            <v-flex xs2>
              <div class="justify-center row layout ma-0">
                <v-progress-circular
                    :size="150"
                    :width="15"
                    :rotate="-90"
                    :value="getLabTokens"
                    color="primary"
                >
                  <div
                      class="headline text-md-center"
                      text-color="">
                    {{ getLabTokens }}</div>
                  <div
                      class="headline text-md-center"
                      text-color="">
                  </div>
                </v-progress-circular>
              </div>
              <div class="justify-center row layout mt-2">Lab Endorsment Tokens Left</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="goTo('/order-tokens')" color="primary">order token</v-btn>
        </v-card-actions>
      </v-card>     
    </v-flex>
  </v-layout>
</template>

<script>
import {firebase, db, auth} from '@/fb';

export default {
  data: () => ({
    lCands:[]
  }),
  computed: {
    getAimaAssTokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'aimaassessor')
      return bt.length
    },
    getBasicTokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'basic')
      return bt.length
    },
    getAdvancedTokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'advanced')
      return bt.length
    },
    getAIMATokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'aima')
      return bt.length
    },
    getProductsTokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'product')
      return bt.length
    },
    getLabTokens() {
      if(!this.$store.state.tokens) return 0
      let tokens = []
      let lTokens = this.$store.state.tokens
      for (const key in lTokens) {
        if (lTokens.hasOwnProperty(key)) {
          const token = lTokens[key];
          if (!token.expired) {
            tokens.push(token)
          }
        }
      }
      let bt = tokens.filter(t => t.type == 'lab')
      return bt.length
    },
    centerCode() {
      if(!this.$store.state.centerData) return ''
      let code = this.$store.state.centerData ? this.$store.state.centerData.code ? this.$store.state.centerData.code : '' : ''
      return code
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  },
  create() {
    console.log('s')
  }
}
</script>