<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start>
        <v-flex xs12 md12>
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> News Article Submission</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Name"
                    v-model="uName"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Email"
                    v-model="uEmail"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                <v-text-field
                  label="Image URL OR Upload"
                  solo
                  v-model="newsImage"
                  prepend-icon="cloud_upload"
                  :loading="loading"
                  @click:prepend="pickFile()"
                ></v-text-field>
                <input
                  type="file"
                  style="display: none"
                  ref="file"
                  accept="csv/*"
                  @change="onFilePicked"
                >
              </v-flex>
                <v-flex xs12>
                  <v-textarea
                    label="Article"
                    v-model="msg"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="sendFeedback()" :loading="loading" color="primary" dark>
                submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  data(){
    return {
      name:null,
      email:null,
      msg:null,
      loading:null,
      newsImage:null,
      upload:null,
      fileUploading:null,
      file:null,
      imgURLExt:null,
    }
  },
  methods: {
    loadFile(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('images/image' + randomString(8, 'Aa#') + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.upload = progress
                }, (error) =>{
          console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log('File available at', downloadURL);
          this.newsImage = downloadURL
          this.loading = false
        });
      });
    },
    pickFile (f) {
      this.$refs.file.click ()
      this.fileUploading = f
    },
    onFilePicked (e) {
      this.loading = true
			const files = e.target.files
			if(files[0] !== undefined) {
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
				})
			} else {
			
			}
    },
    sendFeedback(){
      this.loading = true
      let fb = {
        name:this.$props.uName,
        email:this.$props.uEmail,
        img:this.newsImage,
        msg:this.msg
      }
      console.log(fb)
      db.ref('newsSubmission').push(fb)
      .then(x=>{
        this.loading = false
        this.$emit('snackbarNotification', `Sent`, 'success')
        this.goTo('/')
      })
    },
    goTo(path) {
      this.$router.push(path)
    },
  },
  props:{
    uName:{
      required:true
    },
    uEmail:{
      required:true
    },
  },
  computed:{
    
  },
  created(){
    
  }
}
</script>

<style>

</style>
