<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <!-- <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            label="Show Only"
            multiple
            solo
            chips
            @change="getSelectedStatus"
            hint="Select who are"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Show All But"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex> -->
        <!-- <v-flex xs12 md12 px-1>
        <v-text-field
          label="Search By Name/Email"
          solo
          v-model="searchText"
          @input="getSearchString()"
        ></v-text-field>
        </v-flex> -->
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>

      <v-card 
      v-for="(student,i) in students"
      :key="i"
      >
        <v-layout row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">ID</div>
            <div >{{ student.token }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Level</div>
            <div >{{ student.type }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Planned Date</div>
            <div >{{ student.date }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Planned Time</div>
            <div >{{ student.time }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Center</div>
            <div >{{ student.centerName }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">
              Candidates 
            </div>
            <div v-if="student.students"> 
              <div v-for="name in student.students.map(x=>x.name)">
                {{name}}
              </div>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Trainers</div>
            <div v-if="student.trainers">
              <div v-for="name in student.trainers.map(x=>x.name)">
                {{name}}
              </div>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Country</div>
            <div>{{ student.country }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Session Type</div>
            <div>{{ student.sessionType }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Videos</div>
            <div>{{ student.video }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Files</div>
            <div v-for="(file, i) in student.files" :key="i+'files'">
              <a :href="file.url" target="_blank">file {{i+1}} {{file.name}}</a>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Approval Status</div>
            <v-tooltip v-if="!student.proccessed" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Session Approved' : 'Under Proccess' }}</span>
            </v-tooltip>
            <v-tooltip v-if="student.proccessed" top>
              <span slot="activator">
                <v-avatar
                  size="10px"
                  :color="student.approved?'success' : 'error' "
                >
                </v-avatar>
              </span>
              <span>{{ student.approved ? 'Session Approved' : 'Session Not Approved' }}</span>
            </v-tooltip>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <v-btn small color="primary" dark @click.native="()=>{
                detailsDialog = true
                selectedSession = student
                getStudentsDetails()
              }">details</v-btn>
          </v-flex>
          <v-flex xs6 sm6 md3>
            <div class="caption grey--text ">Approve</div>
            <div>
              <v-btn @click="approveSession(student)" small color="success" flat dark class="ma-0">
                <v-icon left size="16px">done</v-icon>
                approve
              </v-btn>
            </div>
            <div>
              <v-btn @click="disapproveSession(student)" small color="error" flat dark class="ma-0">
                <v-icon left size="16px">close</v-icon>
                disapprove
              </v-btn>
            </div>
            <div>
              <v-btn @click="openExamForSession(student)" small color="success" flat dark class="ma-0">
                <v-icon left size="16px">warning</v-icon>
                open for all
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row justify-center>
        <v-dialog v-model="detailsDialog" fullscreen transition="dialog-bottom-transition" :overlay="false">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon @click.native="detailsDialog = false" dark>
                <v-icon>close</v-icon>
              </v-btn>
              <v-toolbar-title>Session Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark flat @click.native="getStudentsDetails()">refresh data</v-btn>
                <v-btn dark flat @click.native="detailsDialog = false">close</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-progress-linear v-if="sessionLoading" :indeterminate="true"></v-progress-linear>
            <v-layout v-if="selectedSession && selectedSession.students_details" row wrap pa-2>
              <v-flex xs12 v-for="st in selectedSession.students_details" :key="st.uid">
                <v-layout row wrap>
                  <v-flex xs12 class="elevation-3 pa-2 my-1">
                    <v-layout row wrap>
                      <v-flex xs2>
                        Name<br>
                        {{ st.name }}
                      </v-flex>
                      <v-flex xs2>
                        Email<br>
                        {{ st.email }}
                      </v-flex>
                      <v-flex xs2>
                        <span v-if="selectedSession.type == 'basic'">Basic </span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">AIMA Assessor </span>
                        Preassessment<br>
                        pass:
                        <span v-if="selectedSession.type == 'basic'">{{ st.preassessment.basic.pass }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.preassessment.aimaassessor.pass }}</span><br>

                        exit:
                        <span v-if="selectedSession.type == 'basic'">{{ st.preassessment.basic.exit }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.preassessment.aimaassessor.exit }}</span><br>

                        done:
                        <span v-if="selectedSession.type == 'basic'">{{ st.preassessment.basic.done }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.preassessment.aimaassessor.done }}</span><br>

                        score:
                        <span v-if="selectedSession.type == 'basic'">{{ parseFloat(st.preassessment.basic.score).toFixed(1) }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ parseFloat(st.preassessment.aimaassessor.score).toFixed(1) }}</span><br>


                        <v-btn outline small color="primary" @click="resetPre(st.uid, selectedSession.type)" dark>reset exam</v-btn>
                        <v-btn outline small color="success" @click="passPre(st.uid, selectedSession.type)" dark>pass exam</v-btn>
                      </v-flex>
                      <v-flex xs2>
                        <span v-if="selectedSession.type == 'basic'">Basic </span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">AIMA Assessor </span>
                        Exam<br>
                        pass:
                        <span v-if="selectedSession.type == 'basic'">{{ st.exam.basic.pass }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.exam.aimaassessor.pass }}</span><br>
                        exit:
                        <span v-if="selectedSession.type == 'basic'">{{ st.exam.basic.exit }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.exam.aimaassessor.exit }}</span><br>
                        done:
                        <span v-if="selectedSession.type == 'basic'">{{ st.exam.basic.done }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.exam.aimaassessor.done }}</span><br>
                        answered:
                        <span v-if="selectedSession.type == 'basic'">{{st.exam.basic.exam? Object.keys(st.exam.basic.exam).length:0}}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{st.exam.aimaassessor.exam? Object.keys(st.exam.aimaassessor.exam).length:0}}</span><br>

                        score:
                        <span v-if="selectedSession.type == 'basic'">{{ parseFloat(st.exam.basic.score).toFixed(1) }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ parseFloat(st.exam.aimaassessor.score).toFixed(1) }}</span><br>

                        <v-btn outline small color="primary" @click="resetExam(st.uid, selectedSession.type)" dark>reset exam</v-btn>
                        <v-btn outline small color="primary" @click="recorrectExam(st.uid, selectedSession.type)" dark>Recorrect exam</v-btn>


                        <span v-if="selectedSession.type == 'basic'">
                          <v-btn outline small color="primary" v-if="superadmin" @click="issueCertificate(st.exam.basic.token.code, st.uid, 'basic')" dark>Issue {{selectedSession.type}} Certificate</v-btn>
                        </span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">
                          <v-btn outline small color="primary" v-if="superadmin" @click="issueCertificate(st.exam.aimaassessor.token.code, st.uid, 'aimaassessor')" dark>Issue {{selectedSession.type}} Certificate</v-btn>
                        </span>




                        <v-btn outline small color="error" v-if="superadmin" @click="failExam(st.uid, selectedSession.type)" dark>fail exam</v-btn>
                        <v-btn outline small color="success" v-if="superadmin" @click="passExam(st.uid, selectedSession.type)" dark>pass exam</v-btn>
                        
                      </v-flex>
                      <v-flex xs2>
                        <span v-if="selectedSession.type == 'basic'">Basic </span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">AIMA Assessor </span>
                        Certificate<br>
                        <span v-if="selectedSession.type == 'basic'">{{ st.certificate.basic.shorturl }}</span>
                        <span v-else-if="selectedSession.type == 'aimaassessor'">{{ st.certificate.aimaassessor.shorturl }}</span>
                        <br>
                      </v-flex>
                      <v-flex xs12>
                        <v-btn  flat @click.native="getStudentsDetails()">refresh data</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import { closeFullscreen, getIAIDLDate } from '@/customFunction'
import axios from 'axios';

export default {
  props: {
    userName: {
      required: true,
      default: 'IAIDL'
    },
    userImage: {
      required: true,
      default: ''
    },
    userData: {
      required: true,
    },
    isLogin: {
      required: true, 
    },
    superadmin: {
      required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      displayText:['age', 'corporate', 'country', 'degree', 'email', 'gender', 'name', 'phone', 'position'],
      editText:['name', 'phone'],
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      selectedSession:null,
      Qdialog:false,
      question:null,
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 5,
      pagesNumber:0,
      notes:'',
      steps:[],
      detailsDialog:false,
      sessionLoading:null,
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    async recorrectExam(uid, levler){
      await axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/result?key=${uid}&type=exam&level=${levler}`)
      .then(response => { 
        axios.get(`https://c.iaidl.org/yourls-api.php?signature=0a88314b95&action=shorturl&format=json&url=${response.data.url}&keyword=${response.data.token}`)
        .then(resp=>{
          db.ref('candidates/' + uid + '/certificate/' + levler + '/')
          .update({shorturl:resp.data.shorturl})
          .then(x=>{
            axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=${uid}&type=result-candidate-email&etype=exam&level=${levler}`)
            .then(response => { 
              this.$emit('snackbarNotification', `Recorrected`, 'success')
            })
          })
        })
      })
    },
    async issueCertificate(token, uid, levelerA){
      let e = await axios.get(`https://c.iaidl.org/yourls-api.php?signature=0a88314b95&action=shorturl&format=json&url=https://portal.iaidl.org/verification/${token}&keyword=${token}`).then(x=>x.data)
      await db.ref('candidates/' + uid + '/certificate/' + levelerA + '/')
          .update({shorturl:e.shorturl})
      await db.ref('certificates/' + token).update({
        level: levelerA,
        access: true,
        token,
        userKey:uid,
        date:getIAIDLDate(),
        url: 'https://portal.iaidl.org/verification/' + token,
        shorturl: 'https://c.iaidl.org/' + token,
      })
      this.$emit('snackbarNotification', `Issued`, 'success')
    },
    async passExam(uid, levelerB) {
      let old = await db.ref(`candidates/${uid}/exam/${levelerB}`).once('value').then(x=>x.val())
      let token = old.token
      if(token){

          let thsPass = 60
          if(levelerB == 'aimaassessor'){
            thsPass = 71
          }
          let r = {
            access:true,
            done:true,
            exit:true,
            pass:true,
            score:thsPass,
            started:true,
            token,
            meta:{
              done:getIAIDLDate()
            }
          }
          await db.ref(`candidates/${uid}/exam/${levelerB}`).set(r)
          await db.ref(`candidates/${uid}/certificate/${levelerB}`).update({
            access:true,
            url: 'https://portal.iaidl.org/verification/' + token.code,
            shorturl: 'https://c.iaidl.org/' + token.code,
          })
          await db.ref('certificates/' + token.code).update({
            access:true,
            userKey:uid,
            token:token.code,
            date:getIAIDLDate(),
            url: 'https://portal.iaidl.org/verification/' + token.code,
            shorturl: 'https://c.iaidl.org/' + token.code,
          })
          this.$emit('snackbarNotification', `Passed`, 'success')
          // console.log(old)
          // console.log(r)



      }

    },
    async failExam(uid, levelerC) {
      let old = await db.ref(`candidates/${uid}/exam/${levelerC}`).once('value').then(x=>x.val())
      let token = old.token
      if(token){

            let r = {
              startTime:0,
              endTime:0,
              access:false,
              done:true,
              exit:true,
              pass:false,
              score:0,
              started:true,
              token
            }
            await db.ref(`candidates/${uid}/exam/${levelerC}`).set(r)
            await db.ref(`candidates/${uid}/certificate/${levelerC}`).update({
              access:false
            })
            await db.ref('certificates/' + token.code).update({
              access:false
            })
            this.$emit('snackbarNotification', `Failed`, 'success')
            // console.log(old)
            // console.log(r)


      }


    },
    async passPre(uid, levelerD) {
      let old = await db.ref(`candidates/${uid}/preassessment/${levelerD}`).once('value').then(x=>x.val())
      let r = { 
        access:true, 
        done:true,
        score:100, 
        pass:true,
        started:true, 
      }
      await db.ref(`candidates/${uid}/preassessment/${levelerD}`).set(r)
      this.$emit('snackbarNotification', `Reset Succuess`, 'success')
      console.log(old)
      console.log(r)
    },
    async resetPre(uid, levelerC) {
      //let old = await db.ref(`candidates/${uid}/preassessment/${levelerC}`).once('value').then(x=>x.val())
      let r = { 
        access:true, 
        done:false,
        score:0, 
        started:false, 
      }
      await db.ref(`candidates/${uid}/preassessment/${levelerC}`).set(r)
      this.$emit('snackbarNotification', `Reset Succuess`, 'success')
      // console.log(old)
      // console.log(r)
    },
    async resetExam(uid, levelerE) {
      // console.log(uid)
      // console.log(levelerE)
      let old
      let token

      old = await db.ref(`candidates/${uid}/exam/${levelerE}`).once('value').then(x=>x.val())
        token = old.token

      console.log(token)
      if(token){
        let r = {
          startTime:0,
          endTime:99999999999999999999999999999999999999999999999,
          access:true,
          score:0,
          done:false,
          started:false,
          token
        }
        await db.ref(`candidates/${uid}/exam/${levelerE}`).set(r)
        this.$emit('snackbarNotification', `Reset Succuess`, 'success')

      } else {
        console.log("token NA")
      }




    },
    async getStudentsDetails(){
      //console.log(this.selectedSession.students)
      let ss = [];
      await (async () => {
        for (let i = 0; i < this.selectedSession.students.length; i++) {
          this.sessionLoading = true

          const st = this.selectedSession.students[i];
          let s = await db.ref(`candidates/${st.uid}`).once('value').then(x => x.val())
          //console.log(s)
          this.sessionLoading = false
          ss.push(s)
        }
        this.selectedSession.students_details = ss
        console.log(ss)
      })()
    },
    openDoc(url){
      window.open(url)
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        return new Date(b.date) - new Date(a.date);
      })
    },
    async approveSession(session){
      let typer = session.type
      db.ref(`sessions/${session.uid}`).update({
        proccessed:true,
        approved:true
      })
      .then(x=>{
        let m = 0
        let sUID = session.students.map(x=>x.uid);
        (async function lopp(){
          for (let i = 0; i < sUID.length; i++) {
            const uid = sUID[i];
            console.log(uid)
            let centerUID = await db.ref(`candidates/${uid}/center`).once('value').then(snap => snap.val())
            //bring user token and update key at exam
            let tokenUID = await db.ref(`candidates/${uid}/tokens/exam/${typer}`).once('value').then(snap => snap.val())
            //console.log(tokenUID)
            if(tokenUID){
              //get token data
              let tokenDATA = await db.ref(`centers/${centerUID}/tokens/${tokenUID}`).once('value').then(snap => snap.val())
              console.log("TKNupdateSTART===")

              let start = new Date(session.date).getTime() + (session.time.split(':')[0] * 60 * 60 * 1000) + (session.offset * 60 * 1000) - (60 * 60 * 1000)
              let end = new Date(session.date).getTime() + (session.time.split(':')[0] * 60 * 60 * 1000) + (session.offset * 60 * 1000) + (240 * 60 * 1000)
              await db.ref(`candidates/${uid}/exam/${typer}`)
                  .update({
                    startTime: start,
                    endTime: end,
                    token:{
                      type:tokenDATA.type,
                      expired:false,
                      code:tokenDATA.code
                    }
                  })



            } else {
              console.log("Token Not")
            }


          }
          alert("Session Approved Succeed");
        })()
        //console.log(sUID)
      })
    },
    async openExamForSession(session){
      let typerA = session.type
      db.ref(`sessions/${session.uid}`).update({
        proccessed:true,
        approved:true
      })
      .then(x=>{
        let m = 0
        let sUID = session.students.map(x=>x.uid);
        (async function lopp(){
          for (let i = 0; i < sUID.length; i++) {
            const uid = sUID[i];
            console.log(uid)
            db.ref(`candidates/${uid}/exam/${typerA}`)
            .update({
              startTime:0,
              endTime:99999999999999999999999999999999999999999999999
            })
          }
        })()
        console.log(sUID)
      })
    },
    async disapproveSession(session){
      console.log(session.uid)
      db.ref(`sessions/${session.uid}`).update({
        proccessed:true,
        approved:false
      })
      .then(x=>{
        let m = 0
        let sUID = session.students.map(x=>x.uid)
        console.log(sUID)
      })
    },
    updateVisible() {
      this.students = this.allstudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents
      
    },
    getPagesNumber() {
      if (this.filteredStudents.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize) + 1
      }
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    async getStudents(students){
      return students
    },
    loadData() {
      let studentsArr = []
      auth.onAuthStateChanged(user => {
        if (user) {
          let centerEmail = user.uid
          db.ref('sessions')
          .once('value')
          .then(snap=>{
            let candidates = snap.val()
            for (const key in candidates) {
              if (candidates.hasOwnProperty(key)) {
                const uid = candidates[key];
                console.log(uid.token)
                this.loading = true

                db.ref('sessions/' + uid.token)
                .once('value')
                .then(subSnap=>{
                  this.loading = true
                  let candidate = subSnap.val()
                  //console.log(candidate)
                  candidate.uid = candidate.token
                  candidate.students_details = []
                  let index = this.allstudents.findIndex(x => x.uid === candidate.uid)
                  if(index != -1) this.allstudents.splice(index, 1, candidate);
                  if(index === -1) this.allstudents.push(candidate)
                  this.filteredStudents = this.allstudents
                  this.sortByDate()
                  this.updateVisible()
                  this.getPagesNumber()
                  this.loading = false
                })

              }
            }
          })
        } else {
          this.$router.push('/login')
        }
      })
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
  },
  
  created() {
    this.loading = true
    this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
