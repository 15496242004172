<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Transfer-in a candidate</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>

            <v-flex xs8 sm8>
              <v-text-field
                label="Candidate Code ( Found in Candidate Dashboard )"
                v-model="studentCode"
                solo
                clearable
                required
                :error-messages="codeErrors"
                @blur="$v.studentCode.$touch()"
                :disabled="noToken"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 sm4>

              <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="loadUserData()"  large>
                Load User Data
              </v-btn>

            </v-flex>





          <v-flex xs12 sm12>
            <v-text-field
                label="Full Name"
                v-model="studentName"
                solo
                clearable
                required
                prepend-inner-icon="person"
                :disabled="true"
            ></v-text-field>
          </v-flex>




            <v-flex xs12 sm12 my-1>
              <v-card-actions>
                <span v-if="dberror" class="red--text">{{ dberror }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="isLoading" :disabled="candidateNotLoaded" :dark="false" @click="submit()"  large>
                  Transfer Candidate
                </v-btn>
              </v-card-actions>
            </v-flex>
        </v-layout>
      </v-card>

      <v-card v-if="dbsuccess">
        <v-img
          class="white--text"
          height="100px"
          src="https://pngimage.net/wp-content/uploads/2018/06/material-blue-png.png"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Candidate Has Been Transferred</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import {  db } from '@/fb';


export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      candidateNotLoaded:true,
      isLoading:false,
      studentUID:null,
      studentName:null,
      studentCode:null,
      oldCenterUID:null,
      dberror:null,
      dbsuccess:null
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    async loadUserData() {
      console.log('Loading Data!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('errors there')
      } else {
        this.isLoading = true
        this.$emit('progressBar', true)
        let userCoder = this.studentCode
        //alert(userCoder)

        let newCandidateData = await db.ref('candidates')
            .orderByChild('user_code')
            .equalTo(userCoder)
            .once('value')
            .then(snap => snap.val())

        if(newCandidateData){

          let newCandidate
          for (const key in newCandidateData) {
            if (newCandidateData.hasOwnProperty(key)) {
              newCandidate = newCandidateData[key];
            } else {
              this.studentName  = "Student Not Found"
              this.isLoading = false
              this.candidateNotLoaded = true
              this.$emit('progressBar', false)
            }
          }
          if(newCandidate){
            this.studentUID   = newCandidate.uid
            this.studentName  = newCandidate.name
            this.oldCenterUID = newCandidate.center
            this.isLoading = false
            this.candidateNotLoaded = false
            this.$emit('progressBar', false)

          } else {
            this.studentName  = "Student Not Found"
            this.candidateNotLoaded = true
            this.isLoading = false
            this.candidateNotLoaded = true
            this.$emit('progressBar', false)
          }

        } else {
          this.studentName  = "Student Not Found"
          this.candidateNotLoaded = true
          this.isLoading = false
          this.candidateNotLoaded = true
          this.$emit('progressBar', false)
        }



      }
    },
    async submit() {
      this.isLoading = true
      this.$emit('progressBar', true)

      //get this center UID
      let thsUID = this.$store.state.centerData.uid
      let stdUID = this.studentUID
      let oldUID = this.oldCenterUID
      let dater = this.date
      //alert(thsUID)
      if (thsUID && stdUID) {
        if( thsUID == this.oldCenterUID ){
          this.$emit('alertNotification', `${this.studentName} Belongs to Same Center`, 'error')
          this.isLoading = false
          this.$emit('progressBar', false)
          this.candidateNotLoaded = true
        } else {

          //change user center
          await db.ref('candidates/' + stdUID ).update({ 'center': thsUID })
          this.isLoading = false
          this.$emit('progressBar', false)
          //add user to the current center
          db.ref('centers/' + thsUID + '/candidates').push(stdUID)
          db.ref('candidates_transfers').push(
              {
                'new_center': thsUID,
                'old_center': oldUID,
                'date': dater,
                'candidate': stdUID
              }
          )


          //delete user from old center
axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=1234&type=new-student-transfer&etype=new-student-transfer&level=new-student-transfer`)
                      .then(response => { 
          this.$emit('snackbarNotification', `${this.studentName} Transferred successfully`, 'success')
          this.$emit('alertNotification', `${this.studentName} Transferred successfully`, 'success')
          this.dbsuccess= true
                      })

          //update screen


        }




      } else {
        alert("FAILED")
      }


    },
  },
  watch: {
    
  },
  computed: {
    codeErrors () {
      const errors = []
      if (!this.$v.studentCode.$dirty) return errors
      !this.$v.studentCode.minLength && errors.push('Code must be at least 6 characters long')
      !this.$v.studentCode.required && errors.push('Code is required')
      return errors
    },
    
    
  },
  validations: {
    studentCode: {
      required,
      minLength: minLength(6),
    },
  },
  created() {
    

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
