<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-text-field
            label="Search By Name/Email"
            solo
            v-model="searchText"
            @input="filterApps()"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredApps.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12 v-for="app in visibleApps" :key="app.token" mb-1>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs3 v-for="key in Object.keys(app).filter(x=> {
                    let show = ['logo', 'title']
                    if(typeof app[x] != 'object'
                      && show.indexOf(x) == -1
                    ) {
                      return x
                    }
                  })" :key="app.key+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.replace(/_/g, ' ').toUpperCase() }}</div>
                  <div class="subheadline">{{ app[key] }}</div>
                </v-flex>
                <v-flex xs12>
                </v-flex>
                <v-flex xs3 v-if="app.organization" v-for="key in Object.keys(app.organization).filter(x=> {
                      return x
                  })" :key="app.key+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.replace(/_/g, ' ').toUpperCase() }}</div>
                  <div class="subheadline">{{ app.organization[key] }}</div>
                </v-flex>
                <v-flex xs6 sm3 md3>
                  <div class="caption grey--text ">Approval</div>
                  <v-tooltip v-if="!app.proccessed" top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        color="error"
                      >
                      </v-avatar>
                    </span>
                    <span>Under proccessing</span>
                  </v-tooltip>
                  <v-tooltip v-if="app.proccessed && !app.conditional && !app.msg" top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        :color="app.approved?'success' : 'error' "
                      >
                      </v-avatar>
                    </span>
                    <span>{{ app.approved ? 'Lab Approved' : 'Lab Rejected' }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="app.proccessed && app.conditional && !app.msg && !app.approved" top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        :color="app.conditional?'warning' : 'error' "
                      >
                      </v-avatar>
                      <span v-if="app.proccessed && app.conditional" style="font-size:9px">Lab Conditional Approved</span>
                    </span>
                    <span>{{ app.conditional ? 'Lab Conditional Approved' : 'Lab Not Approved' }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="app.proccessed && app.conditional && app.msg && !app.approved" top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        :color="app.conditional?'warning' : 'error' "
                      >
                      </v-avatar>
                      <span v-if="app.proccessed && app.conditional && app.msg && !app.approved" style="font-size:9px">{{ app.msg }}</span>
                    </span>
                    <span>{{ app.msg }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="app.proccessed && app.approved" top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        :color="app.approved?'success' : 'error' "
                      >
                      </v-avatar>
                    </span>
                    <span>{{ app.approved ? 'Lab Approved' : 'Lab Not Approved' }}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions dense>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark small @click="goTo(`/lab-accreditation/${app.token}`)">Lab application</v-btn>
              <v-btn color="primary" :disabled="!app.accredited" small @click="goTo(`/lab-certificate/${app.token}`)">Lab Accreditation Certificate</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import { titleCase, randomString } from '@/customFunction'
import axios from 'axios';
export default {
  data() {
    return {
      searchText:'',
      loading:false,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      labApps:[],
      filteredApps:[],
      visibleApps:[],
      selectedCenter:null,
      
    }
  },
  props:{
    uid:{
      default:null
    },
    userData: {

    }
  },
  methods:{
    goTo(path) {
      this.$router.push(path)
    },
		updateVisible() {
      this.sortByDate()
      this.visibleApps = this.filteredApps.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      this.loading = false
    },
    sortByDate(){
      this.filteredApps = this.filteredApps.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
    },
    getPagesNumber(){
      if (this.filteredApps.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredApps.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredApps.length / this.pageSize) + 1
      }
		},
		filterApps() {
      this.loading = true
			this.filteredApps = this.labApps
			.filter(v=>{return v
				
			})
      this.getPagesNumber()
      this.currentPage = 1
			this.updateVisible()
		},
		clipboardSuccessHandler ({ value, event }){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async getAIMAS(){
      this.loading = true
      let labTokens = await db.ref(`centers/${this.$props.uid}/lab`).once('value').then(x=>x.val())
      if(labTokens) {
        (async ()=>{
          let labApps  = []
          for (let i = 0; i < Object.keys(labTokens).length; i++) {
            const token = Object.keys(labTokens)[i];
            let b = await db.ref(`lab/${token}`).once('value').then(x=>x.val())
            labApps.push(b)
          }
          this.labApps = labApps
          this.filterApps()
          console.log(this.labApps)
        })()
      } else {
        this.loading = false
      }
    }
  },
  watch:{
    userData(){
      this.getAIMAS()
    }
  },
  created(){
    if(this.$props.uid) {
      this.getAIMAS()
    }
  }
}
</script>

<style>

</style>
