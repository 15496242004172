<template>

  <div class="dashboard">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex class="text-md-center" xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Tutorials</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-expansion-panel expand>
              <v-expansion-panel-content v-for="(video, i) in videos" :key="i">
                <div slot="header">{{ video.title }}</div>
                <v-card>
                  <v-card-text class="text-xs-center">
                    <video width="100%" controls>
                      <source :src="video.file" type="video/webm">
                    Your browser does not support the video tag.
                    </video>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';


export default {
  components: {},
  
  data: () => ({
    loading:true,
    videos : [],
  }),
  computed:{
    
  },
  watch: {

  },
  methods: {
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    loadData() {
      auth.onAuthStateChanged(user => {
        if (user) {

          db.ref( 'centers_tuts')
              .once('value')
              .then(snap=>{
                let candidates = snap.val()
                for (let key in candidates) {
                  // console.log(key)
                  this.loading = true
                  db.ref('centers_tuts/' + key)
                      .once('value').then(subSnap=>{
                    this.loading = true
                    let candidate = subSnap.val()
                    if(candidate) {
                      this.videos.push(candidate)
                      // let index = this.assets.findIndex(x => x.email === candidate.email)
                      // if(index === -1)this.assets.push(candidate)
                      // this.loading = false
                    }
                  })


                  // if (candidates.hasOwnProperty(key)) {
                  //   let candidateEmail = candidates[key];
                  //   console.log(candidateEmail)
                  // }



                }
              })
        } else {
          this.$router.push('/login')
        }
      })
    }
  },
  created(){
    this.loadData()
  },
  mounted(){
   
  }
}

</script>

<style>

</style>
