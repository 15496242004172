<template>
  <div class="dashboard"  v-if="getMaterialAccess">
    <v-container class="my-5">
      <v-layout wrap row justify-start>
        <v-flex xs12 md12>
          <v-card @load="getProgress" class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> {{loadManual ? loadManual.name : ''}}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-alert
              value="true"
              type="warning"
            >
              Guided Learning Material should be viewed and studied with Trainer
            </v-alert>
            <v-layout wrap row justify-start>
              <v-flex  xs12 md12>
                <v-stepper v-model="e1">
                  <v-stepper-header class="customStepperHeaderM">
                    <template v-for="n in steps">
                      <v-stepper-step style="padding: 0px;"
                        :key="`${n}-step`"
                        :complete="e1 > n"
                        :step="n"
                      >
                       
                      </v-stepper-step>
                    </template>
                  </v-stepper-header>
                  <v-stepper-items v-on:dragstart="getRight()" @click.right="getRight()" @click.left="getRight()">
                    <v-stepper-content
                      v-for="n in steps"
                      :key="`${n}-content`"
                      :step="n"
                      px-1
                    >
                      <v-btn
                        v-if="n!==1"
                        color="primary"
                        @click="previousStep(n)"
                      >
                        back
                      </v-btn>
                      <v-btn
                        v-if="n!==steps"
                        color="primary"
                        @click="nextStep(n)"
                      >
                        Next
                      </v-btn>
                      <v-btn
                        v-if="n==steps"
                        color="success"
                        @click="markDone (n)"
                      >
                        <v-icon left>done</v-icon>
                        Mark As Done
                      </v-btn>
                      <img width="100%" :src="loadManual ? loadManual.urls[n-1] : ''" alt="">
                      <v-btn
                        v-if="n!==1"
                        color="primary"
                        @click="previousStep(n)"
                      >
                        back
                      </v-btn>
                      <v-btn
                        v-if="n!==steps"
                        color="primary"
                        @click="nextStep(n)"
                      >
                        Next
                      </v-btn>
                      <v-btn
                        v-if="n==steps"
                        color="success"
                        @click="markDone (n)"
                      >
                        <v-icon left>done</v-icon>
                        Mark As Done
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  props: ['isLogin'],
  
  data: ()=> ({
      loadManual:null,
      user:null,
      manuals:null,
      manualShort:null,
      e1: 1,
      steps: 1,
      width:0,
      review:true,
  }),
  methods: {
    getManuals() {
      db.ref('manuals')
      .once('value') 
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        console.log(ms)
        if(this.$store.state.centerData) {
          this.manuals = fms.filter(x=> x.level == this.$route.params.level )
        } else {
          this.manuals = fms.filter(x=> x.level == this.$route.params.level && this.$store.state.userData && x.language == this.$store.state.userData.language )
        }
        this.filterManuals()
        this.getPages()
        this.getRole()
        this.markStart()
      })
    },
    getRole() {
      let role = this.user ? this.user.role : ''
      if(role != 'candidate') this.review = true
      else this.review = false
    },
    filterManuals () {
      this.loadManual = this.manuals.filter(manual => manual.short == this.manualShort)[0]
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    getPages() {
      this.steps = this.loadManual.urls.length
    },
    nextStep (n) {
      window.scrollTo(0,0);
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
      this.markProgress(1)
    },
    previousStep (n) {
      window.scrollTo(0,0);
      if (n === 1) {
        this.e1 = 1
      } else {
        this.e1 = n - 1
      }
      this.markProgress(-1)
    },
    markDone (n) {
      let role = this.user ? this.user.role : ''
      if(this.review || role != 'candidate') return
      this.markProgress(1)
      this.$router.push('/manuals')
    },
    markStart(){
      let role = this.user ? this.user.role : ''
      if(this.review || role != 'candidate') return
      let mattyper = 'basic'
      if(this.level == 'AIMA'){
        mattyper = 'aimaassessor'
      } else if(this.level == 'AIMA_Supportive'){
        mattyper = 'aimaassessor'
      }
      db.ref('candidates/' + this.user.uid + '/material/' + mattyper + '/' + this.manualShort)
      .update({
        started:true,
      })
    },
    markProgress(n){
      let role = this.user ? this.user.role : ''
      if(this.review || role != 'candidate') return
      let mattyper = 'basic'
      if(this.level == 'AIMA'){
        mattyper = 'aimaassessor'
      } else if(this.level == 'AIMA_Supportive'){
        mattyper = 'aimaassessor'
      }
      db.ref('candidates/' + this.user.uid + '/material/' + mattyper + '/' + this.manualShort + '/progress')
      .transaction(progress => {
        return progress + n
      })
    },
    handleScroll() {
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
      this.width = (window.scrollY / height) * 100
      const eventWidth = Math.ceil(this.width)
      if (eventWidth > 1) {
        this.$emit("begin")
        this.$emit('scrollBar', true, this.width)
      }
      if (eventWidth < 1) {
        this.$emit("begin")
        this.$emit('scrollBar', false, this.width)
      }
      if (eventWidth === 100) {
        this.$emit("complete")
      }
    }
  },
  computed:{
    
    getMaterialAccess(){
      this.user = this.$store.state.userData ? this.$store.state.userData : null
      let role = this.user ? this.user.role : ''
      if (role == 'candidate') {

        if(this.level == 'AIMA'){
          return this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : true
        } else if(this.level == 'AIMA_Supportive'){
          return this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : true
        } else {
          return this.$store.state.userData ? this.$store.state.userData.access.basic.material : false
        }


      } else {
        console.log('------------------------------------');
        console.log('a', this.manuals);
        console.log('------------------------------------');
        this.review = true
        return true
      }
    },
    getProgress() {
      
      if(!this.loadManual) return true
      let p = 0
      if(this.level == 'AIMA'){
        p = 1 + (this.$store.state.userData ? this.$store.state.userData.material.aimaassessor[this.manualShort].progress : 0)
      } else if(this.level == 'AIMA_Supportive'){
        p = 1 + (this.$store.state.userData ? this.$store.state.userData.material.aimaassessor[this.manualShort].progress : 0)
      } else if(this.level == 'basic'){
        p = 1 + (this.$store.state.userData ? this.$store.state.userData.material.basic[this.manualShort].progress : 0)
      }




      if (p == this.loadManual.urls.length + 1) {
        this.review = true
        this.e1 = 1
      } else {
        this.review = false
        console.log(parseInt(p))
        this.e1 = isNaN(p) ? 1 : p 
      }
      
      return true
    }
  },
  watch: {
    $route (to, from){
      this.manualShort = this.$route.params.id;
      this.getManuals()
    },
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  },
  created() {
    this.level = this.$route.params.level

  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    window.dispatchEvent(new Event("scroll"))
    this.manualShort = this.$route.params.id;
    this.getManuals()
  },
  destroyed(){
    this.$emit('scrollBar', false, this.width)
  }
}

</script>

<style>
.customStepperHeaderM {
  -ms-flex-wrap: inherit !important;
      flex-wrap: nowrap !important;
  display: flex;
  overflow: hidden !important;
  justify-content: start;
  align-items: center;
}
</style>