<template>

  <v-footer
      dark
      height="auto"
      style="flex-wrap: wrap;"
  >

      <div style="width: 100%;height: 202px;background-image: url('https://portal.iaidl.org/newbg.jpg');background-size: 100% 100%;"></div>

    <v-card
        class=" sm12 lg12" style="width: 100%;display: block;"
        flat
        tile
    >
      <v-card-title class="blue darken-1 justify-center">
        <strong class="subheading"></strong>
      </v-card-title>
      <v-card-actions class="blue darken-4 justify-center">
        &copy;{{ date }} — <strong>IAIDL<sup>®</sup></strong>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      date: new Date().toISOString().substr(0, 4),
    })
  }
</script>