<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <h1 class="subheading grey--text">{{ program.toUpperCase() }} REPORT</h1>
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md3 px-1>
          <v-menu
            :close-on-content-click="true"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              label="Start Date"
              prepend-inner-icon="event"
              readonly
              :value="fromDate"
              solo
              @change="updateCountryFilters"
              append-icon="close"
              @click:append="date"
            ></v-text-field>
            <v-date-picker @input="fromDate"  v-model="fromDate"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12 md3 px-1>
          <v-menu
            :close-on-content-click="true"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              label="End Date"
              prepend-inner-icon="event"
              readonly
              :value="toDate"
              solo
              append-icon="close"
              @click:append="date"
            ></v-text-field>
            <v-date-picker @input="toDate"  v-model="toDate"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12 md3 px-1>
          <v-select
            :items="countries"
            label="Students Countries"
            multiple
            solo
            chips
            @change="getSelectedCountries"
            deletable-chips
            clearable
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 px-1>
          <v-select
            :items="steps"
            label="Status"
            multiple
            solo
            chips
            @change="getSelectedStatus"
            hint="Select who are"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Not Status"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 px-1>
         <v-text-field
           label="Search By Name/Email"
           solo
           v-model="searchText"
           @input="getSearchString()"
         ></v-text-field>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      <v-expansion-panel popout focusable>
        <v-expansion-panel-content
          v-for="(student,i) in students"
          :key="i"
          expand-icon="folder_open"
          :class="(student.lead ? student.lead.done ? 'greenDone' : null : null)"
        >
          <div slot="header">
            <v-card flat>
              <v-layout row wrap :class="`pa-3 project ${(student.lead ? student.lead.done ? 'greenDone' : null : null)}`">
                <v-flex xs6 sm4 md4>
                  <div class="caption grey--text ">Student Name</div>
                  <div class="greenDoneText">{{ student.basic.name }}</div>
                </v-flex>
                <v-flex xs6 sm4 md4>
                  <div class="caption grey--text ">Email</div>
                  <div class="greenDoneText">
                    <!-- <span >{{ student.basic.email | truncate(10, '..') }}</span> -->
                    <span >{{ student.basic.email }}</span>
                  </div>
                </v-flex>
                <v-flex xs6 sm4 md2>
                  <div class="caption grey--text ">Phone</div>
                  <div class="greenDoneText">{{ student.basic.phone }}</div>
                </v-flex>
                <v-flex xs4 sm4 md2>
                  <div class="right">
                    <v-chip small :class="`indigo white--text my-2 caption`">{{ student.basic.program }}</v-chip>
                  </div>
                </v-flex>
                <v-flex xs6 sm4 md2>
                  <div class="caption grey--text ">Date & Time</div>
                  <div class="greenDoneText">{{ student.basic.datetime | formatDate }}</div>
                </v-flex>
                <v-flex xs12 sm12 md12>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
          <v-card>
            <v-layout row wrap pa-3>
              <v-flex xs6 md12>
                <v-card
                    class="mx-auto"
                  >
                    <v-card-title
                      class="blue-grey white--text"
                    >
                      <span class="title">Logs</span>
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <v-timeline dense>
                        <v-slide-x-reverse-transition
                          group
                          hide-on-leave
                        >
                          <v-timeline-item
                            v-for="step in steps"
                            :key="step.id"
                            :color="step.color"
                            small
                            fill-dot
                          >
                          <v-layout row wrap>
                          <v-flex xs12 sm6 md6>
                            <v-btn 
                            flat 
                            v-bind:color="stepsDone(student, step.name)"
                            @click="markSteps(student.basic.email, step.name, (student.lead ? student.lead[step.name] ? true : false : false))"
                            >
                              <v-icon left>{{step.icon}}</v-icon>{{step.text}}
                            </v-btn>
                            <v-dialog v-if="step.details" v-model="dialog.id[step.name+i]" persistent 
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                            max-width="300">
                              <v-btn slot="activator" flat >
                                <v-icon left>launch</v-icon>
                                Details
                              </v-btn>
                              <v-card >
                                <v-toolbar card dark color="primary">
                                  <v-btn icon dark @click="dialog.id[step.name+i] = false">
                                    <v-icon>close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title>{{step.text}} Details</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-toolbar-items>
                                    <!-- <v-btn dark flat @click="dialog.id[step.name+i] = false">Save</v-btn> -->
                                  </v-toolbar-items>
                                  <!-- <v-menu bottom right offset-y>
                                    <v-btn slot="activator" dark icon>
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                    <v-list>
                                      <v-list-tile v-for="(item, i) in countries" :key="i" >
                                        <v-list-tile-title>{{ item }}</v-list-tile-title>
                                      </v-list-tile>
                                    </v-list>
                                  </v-menu> -->
                                </v-toolbar>
                                <v-card-text v-if="step.name === 'interviewed'">Was the candidate accepted during his interview on {{ student.date ? student.date[step.name] ? student.date[step.name] : null : null }}?</v-card-text>
                                <v-card-actions v-if="step.name === 'interviewed'">
                                  <v-spacer></v-spacer>
                                  <v-btn 
                                    flat 
                                    v-bind:color="stepsDone(student, step.details[0].name)"
                                    @click="()=>{
                                      markSteps(student.basic.email, step.name, false);
                                      markSteps(student.basic.email, step.details[0].name, (student.lead ? student.lead[step.details[0].name] ? true : false : false));
                                      dialog.id[step.name+i] = false;
                                      }"
                                    >
                                      <v-icon left>{{step.details[0].icon}}</v-icon>{{step.details[0].text}}
                                  </v-btn>
                                  
                                </v-card-actions>
                                <v-card-text v-if="step.name === 'germanLanguage'">
                                  <v-layout v-for="substep in step.details" :key="substep.id" row wrap>
                                    <v-flex xs12 md4 px-1>
                                      <v-menu
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                      >
                                        <v-text-field
                                          slot="activator"
                                          :label="substep.text + ' Start Date'"
                                          prepend-inner-icon="event"
                                          readonly
                                          :value="student.date ? student.date[substep.text + 'start'] ? student.date[substep.text+ 'start'] : null : null"
                                          solo
                                          append-icon="close"
                                          @click:append="markDates(student.basic.email, substep.text+ 'start', null)"
                                        ></v-text-field>
                                        <v-date-picker @input="markDates(student.basic.email, substep.text + 'start')"  v-model="date"></v-date-picker>
                                      </v-menu>
                                    </v-flex>
                                    <v-flex xs12 md4 px-1>
                                      <v-menu
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                      >
                                        <v-text-field
                                          slot="activator"
                                          :label="substep.text + ' End Date'"
                                          prepend-inner-icon="event"
                                          readonly
                                          :value="student.date ? student.date[substep.text + 'end'] ? student.date[substep.text+ 'end'] : null : null"
                                          solo
                                          append-icon="close"
                                          @click:append="markDates(student.basic.email, substep.text+ 'end', null)"
                                        ></v-text-field>
                                        <v-date-picker @input="markDates(student.basic.email, substep.text + 'end')"  v-model="date"></v-date-picker>
                                      </v-menu>
                                    </v-flex>
                                  </v-layout>
                                </v-card-text>
                                <v-card-actions v-if="step.name === 'germanLanguage'">
                                  <v-spacer></v-spacer>
                                  <v-btn 
                                    v-for="subSteps in step.details"
                                    :key="subSteps[id]"
                                    flat 
                                    v-bind:color="stepsDone(student, subSteps.name)"
                                    @click="()=>{
                                      markSteps(student.basic.email, step.name, false);
                                      markSteps(student.basic.email, subSteps.name, (student.lead ? student.lead[subSteps.name] ? true : false : false));
                                      dialog.id[step.name+i] = false;
                                      }"
                                    >
                                      <v-icon left>{{subSteps.icon}}</v-icon>{{subSteps.text}}
                                  </v-btn>
                                  
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-menu
                              :close-on-content-click="true"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                label="Date"
                                prepend-inner-icon="event"
                                readonly
                                :value="student.date ? student.date[step.name] ? student.date[step.name] : null : null"
                                solo
                                append-icon="close"
                                @click:append="markDates(student.basic.email, step.name, null)"
                              ></v-text-field>
                              <v-date-picker @input="markDates(student.basic.email, step.name)"  v-model="date"></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            
                          </v-flex>
                          </v-layout>
                          </v-timeline-item>
                        </v-slide-x-reverse-transition>
                      </v-timeline>
                    </v-card-text>
                  </v-card>
              </v-flex>
              <!-- <v-flex xs12 md4>
                <v-textarea
                  solo
                  name="input-7-4"
                  label="Notes"
                  v-bind:placeholder="'Notes for ' + student.basic.name"
                  v-model="notes"
                  :value="student.notes ? student.notes.notes ? student.notes.notes : null : null"
                ></v-textarea>
                <v-btn @click="markNotes(student.basic.email, 'notes')" small color="primary">Save</v-btn>
              </v-flex> -->
            </v-layout>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="7"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      
    </v-container>
   
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'

import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      countries:[],
      selectedCountries: [],
      nselectedStatus:[],
      nselect:["done"],
      searchClient: algoliasearch(
        'JBBBFXFJVN',
        '47a3451c290ee70e0cf1ae1faa7888bc'
      ),
      selectedStatus:[],
      searchText:'',
      loading:false,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps: [
        {id:1, icon:'phone', name:'call', value:'call', text:'Called'},
        {id:2, icon:'favorite', name:'interested', value:'interested', text:'Interested'},
        {id:3, icon:'video_label', name:'interviewed', value:'interviewed', text:'Interviewed', details: [
            {id:1, icon:'done', name:'accepted', value:'accepted', text:'Accepted'}
          ]
        },
        {id:14, icon:'people', name:'visited', value:'visited', text:'Visited'},
        {id:4, icon:'file_copy', name:'submitted', value:'submitted', text:'Papers Submitted'},
        {id:5, icon:'create', name:'signed', value:'signed', text:'Signed Contract'},
        {id:6, icon:'euro_symbol', name:'firstPayment', value:'firstPayment', text:'Paid First Installment'},
        {id:7, icon:'publish', name:'papersSent', value:'papersSent', text:'Papers Sent'},
        {id:8, icon:'receipt', name:'initialRegistrationsRecieved', value:'initialRegistrationsRecieved', text:'Initial Registrations Recieved'},
        {id:16, icon:'subject', name:'transferOrder', value:'transferOrder', text:'Transfer Order'},
        {id:17, icon:'account_balance', name:'swift', value:'swift', text:'Swift'},
        {id:9, icon:'euro_symbol', name:'transfer', value:'transfer', text:'Fees Transfered'},
        {id:10, icon:'get_app', name:'finalRegistrationsRecieved', value:'finalRegistrationsRecieved', text:'Final Registrations Recieved'},
        {id:15, icon:'book', name:'germanLanguage', value:'germanLanguage', text:'German Language', details: [
            {id:1, icon:'done', name:'a1', value:'a1', text:'A 1'},
            {id:2, icon:'done', name:'a2', value:'a2', text:'A 2'},
            {id:3, icon:'done', name:'b1', value:'b1', text:'B 1'},
          ]
        },
        {id:18, icon:'assignment_turned_in', name:'evaluation', value:'evaluation', text:'Language Evaluation'},
        {id:23, icon:'brightness_auto', name:'germanCertificate', value:'germanCertificate', text:'German Certificate'},
        {id:19, icon:'video_label', name:'3rdpartyInterview', value:'3rdpartyInterview', text:'Employer interview'},
        {id:20, icon:'offline_pin', name:'contract', value:'contract', text:'Contract'},
        {id:21, icon:'beenhere', name:'ZAVacceptance', value:'ZAVacceptance', text:'ZAV Acceptance'},
        {id:22, icon:'today', name:'embassyAppointment', value:'embassyAppointment', text:'Embassy Appointment'},
        {id:11, icon:'beenhere', name:'gotVisa', value:'gotVisa', text:'Got Visa'},
        {id:12, icon:'flight', name:'traveled', value:'traveled', text:'Left to Destination'},
        {id:13, icon:'done', name:'done', value:'done', text:'Done'},
      ],
      stepsSelector: [
        'call',
        'interested',
        'visited',
        'submitted',
        'signed',
        'firstPayment',
        'papersSent',
        'initialRegistrationsRecieved',
        'transfer',
        'finalRegistrationsRecieved',
        'gotVisa',
        'traveled',
      ]
    }
  },
  watch: {
      $route (to, from){
          this.program = this.$route.query.program;
          this.loadData()
      }
  },
  methods: {
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        let d = new Date(a.basic.datetime)
        console.log(d.getMonth())
        console.log(d.getDate())
        return new Date(b.basic.datetime) - new Date(a.basic.datetime);
      })
    },
    addDayToStudent() {
      
    },
    updateVisible() {
      this.students = this.filteredStudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.students.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents.filter(student =>  {
        if (this.selectedCountries.length > 0) {
          return this.selectedCountries.indexOf(student.basic.country) !== -1
        } else {
          return student
        }
      })
      .filter(student =>  {
        if (this.selectedStatus.length > 0 && this.selectedStatus.length < this.stepsSelector.length) {
          if (student.lead) {
            for (let index = 0; index < this.selectedStatus.length; index++) {
              const element = this.selectedStatus[index];
              if (student.lead[element] === true) {
                return student
              }
            }
          } 
        } else {
          return student
        }
      })
      .filter(student => {
        if (this.nselectedStatus.length > 0 && this.nselectedStatus.length < this.stepsSelector.length) {
          if (!student.lead) {
            return student
          } else {
            for (let index = 0; index < this.nselectedStatus.length; index++) {
              const element = this.nselectedStatus[index];
              if (student.lead[element] !== true) {
                return student
              }
            }
          }
        } else {
          return student
        }
      })
      .filter(student => {
        let name = student.basic.name.toLowerCase();
        let email = student.basic.email.toLowerCase();
        let search = this.searchText.toLowerCase();
        if (name.includes(search) || email.includes(search)) {
          return student
        }
      })
      .filter(student => {
        console.log(this.fromDate)
        return student
      })
      console.log('------------------------------------');
      

      console.log(this.filteredStudents);
      console.log('------------------------------------');
    },
    getPagesNumber() {
      this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    markInterested(email, variable) {
      let done = !variable
      db.ref('leads/' + this.program + '/tunisia/' + email.replace(/\./g, '_dot_') + '/lead')
      .update({
        done
      })
      .then(x=>{
        this.showSnackbar('Updated Successfully')
      })
    },
    markSteps(email, name, variable) {

      let storeVar = !variable
      db.ref('leads/' + this.program + '/tunisia/' + email.replace(/\./g, '_dot_') + '/lead')
      .update({
        [name]:storeVar
      })
      .then(x=>{
        this.showSnackbar('Updated Successfully')
      })
    },
    markDates(email, name, date = this.date) {
      console.log(this.date)
      db.ref('leads/' + this.program + '/tunisia/' + email.replace(/\./g, '_dot_') + '/date')
      .update({
        [name]:date
      })
      if (date === null) {
        this.markSteps(email, name, true)
      } else {
        this.markSteps(email, name, false)
      }
    },
    markNotes(email, name) {
      console.log(this.notes)
      let notes = this.notes
      db.ref('leads/' + this.program + '/tunisia/' + email.replace(/\./g, '_dot_') + '/notes')
      .update({
        [name]:notes
      })
      .then(x=>{
        this.showSnackbar('Updated Successfully')
      })
    },
    stepsDone (student, step) {
      if (student.lead) {
        if (student.lead[step]) {
          return 'green'
        } else {
          return 'grey'
        }
      } else {
        return 'grey'
      }
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    loadData() {
      db.ref('leads/' + this.program + '/tunisia')
      .on('value', res=> {
        let students = res.val()
        let studentsArr = []
        let stCountries = []
        for (const key in students) {
          if (students.hasOwnProperty(key)) {
            const student = students[key];
            studentsArr.push(student)
            stCountries.push(student.basic.country)
            
          }
        }
        this.countries = stCountries 
        this.allstudents = studentsArr
        this.filteredStudents = this.allstudents
        this.sortByDate()
        this.updateCountryFilters()
        this.updateVisible()
        this.getPagesNumber()

        this.loading = false
        if (this.firstload) {
          this.showSnackbar('Data Loaded')
          this.firstload = false
        }
      })
    },
    showSnackbar (text) {
      this.$emit('snackbarNotification', text)
    }
  },
  props: {
   userName: {
    required: true,
    default: 'Green Way'
   },
   userImage: {
    required: true,
    default: ''
   },
   userData: {
    required: true,
   },
   isLogin: {
    required: true, 
   }
  },
  created() {
    this.loading = true
    this.program = this.$route.query.program
    this.loadData()
    console.log('Component has been created!');
  },
  mounted() {
    console.log('Component has been mounted!');
    this.firstLoad()
  },
  destroyed() {
    console.log('Component has been destroyed!');
  }
}
</script>

<style>

.project.complete{
  border-left: 4px solid #3cd1c2;
}
.project.ongoing{
  border-left: 4px solid #ffaa2c;
}
.project.overdue{
  border-left: 4px solid #f83e70;
}
.v-chip.complete{
  background: #3cd1c2;
}
.v-chip.ongoing{
  background: #ffaa2c;
}
.v-chip.overdue{
  background: #f83e70;
}
.v-timeline-item {
  padding-bottom:0;
}

.greenDone {
  background: #106342 !important;
}

.greenDone .greenDoneText {
  color: #fff !important;
}

</style>
