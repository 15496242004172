<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            label="Corporate"
            multiple
            solo
            chips
            @change="updateCountryFilters"
            hint="Select Corporates"
            clearable
            v-model="selectedStatus"
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 px-1>
          <v-select
            :items="steps"
            v-model="nselect"
            label="Show All But"
            multiple
            solo
            chips
            @change="getnSelectedStatus"
            hint="Select who are not"
            clearable
            persistent-hint
            deletable-chips
            small-chips
            :loading="loading"
          ></v-select>
        </v-flex>
        <v-flex xs12 md12 px-1>
        <v-text-field
          label="Search By Name/Email"
          solo
          v-model="searchText"
          @input="getSearchString()"
        ></v-text-field>
        </v-flex>
        <v-flex xs12 align-center class="text-xs-center">
          <v-btn @click="exportStudents()" color="primary" dark>Download Candidates Report</v-btn>
          <v-btn @click="getAllCertificates()" color="primary" dark>Get All Certificates</v-btn>
          <v-btn @click="getAllCards()" color="primary" dark>Get All Cards</v-btn>

          
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredStudents.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      
      <v-card 
      v-for="(student,i) in students"
      :key="i"
      >
        <v-layout v-if="student" row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Candidate Name</div>
            <div >{{ student.name }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Email</div>
            <div >
              <span >{{ student.email | truncate(10, '..') }}</span>
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">
              Phone 
            </div>
            <div>{{ student.phone }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Gender</div>
            <div>{{ student.gender }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Age</div>
            <div>{{ student.age }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Corporate</div>
            <div>{{ student.corporate }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Position</div>
            <div>{{ student.position }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Date of registration</div>
            <div>{{ student.registerDate | formatDate | truncate(10, '..') }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Pass Basic Exam</div>
            <div v-if="student.exam.basic">
              <v-avatar
                  size="10px"
                  :color="student.exam.basic.pass ? 'success' : 'error' "
                >
                </v-avatar>
              
            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Pass AIMA Assessor Exam</div>
            <div v-if="student.exam.aimaassessor">
              <v-avatar
                  size="10px"
                  :color="student.exam.aimaassessor.pass ? 'success' : 'error' "
                >
                </v-avatar>

            </div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Basic exam score</div>
            <div v-if="student.exam.basic.done">{{ parseFloat(student.exam.basic.score).toFixed(1) }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Aima Assessor exam score</div>
            <div v-if="student.exam.aimaassessor.done">{{ parseFloat(student.exam.aimaassessor.score).toFixed(1) }}</div>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Certificate</div>
            <v-btn v-if="student.exam.basic.pass" 
              depressed 
              small 
              color="primary" 
              @click="getPdf(student.name, student.exam.basic.token.code, student.exam.basic.meta.done, 'basic')"
              dark>Get Certificate</v-btn>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Certificate AIMA</div>
            <v-btn v-if="student.exam.aimaassessor.pass"
              depressed
              small
              color="primary"
              @click="getPdf(student.name, student.exam.aimaassessor.token.code, student.exam.aimaassessor.meta.done, 'aimaassessor')"
              dark>Get AIMA Assessor Certificate</v-btn>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Member Card</div>
            <v-btn v-if="student.exam.basic.pass"
              depressed 
              small 
              color="primary" 
              @click="getCardPdf(student.name, student.exam.basic.token.code, student.exam.basic.meta.done, 'basic')"
              dark>Get Card</v-btn>
          </v-flex>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Member Card AIMA</div>
            <v-btn v-if="student.exam.aimaassessor.pass"
              depressed
              small
              color="primary"
              @click="getCardPdf(student.name, student.exam.aimaassessor.token.code, student.exam.aimaassessor.meta.done, 'aimaassessor')"
              dark>Get AIMA Card</v-btn>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">Candidate can access:</div>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">Basic IAIDL:</div>
          </v-flex>
          <v-flex xs6 sm3 md3 v-for="level in Object.keys(student.access.basic)" :key="level">
            <v-chip 
              dark 
              text-color="white" 
              small 
              :class="student.access.basic[level] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'" 
              @click="toggleAccess(student.uid, level, student.access.basic[level], 'basic')"
              >
              <v-icon v-if="(student.access ? student.access.basic[level] : false)" left>done</v-icon>
              <v-icon v-if="(student.access ? !student.access.basic[level] : false)" left>close</v-icon>
              {{ level.toUpperCase() }}
            </v-chip>
          </v-flex>
          <v-flex xs12>
            <div class="caption grey--text ">IAIDL-AIMA:</div>
          </v-flex>

          <v-flex xs12 sm12 md12 v-if="student.tokens.exam.aimaassessor">
          <v-flex xs6 sm3 md3 v-for="leveler in Object.keys(student.access.aimaassessor)" :key="'A' + leveler">
            <v-chip
              dark
              text-color="white"
              small
              :class="student.access.aimaassessor[leveler] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'"
              @click="toggleAccess(student.uid, leveler, student.access.aimaassessor[leveler], 'aimaassessor')"
              >
              <v-icon v-if="(student.access ? student.access.aimaassessor[leveler] : false)" left>done</v-icon>
              <v-icon v-if="(student.access ? !student.access.aimaassessor[leveler] : false)" left>close</v-icon>
              {{ leveler.toUpperCase() }}
            </v-chip>
          </v-flex>
          </v-flex>
          <v-flex  xs12 sm12 md12 v-else>
            <v-btn slot="activator" @click="registerAima(student);" color="primary" dark>
              Register AIMA Assessor
            </v-btn>
          </v-flex>


        </v-layout>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog 
              persistent 
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              v-model="dialog.id[student.name]"
              >
                <v-btn slot="activator" @click="getMaterialProgress(student, 'basic');getAimaAssProgress(student, 'aimaassessor')" color="primary" dark>
                  <v-icon left>launch</v-icon>
                  Details & Registration
                </v-btn>
                <v-card>
                  <v-toolbar card dark color="primary">
                    <v-btn icon dark @click="dialog.id[student.name] = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{student.name.toUpperCase()}} Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <!-- <v-btn dark flat @click="dialog.id[step.name+i] = false">Save</v-btn> -->
                    </v-toolbar-items>
                    <!-- <v-menu bottom right offset-y>
                      <v-btn slot="activator" dark icon>
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                      <v-list>
                        <v-list-tile v-for="(item, i) in countries" :key="i" >
                          <v-list-tile-title>{{ item }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu> -->
                  </v-toolbar>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-layout row wrap>
                          <v-flex xs6 md4 pa-1 v-for="key in Object.keys(student).filter(x=> {
                            showKey = ['age', 'degree', 'language', 'name', 'phone', 'position', 'title', 'email', 'password']
                            if(typeof student[x] == 'string' &&
                            showKey.indexOf(x) != -1
                              ) {
                                return x
                              }
                            })" :key="key">
                            {{key.replace(/_/g, ' ').toUpperCase()}}
                            <v-text-field
                              v-if="key!='language'"
                              :label="key.replace(/_/g, ' ').toUpperCase()"
                              v-model="student[key]"
                              hide-details
                              solo
                            ></v-text-field>
                            <v-select
                              v-if="key=='language'"
                              :items="['AR', 'EN']"
                              solo
                              :label="key.replace(/_/g, ' ').toUpperCase()"
                              v-model="student[key]"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark @click="updateStudent(student)">update</v-btn>
                    </v-card-actions>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <div class="caption grey--text ">Candidate can access:</div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="caption grey--text ">Basic IAIDL:</div>
                      </v-flex>
                      <v-flex xs6 sm3 md3 v-for="type in Object.keys(student.access.basic)" :key="type">
                        <v-chip 
                          dark 
                          text-color="white" 
                          small 
                          :class="student.access.basic[type] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'" 
                          @click="toggleAccess(student.uid, type, student.access.basic[type], 'basic')"
                          >
                          <v-icon v-if="(student.access ? student.access.basic[type] : false)" left>done</v-icon>
                          <v-icon v-if="(student.access ? !student.access.basic[type] : false)" left>close</v-icon>
                          {{ type.toUpperCase() }}
                        </v-chip>
                      </v-flex>
                      <v-flex xs12>
                        <div class="caption grey--text ">IAIDL-AIMA:</div>
                      </v-flex>
<div v-if="student.tokens.exam.aimaassessor">
<v-flex xs6 sm3 md3 v-for="typer in Object.keys(student.access.aimaassessor)" :key="'B' + typer">
  <v-chip
    dark
    text-color="white"
    small
    :class="student.access.aimaassessor[typer] ? 'green darken-2 customPointer' : 'red accent-4 customPointer'"
    @click="toggleAccess(student.uid, typer, student.access.aimaassessor[typer], 'aimaassessor')"
    >
    <v-icon v-if="(student.access ? student.access.aimaassessor[typer] : false)" left>done</v-icon>
    <v-icon v-if="(student.access ? !student.access.aimaassessor[typer] : false)" left>close</v-icon>
    {{ typer.toUpperCase() }}
  </v-chip>
</v-flex>
</div>
<div v-else>
  ssss
</div>
                      <v-flex xs6 sm3 md3>
                      </v-flex>
                      <v-flex xs12>
                        <div class="caption grey--text ">Basic IAIDL:</div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Pre-Assessment</div>
                              <div class="icon">
                                <v-icon color="secondary">assessment</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                :size="150"
                                :width="15"
                                :rotate="-90"
                                :value="student.preassessment.basic.score"
                                :color="student.preassessment.basic.started ? student.preassessment.basic.pass ? 'success' : 'error' : ''"
                              >
                                
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.preassessment.basic.started ? student.preassessment.basic.pass ? 'success' : 'error' : ''">
                                {{  parseInt(student.preassessment.basic.score) }}%</div>
                                <div 
                                  class="headline text-md-center" 
                                  :text-color="student.preassessment.basic.start ? student.preassessment.basic.pass ? 'success' : 'error' : ''">
                                  {{ student.preassessment.basic.started ? student.preassessment.basic.pass ? 'Pass' : 'Fail' : '' }}
                                </div>
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-divider v-if="results(student, 'preassessment', 'basic').examCorrection"></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                          </v-card-actions>
                          <v-expansion-panel v-if="results(student, 'preassessment', 'basic').examCorrection">
                            <v-expansion-panel-content
                            >
                              <template slot="header">
                                <div>Details</div>
                              </template>
                              <v-card>
                                <div v-for="(result, i) in results(student, 'preassessment', 'basic').examCorrection" :key="i">
                                  <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                                    Answer to Question: {{ Object.keys(result)[0] }} was
                                    {{ result[Object.keys(result)[0]] }}
                                    <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn>
                                  </v-alert>
                                </div>
                              </v-card>
                              <v-dialog
                                v-model="Qdialog"
                                :overlay="false"
                                max-width="500px"
                                transition="dialog-transition"
                              >
                                <v-card>
                                  <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                                  <v-card-text v-if="question">
                                    <v-radio-group v-model="question.correctAnswer">
                                      <v-radio 
                                      v-for="answer in question.answers" 
                                      :key="question.key + '_' + answer.number" 
                                      :name="answer.text"
                                      :label="answer.text"
                                      readonly
                                      :value="answer.number" 
                                      color="primary"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" flat @click="Qdialog = false">Dismiss</v-btn>
                                  </v-card-actions>
                                </v-card>
                            </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card>     
                      </v-flex>
                      <!-- START -->
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Material</div>
                              <div class="icon">
                                <v-icon color="primary">school</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                  :size="150"
                                  :width="15"
                                  :rotate="-90"
                                  :value="bMaterialProgress"
                                  color="primary"
                              >
                                {{ parseInt(bMaterialProgress) }}
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <!-- <v-divider></v-divider> -->
                          <v-card-actions>
                            <v-spacer></v-spacer>

                          </v-card-actions>
                        </v-card>
                      </v-flex>
                      <!-- EXAM BASIC START -->
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Exam</div>
                              <div class="icon">
                                <v-icon color="secondary">assessment</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                  :size="150"
                                  :width="15"
                                  :rotate="-90"
                                  :value="student.exam.basic.score"
                                  :color="student.exam.basic.pass ? 'success' : 'error'"
                              >

                                <div
                                    class="headline text-md-center"
                                    :text-color="student.exam.basic.started ? student.exam.basic.pass ? 'success' : 'error' : ''">
                                  {{  parseInt(student.exam.basic.score) }}%</div>
                                <div
                                    class="headline text-md-center"
                                    :text-color="student.exam.basic.start ? student.exam.basic.pass ? 'success' : 'error' : ''">
                                  {{ student.exam.basic.started ? student.exam.basic.pass ? 'Pass' : 'Fail' : '' }}
                                </div>
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-divider v-if="results(student, 'exam', 'basic').examCorrection"></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                outline
                                @click="goTo('/verification/' + student.exam.basic.token.code + '?preview=true')"
                                color="primary"
                                v-if="student.certificate.basic.achieved"
                                dark
                            >certificate</v-btn>
                          </v-card-actions>
                          <v-expansion-panel v-if="results(student, 'exam', 'basic').examCorrection">
                            <v-expansion-panel-content
                            >
                              <template slot="header">
                                <div>Details</div>
                              </template>
                              <v-card>
                                <div v-for="(result, i) in results(student, 'exam', 'basic').examCorrection" :key="i">
                                  <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                                    Answer to Question: {{ Object.keys(result)[0] }} was
                                    {{ result[Object.keys(result)[0]] }}
                                    <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn>
                                  </v-alert>
                                </div>
                              </v-card>
                              <v-dialog
                                  v-model="Qdialog"
                                  :overlay="false"
                                  max-width="500px"
                                  transition="dialog-transition"
                              >
                                <v-card>
                                  <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                                  <v-card-text v-if="question">
                                    <v-radio-group v-model="question.correctAnswer">
                                      <v-radio
                                          v-for="answer in question.answers"
                                          :key="question.key + '_' + answer.number"
                                          :name="answer.text"
                                          :label="answer.text"
                                          readonly
                                          :value="answer.number"
                                          color="primary"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" flat @click="Qdialog = false">Dismiss</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card>
                      </v-flex>
                      <!-- EXAM BASIC END -->
                      <!-- end -->


                      <!-- START DODODO
                      <v-flex xs12>
                        <div class="caption grey--text ">IAIDL-AIMA:</div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Material</div>
                              <div class="icon">
                                <v-icon color="primary">school</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                  :size="150"
                                  :width="15"
                                  :rotate="-90"
                                  :value="aimaMaterialProgress"
                                  color="primary"
                              >
                                {{ parseInt(aimaMaterialProgress) }}
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                          </v-card-actions>
                        </v-card>
                      </v-flex>

                      EXAM AIMA START
                      <v-flex xs12 md4>
                        <v-card class="ma-2">
                          <v-card-title>
                            <div class="layout row ma-0 justify-space-between pb-1">
                              <div class="subheading">Exam</div>
                              <div class="icon">
                                <v-icon color="secondary">assessment</v-icon>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="justify-center row layout ma-0">
                              <v-progress-circular
                                  :size="150"
                                  :width="15"
                                  :rotate="-90"
                                  :value="student.exam.aimaassessor.score"
                                  :color="student.exam.aimaassessor.pass ? 'success' : 'error'"
                              >

                                <div
                                    class="headline text-md-center"
                                    :text-color="student.exam.aimaassessor.started ? student.exam.aimaassessor.pass ? 'success' : 'error' : ''">
                                  {{  parseInt(student.exam.aimaassessor.score) }}%</div>
                                <div
                                    class="headline text-md-center"
                                    :text-color="student.exam.aimaassessor.start ? student.exam.aimaassessor.pass ? 'success' : 'error' : ''">
                                  {{ student.exam.aimaassessor.started ? student.exam.aimaassessor.pass ? 'Pass' : 'Fail' : '' }}
                                </div>
                              </v-progress-circular>
                            </div>
                          </v-card-text>
                          <v-divider v-if="results(student, 'exam', 'aimaassessor').examCorrection"></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                outline
                                @click="goTo('/verification/' + student.exam.aimaassessor.token.code + '?preview=true')"
                                color="primary"
                                v-if="student.certificate.aimaassessor.achieved"
                                dark
                            >certificate</v-btn>
                          </v-card-actions>
                          <v-expansion-panel v-if="results(student, 'exam', 'aimaassessor').examCorrection">
                            <v-expansion-panel-content
                            >
                              <template slot="header">
                                <div>Details</div>
                              </template>
                              <v-card>
                                <div v-for="(result, i) in results(student, 'exam', 'aimaassessor').examCorrection" :key="i">
                                  <v-alert :type="result[Object.keys(result)[0]] ? 'success' : 'error'" :value="true">
                                    Answer to Question: {{ Object.keys(result)[0] }} was
                                    {{ result[Object.keys(result)[0]] }}
                                    <v-btn outline v-if="!result[Object.keys(result)[0]]" color="white white--text" @click="loadQs(Object.keys(result)[0])" dark>Details</v-btn>
                                  </v-alert>
                                </div>
                              </v-card>
                              <v-dialog
                                  v-model="Qdialog"
                                  :overlay="false"
                                  max-width="500px"
                                  transition="dialog-transition"
                              >
                                <v-card>
                                  <v-card-title v-if="question" class="headline">{{question.text}}</v-card-title>
                                  <v-card-text v-if="question">
                                    <v-radio-group v-model="question.correctAnswer">
                                      <v-radio
                                          v-for="answer in question.answers"
                                          :key="question.key + '_' + answer.number"
                                          :name="answer.text"
                                          :label="answer.text"
                                          readonly
                                          :value="answer.number"
                                          color="primary"
                                      ></v-radio>
                                    </v-radio-group>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-chip v-if="question" color="primary" class="white--text">{{ question.category.toUpperCase() }}</v-chip>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" flat @click="Qdialog = false">Dismiss</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card>
                      </v-flex>
                      EXAM AIMA END -->






























                      <!-- end -->
                      <v-flex v-if="student.project" xs12>
                        <v-expansion-panel expand>
                          <v-expansion-panel-content>
                            <div slot="header">Project</div>
                            <v-card>
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex v-for="key in Object.keys(student.project)" :key="key" xs12>
                                    <div v-if="key != 'documents'">
                                      <v-chip text-color="white" color="secondary" label>{{ key.split('_').join(' ').toUpperCase() }}</v-chip>
                                      <div>
                                        <v-chip label>{{ student.project[key] }}</v-chip>
                                      </div>
                                    </div>
                                    <div v-if="key == 'documents'">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <v-chip text-color="white" color="secondary" label>{{ key.split('_').join(' ').toUpperCase() }}</v-chip>
                                        </v-flex>
                                        <v-flex v-for="doc in student.project[key]" :key="doc.url" xs12>
                                          <v-chip color="success" text-color="white" label>{{doc.name}}</v-chip>
                                          <v-btn @click="openDoc(doc.url)" small color="primary">open document</v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
          </v-card-actions>
      </v-card>


      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<!--suppress JSAnnotator -->
<script>
import {firebase, db, auth} from '@/fb'
import axios from 'axios';
import { Parser } from 'json2csv'

export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      displayText:['age', 'corporate', 'country', 'degree', 'email', 'gender', 'name', 'phone', 'position'],
      editText:['name', 'phone'],
      center:null,
      centerUID:null,
      fromDate:null,
      toDate:null,
      program:null,
      allstudents: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      cancelDialog:false,
      question:null,
      filteredStudents:[],
      paginateStudents: [],
      students: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      loading:false,
      bMaterialProgress:0,
      aimaMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    async updateStudent(student){
      const headers = {'Accept': 'application/json'}
      let {name, language, phone, position, title, age, degree, email, password } = student
      await db.ref(`candidates/${student.uid}`).update({name, language, phone, position, title, age, degree, email, password })
      let r = await axios.post(`https://us-central1-astack-8276c.cloudfunctions.net/main/adminUpdate`, {uid:student.uid, name, language, phone, position, title, age, degree, email, password } , {headers:headers})
      if(r.data && r.data.success){
        this.$emit('snackbarNotification', `Updated`, 'success')
        console.log('------------------------------------');
        console.log(r);
        console.log('------------------------------------');
      } else {
        this.$emit('snackbarNotification', `Updated Failed`, 'error')
        console.log('------------------------------------');
        console.log(r);
        console.log('------------------------------------');
      }
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredStudents.sort((a,b) => {
        return new Date(b.registerDate) - new Date(a.registerDate);
      })
    },
    deleteStudent(student){
      console.log(student)
    },
    updateVisible() {
      this.students = this.filteredStudents.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.students.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredStudents  = this.allstudents
      .filter(student =>  {
        if (this.selectedStatus.length > 0) {
          if (student.corporate) {
            for (let index = 0; index < this.selectedStatus.length; index++) {
              const element = this.selectedStatus[index];
              if (student.corporate.toUpperCase() === element) {
                return student
              }
            }
          } 
        } else {
          return student
        }
      })
      .filter(student => {
        let name = student.name ? student.name.toLowerCase(): null ;
        let email = student.email? student.email.toLowerCase() : null;
        let search = this.searchText.toLowerCase();
        if (name && email && (name.includes(search) || email.includes(search))) {
          return student
        }
      })
      this.getPagesNumber()
      this.updateVisible()
    },
    getPagesNumber() {
      this.pagesNumber = parseInt(this.filteredStudents.length / this.pageSize)
    },
    getSelectedCountries (val) {
      if (val.length > 0) {
        this.selectedCountries = val
      } else {
        this.selectedCountries = this.countries
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSelectedStatus (val) {
      if (val.length > 0) {
        this.selectedStatus = val
      } else {
        this.selectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getnSelectedStatus (val) {
      if (val.length > 0) {
        this.nselectedStatus = val
      } else {
        this.nselectedStatus = this.stepsSelector
      }
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    getSearchString () {
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    firstLoad(){
      this.nselectedStatus = ['done']
      this.updateCountryFilters()
      this.updateVisible()
      this.getPagesNumber()
    },
    exportStudents(){
      let a = this.allstudents.map(s=>{
        return {
          name:s.name,
          email:s.email,
          phone:s.phone,
          registered_date:s.registerDate,
          basic_exam_done:s.exam.basic.done,
          basic_exam_done_date:s.exam.basic.meta? s.exam.basic.meta.done:null,
          basic_exam_score:s.exam.basic.score?parseFloat(s.exam.basic.score).toFixed(1):0,
          aima_exam_done:s.exam.aimaassessor.done,
          aima_exam_done_date:s.exam.aimaassessor.meta? s.exam.aimaassessor.meta.done:null,
          aima_exam_score:s.exam.aimaassessor.score?parseFloat(s.exam.aimaassessor.score).toFixed(1):0,
          language:s.language
        }
      })
      let fields = ['name', 'email', 'phone', 'registered_date', 'basic_exam_done', 'basic_exam_done_date', 'basic_exam_score','aima_exam_done', 'aima_exam_done_date', 'aima_exam_score', 'language']
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(a);
      console.log(csv)
			let content = 'data:text/csv;charset=utf-8,';
      content += csv;
      const data = encodeURI(content);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute("download", "candidates_report_"+ new Date().toLocaleString() + ".csv");
      link.click();
    },
    getAllCertificates(){
      let all = this.filteredStudents.filter(s=>{
        if(s.exam && s.exam.basic && s.exam.basic.pass){
          return s
        }
      });
      (async ()=>{
        for (let i = 0; i < all.length; i++) {
          const student = all[i];
          await axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/api/pdf?name=${student.name.toUpperCase()}&code=${student.exam.basic.token.code}&date=${student.exam.basic.meta.done}&level=basic`)
          .then(response => { 
            window.open(response.data, 'Certificate Downloader')
          })
        }
      })()
    },
    getAllCards(){
      let all = this.filteredStudents.filter(s=>{
        if(s.exam && s.exam.basic && s.exam.basic.pass){
          return s
        }
      });
      (async ()=>{
        for (let i = 0; i < all.length; i++) {
          const student = all[i];
          await axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/pdfcard?name=${student.name.toUpperCase()}&code=${student.exam.basic.token.code}&date=${student.exam.basic.meta.done}&level=basic`)
          .then(response => { 
            window.open(response.data, 'Certificate Downloader')
          })
        }
      })()
    },
    loadData() {
      let studentsArr = []
      let HH = 0

      auth.onAuthStateChanged(user => {
        if (user) {
          let centerUIDA = user.uid
          this.centerUID = user.uid
          console.log(centerUIDA)
          db.ref( 'centers/' + centerUIDA + '/candidates')
          .once('value')
          .then(snap=>{
            let candidates = snap.val()
            for (const key in candidates) {
              // HH++
              if( HH < 5 ){

                if (candidates.hasOwnProperty(key)) {
                  const candidateEmail = candidates[key];
                  this.loading = true
                  db.ref('candidates/' + candidateEmail)
                      .once('value').then(subSnap=>{
                    this.loading = true
                    let candidate = subSnap.val()
                    if(candidate) {

                      console.log(candidate.tokens.exam.aimaassessor)
                      let index = this.allstudents.findIndex(x => x.email === candidate.email)
                      if(index != -1) this.allstudents.splice(index, 1, candidate);
                      if(index === -1)this.allstudents.push(candidate)
                      if(candidate.corporate)this.steps.push(candidate.corporate.toUpperCase())
                      this.sortByDate()
                      this.updateCountryFilters()
                      this.updateVisible()
                      this.getPagesNumber()
                      this.loading = false

                    }
                  })
                }

              } else {
                this.loading = false
              }
            }
          })
        } else {
          this.$router.push('/login')
        }
      })
    },
    getPdf(name, token, date, level) {
      let pdate = ``
      this.$emit('snackbarNotification', `Generating ${name} Certificate...`, 'success')
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/api/pdf?name=${name.toUpperCase()}&code=${token}&date=${date}&level=${level}`)
      .then(response => { 
        window.open(response.data, 'Certificate Downloader')
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
    getCardPdf(name, token, date, level) {
      let pdate = ``
      this.$emit('snackbarNotification', `Generating ${name} Card...`, 'success')
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/pdfcard?name=${name.toUpperCase()}&code=${token}&date=${date}&level=${level}`)
      .then(response => { 
        window.open(response.data, 'Card Downloader')
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
    toggleAccess(uid, item, val, level) {
      console.log("item=================")
      console.log(item)
      alert("Access Changed")
      db.ref('candidates/' + uid + '/access/' + level)
      .update({
        [item]:!val
      })
      if (item == 'material') {
        return
      }
      db.ref('candidates/' + uid + '/' + item +'/' + level)
      .update({
        access:!val
      })
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    getMaterialProgress(user, level){
      console.log('------------------------------------');
      console.log(user);
      console.log('------------------------------------');
      let materialProgress = user ? user.material[level] : null
      if (!materialProgress) return true
      db.ref('manuals')
      .once('value')
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        let manuals = fms.filter(x=> x.level == level && user && x.language == user.language )
        console.log(manuals)
        
        manuals.forEach(manual => {
          let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false 
          let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
          manual.progress = {
            started,
            progress
          }
        });
        let progress = 0
        manuals.forEach(manual => {
          progress += manual.progress.progress
        });
        this.bMaterialProgress = progress / manuals.length
      })
    },
    registerAima(user){
      let UID = user ? user.uid : null
      let aimaToken = this.getAimaAssessorToken
      if(UID && aimaToken){
        this.loading = true
        //check if center has aima tokens or no and bring one token
        console.log("CUID===========" + this.centerUID)
        console.log("UID===========" + UID)
        console.log("TKN===========" + aimaToken)
        //HHH
        db.ref('centers/' + this.centerUID + '/tokens/' + aimaToken)
            .update({
              type:'aimaassessor',
              expired:true,
              candidate: UID
            }, function(err){
              if (err) {
                console.log(err);
              } else {

                db.ref('candidates/' + UID + '/tokens/exam/')
                    .update({
                          aimaassessor: aimaToken
                        }, function(err){
                          if (err) {
                            console.log(err);
                          } else {
                            alert("Token Added to student")
                            //window.location.reload()
                          }
                        }
                    )



              }


                }
            )



      } else {
        alert("You dont have enough AIMA Assessor Tokens")
      }

    },
    updateUserAimaToken(user, aimaToken){
      let UID = user ? user.uid : null
      if(UID){
        this.loading = true





      }

    },
    getAimaAssProgress(user, level){
      let materialProgress = user ? user.material[level] : null
      if (!materialProgress) return true
      db.ref('manuals')
      .once('value')
      .then(snap=>{
        let ms = snap.val()
        let fms = []
        for (const key in ms) {
          if (ms.hasOwnProperty(key)) {
            const m = ms[key];
            fms.push(m)
          }
        }
        let manuals = fms.filter(x=> x.level == level && user && x.language == user.language )
        console.log(manuals)

        manuals.forEach(manual => {
          let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false
          let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
          manual.progress = {
            started,
            progress
          }
        });
        let progress = 0
        manuals.forEach(manual => {
          progress += manual.progress.progress
        });
        this.aimaMaterialProgress = progress / manuals.length
      })
    },
    results(user, type, level) {
      let exam = user ? user[type][level].exam : null
      let correctAnswers = user ? user[type][level].correctAnswers : null
      if(!exam) return true
      let examCorrection = []
      let count = 0
      let trueCount = 0
      for (const key in exam) {
        if (exam.hasOwnProperty(key)) {
          const answer = exam[key];
          count++
          if (!correctAnswers) {
            examCorrection.push({[key]:false})
          } else {
            if (correctAnswers.indexOf(key) != -1) {
              examCorrection.push({[key]:true})
              trueCount++
            } else {
              examCorrection.push({[key]:false})
            }
          }
        }
      }
      let score = trueCount / count * 100
      this.$emit('progressBar', false)
      return {examCorrection, score}
    },
    loadQs(key) {
      this.Qdialog = true
      db.ref('questions/' + key)
      .once('value')
      .then(sn=>{
        this.question = sn.val()
      })
    }
  },
  computed:{
    getAimaAssessorToken() {
      let tokens = this.$store.state.tokens ? this.$store.state.tokens : []
      if (tokens.length === 0) {
        return false
      }
      let validTokens = []

      for (const key in tokens) {
          if (tokens.hasOwnProperty(key)) {
            const token = tokens[key];
            if (!token.expired && token.type == 'aimaassessor') {
              validTokens.push({
                token,
                key
              })
            }
          }

      }
      let vtoken = validTokens[0]
      if (!vtoken) {
        return false
      }

      return vtoken ? vtoken.key : false
    }
  },
  created() {
    this.loading = true
    this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
