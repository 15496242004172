<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card @load="getTokensCounts">
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">{{ centerName }} Account</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
          <v-flex xs12>
            <v-card >
              <v-layout wrap>
                <v-flex xs12 md3 pa-1>
                  <v-avatar
                    size="195"
                    color="grey lighten-4"
                    v-if="getCenterImage"
                  >
                    <img :src="getCenterImage" alt="avatar">
                  </v-avatar>
                </v-flex>
                <v-flex xs12 md9 pa-1>
                  <v-card-title primary-title>
                    <div>
                      <div class="headline">{{ centerName }}</div>
                      <div>{{ centerEmail }}</div>
                      <div>{{ centerAddress }}, </div>
                      <div>{{ centerZip }} {{ centerCity }}</div>
                      <div>{{ centerCountry }}</div>
                    </div>
                  </v-card-title>
                </v-flex>
              </v-layout>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <!-- <v-btn @click="genTokens" color="primary" dark>Generate tokens!</v-btn> -->
                <v-btn @click="dialog = true" color="primary" dark>Edit</v-btn>

              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">Baisc Tokens</v-subheader>
            <v-slider
              v-model="basicTokens"
              readonly
              color="primary"
              always-dirty
              :max="basicTokens+3"
              thumb-size="28"
              thumb-color="red"
              thumb-label="always"
            ></v-slider>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">Advanced Tokens</v-subheader>
            <v-slider
              v-model="advancedTokens"
              readonly
              color="primary"
              always-dirty
              :max="advancedTokens+3"
              thumb-size="28"
              thumb-color="red"
              thumb-label="always"
            ></v-slider>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">IAIMA<sup>®</sup> Tokens</v-subheader>
            <v-slider
                v-model="iaimaTokens"
                readonly
                color="primary"
                always-dirty
                :max="iaimaTokens+3"
                thumb-size="28"
                thumb-color="red"
                thumb-label="always"
            ></v-slider>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">IAIMA<sup>®</sup> Assessors Tokens</v-subheader>
            <v-slider
                v-model="iaimaAssTokens"
                readonly
                color="primary"
                always-dirty
                :max="iaimaAssTokens+3"
                thumb-size="28"
                thumb-color="red"
                thumb-label="always"
            ></v-slider>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">Lab Tokens</v-subheader>
            <v-slider
                v-model="labEndTokens"
                readonly
                color="primary"
                always-dirty
                :max="labEndTokens+3"
                thumb-size="28"
                thumb-color="red"
                thumb-label="always"
            ></v-slider>
          </v-flex>
          <v-flex xs12>
            <v-subheader class="pl-0">Product Tokens</v-subheader>
            <v-slider
                v-model="ProdEndTokens"
                readonly
                color="primary"
                always-dirty
                :max="ProdEndTokens+3"
                thumb-size="28"
                thumb-color="red"
                thumb-label="always"
            ></v-slider>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row justify-center>
        <v-dialog 
        fullscreen 
        v-model="dialog" 
        persistent 
        hide-overlay >
          <v-card>
            <v-toolbar card dark color="primary" extended extension-height="3">
              <v-btn icon dark @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
              <v-toolbar-title>Edit Corporate Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark flat @click="dialog = false">Cancel</v-btn>
                <v-btn dark flat :loading="upload>0 && dialog" @click="download()">Save</v-btn>
              </v-toolbar-items>
              <v-progress-linear slot="extension" height="3"  v-if="upload>0" color="green darken-3" v-model="upload"></v-progress-linear>
            </v-toolbar>
            <v-card-text>
              <v-layout row wrap>
                <v-flex class="text-xs-center" xs12 px-1>
                  <croppa 
                    v-model="croppa"
                    accept=".jpeg,.png"
                    :width="250"
                    :height="250"
                    :show-loading="true"
                    :show-remove-button="true"
                    :disable-rotation="true"
                    :prevent-white-space="true"
                    :zoom-speed="10"
                    initial-size="cover"
                    initial-position="center"
                    :initial-image="getCenterImage"
                  >
                  <img :src="getCenterImage"
                      slot="initial">
                  </croppa>
                </v-flex>
                <v-flex xs6 px-1>
                  <v-text-field
                    label="Name"
                    v-model="centerName"
                    solo
                    required
                    :value="getCenterName"
                    prepend-inner-icon="person"
                    :error-messages="nameErrors"
                    @blur="$v.centerName.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 px-1>
                  <v-text-field
                    label="Address"
                    v-model="centerAddress"
                    solo
                    required
                    :value="getCenterAddress"
                    prepend-inner-icon="public"
                    :error-messages="addressErrors"
                    @blur="$v.centerAddress.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 px-1>
                  <v-text-field
                    label="City"
                    v-model="centerCity"
                    solo
                    required
                    :value="getCenterCity"
                    prepend-inner-icon="public"
                    :error-messages="cityErrors"
                    @blur="$v.centerCity.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex @load="getCenterCountry" xs6 px-1>
                  <v-text-field
                    label="Zipcode"
                    v-model="centerZip"
                    solo
                    required
                    :value="getCenterZip"
                    prepend-inner-icon="public"
                    :error-messages="zipcodeErrors"
                    @blur="$v.centerZip.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 px-1>
                  <v-autocomplete
                    :items="countries"
                    item-text="name"
                    v-model="centerCountry"
                    label="Center Country"
                    solo
                    prepend-inner-icon="public"
                    :error-messages="countryErrors"
                    @blur="$v.centerCountry.$touch()"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs6 px-1>
                  <v-text-field
                    label="Center Name"
                    v-model="centerEmail"
                    solo
                    :value="getCenterEmail"
                    prepend-inner-icon="email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators';
import { isEmail } from '@/validators';
import { getRandomInt, titleCase, randomString } from '@/customFunction';
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';

import { Printd } from 'printd';
import axios from 'axios';


export default {
  mixins: [validationMixin],
  props: ['userName'], 
  data() {
    return {
      date: new Date().toISOString(),
      countries: [],
      dialog:false,
      centerName:null,
      centerEmail:null,
      centerCountry:null,
      centerAddress:null,
      centerCity:null,
      centerZip:null,
      croppa:{},
      upload:0,
      initialImage:null,
      basicTokens:0,
      advancedTokens:0,
      iaimaAssTokens:0,
      labEndTokens:0,
      ProdEndTokens:0,
      iaimaTokens:0
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false
    },
logout(){
  
  auth.signOut();
        this.$store.state.centerData = null;
        this.$store.state.role = null;
        this.$store.state.tokens = null;
        this.$store.state.user = null;
        this.$store.state.candidates = null;
        this.$store.state.userData = null;
},
    //download(type, compressionRate) {
    download() {
      
      
      

/*
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.croppa.generateBlob((blob) => {
        var file = blob
        var metadata = {
          contentType: 'image/jpeg'
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('images/' + this.centerName +'.jpg').put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot)=> {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.upload = progress
                  }, (error) =>{

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;

            case 'storage/canceled':
              // User canceled the upload
              break;


            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },() => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
            console.log('File available at', downloadURL);
            db.ref('centers/' + this.$store.state.user.uid)
            .update({
              image:downloadURL,
              name:this.centerName,
              address:this.centerAddress,
              city:this.centerCity,
              zip:this.centerZip,
              country:this.centerCountry
            })
            .then(()=>{
              this.dialog = false
              this.upload = 0
            })
            var user = firebase.auth().currentUser;
            user.updateProfile({
                displayName: this.centerName,
                photoURL: downloadURL,
            })



          });
        });
      }, type, compressionRate)
      */
    },
    
  },
  watch: {
    
  },
  computed: {
    getTokensCounts() {
      let rettokens = this.$store.state.centerData ? this.$store.state.centerData.tokens : null
      if (!rettokens)  return true
      let tokens = []
      for (const key in rettokens) {
        if (rettokens.hasOwnProperty(key)) {
          const token = rettokens[key];
          tokens.push(token)
        }
      }
      let basic = tokens.filter(token => token.type === 'basic' && !token.expired)
      let advanced = tokens.filter(token => token.type === 'advanced' && !token.expired)
      let iaima = tokens.filter(token => token.type === 'iaima' && !token.expired)
      let aimaAss = tokens.filter(token => token.type === 'aimaassessor' && !token.expired)
      let labT = tokens.filter(token => token.type === 'lab' && !token.expired)
      let prodT = tokens.filter(token => token.type === 'product' && !token.expired)
      this.basicTokens = basic.length
      this.advancedTokens = advanced.length
      this.iaimaTokens = iaima.length
      this.iaimaAssTokens = aimaAss.length
      this.labEndTokens = labT.length
      this.ProdEndTokens = prodT.length
      return true
    },
    getCenterAddress() {
      let country = this.$store.state.centerData ? this.$store.state.centerData.address : ''
      this.centerAddress = country
      if(!country) return true
      return country
    },
    getCenterCity() {
      let country = this.$store.state.centerData ? this.$store.state.centerData.city : ''
      this.centerCity = country
      if(!country) return true
      return country
    },
    getCenterZip() {
      let country = this.$store.state.centerData ? this.$store.state.centerData.zip : ''
      this.centerZip = country
      if(!country) return true
      return country
    },
    getCenterCountry() {
      let country = this.$store.state.centerData ? this.$store.state.centerData.country : ''
      this.centerCountry = country
      if(!country) return true
      return country
    },
    getCenterImage() {
      let img = this.$store.state.centerData ? this.$store.state.centerData.image : ''
      this.initialImage = img
      if(!img) return ''
      return img
    },
    getCenterName() {
      let name = this.$store.state.centerData ? this.$store.state.centerData.name : ''
      this.centerName = name
      if(!name) return true
      return name
    },
    getCenterEmail() {
      let email = this.$store.state.centerData ? this.$store.state.centerData.email : ''
      this.centerEmail = email
      if(!email) return true
      return email
    },
    nameErrors () {
      const errors = []
      if (!this.$v.centerName.$dirty) return errors
      !this.$v.centerName.minLength && errors.push('Name must be at least 2 characters long')
      !this.$v.centerName.maxLength && errors.push('Name must be not longer than 20 characters')
      !this.$v.centerName.required && errors.push('Name is required')
      return errors
    },
    countryErrors () {
      const errors = []
      if (!this.$v.centerCountry.$dirty) return errors
      !this.$v.centerCountry.required && errors.push('Country is required')
      return errors
    },
    genderErrors () {
      const errors = []
      if (!this.$v.studentGender.$dirty) return errors
      !this.$v.studentGender.required && errors.push('Gender is required')
      return errors
    },
    titleErrors () {
      const errors = []
      if (!this.$v.studentTitle.$dirty) return errors
      !this.$v.studentTitle.required && errors.push('Title is required')
      return errors
    },
    ageErrors () {
      const errors = []
      if (!this.$v.studentAge.$dirty) return errors
      !this.$v.studentAge.numeric && errors.push('Only numbers are accepted')
      !this.$v.studentAge.required && errors.push('Age is required')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.centerAddress.$dirty) return errors
      !this.$v.centerAddress.required && errors.push('Address is required')
      return errors
    },
    cityErrors () {
      const errors = []
      if (!this.$v.centerCity.$dirty) return errors
      !this.$v.centerCity.required && errors.push('City is required')
      return errors
    },
    zipcodeErrors () {
      const errors = []
      if (!this.$v.centerZip.$dirty) return errors
      !this.$v.centerZip.required && errors.push('Zip is required')
      return errors
    },
    
    
  },
  validations: {
    centerName: {
      required,
      minLength: minLength(2),
      maxLength:maxLength(20)
    },
    centerCountry: {
      required
    },
    centerAddress: {
      required,
    },
    centerCity: {
      required,
    },
    centerZip: {
      required,
    },
  },
  created() {
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    })
  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
/* 
.v-dialog__content * {
  overflow-y: unset !important;
} */
</style>
