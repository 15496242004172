import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import registerCandidate from './views/registerCandidate.vue';
import transferCandidate from './views/transferCandidate.vue';
import registerAnnouncments from './views/registerAnnouncments.vue';
import registerRef from './views/registerRef.vue';
import registerCenterTutorial from './views/registerCenterTutorial.vue';
import registerCandidateTutorial from './views/registerCandidateTutorial.vue';
import registerAsset from './views/registerAsset.vue';
import Report from './views/Report.vue';
import candidateReport from './views/candidateReport.vue';
import Team from './views/Team.vue';
import Login from './views/Login.vue';
import InstantSearch from 'vue-instantsearch';
import createcourse from './views/createcourse';
import Certificate from './views/certificate.vue';
import certificateAima from './views/certificateAima.vue';
import Card from './views/card.vue';
import CardAima from './views/CardAima.vue';
import manualView from './views/manualView.vue';
import manuals from './views/manuals.vue';
import exam from './views/exam';
import preExam from './views/preExam';
import examMode from './views/examMode';
import Help from './views/help';
import manualsCorporate from './views/manualsCorporate';
import corporateAccount from './views/corporateAccount';
import userAccount from './views/userAccount';
import verification from './views/verification'
import editManuals from './views/editManuals'
import editQuestions from './views/editQuestions'
import questionsOverview from './views/questionsOverview'
import feedback from './views/feedback'
import orderTokens from './views/orderTokens'
import registerCenter from './views/registerCenter';
import registerTrainer from './views/registerTrainer';
import trainersReport from './views/trainersReport';
import trainersReviewReport from './views/trainersReviewReport';
import submitProject from './views/submitProject';
import sessions from './views/sessions';
import sessionsReport from './views/sessionsReport';
import sessionsApproval from './views/sessionsApproval';
import settings from './views/settings'
import tutorials from './views/tutorials'
import tutorialsCandidate from './views/tutorialsCandidate'
import candidateTransfers from './views/candidateTransfers'
import assets from './views/assets'
import references from './views/references'
import news from './views/news'
import presentation from './views/presentation'
import presentationsReport from './views/presentationsReport'
import presentationsApproval from './views/presentationsApproval'
import corporatereport from './views/corporatereport'
import candidateReportAll from './views/candidateReportAll'
import aima from './views/aima'
import aimaUsersList from './views/aimaUsersList'
import aimaUsersListAdmin from './views/aimaUsersListAdmin'
import aimaUserReport from './views/aimaUserReport'
import aimaNewUser from './views/aimaNewUser'
import aimaResult from './views/aimaResult'
import aimaUserResult from './views/aimaResultUser'
import aimacat from './views/aimacat'
import aimaServices from './views/aimaServices'
import aimaApplications from './views/aimaApplications'
import labAccreditation from './views/labAccreditation'
import labApplications from './views/labApplications'
import labApplicationsAdmin from './views/labApplicationsAdmin'
import labVerification from './views/labVerification'
import labCertificate from './views/labCertificate'
import tokenOrders from './views/tokenOrders'
import productAccreditation from './views/productAccreditation'
import productApplications from './views/productApplications'
import productApplicationsAdmin from './views/productApplicationsAdmin'
import productVerification from './views/productVerification'
import productCertificate from './views/productCertificate'
import aimaCandidate from "./views/aimaCandidate";
import aimacatCandidate from "./views/aimacatCandidate";
import aimaResultCandidate from "./views/aimaResultCandidate";
import invoiceReport from "./views/invoiceReport";
import invoiceReportAll from "./views/invoiceReportAll";


Vue.use(Router);
Vue.use(InstantSearch);


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/invoice-report',
      name: 'invoice-report',
      component: invoiceReport
    },
    {
      path: '/invoice-report-all',
      name: 'invoice-report-all',
      component: invoiceReportAll
    },
    {
      path: '/token-orders',
      name: 'token-orders',
      component: tokenOrders
    },
    {
      path: '/news',
      name: 'news',
      component: news
    },
    {
      path: '/aima-user-report/',
      name: 'aima-user-report',
      component: aimaUserReport
    },
    {
      path: '/aima-users-list/',
      name: 'aima-users-list',
      component: aimaUsersList
    },
    {
      path: '/aima-users-list-admin/',
      name: 'aima-users-list-admin',
      component: aimaUsersListAdmin
    },
    {
      path: '/aima-new-user/',
      name: 'aima-user-new',
      component: aimaNewUser
    },
    {
      path: '/aima-candidate/:token?',
      name: 'aima-candidate',
      component: aimaCandidate
    },
    {
      path: '/aima-cat-candidate/:token/:cat',
      name: 'aima-cat-candidate',
      component: aimacatCandidate
    },
    {
      path: '/aima-result-candidate/',
      name: 'aima-result-candidate',
      component: aimaResultCandidate
    },
    {
      path: '/aima/:token?',
      name: 'aima',
      component: aima
    },
    {
      path: '/aima-cat/:token/:cat',
      name: 'aima-cat',
      component: aimacat
    },
    {
      path: '/aima-services/:token/',
      name: 'aima-services',
      component: aimaServices
    },
    {
      path: '/aima-user-result/:token?',
      name: 'aima-user-result',
      component: aimaUserResult
    },
    {
      path: '/aima-result/:token?',
      name: 'aima-result',
      component: aimaResult
    },
    {
      path: '/aima-reports/',
      name: 'aima-reports',
      component: aimaApplications
    },
    {
      path: '/assets',
      name: 'assets',
      component: assets
    },
    {
      path: '/presentation',
      name: 'presentation',
      component: presentation
    },
    {
      path: '/lab-accreditation/:token?/:review?',
      name: 'lab-accreditation',
      component: labAccreditation
    }, 
    {
      path: '/lab-applications',
      name: 'lab-applications',
      component: labApplications
    },
    {
      path: '/lab-applications-admin',
      name: 'lab-applications-admin',
      component: labApplicationsAdmin
    },
    {
      path: '/lab-verification/:token?',
      name: 'lab-verification',
      component: labVerification
    }, 
    {
      path: '/lab-certificate/:token?',
      name: 'lab-certificate',
      component: labCertificate
    },
    {
      path: '/product-accreditation/:token?/:review?',
      name: 'product-accreditation',
      component: productAccreditation
    }, {
      path: '/product-applications',
      name: 'product-applications',
      component: productApplications
    }, {
      path: '/product-applications-admin',
      name: 'product-applications-admin',
      component: productApplicationsAdmin
    }, {
      path: '/product-verification/:token?',
      name: 'product-verification',
      component: productVerification
    }, {
      path: '/product-certificate/:token?',
      name: 'product-certificate',
      component: productCertificate
    },
    {
      path: '/presentations-report',
      name: 'presentationsReport',
      component: presentationsReport
    },
    {
      path: '/presentations-approval',
      name: 'presentationsApproval',
      component: presentationsApproval
    },
    {
      path: '/references',
      name: 'references',
      component: references
    },
    {
      path: '/register-center-tutorials',
      name: 'registerCenterTutorial',
      component: registerCenterTutorial
    },
    {
      path: '/register-candidate-tutorials',
      name: 'registerCandidateTutorial',
      component: registerCandidateTutorial
    },
    {
      path: '/register-ref',
      name: 'registerRef',
      component: registerRef
    },
    {
      path: '/register-asset',
      name: 'registerAsset',
      component: registerAsset
    },
    {
      path: '/register-announcments',
      name: 'registerAnnouncments',
      component: registerAnnouncments
    },
    {
      path: '/register-candidate',
      name: 'registerCandidate',
      component: registerCandidate
    },
    {
      path: '/transfer-candidate',
      name: 'transferCandidate',
      component: transferCandidate
    },
    {
      path: '/tutorials',
      name: 'tutorials',
      component: tutorials
    }, 
    {
      path: '/candidate-transfers',
      name: 'candidateTransfers',
      component: candidateTransfers
    },
    {
      path: '/tutorials-candidates',
      name: 'tutorialsCandidates',
      component: tutorialsCandidate
    },
    {
      path: '/settings',
      name: 'settings',
      component: settings
    },
    {
      path: '/register-center',
      name: 'registerCenter',
      component: registerCenter
    },
    {
      path: '/exam-session',
      name: 'examsessions',
      component: sessions
    },
    {
      path: '/exam-session-report',
      name: 'sessionsReport',
      component: sessionsReport
    }, 
    {
      path: '/exam-session-approval',
      name: 'sessionsapproval',
      component: sessionsApproval
    }, 
    {
      path: '/trainer',
      name: 'registerTrainer',
      component: registerTrainer
    },
    {
      path: '/trainers-review-report',
      name: 'trainersReviewReport',
      component: trainersReviewReport
    },
    {
      path: '/corporatereport',
      name: 'corporatereport',
      component: corporatereport
    },
    {
      path: '/candidates-all-centers',
      name: 'candidateReportAll',
      component: candidateReportAll
    },
    { 
      path: '/trainer-report',
      name: 'trainersReport',
      component: trainersReport
    },
    {
      path: '/submit-project',
      name: 'submitProject',
      component: submitProject
    },
    {
      path: '/team',
      name: 'team',
      component: Team
    }, 
    {
      path: '/report',
      name: 'report',
      component: candidateReport
    }, 
    {
      path: '/create-course',
      name: 'create-course',
      component: createcourse
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    { 
      path: '/certificate/:level/',
      name: 'certificate',
      component: Certificate
    },
    {
      path: '/certificateAima/:level/',
      name: 'certificateAima',
      component: certificateAima
    },
    {
      path: '/card/:level/',
      name: 'card',
      component: Card
    },
    {
      path: '/cardAima/:level/',
      name: 'cardAima',
      component: CardAima
    },
    {
      path: '/manual/:level/:id',
      name: 'manual',
      component: manualView
    },
    {
      path: '/manuals/:level',
      name: 'manuals',
      component: manuals
    },
    {
      path: '/exam/:type/:level/:view',
      name: 'preexam',
      component: preExam
    },
    {
      path: '/exam-mode/:type/:level',
      name: 'exammode',
      component: examMode
    },
    {
      path: '/verification/:token/',
      name: 'verification',
      component: verification
    },
    {
      path: '/edit-manuals',
      name: 'edit-manuals',
      component: editManuals
    },
    {
      path: '/edit-questions/:token?',
      name: 'edit-questions',
      component: editQuestions
    },
    {
      path: '/questions-overview',
      name: 'questions-overview',
      component: questionsOverview
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/manuals-overview/:level',
      name: 'manuals-overview',
      component: manualsCorporate
    }, 
    {
      path: '/corporate-account',
      name: 'corporate-account',
      component: corporateAccount
    },
    {
      path: '/account',
      name: 'account',
      component: userAccount
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: feedback
    },
    {
      path: '/order-tokens',
      name: 'orderTokens',
      component: orderTokens
    }
  ]
})
