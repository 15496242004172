<template>
  <v-layout row wrap>
    <v-flex v-if="preaccess" xs12 md4 pa-1>
      <v-card @load="getPreassessment()">
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">Pre-Assessment</div>
            <div class="icon">
              <v-icon color="secondary">assessment</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center row layout ma-0">
            <v-progress-circular
              :size="150"
              :width="15"
              :rotate="-90"
              :value="prescore"
              :color="prestart ? prepass ? 'success' : 'error' : ''"
            >
              
              <div 
                class="headline text-md-center" 
                text-color="prestart ? prepass ? 'success' : 'error' : ''">
              {{  parseInt(prescore) }}%</div>
              <div 
                class="headline text-md-center" 
                text-color="prestart ? prepass ? 'success' : 'error' : ''">
                {{ prestart ? prepass ? 'Pass' : 'Fail' : '' }}
              </div>
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!prelock" outline color="success" @click="goTo('/exam/preassessment/basic/exam')" dark>start</v-btn>
          <v-btn v-if="predone" outline color="success" @click="goTo('/exam/preassessment/basic/result')" dark>review result</v-btn>
        </v-card-actions>
      </v-card>     
    </v-flex>
    <!-- START MAT BASIC Progress -->
    <v-flex v-if="materialaccess" xs12 :md4="preaccess" :md6="!preaccess" pa-1 @load="getMaterialProgress">
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">Basic IAIDL {{title}}</div>
            <div class="icon">
              <v-icon :color="color">{{icon}}</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center row layout ma-0">
            <v-progress-circular
              :size="150"
              :width="15"
              :rotate="-90"
              :value="getOverallProgress"
              :color="color"
            >
              {{ parseInt(getOverallProgress) }}
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
          outline 
          @click="goTo('/manuals/basic')" 
          color="primary" 
          dark>
            <!-- {{ (getOverallProgress<100) ? 'continue' : 'review'}} -->
            <span v-if="getOverallProgress < 100">continue</span>
            <span v-else>review</span>
          </v-btn>
        </v-card-actions>
      </v-card>     
    </v-flex>
    <!-- END MAT BASIC Progress -->
    <!-- START EXAM BASIC Progress -->
    <v-flex v-if="examaccess" xs12 :md4="preaccess" :md6="!preaccess" pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">{{ prepass ? examPass ? 'Exam' : 'Exam' : 'Certification' }}</div>
            <div class="icon">
              <v-icon :color="prepass ? examDone ? examPass ? 'success' : 'error' : 'success' : 'error'">assignment</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center text-xs-center row layout ma-0">
            <v-progress-circular
              :size="150"
              :width="15"
              :rotate="-90"
              :value="100"
              :color="prepass ? examDone ? examPass ? 'success' : 'error' : 'success' : 'error'"
            >
              <v-icon 
                v-if="examPass" 
                x-large 
                :color="prepass ? examDone ? examPass ? 'success' : 'error' : 'success' : 'error'">
                done
              </v-icon>
              <div 
                class="headline text-xs-center" 
                :text-color="prepass ? examDone ? examPass ? 'success' : 'error' : 'success' : 'error'">
                {{ prepass ? examDone ? examPass ? 'Pass' : 'Fail' : 'Eligible' : 'Not Eligible' }}
              </div>
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider v-if="prepass && !examDone || examPass"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            outline 
            color="success" 
            v-if="prepass && !examDone" 
            @click="goTo('/exam/exam/basic/exam')" 
            dark
          >
            Start Certification
          </v-btn>
          <v-btn 
            outline 
            color="success" 
            v-if="examPass" 
            @click="goTo('/certificate/basic')" 
            dark
          >
            Certificate
          </v-btn>
        </v-card-actions>
      </v-card>     
    </v-flex>
    <!-- END EXAM BASIC Progress -->



    <!-- START AIMA PRE -->

    <v-flex v-if="aimapreAssesAccess" xs12 md4 pa-1>
      <v-card @load="getPreassessment()">
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">AIMA Mock Exam</div>
            <div class="icon">
              <v-icon color="secondary">assessment</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center row layout ma-0">
            <v-progress-circular
                :size="150"
                :width="15"
                :rotate="-90"
                :value="prescoreAIMA"
                :color="prestartAIMA ? prepassAIMA ? 'success' : 'error' : ''"
            >

              <div
                  class="headline text-md-center"
                  text-color="prestartAIMA ? prepassAIMA ? 'success' : 'error' : ''">
                {{  parseInt(prescoreAIMA) }}%</div>
              <div
                  class="headline text-md-center"
                  text-color="prestartAIMA ? prepassAIMA ? 'success' : 'error' : ''">
                {{ prestartAIMA ? prepassAIMA ? 'Pass' : 'Fail' : '' }}
              </div>
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!prelockAIMA" outline color="success" @click="goTo('/exam/preassessment/aimaassessor/exam')" dark>start</v-btn>
          <v-btn v-if="predoneAIMA" outline color="success" @click="goTo('/exam/preassessment/aimaassessor/result')" dark>review result</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <!-- END AIMA PRE -->
    <!-- START MAT AIMA Progress DODODOD -->
    <v-flex v-if="aimamataccess" xs12 :md4="aimapreaccess" :md6="!aimapreaccess" pa-1 @load="getAimaMaterialProgress">
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">AIMA Manuals</div>
            <div class="icon">
              <v-icon :color="color">{{icon}}</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center row layout ma-0">
            <v-progress-circular
                :size="150"
                :width="15"
                :rotate="-90"
                :value="this.aimaProgress"
                :color="color"
            >
              {{ parseInt(this.aimaProgress) }}
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              outline
              @click="goTo('/manuals/AIMA')"
              color="primary"
              dark>
            <!-- {{ (this.aimaProgress<100) ? 'continue' : 'review'}} -->
            <span v-if="this.aimaProgress < 100">continue</span>
            <span v-else>review</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex v-if="aimamataccess" xs12 :md4="aimapreaccess" :md6="!aimapreaccess" pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">AIMA Supportive Manuals</div>
            <div class="icon">
              <v-icon :color="color">{{icon}}</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center row layout ma-0">
            <v-progress-circular
                :size="150"
                :width="15"
                :rotate="-90"
                :value="100"
                :color="color"
            >
              {{ parseInt(100) }}
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              outline
              @click="goTo('/manuals/AIMA_Supportive')"
              color="primary"
              dark>
            continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <!-- END MAT AIMA Progress -->

    <!-- START EXAM AIMA Progress -->
    <v-flex v-if="aimaexamaccess" xs12 :md4="aimapreAssesAccess" :md6="!aimapreAssesAccess" pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">{{ examPass ? aimaExamPass ? 'AIMA Exam' : 'AIMA Exam' : 'Certification' }}</div>
            <div class="icon">
              <v-icon :color="examPass ? aimaExamDone ? aimaExamPass ? 'success' : 'error' : 'success' : 'error'">assignment</v-icon>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="justify-center text-xs-center row layout ma-0">
            <v-progress-circular
                :size="150"
                :width="15"
                :rotate="-90"
                :value="100"
                :color="examPass ? aimaExamDone ? aimaExamPass ? 'success' : 'error' : 'success' : 'error'"
            >
              <v-icon
                  v-if="examPass"
                  x-large
                  :color="examPass ? aimaExamDone ? aimaExamPass ? 'success' : 'error' : 'success' : 'error'">
                done
              </v-icon>
              <div
                  class="headline text-xs-center"
                  :text-color="examPass ? aimaExamDone ? aimaExamPass ? 'success' : 'error' : 'success' : 'error'">
                {{ examPass ? aimaExamDone ? aimaExamPass ? 'Pass' : 'Fail' : 'Eligible' : 'Not Eligible' }}
              </div>
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-divider v-if="examPass && !aimaExamDone || aimaExamPass"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              outline
              color="success"
              v-if="examPass && !aimaExamDone"
              @click="goTo('/exam/exam/aimaassessor/exam')"
              dark
          >
            Start AIMA Certification
          </v-btn>
          <v-btn
              outline
              color="success"
              v-if="aimaExamPass"
              @click="goTo('/certificateAima/aimaassessor')"
              dark
          >
            Certificate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>

    <!-- END EXAM AIMA Progress -->

<!-- user code section START -->

    <v-flex xs12 pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">User Code:</div>
          </div>
        </v-card-title>
        <div class="justify-center text-xs-center row layout ma-0">
          <span style="font-size: 32px;font-weight: bold;">{{this.userCode}}</span>
        </div>
      </v-card>
    </v-flex>
<!-- user code section END -->

<!-- user Badge section START -->
    <v-flex xs12 pa-1>
      <v-card>
        <v-card-title>
          <div class="layout row ma-0 justify-space-between pb-1">
            <div class="subheading">User Badges:</div>
          </div>
        </v-card-title>
        <div class="justify-center text-xs-center row layout ma-0" style="padding-bottom: 50px;">
<div style="width: 25%;display: inline-block;vertical-align: middle;">
    <img src="http://www.iaidl.org/badges/basic.png" style="width: 80%;" />
    <a href="">
      <button type="button" style="width: 50%;padding: 5px;background: #2375b7;color: #FFF;">Share</button>
    </a>




</div>


<div style="height:50px;"></div>
        </div>
      </v-card>
    </v-flex>
<!-- user Badge section END -->


  </v-layout>










</template>










<script>
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  data: () => ({
    userCode:'Loading',
    preassessment:null,
    icon: 'school',
    title: 'Manuals',
    subTitle: 'Progress',
    caption: '/8 modules',
    value: 30,
    aimaProgress: 30,
    color: 'primary'
  }),
  computed: {
    materialaccess(){
      return  this.$store.state.userData ? this.$store.state.userData.access.basic.material : null
    },
    aimamataccess(){
      return  this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : null
    },
    examaccess(){
      return  this.$store.state.userData ? this.$store.state.userData.exam.basic.access : null
    },
    aimaexamaccess(){
      return  this.$store.state.userData ? this.$store.state.userData.exam.aimaassessor.access : null
    },
    examDone(){
      let xr = this.$store.state.userData ? this.$store.state.userData.exam.basic.exit : null
      return  xr
    },
    aimaExamDone(){
      let xs = this.$store.state.userData ? this.$store.state.userData.exam.aimaassessor.exit : null
      return  xs
    },
    examPass(){
      let xw = this.$store.state.userData ? this.$store.state.userData.exam.basic.pass : null
      return  xw
    },
    aimaExamPass(){
      let xt = this.$store.state.userData ? this.$store.state.userData.exam.aimaassessor.pass : null
      return  xt
    },
    prelock(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.started : null
    },
    prelockAIMA(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.aimaassessor.started : null
    },
    preaccess(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.access : null
    },
    aimapreAssesAccess(){
      return  this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.preassessment : false
    },
    aimapreaccess(){
      return  this.$store.state.userData ? this.$store.state.userData.access.aimaassessor.material : false
    },
    prescore(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.score : null
    },
    prescoreAIMA(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.aimaassessor.score : null
    },
    prepass(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.pass : null
    },
    prepassAIMA(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.aimaassessor.pass : null
    },
    prestart(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.started : null
    },
    prestartAIMA(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.aimaassessor.started : null
    },
    predone(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.basic.done : null
    },
    predoneAIMA(){
      return  this.$store.state.userData ? this.$store.state.userData.preassessment.aimaassessor.done : null
    },
    getMaterialProgress(){
      let materialProgress = this.$store.state.userData ? this.$store.state.userData.material.basic : null
      if (!materialProgress) return true
      this.$store.state.manuals.forEach(manual => {
        let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false
        let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
        manual.progress = {
          started,
          progress
        }
      });
      return true
    },
    getAimaMaterialProgress(){
      let materialProgress = this.$store.state.userData ? this.$store.state.userData.material.aimaassessor : null
      if (!materialProgress) {
        return true
      }
      /*
      this.$store.state.aimamanuals.forEach(manual => {
        let started = materialProgress[manual.short] ? materialProgress[manual.short].started : false
        let progress = materialProgress[manual.short] ? (materialProgress[manual.short].progress / manual.urls.length) * 100 : 0
        manual.progress = {
          started,
          progress
        }
      });
      */
      return true
    },
    getOverallProgress () {
      //let materialProgress = this.$store.state.userData ? this.$store.state.userData.material.basic : false
      let progress = 0
      let totLength = 0
      this.$store.state.manuals.forEach(manual => {
        let thsLvl = manual.level
        if(thsLvl == 'basic'){
          let thProg = parseFloat(manual.progress.progress)
          if(isNaN(thProg))
            thProg = 0
          progress += thProg
          totLength++
        }

      });
      //totLength = parseInt(this.$store.state.manuals.length)


      let res = parseInt(progress / 8)
      if(isNaN(res))
        res= 0
      return res

    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  },
  async created() {
    let thsUID = this.$store.state.userData.uid
    let thsID = Math.floor((Math.random() * 3999) + 1000) + '-' + Math.floor((Math.random() * 999) + 100);
    let existID = ''
    await db.ref('candidates/' + thsUID + '/user_code').once("value").then(function(dataSnapshot) {
      existID = dataSnapshot.val()
    })
    if(existID){
      //DO nothing
      thsID = existID
      this.userCode = thsID
    } else {
      //update user code
      await db.ref('candidates/' + thsUID ).update({ user_code: thsID })
      this.userCode = thsID
    }

      let aimaEN;
      let aimaAR;
    await db.ref('candidates/' + thsUID + '/material/aimaassessor/aimaass').once("value").then(function(dataSnapshot) {
      aimaEN = dataSnapshot.val()
    })
    await db.ref('candidates/' + thsUID + '/material/aimaassessor/aimaassar').once("value").then(function(dataSnapshot) {
      aimaAR = dataSnapshot.val()
    })
      console.log("Progress===")
      console.log(aimaEN.progress)
      console.log(aimaAR.progress)
      let ARp = parseInt( aimaAR.progress );
      let ENp = parseInt( aimaEN.progress );
      let tot = ( ARp + ENp ) / 2;
      tot = tot *2;
      if( tot > 100 ){
        tot = 100
      }
      this.aimaProgress = tot;


  }
}
</script>