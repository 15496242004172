<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <v-layout wrap row justify-start >
        <v-flex xs12 md12 px-1>
          
          <v-card class="elevation-24 ">
            <v-img
              class="white--text"
              height="100px"
              src="https://wallpaperstudio10.com/static/wpdb/wallpapers/3840x2160/171423.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox class="text-md-center">
                    <span class="headline">IAIDL<sup>®</sup> Exam Entry</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-layout wrap row justify-start ma-3>
              <v-flex xs12 md12 pa-1>
                <v-card>
                  <v-card-title>
                    <div class="layout row ma-0 justify-space-between pb-1">
                      <div class="subheading">Exam</div>
                      <div class="icon">
                        <v-icon color="success">assessment</v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text :class="`${(!done?'pb-0 pt-0':null)}`">
                    <div v-if="done" class="justify-center row layout ma-0">
                      <v-progress-circular
                        :size="150"
                        :width="15"
                        :rotate="-90"
                        :value="100"
                        color="success"
                      >
                        <v-icon x-large color="success">done</v-icon>
                        <div class="headline" text-color="success">Pass</div>
                      </v-progress-circular>
                    </div>
                    <div v-if="!done">
                      <v-layout row wrap my-0>
                        <v-flex xs12>
                          <h1>Exam Instructions</h1>
                        </v-flex>
                        <v-flex xs12>
                          <span>
                            Exam Duration: 30 minutes <br>
                            Number of Questions: 45 <br>
                            Number of Questions: 45 <br>
                            Number of Questions: 45 <br>
                            Number of Questions: 45 <br>
                            Number of Questions: 45 <br>
                            Number of Questions: 45 <br>
                          </span>
                          <v-checkbox color="primary" label="I acknowledge that I read and understand the exam instructions and ready to start the exam" v-model="acknowledge"></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn 
                      color="primary"
                      dark
                      v-if="!done && acknowledge"
                      @click="goTo('/exam-mode')" 
                    >
                      Start Exam
                      <v-icon right>play_arrow</v-icon>
                      </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn outline v-if="done" color="success" @click="goTo('/certificate')" dark>Certificate</v-btn>
                  </v-card-actions>
                </v-card>     
              </v-flex>
            </v-layout>
            <!-- <v-divider></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  props: ['isLogin'],
  data: ()=> ({
    done:false,
    acknowledge:false,
  }),
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  },

}

</script>






