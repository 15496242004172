<template>
  <div>
    <v-card  class="mb-0">
      <v-img
        class="white--text"
        height="100px"
        src="https://portal.iaidl.org/tback.jpg"
      >
        <v-container fill-height fluid>
          <v-layout fill-height>
            <v-flex xs12 align-end flexbox class="text-xs-center">
              <span class="font-weight-black headline">AIMA</span>
              <span><sup>®</sup> | {{cat.toUpperCase().replace(/_/g, ' ')}}</span>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 v-if="aimacat.length > 0">
            <v-stepper v-model="stepperValue" non-linear>
              <v-flex xs12 class="text-xs-center" pt-3>
                <span class="title">{{stepperValue}} of {{aimacat.length}}</span>
              </v-flex>
              <v-flex xs12 class="text-xs-center" pt-3 px-5>
                <v-slider v-model="stepperValue" thumb-label="always" :max="aimacat.length" ticks step="1"></v-slider>
              </v-flex>
              <v-stepper-header style="height: fit-content;display:none;">
                <v-stepper-step v-for="(r,k) in aimacat" :step="k+1" :key="k+1" :complete="stepperValue > aimacat.length-1" editable></v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content v-for="(r,k) in aimacat"  :step="k+1" :key="k+1">
                  <v-card>
                    <v-card-actions v-if="stepperValue >= aimacat.length">
                      <v-btn color="primary" outline @click.native="goTo(`/aima/${token}`)">back to aima</v-btn>
                    </v-card-actions>
                    <v-card-title primary-title>
                      <v-layout row wrap>
                          <span style="font-size:14px;font-weight:300;line-height: 33px;">
                            {{'transformation__element'.replace(/_/g, ' ') | capitalfirst}}:
                          </span>  
                          <span style="font-size:14px;font-weight:700;border: 1px solid #0006;border-radius: 2px;margin: 0 5px;padding:5px;    line-height: 26px;" class="success--text">
                            {{r['transformation__element'].toUpperCase()}}
                          </span>   
                          <span style="font-size:18px;line-height:33px;letter-spacing: -3px;font-weight:300;padding-left:10px;padding-right:10px;">  >>>  </span>
                          <span style="font-size:14px;font-weight:300;line-height: 33px;">
                            {{'transformation__sub-element'.replace(/_/g, ' ') | capitalfirst}}:
                          </span> 
                          <span style="font-size:14px;font-weight:700;border: 1px solid #0006;border-radius: 2px;margin: 0 5px;padding:5px;    line-height: 26px;" class="success--text"> 
                            {{r['transformation__sub-element'].toUpperCase()}}
                          </span>
                          <span style="font-size:18px;line-height:33px;letter-spacing: -3px;font-weight:300;padding-left:10px;padding-right:10px;">  >>>  </span>
                          <span style="font-size:14px;font-weight:300;line-height: 33px;">
                            {{'assessment__category'.replace(/_/g, ' ') | capitalfirst}}:
                          </span>  
                          <span style="font-size:14px;font-weight:700;border: 1px solid #0006;border-radius: 2px;margin: 0 5px;padding:5px;    line-height: 26px;" class="error--text">
                            {{r['assessment__category'].toUpperCase()}}
                          </span>   
                      </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-layout row wrap class="pa-2">
                      <v-flex xs12 md6 px-1>
                        <v-radio-group 
                          @change="updateValueAIMA(cat, k ,'matrix', r.matrix, r.itemno)"
                          v-model="r.matrix"
                          @load="getEx(r)"
                        >
                          <div class="elevation-2 pa-3 mb-2">
                            <v-radio label="Existance" value="existance"></v-radio>
                            <span style="font-size:11px;padding:10px 0;">What {{r['transformation__sub-element'].toUpperCase()}} are in place to support the AI and carry out the work, and to what extent is {{r['transformation__sub-element'].toUpperCase()}} formally defined?</span>
                          </div>
                          <div class="elevation-2 pa-3 mb-2">
                            <v-radio label="Appropirate" value="appropirate"></v-radio>
                            <span style="font-size:11px;padding:10px 0;">To what extent is {{r['transformation__sub-element'].toUpperCase()}} the most appropriate and relevant choice for the situation and strategy, and the Tool's needs?</span>
                          </div>
                          <div class="elevation-2 pa-3 mb-2">
                            <v-radio label="Utlization" value="utlization"></v-radio>
                            <span style="font-size:11px;padding:10px 0;">To what extent is {{r['transformation__sub-element'].toUpperCase()}} being used (and managed) effectively to support the AI and Future Tools and its goals?</span>
                          </div>
                          <div class="elevation-2 pa-3 mb-2">
                            <v-radio label="AI services (implmented)" value="implmented"></v-radio>
                            <span style="font-size:11px;padding:10px 0;">In what ways, and to what extent, is the use of {{r['transformation__sub-element'].toUpperCase()}} achieving its intended outcomes and results?</span>
                          </div>
                        </v-radio-group>
                      </v-flex>
                      <v-flex xs12 md6 px-1>
                        <v-layout row wrap class="pa-2 mt-4">
                          <v-flex xs12 v-for="ex in selectedRating" :key="ex.score" class="elevation-1" pa-2 mb-1>
                            <v-layout row wrap>
                              <v-flex xs2 md1 px-1>
                                {{ex.score}}
                              </v-flex>
                              <v-flex xs10 md11 px-1>
                                {{ex.int.replace(/X/g, r['transformation__sub-element'].toUpperCase())}}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-card-title primary-title>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <span style="font-size:13px;font-weight:300;">
                            {{'subject_area'.replace(/_/g, ' ') | capitalfirst}}:
                          </span>  
                          <span style="font-size:13px;font-weight:700;" class="error--text">
                            <v-chip small label>{{r['subject_area'].toUpperCase()}}</v-chip>
                          </span>   
                        </v-flex>
                        <v-flex xs12>
                          <v-switch 
                            @change="updateValueAIMA(cat, k ,'readiness', r.readiness, r.itemno)"
                            label="Readiness" inset color="success" v-model="r.readiness"></v-switch>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-title primary-title>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <span style="font-size:13px;font-weight:300;">
                            {{'Question To Answer'.replace(/_/g, ' ') | capitalfirst}}:
                          </span>  
                          <span style="font-size:13px;font-weight:700;" class="error--text">
                            <v-chip small label>{{r['question']}}</v-chip>
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <span style="font-size:13px;font-weight:300;">
                            {{'Method of Assessment That Should Be Used'.replace(/_/g, ' ') | capitalfirst}}:
                          </span>  
                          <span style="font-size:13px;font-weight:700;" class="error--text">
                            <v-chip small label>{{r['method__of__assessment']}}</v-chip>
                          </span>
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-select
                            label="Assessment Period"
                            outline
                            :items="[
                              'Annually', 
                              'Biannually',
                              'Quarterly'
                            ]"
                            @change="updateValueAIMA(cat, k ,'timeframe', r.timeframe, r.itemno)"
                            v-model="r.timeframe"
                            clearable
                            hide-details
                          ></v-select>
                        </v-flex>
                        <v-flex xs6>
                          <v-textarea
                            label="Answers"
                            outline
                            hide-details
                            @blur="updateValueAIMA(cat, k ,'answers', r.answers, r.itemno)"
                            v-model="r.answers"
                          ></v-textarea>
                        </v-flex>
                        <v-flex xs6>
                          <v-layout row wrap v-if="r.evidence && r.evidence.length">
                            <v-flex xs12 v-for="(ev, ee) in r.evidence" :key="ee+'-ev'">
                              <v-btn outline color="primary" small :href="ev">Supporting evidence #{{ee+1}}</v-btn>
                            </v-flex>
                          </v-layout>
                          <input
                            type="file"
                            style="display: none"
                            ref="file"
                            @change="onFilePicked"
                          >
                          <v-btn color="success"
                            @click="$refs.file[stepperValue].click()">add a supporting evidence</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-title primary-title>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            label="Rating"
                            outline
                            @blur="updateValueAIMA(cat, k ,'rating', r.rating, r.itemno)"
                            v-model="r.rating"
                            clearable
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions v-if="stepperValue < aimacat.length">
                      <v-btn color="primary" outline @click.native="stepperValue--">back</v-btn>
                      <v-btn color="primary" @click.native="stepperValue++">next</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>                
              </v-stepper-items>
            </v-stepper>
          </v-flex>
        </v-layout>
      </v-card-text>
        
    </v-card>
  </div>
</template>

<script>
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';

export default {
  data() {
    return {
      loading:false,
      cat:null,
      aimacat:[],
      stepperValue:0,
      token:null,
      imgUploadProduct:null,
      uploadProgress:null,
      file:null,
      name:null,
      fileName:null,
      upfiles:{},
      ratingEx:{
        existance:{
          a:{
            score:'0-2',
            int:`X is either nonexistent, of such a small scale, or so lacking in definition, as to not have a material impact on the business or its ability to conduct anAi and Future Tools.`,
          },
          b:{
            score:'3-5',
            int:`X is present but on a limited scale, and is defined but to a limited extent.  As such, it can play a role in theAi and Future Tools but that role will be minor and its influence limited.`,
          },
          c:{
            score:'6-8',
            int:`X has a clear existence in the organization, with clear, tangible definition.  It plays a strong role in the business and has the capacity to influence theAi and Future Tools to a moderate extent.`,
          },
          d:{
            score:'9-10',
            int:`X has a powerful existence in the organization, with clear, highly tangible definition.  It plays a significant role in the business and has the capacity to influence theAi and Future Tools in highly measurable ways.`,
          },
        },
        appropirate:{
          a:{
            score:'0-2',
            int:`X is either nonexistent or for whatever reason entirely irrelevant and inappropriate to theAi and Future Tools.`,
          },
          b:{
            score:'3-5',
            int:`X is present but has very little relevance to theAi and Future Tools.  This means it is not the most appropriate resource for the program… there are clearly more appropriate options the organization can choose from.`,
          },
          c:{
            score:'6-8',
            int:`X is very relevant to theAi and Future Tools and its goals.  In most cases - though not all - X is an appropriate resource.  In some cases, it will not be the most appropriate resource.  In those cases, there are other options that would prove more appropriate.`,
          },
          d:{
            score:'9-10',
            int:`X is extremely relevant to the Ai and Future Tools and its goals, and in all cases is the most appropriate resource the organization can choose (among competing options).`,
          },
        },
        utlization:{
          a:{
            score:'0-2',
            int:`Though present and potentially appropriate, X is, for whatever reason, not being used and/or managed effectively at all, meaning it is having little or no impact on theAi and Future Tools.`,
          },
          b:{
            score:'3-5',
            int:`Though present and potentially appropriate, X is, for whatever reason, being used and/or managed less effectively that it can and should be.  This means it is not having the impact on the Ai and Future Tools that it could and should be having.`,
          },
          c:{
            score:'6-8',
            int:`X is being used and managed very effectively.  Though there is still room for greater impact, its current impact on the Ai and Future Tools is felt clearly and is helping to move the program in the right direction.`,
          },
          d:{
            score:'9-10',
            int:`X is being used and managed as effectively as it possibly can be.  It is having an enormous impact on the Ai and Future Tools, and is helping to drive the program toward world-class status.`,
          },
        },
        implmented:{
          a:{
            score:'0-2',
            int:`X is achieving none of its objectives whatsoever, or is achieving so few and so poorly as to have no impact on our organization.  X is even potentially having a negative impact on the organization or theAi and Future Tools.`,
          },
          b:{
            score:'3-5',
            int:`X is achieving some of its objectives, but not enough to have an observable impact on the organization.`,
          },
          c:{
            score:'6-8',
            int:`X is achieving most of its objectives (and doing so well).  As a result, it is having a noticeable impact on the organization and continues to help move theAi and Future Tools in the right direction.`,
          },
          d:{
            score:'9-10',
            int:`X is achieving all or nearly all of its objectives in a stellar manner.  As a result, it is having a measurable impact on the organization and continues helping it and its Ai and Future Tools to move toward world-class status.`,
          },
        }
      },
      selectedRating:[],
    }
  },
  methods: {
    getEx(r){
      this.selectedRating = this.ratingEx[r]
    },
    async getAimaCat(cat){
      console.log("CATTER")
      console.log(cat)
      
      this.aimacat = await db.ref(`settings/aima/${cat}`).once('value').then(x=>x.val())
    },
    goTo(path) {
      this.$router.push(path)
    },
    loadFile(filerr, type, ext){
      let file = filerr
      let metadata = {
        contentType: type
      };
      let uploadTask = storageRef.child('productimages/iaidl' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
					this.uploadProgress = progress
                }, (error) =>{
        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) =>{
          if(!this.aimacat[this.stepperValue-1].evidence) this.aimacat[this.stepperValue-1].evidence = []
          this.aimacat[this.stepperValue-1].evidence.push(downloadURL)
          await db.ref(`aima/${this.token}/aima/${this.cat}/${this.stepperValue-1}/`).update({
            evidence:this.aimacat[this.stepperValue-1].evidence
          }) 
          console.log(this.aimacat[this.stepperValue])
					this.imgUploadProduct = false
					this.uploadProgress = 100
        });
      });
    },
    onFilePicked (e) {
      this.imgUploadProduct = true
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } else {
        this.name = null
        this.fileName = null
      }
    },
    async updateValueAIMA(g, i, k, v, n) {
      if(k == 'matrix') {
        this.getEx(v)
      }
      console.log(g)
      console.log(i)
      console.log(k)
      console.log(v)
      await db.ref(`aima/${this.token}/aima/${g}/${i}/`).update({
        itemno:n,
        [k]:v
      })
      this.$emit('snackbarNotification', `Stored successfully`, 'success')
    },
    async getAIMA(token, cat){
      let oAIMA = await db.ref(`aima/${token}/aima/${cat}`).once('value').then(x=>x.val())
      console.log(oAIMA)
      if(oAIMA){
        for (let i = 0; i < oAIMA.length; i++) {
          const o = oAIMA[i];
          for (const k in o) {
            if (o.hasOwnProperty(k)) {
              const val = o[k];
              this.aimacat[i][k] = val
            }
          }
          if(!this.aimacat[i].evidence) this.aimacat[i].evidence = []
          console.log(this.aimacat[i])
        }
      }
    }
  },
  computed:{
    
  },
  created(){
    if(this.$route.params.token && this.$route.params.cat) {
      this.token = this.$route.params.token
      this.cat = this.$route.params.cat
      this.getAimaCat(this.cat)
      this.getAIMA(this.token, this.cat)
		} else {
      this.goTo('/aima')
    }
  }
}
</script>

<style>

</style>
