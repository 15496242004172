<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
            class="white--text"
            height="100px"
            src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Add Announcment</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
          <v-flex xs12 sm12 px-1 my-1>
            <v-text-field
                label="Token"
                readonly
                v-model="token"
                solo-inverted
                suffix="Token"
                :loading="tokenLoading"
                disabled
                light
                class="tokenField"
                color="white"
                background-color="primary"
            ></v-text-field>
          </v-flex>

          <v-flex xs8 sm12 px-1 my-1>
            <v-textarea
                label="Details"
                v-model="annouceDetails"
                solo
                clearable
                required
                multi-line
                :error-messages="nameErrors"
                @blur="$v.annouceDetails.$touch()"
                :disabled="noToken"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 sm12 my-1>
            <v-card-actions>
              <span v-if="dberror" class="red--text">{{ dberror }}</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="isLoading" :disabled="noToken" :dark="false" @click="submit()"  large>
                Add Announcement
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase } from '@/fb';

export default {
  mixins: [validationMixin],
  props: ['userName'],
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      dialog:false,
      isLoading:false,
      annouceDetails:"",
      dberror:null,
      dbsuccess:null,
      printBtn:true,
      tokenKey:null,
      tokenCode:null,
      tokenLoading:true
    }
  },
  methods: {
    submit() {
      console.log('submit!')
      console.log(this.annouceDetails)
      this.$v.$touch()
      if (this.noToken) {
        return
      }
      if (this.$v.$invalid) {
        console.log('errors there')
      } else {

        let sss = `IAIDL_ANC_${randomString(8, 'aA#')}`
        let announce = {
          date:this.date,
          title:this.annouceDetails,
        }
        db.ref('announcements/candidates/').push(announce)
            .then(x=>{
              this.goTo('/')
            })


      }
    },
    goTo(path) {
      this.$router.push(path)
    },

  },
  watch: {

  },
  computed: {
    token() {
      this.tokenLoading = true
      let vtoken = `IAIDL_ANC_${randomString(8, 'aA#')}`
      this.tokenCode = vtoken
      this.tokenLoading = false
      this.noToken = false
      return vtoken
    },
    nameErrors () {
      const errors = []
      return errors
    },
  },
  validations: {
    annouceDetails: {
      required
    }
  },
  created() {

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
