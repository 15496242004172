<template>
  <div class="dashboard" v-if="isLogin? isLogin: null">
    <v-container class="my-5">
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      
      <v-card 
      v-for="(invoice,i) in invoices"
      :key="i"
      >
        <v-layout row wrap ma-2 pa-2>
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Token</div>
            <div >{{ invoice.invoice_id }}</div>
          </v-flex>
          
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Invoice Date</div>
            <div >{{ invoice.invoice_date }}</div>
          </v-flex>
          
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Invoice Amount</div>
            <div >{{ invoice.invoice_amount }}</div>
          </v-flex>
          
          <v-flex xs6 sm3 md3>
            <div class="caption grey--text ">Invoice Status</div>
            <div >{{ invoice.invoice_status }}</div>
          </v-flex>
          
            <v-card-actions dense>
              <v-spacer></v-spacer>
              <a :href="'https://portal.iaidl.org/checkout/view_invoice.php?id=' +  invoice.invoice_id + '&center_uid=' + centerUid " target="_blank" v-if="invoice.invoice_status == 'pending_payment'" style="margin-right:20px;"><v-btn color="primary" dark small @click="console.log(invoice)">PAY NOW</v-btn></a>
              <a :href="'https://portal.iaidl.org/checkout/print_invoice.php?id=' +  invoice.invoice_id + '&center_uid=' + centerUid " target="_blank"><v-btn color="primary" dark small @click="console.log(invoice)">Printable Copy</v-btn></a>
            </v-card-actions>
        </v-layout>
      </v-card>
      
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb'
import axios from 'axios';
export default {
  props: {
    userName: {
    required: true,
    default: 'IAIDL'
    },
    userImage: {
    required: true,
    default: ''
    },
    userData: {
    required: true,
    },
    isLogin: {
    required: true, 
    }
  },
  data() {
    return {
      date: new Date().toISOString(),
      center:null,
      fromDate:null,
      toDate:null,
      program:null,
      allinvoices: [],
      dialog: {
        id:[]
      },
      Qdialog:false,
      question:null,
      filteredInvoices:[],
      paginateInvoices: [],
      invoices: [],
      firstload:true,
      nselectedStatus:[],
      nselect:["done"],
      selectedStatus:[],
      searchText:'',
      centerUid:null,
      loading:false,
      bMaterialProgress:0,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      notes:'',
      steps:[]
    }
  },
  watch: {
      $route (to, from){
        this.program = this.$route.query.program;
      }
  },
  methods: {
    openDoc(url){
      window.open(url)
    },
    goTo(path) {
      this.$router.push(path)
    },
    sortByDate(prop) {
      this.filteredInvoices.sort((a,b) => {
        return new Date(b.registerDate) - new Date(a.registerDate);
      })
    },
    updateVisible() {
      this.invoices = this.filteredInvoices.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      if (this.invoices.length == 0 && this.currentPage > 0) {
      }
    },
    updateCountryFilters () {
      this.filteredInvoices  = this.allinvoices
      
    },
    getPagesNumber() {
      if (this.filteredInvoices.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredInvoices.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredInvoices.length / this.pageSize) + 1
      }
    },
    async loadData() {
      let invoicesArr = []
      auth.onAuthStateChanged(user => {
        if (user) {
          this.centerUid = user.uid
          
          axios
          .post('https://portal.iaidl.org/checkout/app_api/invoices/get_invoice_portal.php',
          { 'center_uid' :this.centerUid }
          )
          .then( responseData => ( this.invoices = responseData.data.invoices ) )
        .catch(err => {
          // Manage the state of the application if the request 
          // has failed    
          alert("GENERAL ERROR")
        });









          this.loading = false
          
        } else {
          this.$router.push('/login')
        }
      })
    },
    showSnackbar (text, type) {
      this.$emit('snackbarNotification', text, type)
    },
    
    
  },
  
  created() {
    this.loading = true
    this.loadData()
    //this.monitorChange()

  },
  mounted() {

  },
  destroyed() {
  },
}
</script>

<style>
.customPointer * {
  cursor: pointer !important;
}
</style>
