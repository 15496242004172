<template>

  <div class="dashboard" >
    <v-toolbar v-if="!isLogin" dark app color="primary">
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">IAIDL</span>
        <span><sup>®</sup> Verification Center</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container class="my-5" v-if="!loading">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex class="text-md-center" xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Certificate for {{ company }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <img class="elevation-4 ma-1" width="99%" src="../assets/DEMO.jpg" alt=""> -->
            <div
              v-on:dragstart="getRight()" 
              @click.right="getRight()" 
              @click.left="getRight()"
            >
              <SVGPRODUCTcertificate
              class="certContainer elevation-4 ma-1 pa-0"
              v-if="certificate"
              :company="company"
              :location="location"
              :token="token"
              :date="date"
              :dateTo="dateTo"
              :product="product"
              />
            </div>
            <v-divider></v-divider>
            <v-layout wrap ma-5>
              <v-flex xs12 class="text-md-center">
                  <span class="headline text-md-center">IAIDL<sup>®</sup> hereby confirms that <span class="font-weight-black">{{ company }}</span> product have passed accreditation requirements and thus granted this certification as a proof of that </span>
              </v-flex>
              <v-flex xs12 mt-5>
                <v-card ma-3 class="elevation-2">
                  <v-layout>
                    <v-flex xs12 pa-1>
                      <v-card-title primary-title>
                        <div>
                          <div>IAIDL<sup>®</sup> confirms that this certificate was issued for:</div>
                          <div class="headline">{{ company }}</div>
                          <div class="headline">{{ location }}</div>
                        </div>
                      </v-card-title>
                    </v-flex>
                  </v-layout>
                  <v-divider light></v-divider>
                  <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn @click="" outline color="primary" dark>Report Error</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import printJS from 'print-js'
import VueQr from 'vue-qr'
import SVGPRODUCTcertificate from '../components/SVGPRODUCTcertificate';
import SVGBasiccertificate from '../components/SVGBasiccertificate';
import canvg from 'canvg'
import axios from 'axios';
import download from 'downloadjs'
import { getIAIDLDate } from '@/customFunction'

export default {
  components: {VueQr, SVGPRODUCTcertificate, SVGBasiccertificate},
  props: ['isLogin'],
  data: () => ({
    loading:false,
    token:null,
    level:null,
    name:'',
    code:null,
    date:null,
    candidateImage:null,
    candidatetitle:null,
    candidateName:null,
    candidateEmail:null,
    candidateAddress:null,
    candidateZip:null,
    candidateCity:null,
    candidateCountry:null,
    getCenterImage:null,
    centerName:null,
    centerEmail:null,
    centerAddress:null,
    centerZip:null,
    centerCity:null,
    centerCountry:null,
    title:null,
    certificate:null,
    company:null,
    location:null,
    token:null,
    date:null,
    dateTo:null,
    product:null,
  }),
  computed:{
    
  },
  watch: {

  },
  methods: {
    async getCertificateDetails(t){
      let m = await db.ref(`productCertificates/${t}`).once('value').then(x=>x.val())
      console.log(m)
      this.certificate = m 
      this.certificate.token = t
      this.company = this.certificate.company
      this.location = this.certificate.location
      this.token = this.certificate.token
      this.date = this.certificate.date
      this.dateTo = this.certificate.dateTo
      this.product = this.certificate.product
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    
  },
  created(){
    if(this.$route.params.token) {
      this.getCertificateDetails(this.$route.params.token)
    } else {
      this.goTo('/')
    }
  },
  mounted(){
    // this.countViews()
    // this.getCertificate()
  }
}

</script>

<style>
.certContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
