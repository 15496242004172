<template>
  <div>
    <h1 class="subheading grey--text">Create a New Course</h1>

    <v-container class="my-5">
      <v-card>
        <v-layout wrap pa-2>
            <v-flex color="primary" xs12 sm12 mb-4 class="text-xs-center">
               <v-card
                color="primary"
                dark
                >
                  <v-container fill-height fluid>
                    <v-layout fill-height>
                      <v-flex xs12 align-end flexbox>
                        <span class="headline">Create a New Course</span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
            </v-flex>
           
            <v-flex xs12 sm6 px-1 my-1>
              <v-autocomplete
                :items="operationalOfficers"
                item-text="name"
                label="Operational Officer"
                solo
                prepend-inner-icon="person"
                clearable
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-text-field
                label="Course Name"
                v-model="courseName"
                solo
                autofocus
                clearable
                required
                hint="Hint"
                prepend-inner-icon="book"
                :error-messages="nameErrors"
                @input="$v.courseName.$touch()"
                @blur="$v.courseName.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  label="Beginning Date"
                  prepend-inner-icon="event"
                  readonly
                  :value="beginDate"
                  solo
                  append-icon='close'
                  :rules="beginDateValidate"
                  @click:append="()=>{$v.beginDate.$touch(); beginDate = null}"
                  :error-messages="beginErrors"
                  @input="$v.beginDate.$touch()"
                  @blur="$v.beginDate.$touch()"
                ></v-text-field>
                <v-date-picker
                 @click="$v.beginDate.$touch()"
                 @blur="$v.beginDate.$touch()"
                 v-model="beginDate"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  label="End Date"
                  prepend-inner-icon="event"
                  readonly
                  :value="endDate"
                  solo
                  append-icon='close'
                  :rules="endDateValidate"
                  @click:append="()=>{$v.endDate.$touch(); endDate = null}"
                  :error-messages="endErrors"
                  @input="$v.endDate.$touch()"
                  @blur="$v.endDate.$touch()"
                ></v-text-field>
                <v-date-picker 
                @click="$v.endDate.$touch()"
                 @blur="$v.endDate.$touch()"
                v-model="endDate"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 px-1 my-1>
              <v-autocomplete
                :items="courseTypes"
                item-text="name"
                item-value="value"
                label="Course Type"
                solo
                clearable
                prepend-inner-icon="layers"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 px-1 my-1>
              <v-btn :disabled="nameErrors.length !== 0" color="indigo" :dark="nameErrors.length === 0" large>
                Add {{ courseName }}
              </v-btn>
            </v-flex>
        </v-layout>
      </v-card>
    </v-container>
    
  </div>
</template>

<script>
import db from '@/fb'
import { validationMixin } from 'vuelidate'
import { required, minLength, between } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  data() {
    return {
      date: new Date().toISOString(),
      beginDate:null,
      endDate:null,
      operationalOfficers: [],
      courseTypes:[
        {name:'Public', value:'public'},
        {name:'Corporate', value:'corporate'},
        {name:'Qualification', value:'qualification'}
      ],
      courseName:'',
    }
  },
  methods:{
    markDates() {

    }
  },
  validations: {
    courseName: {
      required,
      minLength: minLength(4)
    },
    beginDate: {
      required
    },
    endDate: {
      required
    },
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.courseName.$dirty) return errors
      !this.$v.courseName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.courseName.required && errors.push('Course name is required')
      return errors
    },
    beginErrors () {
      const errors = []
      if (!this.$v.beginDate.$dirty) return errors
      !this.$v.beginDate.required && errors.push('Begin date is required')
      return errors
    },
    beginDateValidate () {
      const errors = []
      if (!this.beginDate) return errors
      var d1 = new Date(this.date);
      var d2 = new Date(this.beginDate);
      if (d1.getTime() > d2.getTime()) {
        errors.push('Begin should be in the future')
      }
      return errors
    },
    endErrors () {
      const errors = []
      if (!this.$v.endDate.$dirty) return errors
      !this.$v.endDate.required && errors.push('End date is required')
      return errors
    },
    endDateValidate () {
      const errors = []
      if (!this.endDate) return errors
      var d1 = new Date(this.endDate);
      var d2 = new Date(this.beginDate);
      var d3 = new Date(this.date);

      if (d1.getTime() < d2.getTime() || d3.getTime() > d2.getTime()) {
        errors.push('End should be after begin date and in the future')
      }
      return errors
    }
  },
  created() {
    

  }
}
</script>
