<template>
  <div class="dashboard" v-if="certificateAccess">
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24 text-md-center">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Certificate for {{ userName }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <img class="elevation-4 ma-1" width="99%" src="../assets/DEMO.jpg" alt=""> -->
            <div
              v-on:dragstart="getRight()" 
              @click.right="getRight()" 
              @click.left="getRight()"
            >
              
              <SVGcard
              v-if="level == 'aimaassessor'"
              class="certContainer elevation-4 ma-1 pa-0"
              :name="userName.toUpperCase()"
              :code="certificateCode"
              :date="certificateDate"
              />
            </div>
            <v-divider></v-divider>
            <v-layout row wrap>
              <v-flex xs12 ma-0>
                <v-card class="elevation-8">
                  <v-layout row wrap>
                    <v-flex class="pa-3 text-xs-left" xs12 sm6 px-1 my-1>
                      <!-- <v-btn 
                        @click="downloadImg()"
                        color="primary" dark>
                        <v-icon left>image</v-icon>
                        Download Image
                      </v-btn> -->
                      <!--v-btn 
                        :loading="pdfLoading"
                        @click="downloadPDF()"
                        color="primary" dark>
                        <v-icon left>picture_as_pdf</v-icon>
                        Generate & Download PDF
                      </v-btn-->
                      <v-btn 
                        color="primary" 
                        dark
                        @click="goTo('/certificateAima/' + level)"
                        >
                        <v-icon left>image</v-icon>
                        Certificate
                      </v-btn>
                    </v-flex>
                    
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
import printJS from 'print-js'
import VueQr from 'vue-qr'
import SVGcertificate from '../components/SVGcertificate';
import SVGBasiccertificate from '../components/SVGBasiccertificate';
import SVGcard from '../components/SVGcardAima';
import MiniStatistic from '@/components/MiniStatistic';
import canvg from 'canvg'
import axios from 'axios';
import download from 'downloadjs'

export default {
  components: {VueQr, SVGcertificate, SVGBasiccertificate, SVGcard, MiniStatistic},
  props: ['isLogin'],
  data: () => ({
    level:null,
    candidateName:'DR. JOHN DOE',
    date:'00/00/00',
    ttt:null,
    qrImage:null,
    pdfLoading:false,
    printLoading:false,
    code:null,
    views:null,
    countries:null,
    detailedViews:[],
    detailedViewsHeaders:[],
  }),
  computed:{
    userName() {
      let name = this.$store.state.userData ? this.$store.state.userData.name : ''
      let title = this.$store.state.userData ? this.$store.state.userData.title : ''
      return `${title} ${name}`
    }, 
    certificateAccess(){
      return this.$store.state.userData ? this.$store.state.userData.certificate ? this.$store.state.userData.certificate[this.$route.params.level].access : false : false
    },
    certificateCode () {
      let token = this.$store.state.userData ? this.$store.state.userData.exam ? this.$store.state.userData.exam[this.$route.params.level].token : false : false
      if (!token) return true
      this.code = token.code
      axios.get(`//c.iaidl.org/yourls-api.php?signature=0a88314b95&action=url-stats&format=json&shorturl=${token.code}`)
      .then(resp=>{
        console.log('------------------------------------');
        console.log(resp);
        console.log('------------------------------------');
      })
      return token.code
    },
    
    certificateShortLink() {
      let url = this.$store.state.userData ? this.$store.state.userData.certificate ? this.$store.state.userData.certificate[this.$route.params.level].shorturl : false : false
      if (!url) return true
      return url
    },
    certificateDate() {
      let d = this.$store.state.userData ? this.$store.state.userData.exam ? this.$store.state.userData.exam[this.$route.params.level].meta.done : '' : ''
      if (!d) return ''
      let m = new Date(d).toLocaleString('en-US').split(',')[0]
      this.date = m
      return m
    }
  },
  watch: {

  },
  methods: {
    previewVerification() {
      this.goTo('/verification/' + this.code + '?preview=true')
    },
    goTo(path) {
      this.$router.push(path)
    },
    downloadQR () {
      download(this.qrImage, "certificate_QR.png", "image/png");
    },
    getQR(dataUrl,id) {
      this.qrImage = dataUrl
    },
    downloadPDF(url) {
      this.getPdf()
    },
    clipboardSuccessHandler ({ value, event }){
      console.log('success', value)
      this.$emit('snackbarNotification', `${this.certificateShortLink} copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      console.log('error', value)
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    getPdf() {
      this.pdfLoading = true
      this.$emit('snackbarNotification', `Generating ${this.userName} Card...`, 'success')
      axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/pdfcard?name=${this.userName.toUpperCase()}&code=${this.certificateCode}&date=${this.date}&level=${this.level}`)
      .then(response => { 
        window.open(response.data, 'Card Downloader')
        this.pdfLoading = false
      })
      .catch(e => {
        this.$emit('snackbarNotification', `Generation Error`, 'error')
      })
    },
  },
  created(){
    this.level = this.$route.params.level
  },
  mounted(){
    
  }
}

</script>

<style>
.certContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
