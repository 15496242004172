<template>
  <div>
    <embed class="regEmbed" src="//iaidl.org/IAIDL-Regulations.pdf" width="100%" height="375" 
    type="application/pdf">
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style>
.regEmbed {
  height: calc(100vh - 9rem);
}
</style>
