<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props:{
    data:{
      default: []
    }
  }, options: {
    bezierCurve : false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                    },
                    maintainAspectRatio: false
    },
  mounted () {
    this.renderChart(this.$props.data, this.options)
  }
}
</script>

<style>

</style>
