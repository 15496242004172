<template>

  <div class="dashboard" >
    <v-toolbar v-if="!isLogin" dark app color="primary">
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">IAIDL</span>
        <span><sup>®</sup> Verification Center</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container class="my-5" v-if="!loading">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-card class="elevation-24">
            <v-img
              class="white--text"
              height="100px"
              src="https://portal.iaidl.org/tback.jpg"
            >
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex class="text-md-center" xs12 align-end flexbox>
                    <span class="headline">IAIDL<sup>®</sup> Certificate for {{ name }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <!-- <img class="elevation-4 ma-1" width="99%" src="../assets/DEMO.jpg" alt=""> -->
            <div
              v-on:dragstart="getRight()" 
              @click.right="getRight()" 
              @click.left="getRight()"
            >
              <SVGcertificate
              v-if="level == 'advanced'"
              class="certContainer elevation-4 ma-1 pa-0"
              :name="name.toUpperCase()"
              :code="code"
              :date="date"
              />
              <SVGBasiccertificate
              v-if="level == 'basic'"
              class="certContainer elevation-4 ma-1 pa-0"
              :name="name.toUpperCase()"
              :code="code"
              :date="date"
              />
              <SVGAimacertificate
                  v-if="level == 'aimaassessor'"
                  class="certContainer elevation-4 ma-1 pa-0"
                  :name="name.toUpperCase()"
                  :code="code"
                  :date="date"
              />
            </div>
            <v-divider></v-divider>
            <v-layout wrap ma-5>
              <v-flex xs12 class="text-md-center">
                  <span class="headline text-md-center">IAIDL<sup>®</sup> hereby confirms that <span class="font-weight-black">{{ name }}</span> have passed the

                    <span v-if="level == 'basic'">basic</span>
                    <span v-if="level == 'aimaassessor'">AIMA Assessor</span>
                    level certification requirements and thus granted this certification as a proof of that </span>
              </v-flex>
              <v-flex xs12 mt-5>
                <v-card ma-3 class="elevation-2">
                  <v-layout>
                    <v-flex xs3 pa-2>
                      <v-avatar
                        size="195"
                        color="grey lighten-4"
                        v-if="candidateImage"
                        v-on:dragstart="getRight()" 
                        @click.right="getRight()" 
                        @click.left="getRight()"
                      >
                        <img :src="candidateImage" alt="avatar">
                      </v-avatar>
                    </v-flex>
                    <v-flex xs9 pa-1>
                      <v-card-title primary-title>
                        <div>
                          <div>IAIDL<sup>®</sup> confirms that this certificate was issued for:</div>
                          <div class="headline">{{ candidatetitle }} {{ candidateName }}</div>
                          <div>{{ candidateEmail }}</div>
                          <div>{{ candidateAddress }}, </div>
                          <div>{{ candidateZip }} {{ candidateCity }}</div>
                          <div>{{ candidateCountry }}</div>
                        </div>
                      </v-card-title>
                    </v-flex>
                  </v-layout>
                  <v-divider light></v-divider>
                  <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex xs12 mt-5>
                <v-card ma-3 class="elevation-2">
                  <v-layout wrap>
                    <v-flex xs12 md3 pa-2>
                      <v-avatar
                        size="195"
                        color="grey lighten-4"
                        v-if="getCenterImage"
                        v-on:dragstart="getRight()" 
                        @click.right="getRight()" 
                        @click.left="getRight()"
                      >
                        <img :src="getCenterImage" alt="avatar">
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 md9 pa-1>
                      <v-card-title primary-title>
                        <div>
                          <div>IAIDL<sup>®</sup> confirms that this certificate was issued in collaboration with:</div>
                          <div class="headline">{{ title }} {{ centerName }}</div>
                          <div>{{ centerEmail }}</div>
                          <div>{{ centerAddress }}, </div>
                          <div>{{ centerZip }} {{ centerCity }}</div>
                          <div>{{ centerCountry }}</div>
                        </div>
                      </v-card-title>
                    </v-flex>
                  </v-layout>
                  <v-divider light></v-divider>
                  <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { firebase, db, auth, createFirebase } from '@/fb';
//import printJS from 'print-js'
import VueQr from 'vue-qr'
import SVGcertificate from '../components/SVGcertificate';
import SVGBasiccertificate from '../components/SVGBasiccertificate';
import SVGAimacertificate from '../components/SVGAimacertificate';
//import canvg from 'canvg'
import axios from 'axios';
//import download from 'downloadjs'
import { getIAIDLDate } from '@/customFunction'

export default {
  components: {VueQr, SVGcertificate, SVGBasiccertificate, SVGAimacertificate},
  props: ['isLogin'],
  data: () => ({
    loading:true,
    token:null,
    level:null,
    name:'',
    code:null,
    date:null,
    candidateImage:null,
    candidatetitle:null,
    candidateName:null,
    candidateEmail:null,
    candidateAddress:null,
    candidateZip:null,
    candidateCity:null,
    candidateCountry:null,
    getCenterImage:null,
    centerName:null,
    centerEmail:null,
    centerAddress:null,
    centerZip:null,
    centerCity:null,
    centerCountry:null,
    title:null,
  }),
  computed:{
    
  },
  watch: {

  },
  methods: {
    getCertificate(){
      db.ref('certificates/' + this.token)
      .once('value')
      .then(snap=>{
        let cert = snap.val()
        this.level = cert.level
        this.code = cert.token
        this.date = new Date(cert.date).toLocaleString('en-US').split(',')[0]
        db.ref('candidates/' + cert.userKey)
        .once('value')
        .then(y=>{
          let candidate = y.val()
          this.name = `${candidate.title} ${candidate.name}`
          this.candidateImage = candidate.image
          this.candidateName = candidate.name
          this.candidateEmail = candidate.email
          this.candidateAddress = candidate.address
          this.candidateZip = candidate.zip
          this.candidateCity = candidate.city
          this.candidateCountry = candidate.country
          this.candidatetitle = candidate.title
          db.ref('centers/' + candidate.center)
          .once('value')
          .then(x=>{
            let center = x.val()
            this.getCenterImage = center.image
            this.centerName = center.name
            this.centerEmail = center.email
            this.centerAddress = center.address
            this.centerZip = center.zip
            this.centerCity = center.city
            this.centerCountry = center.country
            this.loading = false
            
          })
        })
      })
      return true
    },
    getRight(e) {
      e = e || window.event;
      e.preventDefault()
    },
    countViews(){
      return true
      /*
      axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=c500493b37224398b5ddbeb972b15e68')
      .then( (data) => {
          let ipData = data.data
          console.log(this.$route.query.preview)
          if (this.$route.query.preview == 'true') {
            return true
          } else {
            db.ref('certificates/' + this.token + '/views/').push({
              ip: ipData.ip,
              country: ipData.country_name,
              city: ipData.city,
              latitude: ipData.latitude,
              longitude: ipData.longitude,
              time: getIAIDLDate(),
              distantTime:ipData.time_zone.current_time,
              offset:ipData.time_zone.offset
            })
          }
      });
      */
    }
  },
  created(){
    this.token = this.$route.params.token

  },
  mounted(){
    this.countViews()
    this.getCertificate()
  }
}

</script>

<style>
.certContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
