<template>
  <div class="dashboard" >
    <v-container class="my-5">
      <v-layout wrap row justify-start class="mb-3">
        <v-flex xs12 md12 px-1>
          <v-text-field
            label="Search By Name/Email"
            solo
            v-model="searchText"
            @input="filterCenters()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 align-center class="text-xs-center">
          <v-btn @click="exportStudents()" color="primary" dark>Download Corporate Report</v-btn>
        </v-flex>
      </v-layout>
      <div v-if="loading" class="text-xs-center">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
            <span> {{ filteredCenters.length}} Results </span>
            <div class="text-xs-center">
              <v-pagination
                v-model="currentPage"
                :length="pagesNumber"
                :total-visible="5"
                @input="updateVisible"
              ></v-pagination>
            </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12 v-for="center in visibleCenters" :key="center.key" mb-1>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs3 v-for="key in Object.keys(center).filter(x=> {
                    let show = ['code', 'country', 'email', 'name', 'password', 'phone', 'registerDate', 'representative', 'title']
                    if(typeof center[x] != 'object'
                      && show.indexOf(x) != -1
                    ) {
                      return x
                    }
                  })" :key="center.key+key">
                  <div style="font-size:11px;font-weight:600;">{{ key.toUpperCase() }}</div>
                  <div class="subheadline">{{ center[key] }}</div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">REGULATIONS ACCEPTED</div>
                  <v-tooltip top>
                    <span slot="activator">
                      <v-avatar
                        size="10px"
                        :color="center.regulations ?'success' : 'error' "
                      >
                      </v-avatar>
                    </span>
                    <span>{{ center.regulations ? 'Regulations Accepted' : 'Under Proccess' }}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">TOTAL TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type != null).length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">AVAILABLE TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.expired == false).length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">REGISTERED CANDIDATES</div>
                  <div v-if="center.candidates">{{ Object.keys(center.candidates).length }}</div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">BASIC TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'basic').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">ADVANCED TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'advanced').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">AIMA ASSESSOR TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'aimaassessor').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">AIMA TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'aima').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">PRODUCT TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'product').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">LAB TOKENS</div>
                  <div v-if="center.tokens">
                    {{ [...Object.values(center.tokens)].filter(e=>e.type == 'lab').length }}
                  </div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">TRAINERS</div>
                  <div v-if="center.trainers">{{ Object.keys(center.trainers).length }}</div>
                </v-flex>
                <v-flex xs3>
                  <div style="font-size:11px;font-weight:600;">SESSIONS</div>
                  <div v-if="center.sessions">{{ Object.keys(center.sessions).length }}</div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions dense>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark small @click="changeEmail(center)">Change Email</v-btn>
              <v-btn color="primary" dark small @click="resetPass(center)">Deactivate User</v-btn>
              <v-btn color="primary" dark small @click="()=>{
                  addTokensD = true
                  selectedCenter = center
                }">add tokens</v-btn>
              <v-btn color="primary" dark small @click="()=>{
                  addInvoicesD = true
                  selectedCenter = center
                }">Add Invoice</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog v-model="addTokensD" v-if="selectedCenter" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">You want to add Tokens to {{selectedCenter.name}}?</v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                Type
                <v-select
                  label="Type"
                  :items="['advanced', 'basic', 'aima', 'lab', 'product', 'aimaassessor'].map(x=>x.toUpperCase())"
                  required
                  v-model="tokensType"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                Number to add to {{selectedCenter.name}} account
                <v-text-field
                  label="Number"
                  required
                  v-model="tokensAmount"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" flat @click="addTokensD = false">Cancel</v-btn>
            <v-btn :disabled="!tokensAmount" color="green darken-1" flat @click="addTokens()">add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addInvoicesD" v-if="selectedCenter" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">You want to add Invoice to {{selectedCenter.name}}?</v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  label="Invoice Amount"
                  required
                  v-model="invoiceAmount"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  label="Invoice Description"
                  required
                  name="invoiceDesc"
                  v-model="invoiceDesc"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
              <v-menu
                v-model="invoiceDateSel"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template slot="activator">
                  <v-text-field
                    v-model="invoiceDate"
                    label="Invoice Date"
                    append-icon="event"
                    readonly
                    solo
                    hide-details
                    clearable
                    :loading="loading"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="invoiceDate" @input="invoiceDateSel = false"></v-date-picker>
              </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" flat @click="addInvoicesD = false">Cancel</v-btn>
            <v-btn :disabled="!invoiceAmount" color="green darken-1" flat @click="addInvoice()">add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-layout row wrap py-3>
        <v-flex xs12 sm12 md12>
          <div class="text-xs-center">
            <v-pagination
              v-model="currentPage"
              :length="pagesNumber"
              :total-visible="7"
              @input="updateVisible"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
      
    </v-container>
  </div>
</template>

<script>
import {firebase, db, auth} from '@/fb';
//import adminSDK from firebase-admin;
import { titleCase, randomString } from '@/customFunction';
import axios from 'axios';
import {Parser} from "json2csv";
export default {
  data() {
    return {
      searchText:'',
      loading:false,
      invoiceDateSel:false,
      currentPage: 1,
      pageSize: 7,
      pagesNumber:0,
      centers:[],
      filteredCenters:[],
      visibleCenters:[],
      selectedCenter:null,
      tokensAmount:0,
      tokensInvoice:0,
      invoiceAmount:0,
      invoiceDesc:'',
      invoiceDate:null,
      addTokensD:false,
      addInvoicesD:false,
      tokensType:null,
      textFields:[

      ]
    }
  },
  methods:{
  exportStudents(){
    let a = this.centers.map(s=>{
      return {
        name:s.name,
        email:s.email,
        uid:s.uid
      }
    })
    let fields = ['name', 'email', 'uid']
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(a);
    //console.log(csv)
    let content = 'data:text/csv;charset=utf-8,';
    content += csv;
    const data = encodeURI(content);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute("download", "centers_report_"+ new Date().toLocaleString() + ".csv");
    link.click();
  },
  async changeEmail(cData){
    /*
      let thsUID = cData.uid
      console.log(thsUID);
      adminSDK.auth().updateUser(thsUID, {
        email: 'mohammed.aldajani@nokiasaudi.com',
        emailVerified: true
      })
      .then((userRecord) => {
        // See the UserRecord reference doc for the contents of userRecord.
        console.log('Successfully updated user', userRecord.toJSON());
      })
      .catch((error) => {
        console.log('Error updating user:', error);
      });



*/




  },
  async resetPass(cData) {

      console.log("DATAss")
      let thsUID = cData.uid
      
      //console.log(thsUID)
      if(thsUID){
      console.log("DATA======qqqwwq")
            // auth.updateUser(thsUID, {
            //   disabled: true,
            // })
            // .then((userRecord) => {
            //   // See the UserRecord reference doc for the contents of userRecord.
            //   console.log('Successfully updated user', userRecord.toJSON());
            // })
            // .catch((error) => {
            //   console.log('Error updating user:', error);
            // });

alert("User Deactivation Failed")

            // auth.sendPasswordResetEmail(thsEMAIL).then((data) => {
            //   this.resetStat = `Sent successfully to ${this.user.username}`
            //   alert("Password reset link Sent successfully to " + thsEMAIL)
            // }).catch((error) => {
            //   alert(error.message)
            // });
      }


    },
    async addInvoice(){
      if(!this.invoiceDate) {
        this.$emit('snackbarNotification', `Select a Date`, 'error')
        return
      }
      this.loading = true;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


      await axios.post("https://portal.iaidl.org/checkout/app_api/invoices/add_invoice_portal.php",
      { 'center_email': this.selectedCenter.email , 'center_name': this.selectedCenter.name , 'center_uid': this.selectedCenter.uid , 'invoice_date': this.invoiceDate, 'invoice_desc': this.invoiceDesc, 'invoice_amount': this.invoiceAmount })
        .then(
          response => console.log(response),
          alert("Invoice Inserted"),
          this.addInvoicesD = false,
          this.invoiceDate = '',
          this.invoiceDesc = '',
          this.invoiceAmount = 0,
          this.loading = false
        )
        .catch(err => {
          // Manage the state of the application if the request 
          // has failed    
          alert("GENERAL ERROR")
        });
        
    },
    async addTokens(){
      if(!this.tokensType) {
        this.$emit('snackbarNotification', `Select a type`, 'error')
        return
      }
      this.loading = true;
      (async ()=>{
        for (let i = 0; i < parseInt(this.tokensAmount); i++) {
          let code = `IAIDL_${randomString(8, 'aA#')}`
          let types = ['advanced', 'basic', 'aima', 'lab', 'product', 'aimaassessor']
          let type = this.tokensType.toLowerCase()
          let expired = false
          await db.ref(`centers/${this.selectedCenter.uid}/tokens`)
          .push({
            code,
            type,
            expired
          })
        }
        this.addTokensD = false
        this.loading = false;
        this.getCenters()
      })()
    },
    goTo(path) {
      this.$router.push(path)
    },
		updateVisible() {
      this.sortByDate()
      this.visibleCenters = this.filteredCenters.slice((this.currentPage -1) * this.pageSize, ((this.currentPage -1) * this.pageSize) + this.pageSize);
      this.loading = false
    },
    sortByDate(){
      this.filteredCenters = this.filteredCenters.sort(function(a,b){
        return new Date(b.issue_date) - new Date(a.issue_date);
      });
    },
    getPagesNumber(){
      if (this.filteredCenters.length % this.pageSize == 0) {
        this.pagesNumber = parseInt(this.filteredCenters.length / this.pageSize)
      } else {
        this.pagesNumber = parseInt(this.filteredCenters.length / this.pageSize) + 1
      }
		},
		filterCenters() {
      this.loading = true
			this.filteredCenters = this.centers
			.filter(v=>{
				if(!this.searchText) return v
				for (const key in v) {
					if (v.hasOwnProperty(key)) {
						const val = v[key];
						if(val && typeof val == 'string' && val.toLowerCase().includes(this.searchText.toLowerCase())) return v
					}
				}
			})
      this.getPagesNumber()
      this.currentPage = 1
			this.updateVisible()
		},
		clipboardSuccessHandler ({ value, event }){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler ({ value, event }) {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async getCenters(){
      this.loading = true
      this.centers = []
      let centers = await db.ref(`centers`).once('value').then(x=>x.val())
      for (const key in centers) {
        if (centers.hasOwnProperty(key)) {
          const center = centers[key];
          center.key = key
          this.centers.push(center)
        }
      }
      console.log(centers)
      this.filterCenters()

    }
  },
  created(){
    this.getCenters()
  }
}
</script>

<style>

</style>
