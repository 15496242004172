/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card>
        <v-img
          class="white--text"
          height="100px"
          src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">AI Product Accreditation Application</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
          <v-flex xs12 sm12 px-1 my-1>
            <v-text-field
              label="Token"
              readonly
              v-model="token"
              solo-inverted
              suffix="Token"
              :loading="tokenLoading"
              disabled
              light
              class="tokenField"
              color="white"
              background-color="primary"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <span class="title">Organization & Representative Information</span>
          </v-flex>
          <v-flex xs12 sm4 px-1
            v-for="(k, i) in fields.organization"
            :key="i+'org'"
          >
            <v-text-field
              :label="k"
              :disabled="review"
              v-model="app.organization[k.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]"
              solo
            ></v-text-field>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <span class="title">Manager Information</span>
          </v-flex>
          <v-flex xs12 sm4 px-1
            v-for="(k, i) in fields.labManager"
            :key="i"
          >
            <v-text-field
              :label="k"
              v-model="app.labManager[k.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]"
              :disabled="review"
              solo
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap v-for="(d, i) in fields.textAreas" :key="i">
              <v-flex xs12 px-1 py-2>
                <div class="title">{{ d.key }}</div>
                <div style="font-size:11px;">{{ d.question }}</div>
              </v-flex>
              <v-flex xs12 px-1>
                <v-textarea
                  v-if="app.textAreas"
                  :label="d.question"
                  :disabled="review"
                  solo
                  v-model="app.textAreas[d.key.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]"
                ></v-textarea>
                <v-textarea
                v-else
                  :label="d.question"
                  :disabled="review"
                  v-model="app.textAreas[d.key]"
                  solo
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <div class="subtitle">KPIs & Financial Results</div>
            <div class="title py-1">Non-Financial KPIs </div>
            <div style="font-size:12px;">List the top three (3) Non-Financial KPIs associated with the Product performance and indicate to what extent these have been met.</div>
          </v-flex>
          <v-flex xs12 v-for="(k,i) in 3" :key="i+'non'">
            <v-layout row wrap>
              <v-flex xs6 px-1>
                <v-text-field
                  :label="`Non-Financial KPI #${i+1}`"
                  :disabled="review"
                  solo
                  v-model="app.kpisnon[i].name"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 px-1>
                <v-text-field
                  :label="`Met % for #${i+1}`"
                  :disabled="review"
                  solo
                  v-model="app.kpisnon[i].percent"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <div class="subtitle">KPIs & Financial Results</div>
            <div class="title py-1">Financial KPIs </div>
            <div style="font-size:12px;">List the top three (3) Financial KPIs associated with the product performance and indicate to what extent these have been met.</div>
          </v-flex>
          <v-flex xs12 v-for="(k,i) in 3" :key="i+'kpi'">
            <v-layout row wrap>
              <v-flex xs6 px-1>
                <v-text-field
                  :label="`Financial KPI #${i+1}`"
                  :disabled="review"
                  solo
                  v-model="app.kpis[i].name"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 px-1>
                <v-text-field
                  :label="`Met % for #${i+1}`"
                  :disabled="review"
                  solo
                  v-model="app.kpis[i].percent"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <div class="title py-1">Images</div>
            <div style="font-size:12px;">Paste here any illustrations, sketches, or photos of tools used that serve to depict the product.</div>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 v-for="(img, i) in app.imgs" :key="'img'+i" >
                <v-badge right overlap color="error">
                  <v-icon slot="badge" small color="white" @click="()=>{ if(!review) app.imgs.splice(i, 1)}">close</v-icon>
                  <img :src="img" style="max-width:100%;max-height:150px;" alt="">
                </v-badge>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <input
              type="file"
              style="display: none"
              ref="file"
              @change="onFilePicked"
            >
            <v-btn color="success"
              :disabled="review"
              @click="$refs.file.click()">add an image</v-btn>
          </v-flex>
          <v-flex xs12 px-1 py-2>
            <div class="title py-1">Supporting Documentation</div>
            <div style="font-size:12px;">Attach to (or submit with) this application any additional supporting documentation the organization has which demonstrates the value and business impact of this product .  Examples might include research studies, design documentation, business plans, press releases, financial statements, and so forth.</div>
            <div style="font-size:12px;">For Concept applications, a minimum of a business plan and supporting research must be submitted with this application.</div>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 v-for="(doc, i) in app.documents" :key="'doc'+i" >
                <v-badge right overlap color="error">
                  <v-icon slot="badge" small color="white" @click="()=>{ if(!review) app.documents.splice(i, 1)}">close</v-icon>
                  <v-btn outline color="primary" :href="doc">Supporting Document #{{i+1}}</v-btn>
                </v-badge>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <input
              type="file"
              style="display: none"
              ref="doc"
              @change="onFilePickedDoc"
            >
            <v-btn color="success" 
              :disabled="review"
              @click="$refs.doc.click()">add a Document</v-btn>
          </v-flex>
          <v-flex xs8>
            <v-progress-linear v-if="uploadProgressDoc>0 && uploadProgressDoc<100" v-model="uploadProgressDoc"></v-progress-linear>
          </v-flex>
          <v-divider></v-divider>
          <v-flex xs12 px-1 py-2>
            <span class="title">AIMA Assessor</span>
          </v-flex>
          <v-flex xs12 sm4 px-1
            v-for="(k, i) in fields.assessor"
            :key="i+'assessor'"
          >
            <v-text-field
              :label="k"
              :disabled="review"
              v-model="app.assessor[k.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]"
              solo
            ></v-text-field>
          </v-flex>
          <v-flex xs12 px-1>
            <v-text-field
              label="Date"
              disabled
              v-model="app.date"
              solo
            ></v-text-field>
          </v-flex>
          <v-flex xs12 px-1>
            <v-text-field
              label="Assessor Signature (please enter your name in all caps)"
              v-model="app.signature"
              :disabled="review"
              solo
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 my-1>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="isLoading" :disabled="noToken || review" :dark="false" @click="()=>{
                  later = true
                  submit()
                }"  large>
                Save application to continue later
              </v-btn>
              <v-btn color="success" :loading="isLoading" :disabled="noToken || review" :dark="false" @click="submit()"  large>
                Submit application for review
              </v-btn>
              <v-btn color="primary" :loading="isLoading" v-if="review" :dark="false" @click="goTo('/product-applications-admin')"  large>
                back to applications
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">No available Tokens, Order?</v-card-title>
            <v-card-text>{{ userName }} have used all of purchased tokens, please make a purchase to continue with this product accreditation, do you want to place an order now?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="dialog = false">Later</v-btn>
              <v-btn color="green darken-1" flat @click="()=>{dialog = false; goTo('/order-tokens')}">Place an Order</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { isEmail } from '@/validators'
import { titleCase, randomString } from '@/customFunction'
import { firebase, db, auth, createFirebase, storageRef } from '@/fb';
import { Printd } from 'printd'
import axios from 'axios'

export default {
  mixins: [validationMixin],
  props: ['userName', 'uid'], 
  data() {
    return {
      review:false,
      date: new Date().toISOString(),

      noToken:false,
      dialog:false,
      gender:["Male", "Female"],
      titles:["Dr. ", "Prof. ", "Mr. ", "Mrs. ", "Ms. "],
      isLoading:false,
      countries: [],
      tokenKey:null,
      tokenCode:null,
      tokenLoading:true,
      app:{
        organization:{},
        labManager:{},
        assessor:{},
        typeTools:{},
        sourceTools:{},
        textAreas:{},
        kpisnon:[
          {name:'', percentage:'0'},
          {name:'', percentage:'0'},
          {name:'', percentage:'0'},
        ],
        kpis:[
          {name:'', percentage:'0'},
          {name:'', percentage:'0'},
          {name:'', percentage:'0'},
        ],
        imgs:[],
        documents:[]
      },
      fields:{
        organization:[
          'Name of Product',
          'Name of Organization',
          'Location of Organization',
          'Industries / Sectors',
          'Size of Organization',
          'Representative’s Title',
          'Representative’s Name',
          'Representative’s Dept.',
          'Representative’s Email',
          'Representative’s Phone',
        ],
        labManager:[
          'Author’s Name',
          'Author’s Firm',
          'Author’s Title',
          'Author’s Email',
          'Author’s Phone',
        ],
        assessor:[
          'AIMA Name',
          'AIMA ID',
          'Assessor’s Name',
          'Assessor’s Email',
          'Assessor’s Phone',
        ],
        typeTools:[
          'Open Source Robot',
          'SMART Big Data Product  evolution of a current offering targeted at existing Service',
          'Artificial Intelligence tools',
          'Drone',
          'IOT Creation',
          'Three D Printers',
          'VR and AR',
          'EEG technology',
          'Blockchain Tech',
          'Other',
        ],
        sourceTools:[
          'Internal Core R&D Group / Center of Excellence',
          'Internal New Product Development (NPD) Group',
          'Internal Core Product  Group',
          'Outside Contracted R&D / NPD Vendor',
          'Outside Partnership involving Technology Transfer or Strategic Supplier',
          'Outside Partnership involves Cross-Branding / Cross-Marketing / Cross-Selling another organization’s offerings',
          'Combined investment into / partnership with one or more independent New Business Ventures (“Startups”)',
          'Spin-Out of a fully-owned New Business Venture, or Joint Venture with another organization',
          'Merger with, or Acquisition of, another organization resulting in a combined development effort',
        ],
        textAreas:[
          { key:`Challenge Being Addressed`, question:`Describe the Challenge being addressed during the transformation Journey and how the Product helped you to overcome your challenges.` },
          { key:`Description of the Product`, question:`Provide a general description of the Product .  Explain what is new and/or novel about it relative to existing solutions. Explain how it solves the Organization Transformation Challenge problem Intersection with Innovation ,and any additional gains it creates.` },
          { key:`Main Beneficiaries`, question:`Explain How this Product Serve and impacts the organization – who are its customers or beneficiaries? Explain any relevant demographic and/or psychographic parameters involved. Explain what these customers’ decision factors are for whether or not to adopt the new Business Model.` },
          { key:`Future Tools and Policies`, question:`Describe the system integration with the Product (IT governance , Data Strategy , AI strategy declared). Indicate whether these are existing, adjacent, new-to-the-organization, or new-to-the-world markets. Indicate the implementation phases and plan to share the data with other organization` },
          { key:`Business Model Tools`, question:`Explain what elements of the organization’s use these tools to improve operations or client servicing. For example:  technology, Integration, service, customer service, branding, overall customer experience, asset utilization, marketing channels, sales channels, distribution channels, revenue model, internal organization design, financial transactions, data / analytics, communication processes, human resources, and so forth.` },
          { key:`Future Management Tools and organization maturity`, question:`Describe the association with the innovation associated with this Product` },
          { key:`Data-Driven Organization`, question:`Explain data flow chart , and when you use the organization Product to enhance process or service.` },
          { key:`Intellectual Property`, question:`Provide a list and description of any Intellectual Property associated with the Product. (If the assignee of the Intellectual Property Rights is a party other than the organization named herein, identify that party.)` },
          { key:`Outcomes / Results / Business Impact`, question:`Describe the outcomes, results, and business impact arising from the use and adoption of this Product . For example, increased revenues, profits, market share, customer satisfaction, quality, reliability, responsiveness, accessibility, effectiveness, efficiency, and so forth.` },
          { key:`Organizational Learnings`, question:`Describe what new insights and learnings the organization has realized from developing and launching this Product  that can be used and applied in other future endeavors.` },
        ],
      },
      imgUploadProduct:false,
      file:null,
      name:null,
      imgURLExt:null,
      fileName:null,
      uploadProgress:0,
      uploadProgressDoc:0,
      later:false,
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    checkBeforeSubmit(){
      let gg = true
      let a = Object.values(this.app)
      let b = Object.keys(this.app)
      for (let i = 0; i < a.length; i++) {
        const item = a[i];
        if(Array.isArray(item)){
          if(item.length<1) {
            this.$emit('snackbarNotification', `${b[i]} are missing`, 'error')
            console.log(b[i])
            return false
          }
          if(item.length>0 && item[0].percentage){
            for (let q = 0; q < item.length; q++) {
              const obj = item[q];
              if(!obj.name) {
                this.$emit('snackbarNotification', `KPI entry missing`, 'error')
                console.log('KPI missing')
                return false
              }
              if(!obj.percentage) {
                this.$emit('snackbarNotification', `KPI entry missing`, 'error')
                console.log('KPI missing')
                return false
              }
            }
          }
        } else if (typeof app[item] == 'string') {
          if(!this.app[item] || this.app[item] == '') {
            this.$emit('snackbarNotification', `${item} missing`, 'error')
            console.log('string', item)
            return false
          }
        } else {
          console.log('obj', b[i])
          let dObj = ['organization','labManager','assessor']
          if(dObj.includes(b[i])){
            for (let p = 0; p < this.fields[b[i]].length; p++) {
              const n = this.fields[b[i]][p];
              if(!this.app[b[i]][n.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]) {
                this.$emit('snackbarNotification', `Items in ${b[i]} missing`, 'error')
                console.log(`Items in ${b[i]} missing`)
                return false
              }
            }
          }
          if(b[i] == 'textAreas') {
            for (let p = 0; p < this.fields['textAreas'].length; p++) {
              const n = this.fields['textAreas'][p];
              if(!this.app['textAreas'][n.key.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_')]) {
                console.log(`${n.key} missing`)
                this.$emit('snackbarNotification', `${n.key} missing`, 'error')
                return false
              }
            }
          }
        }
      }
      return gg
    },
    async submit() {
      console.log(this.checkBeforeSubmit())
      if(this.checkBeforeSubmit() || this.later){
        console.log('submit!')
        console.log(this.app)
        this.app.token = this.token
        this.app.date = new Date()
        let a = await db.ref(`product/${this.token}`).update(this.app)
        let b = await db.ref(`centers/${this.$props.uid}/product/${this.token}`).update(this.app)
        await db.ref(`centers/${auth.currentUser.uid}/tokens/${this.tokenKey}`).update({
          expired:true
        })



axios.get(`https://us-central1-astack-8276c.cloudfunctions.net/main/mailer/?key=1234&type=new-product-register&etype=new-product-register&level=new-product-register`)
                      .then(response => { 
        this.$emit('snackbarNotification', `Submitted`, 'success')
        this.goTo('/product-applications')
                      })


      } else {
        this.$emit('snackbarNotification', `Please check application and submit again`, 'error')
      }
      
    },
    loadFile(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('productimages/iaidl' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
					this.uploadProgress = progress
                }, (error) =>{
        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          this.app.imgs.push(downloadURL) 
					this.imgUploadProduct = false
					this.uploadProgress = 100
        });
      });
    },
    onFilePicked (e) {
      this.imgUploadProduct = true
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } else {
        this.name = null
        this.fileName = null
      }
    },
    loadFileDoc(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('productimages/iaidl' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress)
					this.uploadProgressDoc = progress
                }, (error) =>{
        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          this.app.documents.push(downloadURL) 
					this.imgUploadProduct = false
					this.uploadProgressDoc = 100
        });
      });
    },
    onFilePickedDoc (e) {
      this.imgUploadProduct = true
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.imgURLExt = `.${this.file.name.split('.').pop()}`
          this.loadFileDoc(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } else {
        this.name = null
        this.fileName = null
      }
    },
    async loadAPP(t){
      let m = await db.ref(`product/${t}`).once('value').then(x=>x.val())
      this.app = m
      
    }
  },
  watch: {
    
  },
  computed: {
    token() {
      if(!this.$route.params.token) {
        this.tokenLoading = true
        let tokens = this.$store.state.tokens ? this.$store.state.tokens : []
        if (tokens.length === 0) {
          return
        }
        let validTokens = []
        for (const key in tokens) {
          if (tokens.hasOwnProperty(key)) {
            const token = tokens[key];
            if (!token.expired && token.type == 'product') {
              validTokens.push({
                token,
                key
              })
            }
          }
        }
        let vtoken = validTokens[0]
        if (!vtoken) {
          this.noToken = true
          this.tokenLoading = false
          this.dialog = true
          return 'NO AVAILABLE TOKENS'
        }
        this.tokenKey = vtoken ? vtoken.key : null
        this.tokenCode = vtoken ? vtoken.token : null
        this.tokenLoading = false
        this.noToken = false
        return vtoken ? vtoken.token.code : null
      } else {
        this.tokenLoading = false
        this.noToken = false
        return this.$route.params.token
      }
      
    },
  },
  created() {
    fetch('https://restcountries.eu/rest/v2/all').then((response) => {
      return response.json().then((json) => {
        json.forEach(country => {
          this.countries.push(country.name)
        });
      })
    });
    if(this.$route.params.token) {
      this.a = this.$route.params.token
      console.log("======================================");
      console.log(this.a);
      this.loadAPP(this.a)
    } 
    if(this.$route.params.review) {
      this.review = true
		} 

  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
