<template class="ma-0 pa-0">
  <div id="svg-container" class="ma-0 pa-0">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 267.43 178.34">
        <defs>
            <svg:style>
                .cls-1 {
                    fill: none;
                }
                
                .cls-2 {
                    isolation: isolate;
                }
                
                .cls-3 {
                    fill: #fff;
                }
                
                .cls-4 {
                    fill: url(#Degradado_sin_nombre_28);
                }
                
                .cls-5 {
                    fill: url(#Degradado_sin_nombre_4);
                }
                
                .cls-6 {
                    fill: url(#Degradado_sin_nombre_61);
                }
                
                .cls-7 {
                    fill: url(#Degradado_sin_nombre_61-2);
                }
                
                .cls-8 {
                    fill: url(#Degradado_sin_nombre_61-3);
                }
                
                .cls-9 {
                    font-size: 15.26px;
                }

                .cls-name {
                    font-size: {{size}}px !important;
                }
                
                .cls-12,
                .cls-9 {
                    fill: #2b3990;
                }
                
                .cls-18,
                .cls-37,
                .cls-9 {
                    font-family: Montserrat-Bold, Montserrat;
                    font-weight: 700;
                }
                
                .cls-10 {
                    letter-spacing: -0.01em;
                }
                
                .cls-11 {
                    letter-spacing: 0em;
                }
                
                .cls-13 {
                    letter-spacing: 0.05em;
                }
                
                .cls-14 {
                    letter-spacing: -0.01em;
                }
                
                .cls-15 {
                    letter-spacing: 0em;
                }
                
                .cls-16 {
                    letter-spacing: 0em;
                }
                
                .cls-17 {
                    letter-spacing: 0em;
                }
                
                .cls-18 {
                    font-size: 6.83px;
                }
                
                .cls-18,
                .cls-27 {
                    fill: #27aae1;
                }
                
                .cls-19 {
                    letter-spacing: -0.01em;
                }
                
                .cls-20 {
                    letter-spacing: -0.01em;
                }
                
                .cls-21 {
                    letter-spacing: 0em;
                }
                
                .cls-22 {
                    letter-spacing: 0.05em;
                }
                
                .cls-23 {
                    letter-spacing: -0.01em;
                }
                
                .cls-24 {
                    letter-spacing: 0em;
                }
                
                .cls-25 {
                    letter-spacing: 0em;
                }
                
                .cls-26 {
                    letter-spacing: 0em;
                }
                
                .cls-28 {
                    fill: url(#linear-gradient);
                }
                
                .cls-29 {
                    opacity: 0.58;
                    mix-blend-mode: overlay;
                }
                
                .cls-30 {
                    clip-path: url(#clip-path);
                }
                
                .cls-31 {
                    opacity: 0.2;
                }
                
                .cls-32 {
                    fill: #020202;
                }
                
                .cls-33 {
                    fill: url(#linear-gradient-2);
                }
                
                .cls-34 {
                    opacity: 0.25;
                    mix-blend-mode: hard-light;
                }
                
                .cls-35 {
                    fill: #5a3b18;
                    fill-rule: evenodd;
                }
                
                .cls-36,
                .cls-37 {
                    fill: #231f20;
                }
                
                .cls-37 {
                    font-size: 4.16px;
                }
                
                .cls-38 {
                    letter-spacing: 0.05em;
                }
                
                .cls-39 {
                    letter-spacing: -0.01em;
                }
                
                .cls-40 {
                    letter-spacing: 0em;
                }
                
                .cls-41 {
                    letter-spacing: 0em;
                }
                
                .cls-42 {
                    letter-spacing: 0em;
                }
            </svg:style>
            <linearGradient id="Degradado_sin_nombre_28" x1="57.21" y1="132.6" x2="-69.3" y2="29.39" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" />
                <stop offset="0.37" stop-color="#eff8fc" />
                <stop offset="1" stop-color="#cfe8f5" />
            </linearGradient>
            <linearGradient id="Degradado_sin_nombre_4" x1="163.88" y1="168.5" x2="236.04" y2="101.62" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" />
                <stop offset="1" stop-color="#27c2e4" />
            </linearGradient>
            <linearGradient id="Degradado_sin_nombre_61" x1="239.74" y1="160.33" x2="174.21" y2="-30.12" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#2a2a71" />
                <stop offset="1" stop-color="#27c2e4" />
            </linearGradient>
            <linearGradient id="Degradado_sin_nombre_61-2" x1="181.81" y1="85.78" x2="262.93" y2="45.09" xlink:href="#Degradado_sin_nombre_61" />
            <linearGradient id="Degradado_sin_nombre_61-3" x1="162.86" y1="-42.87" x2="237.51" y2="143.94" xlink:href="#Degradado_sin_nombre_61" />
            <linearGradient id="linear-gradient" x1="202.23" y1="147.21" x2="202.23" y2="80.28" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#b75c27" />
                <stop offset="0.05" stop-color="#bc642d" />
                <stop offset="0.14" stop-color="#c97b3c" />
                <stop offset="0.24" stop-color="#dfa156" />
                <stop offset="0.36" stop-color="#fcd479" />
                <stop offset="0.38" stop-color="#ffd97c" />
                <stop offset="0.5" stop-color="#ffdb7e" />
                <stop offset="0.57" stop-color="#fee485" />
                <stop offset="0.59" stop-color="#fde888" />
                <stop offset="0.61" stop-color="#fee384" />
                <stop offset="0.66" stop-color="#ffdb7e" />
                <stop offset="0.76" stop-color="#ffd97c" />
                <stop offset="0.86" stop-color="#e3a85a" />
                <stop offset="1" stop-color="#b75c27" />
            </linearGradient>
            <clipPath id="clip-path">
                <path id="SVGID" class="cls-1" d="M222.22,139.64h0a2.18,2.18,0,0,0-2.48,1.74v0a2.18,2.18,0,0,1-2.9,1.63l0,0a2.18,2.18,0,0,0-2.78,1.22l0,0a2.18,2.18,0,0,1-3.16,1l0,0a2.18,2.18,0,0,0-3,.65l0,0a2.18,2.18,0,0,1-3.3.39l0,0a2.18,2.18,0,0,0-3,.06l0,0a2.18,2.18,0,0,1-3.31-.26l0,0a2.18,2.18,0,0,0-3-.53l0,0a2.18,2.18,0,0,1-3.2-.9l0,0a2.18,2.18,0,0,0-2.82-1.1l0,0a2.18,2.18,0,0,1-3-1.5v0a2.18,2.18,0,0,0-2.55-1.63h0a2.18,2.18,0,0,1-2.61-2.05v0a2.18,2.18,0,0,0-2.19-2.1h0a2.18,2.18,0,0,1-2.16-2.52v0a2.18,2.18,0,0,0-1.74-2.48h0a2.18,2.18,0,0,1-1.63-2.9l0,0a2.18,2.18,0,0,0-1.22-2.78l0,0a2.18,2.18,0,0,1-1-3.16l0,0a2.18,2.18,0,0,0-.65-3l0,0a2.18,2.18,0,0,1-.39-3.3l0,0a2.18,2.18,0,0,0-.06-3l0,0a2.18,2.18,0,0,1,.26-3.31l0,0a2.18,2.18,0,0,0,.53-3l0,0a2.18,2.18,0,0,1,.9-3.2l0,0a2.18,2.18,0,0,0,1.1-2.82l0,0a2.18,2.18,0,0,1,1.5-3h0a2.18,2.18,0,0,0,1.63-2.55v0a2.18,2.18,0,0,1,2.05-2.61h0a2.18,2.18,0,0,0,2.1-2.19v0a2.18,2.18,0,0,1,2.52-2.16h0a2.18,2.18,0,0,0,2.48-1.74v0a2.18,2.18,0,0,1,2.9-1.63l0,0a2.18,2.18,0,0,0,2.78-1.22l0,0a2.18,2.18,0,0,1,3.16-1l0,0a2.18,2.18,0,0,0,3-.65l0,0a2.18,2.18,0,0,1,3.3-.39l0,0a2.18,2.18,0,0,0,3-.06l0,0a2.18,2.18,0,0,1,3.31.26l0,0a2.18,2.18,0,0,0,3,.53l0,0a2.18,2.18,0,0,1,3.2.9l0,0a2.18,2.18,0,0,0,2.82,1.1l0,0a2.18,2.18,0,0,1,3,1.5v0A2.18,2.18,0,0,0,221.08,87h0a2.18,2.18,0,0,1,2.61,2.05v0a2.18,2.18,0,0,0,2.19,2.1h0a2.18,2.18,0,0,1,2.16,2.52v0a2.18,2.18,0,0,0,1.74,2.48h0a2.18,2.18,0,0,1,1.63,2.9l0,0a2.18,2.18,0,0,0,1.22,2.78l0,0a2.18,2.18,0,0,1,1,3.16l0,0a2.18,2.18,0,0,0,.65,3l0,0a2.18,2.18,0,0,1,.39,3.3l0,0a2.18,2.18,0,0,0,.06,3l0,0a2.18,2.18,0,0,1-.26,3.31l0,0a2.18,2.18,0,0,0-.53,3l0,0a2.18,2.18,0,0,1-.9,3.2l0,0a2.18,2.18,0,0,0-1.1,2.82l0,0a2.18,2.18,0,0,1-1.5,3h0a2.18,2.18,0,0,0-1.63,2.55v0a2.18,2.18,0,0,1-2.05,2.61h0a2.18,2.18,0,0,0-2.1,2.19v0A2.18,2.18,0,0,1,222.22,139.64Z" />
            </clipPath>
            <linearGradient id="linear-gradient-2" x1="173.89" y1="113.74" x2="230.57" y2="113.74" gradientUnits="userSpaceOnUse">
                <stop offset="0.18" stop-color="#db953b" />
                <stop offset="0.38" stop-color="#ffd97c" />
                <stop offset="0.5" stop-color="#ffdb7e" />
                <stop offset="0.57" stop-color="#fee485" />
                <stop offset="0.59" stop-color="#fde888" />
                <stop offset="0.61" stop-color="#fee384" />
                <stop offset="0.66" stop-color="#ffdb7e" />
                <stop offset="0.76" stop-color="#ffd97c" />
            </linearGradient>
        </defs>
        <title>Asset 4iaidlCard</title>
        <g class="cls-2">
            <g id="Layer_2" data-name="Layer 2">
                <g id="PRINT">
                    <rect class="cls-3" width="242.96" height="155.91" />
                    <polygon class="cls-4" points="171.56 155.91 0 155.91 0 34.51 171.56 155.91" />
                    <polygon class="cls-5" points="121.68 155.91 242.96 155.91 242.96 34.51 121.68 155.91" />
                    <polygon class="cls-6" points="242.96 155.91 242.96 0 126.18 0 233.13 155.91 242.96 155.91" />
                    <polygon class="cls-7" points="242.96 155.91 242.96 0 151.86 0 236.55 155.91 242.96 155.91" />
                    <polygon class="cls-8" points="236.55 155.91 220.54 12.04 149.07 12.04 236.55 155.91" />
                    <text class="cls-9 cls-name" transform="translate(9.45 66.65)">{{name}}
                    </text>
                    <path class="cls-12" d="M10.44,44.36h.42v5.7h-.42Z" />
                    <path class="cls-12" d="M18,48.46H14.69L14,50.06h-.45l2.62-5.7h.42l2.62,5.7h-.45Zm-.15-.35-1.5-3.29-1.49,3.29Z" />
                    <path class="cls-12" d="M21.84,44.36h.42v5.7h-.42Z" />
                    <path class="cls-12" d="M25.87,44.36h2.25a3.3,3.3,0,0,1,1.58.37,2.68,2.68,0,0,1,1.07,1,3,3,0,0,1,0,2.94,2.68,2.68,0,0,1-1.07,1,3.3,3.3,0,0,1-1.58.37H25.87Zm2.22,5.32a3,3,0,0,0,1.39-.31,2.24,2.24,0,0,0,.93-.87,2.7,2.7,0,0,0,0-2.57,2.24,2.24,0,0,0-.93-.87,3,3,0,0,0-1.39-.31H26.28v4.95Z" />
                    <path class="cls-12" d="M34.22,44.36h.42v5.32h3.28v.37h-3.7Z" />
                    <path class="cls-12" d="M45.23,49.73a2.77,2.77,0,0,1-1.06-1,3,3,0,0,1,0-3,2.77,2.77,0,0,1,1.06-1,3,3,0,0,1,1.51-.37,3.16,3.16,0,0,1,1.14.2,2.36,2.36,0,0,1,.89.59l-.26.27a2.39,2.39,0,0,0-1.75-.68,2.64,2.64,0,0,0-1.3.33,2.39,2.39,0,0,0-.92.9,2.63,2.63,0,0,0,0,2.56,2.39,2.39,0,0,0,.92.9,2.63,2.63,0,0,0,1.3.33A2.39,2.39,0,0,0,48.51,49l.26.27a2.39,2.39,0,0,1-.89.59,3.11,3.11,0,0,1-1.14.2A3,3,0,0,1,45.23,49.73Z" />
                    <path class="cls-12" d="M55.72,49.69v.37H51.79v-5.7H55.6v.37H52.2V47h3v.37h-3v2.34Z" />
                    <path class="cls-12" d="M62.82,50.06l-1.36-1.9a4.21,4.21,0,0,1-.55,0H59.28v1.86h-.42v-5.7h2a2.56,2.56,0,0,1,1.69.51,1.74,1.74,0,0,1,.61,1.41,1.74,1.74,0,0,1-1.34,1.78l1.42,2Zm-1.91-2.23a2.11,2.11,0,0,0,1.39-.4,1.6,1.6,0,0,0,0-2.29,2.11,2.11,0,0,0-1.39-.4H59.28v3.09Z" />
                    <path class="cls-12" d="M67.36,44.74H65.31v-.37h4.52v.37H67.78v5.32h-.42Z" />
                    <path class="cls-12" d="M72.48,44.36h.42v5.7h-.42Z" />
                    <path class="cls-12" d="M76.93,44.74v2.43h3v.37h-3v2.52h-.42v-5.7h3.81v.37Z" />
                    <path class="cls-12" d="M83.28,44.36h.42v5.7h-.42Z" />
                    <path class="cls-12" d="M91.24,49.69v.37H87.31v-5.7h3.81v.37h-3.4V47h3v.37h-3v2.34Z" />
                    <path class="cls-12" d="M94.38,44.36h2.25a3.3,3.3,0,0,1,1.58.37,2.68,2.68,0,0,1,1.07,1,3,3,0,0,1,0,2.94,2.68,2.68,0,0,1-1.07,1,3.3,3.3,0,0,1-1.58.37H94.38Zm2.22,5.32A3,3,0,0,0,98,49.37a2.24,2.24,0,0,0,.93-.87,2.7,2.7,0,0,0,0-2.57,2.24,2.24,0,0,0-.93-.87,3,3,0,0,0-1.39-.31H94.8v4.95Z" />
                    <path class="cls-12" d="M111.84,50.06V45.19l-2.39,4.12h-.2l-2.39-4.1v4.86h-.41v-5.7h.35l2.55,4.4,2.55-4.4h.34v5.7Z" />
                    <path class="cls-12" d="M119.8,49.69v.37h-3.93v-5.7h3.81v.37h-3.4V47h3v.37h-3v2.34Z" />
                    <path class="cls-12" d="M128.33,50.06V45.19l-2.38,4.12h-.2l-2.38-4.1v4.86h-.41v-5.7h.35l2.55,4.4,2.55-4.4h.34v5.7Z" />
                    <path class="cls-12" d="M136.67,47.6a1.39,1.39,0,0,1,.32,1,1.3,1.3,0,0,1-.53,1.12,2.63,2.63,0,0,1-1.57.39h-2.54v-5.7h2.38a2.41,2.41,0,0,1,1.45.38,1.26,1.26,0,0,1,.52,1.08,1.31,1.31,0,0,1-.26.82,1.42,1.42,0,0,1-.7.48A1.58,1.58,0,0,1,136.67,47.6Zm-3.9-2.88V47h1.95a2,2,0,0,0,1.15-.29,1.09,1.09,0,0,0,0-1.69,2,2,0,0,0-1.15-.29Zm3.38,4.69a1,1,0,0,0,.43-.89,1,1,0,0,0-.43-.89,2.28,2.28,0,0,0-1.26-.29h-2.12V49.7h2.13A2.25,2.25,0,0,0,136.15,49.41Z" />
                    <path class="cls-12" d="M144,49.69v.37H140.1v-5.7h3.81v.37h-3.39V47h3v.37h-3v2.34Z" />
                    <path class="cls-12" d="M151.13,50.06l-1.36-1.9a4.19,4.19,0,0,1-.55,0h-1.63v1.86h-.42v-5.7h2a2.56,2.56,0,0,1,1.69.51,1.74,1.74,0,0,1,.61,1.41,1.74,1.74,0,0,1-1.33,1.78l1.42,2Zm-1.91-2.23a2.11,2.11,0,0,0,1.39-.4,1.6,1.6,0,0,0,0-2.29,2.11,2.11,0,0,0-1.39-.4h-1.63v3.09Z" />
                    <text class="cls-9" transform="translate(9.45 101.97)">{{code}}
                    </text>
                    <text class="cls-18" transform="translate(9.53 148.83)">c.iaidl.org/{{code}}
                    </text>
                    <path class="cls-12" d="M15.83,85.38V80.51l-2.39,4.12h-.2l-2.39-4.1v4.86h-.41v-5.7h.35l2.55,4.4,2.55-4.4h.34v5.7Z" />
                    <path class="cls-12" d="M23.79,85v.37H19.85v-5.7h3.81v.37h-3.4v2.25h3v.37h-3V85Z" />
                    <path class="cls-12" d="M32.32,85.38V80.51l-2.39,4.12h-.2l-2.39-4.1v4.86h-.41v-5.7h.35l2.55,4.4,2.55-4.4h.34v5.7Z" />
                    <path class="cls-12" d="M40.66,82.92a1.39,1.39,0,0,1,.32,1A1.3,1.3,0,0,1,40.45,85a2.63,2.63,0,0,1-1.57.39H36.34v-5.7h2.38a2.41,2.41,0,0,1,1.45.38,1.26,1.26,0,0,1,.52,1.08,1.31,1.31,0,0,1-.26.82,1.42,1.42,0,0,1-.7.48A1.58,1.58,0,0,1,40.66,82.92ZM36.75,80v2.27H38.7A2,2,0,0,0,39.85,82a1,1,0,0,0,.4-.85,1,1,0,0,0-.4-.84A2,2,0,0,0,38.7,80Zm3.38,4.69a1,1,0,0,0,.43-.89,1,1,0,0,0-.43-.89,2.28,2.28,0,0,0-1.26-.29H36.75V85h2.13A2.25,2.25,0,0,0,40.14,84.74Z" />
                    <path class="cls-12" d="M48,85v.37H44.09v-5.7H47.9v.37H44.5v2.25h3v.37h-3V85Z" />
                    <path class="cls-12" d="M55.12,85.38l-1.36-1.9a4.29,4.29,0,0,1-.55,0H51.58v1.86h-.42v-5.7h2a2.56,2.56,0,0,1,1.69.51,1.74,1.74,0,0,1,.61,1.41,1.74,1.74,0,0,1-1.34,1.78l1.42,2Zm-1.91-2.23a2.11,2.11,0,0,0,1.39-.4,1.6,1.6,0,0,0,0-2.29,2.11,2.11,0,0,0-1.39-.4H51.58v3.09Z" />
                    <path class="cls-12" d="M59,85.21a2.31,2.31,0,0,1-.88-.56l.19-.31a2.21,2.21,0,0,0,.81.52,2.91,2.91,0,0,0,1.07.2,2,2,0,0,0,1.23-.31,1,1,0,0,0,.42-.81.84.84,0,0,0-.22-.61A1.45,1.45,0,0,0,61,83a7.6,7.6,0,0,0-.88-.25,8.64,8.64,0,0,1-1-.3,1.61,1.61,0,0,1-.64-.45,1.21,1.21,0,0,1-.26-.82,1.39,1.39,0,0,1,.22-.76,1.49,1.49,0,0,1,.67-.55,2.74,2.74,0,0,1,1.13-.2,3.17,3.17,0,0,1,.93.14,2.73,2.73,0,0,1,.79.37l-.15.33a2.66,2.66,0,0,0-.75-.36,2.85,2.85,0,0,0-.81-.12,1.92,1.92,0,0,0-1.2.32,1,1,0,0,0-.41.82.84.84,0,0,0,.22.61,1.45,1.45,0,0,0,.55.35,8.45,8.45,0,0,0,.89.26,8.25,8.25,0,0,1,1,.3,1.65,1.65,0,0,1,.64.44,1.16,1.16,0,0,1,.26.8,1.35,1.35,0,0,1-.22.76,1.53,1.53,0,0,1-.69.55,2.84,2.84,0,0,1-1.14.2A3.33,3.33,0,0,1,59,85.21Z" />
                    <path class="cls-12" d="M69.88,79.68v5.7h-.42V82.67H65.65v2.71h-.42v-5.7h.42v2.62h3.81V79.68Z" />
                    <path class="cls-12" d="M73.49,79.68h.42v5.7h-.42Z" />
                    <path class="cls-12" d="M81.25,80.19a1.93,1.93,0,0,1,0,2.82,2.55,2.55,0,0,1-1.69.51H77.94v1.86h-.42v-5.7h2A2.56,2.56,0,0,1,81.25,80.19ZM81,82.74a1.6,1.6,0,0,0,0-2.28,2.11,2.11,0,0,0-1.39-.4H77.94v3.09h1.63A2.12,2.12,0,0,0,81,82.74Z" />
                    <path class="cls-12" d="M91.84,81.62l-.23,1.82H92.8v.33H91.57l-.2,1.61h-.33l.2-1.61H89.47l-.2,1.61h-.33l.2-1.61H88v-.33h1.21l.23-1.82H88.21V81.3h1.23l.2-1.61H90l-.2,1.61h1.79l.2-1.61h.33l-.2,1.61h1.17v.33Zm-.33,0H89.73l-.22,1.82h1.78Z" />
                    <path class="cls-27" d="M58,28.68H12.5a3.06,3.06,0,0,1-3-3V11.23a3.06,3.06,0,0,1,3-3H58a3.06,3.06,0,0,1,3,3v14.4A3.06,3.06,0,0,1,58,28.68ZM9.71,11.54V25.32a3.06,3.06,0,0,0,3,3h45a3.06,3.06,0,0,0,3-3V11.54a3.06,3.06,0,0,0-3-3h-45A3.06,3.06,0,0,0,9.71,11.54Z" />
                    <path class="cls-27" d="M19.9,15V26.73H16.21V15Z" />
                    <path class="cls-27" d="M29.12,24.95H25.19l-.58,1.78H20.73L25,15H29.3l4.29,11.77H29.7Zm-.9-2.78L27.15,18.9l-1.06,3.28Z" />
                    <path class="cls-27" d="M38.11,15V26.73H34.42V15Z" />
                    <path class="cls-27" d="M50.05,23.83A5.39,5.39,0,0,1,47.91,26a6.54,6.54,0,0,1-3.23.77H39.77V15h4.91a6.71,6.71,0,0,1,3.23.75,5.26,5.26,0,0,1,2.14,2.08,6.49,6.49,0,0,1,0,6.05Zm-3.72-1.08a2.53,2.53,0,0,0,.73-1.95,2.53,2.53,0,0,0-.73-1.95,2.79,2.79,0,0,0-2-.68h-.88v5.25h.88A2.79,2.79,0,0,0,46.33,22.75Z" />
                    <path class="cls-27" d="M55.82,23.93h3.56v2.79H52.13V15h3.69Z" />
                    <path class="cls-27" d="M12.42,20.88a.63.63,0,0,0,.12-.73l1.33-1.33a.44.44,0,0,1,.31-.13h.61v.45a1.07,1.07,0,0,0,0,.27.2.2,0,1,0,.39-.1.66.66,0,0,1,0-.17V14.39a.66.66,0,0,1,.66-.66H20.6l.17,0a.2.2,0,1,0,.1-.39,1.07,1.07,0,0,0-.27,0h-.45V11.54a.63.63,0,1,0-.4,0v1.79H19.3v-.88a.2.2,0,0,0-.4,0v.88h-.46V10.08a.2.2,0,0,0-.4,0v3.25h-.46v-.88a.2.2,0,0,0-.4,0v.88h-.46v-.61a.84.84,0,0,0-.25-.6l-1.33-1.33a.63.63,0,1,0-.29.29l1.33,1.33a.44.44,0,0,1,.13.31v.61h-.45a1.07,1.07,0,0,0-1.06,1.06v.45H13a.63.63,0,1,0,0,.4h1.79v.46h-.88a.2.2,0,1,0,0,.4h.88v.46H11.55a.2.2,0,1,0,0,.4h3.25v.46h-.88a.2.2,0,0,0,0,.4h.88v.46h-.61a.84.84,0,0,0-.6.25l-1.33,1.33a.63.63,0,1,0,.17,1Zm7.3-9.94a.23.23,0,1,1,.23.23A.23.23,0,0,1,19.73,10.94Zm-5.33-.27a.23.23,0,1,1,.16.07A.23.23,0,0,1,14.4,10.67Zm-2,4.6a.23.23,0,1,1,.23-.23A.23.23,0,0,1,12.41,15.27Zm-.59,5.33a.23.23,0,1,1,.32,0A.23.23,0,0,1,11.81,20.6Z" />
                    <path class="cls-27" d="M65.19,9.94v3.63H64V9.94Z" />
                    <path class="cls-27" d="M69.18,13.57H68l-1.21-1.83v1.83H65.7V9.94h1.14L68,11.79V9.94h1.14Z" />
                    <path class="cls-27" d="M72.55,9.94v.9h-1v2.73H70.45V10.84h-1v-.9Z" />
                    <path class="cls-27" d="M74,10.84v.45h1.13v.86H74v.51h1.28v.91H72.86V9.94h2.42v.9Z" />
                    <path class="cls-27" d="M77.53,13.57l-.69-1.3v1.3H75.71V9.94H77.4a1.67,1.67,0,0,1,.75.16,1.09,1.09,0,0,1,.47.43,1.19,1.19,0,0,1,.16.61,1.08,1.08,0,0,1-.2.65,1.11,1.11,0,0,1-.58.41l.79,1.39Zm-.69-2.06h.45a.36.36,0,0,0,.25-.07.36.36,0,0,0,0-.46.34.34,0,0,0-.24-.08h-.45Z" />
                    <path class="cls-27" d="M82.63,13.57H81.49l-1.21-1.83v1.83H79.14V9.94h1.14l1.21,1.86V9.94h1.14Z" />
                    <path class="cls-27" d="M85.47,13H84.26l-.18.55h-1.2l1.33-3.63h1.31l1.32,3.63h-1.2Zm-.28-.86-.33-1-.33,1Z" />
                    <path class="cls-27" d="M90,9.94v.9H89v2.73H87.85V10.84h-1v-.9Z" />
                    <path class="cls-27" d="M91.4,9.94v3.63H90.26V9.94Z" />
                    <path class="cls-27" d="M94.62,10.11a1.77,1.77,0,0,1,.67.66,2,2,0,0,1,0,1.92,1.79,1.79,0,0,1-.68.67,1.95,1.95,0,0,1-1.88,0,1.8,1.8,0,0,1-.68-.67,2,2,0,0,1,0-1.92,1.78,1.78,0,0,1,.68-.66,2,2,0,0,1,1.88,0Zm-1.47,1a1,1,0,0,0,0,1.18.74.74,0,0,0,1.05,0,1,1,0,0,0,0-1.18.74.74,0,0,0-1.05,0Z" />
                    <path class="cls-27" d="M99.44,13.57H98.3L97.1,11.74v1.83H96V9.94H97.1l1.21,1.86V9.94h1.14Z" />
                    <path class="cls-27" d="M102.28,13h-1.21l-.18.55H99.7L101,9.94h1.31l1.32,3.63h-1.2Zm-.28-.86-.33-1-.33,1Z" />
                    <path class="cls-27" d="M105.06,12.71h1.1v.86h-2.24V9.94h1.14Z" />
                    <path class="cls-27" d="M69.34,21h-2.6l-.39,1.18H63.79l2.85-7.79h2.82l2.84,7.79H69.73Zm-.59-1.84L68,17l-.7,2.17Z" />
                    <path class="cls-27" d="M75.29,14.43v7.79H72.85V14.43Z" />
                    <path class="cls-27" d="M67.22,25.71a1.66,1.66,0,0,1-.66.65,2,2,0,0,1-1,.24H64V23h1.51a2.07,2.07,0,0,1,1,.23,1.62,1.62,0,0,1,.66.64,2,2,0,0,1,0,1.87Zm-1.15-.33a.91.91,0,0,0,0-1.2.86.86,0,0,0-.61-.21h-.27v1.62h.27A.86.86,0,0,0,66.07,25.38Z" />
                    <path class="cls-27" d="M69.69,26.6,69,25.3v1.3H67.86V23h1.69a1.67,1.67,0,0,1,.75.16,1.09,1.09,0,0,1,.47.43,1.19,1.19,0,0,1,.16.61,1.08,1.08,0,0,1-.2.65,1.11,1.11,0,0,1-.58.41l.79,1.39ZM69,24.54h.45a.36.36,0,0,0,.25-.07.36.36,0,0,0,0-.46.34.34,0,0,0-.24-.08H69Z" />
                    <path class="cls-27" d="M72.44,23V26.6H71.3V23Z" />
                    <path class="cls-27" d="M73.9,23l.75,2.5L75.4,23h1.21L75.38,26.6H73.91L72.69,23Z" />
                    <path class="cls-27" d="M78,23V26.6H76.86V23Z" />
                    <path class="cls-27" d="M82,26.6H80.86l-1.21-1.83V26.6H78.51V23h1.14l1.21,1.86V23H82Z" />
                    <path class="cls-27" d="M85.4,23.28a1.36,1.36,0,0,1,.53.93h-1.2a.44.44,0,0,0-.19-.17.68.68,0,0,0-.3-.06.63.63,0,0,0-.51.21.87.87,0,0,0-.18.58.89.89,0,0,0,.2.64.81.81,0,0,0,.62.21.76.76,0,0,0,.64-.32H84.1v-.81H86v1.14a2.19,2.19,0,0,1-.65.7,1.8,1.8,0,0,1-1.05.29,2,2,0,0,1-1-.23,1.58,1.58,0,0,1-.64-.65,2.17,2.17,0,0,1,0-1.92,1.59,1.59,0,0,1,.64-.65,2,2,0,0,1,1-.23A1.86,1.86,0,0,1,85.4,23.28Z" />
                    <path class="cls-27" d="M88.38,25.74h1.1v.86H87.24V23h1.14Z" />
                    <path class="cls-27" d="M91,23V26.6H89.83V23Z" />
                    <path class="cls-27" d="M94,23.11a1.56,1.56,0,0,1,.61.5,1.82,1.82,0,0,1,.31.75h-1.2a.65.65,0,0,0-.23-.28.61.61,0,0,0-.35-.1.54.54,0,0,0-.45.22,1.09,1.09,0,0,0,0,1.16.54.54,0,0,0,.45.22.61.61,0,0,0,.35-.1.65.65,0,0,0,.23-.28h1.2a1.82,1.82,0,0,1-.31.75,1.56,1.56,0,0,1-.61.5,1.94,1.94,0,0,1-.84.18,1.88,1.88,0,0,1-1-.23,1.57,1.57,0,0,1-.62-.65,2.22,2.22,0,0,1,0-1.92,1.58,1.58,0,0,1,.62-.65,1.88,1.88,0,0,1,1-.23A1.94,1.94,0,0,1,94,23.11Z" />
                    <path class="cls-27" d="M96.49,23.88v.45h1.13v.86H96.49v.51h1.28v.91H95.35V23h2.42v.9Z" />
                    <path class="cls-27" d="M101.68,26.6h-1.14l-1.21-1.83V26.6H98.2V23h1.14l1.21,1.86V23h1.14Z" />
                    <path class="cls-27" d="M104.84,26.1a1.1,1.1,0,0,1-.47.4,1.69,1.69,0,0,1-.73.15,1.79,1.79,0,0,1-1.07-.31,1.12,1.12,0,0,1-.46-.89h1.21q0,.31.28.31a.21.21,0,0,0,.14,0,.17.17,0,0,0,.05-.13.23.23,0,0,0-.13-.2,2.58,2.58,0,0,0-.42-.17,3.93,3.93,0,0,1-.57-.23,1.14,1.14,0,0,1-.4-.35,1.12,1.12,0,0,1,0-1.2,1.13,1.13,0,0,1,.5-.38,1.87,1.87,0,0,1,.71-.13,1.63,1.63,0,0,1,1,.3,1.14,1.14,0,0,1,.42.87h-1.23q0-.29-.24-.29a.16.16,0,0,0-.12,0,.17.17,0,0,0,0,.13.22.22,0,0,0,.13.19,2.69,2.69,0,0,0,.41.17,4.37,4.37,0,0,1,.58.24,1.19,1.19,0,0,1,.4.35,1,1,0,0,1,.17.6A1,1,0,0,1,104.84,26.1Z" />
                    <path class="cls-27" d="M106.54,23.88v.45h1.13v.86h-1.13v.51h1.28v.91H105.4V23h2.42v.9Z" />
                    <path class="cls-28" d="M222.46,139.68l-.48-.08a2,2,0,0,0-2.25,1.57l-.09.48a2,2,0,0,1-2.63,1.48l-.45-.17a2,2,0,0,0-2.52,1.1l-.18.45a2,2,0,0,1-2.86.93l-.41-.25a2,2,0,0,0-2.68.59l-.27.4a2,2,0,0,1-3,.36l-.35-.33a2,2,0,0,0-2.75.06l-.34.34a2,2,0,0,1-3-.23l-.28-.39a2,2,0,0,0-2.71-.48l-.4.27a2,2,0,0,1-2.9-.81l-.2-.44a2,2,0,0,0-2.56-1l-.45.19a2,2,0,0,1-2.69-1.36l-.11-.47a2,2,0,0,0-2.32-1.48l-.47.1a2,2,0,0,1-2.37-1.86l0-.48a2,2,0,0,0-2-1.9h-.48a2,2,0,0,1-2-2.29l.08-.48a2,2,0,0,0-1.57-2.25l-.48-.09a2,2,0,0,1-1.48-2.63l.17-.45a2,2,0,0,0-1.1-2.52l-.45-.18a2,2,0,0,1-.93-2.86l.25-.41a2,2,0,0,0-.59-2.68l-.4-.27a2,2,0,0,1-.36-3l.33-.35a2,2,0,0,0-.06-2.75l-.34-.34a2,2,0,0,1,.23-3l.39-.28a2,2,0,0,0,.48-2.71l-.27-.4a2,2,0,0,1,.81-2.9l.44-.2a2,2,0,0,0,1-2.56l-.19-.45a2,2,0,0,1,1.36-2.69l.47-.11a2,2,0,0,0,1.48-2.32l-.1-.47a2,2,0,0,1,1.86-2.37l.48,0a2,2,0,0,0,1.9-2v-.48a2,2,0,0,1,2.29-2l.48.08a2,2,0,0,0,2.25-1.57l.09-.48a2,2,0,0,1,2.63-1.48l.45.17a2,2,0,0,0,2.52-1.1l.18-.45a2,2,0,0,1,2.86-.93l.41.25a2,2,0,0,0,2.68-.59l.27-.4a2,2,0,0,1,3-.36l.35.33a2,2,0,0,0,2.75-.06l.34-.34a2,2,0,0,1,3,.23l.28.39a2,2,0,0,0,2.71.48l.4-.27a2,2,0,0,1,2.9.81l.2.44a2,2,0,0,0,2.56,1l.45-.19a2,2,0,0,1,2.69,1.36l.11.47a2,2,0,0,0,2.32,1.48l.47-.1a2,2,0,0,1,2.37,1.86l0,.48a2,2,0,0,0,2,1.9h.48a2,2,0,0,1,2,2.29l-.08.48a2,2,0,0,0,1.57,2.25l.48.09A2,2,0,0,1,231.62,99l-.17.45a2,2,0,0,0,1.1,2.52l.45.18a2,2,0,0,1,.93,2.86l-.25.41a2,2,0,0,0,.59,2.68l.4.27a2,2,0,0,1,.36,3l-.33.35a2,2,0,0,0,.06,2.75l.34.34a2,2,0,0,1-.23,3l-.39.28a2,2,0,0,0-.48,2.71l.27.4a2,2,0,0,1-.81,2.9l-.44.2a2,2,0,0,0-1,2.56l.19.45a2,2,0,0,1-1.36,2.69l-.47.11a2,2,0,0,0-1.48,2.32l.1.47a2,2,0,0,1-1.86,2.37l-.48,0a2,2,0,0,0-1.9,2v.48A2,2,0,0,1,222.46,139.68Z" />
                    <path class="cls-33" d="M202.23,85.4a28.34,28.34,0,1,0,28.34,28.34A28.34,28.34,0,0,0,202.23,85.4Zm0,55.91a27.57,27.57,0,1,1,27.57-27.57A27.57,27.57,0,0,1,202.23,141.32Z" />
                    <g class="cls-34">
                        <path class="cls-35" d="M225.64,116.34a8.29,8.29,0,0,1,.93.51c.44.36.91.51,1.3,0s.86-.62,1.14-.6a1.44,1.44,0,0,0-1.31-.46,5.36,5.36,0,0,0-1.08.47A1.53,1.53,0,0,1,225.64,116.34Z" />
                        <path class="cls-35" d="M225.78,116.07s.66-.19,1-.21c.57,0,1-.25,1-.88a1.2,1.2,0,0,1,.4-1.23c-1.35.14-1.39.92-1.71,1.66C226.37,115.64,226.23,115.8,225.78,116.07Z" />
                        <path class="cls-35" d="M225.07,119.5a8.32,8.32,0,0,1,.85.63c.39.41.83.63,1.29.2s.93-.5,1.21-.44a1.44,1.44,0,0,0-1.23-.63,5.36,5.36,0,0,0-1.14.32A1.53,1.53,0,0,1,225.07,119.5Z" />
                        <path class="cls-35" d="M225.25,119.25s.68-.09,1.06-.07c.57.05,1-.11,1.07-.74,0-.8.32-1,.56-1.17-1.35,0-1.5.72-1.91,1.42C225.89,118.91,225.73,119,225.25,119.25Z" />
                        <path class="cls-35" d="M224.08,122.56a8.29,8.29,0,0,1,.75.75c.33.46.74.74,1.25.37s1-.37,1.26-.27a1.45,1.45,0,0,0-1.13-.79,5.38,5.38,0,0,0-1.17.16A1.53,1.53,0,0,1,224.08,122.56Z" />
                        <path class="cls-35" d="M224.28,122.33s.68,0,1.06.08c.56.13,1,0,1.16-.59.14-.79.46-1,.71-1.08-1.33-.23-1.59.51-2.09,1.14A1.36,1.36,0,0,1,224.28,122.33Z" />
                        <path class="cls-35" d="M222.67,125.45a8.26,8.26,0,0,1,.64.84c.26.5.63.84,1.19.54.71-.38,1-.23,1.28-.09a1.45,1.45,0,0,0-1-.94,5.4,5.4,0,0,0-1.18,0A1.53,1.53,0,0,1,222.67,125.45Z" />
                        <path class="cls-35" d="M222.91,125.25s.68.09,1,.23c.53.2,1,.17,1.23-.43.25-.77.58-.88.86-1-1.29-.41-1.64.29-2.23.84A1.36,1.36,0,0,1,222.91,125.25Z" />
                        <path class="cls-35" d="M220.88,128.12a8.29,8.29,0,0,1,.52.92c.19.53.51.91,1.1.7.76-.28,1.06-.09,1.28.08a1.45,1.45,0,0,0-.88-1.07,5.35,5.35,0,0,0-1.17-.16A1.53,1.53,0,0,1,220.88,128.12Z" />
                        <path class="cls-35" d="M221.14,128s.66.18,1,.36c.5.27,1,.31,1.28-.26.35-.73.7-.79,1-.84-1.22-.58-1.67.06-2.32.53A1.36,1.36,0,0,1,221.14,128Z" />
                        <path class="cls-35" d="M218.75,130.52a8.35,8.35,0,0,1,.39,1c.12.55.38,1,1,.84.79-.18,1.06.06,1.26.26a1.45,1.45,0,0,0-.72-1.18,5.34,5.34,0,0,0-1.14-.32A1.53,1.53,0,0,1,218.75,130.52Z" />
                        <path class="cls-35" d="M219,130.4s.63.27.94.5.95.44,1.3-.08.8-.69,1.09-.7c-1.13-.74-1.66-.16-2.37.21A1.36,1.36,0,0,1,219,130.4Z" />
                        <path class="cls-35" d="M216.31,132.62a8.38,8.38,0,0,1,.26,1c0,.57.24,1,.87,1,.81-.07,1,.2,1.21.42a1.45,1.45,0,0,0-.56-1.27,5.4,5.4,0,0,0-1.08-.47C216.77,133.2,216.61,133.06,216.31,132.62Z" />
                        <path class="cls-35" d="M216.6,132.54s.58.35.86.62.88.57,1.3.1.88-.58,1.17-.55c-1-.89-1.62-.39-2.38-.11A1.36,1.36,0,0,1,216.6,132.54Z" />
                        <path class="cls-35" d="M213.61,134.36a8.26,8.26,0,0,1,.12,1.05c0,.57.1,1,.73,1.08.81,0,1,.34,1.15.59a1.45,1.45,0,0,0-.38-1.33,5.34,5.34,0,0,0-1-.61A1.53,1.53,0,0,1,213.61,134.36Z" />
                        <path class="cls-35" d="M213.91,134.32s.53.43.77.73c.35.45.79.68,1.27.27a1.2,1.2,0,0,1,1.24-.39c-.89-1-1.56-.6-2.34-.43A1.36,1.36,0,0,1,213.91,134.32Z" />
                        <path class="cls-35" d="M210.7,135.72a8.35,8.35,0,0,1,0,1.06c-.11.55,0,1,.58,1.17.8.15.95.47,1.06.74a1.44,1.44,0,0,0-.19-1.37,5.37,5.37,0,0,0-.92-.75A1.53,1.53,0,0,1,210.7,135.72Z" />
                        <path class="cls-35" d="M211,135.72s.47.5.66.83c.29.49.69.78,1.22.44a1.2,1.2,0,0,1,1.28-.21c-.75-1.13-1.46-.81-2.26-.75A1.36,1.36,0,0,1,211,135.72Z" />
                        <path class="cls-35" d="M207.63,136.67a8.31,8.31,0,0,1-.17,1c-.19.53-.18,1,.41,1.24.77.26.87.6.94.87a1.44,1.44,0,0,0,0-1.38,5.37,5.37,0,0,0-.81-.86A1.53,1.53,0,0,1,207.63,136.67Z" />
                        <path class="cls-35" d="M207.93,136.71s.4.56.54.91c.22.53.58.87,1.15.61a1.21,1.21,0,0,1,1.29,0c-.59-1.22-1.33-1-2.14-1.05A1.36,1.36,0,0,1,207.93,136.71Z" />
                        <path class="cls-35" d="M204.46,137.19a8.39,8.39,0,0,1-.32,1c-.26.5-.32,1,.24,1.28.73.36.78.71.82,1a1.45,1.45,0,0,0,.18-1.37,5.36,5.36,0,0,0-.68-1A1.53,1.53,0,0,1,204.46,137.19Z" />
                        <path class="cls-35" d="M204.75,137.27s.31.61.41,1c.14.55.45.94,1.06.76a1.2,1.2,0,0,1,1.29.14c-.41-1.29-1.18-1.18-2-1.33A1.36,1.36,0,0,1,204.75,137.27Z" />
                        <path class="cls-35" d="M201.24,137.27a8.32,8.32,0,0,1-.45,1c-.33.46-.45.94.07,1.3.67.46.68.81.67,1.1a1.45,1.45,0,0,0,.37-1.33,5.34,5.34,0,0,0-.54-1.05A1.53,1.53,0,0,1,201.24,137.27Z" />
                        <path class="cls-35" d="M201.52,137.39s.23.64.28,1c.07.57.32,1,.95.9a1.2,1.2,0,0,1,1.26.31c-.23-1.33-1-1.33-1.78-1.59C202,138,201.82,137.82,201.52,137.39Z" />
                        <path class="cls-35" d="M198,136.92a8.37,8.37,0,0,1-.57.89c-.39.42-.58.87-.11,1.3s.56.9.52,1.18a1.45,1.45,0,0,0,.54-1.27,5.41,5.41,0,0,0-.4-1.11A1.52,1.52,0,0,1,198,136.92Z" />
                        <path class="cls-35" d="M198.31,137.08s.14.67.14,1.05c0,.57.18,1,.82,1a1.2,1.2,0,0,1,1.2.48c-.05-1.35-.83-1.45-1.55-1.81C198.69,137.7,198.54,137.54,198.31,137.08Z" />
                        <path class="cls-35" d="M194.93,136.14a8.28,8.28,0,0,1-.69.8c-.44.36-.69.78-.29,1.27s.44,1,.35,1.24a1.45,1.45,0,0,0,.71-1.18,5.4,5.4,0,0,0-.24-1.16A1.53,1.53,0,0,1,194.93,136.14Z" />
                        <path class="cls-35" d="M195.17,136.33s0,.68,0,1.06c-.09.56,0,1,.67,1.12.8.08,1,.39,1.13.64.13-1.35-.62-1.55-1.28-2A1.36,1.36,0,0,1,195.17,136.33Z" />
                        <path class="cls-35" d="M191.95,134.94a8.3,8.3,0,0,1-.79.7c-.48.3-.79.68-.46,1.22s.3,1,.18,1.27a1.44,1.44,0,0,0,.87-1.08,5.36,5.36,0,0,0-.08-1.18A1.53,1.53,0,0,1,191.95,134.94Z" />
                        <path class="cls-35" d="M192.16,135.16s0,.68-.15,1.05c-.17.55-.1,1,.51,1.2.78.19.92.52,1,.79.32-1.32-.4-1.62-1-2.16A1.36,1.36,0,0,1,192.16,135.16Z" />
                        <path class="cls-35" d="M189.16,133.34a8.3,8.3,0,0,1-.88.59c-.52.23-.88.57-.62,1.15.33.74.16,1,0,1.29a1.45,1.45,0,0,0,1-.95,5.37,5.37,0,0,0,.08-1.18A1.53,1.53,0,0,1,189.16,133.34Z" />
                        <path class="cls-35" d="M189.34,133.58s-.14.67-.3,1c-.24.52-.24,1,.34,1.26.75.3.84.64.91.92.49-1.26-.18-1.66-.69-2.28A1.36,1.36,0,0,1,189.34,133.58Z" />
                        <path class="cls-35" d="M186.62,131.37a8.37,8.37,0,0,1-1,.46c-.55.15-.95.44-.77,1.05.23.78,0,1.06-.17,1.27a1.45,1.45,0,0,0,1.13-.8,5.37,5.37,0,0,0,.24-1.16A1.52,1.52,0,0,1,186.62,131.37Z" />
                        <path class="cls-35" d="M186.76,131.64s-.23.64-.43,1c-.31.48-.38,1,.17,1.29.7.4.74.75.78,1,.66-1.18.05-1.67-.37-2.35A1.36,1.36,0,0,1,186.76,131.64Z" />
                        <path class="cls-35" d="M184.36,129.08a8.34,8.34,0,0,1-1,.33c-.56.08-1,.31-.91.94.12.8-.13,1.05-.34,1.24a1.45,1.45,0,0,0,1.22-.64,5.35,5.35,0,0,0,.4-1.11C183.81,129.58,183.94,129.41,184.36,129.08Z" />
                        <path class="cls-35" d="M184.46,129.36s-.31.61-.56.9-.51.91,0,1.3.63.84.63,1.13c.82-1.08.28-1.65-.05-2.38A1.36,1.36,0,0,1,184.46,129.36Z" />
                        <path class="cls-35" d="M182.44,126.5a8.32,8.32,0,0,1-1,.19c-.57,0-1,.17-1,.81,0,.81-.27,1-.51,1.18a1.44,1.44,0,0,0,1.3-.47,5.37,5.37,0,0,0,.54-1.05C181.82,126.92,182,126.77,182.44,126.5Z" />
                        <path class="cls-35" d="M182.5,126.8s-.39.56-.68.82-.63.84-.18,1.29a1.2,1.2,0,0,1,.47,1.21c1-1,.5-1.59.27-2.37A1.36,1.36,0,0,1,182.5,126.8Z" />
                        <path class="cls-35" d="M180.88,123.69a8.32,8.32,0,0,1-1.06,0c-.56-.08-1,0-1.13.66-.1.8-.41,1-.66,1.1a1.45,1.45,0,0,0,1.35-.29,5.37,5.37,0,0,0,.68-1A1.53,1.53,0,0,1,180.88,123.69Z" />
                        <path class="cls-35" d="M180.9,124s-.47.5-.78.72c-.47.32-.74.74-.36,1.25a1.2,1.2,0,0,1,.3,1.26c1.08-.82.71-1.51.59-2.31A1.36,1.36,0,0,1,180.9,124Z" />
                        <path class="cls-35" d="M179.72,120.69a8.23,8.23,0,0,1-1.05-.1c-.55-.15-1-.11-1.21.5-.21.78-.54.91-.81,1A1.44,1.44,0,0,0,178,122a5.38,5.38,0,0,0,.81-.86A1.52,1.52,0,0,1,179.72,120.69Z" />
                        <path class="cls-35" d="M179.71,121s-.53.43-.87.61c-.51.25-.83.64-.53,1.19a1.2,1.2,0,0,1,.13,1.29c1.18-.67.91-1.4.9-2.21A1.36,1.36,0,0,1,179.71,121Z" />
                        <path class="cls-35" d="M179,117.56a8.3,8.3,0,0,1-1-.25c-.52-.23-1-.25-1.26.33-.31.75-.66.83-.94.88a1.45,1.45,0,0,0,1.38.09,5.37,5.37,0,0,0,.92-.74A1.53,1.53,0,0,1,179,117.56Z" />
                        <path class="cls-35" d="M178.93,117.86s-.58.36-.95.48c-.54.18-.91.52-.68,1.11a1.2,1.2,0,0,1-.05,1.29c1.26-.5,1.09-1.26,1.2-2.06A1.36,1.36,0,0,1,178.93,117.86Z" />
                        <path class="cls-35" d="M178.69,114.36a8.39,8.39,0,0,1-1-.38c-.49-.29-1-.39-1.3.15-.41.7-.76.73-1,.75a1.45,1.45,0,0,0,1.35.28,5.38,5.38,0,0,0,1-.61A1.53,1.53,0,0,1,178.69,114.36Z" />
                        <path class="cls-35" d="M178.59,114.65s-.63.27-1,.35c-.56.1-1,.39-.83,1a1.2,1.2,0,0,1-.23,1.27c1.31-.32,1.26-1.1,1.47-1.88C178.06,115.15,178.18,115,178.59,114.65Z" />
                        <path class="cls-35" d="M178.82,111.15a8.24,8.24,0,0,1-.93-.51c-.44-.36-.91-.51-1.3,0s-.86.62-1.14.6a1.44,1.44,0,0,0,1.31.46,5.35,5.35,0,0,0,1.08-.47A1.53,1.53,0,0,1,178.82,111.15Z" />
                        <path class="cls-35" d="M178.69,111.42s-.66.19-1,.21c-.57,0-1,.25-1,.88a1.2,1.2,0,0,1-.4,1.23c1.35-.14,1.39-.92,1.71-1.66C178.1,111.84,178.23,111.69,178.69,111.42Z" />
                        <path class="cls-35" d="M179.39,108a8.28,8.28,0,0,1-.85-.63c-.39-.41-.83-.63-1.29-.2s-.93.5-1.21.44a1.44,1.44,0,0,0,1.23.63,5.37,5.37,0,0,0,1.14-.32A1.53,1.53,0,0,1,179.39,108Z" />
                        <path class="cls-35" d="M179.22,108.23s-.68.09-1.06.07c-.57-.05-1,.11-1.07.74,0,.8-.32,1-.56,1.17,1.35,0,1.5-.73,1.91-1.42C178.57,108.57,178.73,108.44,179.22,108.23Z" />
                        <path class="cls-35" d="M180.39,104.93a8.25,8.25,0,0,1-.75-.75c-.33-.46-.74-.74-1.25-.37s-1,.37-1.26.27a1.45,1.45,0,0,0,1.13.79,5.38,5.38,0,0,0,1.17-.16A1.53,1.53,0,0,1,180.39,104.93Z" />
                        <path class="cls-35" d="M180.18,105.15s-.68,0-1.06-.08c-.56-.13-1,0-1.16.59-.14.79-.46,1-.71,1.08,1.34.23,1.59-.51,2.09-1.14A1.36,1.36,0,0,1,180.18,105.15Z" />
                        <path class="cls-35" d="M181.79,102a8.31,8.31,0,0,1-.64-.84c-.26-.5-.63-.84-1.19-.54-.71.38-1,.23-1.28.09a1.45,1.45,0,0,0,1,.94,5.4,5.4,0,0,0,1.18,0A1.53,1.53,0,0,1,181.79,102Z" />
                        <path class="cls-35" d="M181.56,102.23s-.68-.09-1-.23c-.53-.2-1-.17-1.23.43-.25.77-.58.88-.86,1,1.29.41,1.64-.29,2.23-.84A1.36,1.36,0,0,1,181.56,102.23Z" />
                        <path class="cls-35" d="M183.58,99.37a8.3,8.3,0,0,1-.52-.92c-.19-.53-.51-.91-1.1-.7-.76.28-1.06.09-1.28-.08a1.45,1.45,0,0,0,.88,1.07,5.35,5.35,0,0,0,1.17.16A1.53,1.53,0,0,1,183.58,99.37Z" />
                        <path class="cls-35" d="M183.32,99.53s-.66-.18-1-.36c-.5-.27-1-.31-1.28.26-.35.73-.7.79-1,.84,1.22.58,1.67-.06,2.32-.53A1.36,1.36,0,0,1,183.32,99.53Z" />
                        <path class="cls-35" d="M185.71,97a8.33,8.33,0,0,1-.39-1c-.12-.55-.38-1-1-.84-.79.18-1.06-.06-1.26-.26a1.45,1.45,0,0,0,.72,1.18,5.36,5.36,0,0,0,1.14.32A1.53,1.53,0,0,1,185.71,97Z" />
                        <path class="cls-35" d="M185.43,97.08s-.63-.27-.94-.5-.95-.44-1.3.08-.8.69-1.09.7c1.13.74,1.66.16,2.37-.21A1.36,1.36,0,0,1,185.43,97.08Z" />
                        <path class="cls-35" d="M188.15,94.87a8.36,8.36,0,0,1-.26-1c0-.57-.24-1-.88-1-.81.07-1-.2-1.21-.42a1.45,1.45,0,0,0,.56,1.27,5.38,5.38,0,0,0,1.08.47C187.69,94.28,187.85,94.42,188.15,94.87Z" />
                        <path class="cls-35" d="M187.86,94.95s-.58-.35-.86-.62-.88-.57-1.3-.1-.88.58-1.17.55c1,.89,1.62.39,2.38.11A1.36,1.36,0,0,1,187.86,94.95Z" />
                        <path class="cls-35" d="M190.85,93.12a8.26,8.26,0,0,1-.12-1.05c0-.57-.1-1-.73-1.08-.81,0-1-.34-1.15-.59a1.44,1.44,0,0,0,.38,1.33,5.35,5.35,0,0,0,1,.61A1.53,1.53,0,0,1,190.85,93.12Z" />
                        <path class="cls-35" d="M190.55,93.17s-.53-.43-.77-.73c-.35-.45-.79-.68-1.27-.27a1.2,1.2,0,0,1-1.24.39c.89,1,1.56.6,2.34.43A1.36,1.36,0,0,1,190.55,93.17Z" />
                        <path class="cls-35" d="M193.76,91.76a8.37,8.37,0,0,1,0-1.06c.11-.55,0-1-.58-1.17-.8-.15-.95-.47-1.06-.74a1.44,1.44,0,0,0,.19,1.37,5.38,5.38,0,0,0,.92.75A1.53,1.53,0,0,1,193.76,91.76Z" />
                        <path class="cls-35" d="M193.46,91.76s-.47-.5-.66-.83c-.29-.49-.69-.78-1.22-.44a1.2,1.2,0,0,1-1.28.21c.75,1.13,1.46.81,2.26.75A1.36,1.36,0,0,1,193.46,91.76Z" />
                        <path class="cls-35" d="M196.83,90.82a8.33,8.33,0,0,1,.17-1c.19-.53.18-1-.41-1.24-.77-.26-.87-.6-.94-.87a1.45,1.45,0,0,0,0,1.38,5.36,5.36,0,0,0,.81.86A1.53,1.53,0,0,1,196.83,90.82Z" />
                        <path class="cls-35" d="M196.53,90.78s-.39-.56-.54-.91c-.22-.53-.58-.87-1.15-.61a1.2,1.2,0,0,1-1.29,0c.59,1.22,1.33,1,2.14,1.05A1.36,1.36,0,0,1,196.53,90.78Z" />
                        <path class="cls-35" d="M200,90.3a8.3,8.3,0,0,1,.32-1c.26-.5.32-1-.24-1.28-.73-.36-.78-.71-.82-1a1.45,1.45,0,0,0-.18,1.37,5.37,5.37,0,0,0,.68,1A1.53,1.53,0,0,1,200,90.3Z" />
                        <path class="cls-35" d="M199.71,90.22s-.32-.61-.41-1c-.14-.55-.45-.94-1.06-.76a1.2,1.2,0,0,1-1.29-.14c.41,1.29,1.18,1.18,2,1.33A1.36,1.36,0,0,1,199.71,90.22Z" />
                        <path class="cls-35" d="M203.22,90.21a8.38,8.38,0,0,1,.45-1c.33-.46.45-.94-.07-1.3-.67-.46-.68-.81-.67-1.1a1.45,1.45,0,0,0-.37,1.33,5.33,5.33,0,0,0,.54,1.05A1.53,1.53,0,0,1,203.22,90.21Z" />
                        <path class="cls-35" d="M202.94,90.1s-.23-.64-.28-1c-.07-.57-.32-1-.95-.89a1.2,1.2,0,0,1-1.26-.31c.23,1.33,1,1.33,1.78,1.59C202.48,89.53,202.65,89.66,202.94,90.1Z" />
                        <path class="cls-35" d="M206.42,90.56a8.37,8.37,0,0,1,.57-.89c.39-.42.58-.87.11-1.3s-.56-.9-.52-1.18a1.45,1.45,0,0,0-.54,1.27,5.4,5.4,0,0,0,.4,1.11A1.53,1.53,0,0,1,206.42,90.56Z" />
                        <path class="cls-35" d="M206.16,90.41s-.14-.67-.14-1.05c0-.57-.18-1-.82-1a1.2,1.2,0,0,1-1.2-.48c.05,1.35.83,1.45,1.54,1.81C205.78,89.79,205.92,89.94,206.16,90.41Z" />
                        <path class="cls-35" d="M209.53,91.35a8.36,8.36,0,0,1,.69-.8c.44-.36.69-.78.29-1.27s-.44-1-.35-1.24a1.44,1.44,0,0,0-.71,1.18,5.39,5.39,0,0,0,.24,1.16A1.52,1.52,0,0,1,209.53,91.35Z" />
                        <path class="cls-35" d="M209.3,91.16s0-.68,0-1.06c.09-.56,0-1-.67-1.12-.8-.08-1-.39-1.13-.64-.13,1.35.62,1.55,1.28,2A1.36,1.36,0,0,1,209.3,91.16Z" />
                        <path class="cls-35" d="M212.51,92.55a8.25,8.25,0,0,1,.79-.7c.48-.3.79-.68.46-1.22s-.3-1-.18-1.27a1.44,1.44,0,0,0-.87,1.08,5.36,5.36,0,0,0,.08,1.18A1.53,1.53,0,0,1,212.51,92.55Z" />
                        <path class="cls-35" d="M212.3,92.33s0-.68.15-1.05c.17-.55.1-1-.51-1.2-.78-.19-.92-.52-1-.79-.32,1.32.4,1.62,1,2.16A1.36,1.36,0,0,1,212.3,92.33Z" />
                        <path class="cls-35" d="M215.3,94.15a8.34,8.34,0,0,1,.88-.59c.52-.23.88-.57.62-1.15-.33-.74-.16-1,0-1.29a1.45,1.45,0,0,0-1,.95,5.35,5.35,0,0,0-.08,1.18A1.53,1.53,0,0,1,215.3,94.15Z" />
                        <path class="cls-35" d="M215.12,93.9s.14-.67.3-1c.24-.52.24-1-.34-1.26-.75-.3-.84-.64-.91-.92-.49,1.26.18,1.66.69,2.28A1.36,1.36,0,0,1,215.12,93.9Z" />
                        <path class="cls-35" d="M217.85,96.12a8.36,8.36,0,0,1,1-.46c.55-.15.95-.44.77-1.05-.23-.78,0-1.06.17-1.27a1.45,1.45,0,0,0-1.13.8,5.37,5.37,0,0,0-.24,1.16A1.53,1.53,0,0,1,217.85,96.12Z" />
                        <path class="cls-35" d="M217.71,95.85s.23-.64.43-1c.31-.48.38-1-.17-1.29-.7-.4-.74-.75-.78-1-.66,1.18-.05,1.67.37,2.35A1.36,1.36,0,0,1,217.71,95.85Z" />
                        <path class="cls-35" d="M220.1,98.41a8.27,8.27,0,0,1,1-.33c.56-.08,1-.31.91-.94-.12-.8.13-1.05.34-1.24a1.45,1.45,0,0,0-1.22.64,5.35,5.35,0,0,0-.4,1.11C220.65,97.91,220.52,98.08,220.1,98.41Z" />
                        <path class="cls-35" d="M220,98.12s.31-.61.56-.9.51-.91,0-1.3-.63-.84-.63-1.13c-.82,1.08-.28,1.65.05,2.38A1.36,1.36,0,0,1,220,98.12Z" />
                        <path class="cls-35" d="M222,101a8.25,8.25,0,0,1,1-.19c.57,0,1-.17,1-.81,0-.81.27-1,.51-1.18a1.45,1.45,0,0,0-1.3.47,5.38,5.38,0,0,0-.54,1C222.64,100.56,222.49,100.71,222,101Z" />
                        <path class="cls-35" d="M222,100.69s.39-.56.68-.82.63-.84.18-1.29a1.2,1.2,0,0,1-.47-1.21c-1,1-.5,1.59-.27,2.37A1.36,1.36,0,0,1,222,100.69Z" />
                        <path class="cls-35" d="M223.58,103.8a8.33,8.33,0,0,1,1.06,0c.56.08,1,0,1.13-.66.1-.8.41-1,.66-1.1a1.45,1.45,0,0,0-1.35.29,5.36,5.36,0,0,0-.68,1A1.52,1.52,0,0,1,223.58,103.8Z" />
                        <path class="cls-35" d="M223.56,103.49s.46-.5.78-.72c.47-.32.74-.74.36-1.25a1.2,1.2,0,0,1-.3-1.26c-1.08.82-.71,1.51-.59,2.31A1.36,1.36,0,0,1,223.56,103.49Z" />
                        <path class="cls-35" d="M224.74,106.79a8.35,8.35,0,0,1,1.05.1c.55.15,1,.11,1.21-.5.21-.78.54-.91.81-1a1.44,1.44,0,0,0-1.38.1,5.35,5.35,0,0,0-.81.86A1.53,1.53,0,0,1,224.74,106.79Z" />
                        <path class="cls-35" d="M224.76,106.49s.53-.43.87-.61c.51-.25.83-.64.53-1.19a1.2,1.2,0,0,1-.13-1.29c-1.18.67-.91,1.4-.9,2.21A1.35,1.35,0,0,1,224.76,106.49Z" />
                        <path class="cls-35" d="M225.47,109.92a8.34,8.34,0,0,1,1,.25c.52.23,1,.25,1.26-.33.31-.75.66-.83.94-.88a1.45,1.45,0,0,0-1.38-.09,5.36,5.36,0,0,0-.92.74A1.53,1.53,0,0,1,225.47,109.92Z" />
                        <path class="cls-35" d="M225.53,109.62s.58-.36.95-.48c.54-.18.91-.52.68-1.11a1.2,1.2,0,0,1,.05-1.29c-1.26.5-1.09,1.26-1.2,2.06A1.36,1.36,0,0,1,225.53,109.62Z" />
                        <path class="cls-35" d="M225.77,113.12a8.33,8.33,0,0,1,1,.38c.49.29,1,.39,1.3-.15.41-.7.76-.73,1-.75a1.45,1.45,0,0,0-1.35-.28,5.37,5.37,0,0,0-1,.61A1.53,1.53,0,0,1,225.77,113.12Z" />
                        <path class="cls-35" d="M225.87,112.84s.63-.27,1-.35c.56-.1,1-.39.83-1a1.2,1.2,0,0,1,.23-1.27c-1.31.32-1.26,1.1-1.47,1.88C226.4,112.34,226.28,112.51,225.87,112.84Z" />
                    </g>
                    <path class="cls-36" d="M179.07,112l0-.53,2.31.21,0,.53Z" />
                    <path class="cls-36" d="M179.54,108.71l2.28.4-.08.43-1.59.89,1.39.25-.09.52-2.28-.4.08-.44,1.59-.89-1.39-.25Z" />
                    <path class="cls-36" d="M180.28,107.65l-.21.71-.42-.12.57-1.94.42.12-.21.71,1.8.53-.15.51Z" />
                    <path class="cls-36" d="M182.81,104.9l.4.16-.68,1.66-2.15-.87.66-1.62.4.16-.46,1.13.47.19.41-1,.39.16-.41,1,.5.2Z" />
                    <path class="cls-36" d="M184.06,103.45l-.78.09v0L183,104l.57.31-.25.47-2-1.1.48-.88a1.29,1.29,0,0,1,.34-.42.77.77,0,0,1,.87-.06.79.79,0,0,1,.33.32.78.78,0,0,1,.08.45l.9-.1Zm-1.63-.72a.56.56,0,0,0-.27.27l-.21.38.71.38.21-.38A.56.56,0,0,0,183,103a.37.37,0,0,0-.2-.25A.37.37,0,0,0,182.44,102.72Z" />
                    <path class="cls-36" d="M183.84,99.6l1.89,1.33-.25.36-1.81.13,1.15.81-.3.43-1.89-1.33.26-.36,1.81-.13-1.15-.81Z" />
                    <path class="cls-36" d="M186.7,99.09l-.72.8.23.49-.37.41-1-2.32.35-.39,2.41.78-.38.42Zm-.42-.15-.91-.32.41.87Z" />
                    <path class="cls-36" d="M186.73,97.11l-.55.5-.29-.32,1.5-1.36.29.32-.55.5,1.26,1.39-.4.36Z" />
                    <path class="cls-36" d="M187.7,95.7l.42-.33,1.43,1.82-.42.33Z" />
                    <path class="cls-36" d="M190.27,96.52a1.15,1.15,0,0,1-.61-.11,1.23,1.23,0,0,1-.67-1,1.15,1.15,0,0,1,.15-.61,1.43,1.43,0,0,1,1.09-.71,1.18,1.18,0,0,1,1.13,1.75,1.43,1.43,0,0,1-1.09.71Zm.64-.89a.69.69,0,0,0,.08-.36.83.83,0,0,0-.42-.64.68.68,0,0,0-.36-.08.78.78,0,0,0-.62.4.68.68,0,0,0-.08.36.83.83,0,0,0,.42.64.67.67,0,0,0,.36.08.77.77,0,0,0,.62-.4Z" />
                    <path class="cls-36" d="M193.18,92.4l1,2.09-.4.19-1.65-.77.61,1.27-.48.23-1-2.09.4-.19,1.65.77-.61-1.27Z" />
                    <path class="cls-36" d="M195.9,93.36l-1,.34,0,.54-.52.18.24-2.53.5-.17,1.72,1.87-.53.18Zm-.29-.33-.63-.72-.07,1Z" />
                    <path class="cls-36" d="M196.45,91.31l.52-.12.41,1.83,1.13-.25.09.43-1.66.37Z" />
                    <path class="cls-36" d="M201.25,92.43l-1.07.07L200,93l-.55,0,.87-2.38.53,0,1.19,2.24-.56,0Zm-.2-.39-.43-.86-.3.91Z" />
                    <path class="cls-36" d="M202.27,90.6h.54l0,2.32h-.54Z" />
                    <path class="cls-36" d="M204.51,90.68l1,.15a1.49,1.49,0,0,1,.64.24,1.08,1.08,0,0,1,.39.47,1.23,1.23,0,0,1-.17,1.21,1.08,1.08,0,0,1-.5.34,1.48,1.48,0,0,1-.68,0l-1-.15Zm.75,2a.78.78,0,0,0,.58-.11.77.77,0,0,0,.15-1,.78.78,0,0,0-.52-.27l-.49-.07-.21,1.42Z" />
                    <path class="cls-36" d="M208.26,93.79,208,93h0l-.45-.12-.17.62-.52-.14.61-2.24,1,.26a1.29,1.29,0,0,1,.49.24.79.79,0,0,1,.26.37.8.8,0,0,1,0,.46.76.76,0,0,1-.65.58l.31.86Zm.34-1.75a.57.57,0,0,0-.33-.2l-.42-.11-.21.78.42.11a.55.55,0,0,0,.38,0,.42.42,0,0,0,.16-.58Z" />
                    <path class="cls-36" d="M209.9,91.89l.5.18-.79,2.18-.5-.18Z" />
                    <path class="cls-36" d="M213.07,93.24l-1.88,1.69-.48-.22.06-2.52.53.24-.05,1.8L212.59,93Z" />
                    <path class="cls-36" d="M213.35,93.43l.47.26-1.13,2-.47-.26Z" />
                    <path class="cls-36" d="M216.19,95.22l-1.31,1.91-.36-.25-.16-1.81-.79,1.16-.44-.3L214.43,94l.37.25.16,1.81.79-1.16Z" />
                    <path class="cls-36" d="M217.2,97.55l.37.32-.62.71a1.42,1.42,0,0,1-.47-.12,1.7,1.7,0,0,1-.43-.27,1.36,1.36,0,0,1-.39-.54,1.14,1.14,0,0,1-.06-.62,1.24,1.24,0,0,1,.81-.93,1.14,1.14,0,0,1,.62,0,1.38,1.38,0,0,1,.59.32,1.43,1.43,0,0,1,.35.44,1.09,1.09,0,0,1,.12.5h-.47a.8.8,0,0,0-.28-.59.82.82,0,0,0-.36-.19.66.66,0,0,0-.37,0,.78.78,0,0,0-.33.23.75.75,0,0,0-.18.35.68.68,0,0,0,0,.37.79.79,0,0,0,.23.33.87.87,0,0,0,.38.2Z" />
                    <path class="cls-36" d="M219.49,98.26l.35.41-1.43,1.22.75.89-.33.28-1.1-1.29Z" />
                    <path class="cls-36" d="M220.83,99.91l.32.43-1.87,1.37-.32-.43Z" />
                    <path class="cls-36" d="M219.92,102.44a1.15,1.15,0,0,1,.13-.61,1.25,1.25,0,0,1,1.05-.65,1.14,1.14,0,0,1,.6.16,1.33,1.33,0,0,1,.47.47,1.37,1.37,0,0,1,.19.52,1.1,1.1,0,0,1,0,.51l-.45-.13a.74.74,0,0,0-.07-.64.77.77,0,0,0-.28-.28.68.68,0,0,0-.36-.09.83.83,0,0,0-.66.4.67.67,0,0,0-.09.36.77.77,0,0,0,.12.38.73.73,0,0,0,.54.35l-.09.46a1.08,1.08,0,0,1-.48-.19,1.38,1.38,0,0,1-.38-.41A1.33,1.33,0,0,1,219.92,102.44Z" />
                    <path class="cls-36" d="M221.94,105.56l-.39.18-.77-1.62,2.09-1,.75,1.58-.39.18-.52-1.1-.46.22.46,1-.38.18-.46-1-.49.23Z" />
                    <path class="cls-36" d="M224.57,107.35l-2.19.75-.14-.42,1-1.55-1.33.45-.17-.5,2.19-.75.14.42-1,1.54,1.33-.45Z" />
                    <path class="cls-36" d="M222.58,108.78a1.25,1.25,0,0,1,.1-.44l.43.09a1.23,1.23,0,0,0-.1.38,1.34,1.34,0,0,0,0,.42.65.65,0,0,0,.14.32.2.2,0,0,0,.2.07.17.17,0,0,0,.12-.09.5.5,0,0,0,.05-.18c0-.07,0-.17,0-.28a3.81,3.81,0,0,1,0-.45.7.7,0,0,1,.14-.33.54.54,0,0,1,.34-.2.66.66,0,0,1,.38,0,.74.74,0,0,1,.32.27,1.36,1.36,0,0,1,.21.5,1.82,1.82,0,0,1,0,.43,1.27,1.27,0,0,1-.07.39l-.43-.07a1.3,1.3,0,0,0,0-.67.61.61,0,0,0-.14-.31.22.22,0,0,0-.21-.06.19.19,0,0,0-.15.16,2,2,0,0,0,0,.39,3.81,3.81,0,0,1,0,.45.73.73,0,0,1-.13.33.53.53,0,0,1-.33.2.63.63,0,0,1-.38,0,.74.74,0,0,1-.33-.27,1.33,1.33,0,0,1-.21-.51A1.87,1.87,0,0,1,222.58,108.78Z" />
                    <path class="cls-36" d="M223.54,112.3l-.43,0-.21-1.78,2.3-.27.2,1.74-.43,0-.14-1.21-.5.06.12,1.07-.41,0-.12-1.07-.53.06Z" />
                    <path class="cls-36" d="M189.06,106.36h1.71v7.38h-1.71Z" />
                    <path class="cls-36" d="M197.39,112.16H194l-.65,1.58h-1.75l3.29-7.38h1.69l3.3,7.38H198Zm-.54-1.3L195.68,108l-1.17,2.83Z" />
                    <path class="cls-36" d="M200.6,106.36h1.71v7.38H200.6Z" />
                    <path class="cls-36" d="M204.06,106.36h3.35a4.72,4.72,0,0,1,2.13.46,3.44,3.44,0,0,1,1.43,1.29,3.94,3.94,0,0,1,0,3.88,3.43,3.43,0,0,1-1.43,1.29,4.72,4.72,0,0,1-2.13.46h-3.35Zm3.27,6a2.48,2.48,0,0,0,1.77-.62,2.45,2.45,0,0,0,0-3.34,2.48,2.48,0,0,0-1.77-.62h-1.56v4.58Z" />
                    <path class="cls-36" d="M212.78,106.36h1.71v6h3.7v1.39h-5.41Z" />
                    <path class="cls-36" d="M180.4,117.24a1.11,1.11,0,0,1-.44-.41,1.21,1.21,0,0,1,0-1.2,1.11,1.11,0,0,1,.44-.41,1.3,1.3,0,0,1,.62-.15,1.31,1.31,0,0,1,.53.1,1.06,1.06,0,0,1,.4.29l-.33.31a.72.72,0,0,0-.57-.26.76.76,0,0,0-.37.09.65.65,0,0,0-.25.25.8.8,0,0,0,0,.75.64.64,0,0,0,.25.25.75.75,0,0,0,.37.09.71.71,0,0,0,.57-.27l.33.31a1.05,1.05,0,0,1-.4.3,1.32,1.32,0,0,1-.53.1A1.3,1.3,0,0,1,180.4,117.24Z" />
                    <path class="cls-36" d="M184,116.93v.42h-1.74V115.1H184v.42h-1.18V116h1v.4h-1v.52Z" />
                    <path class="cls-36" d="M185.88,117.35l-.43-.63H185v.63h-.52V115.1h1a1.25,1.25,0,0,1,.52.1.77.77,0,0,1,.34.28.78.78,0,0,1,.12.43.73.73,0,0,1-.46.71l.5.72Zm-.13-1.72a.55.55,0,0,0-.36-.1H185v.78h.42a.54.54,0,0,0,.36-.1.4.4,0,0,0,0-.58Z" />
                    <path class="cls-36" d="M187.24,115.52h-.72v-.42h2v.42h-.72v1.82h-.52Z" />
                    <path class="cls-36" d="M188.76,115.1h.52v2.25h-.52Z" />
                    <path class="cls-36" d="M190.33,115.52v.59h1v.42h-1v.82h-.52V115.1h1.7v.42Z" />
                    <path class="cls-36" d="M191.86,115.1h.52v2.25h-.52Z" />
                    <path class="cls-36" d="M194.65,116.93v.42h-1.74V115.1h1.7v.42h-1.18V116h1v.4h-1v.52Z" />
                    <path class="cls-36" d="M195.07,115.1h1a1.43,1.43,0,0,1,.65.14,1,1,0,0,1,.44.39,1.19,1.19,0,0,1,0,1.18,1,1,0,0,1-.44.39,1.43,1.43,0,0,1-.65.14h-1Zm1,1.82a.75.75,0,0,0,.54-.19.74.74,0,0,0,0-1,.75.75,0,0,0-.54-.19h-.48v1.39Z" />
                    <path class="cls-36" d="M200.12,115.2a.77.77,0,0,1,.34.28.85.85,0,0,1,0,.87.75.75,0,0,1-.34.28,1.27,1.27,0,0,1-.52.1h-.45v.62h-.52V115.1h1A1.25,1.25,0,0,1,200.12,115.2Zm-.19,1a.4.4,0,0,0,0-.58.55.55,0,0,0-.36-.1h-.42v.78h.42A.55.55,0,0,0,199.93,116.2Z" />
                    <path class="cls-36" d="M202.38,117.35l-.43-.63h-.48v.63h-.52V115.1h1a1.25,1.25,0,0,1,.52.1.77.77,0,0,1,.34.28.78.78,0,0,1,.12.43.73.73,0,0,1-.46.71l.5.72Zm-.13-1.72a.55.55,0,0,0-.36-.1h-.42v.78h.42a.54.54,0,0,0,.36-.1.4.4,0,0,0,0-.58Z" />
                    <path class="cls-36" d="M203.76,117.23a1.11,1.11,0,0,1-.44-.42,1.19,1.19,0,0,1,0-1.19,1.11,1.11,0,0,1,.44-.42,1.31,1.31,0,0,1,.63-.15,1.3,1.3,0,0,1,.63.15,1.15,1.15,0,0,1,0,2,1.3,1.3,0,0,1-.63.15A1.31,1.31,0,0,1,203.76,117.23Zm1-.38a.65.65,0,0,0,.25-.25.8.8,0,0,0,0-.75.65.65,0,0,0-.25-.25.75.75,0,0,0-.72,0,.65.65,0,0,0-.25.25.8.8,0,0,0,0,.75.65.65,0,0,0,.25.25.75.75,0,0,0,.72,0Z" />
                    <path class="cls-36" d="M206.53,115.52v.59h1v.42h-1v.82H206V115.1h1.7v.42Z" />
                    <path class="cls-36" d="M209.8,116.93v.42h-1.74V115.1h1.7v.42h-1.18V116h1v.4h-1v.52Z" />
                    <path class="cls-36" d="M210.44,117.31a1.2,1.2,0,0,1-.4-.19l.18-.39a1.16,1.16,0,0,0,.34.17,1.29,1.29,0,0,0,.4.06.63.63,0,0,0,.33-.07.2.2,0,0,0,.11-.17.17.17,0,0,0-.06-.13.48.48,0,0,0-.16-.08l-.26-.07-.42-.12a.69.69,0,0,1-.28-.2.53.53,0,0,1-.12-.36.62.62,0,0,1,.11-.35.72.72,0,0,1,.32-.25,1.31,1.31,0,0,1,.52-.09,1.73,1.73,0,0,1,.42.05,1.23,1.23,0,0,1,.36.15l-.16.39a1.25,1.25,0,0,0-.62-.18.58.58,0,0,0-.32.07.22.22,0,0,0-.1.19.18.18,0,0,0,.12.17,1.92,1.92,0,0,0,.37.11l.42.12a.71.71,0,0,1,.28.19.52.52,0,0,1,.12.36.61.61,0,0,1-.11.35.72.72,0,0,1-.32.25,1.31,1.31,0,0,1-.52.09A1.82,1.82,0,0,1,210.44,117.31Z" />
                    <path class="cls-36" d="M212.49,117.31a1.2,1.2,0,0,1-.4-.19l.18-.39a1.16,1.16,0,0,0,.34.17,1.29,1.29,0,0,0,.4.06.63.63,0,0,0,.33-.07.2.2,0,0,0,.11-.17.17.17,0,0,0-.06-.13.48.48,0,0,0-.16-.08l-.26-.07-.42-.12a.69.69,0,0,1-.28-.2.53.53,0,0,1-.12-.36.62.62,0,0,1,.11-.35.72.72,0,0,1,.32-.25,1.31,1.31,0,0,1,.52-.09,1.73,1.73,0,0,1,.42.05,1.23,1.23,0,0,1,.36.15l-.16.39a1.25,1.25,0,0,0-.62-.18.58.58,0,0,0-.32.07.22.22,0,0,0-.1.19.18.18,0,0,0,.12.17,1.92,1.92,0,0,0,.37.11l.42.12a.71.71,0,0,1,.28.19.52.52,0,0,1,.12.36.61.61,0,0,1-.11.35.72.72,0,0,1-.32.25,1.31,1.31,0,0,1-.52.09A1.82,1.82,0,0,1,212.49,117.31Z" />
                    <path class="cls-36" d="M214.31,115.1h.52v2.25h-.52Z" />
                    <path class="cls-36" d="M215.82,117.23a1.11,1.11,0,0,1-.44-.42,1.19,1.19,0,0,1,0-1.19,1.11,1.11,0,0,1,.44-.42,1.31,1.31,0,0,1,.63-.15,1.3,1.3,0,0,1,.63.15,1.15,1.15,0,0,1,0,2,1.3,1.3,0,0,1-.63.15A1.31,1.31,0,0,1,215.82,117.23Zm1-.38a.65.65,0,0,0,.25-.25.8.8,0,0,0,0-.75.65.65,0,0,0-.25-.25.75.75,0,0,0-.72,0,.65.65,0,0,0-.25.25.8.8,0,0,0,0,.75.65.65,0,0,0,.25.25.75.75,0,0,0,.72,0Z" />
                    <path class="cls-36" d="M220.14,115.1v2.25h-.43L218.59,116v1.36h-.51V115.1h.43l1.12,1.36V115.1Z" />
                    <path class="cls-36" d="M222.15,116.87h-1l-.2.48h-.53l1-2.25h.51l1,2.25h-.55Zm-.16-.39-.36-.86-.36.86Z" />
                    <path class="cls-36" d="M223.13,115.1h.52v1.82h1.13v.42h-1.65Z" />
                    <polygon class="cls-36" points="202.24 95.75 203.26 97.82 205.54 98.15 203.89 99.76 204.28 102.03 202.24 100.96 200.2 102.03 200.59 99.76 198.94 98.15 201.22 97.82 202.24 95.75" />
                    <polygon class="cls-36" points="207.73 100.19 208.35 101.46 209.75 101.66 208.74 102.65 208.98 104.04 207.73 103.39 206.48 104.04 206.72 102.65 205.71 101.66 207.1 101.46 207.73 100.19" />
                    <polygon class="cls-36" points="196.75 100.19 197.38 101.46 198.77 101.66 197.76 102.65 198 104.04 196.75 103.39 195.5 104.04 195.74 102.65 194.73 101.66 196.12 101.46 196.75 100.19" />
                    <path class="cls-36" d="M190.75,123.09a1.11,1.11,0,0,1-.44-.41,1.21,1.21,0,0,1,0-1.2,1.11,1.11,0,0,1,.44-.41,1.3,1.3,0,0,1,.62-.15,1.31,1.31,0,0,1,.53.1,1.06,1.06,0,0,1,.4.29l-.33.31a.72.72,0,0,0-.57-.26.76.76,0,0,0-.37.09.65.65,0,0,0-.25.25.8.8,0,0,0,0,.75.64.64,0,0,0,.25.25.75.75,0,0,0,.37.09.71.71,0,0,0,.57-.27l.33.31a1.05,1.05,0,0,1-.4.3,1.32,1.32,0,0,1-.53.1A1.3,1.3,0,0,1,190.75,123.09Z" />
                    <path class="cls-36" d="M194.38,122.78v.42h-1.74V121h1.7v.42h-1.18v.49h1v.4h-1v.52Z" />
                    <path class="cls-36" d="M196.23,123.2l-.43-.63h-.48v.63h-.52V121h1a1.25,1.25,0,0,1,.52.1.77.77,0,0,1,.34.28.78.78,0,0,1,.12.43.73.73,0,0,1-.46.71l.5.72Zm-.13-1.72a.55.55,0,0,0-.36-.1h-.42v.78h.42a.54.54,0,0,0,.36-.1.4.4,0,0,0,0-.58Z" />
                    <path class="cls-36" d="M197.59,121.38h-.72V121h2v.42h-.72v1.82h-.52Z" />
                    <path class="cls-36" d="M199.11,121h.52v2.25h-.52Z" />
                    <path class="cls-36" d="M200.68,121.37V122h1v.42h-1v.82h-.52V121h1.7v.42Z" />
                    <path class="cls-36" d="M202.21,121h.52v2.25h-.52Z" />
                    <path class="cls-36" d="M203.72,123.09a1.11,1.11,0,0,1-.44-.41,1.21,1.21,0,0,1,0-1.2,1.11,1.11,0,0,1,.44-.41,1.3,1.3,0,0,1,.62-.15,1.31,1.31,0,0,1,.53.1,1.06,1.06,0,0,1,.4.29l-.33.31a.72.72,0,0,0-.57-.26.76.76,0,0,0-.37.09.65.65,0,0,0-.25.25.8.8,0,0,0,0,.75.64.64,0,0,0,.25.25.75.75,0,0,0,.37.09.71.71,0,0,0,.57-.27l.33.31a1.05,1.05,0,0,1-.4.3,1.32,1.32,0,0,1-.53.1A1.3,1.3,0,0,1,203.72,123.09Z" />
                    <path class="cls-36" d="M207.05,122.72h-1l-.2.48h-.53l1-2.25h.51l1,2.25h-.55Zm-.16-.39-.36-.86-.36.86Z" />
                    <path class="cls-36" d="M208.38,121.38h-.72V121h2v.42h-.72v1.82h-.52Z" />
                    <path class="cls-36" d="M211.64,122.78v.42H209.9V121h1.7v.42h-1.18v.49h1v.4h-1v.52Z" />
                    <path class="cls-36" d="M214.42,122.34h.42v.35h-.46l-.06.51h-.36l.06-.51h-.47l-.06.51h-.36l.06-.51h-.42v-.35h.46l.07-.52h-.42v-.35h.46l.06-.51h.36l-.06.51h.47l.06-.51h.36l-.06.51h.42v.35h-.46Zm-.36,0,.07-.52h-.47l-.07.52Z" />
                    <text class="cls-37" transform="translate(186.37 129.86)">{{code}}
                    </text>
                    <rect class="cls-1" x="1.3" width="240.94" height="155.91" />
                    <path class="cls-3" d="M188.79,41.24v.44h1.1v.84h-1.1V43H190v.89h-2.37V40.36H190v.88Z" />
                    <path class="cls-3" d="M193.05,43.42a1.08,1.08,0,0,1-.46.39,1.66,1.66,0,0,1-.72.14,1.75,1.75,0,0,1-1.05-.3,1.1,1.1,0,0,1-.45-.88h1.18c0,.2.11.31.27.31a.2.2,0,0,0,.14,0,.16.16,0,0,0,.05-.13.22.22,0,0,0-.13-.2,2.52,2.52,0,0,0-.41-.17,3.85,3.85,0,0,1-.56-.23,1.12,1.12,0,0,1-.39-.34,1.09,1.09,0,0,1,0-1.18,1.09,1.09,0,0,1,.49-.37,1.83,1.83,0,0,1,.7-.13,1.6,1.6,0,0,1,1,.3,1.12,1.12,0,0,1,.41.85h-1.2c0-.19-.09-.28-.23-.28a.16.16,0,0,0-.12,0,.17.17,0,0,0,0,.13.22.22,0,0,0,.13.19,2.68,2.68,0,0,0,.4.17,4.23,4.23,0,0,1,.56.24,1.17,1.17,0,0,1,.39.35,1,1,0,0,1,.17.58A1,1,0,0,1,193.05,43.42Z" />
                    <path class="cls-3" d="M196.19,43.42a1.08,1.08,0,0,1-.46.39,1.66,1.66,0,0,1-.72.14,1.75,1.75,0,0,1-1.05-.3,1.1,1.1,0,0,1-.45-.88h1.18c0,.2.11.31.27.31a.2.2,0,0,0,.14,0,.16.16,0,0,0,.05-.13.22.22,0,0,0-.13-.2,2.52,2.52,0,0,0-.41-.17,3.85,3.85,0,0,1-.56-.23,1.12,1.12,0,0,1-.39-.34,1.09,1.09,0,0,1,0-1.18,1.09,1.09,0,0,1,.49-.37,1.83,1.83,0,0,1,.7-.13,1.6,1.6,0,0,1,1,.3,1.12,1.12,0,0,1,.41.85h-1.2c0-.19-.09-.28-.23-.28a.16.16,0,0,0-.12,0,.17.17,0,0,0,0,.13.22.22,0,0,0,.13.19,2.68,2.68,0,0,0,.4.17,4.23,4.23,0,0,1,.56.24,1.17,1.17,0,0,1,.39.35,1,1,0,0,1,.17.58A1,1,0,0,1,196.19,43.42Z" />
                    <path class="cls-3" d="M197.85,41.24v.44H199v.84h-1.1V43h1.25v.89h-2.37V40.36h2.37v.88Z" />
                    <path class="cls-3" d="M202.94,43.91h-1.11l-1.18-1.79v1.79h-1.11V40.36h1.11l1.18,1.82V40.36h1.11Z" />
                    <path class="cls-3" d="M206.23,40.36v.88h-.94v2.67h-1.11V41.24h-.93v-.88Z" />
                    <path class="cls-3" d="M207.64,40.36v3.55h-1.11V40.36Z" />
                    <path class="cls-3" d="M210.42,43.38h-1.18l-.18.54h-1.17l1.3-3.55h1.29l1.29,3.55H210.6Zm-.27-.84-.32-1-.32,1Z" />
                    <path class="cls-3" d="M213.14,43.07h1.07v.84H212V40.36h1.11Z" />
                    <path class="cls-3" d="M217.15,43.42a1.08,1.08,0,0,1-.46.39,1.66,1.66,0,0,1-.72.14,1.75,1.75,0,0,1-1.05-.3,1.1,1.1,0,0,1-.45-.88h1.18c0,.2.11.31.27.31a.2.2,0,0,0,.14,0,.16.16,0,0,0,.05-.13.22.22,0,0,0-.13-.2,2.52,2.52,0,0,0-.41-.17,3.85,3.85,0,0,1-.56-.23,1.12,1.12,0,0,1-.39-.34,1.09,1.09,0,0,1,0-1.18,1.09,1.09,0,0,1,.49-.37,1.83,1.83,0,0,1,.7-.13,1.6,1.6,0,0,1,1,.3,1.12,1.12,0,0,1,.41.85H216c0-.19-.09-.28-.23-.28a.16.16,0,0,0-.12,0,.17.17,0,0,0,0,.13.22.22,0,0,0,.13.19,2.68,2.68,0,0,0,.4.17,4.23,4.23,0,0,1,.56.24,1.17,1.17,0,0,1,.39.35,1,1,0,0,1,.17.58A1,1,0,0,1,217.15,43.42Z" />
                    <path class="cls-3" d="M169.51,14.37a1.44,1.44,0,0,1,.52.77h-.43a1,1,0,0,0-.39-.5,1.12,1.12,0,0,0-.65-.18,1,1,0,0,0-.93.58,1.43,1.43,0,0,0-.14.65,1.4,1.4,0,0,0,.14.65,1,1,0,0,0,.38.42,1,1,0,0,0,.55.15,1.12,1.12,0,0,0,.65-.18,1,1,0,0,0,.39-.5H170a1.44,1.44,0,0,1-.52.77,1.53,1.53,0,0,1-.95.28,1.48,1.48,0,0,1-.78-.2,1.39,1.39,0,0,1-.53-.56,1.76,1.76,0,0,1-.19-.83,1.78,1.78,0,0,1,.19-.83,1.39,1.39,0,0,1,.53-.57,1.49,1.49,0,0,1,.78-.2A1.53,1.53,0,0,1,169.51,14.37Z" />
                    <path class="cls-3" d="M171,14.44v1.07h1.19v.31H171v1.09h1.33v.33h-1.73V14.12h1.73v.33Z" />
                    <path class="cls-3" d="M174.48,17.25,173.66,16h-.44v1.28h-.4V14.12h1a1.12,1.12,0,0,1,.8.26.9.9,0,0,1,.27.68.88.88,0,0,1-.21.62,1,1,0,0,1-.62.29l.85,1.29Zm-1.26-1.58h.59q.69,0,.69-.6t-.69-.61h-.59Z" />
                    <path class="cls-3" d="M177.33,14.12v.33h-.89v2.81H176V14.44h-.89v-.33Z" />
                    <path class="cls-3" d="M178.14,14.12v3.14h-.4V14.12Z" />
                    <path class="cls-3" d="M180.56,14.12v.33h-1.37v1.09h1.16v.32h-1.16v1.41h-.4V14.12Z" />
                    <path class="cls-3" d="M181.45,14.12v3.14H181V14.12Z" />
                    <path class="cls-3" d="M182.5,14.44v1.07h1.19v.31H182.5v1.09h1.33v.33H182.1V14.12h1.73v.33Z" />
                    <path class="cls-3" d="M186.8,16.51a1.31,1.31,0,0,1-.56.55,1.91,1.91,0,0,1-.89.19h-1V14.12h1a2,2,0,0,1,.89.19,1.28,1.28,0,0,1,.56.54,1.9,1.9,0,0,1,0,1.66Zm-.55.06a1.39,1.39,0,0,0,0-1.78,1.29,1.29,0,0,0-.94-.32h-.56v2.42h.56A1.29,1.29,0,0,0,186.26,16.57Z" />
                    <path class="cls-3" d="M189,16v1.26h-.4V14.12h1a1.14,1.14,0,0,1,.81.25.9.9,0,0,1,.27.69.88.88,0,0,1-.28.69,1.16,1.16,0,0,1-.8.25Zm1.09-.49a.58.58,0,0,0,.16-.44.59.59,0,0,0-.16-.45.76.76,0,0,0-.53-.15H189v1.2h.56A.75.75,0,0,0,190.13,15.49Z" />
                    <path class="cls-3" d="M192.85,17.25,192,16h-.44v1.28h-.4V14.12h1a1.12,1.12,0,0,1,.8.26.9.9,0,0,1,.27.68.88.88,0,0,1-.21.62,1,1,0,0,1-.62.29l.85,1.29Zm-1.26-1.58h.59q.69,0,.69-.6t-.69-.61h-.59Z" />
                    <path class="cls-3" d="M196,14.28a1.45,1.45,0,0,1,.55.57,1.82,1.82,0,0,1,0,1.66,1.46,1.46,0,0,1-.55.57,1.53,1.53,0,0,1-.79.2,1.55,1.55,0,0,1-.79-.2,1.45,1.45,0,0,1-.55-.57,1.82,1.82,0,0,1,0-1.66,1.44,1.44,0,0,1,.55-.57,1.54,1.54,0,0,1,.79-.21A1.53,1.53,0,0,1,196,14.28Zm-1.37.32a1,1,0,0,0-.4.43,1.54,1.54,0,0,0,0,1.3,1,1,0,0,0,.4.43,1.13,1.13,0,0,0,.58.15,1.11,1.11,0,0,0,.58-.15,1,1,0,0,0,.4-.43,1.52,1.52,0,0,0,0-1.3,1,1,0,0,0-.4-.43,1.2,1.2,0,0,0-1.16,0Z" />
                    <path class="cls-3" d="M199.07,14.12v.33H197.7v1.09h1.16v.32H197.7v1.41h-.4V14.12Z" />
                    <path class="cls-3" d="M200,14.44v1.07h1.19v.31H200v1.09h1.33v.33h-1.73V14.12h1.73v.33Z" />
                    <path class="cls-3" d="M203.68,16.85a.8.8,0,0,1-.32.31,1.11,1.11,0,0,1-.54.12,1.29,1.29,0,0,1-.55-.11,1,1,0,0,1-.39-.31.88.88,0,0,1-.17-.45h.43a.61.61,0,0,0,.09.26.6.6,0,0,0,.22.21.7.7,0,0,0,.35.08.55.55,0,0,0,.41-.14.49.49,0,0,0,.14-.36.47.47,0,0,0-.1-.31A.7.7,0,0,0,203,16l-.4-.15a4,4,0,0,1-.48-.18.81.81,0,0,1-.31-.26.76.76,0,0,1-.13-.46.79.79,0,0,1,.12-.43.77.77,0,0,1,.34-.29,1.22,1.22,0,0,1,.52-.1,1.12,1.12,0,0,1,.73.23.86.86,0,0,1,.32.57h-.44a.59.59,0,0,0-.19-.33.6.6,0,0,0-.42-.14.58.58,0,0,0-.39.13.45.45,0,0,0-.15.36.44.44,0,0,0,.1.3.66.66,0,0,0,.24.18l.39.14a3.94,3.94,0,0,1,.49.19.84.84,0,0,1,.31.27.8.8,0,0,1,.13.48A.84.84,0,0,1,203.68,16.85Z" />
                    <path class="cls-3" d="M206.21,16.85a.8.8,0,0,1-.32.31,1.11,1.11,0,0,1-.54.12,1.29,1.29,0,0,1-.55-.11,1,1,0,0,1-.39-.31.88.88,0,0,1-.17-.45h.43a.61.61,0,0,0,.09.26.6.6,0,0,0,.22.21.7.7,0,0,0,.35.08.55.55,0,0,0,.41-.14.49.49,0,0,0,.14-.36.47.47,0,0,0-.1-.31.7.7,0,0,0-.25-.19l-.4-.15a4,4,0,0,1-.48-.18.81.81,0,0,1-.31-.26.76.76,0,0,1-.13-.46.79.79,0,0,1,.12-.43.77.77,0,0,1,.34-.29,1.22,1.22,0,0,1,.52-.1,1.12,1.12,0,0,1,.73.23.86.86,0,0,1,.32.57h-.44a.59.59,0,0,0-.19-.33.6.6,0,0,0-.42-.14.58.58,0,0,0-.39.13.45.45,0,0,0-.15.36.44.44,0,0,0,.1.3.66.66,0,0,0,.24.18l.39.14a3.94,3.94,0,0,1,.49.19.84.84,0,0,1,.31.27.8.8,0,0,1,.13.48A.84.84,0,0,1,206.21,16.85Z" />
                    <path class="cls-3" d="M207.26,14.12v3.14h-.4V14.12Z" />
                    <path class="cls-3" d="M210.13,14.28a1.45,1.45,0,0,1,.55.57,1.82,1.82,0,0,1,0,1.66,1.46,1.46,0,0,1-.55.57,1.53,1.53,0,0,1-.79.2,1.55,1.55,0,0,1-.79-.2,1.45,1.45,0,0,1-.55-.57,1.82,1.82,0,0,1,0-1.66,1.44,1.44,0,0,1,.55-.57,1.54,1.54,0,0,1,.79-.21A1.53,1.53,0,0,1,210.13,14.28Zm-1.37.32a1,1,0,0,0-.4.43,1.54,1.54,0,0,0,0,1.3,1,1,0,0,0,.4.43,1.13,1.13,0,0,0,.58.15,1.11,1.11,0,0,0,.58-.15,1,1,0,0,0,.4-.43,1.52,1.52,0,0,0,0-1.3,1,1,0,0,0-.4-.43,1.2,1.2,0,0,0-1.16,0Z" />
                    <path class="cls-3" d="M213.87,17.25h-.41l-1.63-2.48v2.48h-.4V14.12h.4l1.63,2.48V14.12h.41Z" />
                    <path class="cls-3" d="M216.36,16.53H215l-.26.73h-.44l1.16-3.08h.46l1.16,3.08h-.44Zm-.12-.32-.57-1.58-.57,1.58Z" />
                    <path class="cls-3" d="M217.87,16.94H219v.32h-1.52V14.12h.4Z" />
                    <path class="cls-3" d="M216,39.07H169.89a3.06,3.06,0,0,1-3-3V21.37a3.06,3.06,0,0,1,3-3H216a3.06,3.06,0,0,1,3,3V36A3.06,3.06,0,0,1,216,39.07ZM167.11,21.68v14a3.06,3.06,0,0,0,3,3h45.6a3.06,3.06,0,0,0,3-3v-14a3.06,3.06,0,0,0-3-3h-45.6A3.06,3.06,0,0,0,167.11,21.68Z" />
                    <path class="cls-3" d="M177.43,25.18V37.09h-3.74V25.18Z" />
                    <path class="cls-3" d="M186.75,35.29h-4l-.59,1.8h-3.92l4.36-11.91h4.31l4.34,11.91h-3.94Zm-.91-2.81-1.08-3.32-1.08,3.32Z" />
                    <path class="cls-3" d="M195.85,25.18V37.09h-3.74V25.18Z" />
                    <path class="cls-3" d="M207.93,34.16a5.45,5.45,0,0,1-2.16,2.15,6.62,6.62,0,0,1-3.27.78h-5V25.18h5a6.8,6.8,0,0,1,3.27.76,5.32,5.32,0,0,1,2.16,2.1,6.57,6.57,0,0,1,0,6.13Zm-3.77-1.09a2.56,2.56,0,0,0,.74-2,2.56,2.56,0,0,0-.74-2,2.82,2.82,0,0,0-2-.69h-.89v5.32h.89A2.82,2.82,0,0,0,204.17,33.07Z" />
                    <path class="cls-3" d="M213.77,34.26h3.6v2.83H210V25.18h3.74Z" />
                    <path class="cls-3" d="M169.86,31.17a.64.64,0,0,0,.12-.74l1.34-1.34a.45.45,0,0,1,.32-.13h.62v.45a1.08,1.08,0,0,0,0,.27.2.2,0,1,0,.4-.1.67.67,0,0,1,0-.17v-4.8a.67.67,0,0,1,.67-.67h4.8l.17,0a.2.2,0,1,0,.1-.4,1.08,1.08,0,0,0-.27,0h-.45V21.72a.64.64,0,1,0-.41,0v1.81h-.46v-.89a.2.2,0,1,0-.41,0v.89h-.46V20.24a.2.2,0,0,0-.41,0v3.29h-.46v-.89a.2.2,0,1,0-.41,0v.89h-.46v-.62a.85.85,0,0,0-.25-.61L172.6,21a.64.64,0,1,0-.29.29l1.34,1.34a.45.45,0,0,1,.13.32v.62h-.45a1.08,1.08,0,0,0-1.08,1.08v.45h-1.81a.64.64,0,1,0,0,.41h1.81v.46h-.89a.2.2,0,0,0,0,.41h.89v.46H169a.2.2,0,0,0,0,.41h3.29v.46h-.89a.2.2,0,0,0,0,.41h.89v.46h-.62a.85.85,0,0,0-.61.25l-1.34,1.34a.64.64,0,1,0,.17,1Zm7.39-10.06a.23.23,0,1,1,.23.23A.23.23,0,0,1,177.25,21.12Zm-5.39-.27a.23.23,0,1,1,.16.07A.23.23,0,0,1,171.86,20.84Zm-2,4.65a.23.23,0,1,1,.23-.23A.23.23,0,0,1,169.84,25.5Zm-.6,5.39a.23.23,0,1,1,.33,0A.23.23,0,0,1,169.24,30.89Z" />
                </g>
            </g>
        </g>
    </svg>
  </div>
</template>

<script>
import db from '@/fb';


export default {
  props: ['name', 'code', 'date'],
  data() {
    return {
        size:0
    }
  },
  methods: {
    getSize(){
      this.size = (11 / this.$props.name.length) * 22
    }
  },
  computed: {
    
  }, 
  created(){
    this.getSize()
  },
  mounted() {
    this.getSize()
  }
}
</script>
