<template class="ma-0 pa-0">
  <div id="svg-container" class="ma-0 pa-0">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 595.3 841.9" style="enable-background:new 0 0 595.3 841.9;" xml:space="preserve">
<svg:style type="text/css">
	.st0{opacity:0.45;clip-path:url(#SVGID_2_);}
	.st1{opacity:0.45;}
	.st2{clip-path:url(#SVGID_4_);}
	.st3{fill:url(#SVGID_5_);}
	.st4{fill:url(#SVGID_6_);}
	.st5{opacity:0.6;fill:url(#SVGID_7_);}
	.st6{opacity:0.6;fill:url(#SVGID_8_);}
	.st7{opacity:0.6;fill:url(#SVGID_9_);}
	.st8{opacity:0.6;}
	.st9{clip-path:url(#SVGID_11_);fill:#3173B4;}
	.st10{clip-path:url(#SVGID_11_);fill:#3174B5;}
	.st11{clip-path:url(#SVGID_11_);fill:#3075B5;}
	.st12{clip-path:url(#SVGID_11_);fill:#3076B6;}
	.st13{clip-path:url(#SVGID_11_);fill:#2F77B7;}
	.st14{clip-path:url(#SVGID_11_);fill:#2F79B8;}
	.st15{clip-path:url(#SVGID_11_);fill:#2E7AB8;}
	.st16{clip-path:url(#SVGID_11_);fill:#2F7BB9;}
	.st17{clip-path:url(#SVGID_11_);fill:#2E7CB9;}
	.st18{clip-path:url(#SVGID_11_);fill:#2E7DBA;}
	.st19{clip-path:url(#SVGID_11_);fill:#2C7EBB;}
	.st20{clip-path:url(#SVGID_11_);fill:#2B7FBC;}
	.st21{clip-path:url(#SVGID_11_);fill:#2D7FBC;}
	.st22{clip-path:url(#SVGID_11_);fill:#2D81BD;}
	.st23{clip-path:url(#SVGID_11_);fill:#2C82BE;}
	.st24{clip-path:url(#SVGID_11_);fill:#2C83BE;}
	.st25{clip-path:url(#SVGID_11_);fill:#2C84C0;}
	.st26{clip-path:url(#SVGID_11_);fill:#2A86C0;}
	.st27{clip-path:url(#SVGID_11_);fill:#2B87C1;}
	.st28{clip-path:url(#SVGID_11_);fill:#2B88C2;}
	.st29{clip-path:url(#SVGID_11_);fill:#2A89C2;}
	.st30{clip-path:url(#SVGID_11_);fill:#2B8AC3;}
	.st31{clip-path:url(#SVGID_11_);fill:#2A8BC3;}
	.st32{clip-path:url(#SVGID_11_);fill:#288CC4;}
	.st33{clip-path:url(#SVGID_11_);fill:#288DC5;}
	.st34{clip-path:url(#SVGID_11_);fill:#2A8DC5;}
	.st35{clip-path:url(#SVGID_11_);fill:#298FC6;}
	.st36{clip-path:url(#SVGID_11_);fill:#2891C7;}
	.st37{clip-path:url(#SVGID_11_);fill:#2991C8;}
	.st38{clip-path:url(#SVGID_11_);fill:#2892C7;}
	.st39{clip-path:url(#SVGID_11_);fill:#2594C9;}
	.st40{clip-path:url(#SVGID_11_);fill:#2695C9;}
	.st41{clip-path:url(#SVGID_11_);fill:#2496CA;}
	.st42{clip-path:url(#SVGID_11_);fill:#2696CB;}
	.st43{clip-path:url(#SVGID_11_);fill:#2498CA;}
	.st44{clip-path:url(#SVGID_11_);fill:#2698CB;}
	.st45{clip-path:url(#SVGID_11_);fill:#2699CD;}
	.st46{clip-path:url(#SVGID_11_);fill:#289BCC;}
	.st47{clip-path:url(#SVGID_11_);fill:#269BCD;}
	.st48{clip-path:url(#SVGID_11_);fill:#289CCE;}
	.st49{clip-path:url(#SVGID_11_);fill:#269DCE;}
	.st50{clip-path:url(#SVGID_11_);fill:#239FCE;}
	.st51{clip-path:url(#SVGID_11_);fill:#24A0D0;}
	.st52{clip-path:url(#SVGID_11_);fill:#22A1CF;}
	.st53{clip-path:url(#SVGID_11_);fill:#1FA2D1;}
	.st54{clip-path:url(#SVGID_11_);fill:#22A3D1;}
	.st55{clip-path:url(#SVGID_11_);fill:#23A4D2;}
	.st56{clip-path:url(#SVGID_11_);fill:#20A5D3;}
	.st57{clip-path:url(#SVGID_11_);fill:#23A6D3;}
	.st58{clip-path:url(#SVGID_11_);fill:#20A7D4;}
	.st59{clip-path:url(#SVGID_11_);fill:#1DA8D5;}
	.st60{clip-path:url(#SVGID_11_);fill:#20A9D4;}
	.st61{clip-path:url(#SVGID_11_);fill:#1DAAD6;}
	.st62{clip-path:url(#SVGID_11_);fill:#1EABD7;}
	.st63{clip-path:url(#SVGID_11_);fill:#1DACD6;}
	.st64{clip-path:url(#SVGID_11_);fill:#1EADD7;}
	.st65{clip-path:url(#SVGID_11_);fill:#1CAED8;}
	.st66{clip-path:url(#SVGID_11_);fill:#1EAFD8;}
	.st67{clip-path:url(#SVGID_11_);fill:#20B0D9;}
	.st68{clip-path:url(#SVGID_11_);fill:#1DB1DA;}
	.st69{clip-path:url(#SVGID_11_);fill:#1BB2DA;}
	.st70{clip-path:url(#SVGID_11_);fill:#19B4DC;}
	.st71{clip-path:url(#SVGID_11_);fill:#17B5DC;}
	.st72{clip-path:url(#SVGID_11_);fill:#18B6DD;}
	.st73{clip-path:url(#SVGID_11_);fill:#17B7DD;}
	.st74{clip-path:url(#SVGID_11_);fill:#1BB9DF;}
	.st75{clip-path:url(#SVGID_11_);fill:#1DBAE0;}
	.st76{clip-path:url(#SVGID_11_);fill:#1CBADF;}
	.st77{clip-path:url(#SVGID_11_);fill:#1DBCE0;}
	.st78{clip-path:url(#SVGID_11_);fill:#1ABCE1;}
	.st79{clip-path:url(#SVGID_11_);fill:#1EBDE1;}
	.st80{clip-path:url(#SVGID_11_);fill:#1CBEE2;}
	.st81{clip-path:url(#SVGID_11_);fill:#1CC0E3;}
	.st82{clip-path:url(#SVGID_11_);fill:#17C1E4;}
	.st83{clip-path:url(#SVGID_11_);fill:#1CC1E4;}
	.st84{clip-path:url(#SVGID_11_);fill:#1DC1E3;}
	.st85{clip-path:url(#SVGID_11_);fill:#21C2E4;}
	.st86{clip-path:url(#SVGID_11_);fill:#25C2E3;}
	.st87{clip-path:url(#SVGID_11_);fill:#29C2E4;}
	.st88{clip-path:url(#SVGID_11_);fill:#2BC3E4;}
	.st89{clip-path:url(#SVGID_11_);fill:#2FC3E4;}
	.st90{clip-path:url(#SVGID_11_);fill:#32C3E4;}
	.st91{clip-path:url(#SVGID_11_);fill:#31C3E4;}
	.st92{clip-path:url(#SVGID_11_);fill:#35C3E4;}
	.st93{clip-path:url(#SVGID_11_);fill:#37C4E4;}
	.st94{clip-path:url(#SVGID_11_);fill:#3AC4E3;}
	.st95{clip-path:url(#SVGID_11_);fill:#3CC4E3;}
	.st96{clip-path:url(#SVGID_11_);fill:#3FC4E3;}
	.st97{clip-path:url(#SVGID_11_);fill:#41C5E2;}
	.st98{clip-path:url(#SVGID_11_);fill:#43C5E2;}
	.st99{clip-path:url(#SVGID_11_);fill:#46C5E1;}
	.st100{clip-path:url(#SVGID_11_);fill:#45C5E2;}
	.st101{clip-path:url(#SVGID_11_);fill:#47C6E2;}
	.st102{clip-path:url(#SVGID_11_);fill:#49C6E2;}
	.st103{clip-path:url(#SVGID_11_);fill:#49C6E3;}
	.st104{clip-path:url(#SVGID_11_);fill:#4BC6E2;}
	.st105{clip-path:url(#SVGID_11_);fill:#4DC6E2;}
	.st106{clip-path:url(#SVGID_11_);fill:#4EC7E3;}
	.st107{clip-path:url(#SVGID_11_);fill:#4FC7E2;}
	.st108{clip-path:url(#SVGID_11_);fill:#51C7E2;}
	.st109{clip-path:url(#SVGID_11_);fill:#53C7E2;}
	.st110{clip-path:url(#SVGID_11_);fill:#54C8E2;}
	.st111{clip-path:url(#SVGID_11_);fill:#56C8E2;}
	.st112{clip-path:url(#SVGID_11_);fill:#58C8E1;}
	.st113{clip-path:url(#SVGID_11_);fill:#59C8E2;}
	.st114{clip-path:url(#SVGID_11_);fill:#5BC9E2;}
	.st115{clip-path:url(#SVGID_11_);fill:#5CC9E2;}
	.st116{clip-path:url(#SVGID_11_);fill:#5CC9E3;}
	.st117{clip-path:url(#SVGID_11_);fill:#5DC9E3;}
	.st118{clip-path:url(#SVGID_11_);fill:#5FCAE3;}
	.st119{clip-path:url(#SVGID_11_);fill:#60CAE3;}
	.st120{clip-path:url(#SVGID_11_);fill:#62CAE3;}
	.st121{clip-path:url(#SVGID_11_);fill:#63CBE3;}
	.st122{clip-path:url(#SVGID_11_);fill:#64CBE4;}
	.st123{clip-path:url(#SVGID_11_);fill:#65CBE3;}
	.st124{clip-path:url(#SVGID_11_);fill:#66CBE4;}
	.st125{clip-path:url(#SVGID_11_);fill:#66CBE3;}
	.st126{clip-path:url(#SVGID_11_);fill:#67CBE4;}
	.st127{opacity:0.6;fill:url(#SVGID_12_);}
	.st128{opacity:0.6;fill:url(#SVGID_13_);}
	.st129{opacity:0.6;fill:url(#SVGID_14_);}
	.st130{opacity:0.6;fill:url(#SVGID_15_);}
	.st131{opacity:0.6;fill:url(#SVGID_16_);}
	.st132{opacity:0.6;fill:url(#SVGID_17_);}
	.st133{opacity:0.15;}
	.st134{fill:#67CBE4;}
	.st135{fill:url(#SVGID_18_);}
	.st136{fill:url(#SVGID_19_);}
	.st137{fill:url(#SVGID_20_);}
	.st138{fill:url(#SVGID_21_);}
	.st139{fill:url(#SVGID_22_);}
	.st140{fill:url(#SVGID_23_);}
	.st141{fill:url(#SVGID_24_);}
	.st142{fill:url(#SVGID_25_);}
	.st143{fill:url(#SVGID_26_);}
	.st144{fill:url(#SVGID_27_);}
	.st145{fill:url(#SVGID_28_);}
	.st146{fill:url(#SVGID_29_);}
	.st147{fill:url(#SVGID_30_);}
	.st148{fill:url(#SVGID_31_);}
	.st149{fill:url(#SVGID_32_);}
	.st150{fill:url(#SVGID_33_);}
	.st151{fill:url(#SVGID_34_);}
	.st152{fill:url(#SVGID_35_);}
	.st153{fill:url(#SVGID_36_);}
	.st154{fill:url(#SVGID_37_);}
	.st155{opacity:0.2;}
	.st156{fill:url(#SVGID_38_);}
	.st157{fill:url(#SVGID_39_);}
	.st158{fill:url(#SVGID_40_);}
	.st159{fill:url(#SVGID_41_);}
	.st160{fill:url(#SVGID_42_);}
	.st161{fill:url(#SVGID_43_);}
	.st162{fill:url(#SVGID_44_);}
	.st163{fill:url(#SVGID_45_);}
	.st164{fill:url(#SVGID_46_);}
	.st165{fill:url(#SVGID_47_);}
	.st166{fill:url(#SVGID_48_);}
	.st167{fill:url(#SVGID_49_);}
	.st168{fill:url(#SVGID_50_);}
	.st169{fill:url(#SVGID_51_);}
	.st170{fill:url(#SVGID_52_);}
	.st171{fill:url(#SVGID_53_);}
	.st172{fill:url(#SVGID_54_);}
	.st173{fill:url(#SVGID_55_);}
	.st174{fill:url(#SVGID_56_);}
	.st175{fill:url(#SVGID_57_);}
	.st176{fill:url(#SVGID_58_);}
	.st177{opacity:0.8;fill:url(#SVGID_59_);}
	.st178{opacity:0.8;fill:url(#SVGID_60_);}
	.st179{fill:url(#SVGID_61_);}
	.st180{fill:url(#SVGID_62_);}
	.st181{opacity:0.4;fill:url(#SVGID_63_);}
	.st182{opacity:0.1;fill:url(#SVGID_64_);}
	.st183{opacity:8.000000e-02;fill:url(#SVGID_65_);}
	.st184{opacity:7.000000e-02;fill:url(#SVGID_66_);}
	.st185{opacity:7.000000e-02;fill:url(#SVGID_67_);}
	.st186{opacity:0.4;}
	.st187{fill:url(#SVGID_68_);}
	.st188{fill:url(#SVGID_69_);}
	.st189{fill:url(#SVGID_70_);}
	.st190{fill:url(#SVGID_71_);}
	.st191{fill:url(#SVGID_72_);}
	.st192{fill:url(#SVGID_73_);}
	.st193{fill:url(#SVGID_74_);}
	.st194{fill:url(#SVGID_75_);}
	.st195{fill:url(#SVGID_76_);}
	.st196{fill:url(#SVGID_77_);}
	.st197{fill:url(#SVGID_78_);}
	.st198{fill:#1B75BC;}
	.st199{opacity:0.35;fill:#1B75BC;}
	.st200{fill:url(#SVGID_79_);}
	.st201{fill:url(#SVGID_80_);}
	.st202{fill:url(#SVGID_81_);}
	.st203{fill:url(#SVGID_82_);}
	.st204{fill:url(#SVGID_83_);}
	.st205{fill:url(#SVGID_84_);}
	.st206{fill:url(#SVGID_85_);}
	.st207{fill:url(#SVGID_86_);}
	.st208{opacity:0.3;fill:url(#SVGID_87_);}
	.st209{opacity:0.5;fill:url(#SVGID_88_);}
	.st210{opacity:0.15;fill:url(#SVGID_89_);}
	.st211{opacity:0.15;fill:url(#SVGID_90_);}
	.st212{opacity:0.15;fill:url(#SVGID_91_);}
	.st213{opacity:0.1;fill:url(#SVGID_92_);}
	.st214{fill:url(#SVGID_93_);}
	.st215{fill:url(#SVGID_94_);}
	.st216{fill:url(#SVGID_95_);}
	.st217{fill:url(#SVGID_96_);}
	.st218{fill:url(#SVGID_97_);}
	.st219{fill:url(#SVGID_98_);}
	.st220{fill:url(#SVGID_99_);}
	.st221{opacity:0.25;}
	.st222{opacity:0.35;}
	.st223{opacity:0.3;fill:url(#SVGID_100_);}
	.st224{opacity:0.3;fill:url(#SVGID_101_);}
	.st225{fill:url(#SVGID_102_);}
	.st226{fill:url(#SVGID_103_);}
	.st227{opacity:0.8;}
	.st228{fill:url(#SVGID_104_);}
	.st229{fill:url(#SVGID_105_);}
	.st230{fill:url(#SVGID_106_);}
	.st231{fill:url(#SVGID_107_);}
	.st232{fill:url(#SVGID_108_);}
	.st233{fill:url(#SVGID_109_);}
	.st234{fill:url(#SVGID_110_);}
	.st235{fill:url(#SVGID_111_);}
	.st236{fill:url(#SVGID_112_);}
	.st237{fill:url(#SVGID_113_);}
	.st238{fill:url(#SVGID_114_);}
	.st239{fill:url(#SVGID_115_);}
	.st240{fill:url(#SVGID_116_);}
	.st241{fill:url(#SVGID_117_);}
	.st242{fill:url(#SVGID_118_);}
	.st243{fill:url(#SVGID_119_);}
	.st244{fill:url(#SVGID_120_);}
	.st245{fill:url(#SVGID_121_);}
	.st246{clip-path:url(#SVGID_123_);fill:#3173B4;}
	.st247{clip-path:url(#SVGID_123_);fill:#3174B5;}
	.st248{clip-path:url(#SVGID_123_);fill:#3275B5;}
	.st249{clip-path:url(#SVGID_123_);fill:#3376B6;}
	.st250{clip-path:url(#SVGID_123_);fill:#3377B7;}
	.st251{clip-path:url(#SVGID_123_);fill:#3278B7;}
	.st252{clip-path:url(#SVGID_123_);fill:#3379B8;}
	.st253{clip-path:url(#SVGID_123_);fill:#347AB8;}
	.st254{clip-path:url(#SVGID_123_);fill:#357BB9;}
	.st255{clip-path:url(#SVGID_123_);fill:#357CBA;}
	.st256{clip-path:url(#SVGID_123_);fill:#367DBA;}
	.st257{clip-path:url(#SVGID_123_);fill:#377EBB;}
	.st258{clip-path:url(#SVGID_123_);fill:#387FBB;}
	.st259{clip-path:url(#SVGID_123_);fill:#3880BC;}
	.st260{clip-path:url(#SVGID_123_);fill:#3882BD;}
	.st261{clip-path:url(#SVGID_123_);fill:#3983BE;}
	.st262{clip-path:url(#SVGID_123_);fill:#3784BF;}
	.st263{clip-path:url(#SVGID_123_);fill:#3885C0;}
	.st264{clip-path:url(#SVGID_123_);fill:#3986C0;}
	.st265{clip-path:url(#SVGID_123_);fill:#3A87C1;}
	.st266{clip-path:url(#SVGID_123_);fill:#3A88C2;}
	.st267{clip-path:url(#SVGID_123_);fill:#3A8AC3;}
	.st268{clip-path:url(#SVGID_123_);fill:#3B8BC4;}
	.st269{clip-path:url(#SVGID_123_);fill:#3C8BC4;}
	.st270{clip-path:url(#SVGID_123_);fill:#3C8DC5;}
	.st271{clip-path:url(#SVGID_123_);fill:#3D8DC5;}
	.st272{clip-path:url(#SVGID_123_);fill:#3F8FC7;}
	.st273{clip-path:url(#SVGID_123_);fill:#4091C6;}
	.st274{clip-path:url(#SVGID_123_);fill:#4192C8;}
	.st275{clip-path:url(#SVGID_123_);fill:#4292C8;}
	.st276{clip-path:url(#SVGID_123_);fill:#4194C8;}
	.st277{clip-path:url(#SVGID_123_);fill:#4394C9;}
	.st278{clip-path:url(#SVGID_123_);fill:#4496CA;}
	.st279{clip-path:url(#SVGID_123_);fill:#4297CC;}
	.st280{clip-path:url(#SVGID_123_);fill:#4398CC;}
	.st281{clip-path:url(#SVGID_123_);fill:#4499CC;}
	.st282{clip-path:url(#SVGID_123_);fill:#469BCD;}
	.st283{clip-path:url(#SVGID_123_);fill:#479CCE;}
	.st284{clip-path:url(#SVGID_123_);fill:#469DCF;}
	.st285{clip-path:url(#SVGID_123_);fill:#479FD0;}
	.st286{clip-path:url(#SVGID_123_);fill:#49A0CF;}
	.st287{clip-path:url(#SVGID_123_);fill:#48A1D1;}
	.st288{clip-path:url(#SVGID_123_);fill:#48A3D1;}
	.st289{clip-path:url(#SVGID_123_);fill:#49A4D3;}
	.st290{clip-path:url(#SVGID_123_);fill:#4AA5D4;}
	.st291{clip-path:url(#SVGID_123_);fill:#49A7D5;}
	.st292{clip-path:url(#SVGID_123_);fill:#4BA8D5;}
	.st293{clip-path:url(#SVGID_123_);fill:#4BAAD6;}
	.st294{clip-path:url(#SVGID_123_);fill:#4CABD7;}
	.st295{clip-path:url(#SVGID_123_);fill:#4EADD8;}
	.st296{clip-path:url(#SVGID_123_);fill:#4FAED9;}
	.st297{clip-path:url(#SVGID_123_);fill:#50AEDA;}
	.st298{clip-path:url(#SVGID_123_);fill:#51B0D9;}
	.st299{clip-path:url(#SVGID_123_);fill:#51B1DB;}
	.st300{clip-path:url(#SVGID_123_);fill:#52B2DB;}
	.st301{clip-path:url(#SVGID_123_);fill:#53B3DC;}
	.st302{clip-path:url(#SVGID_123_);fill:#54B5DC;}
	.st303{clip-path:url(#SVGID_123_);fill:#56B6DD;}
	.st304{clip-path:url(#SVGID_123_);fill:#57B6DE;}
	.st305{clip-path:url(#SVGID_123_);fill:#58B8DF;}
	.st306{clip-path:url(#SVGID_123_);fill:#57B8E0;}
	.st307{clip-path:url(#SVGID_123_);fill:#58BAE0;}
	.st308{clip-path:url(#SVGID_123_);fill:#59BBE1;}
	.st309{clip-path:url(#SVGID_123_);fill:#5ABCE1;}
	.st310{clip-path:url(#SVGID_123_);fill:#5BBCE2;}
	.st311{clip-path:url(#SVGID_123_);fill:#5CBEE3;}
	.st312{clip-path:url(#SVGID_123_);fill:#5DBEE3;}
	.st313{clip-path:url(#SVGID_123_);fill:#5DBFE4;}
	.st314{clip-path:url(#SVGID_123_);fill:#5EC0E3;}
	.st315{clip-path:url(#SVGID_123_);fill:#60C2E4;}
	.st316{clip-path:url(#SVGID_123_);fill:#61C2E6;}
	.st317{clip-path:url(#SVGID_123_);fill:#61C3E5;}
	.st318{clip-path:url(#SVGID_123_);fill:#62C4E6;}
	.st319{clip-path:url(#SVGID_123_);fill:#63C5E6;}
	.st320{clip-path:url(#SVGID_123_);fill:#65C7E7;}
	.st321{clip-path:url(#SVGID_123_);fill:#64C7E8;}
	.st322{clip-path:url(#SVGID_123_);fill:#66C8E8;}
	.st323{clip-path:url(#SVGID_123_);fill:#67C9E8;}
	.st324{clip-path:url(#SVGID_123_);fill:#68CAE8;}
	.st325{clip-path:url(#SVGID_123_);fill:#68CBEA;}
	.st326{clip-path:url(#SVGID_123_);fill:#69CDEC;}
	.st327{clip-path:url(#SVGID_123_);fill:#6BCDEA;}
	.st328{clip-path:url(#SVGID_123_);fill:#6DCEEB;}
	.st329{clip-path:url(#SVGID_123_);fill:#6DCEEA;}
	.st330{clip-path:url(#SVGID_123_);fill:#6ECEEB;}
	.st331{clip-path:url(#SVGID_123_);fill:#70CEEB;}
	.st332{clip-path:url(#SVGID_123_);fill:#71CFEA;}
	.st333{clip-path:url(#SVGID_123_);fill:#72CFEB;}
	.st334{clip-path:url(#SVGID_123_);fill:#74CFEB;}
	.st335{clip-path:url(#SVGID_123_);fill:#75D0EB;}
	.st336{clip-path:url(#SVGID_123_);fill:#78D0EB;}
	.st337{clip-path:url(#SVGID_123_);fill:#79D0EB;}
	.st338{clip-path:url(#SVGID_123_);fill:#7AD1EB;}
	.st339{clip-path:url(#SVGID_123_);fill:#7CD1EB;}
	.st340{clip-path:url(#SVGID_123_);fill:#7ED2EC;}
	.st341{clip-path:url(#SVGID_123_);fill:#7FD2EB;}
	.st342{clip-path:url(#SVGID_123_);fill:#81D3EC;}
	.st343{clip-path:url(#SVGID_123_);fill:#84D3EB;}
	.st344{clip-path:url(#SVGID_123_);fill:#85D4EB;}
	.st345{clip-path:url(#SVGID_123_);fill:#86D4EB;}
	.st346{clip-path:url(#SVGID_123_);fill:#88D4EB;}
	.st347{clip-path:url(#SVGID_123_);fill:#89D5EC;}
	.st348{clip-path:url(#SVGID_123_);fill:#8BD5EB;}
	.st349{clip-path:url(#SVGID_123_);fill:#8CD6EB;}
	.st350{clip-path:url(#SVGID_123_);fill:#8DD6EC;}
	.st351{clip-path:url(#SVGID_123_);fill:#8FD6EB;}
	.st352{clip-path:url(#SVGID_123_);fill:#91D7EC;}
	.st353{clip-path:url(#SVGID_123_);fill:#92D7EB;}
	.st354{clip-path:url(#SVGID_123_);fill:#93D8EC;}
	.st355{clip-path:url(#SVGID_123_);fill:#94D8EC;}
	.st356{clip-path:url(#SVGID_123_);fill:#96D8EC;}
	.st357{clip-path:url(#SVGID_123_);fill:#97D9EC;}
	.st358{clip-path:url(#SVGID_123_);fill:#98D9EC;}
	.st359{clip-path:url(#SVGID_123_);fill:#99DAED;}
	.st360{clip-path:url(#SVGID_125_);fill:#3173B4;}
	.st361{clip-path:url(#SVGID_125_);fill:#3174B5;}
	.st362{clip-path:url(#SVGID_125_);fill:#3275B5;}
	.st363{clip-path:url(#SVGID_125_);fill:#3376B6;}
	.st364{clip-path:url(#SVGID_125_);fill:#3377B7;}
	.st365{clip-path:url(#SVGID_125_);fill:#3278B7;}
	.st366{clip-path:url(#SVGID_125_);fill:#3379B8;}
	.st367{clip-path:url(#SVGID_125_);fill:#347AB8;}
	.st368{clip-path:url(#SVGID_125_);fill:#357BB9;}
	.st369{clip-path:url(#SVGID_125_);fill:#357CBA;}
	.st370{clip-path:url(#SVGID_125_);fill:#367DBA;}
	.st371{clip-path:url(#SVGID_125_);fill:#377EBB;}
	.st372{clip-path:url(#SVGID_125_);fill:#387FBB;}
	.st373{clip-path:url(#SVGID_125_);fill:#3880BC;}
	.st374{clip-path:url(#SVGID_125_);fill:#3882BD;}
	.st375{clip-path:url(#SVGID_125_);fill:#3983BE;}
	.st376{clip-path:url(#SVGID_125_);fill:#3784BF;}
	.st377{clip-path:url(#SVGID_125_);fill:#3885C0;}
	.st378{clip-path:url(#SVGID_125_);fill:#3986C0;}
	.st379{clip-path:url(#SVGID_125_);fill:#3A87C1;}
	.st380{clip-path:url(#SVGID_125_);fill:#3A88C2;}
	.st381{clip-path:url(#SVGID_125_);fill:#3A8AC3;}
	.st382{clip-path:url(#SVGID_125_);fill:#3B8BC4;}
	.st383{clip-path:url(#SVGID_125_);fill:#3C8BC4;}
	.st384{clip-path:url(#SVGID_125_);fill:#3C8DC5;}
	.st385{clip-path:url(#SVGID_125_);fill:#3D8DC5;}
	.st386{clip-path:url(#SVGID_125_);fill:#3F8FC7;}
	.st387{clip-path:url(#SVGID_125_);fill:#4091C6;}
	.st388{clip-path:url(#SVGID_125_);fill:#4192C8;}
	.st389{clip-path:url(#SVGID_125_);fill:#4292C8;}
	.st390{clip-path:url(#SVGID_125_);fill:#4194C8;}
	.st391{clip-path:url(#SVGID_125_);fill:#4394C9;}
	.st392{clip-path:url(#SVGID_125_);fill:#4496CA;}
	.st393{clip-path:url(#SVGID_125_);fill:#4297CC;}
	.st394{clip-path:url(#SVGID_125_);fill:#4398CC;}
	.st395{clip-path:url(#SVGID_125_);fill:#4499CC;}
	.st396{clip-path:url(#SVGID_125_);fill:#469BCD;}
	.st397{clip-path:url(#SVGID_125_);fill:#479CCE;}
	.st398{clip-path:url(#SVGID_125_);fill:#469DCF;}
	.st399{clip-path:url(#SVGID_125_);fill:#479FD0;}
	.st400{clip-path:url(#SVGID_125_);fill:#49A0CF;}
	.st401{clip-path:url(#SVGID_125_);fill:#48A1D1;}
	.st402{clip-path:url(#SVGID_125_);fill:#48A3D1;}
	.st403{clip-path:url(#SVGID_125_);fill:#49A4D3;}
	.st404{clip-path:url(#SVGID_125_);fill:#4AA5D4;}
	.st405{clip-path:url(#SVGID_125_);fill:#49A7D5;}
	.st406{clip-path:url(#SVGID_125_);fill:#4BA8D5;}
	.st407{clip-path:url(#SVGID_125_);fill:#4BAAD6;}
	.st408{clip-path:url(#SVGID_125_);fill:#4CABD7;}
	.st409{clip-path:url(#SVGID_125_);fill:#4EADD8;}
	.st410{clip-path:url(#SVGID_125_);fill:#4FAED9;}
	.st411{clip-path:url(#SVGID_125_);fill:#50AEDA;}
	.st412{clip-path:url(#SVGID_125_);fill:#51B0D9;}
	.st413{clip-path:url(#SVGID_125_);fill:#51B1DB;}
	.st414{clip-path:url(#SVGID_125_);fill:#52B2DB;}
	.st415{clip-path:url(#SVGID_125_);fill:#53B3DC;}
	.st416{clip-path:url(#SVGID_125_);fill:#54B5DC;}
	.st417{clip-path:url(#SVGID_125_);fill:#56B6DD;}
	.st418{clip-path:url(#SVGID_125_);fill:#57B6DE;}
	.st419{clip-path:url(#SVGID_125_);fill:#58B8DF;}
	.st420{clip-path:url(#SVGID_125_);fill:#57B8E0;}
	.st421{clip-path:url(#SVGID_125_);fill:#58BAE0;}
	.st422{clip-path:url(#SVGID_125_);fill:#59BBE1;}
	.st423{clip-path:url(#SVGID_125_);fill:#5ABCE1;}
	.st424{clip-path:url(#SVGID_125_);fill:#5BBCE2;}
	.st425{clip-path:url(#SVGID_125_);fill:#5CBEE3;}
	.st426{clip-path:url(#SVGID_125_);fill:#5DBEE3;}
	.st427{clip-path:url(#SVGID_125_);fill:#5DBFE4;}
	.st428{clip-path:url(#SVGID_125_);fill:#5EC0E3;}
	.st429{clip-path:url(#SVGID_125_);fill:#60C2E4;}
	.st430{clip-path:url(#SVGID_125_);fill:#61C2E6;}
	.st431{clip-path:url(#SVGID_125_);fill:#61C3E5;}
	.st432{clip-path:url(#SVGID_125_);fill:#62C4E6;}
	.st433{clip-path:url(#SVGID_125_);fill:#63C5E6;}
	.st434{clip-path:url(#SVGID_125_);fill:#65C7E7;}
	.st435{clip-path:url(#SVGID_125_);fill:#64C7E8;}
	.st436{clip-path:url(#SVGID_125_);fill:#66C8E8;}
	.st437{clip-path:url(#SVGID_125_);fill:#67C9E8;}
	.st438{clip-path:url(#SVGID_125_);fill:#68CAE8;}
	.st439{clip-path:url(#SVGID_125_);fill:#68CBEA;}
	.st440{clip-path:url(#SVGID_125_);fill:#69CDEC;}
	.st441{clip-path:url(#SVGID_125_);fill:#6BCDEA;}
	.st442{clip-path:url(#SVGID_125_);fill:#6DCEEB;}
	.st443{clip-path:url(#SVGID_125_);fill:#6DCEEA;}
	.st444{clip-path:url(#SVGID_125_);fill:#6ECEEB;}
	.st445{clip-path:url(#SVGID_125_);fill:#70CEEB;}
	.st446{clip-path:url(#SVGID_125_);fill:#71CFEA;}
	.st447{clip-path:url(#SVGID_125_);fill:#72CFEB;}
	.st448{clip-path:url(#SVGID_125_);fill:#74CFEB;}
	.st449{clip-path:url(#SVGID_125_);fill:#75D0EB;}
	.st450{clip-path:url(#SVGID_125_);fill:#78D0EB;}
	.st451{clip-path:url(#SVGID_125_);fill:#79D0EB;}
	.st452{clip-path:url(#SVGID_125_);fill:#7AD1EB;}
	.st453{clip-path:url(#SVGID_125_);fill:#7CD1EB;}
	.st454{clip-path:url(#SVGID_125_);fill:#7ED2EC;}
	.st455{clip-path:url(#SVGID_125_);fill:#7FD2EB;}
	.st456{clip-path:url(#SVGID_125_);fill:#81D3EC;}
	.st457{clip-path:url(#SVGID_125_);fill:#84D3EB;}
	.st458{clip-path:url(#SVGID_125_);fill:#85D4EB;}
	.st459{clip-path:url(#SVGID_125_);fill:#86D4EB;}
	.st460{clip-path:url(#SVGID_125_);fill:#88D4EB;}
	.st461{clip-path:url(#SVGID_125_);fill:#89D5EC;}
	.st462{clip-path:url(#SVGID_125_);fill:#8BD5EB;}
	.st463{clip-path:url(#SVGID_125_);fill:#8CD6EB;}
	.st464{clip-path:url(#SVGID_125_);fill:#8DD6EC;}
	.st465{clip-path:url(#SVGID_125_);fill:#8FD6EB;}
	.st466{clip-path:url(#SVGID_125_);fill:#91D7EC;}
	.st467{clip-path:url(#SVGID_125_);fill:#92D7EB;}
	.st468{clip-path:url(#SVGID_125_);fill:#93D8EC;}
	.st469{clip-path:url(#SVGID_125_);fill:#94D8EC;}
	.st470{clip-path:url(#SVGID_125_);fill:#96D8EC;}
	.st471{clip-path:url(#SVGID_125_);fill:#97D9EC;}
	.st472{clip-path:url(#SVGID_125_);fill:#98D9EC;}
	.st473{clip-path:url(#SVGID_125_);fill:#99DAED;}
	.st474{clip-path:url(#SVGID_127_);fill:#3173B4;}
	.st475{clip-path:url(#SVGID_127_);fill:#3174B5;}
	.st476{clip-path:url(#SVGID_127_);fill:#3275B5;}
	.st477{clip-path:url(#SVGID_127_);fill:#3376B6;}
	.st478{clip-path:url(#SVGID_127_);fill:#3377B7;}
	.st479{clip-path:url(#SVGID_127_);fill:#3278B7;}
	.st480{clip-path:url(#SVGID_127_);fill:#3379B8;}
	.st481{clip-path:url(#SVGID_127_);fill:#347AB8;}
	.st482{clip-path:url(#SVGID_127_);fill:#357BB9;}
	.st483{clip-path:url(#SVGID_127_);fill:#357CBA;}
	.st484{clip-path:url(#SVGID_127_);fill:#367DBA;}
	.st485{clip-path:url(#SVGID_127_);fill:#377EBB;}
	.st486{clip-path:url(#SVGID_127_);fill:#387FBB;}
	.st487{clip-path:url(#SVGID_127_);fill:#3880BC;}
	.st488{clip-path:url(#SVGID_127_);fill:#3882BD;}
	.st489{clip-path:url(#SVGID_127_);fill:#3983BE;}
	.st490{clip-path:url(#SVGID_127_);fill:#3784BF;}
	.st491{clip-path:url(#SVGID_127_);fill:#3885C0;}
	.st492{clip-path:url(#SVGID_127_);fill:#3986C0;}
	.st493{clip-path:url(#SVGID_127_);fill:#3A87C1;}
	.st494{clip-path:url(#SVGID_127_);fill:#3A88C2;}
	.st495{clip-path:url(#SVGID_127_);fill:#3A8AC3;}
	.st496{clip-path:url(#SVGID_127_);fill:#3B8BC4;}
	.st497{clip-path:url(#SVGID_127_);fill:#3C8BC4;}
	.st498{clip-path:url(#SVGID_127_);fill:#3C8DC5;}
	.st499{clip-path:url(#SVGID_127_);fill:#3D8DC5;}
	.st500{clip-path:url(#SVGID_127_);fill:#3F8FC7;}
	.st501{clip-path:url(#SVGID_127_);fill:#4091C6;}
	.st502{clip-path:url(#SVGID_127_);fill:#4192C8;}
	.st503{clip-path:url(#SVGID_127_);fill:#4292C8;}
	.st504{clip-path:url(#SVGID_127_);fill:#4194C8;}
	.st505{clip-path:url(#SVGID_127_);fill:#4394C9;}
	.st506{clip-path:url(#SVGID_127_);fill:#4496CA;}
	.st507{clip-path:url(#SVGID_127_);fill:#4297CC;}
	.st508{clip-path:url(#SVGID_127_);fill:#4398CC;}
	.st509{clip-path:url(#SVGID_127_);fill:#4499CC;}
	.st510{clip-path:url(#SVGID_127_);fill:#469BCD;}
	.st511{clip-path:url(#SVGID_127_);fill:#479CCE;}
	.st512{clip-path:url(#SVGID_127_);fill:#469DCF;}
	.st513{clip-path:url(#SVGID_127_);fill:#479FD0;}
	.st514{clip-path:url(#SVGID_127_);fill:#49A0CF;}
	.st515{clip-path:url(#SVGID_127_);fill:#48A1D1;}
	.st516{clip-path:url(#SVGID_127_);fill:#48A3D1;}
	.st517{clip-path:url(#SVGID_127_);fill:#49A4D3;}
	.st518{clip-path:url(#SVGID_127_);fill:#4AA5D4;}
	.st519{clip-path:url(#SVGID_127_);fill:#49A7D5;}
	.st520{clip-path:url(#SVGID_127_);fill:#4BA8D5;}
	.st521{clip-path:url(#SVGID_127_);fill:#4BAAD6;}
	.st522{clip-path:url(#SVGID_127_);fill:#4CABD7;}
	.st523{clip-path:url(#SVGID_127_);fill:#4EADD8;}
	.st524{clip-path:url(#SVGID_127_);fill:#4FAED9;}
	.st525{clip-path:url(#SVGID_127_);fill:#50AEDA;}
	.st526{clip-path:url(#SVGID_127_);fill:#51B0D9;}
	.st527{clip-path:url(#SVGID_127_);fill:#51B1DB;}
	.st528{clip-path:url(#SVGID_127_);fill:#52B2DB;}
	.st529{clip-path:url(#SVGID_127_);fill:#53B3DC;}
	.st530{clip-path:url(#SVGID_127_);fill:#54B5DC;}
	.st531{clip-path:url(#SVGID_127_);fill:#56B6DD;}
	.st532{clip-path:url(#SVGID_127_);fill:#57B6DE;}
	.st533{clip-path:url(#SVGID_127_);fill:#58B8DF;}
	.st534{clip-path:url(#SVGID_127_);fill:#57B8E0;}
	.st535{clip-path:url(#SVGID_127_);fill:#58BAE0;}
	.st536{clip-path:url(#SVGID_127_);fill:#59BBE1;}
	.st537{clip-path:url(#SVGID_127_);fill:#5ABCE1;}
	.st538{clip-path:url(#SVGID_127_);fill:#5BBCE2;}
	.st539{clip-path:url(#SVGID_127_);fill:#5CBEE3;}
	.st540{clip-path:url(#SVGID_127_);fill:#5DBEE3;}
	.st541{clip-path:url(#SVGID_127_);fill:#5DBFE4;}
	.st542{clip-path:url(#SVGID_127_);fill:#5EC0E3;}
	.st543{clip-path:url(#SVGID_127_);fill:#60C2E4;}
	.st544{clip-path:url(#SVGID_127_);fill:#61C2E6;}
	.st545{clip-path:url(#SVGID_127_);fill:#61C3E5;}
	.st546{clip-path:url(#SVGID_127_);fill:#62C4E6;}
	.st547{clip-path:url(#SVGID_127_);fill:#63C5E6;}
	.st548{clip-path:url(#SVGID_127_);fill:#65C7E7;}
	.st549{clip-path:url(#SVGID_127_);fill:#64C7E8;}
	.st550{clip-path:url(#SVGID_127_);fill:#66C8E8;}
	.st551{clip-path:url(#SVGID_127_);fill:#67C9E8;}
	.st552{clip-path:url(#SVGID_127_);fill:#68CAE8;}
	.st553{clip-path:url(#SVGID_127_);fill:#68CBEA;}
	.st554{clip-path:url(#SVGID_127_);fill:#69CDEC;}
	.st555{clip-path:url(#SVGID_127_);fill:#6BCDEA;}
	.st556{clip-path:url(#SVGID_127_);fill:#6DCEEB;}
	.st557{clip-path:url(#SVGID_127_);fill:#6DCEEA;}
	.st558{clip-path:url(#SVGID_127_);fill:#6ECEEB;}
	.st559{clip-path:url(#SVGID_127_);fill:#70CEEB;}
	.st560{clip-path:url(#SVGID_127_);fill:#71CFEA;}
	.st561{clip-path:url(#SVGID_127_);fill:#72CFEB;}
	.st562{clip-path:url(#SVGID_127_);fill:#74CFEB;}
	.st563{clip-path:url(#SVGID_127_);fill:#75D0EB;}
	.st564{clip-path:url(#SVGID_127_);fill:#78D0EB;}
	.st565{clip-path:url(#SVGID_127_);fill:#79D0EB;}
	.st566{clip-path:url(#SVGID_127_);fill:#7AD1EB;}
	.st567{clip-path:url(#SVGID_127_);fill:#7CD1EB;}
	.st568{clip-path:url(#SVGID_127_);fill:#7ED2EC;}
	.st569{clip-path:url(#SVGID_127_);fill:#7FD2EB;}
	.st570{clip-path:url(#SVGID_127_);fill:#81D3EC;}
	.st571{clip-path:url(#SVGID_127_);fill:#84D3EB;}
	.st572{clip-path:url(#SVGID_127_);fill:#85D4EB;}
	.st573{clip-path:url(#SVGID_127_);fill:#86D4EB;}
	.st574{clip-path:url(#SVGID_127_);fill:#88D4EB;}
	.st575{clip-path:url(#SVGID_127_);fill:#89D5EC;}
	.st576{clip-path:url(#SVGID_127_);fill:#8BD5EB;}
	.st577{clip-path:url(#SVGID_127_);fill:#8CD6EB;}
	.st578{clip-path:url(#SVGID_127_);fill:#8DD6EC;}
	.st579{clip-path:url(#SVGID_127_);fill:#8FD6EB;}
	.st580{clip-path:url(#SVGID_127_);fill:#91D7EC;}
	.st581{clip-path:url(#SVGID_127_);fill:#92D7EB;}
	.st582{clip-path:url(#SVGID_127_);fill:#93D8EC;}
	.st583{clip-path:url(#SVGID_127_);fill:#94D8EC;}
	.st584{clip-path:url(#SVGID_127_);fill:#96D8EC;}
	.st585{clip-path:url(#SVGID_127_);fill:#97D9EC;}
	.st586{clip-path:url(#SVGID_127_);fill:#98D9EC;}
	.st587{clip-path:url(#SVGID_127_);fill:#99DAED;}
	.st588{clip-path:url(#SVGID_129_);fill:#3173B4;}
	.st589{clip-path:url(#SVGID_129_);fill:#3174B5;}
	.st590{clip-path:url(#SVGID_129_);fill:#3275B5;}
	.st591{clip-path:url(#SVGID_129_);fill:#3376B6;}
	.st592{clip-path:url(#SVGID_129_);fill:#3377B7;}
	.st593{clip-path:url(#SVGID_129_);fill:#3278B7;}
	.st594{clip-path:url(#SVGID_129_);fill:#3379B8;}
	.st595{clip-path:url(#SVGID_129_);fill:#347AB8;}
	.st596{clip-path:url(#SVGID_129_);fill:#357BB9;}
	.st597{clip-path:url(#SVGID_129_);fill:#357CBA;}
	.st598{clip-path:url(#SVGID_129_);fill:#367DBA;}
	.st599{clip-path:url(#SVGID_129_);fill:#377EBB;}
	.st600{clip-path:url(#SVGID_129_);fill:#387FBB;}
	.st601{clip-path:url(#SVGID_129_);fill:#3880BC;}
	.st602{clip-path:url(#SVGID_129_);fill:#3882BD;}
	.st603{clip-path:url(#SVGID_129_);fill:#3983BE;}
	.st604{clip-path:url(#SVGID_129_);fill:#3784BF;}
	.st605{clip-path:url(#SVGID_129_);fill:#3885C0;}
	.st606{clip-path:url(#SVGID_129_);fill:#3986C0;}
	.st607{clip-path:url(#SVGID_129_);fill:#3A87C1;}
	.st608{clip-path:url(#SVGID_129_);fill:#3A88C2;}
	.st609{clip-path:url(#SVGID_129_);fill:#3A8AC3;}
	.st610{clip-path:url(#SVGID_129_);fill:#3B8BC4;}
	.st611{clip-path:url(#SVGID_129_);fill:#3C8BC4;}
	.st612{clip-path:url(#SVGID_129_);fill:#3C8DC5;}
	.st613{clip-path:url(#SVGID_129_);fill:#3D8DC5;}
	.st614{clip-path:url(#SVGID_129_);fill:#3F8FC7;}
	.st615{clip-path:url(#SVGID_129_);fill:#4091C6;}
	.st616{clip-path:url(#SVGID_129_);fill:#4192C8;}
	.st617{clip-path:url(#SVGID_129_);fill:#4292C8;}
	.st618{clip-path:url(#SVGID_129_);fill:#4194C8;}
	.st619{clip-path:url(#SVGID_129_);fill:#4394C9;}
	.st620{clip-path:url(#SVGID_129_);fill:#4496CA;}
	.st621{clip-path:url(#SVGID_129_);fill:#4297CC;}
	.st622{clip-path:url(#SVGID_129_);fill:#4398CC;}
	.st623{clip-path:url(#SVGID_129_);fill:#4499CC;}
	.st624{clip-path:url(#SVGID_129_);fill:#469BCD;}
	.st625{clip-path:url(#SVGID_129_);fill:#479CCE;}
	.st626{clip-path:url(#SVGID_129_);fill:#469DCF;}
	.st627{clip-path:url(#SVGID_129_);fill:#479FD0;}
	.st628{clip-path:url(#SVGID_129_);fill:#49A0CF;}
	.st629{clip-path:url(#SVGID_129_);fill:#48A1D1;}
	.st630{clip-path:url(#SVGID_129_);fill:#48A3D1;}
	.st631{clip-path:url(#SVGID_129_);fill:#49A4D3;}
	.st632{clip-path:url(#SVGID_129_);fill:#4AA5D4;}
	.st633{clip-path:url(#SVGID_129_);fill:#49A7D5;}
	.st634{clip-path:url(#SVGID_129_);fill:#4BA8D5;}
	.st635{clip-path:url(#SVGID_129_);fill:#4BAAD6;}
	.st636{clip-path:url(#SVGID_129_);fill:#4CABD7;}
	.st637{clip-path:url(#SVGID_129_);fill:#4EADD8;}
	.st638{clip-path:url(#SVGID_129_);fill:#4FAED9;}
	.st639{clip-path:url(#SVGID_129_);fill:#50AEDA;}
	.st640{clip-path:url(#SVGID_129_);fill:#51B0D9;}
	.st641{clip-path:url(#SVGID_129_);fill:#51B1DB;}
	.st642{clip-path:url(#SVGID_129_);fill:#52B2DB;}
	.st643{clip-path:url(#SVGID_129_);fill:#53B3DC;}
	.st644{clip-path:url(#SVGID_129_);fill:#54B5DC;}
	.st645{clip-path:url(#SVGID_129_);fill:#56B6DD;}
	.st646{clip-path:url(#SVGID_129_);fill:#57B6DE;}
	.st647{clip-path:url(#SVGID_129_);fill:#58B8DF;}
	.st648{clip-path:url(#SVGID_129_);fill:#57B8E0;}
	.st649{clip-path:url(#SVGID_129_);fill:#58BAE0;}
	.st650{clip-path:url(#SVGID_129_);fill:#59BBE1;}
	.st651{clip-path:url(#SVGID_129_);fill:#5ABCE1;}
	.st652{clip-path:url(#SVGID_129_);fill:#5BBCE2;}
	.st653{clip-path:url(#SVGID_129_);fill:#5CBEE3;}
	.st654{clip-path:url(#SVGID_129_);fill:#5DBEE3;}
	.st655{clip-path:url(#SVGID_129_);fill:#5DBFE4;}
	.st656{clip-path:url(#SVGID_129_);fill:#5EC0E3;}
	.st657{clip-path:url(#SVGID_129_);fill:#60C2E4;}
	.st658{clip-path:url(#SVGID_129_);fill:#61C2E6;}
	.st659{clip-path:url(#SVGID_129_);fill:#61C3E5;}
	.st660{clip-path:url(#SVGID_129_);fill:#62C4E6;}
	.st661{clip-path:url(#SVGID_129_);fill:#63C5E6;}
	.st662{clip-path:url(#SVGID_129_);fill:#65C7E7;}
	.st663{clip-path:url(#SVGID_129_);fill:#64C7E8;}
	.st664{clip-path:url(#SVGID_129_);fill:#66C8E8;}
	.st665{clip-path:url(#SVGID_129_);fill:#67C9E8;}
	.st666{clip-path:url(#SVGID_129_);fill:#68CAE8;}
	.st667{clip-path:url(#SVGID_129_);fill:#68CBEA;}
	.st668{clip-path:url(#SVGID_129_);fill:#69CDEC;}
	.st669{clip-path:url(#SVGID_129_);fill:#6BCDEA;}
	.st670{clip-path:url(#SVGID_129_);fill:#6DCEEB;}
	.st671{clip-path:url(#SVGID_129_);fill:#6DCEEA;}
	.st672{clip-path:url(#SVGID_129_);fill:#6ECEEB;}
	.st673{clip-path:url(#SVGID_129_);fill:#70CEEB;}
	.st674{clip-path:url(#SVGID_129_);fill:#71CFEA;}
	.st675{clip-path:url(#SVGID_129_);fill:#72CFEB;}
	.st676{clip-path:url(#SVGID_129_);fill:#74CFEB;}
	.st677{clip-path:url(#SVGID_129_);fill:#75D0EB;}
	.st678{clip-path:url(#SVGID_129_);fill:#78D0EB;}
	.st679{clip-path:url(#SVGID_129_);fill:#79D0EB;}
	.st680{clip-path:url(#SVGID_129_);fill:#7AD1EB;}
	.st681{clip-path:url(#SVGID_129_);fill:#7CD1EB;}
	.st682{clip-path:url(#SVGID_129_);fill:#7ED2EC;}
	.st683{clip-path:url(#SVGID_129_);fill:#7FD2EB;}
	.st684{clip-path:url(#SVGID_129_);fill:#81D3EC;}
	.st685{clip-path:url(#SVGID_129_);fill:#84D3EB;}
	.st686{clip-path:url(#SVGID_129_);fill:#85D4EB;}
	.st687{clip-path:url(#SVGID_129_);fill:#86D4EB;}
	.st688{clip-path:url(#SVGID_129_);fill:#88D4EB;}
	.st689{clip-path:url(#SVGID_129_);fill:#89D5EC;}
	.st690{clip-path:url(#SVGID_129_);fill:#8BD5EB;}
	.st691{clip-path:url(#SVGID_129_);fill:#8CD6EB;}
	.st692{clip-path:url(#SVGID_129_);fill:#8DD6EC;}
	.st693{clip-path:url(#SVGID_129_);fill:#8FD6EB;}
	.st694{clip-path:url(#SVGID_129_);fill:#91D7EC;}
	.st695{clip-path:url(#SVGID_129_);fill:#92D7EB;}
	.st696{clip-path:url(#SVGID_129_);fill:#93D8EC;}
	.st697{clip-path:url(#SVGID_129_);fill:#94D8EC;}
	.st698{clip-path:url(#SVGID_129_);fill:#96D8EC;}
	.st699{clip-path:url(#SVGID_129_);fill:#97D9EC;}
	.st700{clip-path:url(#SVGID_129_);fill:#98D9EC;}
	.st701{clip-path:url(#SVGID_129_);fill:#99DAED;}
	.st702{clip-path:url(#SVGID_131_);fill:#3173B4;}
	.st703{clip-path:url(#SVGID_131_);fill:#3174B5;}
	.st704{clip-path:url(#SVGID_131_);fill:#3275B5;}
	.st705{clip-path:url(#SVGID_131_);fill:#3376B6;}
	.st706{clip-path:url(#SVGID_131_);fill:#3377B7;}
	.st707{clip-path:url(#SVGID_131_);fill:#3278B7;}
	.st708{clip-path:url(#SVGID_131_);fill:#3379B8;}
	.st709{clip-path:url(#SVGID_131_);fill:#347AB8;}
	.st710{clip-path:url(#SVGID_131_);fill:#357BB9;}
	.st711{clip-path:url(#SVGID_131_);fill:#357CBA;}
	.st712{clip-path:url(#SVGID_131_);fill:#367DBA;}
	.st713{clip-path:url(#SVGID_131_);fill:#377EBB;}
	.st714{clip-path:url(#SVGID_131_);fill:#387FBB;}
	.st715{clip-path:url(#SVGID_131_);fill:#3880BC;}
	.st716{clip-path:url(#SVGID_131_);fill:#3882BD;}
	.st717{clip-path:url(#SVGID_131_);fill:#3983BE;}
	.st718{clip-path:url(#SVGID_131_);fill:#3784BF;}
	.st719{clip-path:url(#SVGID_131_);fill:#3885C0;}
	.st720{clip-path:url(#SVGID_131_);fill:#3986C0;}
	.st721{clip-path:url(#SVGID_131_);fill:#3A87C1;}
	.st722{clip-path:url(#SVGID_131_);fill:#3A88C2;}
	.st723{clip-path:url(#SVGID_131_);fill:#3A8AC3;}
	.st724{clip-path:url(#SVGID_131_);fill:#3B8BC4;}
	.st725{clip-path:url(#SVGID_131_);fill:#3C8BC4;}
	.st726{clip-path:url(#SVGID_131_);fill:#3C8DC5;}
	.st727{clip-path:url(#SVGID_131_);fill:#3D8DC5;}
	.st728{clip-path:url(#SVGID_131_);fill:#3F8FC7;}
	.st729{clip-path:url(#SVGID_131_);fill:#4091C6;}
	.st730{clip-path:url(#SVGID_131_);fill:#4192C8;}
	.st731{clip-path:url(#SVGID_131_);fill:#4292C8;}
	.st732{clip-path:url(#SVGID_131_);fill:#4194C8;}
	.st733{clip-path:url(#SVGID_131_);fill:#4394C9;}
	.st734{clip-path:url(#SVGID_131_);fill:#4496CA;}
	.st735{clip-path:url(#SVGID_131_);fill:#4297CC;}
	.st736{clip-path:url(#SVGID_131_);fill:#4398CC;}
	.st737{clip-path:url(#SVGID_131_);fill:#4499CC;}
	.st738{clip-path:url(#SVGID_131_);fill:#469BCD;}
	.st739{clip-path:url(#SVGID_131_);fill:#479CCE;}
	.st740{clip-path:url(#SVGID_131_);fill:#469DCF;}
	.st741{clip-path:url(#SVGID_131_);fill:#479FD0;}
	.st742{clip-path:url(#SVGID_131_);fill:#49A0CF;}
	.st743{clip-path:url(#SVGID_131_);fill:#48A1D1;}
	.st744{clip-path:url(#SVGID_131_);fill:#48A3D1;}
	.st745{clip-path:url(#SVGID_131_);fill:#49A4D3;}
	.st746{clip-path:url(#SVGID_131_);fill:#4AA5D4;}
	.st747{clip-path:url(#SVGID_131_);fill:#49A7D5;}
	.st748{clip-path:url(#SVGID_131_);fill:#4BA8D5;}
	.st749{clip-path:url(#SVGID_131_);fill:#4BAAD6;}
	.st750{clip-path:url(#SVGID_131_);fill:#4CABD7;}
	.st751{clip-path:url(#SVGID_131_);fill:#4EADD8;}
	.st752{clip-path:url(#SVGID_131_);fill:#4FAED9;}
	.st753{clip-path:url(#SVGID_131_);fill:#50AEDA;}
	.st754{clip-path:url(#SVGID_131_);fill:#51B0D9;}
	.st755{clip-path:url(#SVGID_131_);fill:#51B1DB;}
	.st756{clip-path:url(#SVGID_131_);fill:#52B2DB;}
	.st757{clip-path:url(#SVGID_131_);fill:#53B3DC;}
	.st758{clip-path:url(#SVGID_131_);fill:#54B5DC;}
	.st759{clip-path:url(#SVGID_131_);fill:#56B6DD;}
	.st760{clip-path:url(#SVGID_131_);fill:#57B6DE;}
	.st761{clip-path:url(#SVGID_131_);fill:#58B8DF;}
	.st762{clip-path:url(#SVGID_131_);fill:#57B8E0;}
	.st763{clip-path:url(#SVGID_131_);fill:#58BAE0;}
	.st764{clip-path:url(#SVGID_131_);fill:#59BBE1;}
	.st765{clip-path:url(#SVGID_131_);fill:#5ABCE1;}
	.st766{clip-path:url(#SVGID_131_);fill:#5BBCE2;}
	.st767{clip-path:url(#SVGID_131_);fill:#5CBEE3;}
	.st768{clip-path:url(#SVGID_131_);fill:#5DBEE3;}
	.st769{clip-path:url(#SVGID_131_);fill:#5DBFE4;}
	.st770{clip-path:url(#SVGID_131_);fill:#5EC0E3;}
	.st771{clip-path:url(#SVGID_131_);fill:#60C2E4;}
	.st772{clip-path:url(#SVGID_131_);fill:#61C2E6;}
	.st773{clip-path:url(#SVGID_131_);fill:#61C3E5;}
	.st774{clip-path:url(#SVGID_131_);fill:#62C4E6;}
	.st775{clip-path:url(#SVGID_131_);fill:#63C5E6;}
	.st776{clip-path:url(#SVGID_131_);fill:#65C7E7;}
	.st777{clip-path:url(#SVGID_131_);fill:#64C7E8;}
	.st778{clip-path:url(#SVGID_131_);fill:#66C8E8;}
	.st779{clip-path:url(#SVGID_131_);fill:#67C9E8;}
	.st780{clip-path:url(#SVGID_131_);fill:#68CAE8;}
	.st781{clip-path:url(#SVGID_131_);fill:#68CBEA;}
	.st782{clip-path:url(#SVGID_131_);fill:#69CDEC;}
	.st783{clip-path:url(#SVGID_131_);fill:#6BCDEA;}
	.st784{clip-path:url(#SVGID_131_);fill:#6DCEEB;}
	.st785{clip-path:url(#SVGID_131_);fill:#6DCEEA;}
	.st786{clip-path:url(#SVGID_131_);fill:#6ECEEB;}
	.st787{clip-path:url(#SVGID_131_);fill:#70CEEB;}
	.st788{clip-path:url(#SVGID_131_);fill:#71CFEA;}
	.st789{clip-path:url(#SVGID_131_);fill:#72CFEB;}
	.st790{clip-path:url(#SVGID_131_);fill:#74CFEB;}
	.st791{clip-path:url(#SVGID_131_);fill:#75D0EB;}
	.st792{clip-path:url(#SVGID_131_);fill:#78D0EB;}
	.st793{clip-path:url(#SVGID_131_);fill:#79D0EB;}
	.st794{clip-path:url(#SVGID_131_);fill:#7AD1EB;}
	.st795{clip-path:url(#SVGID_131_);fill:#7CD1EB;}
	.st796{clip-path:url(#SVGID_131_);fill:#7ED2EC;}
	.st797{clip-path:url(#SVGID_131_);fill:#7FD2EB;}
	.st798{clip-path:url(#SVGID_131_);fill:#81D3EC;}
	.st799{clip-path:url(#SVGID_131_);fill:#84D3EB;}
	.st800{clip-path:url(#SVGID_131_);fill:#85D4EB;}
	.st801{clip-path:url(#SVGID_131_);fill:#86D4EB;}
	.st802{clip-path:url(#SVGID_131_);fill:#88D4EB;}
	.st803{clip-path:url(#SVGID_131_);fill:#89D5EC;}
	.st804{clip-path:url(#SVGID_131_);fill:#8BD5EB;}
	.st805{clip-path:url(#SVGID_131_);fill:#8CD6EB;}
	.st806{clip-path:url(#SVGID_131_);fill:#8DD6EC;}
	.st807{clip-path:url(#SVGID_131_);fill:#8FD6EB;}
	.st808{clip-path:url(#SVGID_131_);fill:#91D7EC;}
	.st809{clip-path:url(#SVGID_131_);fill:#92D7EB;}
	.st810{clip-path:url(#SVGID_131_);fill:#93D8EC;}
	.st811{clip-path:url(#SVGID_131_);fill:#94D8EC;}
	.st812{clip-path:url(#SVGID_131_);fill:#96D8EC;}
	.st813{clip-path:url(#SVGID_131_);fill:#97D9EC;}
	.st814{clip-path:url(#SVGID_131_);fill:#98D9EC;}
	.st815{clip-path:url(#SVGID_131_);fill:#99DAED;}
	.st816{clip-path:url(#SVGID_133_);fill:#3173B4;}
	.st817{clip-path:url(#SVGID_133_);fill:#3174B5;}
	.st818{clip-path:url(#SVGID_133_);fill:#3275B5;}
	.st819{clip-path:url(#SVGID_133_);fill:#3376B6;}
	.st820{clip-path:url(#SVGID_133_);fill:#3377B7;}
	.st821{clip-path:url(#SVGID_133_);fill:#3278B7;}
	.st822{clip-path:url(#SVGID_133_);fill:#3379B8;}
	.st823{clip-path:url(#SVGID_133_);fill:#347AB8;}
	.st824{clip-path:url(#SVGID_133_);fill:#357BB9;}
	.st825{clip-path:url(#SVGID_133_);fill:#357CBA;}
	.st826{clip-path:url(#SVGID_133_);fill:#367DBA;}
	.st827{clip-path:url(#SVGID_133_);fill:#377EBB;}
	.st828{clip-path:url(#SVGID_133_);fill:#387FBB;}
	.st829{clip-path:url(#SVGID_133_);fill:#3880BC;}
	.st830{clip-path:url(#SVGID_133_);fill:#3882BD;}
	.st831{clip-path:url(#SVGID_133_);fill:#3983BE;}
	.st832{clip-path:url(#SVGID_133_);fill:#3784BF;}
	.st833{clip-path:url(#SVGID_133_);fill:#3885C0;}
	.st834{clip-path:url(#SVGID_133_);fill:#3986C0;}
	.st835{clip-path:url(#SVGID_133_);fill:#3A87C1;}
	.st836{clip-path:url(#SVGID_133_);fill:#3A88C2;}
	.st837{clip-path:url(#SVGID_133_);fill:#3A8AC3;}
	.st838{clip-path:url(#SVGID_133_);fill:#3B8BC4;}
	.st839{clip-path:url(#SVGID_133_);fill:#3C8BC4;}
	.st840{clip-path:url(#SVGID_133_);fill:#3C8DC5;}
	.st841{clip-path:url(#SVGID_133_);fill:#3D8DC5;}
	.st842{clip-path:url(#SVGID_133_);fill:#3F8FC7;}
	.st843{clip-path:url(#SVGID_133_);fill:#4091C6;}
	.st844{clip-path:url(#SVGID_133_);fill:#4192C8;}
	.st845{clip-path:url(#SVGID_133_);fill:#4292C8;}
	.st846{clip-path:url(#SVGID_133_);fill:#4194C8;}
	.st847{clip-path:url(#SVGID_133_);fill:#4394C9;}
	.st848{clip-path:url(#SVGID_133_);fill:#4496CA;}
	.st849{clip-path:url(#SVGID_133_);fill:#4297CC;}
	.st850{clip-path:url(#SVGID_133_);fill:#4398CC;}
	.st851{clip-path:url(#SVGID_133_);fill:#4499CC;}
	.st852{clip-path:url(#SVGID_133_);fill:#469BCD;}
	.st853{clip-path:url(#SVGID_133_);fill:#479CCE;}
	.st854{clip-path:url(#SVGID_133_);fill:#469DCF;}
	.st855{clip-path:url(#SVGID_133_);fill:#479FD0;}
	.st856{clip-path:url(#SVGID_133_);fill:#49A0CF;}
	.st857{clip-path:url(#SVGID_133_);fill:#48A1D1;}
	.st858{clip-path:url(#SVGID_133_);fill:#48A3D1;}
	.st859{clip-path:url(#SVGID_133_);fill:#49A4D3;}
	.st860{clip-path:url(#SVGID_133_);fill:#4AA5D4;}
	.st861{clip-path:url(#SVGID_133_);fill:#49A7D5;}
	.st862{clip-path:url(#SVGID_133_);fill:#4BA8D5;}
	.st863{clip-path:url(#SVGID_133_);fill:#4BAAD6;}
	.st864{clip-path:url(#SVGID_133_);fill:#4CABD7;}
	.st865{clip-path:url(#SVGID_133_);fill:#4EADD8;}
	.st866{clip-path:url(#SVGID_133_);fill:#4FAED9;}
	.st867{clip-path:url(#SVGID_133_);fill:#50AEDA;}
	.st868{clip-path:url(#SVGID_133_);fill:#51B0D9;}
	.st869{clip-path:url(#SVGID_133_);fill:#51B1DB;}
	.st870{clip-path:url(#SVGID_133_);fill:#52B2DB;}
	.st871{clip-path:url(#SVGID_133_);fill:#53B3DC;}
	.st872{clip-path:url(#SVGID_133_);fill:#54B5DC;}
	.st873{clip-path:url(#SVGID_133_);fill:#56B6DD;}
	.st874{clip-path:url(#SVGID_133_);fill:#57B6DE;}
	.st875{clip-path:url(#SVGID_133_);fill:#58B8DF;}
	.st876{clip-path:url(#SVGID_133_);fill:#57B8E0;}
	.st877{clip-path:url(#SVGID_133_);fill:#58BAE0;}
	.st878{clip-path:url(#SVGID_133_);fill:#59BBE1;}
	.st879{clip-path:url(#SVGID_133_);fill:#5ABCE1;}
	.st880{clip-path:url(#SVGID_133_);fill:#5BBCE2;}
	.st881{clip-path:url(#SVGID_133_);fill:#5CBEE3;}
	.st882{clip-path:url(#SVGID_133_);fill:#5DBEE3;}
	.st883{clip-path:url(#SVGID_133_);fill:#5DBFE4;}
	.st884{clip-path:url(#SVGID_133_);fill:#5EC0E3;}
	.st885{clip-path:url(#SVGID_133_);fill:#60C2E4;}
	.st886{clip-path:url(#SVGID_133_);fill:#61C2E6;}
	.st887{clip-path:url(#SVGID_133_);fill:#61C3E5;}
	.st888{clip-path:url(#SVGID_133_);fill:#62C4E6;}
	.st889{clip-path:url(#SVGID_133_);fill:#63C5E6;}
	.st890{clip-path:url(#SVGID_133_);fill:#65C7E7;}
	.st891{clip-path:url(#SVGID_133_);fill:#64C7E8;}
	.st892{clip-path:url(#SVGID_133_);fill:#66C8E8;}
	.st893{clip-path:url(#SVGID_133_);fill:#67C9E8;}
	.st894{clip-path:url(#SVGID_133_);fill:#68CAE8;}
	.st895{clip-path:url(#SVGID_133_);fill:#68CBEA;}
	.st896{clip-path:url(#SVGID_133_);fill:#69CDEC;}
	.st897{clip-path:url(#SVGID_133_);fill:#6BCDEA;}
	.st898{clip-path:url(#SVGID_133_);fill:#6DCEEB;}
	.st899{clip-path:url(#SVGID_133_);fill:#6DCEEA;}
	.st900{clip-path:url(#SVGID_133_);fill:#6ECEEB;}
	.st901{clip-path:url(#SVGID_133_);fill:#70CEEB;}
	.st902{clip-path:url(#SVGID_133_);fill:#71CFEA;}
	.st903{clip-path:url(#SVGID_133_);fill:#72CFEB;}
	.st904{clip-path:url(#SVGID_133_);fill:#74CFEB;}
	.st905{clip-path:url(#SVGID_133_);fill:#75D0EB;}
	.st906{clip-path:url(#SVGID_133_);fill:#78D0EB;}
	.st907{clip-path:url(#SVGID_133_);fill:#79D0EB;}
	.st908{clip-path:url(#SVGID_133_);fill:#7AD1EB;}
	.st909{clip-path:url(#SVGID_133_);fill:#7CD1EB;}
	.st910{clip-path:url(#SVGID_133_);fill:#7ED2EC;}
	.st911{clip-path:url(#SVGID_133_);fill:#7FD2EB;}
	.st912{clip-path:url(#SVGID_133_);fill:#81D3EC;}
	.st913{clip-path:url(#SVGID_133_);fill:#84D3EB;}
	.st914{clip-path:url(#SVGID_133_);fill:#85D4EB;}
	.st915{clip-path:url(#SVGID_133_);fill:#86D4EB;}
	.st916{clip-path:url(#SVGID_133_);fill:#88D4EB;}
	.st917{clip-path:url(#SVGID_133_);fill:#89D5EC;}
	.st918{clip-path:url(#SVGID_133_);fill:#8BD5EB;}
	.st919{clip-path:url(#SVGID_133_);fill:#8CD6EB;}
	.st920{clip-path:url(#SVGID_133_);fill:#8DD6EC;}
	.st921{clip-path:url(#SVGID_133_);fill:#8FD6EB;}
	.st922{clip-path:url(#SVGID_133_);fill:#91D7EC;}
	.st923{clip-path:url(#SVGID_133_);fill:#92D7EB;}
	.st924{clip-path:url(#SVGID_133_);fill:#93D8EC;}
	.st925{clip-path:url(#SVGID_133_);fill:#94D8EC;}
	.st926{clip-path:url(#SVGID_133_);fill:#96D8EC;}
	.st927{clip-path:url(#SVGID_133_);fill:#97D9EC;}
	.st928{clip-path:url(#SVGID_133_);fill:#98D9EC;}
	.st929{clip-path:url(#SVGID_133_);fill:#99DAED;}
	.st930{clip-path:url(#SVGID_135_);fill:#3173B4;}
	.st931{clip-path:url(#SVGID_135_);fill:#3174B5;}
	.st932{clip-path:url(#SVGID_135_);fill:#3275B5;}
	.st933{clip-path:url(#SVGID_135_);fill:#3376B6;}
	.st934{clip-path:url(#SVGID_135_);fill:#3377B7;}
	.st935{clip-path:url(#SVGID_135_);fill:#3278B7;}
	.st936{clip-path:url(#SVGID_135_);fill:#3379B8;}
	.st937{clip-path:url(#SVGID_135_);fill:#347AB8;}
	.st938{clip-path:url(#SVGID_135_);fill:#357BB9;}
	.st939{clip-path:url(#SVGID_135_);fill:#357CBA;}
	.st940{clip-path:url(#SVGID_135_);fill:#367DBA;}
	.st941{clip-path:url(#SVGID_135_);fill:#377EBB;}
	.st942{clip-path:url(#SVGID_135_);fill:#387FBB;}
	.st943{clip-path:url(#SVGID_135_);fill:#3880BC;}
	.st944{clip-path:url(#SVGID_135_);fill:#3882BD;}
	.st945{clip-path:url(#SVGID_135_);fill:#3983BE;}
	.st946{clip-path:url(#SVGID_135_);fill:#3784BF;}
	.st947{clip-path:url(#SVGID_135_);fill:#3885C0;}
	.st948{clip-path:url(#SVGID_135_);fill:#3986C0;}
	.st949{clip-path:url(#SVGID_135_);fill:#3A87C1;}
	.st950{clip-path:url(#SVGID_135_);fill:#3A88C2;}
	.st951{clip-path:url(#SVGID_135_);fill:#3A8AC3;}
	.st952{clip-path:url(#SVGID_135_);fill:#3B8BC4;}
	.st953{clip-path:url(#SVGID_135_);fill:#3C8BC4;}
	.st954{clip-path:url(#SVGID_135_);fill:#3C8DC5;}
	.st955{clip-path:url(#SVGID_135_);fill:#3D8DC5;}
	.st956{clip-path:url(#SVGID_135_);fill:#3F8FC7;}
	.st957{clip-path:url(#SVGID_135_);fill:#4091C6;}
	.st958{clip-path:url(#SVGID_135_);fill:#4192C8;}
	.st959{clip-path:url(#SVGID_135_);fill:#4292C8;}
	.st960{clip-path:url(#SVGID_135_);fill:#4194C8;}
	.st961{clip-path:url(#SVGID_135_);fill:#4394C9;}
	.st962{clip-path:url(#SVGID_135_);fill:#4496CA;}
	.st963{clip-path:url(#SVGID_135_);fill:#4297CC;}
	.st964{clip-path:url(#SVGID_135_);fill:#4398CC;}
	.st965{clip-path:url(#SVGID_135_);fill:#4499CC;}
	.st966{clip-path:url(#SVGID_135_);fill:#469BCD;}
	.st967{clip-path:url(#SVGID_135_);fill:#479CCE;}
	.st968{clip-path:url(#SVGID_135_);fill:#469DCF;}
	.st969{clip-path:url(#SVGID_135_);fill:#479FD0;}
	.st970{clip-path:url(#SVGID_135_);fill:#49A0CF;}
	.st971{clip-path:url(#SVGID_135_);fill:#48A1D1;}
	.st972{clip-path:url(#SVGID_135_);fill:#48A3D1;}
	.st973{clip-path:url(#SVGID_135_);fill:#49A4D3;}
	.st974{clip-path:url(#SVGID_135_);fill:#4AA5D4;}
	.st975{clip-path:url(#SVGID_135_);fill:#49A7D5;}
	.st976{clip-path:url(#SVGID_135_);fill:#4BA8D5;}
	.st977{clip-path:url(#SVGID_135_);fill:#4BAAD6;}
	.st978{clip-path:url(#SVGID_135_);fill:#4CABD7;}
	.st979{clip-path:url(#SVGID_135_);fill:#4EADD8;}
	.st980{clip-path:url(#SVGID_135_);fill:#4FAED9;}
	.st981{clip-path:url(#SVGID_135_);fill:#50AEDA;}
	.st982{clip-path:url(#SVGID_135_);fill:#51B0D9;}
	.st983{clip-path:url(#SVGID_135_);fill:#51B1DB;}
	.st984{clip-path:url(#SVGID_135_);fill:#52B2DB;}
	.st985{clip-path:url(#SVGID_135_);fill:#53B3DC;}
	.st986{clip-path:url(#SVGID_135_);fill:#54B5DC;}
	.st987{clip-path:url(#SVGID_135_);fill:#56B6DD;}
	.st988{clip-path:url(#SVGID_135_);fill:#57B6DE;}
	.st989{clip-path:url(#SVGID_135_);fill:#58B8DF;}
	.st990{clip-path:url(#SVGID_135_);fill:#57B8E0;}
	.st991{clip-path:url(#SVGID_135_);fill:#58BAE0;}
	.st992{clip-path:url(#SVGID_135_);fill:#59BBE1;}
	.st993{clip-path:url(#SVGID_135_);fill:#5ABCE1;}
	.st994{clip-path:url(#SVGID_135_);fill:#5BBCE2;}
	.st995{clip-path:url(#SVGID_135_);fill:#5CBEE3;}
	.st996{clip-path:url(#SVGID_135_);fill:#5DBEE3;}
	.st997{clip-path:url(#SVGID_135_);fill:#5DBFE4;}
	.st998{clip-path:url(#SVGID_135_);fill:#5EC0E3;}
	.st999{clip-path:url(#SVGID_135_);fill:#60C2E4;}
	.st1000{clip-path:url(#SVGID_135_);fill:#61C2E6;}
	.st1001{clip-path:url(#SVGID_135_);fill:#61C3E5;}
	.st1002{clip-path:url(#SVGID_135_);fill:#62C4E6;}
	.st1003{clip-path:url(#SVGID_135_);fill:#63C5E6;}
	.st1004{clip-path:url(#SVGID_135_);fill:#65C7E7;}
	.st1005{clip-path:url(#SVGID_135_);fill:#64C7E8;}
	.st1006{clip-path:url(#SVGID_135_);fill:#66C8E8;}
	.st1007{clip-path:url(#SVGID_135_);fill:#67C9E8;}
	.st1008{clip-path:url(#SVGID_135_);fill:#68CAE8;}
	.st1009{clip-path:url(#SVGID_135_);fill:#68CBEA;}
	.st1010{clip-path:url(#SVGID_135_);fill:#69CDEC;}
	.st1011{clip-path:url(#SVGID_135_);fill:#6BCDEA;}
	.st1012{clip-path:url(#SVGID_135_);fill:#6DCEEB;}
	.st1013{clip-path:url(#SVGID_135_);fill:#6DCEEA;}
	.st1014{clip-path:url(#SVGID_135_);fill:#6ECEEB;}
	.st1015{clip-path:url(#SVGID_135_);fill:#70CEEB;}
	.st1016{clip-path:url(#SVGID_135_);fill:#71CFEA;}
	.st1017{clip-path:url(#SVGID_135_);fill:#72CFEB;}
	.st1018{clip-path:url(#SVGID_135_);fill:#74CFEB;}
	.st1019{clip-path:url(#SVGID_135_);fill:#75D0EB;}
	.st1020{clip-path:url(#SVGID_135_);fill:#78D0EB;}
	.st1021{clip-path:url(#SVGID_135_);fill:#79D0EB;}
	.st1022{clip-path:url(#SVGID_135_);fill:#7AD1EB;}
	.st1023{clip-path:url(#SVGID_135_);fill:#7CD1EB;}
	.st1024{clip-path:url(#SVGID_135_);fill:#7ED2EC;}
	.st1025{clip-path:url(#SVGID_135_);fill:#7FD2EB;}
	.st1026{clip-path:url(#SVGID_135_);fill:#81D3EC;}
	.st1027{clip-path:url(#SVGID_135_);fill:#84D3EB;}
	.st1028{clip-path:url(#SVGID_135_);fill:#85D4EB;}
	.st1029{clip-path:url(#SVGID_135_);fill:#86D4EB;}
	.st1030{clip-path:url(#SVGID_135_);fill:#88D4EB;}
	.st1031{clip-path:url(#SVGID_135_);fill:#89D5EC;}
	.st1032{clip-path:url(#SVGID_135_);fill:#8BD5EB;}
	.st1033{clip-path:url(#SVGID_135_);fill:#8CD6EB;}
	.st1034{clip-path:url(#SVGID_135_);fill:#8DD6EC;}
	.st1035{clip-path:url(#SVGID_135_);fill:#8FD6EB;}
	.st1036{clip-path:url(#SVGID_135_);fill:#91D7EC;}
	.st1037{clip-path:url(#SVGID_135_);fill:#92D7EB;}
	.st1038{clip-path:url(#SVGID_135_);fill:#93D8EC;}
	.st1039{clip-path:url(#SVGID_135_);fill:#94D8EC;}
	.st1040{clip-path:url(#SVGID_135_);fill:#96D8EC;}
	.st1041{clip-path:url(#SVGID_135_);fill:#97D9EC;}
	.st1042{clip-path:url(#SVGID_135_);fill:#98D9EC;}
	.st1043{clip-path:url(#SVGID_135_);fill:#99DAED;}
	.st1044{clip-path:url(#SVGID_137_);fill:#3173B4;}
	.st1045{clip-path:url(#SVGID_137_);fill:#3174B5;}
	.st1046{clip-path:url(#SVGID_137_);fill:#3275B5;}
	.st1047{clip-path:url(#SVGID_137_);fill:#3376B6;}
	.st1048{clip-path:url(#SVGID_137_);fill:#3377B7;}
	.st1049{clip-path:url(#SVGID_137_);fill:#3278B7;}
	.st1050{clip-path:url(#SVGID_137_);fill:#3379B8;}
	.st1051{clip-path:url(#SVGID_137_);fill:#347AB8;}
	.st1052{clip-path:url(#SVGID_137_);fill:#357BB9;}
	.st1053{clip-path:url(#SVGID_137_);fill:#357CBA;}
	.st1054{clip-path:url(#SVGID_137_);fill:#367DBA;}
	.st1055{clip-path:url(#SVGID_137_);fill:#377EBB;}
	.st1056{clip-path:url(#SVGID_137_);fill:#387FBB;}
	.st1057{clip-path:url(#SVGID_137_);fill:#3880BC;}
	.st1058{clip-path:url(#SVGID_137_);fill:#3882BD;}
	.st1059{clip-path:url(#SVGID_137_);fill:#3983BE;}
	.st1060{clip-path:url(#SVGID_137_);fill:#3784BF;}
	.st1061{clip-path:url(#SVGID_137_);fill:#3885C0;}
	.st1062{clip-path:url(#SVGID_137_);fill:#3986C0;}
	.st1063{clip-path:url(#SVGID_137_);fill:#3A87C1;}
	.st1064{clip-path:url(#SVGID_137_);fill:#3A88C2;}
	.st1065{clip-path:url(#SVGID_137_);fill:#3A8AC3;}
	.st1066{clip-path:url(#SVGID_137_);fill:#3B8BC4;}
	.st1067{clip-path:url(#SVGID_137_);fill:#3C8BC4;}
	.st1068{clip-path:url(#SVGID_137_);fill:#3C8DC5;}
	.st1069{clip-path:url(#SVGID_137_);fill:#3D8DC5;}
	.st1070{clip-path:url(#SVGID_137_);fill:#3F8FC7;}
	.st1071{clip-path:url(#SVGID_137_);fill:#4091C6;}
	.st1072{clip-path:url(#SVGID_137_);fill:#4192C8;}
	.st1073{clip-path:url(#SVGID_137_);fill:#4292C8;}
	.st1074{clip-path:url(#SVGID_137_);fill:#4194C8;}
	.st1075{clip-path:url(#SVGID_137_);fill:#4394C9;}
	.st1076{clip-path:url(#SVGID_137_);fill:#4496CA;}
	.st1077{clip-path:url(#SVGID_137_);fill:#4297CC;}
	.st1078{clip-path:url(#SVGID_137_);fill:#4398CC;}
	.st1079{clip-path:url(#SVGID_137_);fill:#4499CC;}
	.st1080{clip-path:url(#SVGID_137_);fill:#469BCD;}
	.st1081{clip-path:url(#SVGID_137_);fill:#479CCE;}
	.st1082{clip-path:url(#SVGID_137_);fill:#469DCF;}
	.st1083{clip-path:url(#SVGID_137_);fill:#479FD0;}
	.st1084{clip-path:url(#SVGID_137_);fill:#49A0CF;}
	.st1085{clip-path:url(#SVGID_137_);fill:#48A1D1;}
	.st1086{clip-path:url(#SVGID_137_);fill:#48A3D1;}
	.st1087{clip-path:url(#SVGID_137_);fill:#49A4D3;}
	.st1088{clip-path:url(#SVGID_137_);fill:#4AA5D4;}
	.st1089{clip-path:url(#SVGID_137_);fill:#49A7D5;}
	.st1090{clip-path:url(#SVGID_137_);fill:#4BA8D5;}
	.st1091{clip-path:url(#SVGID_137_);fill:#4BAAD6;}
	.st1092{clip-path:url(#SVGID_137_);fill:#4CABD7;}
	.st1093{clip-path:url(#SVGID_137_);fill:#4EADD8;}
	.st1094{clip-path:url(#SVGID_137_);fill:#4FAED9;}
	.st1095{clip-path:url(#SVGID_137_);fill:#50AEDA;}
	.st1096{clip-path:url(#SVGID_137_);fill:#51B0D9;}
	.st1097{clip-path:url(#SVGID_137_);fill:#51B1DB;}
	.st1098{clip-path:url(#SVGID_137_);fill:#52B2DB;}
	.st1099{clip-path:url(#SVGID_137_);fill:#53B3DC;}
	.st1100{clip-path:url(#SVGID_137_);fill:#54B5DC;}
	.st1101{clip-path:url(#SVGID_137_);fill:#56B6DD;}
	.st1102{clip-path:url(#SVGID_137_);fill:#57B6DE;}
	.st1103{clip-path:url(#SVGID_137_);fill:#58B8DF;}
	.st1104{clip-path:url(#SVGID_137_);fill:#57B8E0;}
	.st1105{clip-path:url(#SVGID_137_);fill:#58BAE0;}
	.st1106{clip-path:url(#SVGID_137_);fill:#59BBE1;}
	.st1107{clip-path:url(#SVGID_137_);fill:#5ABCE1;}
	.st1108{clip-path:url(#SVGID_137_);fill:#5BBCE2;}
	.st1109{clip-path:url(#SVGID_137_);fill:#5CBEE3;}
	.st1110{clip-path:url(#SVGID_137_);fill:#5DBEE3;}
	.st1111{clip-path:url(#SVGID_137_);fill:#5DBFE4;}
	.st1112{clip-path:url(#SVGID_137_);fill:#5EC0E3;}
	.st1113{clip-path:url(#SVGID_137_);fill:#60C2E4;}
	.st1114{clip-path:url(#SVGID_137_);fill:#61C2E6;}
	.st1115{clip-path:url(#SVGID_137_);fill:#61C3E5;}
	.st1116{clip-path:url(#SVGID_137_);fill:#62C4E6;}
	.st1117{clip-path:url(#SVGID_137_);fill:#63C5E6;}
	.st1118{clip-path:url(#SVGID_137_);fill:#65C7E7;}
	.st1119{clip-path:url(#SVGID_137_);fill:#64C7E8;}
	.st1120{clip-path:url(#SVGID_137_);fill:#66C8E8;}
	.st1121{clip-path:url(#SVGID_137_);fill:#67C9E8;}
	.st1122{clip-path:url(#SVGID_137_);fill:#68CAE8;}
	.st1123{clip-path:url(#SVGID_137_);fill:#68CBEA;}
	.st1124{clip-path:url(#SVGID_137_);fill:#69CDEC;}
	.st1125{clip-path:url(#SVGID_137_);fill:#6BCDEA;}
	.st1126{clip-path:url(#SVGID_137_);fill:#6DCEEB;}
	.st1127{clip-path:url(#SVGID_137_);fill:#6DCEEA;}
	.st1128{clip-path:url(#SVGID_137_);fill:#6ECEEB;}
	.st1129{clip-path:url(#SVGID_137_);fill:#70CEEB;}
	.st1130{clip-path:url(#SVGID_137_);fill:#71CFEA;}
	.st1131{clip-path:url(#SVGID_137_);fill:#72CFEB;}
	.st1132{clip-path:url(#SVGID_137_);fill:#74CFEB;}
	.st1133{clip-path:url(#SVGID_137_);fill:#75D0EB;}
	.st1134{clip-path:url(#SVGID_137_);fill:#78D0EB;}
	.st1135{clip-path:url(#SVGID_137_);fill:#79D0EB;}
	.st1136{clip-path:url(#SVGID_137_);fill:#7AD1EB;}
	.st1137{clip-path:url(#SVGID_137_);fill:#7CD1EB;}
	.st1138{clip-path:url(#SVGID_137_);fill:#7ED2EC;}
	.st1139{clip-path:url(#SVGID_137_);fill:#7FD2EB;}
	.st1140{clip-path:url(#SVGID_137_);fill:#81D3EC;}
	.st1141{clip-path:url(#SVGID_137_);fill:#84D3EB;}
	.st1142{clip-path:url(#SVGID_137_);fill:#85D4EB;}
	.st1143{clip-path:url(#SVGID_137_);fill:#86D4EB;}
	.st1144{clip-path:url(#SVGID_137_);fill:#88D4EB;}
	.st1145{clip-path:url(#SVGID_137_);fill:#89D5EC;}
	.st1146{clip-path:url(#SVGID_137_);fill:#8BD5EB;}
	.st1147{clip-path:url(#SVGID_137_);fill:#8CD6EB;}
	.st1148{clip-path:url(#SVGID_137_);fill:#8DD6EC;}
	.st1149{clip-path:url(#SVGID_137_);fill:#8FD6EB;}
	.st1150{clip-path:url(#SVGID_137_);fill:#91D7EC;}
	.st1151{clip-path:url(#SVGID_137_);fill:#92D7EB;}
	.st1152{clip-path:url(#SVGID_137_);fill:#93D8EC;}
	.st1153{clip-path:url(#SVGID_137_);fill:#94D8EC;}
	.st1154{clip-path:url(#SVGID_137_);fill:#96D8EC;}
	.st1155{clip-path:url(#SVGID_137_);fill:#97D9EC;}
	.st1156{clip-path:url(#SVGID_137_);fill:#98D9EC;}
	.st1157{clip-path:url(#SVGID_137_);fill:#99DAED;}
	.st1158{clip-path:url(#SVGID_139_);fill:#3173B4;}
	.st1159{clip-path:url(#SVGID_139_);fill:#3174B5;}
	.st1160{clip-path:url(#SVGID_139_);fill:#3275B5;}
	.st1161{clip-path:url(#SVGID_139_);fill:#3376B6;}
	.st1162{clip-path:url(#SVGID_139_);fill:#3377B7;}
	.st1163{clip-path:url(#SVGID_139_);fill:#3278B7;}
	.st1164{clip-path:url(#SVGID_139_);fill:#3379B8;}
	.st1165{clip-path:url(#SVGID_139_);fill:#347AB8;}
	.st1166{clip-path:url(#SVGID_139_);fill:#357BB9;}
	.st1167{clip-path:url(#SVGID_139_);fill:#357CBA;}
	.st1168{clip-path:url(#SVGID_139_);fill:#367DBA;}
	.st1169{clip-path:url(#SVGID_139_);fill:#377EBB;}
	.st1170{clip-path:url(#SVGID_139_);fill:#387FBB;}
	.st1171{clip-path:url(#SVGID_139_);fill:#3880BC;}
	.st1172{clip-path:url(#SVGID_139_);fill:#3882BD;}
	.st1173{clip-path:url(#SVGID_139_);fill:#3983BE;}
	.st1174{clip-path:url(#SVGID_139_);fill:#3784BF;}
	.st1175{clip-path:url(#SVGID_139_);fill:#3885C0;}
	.st1176{clip-path:url(#SVGID_139_);fill:#3986C0;}
	.st1177{clip-path:url(#SVGID_139_);fill:#3A87C1;}
	.st1178{clip-path:url(#SVGID_139_);fill:#3A88C2;}
	.st1179{clip-path:url(#SVGID_139_);fill:#3A8AC3;}
	.st1180{clip-path:url(#SVGID_139_);fill:#3B8BC4;}
	.st1181{clip-path:url(#SVGID_139_);fill:#3C8BC4;}
	.st1182{clip-path:url(#SVGID_139_);fill:#3C8DC5;}
	.st1183{clip-path:url(#SVGID_139_);fill:#3D8DC5;}
	.st1184{clip-path:url(#SVGID_139_);fill:#3F8FC7;}
	.st1185{clip-path:url(#SVGID_139_);fill:#4091C6;}
	.st1186{clip-path:url(#SVGID_139_);fill:#4192C8;}
	.st1187{clip-path:url(#SVGID_139_);fill:#4292C8;}
	.st1188{clip-path:url(#SVGID_139_);fill:#4194C8;}
	.st1189{clip-path:url(#SVGID_139_);fill:#4394C9;}
	.st1190{clip-path:url(#SVGID_139_);fill:#4496CA;}
	.st1191{clip-path:url(#SVGID_139_);fill:#4297CC;}
	.st1192{clip-path:url(#SVGID_139_);fill:#4398CC;}
	.st1193{clip-path:url(#SVGID_139_);fill:#4499CC;}
	.st1194{clip-path:url(#SVGID_139_);fill:#469BCD;}
	.st1195{clip-path:url(#SVGID_139_);fill:#479CCE;}
	.st1196{clip-path:url(#SVGID_139_);fill:#469DCF;}
	.st1197{clip-path:url(#SVGID_139_);fill:#479FD0;}
	.st1198{clip-path:url(#SVGID_139_);fill:#49A0CF;}
	.st1199{clip-path:url(#SVGID_139_);fill:#48A1D1;}
	.st1200{clip-path:url(#SVGID_139_);fill:#48A3D1;}
	.st1201{clip-path:url(#SVGID_139_);fill:#49A4D3;}
	.st1202{clip-path:url(#SVGID_139_);fill:#4AA5D4;}
	.st1203{clip-path:url(#SVGID_139_);fill:#49A7D5;}
	.st1204{clip-path:url(#SVGID_139_);fill:#4BA8D5;}
	.st1205{clip-path:url(#SVGID_139_);fill:#4BAAD6;}
	.st1206{clip-path:url(#SVGID_139_);fill:#4CABD7;}
	.st1207{clip-path:url(#SVGID_139_);fill:#4EADD8;}
	.st1208{clip-path:url(#SVGID_139_);fill:#4FAED9;}
	.st1209{clip-path:url(#SVGID_139_);fill:#50AEDA;}
	.st1210{clip-path:url(#SVGID_139_);fill:#51B0D9;}
	.st1211{clip-path:url(#SVGID_139_);fill:#51B1DB;}
	.st1212{clip-path:url(#SVGID_139_);fill:#52B2DB;}
	.st1213{clip-path:url(#SVGID_139_);fill:#53B3DC;}
	.st1214{clip-path:url(#SVGID_139_);fill:#54B5DC;}
	.st1215{clip-path:url(#SVGID_139_);fill:#56B6DD;}
	.st1216{clip-path:url(#SVGID_139_);fill:#57B6DE;}
	.st1217{clip-path:url(#SVGID_139_);fill:#58B8DF;}
	.st1218{clip-path:url(#SVGID_139_);fill:#57B8E0;}
	.st1219{clip-path:url(#SVGID_139_);fill:#58BAE0;}
	.st1220{clip-path:url(#SVGID_139_);fill:#59BBE1;}
	.st1221{clip-path:url(#SVGID_139_);fill:#5ABCE1;}
	.st1222{clip-path:url(#SVGID_139_);fill:#5BBCE2;}
	.st1223{clip-path:url(#SVGID_139_);fill:#5CBEE3;}
	.st1224{clip-path:url(#SVGID_139_);fill:#5DBEE3;}
	.st1225{clip-path:url(#SVGID_139_);fill:#5DBFE4;}
	.st1226{clip-path:url(#SVGID_139_);fill:#5EC0E3;}
	.st1227{clip-path:url(#SVGID_139_);fill:#60C2E4;}
	.st1228{clip-path:url(#SVGID_139_);fill:#61C2E6;}
	.st1229{clip-path:url(#SVGID_139_);fill:#61C3E5;}
	.st1230{clip-path:url(#SVGID_139_);fill:#62C4E6;}
	.st1231{clip-path:url(#SVGID_139_);fill:#63C5E6;}
	.st1232{clip-path:url(#SVGID_139_);fill:#65C7E7;}
	.st1233{clip-path:url(#SVGID_139_);fill:#64C7E8;}
	.st1234{clip-path:url(#SVGID_139_);fill:#66C8E8;}
	.st1235{clip-path:url(#SVGID_139_);fill:#67C9E8;}
	.st1236{clip-path:url(#SVGID_139_);fill:#68CAE8;}
	.st1237{clip-path:url(#SVGID_139_);fill:#68CBEA;}
	.st1238{clip-path:url(#SVGID_139_);fill:#69CDEC;}
	.st1239{clip-path:url(#SVGID_139_);fill:#6BCDEA;}
	.st1240{clip-path:url(#SVGID_139_);fill:#6DCEEB;}
	.st1241{clip-path:url(#SVGID_139_);fill:#6DCEEA;}
	.st1242{clip-path:url(#SVGID_139_);fill:#6ECEEB;}
	.st1243{clip-path:url(#SVGID_139_);fill:#70CEEB;}
	.st1244{clip-path:url(#SVGID_139_);fill:#71CFEA;}
	.st1245{clip-path:url(#SVGID_139_);fill:#72CFEB;}
	.st1246{clip-path:url(#SVGID_139_);fill:#74CFEB;}
	.st1247{clip-path:url(#SVGID_139_);fill:#75D0EB;}
	.st1248{clip-path:url(#SVGID_139_);fill:#78D0EB;}
	.st1249{clip-path:url(#SVGID_139_);fill:#79D0EB;}
	.st1250{clip-path:url(#SVGID_139_);fill:#7AD1EB;}
	.st1251{clip-path:url(#SVGID_139_);fill:#7CD1EB;}
	.st1252{clip-path:url(#SVGID_139_);fill:#7ED2EC;}
	.st1253{clip-path:url(#SVGID_139_);fill:#7FD2EB;}
	.st1254{clip-path:url(#SVGID_139_);fill:#81D3EC;}
	.st1255{clip-path:url(#SVGID_139_);fill:#84D3EB;}
	.st1256{clip-path:url(#SVGID_139_);fill:#85D4EB;}
	.st1257{clip-path:url(#SVGID_139_);fill:#86D4EB;}
	.st1258{clip-path:url(#SVGID_139_);fill:#88D4EB;}
	.st1259{clip-path:url(#SVGID_139_);fill:#89D5EC;}
	.st1260{clip-path:url(#SVGID_139_);fill:#8BD5EB;}
	.st1261{clip-path:url(#SVGID_139_);fill:#8CD6EB;}
	.st1262{clip-path:url(#SVGID_139_);fill:#8DD6EC;}
	.st1263{clip-path:url(#SVGID_139_);fill:#8FD6EB;}
	.st1264{clip-path:url(#SVGID_139_);fill:#91D7EC;}
	.st1265{clip-path:url(#SVGID_139_);fill:#92D7EB;}
	.st1266{clip-path:url(#SVGID_139_);fill:#93D8EC;}
	.st1267{clip-path:url(#SVGID_139_);fill:#94D8EC;}
	.st1268{clip-path:url(#SVGID_139_);fill:#96D8EC;}
	.st1269{clip-path:url(#SVGID_139_);fill:#97D9EC;}
	.st1270{clip-path:url(#SVGID_139_);fill:#98D9EC;}
	.st1271{clip-path:url(#SVGID_139_);fill:#99DAED;}
	.st1272{clip-path:url(#SVGID_141_);fill:#3173B4;}
	.st1273{clip-path:url(#SVGID_141_);fill:#3174B5;}
	.st1274{clip-path:url(#SVGID_141_);fill:#3275B5;}
	.st1275{clip-path:url(#SVGID_141_);fill:#3376B6;}
	.st1276{clip-path:url(#SVGID_141_);fill:#3377B7;}
	.st1277{clip-path:url(#SVGID_141_);fill:#3278B7;}
	.st1278{clip-path:url(#SVGID_141_);fill:#3379B8;}
	.st1279{clip-path:url(#SVGID_141_);fill:#347AB8;}
	.st1280{clip-path:url(#SVGID_141_);fill:#357BB9;}
	.st1281{clip-path:url(#SVGID_141_);fill:#357CBA;}
	.st1282{clip-path:url(#SVGID_141_);fill:#367DBA;}
	.st1283{clip-path:url(#SVGID_141_);fill:#377EBB;}
	.st1284{clip-path:url(#SVGID_141_);fill:#387FBB;}
	.st1285{clip-path:url(#SVGID_141_);fill:#3880BC;}
	.st1286{clip-path:url(#SVGID_141_);fill:#3882BD;}
	.st1287{clip-path:url(#SVGID_141_);fill:#3983BE;}
	.st1288{clip-path:url(#SVGID_141_);fill:#3784BF;}
	.st1289{clip-path:url(#SVGID_141_);fill:#3885C0;}
	.st1290{clip-path:url(#SVGID_141_);fill:#3986C0;}
	.st1291{clip-path:url(#SVGID_141_);fill:#3A87C1;}
	.st1292{clip-path:url(#SVGID_141_);fill:#3A88C2;}
	.st1293{clip-path:url(#SVGID_141_);fill:#3A8AC3;}
	.st1294{clip-path:url(#SVGID_141_);fill:#3B8BC4;}
	.st1295{clip-path:url(#SVGID_141_);fill:#3C8BC4;}
	.st1296{clip-path:url(#SVGID_141_);fill:#3C8DC5;}
	.st1297{clip-path:url(#SVGID_141_);fill:#3D8DC5;}
	.st1298{clip-path:url(#SVGID_141_);fill:#3F8FC7;}
	.st1299{clip-path:url(#SVGID_141_);fill:#4091C6;}
	.st1300{clip-path:url(#SVGID_141_);fill:#4192C8;}
	.st1301{clip-path:url(#SVGID_141_);fill:#4292C8;}
	.st1302{clip-path:url(#SVGID_141_);fill:#4194C8;}
	.st1303{clip-path:url(#SVGID_141_);fill:#4394C9;}
	.st1304{clip-path:url(#SVGID_141_);fill:#4496CA;}
	.st1305{clip-path:url(#SVGID_141_);fill:#4297CC;}
	.st1306{clip-path:url(#SVGID_141_);fill:#4398CC;}
	.st1307{clip-path:url(#SVGID_141_);fill:#4499CC;}
	.st1308{clip-path:url(#SVGID_141_);fill:#469BCD;}
	.st1309{clip-path:url(#SVGID_141_);fill:#479CCE;}
	.st1310{clip-path:url(#SVGID_141_);fill:#469DCF;}
	.st1311{clip-path:url(#SVGID_141_);fill:#479FD0;}
	.st1312{clip-path:url(#SVGID_141_);fill:#49A0CF;}
	.st1313{clip-path:url(#SVGID_141_);fill:#48A1D1;}
	.st1314{clip-path:url(#SVGID_141_);fill:#48A3D1;}
	.st1315{clip-path:url(#SVGID_141_);fill:#49A4D3;}
	.st1316{clip-path:url(#SVGID_141_);fill:#4AA5D4;}
	.st1317{clip-path:url(#SVGID_141_);fill:#49A7D5;}
	.st1318{clip-path:url(#SVGID_141_);fill:#4BA8D5;}
	.st1319{clip-path:url(#SVGID_141_);fill:#4BAAD6;}
	.st1320{clip-path:url(#SVGID_141_);fill:#4CABD7;}
	.st1321{clip-path:url(#SVGID_141_);fill:#4EADD8;}
	.st1322{clip-path:url(#SVGID_141_);fill:#4FAED9;}
	.st1323{clip-path:url(#SVGID_141_);fill:#50AEDA;}
	.st1324{clip-path:url(#SVGID_141_);fill:#51B0D9;}
	.st1325{clip-path:url(#SVGID_141_);fill:#51B1DB;}
	.st1326{clip-path:url(#SVGID_141_);fill:#52B2DB;}
	.st1327{clip-path:url(#SVGID_141_);fill:#53B3DC;}
	.st1328{clip-path:url(#SVGID_141_);fill:#54B5DC;}
	.st1329{clip-path:url(#SVGID_141_);fill:#56B6DD;}
	.st1330{clip-path:url(#SVGID_141_);fill:#57B6DE;}
	.st1331{clip-path:url(#SVGID_141_);fill:#58B8DF;}
	.st1332{clip-path:url(#SVGID_141_);fill:#57B8E0;}
	.st1333{clip-path:url(#SVGID_141_);fill:#58BAE0;}
	.st1334{clip-path:url(#SVGID_141_);fill:#59BBE1;}
	.st1335{clip-path:url(#SVGID_141_);fill:#5ABCE1;}
	.st1336{clip-path:url(#SVGID_141_);fill:#5BBCE2;}
	.st1337{clip-path:url(#SVGID_141_);fill:#5CBEE3;}
	.st1338{clip-path:url(#SVGID_141_);fill:#5DBEE3;}
	.st1339{clip-path:url(#SVGID_141_);fill:#5DBFE4;}
	.st1340{clip-path:url(#SVGID_141_);fill:#5EC0E3;}
	.st1341{clip-path:url(#SVGID_141_);fill:#60C2E4;}
	.st1342{clip-path:url(#SVGID_141_);fill:#61C2E6;}
	.st1343{clip-path:url(#SVGID_141_);fill:#61C3E5;}
	.st1344{clip-path:url(#SVGID_141_);fill:#62C4E6;}
	.st1345{clip-path:url(#SVGID_141_);fill:#63C5E6;}
	.st1346{clip-path:url(#SVGID_141_);fill:#65C7E7;}
	.st1347{clip-path:url(#SVGID_141_);fill:#64C7E8;}
	.st1348{clip-path:url(#SVGID_141_);fill:#66C8E8;}
	.st1349{clip-path:url(#SVGID_141_);fill:#67C9E8;}
	.st1350{clip-path:url(#SVGID_141_);fill:#68CAE8;}
	.st1351{clip-path:url(#SVGID_141_);fill:#68CBEA;}
	.st1352{clip-path:url(#SVGID_141_);fill:#69CDEC;}
	.st1353{clip-path:url(#SVGID_141_);fill:#6BCDEA;}
	.st1354{clip-path:url(#SVGID_141_);fill:#6DCEEB;}
	.st1355{clip-path:url(#SVGID_141_);fill:#6DCEEA;}
	.st1356{clip-path:url(#SVGID_141_);fill:#6ECEEB;}
	.st1357{clip-path:url(#SVGID_141_);fill:#70CEEB;}
	.st1358{clip-path:url(#SVGID_141_);fill:#71CFEA;}
	.st1359{clip-path:url(#SVGID_141_);fill:#72CFEB;}
	.st1360{clip-path:url(#SVGID_141_);fill:#74CFEB;}
	.st1361{clip-path:url(#SVGID_141_);fill:#75D0EB;}
	.st1362{clip-path:url(#SVGID_141_);fill:#78D0EB;}
	.st1363{clip-path:url(#SVGID_141_);fill:#79D0EB;}
	.st1364{clip-path:url(#SVGID_141_);fill:#7AD1EB;}
	.st1365{clip-path:url(#SVGID_141_);fill:#7CD1EB;}
	.st1366{clip-path:url(#SVGID_141_);fill:#7ED2EC;}
	.st1367{clip-path:url(#SVGID_141_);fill:#7FD2EB;}
	.st1368{clip-path:url(#SVGID_141_);fill:#81D3EC;}
	.st1369{clip-path:url(#SVGID_141_);fill:#84D3EB;}
	.st1370{clip-path:url(#SVGID_141_);fill:#85D4EB;}
	.st1371{clip-path:url(#SVGID_141_);fill:#86D4EB;}
	.st1372{clip-path:url(#SVGID_141_);fill:#88D4EB;}
	.st1373{clip-path:url(#SVGID_141_);fill:#89D5EC;}
	.st1374{clip-path:url(#SVGID_141_);fill:#8BD5EB;}
	.st1375{clip-path:url(#SVGID_141_);fill:#8CD6EB;}
	.st1376{clip-path:url(#SVGID_141_);fill:#8DD6EC;}
	.st1377{clip-path:url(#SVGID_141_);fill:#8FD6EB;}
	.st1378{clip-path:url(#SVGID_141_);fill:#91D7EC;}
	.st1379{clip-path:url(#SVGID_141_);fill:#92D7EB;}
	.st1380{clip-path:url(#SVGID_141_);fill:#93D8EC;}
	.st1381{clip-path:url(#SVGID_141_);fill:#94D8EC;}
	.st1382{clip-path:url(#SVGID_141_);fill:#96D8EC;}
	.st1383{clip-path:url(#SVGID_141_);fill:#97D9EC;}
	.st1384{clip-path:url(#SVGID_141_);fill:#98D9EC;}
	.st1385{clip-path:url(#SVGID_141_);fill:#99DAED;}
	.st1386{clip-path:url(#SVGID_143_);fill:#3173B4;}
	.st1387{clip-path:url(#SVGID_143_);fill:#3174B5;}
	.st1388{clip-path:url(#SVGID_143_);fill:#3275B5;}
	.st1389{clip-path:url(#SVGID_143_);fill:#3376B6;}
	.st1390{clip-path:url(#SVGID_143_);fill:#3377B7;}
	.st1391{clip-path:url(#SVGID_143_);fill:#3278B7;}
	.st1392{clip-path:url(#SVGID_143_);fill:#3379B8;}
	.st1393{clip-path:url(#SVGID_143_);fill:#347AB8;}
	.st1394{clip-path:url(#SVGID_143_);fill:#357BB9;}
	.st1395{clip-path:url(#SVGID_143_);fill:#357CBA;}
	.st1396{clip-path:url(#SVGID_143_);fill:#367DBA;}
	.st1397{clip-path:url(#SVGID_143_);fill:#377EBB;}
	.st1398{clip-path:url(#SVGID_143_);fill:#387FBB;}
	.st1399{clip-path:url(#SVGID_143_);fill:#3880BC;}
	.st1400{clip-path:url(#SVGID_143_);fill:#3882BD;}
	.st1401{clip-path:url(#SVGID_143_);fill:#3983BE;}
	.st1402{clip-path:url(#SVGID_143_);fill:#3784BF;}
	.st1403{clip-path:url(#SVGID_143_);fill:#3885C0;}
	.st1404{clip-path:url(#SVGID_143_);fill:#3986C0;}
	.st1405{clip-path:url(#SVGID_143_);fill:#3A87C1;}
	.st1406{clip-path:url(#SVGID_143_);fill:#3A88C2;}
	.st1407{clip-path:url(#SVGID_143_);fill:#3A8AC3;}
	.st1408{clip-path:url(#SVGID_143_);fill:#3B8BC4;}
	.st1409{clip-path:url(#SVGID_143_);fill:#3C8BC4;}
	.st1410{clip-path:url(#SVGID_143_);fill:#3C8DC5;}
	.st1411{clip-path:url(#SVGID_143_);fill:#3D8DC5;}
	.st1412{clip-path:url(#SVGID_143_);fill:#3F8FC7;}
	.st1413{clip-path:url(#SVGID_143_);fill:#4091C6;}
	.st1414{clip-path:url(#SVGID_143_);fill:#4192C8;}
	.st1415{clip-path:url(#SVGID_143_);fill:#4292C8;}
	.st1416{clip-path:url(#SVGID_143_);fill:#4194C8;}
	.st1417{clip-path:url(#SVGID_143_);fill:#4394C9;}
	.st1418{clip-path:url(#SVGID_143_);fill:#4496CA;}
	.st1419{clip-path:url(#SVGID_143_);fill:#4297CC;}
	.st1420{clip-path:url(#SVGID_143_);fill:#4398CC;}
	.st1421{clip-path:url(#SVGID_143_);fill:#4499CC;}
	.st1422{clip-path:url(#SVGID_143_);fill:#469BCD;}
	.st1423{clip-path:url(#SVGID_143_);fill:#479CCE;}
	.st1424{clip-path:url(#SVGID_143_);fill:#469DCF;}
	.st1425{clip-path:url(#SVGID_143_);fill:#479FD0;}
	.st1426{clip-path:url(#SVGID_143_);fill:#49A0CF;}
	.st1427{clip-path:url(#SVGID_143_);fill:#48A1D1;}
	.st1428{clip-path:url(#SVGID_143_);fill:#48A3D1;}
	.st1429{clip-path:url(#SVGID_143_);fill:#49A4D3;}
	.st1430{clip-path:url(#SVGID_143_);fill:#4AA5D4;}
	.st1431{clip-path:url(#SVGID_143_);fill:#49A7D5;}
	.st1432{clip-path:url(#SVGID_143_);fill:#4BA8D5;}
	.st1433{clip-path:url(#SVGID_143_);fill:#4BAAD6;}
	.st1434{clip-path:url(#SVGID_143_);fill:#4CABD7;}
	.st1435{clip-path:url(#SVGID_143_);fill:#4EADD8;}
	.st1436{clip-path:url(#SVGID_143_);fill:#4FAED9;}
	.st1437{clip-path:url(#SVGID_143_);fill:#50AEDA;}
	.st1438{clip-path:url(#SVGID_143_);fill:#51B0D9;}
	.st1439{clip-path:url(#SVGID_143_);fill:#51B1DB;}
	.st1440{clip-path:url(#SVGID_143_);fill:#52B2DB;}
	.st1441{clip-path:url(#SVGID_143_);fill:#53B3DC;}
	.st1442{clip-path:url(#SVGID_143_);fill:#54B5DC;}
	.st1443{clip-path:url(#SVGID_143_);fill:#56B6DD;}
	.st1444{clip-path:url(#SVGID_143_);fill:#57B6DE;}
	.st1445{clip-path:url(#SVGID_143_);fill:#58B8DF;}
	.st1446{clip-path:url(#SVGID_143_);fill:#57B8E0;}
	.st1447{clip-path:url(#SVGID_143_);fill:#58BAE0;}
	.st1448{clip-path:url(#SVGID_143_);fill:#59BBE1;}
	.st1449{clip-path:url(#SVGID_143_);fill:#5ABCE1;}
	.st1450{clip-path:url(#SVGID_143_);fill:#5BBCE2;}
	.st1451{clip-path:url(#SVGID_143_);fill:#5CBEE3;}
	.st1452{clip-path:url(#SVGID_143_);fill:#5DBEE3;}
	.st1453{clip-path:url(#SVGID_143_);fill:#5DBFE4;}
	.st1454{clip-path:url(#SVGID_143_);fill:#5EC0E3;}
	.st1455{clip-path:url(#SVGID_143_);fill:#60C2E4;}
	.st1456{clip-path:url(#SVGID_143_);fill:#61C2E6;}
	.st1457{clip-path:url(#SVGID_143_);fill:#61C3E5;}
	.st1458{clip-path:url(#SVGID_143_);fill:#62C4E6;}
	.st1459{clip-path:url(#SVGID_143_);fill:#63C5E6;}
	.st1460{clip-path:url(#SVGID_143_);fill:#65C7E7;}
	.st1461{clip-path:url(#SVGID_143_);fill:#64C7E8;}
	.st1462{clip-path:url(#SVGID_143_);fill:#66C8E8;}
	.st1463{clip-path:url(#SVGID_143_);fill:#67C9E8;}
	.st1464{clip-path:url(#SVGID_143_);fill:#68CAE8;}
	.st1465{clip-path:url(#SVGID_143_);fill:#68CBEA;}
	.st1466{clip-path:url(#SVGID_143_);fill:#69CDEC;}
	.st1467{clip-path:url(#SVGID_143_);fill:#6BCDEA;}
	.st1468{clip-path:url(#SVGID_143_);fill:#6DCEEB;}
	.st1469{clip-path:url(#SVGID_143_);fill:#6DCEEA;}
	.st1470{clip-path:url(#SVGID_143_);fill:#6ECEEB;}
	.st1471{clip-path:url(#SVGID_143_);fill:#70CEEB;}
	.st1472{clip-path:url(#SVGID_143_);fill:#71CFEA;}
	.st1473{clip-path:url(#SVGID_143_);fill:#72CFEB;}
	.st1474{clip-path:url(#SVGID_143_);fill:#74CFEB;}
	.st1475{clip-path:url(#SVGID_143_);fill:#75D0EB;}
	.st1476{clip-path:url(#SVGID_143_);fill:#78D0EB;}
	.st1477{clip-path:url(#SVGID_143_);fill:#79D0EB;}
	.st1478{clip-path:url(#SVGID_143_);fill:#7AD1EB;}
	.st1479{clip-path:url(#SVGID_143_);fill:#7CD1EB;}
	.st1480{clip-path:url(#SVGID_143_);fill:#7ED2EC;}
	.st1481{clip-path:url(#SVGID_143_);fill:#7FD2EB;}
	.st1482{clip-path:url(#SVGID_143_);fill:#81D3EC;}
	.st1483{clip-path:url(#SVGID_143_);fill:#84D3EB;}
	.st1484{clip-path:url(#SVGID_143_);fill:#85D4EB;}
	.st1485{clip-path:url(#SVGID_143_);fill:#86D4EB;}
	.st1486{clip-path:url(#SVGID_143_);fill:#88D4EB;}
	.st1487{clip-path:url(#SVGID_143_);fill:#89D5EC;}
	.st1488{clip-path:url(#SVGID_143_);fill:#8BD5EB;}
	.st1489{clip-path:url(#SVGID_143_);fill:#8CD6EB;}
	.st1490{clip-path:url(#SVGID_143_);fill:#8DD6EC;}
	.st1491{clip-path:url(#SVGID_143_);fill:#8FD6EB;}
	.st1492{clip-path:url(#SVGID_143_);fill:#91D7EC;}
	.st1493{clip-path:url(#SVGID_143_);fill:#92D7EB;}
	.st1494{clip-path:url(#SVGID_143_);fill:#93D8EC;}
	.st1495{clip-path:url(#SVGID_143_);fill:#94D8EC;}
	.st1496{clip-path:url(#SVGID_143_);fill:#96D8EC;}
	.st1497{clip-path:url(#SVGID_143_);fill:#97D9EC;}
	.st1498{clip-path:url(#SVGID_143_);fill:#98D9EC;}
	.st1499{clip-path:url(#SVGID_143_);fill:#99DAED;}
	.st1500{fill:url(#SVGID_144_);}
	.st1501{fill:url(#SVGID_145_);}
	.st1502{opacity:0.2;fill:url(#SVGID_146_);}
	.st1503{opacity:0.19;clip-path:url(#SVGID_2_);}
	.st1504{opacity:0.35;clip-path:url(#SVGID_2_);}
	.st1505{clip-path:url(#SVGID_2_);}
	.st1506{fill:url(#SVGID_147_);}
	.st1507{opacity:0.58;}
	.st1508{clip-path:url(#SVGID_149_);}
	.st1509{fill:#020202;}
	.st1510{fill:url(#SVGID_150_);}
	.st1511{fill-rule:evenodd;clip-rule:evenodd;fill:#5A3B18;}
	.st1512{font-family:'Montserrat-Bold';}
	.st1513{font-size:8.0915px;font-weight: 600;}
	.st1514{font-family:'Montserrat-SemiBold';}
	.st1515{font-size:{{locationFont}}px;fill: #1B75BC;}
	.st1516{font-size:20.0245px;}
	.st1517{font-family:'Montserrat-ExtraBold';}
	.st1518{font-size:{{companyFont}}px;font-weight:900;}
	.st1519{fill:#414042;}
	.st1520{fill:#010101;}
	.st1521{font-size:9.716px;}
	.st1522{font-size:11.4175px;}
	.st1523{fill:url(#SVGID_151_);}
	.st1524{fill:url(#SVGID_152_);}
	.st1525{fill:url(#SVGID_153_);}
	.st1526{clip-path:url(#SVGID_2_);fill:#1B75BC;}
</svg:style>
<g>
	<defs>
		<rect id="SVGID_1_" width="595.4" height="841.7"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
	<g class="st0">
		<defs>
			
				<rect id="SVGID_3_" x="-128.2" y="121.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -128.228 717.1362)" class="st1" width="845.4" height="601.9"/>
		</defs>
		<clipPath id="SVGID_4_">
			<use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
		</clipPath>
		<g class="st2">
			
				<radialGradient id="SVGID_5_" cx="855.6381" cy="426.9887" r="4.9739" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
				<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
				<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
				<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
				<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
				<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st3" cx="496.8" cy="181.5" rx="6" ry="5.6"/>
			
				<radialGradient id="SVGID_6_" cx="773.4438" cy="475.4556" r="9.2165" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st4" cx="555.1" cy="274.2" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_7_" cx="387.8831" cy="167.4643" r="9.2164" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st5" cx="184.4" cy="708.8" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_8_" cx="549.504" cy="230.7924" r="9.2165" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st6" cx="260.6" cy="526.6" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_9_" cx="789.4937" cy="251.0856" r="9.2164" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st7" cx="285" cy="256.1" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_12_" cx="972.4518" cy="346.1765" r="9.2165" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st127" cx="399.5" cy="49.9" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_13_" cx="930.6256" cy="240.0089" r="9.2165" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st128" cx="271.7" cy="97" rx="11.1" ry="10.4"/>
			
				<radialGradient id="SVGID_14_" cx="328.3897" cy="74.5877" r="16.4377" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st129" cx="72.6" cy="775.8" rx="19.8" ry="18.5"/>
			
				<radialGradient id="SVGID_15_" cx="353.7359" cy="419.8723" r="16.4377" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st130" cx="488.2" cy="747.3" rx="19.8" ry="18.5"/>
			
				<radialGradient id="SVGID_16_" cx="269.9344" cy="268.2846" r="25.5741" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st131" cx="305.7" cy="841.7" rx="30.8" ry="28.8"/>
			
				<radialGradient id="SVGID_17_" cx="944.4548" cy="489.9116" r="25.5741" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3995" style="stop-color:#5BB4D8;stop-opacity:0.7471"/>
				<stop  offset="0.7093" style="stop-color:#3C84BD;stop-opacity:0.2001"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st132" cx="572.5" cy="81.4" rx="30.8" ry="28.8"/>
			<g class="st133">
				<path class="st134" d="M385.7,64.2l-83.1-37.8l-39.1-96.1l-0.2,0.3l-41.9,93.4l-0.4-0.1l0.3,0.3l-0.1,0.1l0.2,0l70.4,77.6l0.1,0
					l93.7-37.5l-0.1-0.1L385.7,64.2z M222,24.6l163.1,39.8L292,101.7L222,24.6z M302.4,26.7l81.7,37.1L222.9,24.5L302.4,26.7z
					 M221.8,24.2l41.7-93l38.8,95.2L221.8,24.2z"/>
				<path class="st134" d="M385.8,64.3L385.8,64.3L263.6-69.3l-0.2,0.2L385.4,64.4l-54.7,124.8l0.3-0.1l79.6-30.3l0-0.1L385.8,64.3
					L385.8,64.3z M331.2,188.6l54.3-123.8l24.7,93.7L331.2,188.6z"/>
				<path class="st134" d="M292.1,101.8l-0.3,0.1l17.3,72.3l0,0l22.1,15l-0.2-0.5L292.1,101.8z M309.4,174.1l-16.9-70.6l38.1,84.9
					L309.4,174.1z"/>
				<path class="st134" d="M484.8,4.1l-99.7,60.3l0.4,0.1l79.8,9.4l0-0.1L484.8,4.1z M386.1,64.2l98.2-59.5l-19.2,68.8L386.1,64.2z"
					/>
				<path class="st134" d="M580.8,185L580.8,185L464.7,73l0.4,0.8l117.3,266.6l0.3-0.1L567,268.9L580.8,185z M465.8,74.5
					l114.7,110.5l-13.8,83.4L465.8,74.5z M582,338.9L467.3,78.1L566.7,269L582,338.9z"/>
				<path class="st134" d="M567.1,268.6l-0.3,0.2l-126.4,90l0.6-0.1l109.5-14.1l-31,144.4l0.3,0.1l63-149l-0.3,0l-31.6,4.1
					L567.1,268.6z M441.5,358.4l125.1-89.1l-16.1,75.1L441.5,358.4z M550.8,344.6l31.5-4.1l-62.1,146.9L550.8,344.6z"/>
				<path class="st134" d="M505.1,15.6l-0.2,0L313.1-21.8L485.3,4.7l-0.7-0.4L310.4-96.6l-0.2,0.3L483.8,4.2L305.7-23.3l-0.1,0.3
					l199,38.8l-38.8,56.8l0.1-0.1l-0.8,1.3l-54.8,84.9l-8.3,61.3l-71.7-31.5l0.6,0.6l66.8,65.3l0-0.3l11.5-30.5l55.3,24.3l0,0l0,0
					l3.9,51.9l0.2-0.1l98.9-30.7l-0.6-0.1l-102.2-21.3l0.7-173.7L505.1,15.6z M409.2,223.1l55.8-148.5l-0.7,172.8L409.2,223.1z
					 M410.6,158.7l54-83.6L408.9,223l-6.7-2.9L410.6,158.7z M464.7,247.8L566.2,269l-97.6,30.3L464.7,247.8z M402.2,220.4l6.6,2.9
					l-11,29.3L402.2,220.4z M331.7,189.3l70.2,30.9l-4.5,33.4L331.7,189.3z"/>
				<polygon class="st134" points="504.8,15.6 385.7,64.1 310.2,-97.1 310.2,-96.5 302.3,26.5 302.6,26.5 310.4,-95.9 385.5,64.5 
					385.7,64.5 504.9,15.8 				"/>
				<polygon class="st134" points="635.4,151.3 635.1,151.2 582.4,340.1 464.8,247.7 581.1,184.9 580.7,184.9 410.5,158.5 
					410.4,158.8 580.2,185.1 464.3,247.6 464.5,247.8 582.6,340.6 582.6,340.4 				"/>
				<path class="st134" d="M635.6,151.3l-0.2-0.1l-171.1-78l129,104l-12.8,7.9l0.2,0.1l73.5,41.1l-87.4,42.6l0.1,0.3l87.8-42.8
					l-0.2-0.1l-60.8-49L635.6,151.3z M466.3,74.4l168.7,76.9l-41.4,25.6L466.3,74.4z M593.5,177.3l59.5,48L581,185L593.5,177.3z"/>
				<path class="st134" d="M580.8,185l-0.3,0l1.8,155.4l0,0l62.5,118.8l-94.4-114.8l0.3,0.8L607,517.9l0.2-0.3l38.6-57.1l-0.1-0.1
					l0.1-0.1l-63.2-120.1L580.8,185z M551.2,345.9l94.3,114.6l-38.3,56.7L551.2,345.9z"/>
				<polygon class="st134" points="426.3,-128.3 426.2,-127.9 385.4,64.3 385.8,64.4 426.4,-127.4 504.7,15.8 504.8,15.8 
					635.1,151.4 635.4,151.2 505,15.6 				"/>
				<polygon class="st134" points="573.8,25.6 573.6,25.7 465.2,73.6 465.3,73.9 573.5,26.1 580.5,185 580.8,185 				"/>
				<polygon class="st134" points="646.1,460.6 645.7,460.4 441,358.5 440.9,358.8 645.2,460.4 519.6,489 519.7,489.3 				"/>
				
					<radialGradient id="SVGID_18_" cx="988.3964" cy="380.3454" r="2.6995" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st135" d="M609,520.1c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C605.5,520.7,607.5,521,609,520.1z"/>
				
					<radialGradient id="SVGID_19_" cx="965.9163" cy="435.9086" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st136" d="M647.6,463c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7s-1.8,2.9-0.7,4.2
					C644.1,463.6,646.1,464,647.6,463z"/>
				
					<radialGradient id="SVGID_20_" cx="925.5032" cy="336.0345" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st137" d="M521.5,491.6c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C518,492.2,520.1,492.6,521.5,491.6z"/>
				
					<radialGradient id="SVGID_21_" cx="793.3416" cy="350.6327" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st138" d="M443.1,361c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C439.6,361.6,441.6,361.9,443.1,361z"/>
				
					<radialGradient id="SVGID_22_" cx="836.8268" cy="431.5151" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st139" d="M552.7,347.6c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7s-1.8,2.9-0.7,4.2
					C549.2,348.3,551.2,348.6,552.7,347.6z"/>
				
					<radialGradient id="SVGID_23_" cx="848.7319" cy="455.3853" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st140" d="M584.4,342.9c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C580.9,343.5,582.9,343.8,584.4,342.9z"/>
				
					<radialGradient id="SVGID_24_" cx="789.6514" cy="481.7618" r="2.6995" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st141" d="M568.7,271.4c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C565.2,272.1,567.3,272.4,568.7,271.4z"/>
				
					<radialGradient id="SVGID_25_" cx="801.3103" cy="562.6461" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st142" d="M656,229c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C652.5,229.6,654.6,229.9,656,229z"/>
				
					<radialGradient id="SVGID_26_" cx="735.8466" cy="534.5136" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st143" d="M582.6,187.5c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C579.1,188.2,581.1,188.5,582.6,187.5z"/>
				
					<radialGradient id="SVGID_27_" cx="724.7669" cy="423.7371" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st144" d="M466.5,250.1c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C463,250.8,465,251.1,466.5,250.1z"/>
				
					<radialGradient id="SVGID_28_" cx="634.3873" cy="433.2152" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st145" d="M412.3,161.1c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C408.8,161.8,410.9,162.1,412.3,161.1z"/>
				
					<radialGradient id="SVGID_29_" cx="554.3826" cy="465.2376" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st146" d="M387.5,66.8c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C384,67.5,386,67.8,387.5,66.8z"/>
				
					<radialGradient id="SVGID_30_" cx="486.8609" cy="428.665" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st147" d="M304.3,29c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C300.9,29.7,302.9,30,304.3,29z"/>
				
					<radialGradient id="SVGID_31_" cx="452.6018" cy="456.4891" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st148" d="M307.5-20.6c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C304-20,306.1-19.7,307.5-20.6z"/>
				
					<radialGradient id="SVGID_32_" cx="577.4713" cy="570.9146" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st149" d="M507,18.5c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C503.6,19.2,505.6,19.5,507,18.5z"/>
				
					<radialGradient id="SVGID_33_" cx="559.1402" cy="562.6506" r="2.6995" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st150" d="M486.1,7.2c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C482.6,7.8,484.6,8.1,486.1,7.2z"/>
				
					<radialGradient id="SVGID_34_" cx="599.6875" cy="514.1191" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st151" d="M467.1,76.2c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C463.6,76.9,465.6,77.2,467.1,76.2z"/>
				
					<radialGradient id="SVGID_35_" cx="737.9576" cy="588.803" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st152" d="M637.1,153.8c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C633.7,154.4,635.7,154.7,637.1,153.8z"/>
				
					<radialGradient id="SVGID_36_" cx="617.6817" cy="363.9549" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st153" d="M332.9,191.3c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C329.4,192,331.4,192.3,332.9,191.3z"/>
				
					<radialGradient id="SVGID_37_" cx="696.4207" cy="375.4864" r="2.6996" gradientTransform="matrix(0.7016 0.9158 0.978 -0.657 -458.3962 -137.6978)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st154" d="M399.4,255.9c1.5-1,1.8-2.9,0.7-4.2c-1-1.4-3.1-1.7-4.5-0.7c-1.5,1-1.8,2.9-0.7,4.2
					C395.9,256.5,397.9,256.8,399.4,255.9z"/>
			</g>
			<g class="st155">
				<path class="st134" d="M348.3,956.7l-60,59.7l-0.1,97.4l-0.3-0.3l-70.7-69.6l-0.3,0.2l0.1-0.3l-0.1-0.1l0.1-0.1l34.5-91l0.1,0
					l96.5,3.7l0,0.1L348.3,956.7z M217.5,1043.4l130.2-86.7l-95.9-3.7L217.5,1043.4z M288,1016.2l59-58.7l-128.6,85.7L288,1016.2z
					 M217.5,1043.9l70.4,69.2l0.1-96.6L217.5,1043.9z"/>
				<path class="st134" d="M348.4,956.6L348.4,956.6l-60.2,156.9l-0.3-0.1L348,956.6l-93.4-93.3l0.4,0l81.4,1.7l0,0.1L348.4,956.6
					L348.4,956.6z M255.3,863.7l92.6,92.5l-11.8-90.8L255.3,863.7z"/>
				<path class="st134" d="M251.9,952.9l-0.3,0l-10.6-69.5l0,0l14.2-20.3l0,0.5L251.9,952.9z M241.2,883.4l10.4,67.9l3.2-87.3
					L241.2,883.4z"/>
				<path class="st134" d="M457.7,978.7l-110-22l0.4-0.2l67.3-33.5l0.1,0.1L457.7,978.7z M348.6,956.6l108.4,21.7l-41.7-54.9
					L348.6,956.6z"/>
				<path class="st134" d="M477.8,788.2L477.8,788.2L415.1,924l0.1-0.9l8-273.2l0.3,0l11.8,68.2L477.8,788.2z M415.6,922.3
					l61.9-134.1l-42.2-69.6L415.6,922.3z M423.5,651.4l-7.8,267.2L435,718.2L423.5,651.4z"/>
				<path class="st134" d="M435.5,718.4l-0.4-0.1l-144.3-39.9l0.6-0.1l102.1-22l-79.4-118.2l0.2-0.2L423.7,650l-0.3,0.1l-29.5,6.4
					L435.5,718.4z M292,678.4l142.8,39.5l-41.2-61.4L292,678.4z M393.7,656.2l29.3-6.3L315.2,539.2L393.7,656.2z"/>
				<path class="st134" d="M471.6,962.1l-0.2,0.1l-156.5,93.6L458,978l-0.5,0.6l-118.1,144.4l-0.2-0.2l117.6-143.8l-148,80.5
					l-0.2-0.2l162.4-97.1L416.3,924l0.1,0.1l-1.2-0.9l-79.1-57.9l-29.4-51.7l-52.2,50.6l0.3-0.7l35.7-78.9l0.1,0.2l21.2,23.4
					l40.2-39l0,0l0,0L336.9,722l0.2,0l98.7-4l-0.5,0.3l-82.9,51.1l63.1,153.7L471.6,962.1z M312,808.5l102.9,114l-62.7-152.9
					L312,808.5z M336.4,865.1l77.9,57.1L311.8,808.7l-4.9,4.7L336.4,865.1z M352.3,769.1l82.3-50.7l-97.4,3.9L352.3,769.1z
					 M306.8,813.1l4.8-4.7L291.3,786L306.8,813.1z M255.4,862.9l51.1-49.5l-16-28.1L255.4,862.9z"/>
				<polygon class="st134" points="471.4,962.3 348.3,956.8 339.4,1123.4 339.2,1122.9 288,1016.4 288.3,1016.3 339.2,1122.3 
					348,956.5 348.2,956.5 471.4,962 				"/>
				<polygon class="st134" points="538.3,800.8 538,800.9 423.4,650.2 352.5,769.2 478.1,788.1 477.7,788.3 336.4,865.3 
					336.2,865.1 477.2,788.3 352,769.4 352.1,769.2 423.4,649.7 423.5,649.9 				"/>
				<path class="st134" d="M538.4,800.8l-0.2,0.2L414.7,924l76.9-132.8l-14.2-3l0.1-0.2l50.4-59.6l-92.7-10.2l0-0.3l93.2,10.2
					l-0.1,0.2l-36.3,62.6L538.4,800.8z M416,922.3l121.8-121.3l-45.9-9.6L416,922.3z M491.7,791l35.5-61.3l-49.3,58.4L491.7,791z"/>
				<path class="st134" d="M477.8,788.1l-0.3,0.1L423.2,650l0,0L435.9,525l-42.4,131.5l-0.1-0.8l-12.1-170.8l0.3,0.2l54.7,38.4
					l-0.1,0.1l0.1,0l-12.8,126.3L477.8,788.1z M393.7,654.9L436,523.6l-54.4-38.2L393.7,654.9z"/>
				<polygon class="st134" points="453.5,1114.5 453.3,1114.1 348.1,956.7 348.3,956.5 453.3,1113.6 471.2,962.1 471.2,962.1 
					538,800.8 538.3,800.9 471.5,962.1 				"/>
				<polygon class="st134" points="528.9,931.6 528.6,931.6 415.4,923.3 415.4,923 528.4,931.3 477.5,788.2 477.8,788.1 				"/>
				<polygon class="st134" points="436.5,523.4 436.3,523.7 291.5,678.5 291.3,678.3 435.8,523.8 314.2,538.1 314.2,537.8 				"/>
				
					<radialGradient id="SVGID_38_" cx="1230.265" cy="48.8731" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st156" d="M382.2,482.4c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C378.9,482.9,380.6,482,382.2,482.4z"/>
				
					<radialGradient id="SVGID_39_" cx="1208.9548" cy="101.5393" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st157" d="M436.9,520.8c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C433.6,521.3,435.3,520.4,436.9,520.8z"/>
				
					<radialGradient id="SVGID_40_" cx="1170.6516" cy="6.8698" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st158" d="M315,535.2c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C311.7,535.7,313.3,534.8,315,535.2z"/>
				
					<radialGradient id="SVGID_41_" cx="1045.3784" cy="20.7027" r="2.5588" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st159" d="M292.4,675.7c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C289.1,676.2,290.8,675.3,292.4,675.7z"/>
				
					<radialGradient id="SVGID_42_" cx="1086.5942" cy="97.3705" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st160" d="M394.4,652.9c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C391,653.4,392.7,652.5,394.4,652.9z"/>
				
					<radialGradient id="SVGID_43_" cx="1097.8779" cy="119.9968" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st161" d="M424.2,647.1c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C420.8,647.7,422.5,646.7,424.2,647.1z"/>
				
					<radialGradient id="SVGID_44_" cx="1041.8762" cy="144.9965" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st162" d="M436,715.4c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C432.7,715.9,434.3,715,436,715.4z"/>
				
					<radialGradient id="SVGID_45_" cx="1052.9247" cy="221.6651" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st163" d="M528.6,725.5c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C525.3,726,527,725.1,528.6,725.5z"/>
				
					<radialGradient id="SVGID_46_" cx="990.8742" cy="194.9968" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st164" d="M478.4,785.4c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C475.1,785.9,476.8,785,478.4,785.4z"/>
				
					<radialGradient id="SVGID_47_" cx="980.3758" cy="89.9942" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st165" d="M353,766.5c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C349.7,767,351.4,766.1,353,766.5z"/>
				
					<radialGradient id="SVGID_48_" cx="894.707" cy="98.9751" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st166" d="M337.1,862.4c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C333.7,862.9,335.4,862,337.1,862.4z"/>
				
					<radialGradient id="SVGID_49_" cx="818.8715" cy="129.3256" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st167" d="M349,953.8c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C345.6,954.3,347.3,953.4,349,953.8z"/>
				
					<radialGradient id="SVGID_50_" cx="754.8705" cy="94.6571" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st168" d="M288.9,1013.5c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C285.6,1014,287.3,1013.1,288.9,1013.5z"/>
				
					<radialGradient id="SVGID_51_" cx="722.3963" cy="121.0298" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st169" d="M309.6,1056.5c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C306.2,1057,307.9,1056.1,309.6,1056.5z"/>
				
					<radialGradient id="SVGID_52_" cx="840.7531" cy="229.495" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st170" d="M472.3,958.9c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C468.9,959.4,470.6,958.5,472.3,958.9z"/>
				
					<radialGradient id="SVGID_53_" cx="823.3777" cy="221.6612" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st171" d="M457.8,975.6c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C454.5,976.1,456.2,975.2,457.8,975.6z"/>
				
					<radialGradient id="SVGID_54_" cx="861.8132" cy="175.6608" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st172" d="M416.1,920.4c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C412.8,920.9,414.5,920,416.1,920.4z"/>
				
					<radialGradient id="SVGID_55_" cx="992.8735" cy="246.4564" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st173" d="M538.9,798.1c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C535.6,798.6,537.3,797.7,538.9,798.1z"/>
				
					<radialGradient id="SVGID_56_" cx="878.8745" cy="33.3245" r="2.5588" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st174" d="M255.8,860.7c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2c-1.6-0.4-2.6-2-2.2-3.5
					C252.5,861.2,254.1,860.3,255.8,860.7z"/>
				
					<radialGradient id="SVGID_57_" cx="953.5087" cy="44.2575" r="2.5589" gradientTransform="matrix(0.3084 -1.0895 1.1635 0.2888 -54.818 1811.4276)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<path class="st175" d="M291.5,782.6c1.6,0.4,2.6,2,2.2,3.5c-0.4,1.5-2.1,2.5-3.8,2s-2.6-2-2.2-3.5
					C288.2,783.1,289.9,782.2,291.5,782.6z"/>
			</g>
			
				<radialGradient id="SVGID_58_" cx="455.9118" cy="170.7369" r="3.2726" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="0" style="stop-color:#FEE11B"/>
				<stop  offset="0.7833" style="stop-color:#FEE11B;stop-opacity:0"/>
			</radialGradient>
			
				<radialGradient id="SVGID_59_" cx="450.1011" cy="142.7016" r="6.5446" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.3005" style="stop-color:#F39220"/>
				<stop  offset="0.422" style="stop-color:#F39720;stop-opacity:0.8043"/>
				<stop  offset="0.6116" style="stop-color:#F3A71F;stop-opacity:0.4987"/>
				<stop  offset="0.8446" style="stop-color:#F4C01B;stop-opacity:0.1235"/>
				<stop  offset="0.9212" style="stop-color:#F5C919;stop-opacity:0"/>
			</radialGradient>
			
				<radialGradient id="SVGID_60_" cx="435.1732" cy="219.8706" r="1.3159" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="0.3005" style="stop-color:#F39220"/>
				<stop  offset="0.422" style="stop-color:#F39720;stop-opacity:0.8043"/>
				<stop  offset="0.6116" style="stop-color:#F3A71F;stop-opacity:0.4987"/>
				<stop  offset="0.8446" style="stop-color:#F4C01B;stop-opacity:0.1235"/>
				<stop  offset="0.9212" style="stop-color:#F5C919;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st178" cx="247.5" cy="655.5" rx="1.6" ry="1.5"/>
			
				<radialGradient id="SVGID_61_" cx="611.2628" cy="58.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
				<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
				<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
				<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
				<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
				<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st179" cx="53.7" cy="457" rx="6" ry="5.6"/>
			
				<radialGradient id="SVGID_62_" cx="459.1844" cy="389.757" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.2562" style="stop-color:#67CBE4"/>
				<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
				<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
				<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
				<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
				<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
				<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st180" cx="452" cy="628.4" rx="6" ry="5.6"/>
			
				<radialGradient id="SVGID_63_" cx="472.7158" cy="355.6267" r="19.9358" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st181" cx="410.9" cy="613.2" rx="24" ry="22.5"/>
			
				<radialGradient id="SVGID_64_" cx="480.0442" cy="278.2998" r="15.9137" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st182" cx="317.8" cy="604.9" rx="19.2" ry="17.9"/>
			
				<radialGradient id="SVGID_65_" cx="369.672" cy="373.6965" r="15.9137" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st183" cx="432.6" cy="729.3" rx="19.2" ry="17.9"/>
			
				<radialGradient id="SVGID_66_" cx="534.4189" cy="360.9818" r="11.3506" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st184" cx="417.3" cy="543.6" rx="13.7" ry="12.8"/>
			
				<radialGradient id="SVGID_67_" cx="425.9301" cy="329.9156" r="7.8783" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st185" cx="379.9" cy="665.9" rx="9.5" ry="8.9"/>
			<g class="st186">
				<path class="st134" d="M616.2,950.1L305.9,738.4l166.4-10.5l90.2-70.1l-63.2-24.3l-0.1-0.2l-44.8-84.8l0.1-0.3l104.4-242.4
					l0.3-0.1l54.1-17.2l0.4,1.2l-53.8,17.1L455.7,548.5l44.5,84.1l64.3,24.8l-44.1,111.4l-10.4,39.1L616.2,950.1z M309.6,739.4
					l301,205.3L508.7,808l0.1-0.3l10.5-39.4l43-108.7l-89.4,69.5l-0.2,0L309.6,739.4z"/>
				<polygon class="st134" points="563.8,657.9 307.9,739.2 307.6,738.6 563.6,657.4 				"/>
				<polygon class="st134" points="614,947.1 612.9,947.7 472,728.8 390.4,579.9 499.4,632.2 595.3,382.3 480.6,357.5 559,305.8 
					559.7,306.8 483.8,356.9 597,381.4 596.8,382.1 500.1,633.9 393.4,582.7 473.1,728.2 				"/>
				<polygon class="st134" points="510,808.4 307.6,739.2 307.9,738.6 508.8,807.3 472.2,728.6 472.9,728.4 				"/>
				<path class="st134" d="M604.9,605.5l-0.3,0.4l-40.8,52.2l-0.3-0.3L499.9,594l-108.1-12.4l0.1-0.6l107.3,12.3l-44.7-44.9l0.2-0.2
					l141-166.4l-98.9-102.7l62.7,26.9l-0.3,0.6L499.4,281l97.2,100.9l-0.2,0.2l-141,166.4l44.8,45L604.9,605.5z M563.7,657.2
					l40-51.2L501,594.2L563.7,657.2z"/>
				<polygon class="st134" points="613.8,288.8 613.8,289.2 596.4,382 596.3,382.1 433.2,478.3 604.5,605.5 604.1,606 432.1,478.3 
					432.5,478 595.8,381.7 613,289.4 498.1,280.4 498.1,279.8 				"/>
				
					<radialGradient id="SVGID_68_" cx="361.153" cy="269.9609" r="10.5632" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st187" cx="307.8" cy="738.9" rx="12.7" ry="11.9"/>
				
					<radialGradient id="SVGID_69_" cx="500.9723" cy="339.8706" r="11.4809" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st188" cx="391.9" cy="581.3" rx="13.8" ry="12.9"/>
				
					<radialGradient id="SVGID_70_" cx="370.3734" cy="406.9648" r="9.7236" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st189" cx="472.7" cy="728.5" rx="11.7" ry="11"/>
				
					<radialGradient id="SVGID_71_" cx="299.982" cy="434.7817" r="8.4091" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st190" cx="506.2" cy="807.9" rx="10.1" ry="9.5"/>
				
					<radialGradient id="SVGID_72_" cx="433.9482" cy="482.6028" r="9.0392" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st191" cx="563.7" cy="656.9" rx="10.9" ry="10.2"/>
				
					<radialGradient id="SVGID_73_" cx="455.153" cy="428.0769" r="8.5154" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st192" cx="498.1" cy="633" rx="10.3" ry="9.6"/>
				
					<radialGradient id="SVGID_74_" cx="530.1013" cy="392.3028" r="8.9076" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st193" cx="455" cy="548.5" rx="10.7" ry="10"/>
				
					<radialGradient id="SVGID_75_" cx="744.9278" cy="478.9616" r="7.6266" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st194" cx="559.3" cy="306.3" rx="9.2" ry="8.6"/>
				
					<radialGradient id="SVGID_76_" cx="760.2205" cy="523.9075" r="7.1929" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st195" cx="613.4" cy="289.1" rx="8.7" ry="8.1"/>
				
					<radialGradient id="SVGID_77_" cx="677.6442" cy="506.8627" r="11.5735" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st196" cx="592.9" cy="382.2" rx="13.9" ry="13"/>
				
					<radialGradient id="SVGID_78_" cx="700.4787" cy="414.0906" r="7.5875" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st197" cx="481.2" cy="356.4" rx="9.1" ry="8.6"/>
			</g>
			<g>
				<polygon class="st134" points="186.8,57.8 186.6,57.9 138.5,111.4 53.8,457.4 53.8,457.4 -6.4,523.9 -6.6,523.7 53.6,457.3 
					138.2,111.2 138.3,111.2 186.2,58 -6.5,35.7 -6.5,35.4 				"/>
				<polygon class="st134" points="479.9,0.1 143.7,173.9 324.5,122.4 324.6,122.7 141.1,174.9 479.8,-0.1 				"/>
				<polygon class="st198" points="324.8,122.6 324.6,122.7 -6.4,292.5 -6.5,292.3 324.3,122.5 245.8,1.5 186.6,58 186.4,57.8 
					245.9,1 246,1.2 				"/>
				<path class="st199" d="M595.8,404.5l-0.4,0l-114.5,5.7l-28.8,218.5l-0.2-0.2L225.8,422.8l-0.7,0l0.7-0.3l243.5-108.4
					L253.1,159.3l0.2-0.2L469.5,314l25.5-11.3l0,0.3l-3.5,26.8L595.8,404.5z M480.9,409.9l114.1-5.7l-103.5-74.2L480.9,409.9z
					 M491.2,329.5l3.5-26.4l-24.9,11.1L491.2,329.5z M480.6,409.9l10.5-80l-21.6-15.5L226.6,422.5L480.6,409.9z M451.8,628.1
					l28.7-217.9l-254.4,12.6L451.8,628.1z"/>
				<polygon class="st134" points="452.4,629.8 253,159.3 253.3,159.2 451.5,627.1 411.5,415.1 411.8,415.1 				"/>
				<polygon class="st199" points="573.4,78.7 138.4,111.4 138.4,111.2 571.6,78.5 245.8,1.4 245.9,1.1 				"/>
				<path class="st134" d="M572.7,78.4l-0.1,0.3l-77.7,224.5l-0.1,0l-315-101.6l0.4-0.1L572.7,78.4z M494.7,302.8l77.5-223.9
					L180.8,201.4L494.7,302.8z"/>
				<path class="st134" d="M142.6,174.4l-0.2,0.1l-120,95.1l31.4,187.8l-60.3,11.3l-0.1-0.3l60-11.2L22.2,269.8l-28.6,22.7l-0.2-0.2
					l28.8-22.8L-6.7,96.8l0.3,0.1L142.6,174.4z M22.5,269.3l119.7-94.8L-6.3,97.3L22.5,269.3z"/>
				
					<radialGradient id="SVGID_79_" cx="917.9589" cy="128.2685" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st200" cx="137.2" cy="111.3" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_80_" cx="861.9589" cy="133.2145" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st201" cx="143.1" cy="174.4" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_81_" cx="838.9589" cy="164.0782" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st202" cx="180.3" cy="200.3" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_82_" cx="719.9589" cy="318.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st203" cx="366.7" cy="334.5" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_83_" cx="908.2629" cy="283.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st204" cx="324.5" cy="122.2" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_84_" cx="747.9468" cy="424.4995" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st205" cx="493.8" cy="302.9" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_85_" cx="648.4261" cy="355.4116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st206" cx="410.6" cy="415.1" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_86_" cx="641.7061" cy="201.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st207" cx="225.8" cy="422.7" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_87_" cx="815.4023" cy="201.9116" r="32.7646" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st208" cx="225.8" cy="226.9" rx="39.4" ry="36.9"/>
				
					<radialGradient id="SVGID_88_" cx="633.5177" cy="508.9116" r="27.6664" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st209" cx="595.4" cy="431.9" rx="33.3" ry="31.2"/>
				
					<radialGradient id="SVGID_89_" cx="702.8209" cy="330.9624" r="15.8665" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st210" cx="381.2" cy="353.8" rx="19.1" ry="17.9"/>
				
					<radialGradient id="SVGID_90_" cx="611.2628" cy="63.8576" r="23.1354" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st211" cx="59.7" cy="457" rx="27.8" ry="26.1"/>
				
					<radialGradient id="SVGID_91_" cx="758.2629" cy="422.4044" r="23.1354" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st212" cx="491.3" cy="291.3" rx="27.8" ry="26.1"/>
				
					<radialGradient id="SVGID_92_" cx="959.4579" cy="114.814" r="58.2242" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
					<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
					<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
					<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
					<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
					<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st213" cx="121" cy="64.5" rx="70.1" ry="65.6"/>
				
					<radialGradient id="SVGID_93_" cx="965.3747" cy="169.2449" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st214" cx="186.5" cy="57.9" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_94_" cx="959.0414" cy="109.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st215" cx="115.1" cy="65" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_95_" cx="875.4606" cy="224.5949" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st216" cx="253.1" cy="159.2" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_96_" cx="936.4096" cy="267.4424" r="3.636" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st217" cx="304.7" cy="90.5" rx="4.4" ry="4.1"/>
				
					<radialGradient id="SVGID_97_" cx="946.9589" cy="489.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st218" cx="572.5" cy="78.6" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_98_" cx="930.6256" cy="8.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st219" cx="-6.5" cy="97" rx="6" ry="5.6"/>
				
					<radialGradient id="SVGID_99_" cx="758.2629" cy="8.9116" r="4.9461" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
					<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
					<stop  offset="0.2562" style="stop-color:#67CBE4"/>
					<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
					<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
					<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
					<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
					<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
					<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
					<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
				</radialGradient>
				<ellipse class="st220" cx="-6.5" cy="291.3" rx="6" ry="5.6"/>
				<g class="st221">
					<path class="st134" d="M623.4,268.8l-0.4,0.2l-118.4,68.2l0.1-0.3l13-70.3l-109.5-2.5l-0.2,0.1l0-0.2l-22.6-161l0,0l27.4-67.8
						l53.7-117.1l0.1,0.4l21.6,73.9l-0.3,0.1L466.4-81L413,35.3l-27.3,67.8l22.5,160.8l58.4-27.2l83.3-142.5l-0.2,0.8L518,266.4
						L623.4,268.8z M505,336.7l117.4-67.7L518,266.7L505,336.7z M517.7,266.4l31.6-170.6l-82.5,141.1l0,0L408.7,264L517.7,266.4z"/>
					<path class="st134" d="M550.3,95.1l-0.7,0l-163.9,8.1l81.1,133.5L504.9,337l-0.3,0.1l-38.1-100.2l-81.1-133.6l-97.5,15l0.6-0.4
						l122.3-77.5l0.1,0L550.3,95.1z M385.6,103l163.4-8L410.8,40.7l-121.6,77.1L385.6,103L385.6,103z"/>
					<polygon class="st134" points="549.7,94.9 549.6,95.2 289,46 289.3,45.8 466.3,-81.5 466.5,-81.3 289.7,45.9 					"/>
				</g>
				<g class="st222">
					<polygon class="st198" points="154.9,1.3 138.8,111.8 -6.5,97.4 -6.4,96.6 138,110.8 154,1.2 					"/>
					<path class="st198" d="M367.7,335.5L141.9,174.6l0-0.2l-3.9-62L-6.1,292.7l-0.8-0.4L114.6,64.7l-36-63.3l0.8-0.4l36.2,63.6
						L-3.8,288.4l142.6-178.3l4,62.2l43.6-115.1l0.5,0.2l138.2,64.8l0,0.2L367.7,335.5z M142.9,174.3l223.5,159.2l-42.3-210.6
						L186.8,58.5L142.9,174.3z"/>
					<polygon class="st198" points="609.4,275.1 366.9,335 366.7,334.7 282,203.9 186.3,58.3 115.1,65.1 115.1,64.3 186.8,57.4 
						186.9,57.6 282.8,203.5 367.3,334 609.2,274.3 					"/>
					<polygon class="st198" points="573.5,78.9 572.6,79 325.4,122.9 495.4,302.9 495.2,303.2 411.6,415.9 366.7,334.7 367.5,334.3 
						411.7,414.3 494.3,303 323.7,122.3 324.5,122.2 571.5,78.4 479.5,0.3 480.1,-0.3 					"/>
					<polygon class="st198" points="615.3,544 411.4,415.5 411.9,414.8 613.1,541.6 494.4,303.1 495.3,302.8 					"/>
				</g>
			</g>
			
				<radialGradient id="SVGID_100_" cx="956.9218" cy="402.9294" r="19.3013" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st223" cx="467.8" cy="67.4" rx="23.2" ry="21.8"/>
			
				<radialGradient id="SVGID_101_" cx="818.4719" cy="489.9116" r="67.6566" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
				<stop  offset="0.1654" style="stop-color:#66C8E2;stop-opacity:0.8038"/>
				<stop  offset="0.3055" style="stop-color:#62BFDD;stop-opacity:0.6325"/>
				<stop  offset="0.438" style="stop-color:#5AB3D7;stop-opacity:0.4704"/>
				<stop  offset="0.5658" style="stop-color:#4FA2CE;stop-opacity:0.3141"/>
				<stop  offset="0.6904" style="stop-color:#428CC2;stop-opacity:0.1618"/>
				<stop  offset="0.8108" style="stop-color:#3375B5;stop-opacity:1.446849e-02"/>
				<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st224" cx="572.5" cy="223.4" rx="81.4" ry="76.3"/>
			<g>
				<g>
					
						<radialGradient id="SVGID_102_" cx="882.8135" cy="378.3934" r="10.5104" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
						<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
						<stop  offset="0.2562" style="stop-color:#67CBE4"/>
						<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
						<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
						<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
						<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
						<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
						<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
						<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
					</radialGradient>
					<ellipse class="st225" cx="438.3" cy="150.9" rx="12.7" ry="11.8"/>
				</g>
				<g>
					
						<radialGradient id="SVGID_103_" cx="646.6522" cy="355.2415" r="10.5104" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
						<stop  offset="4.926110e-03" style="stop-color:#67CBE4"/>
						<stop  offset="0.2562" style="stop-color:#67CBE4"/>
						<stop  offset="0.3935" style="stop-color:#67C8E2;stop-opacity:0.7575"/>
						<stop  offset="0.4895" style="stop-color:#63C1DE;stop-opacity:0.5881"/>
						<stop  offset="0.5729" style="stop-color:#5CB6D9;stop-opacity:0.4409"/>
						<stop  offset="0.6491" style="stop-color:#52A7D1;stop-opacity:0.3064"/>
						<stop  offset="0.7204" style="stop-color:#4794C7;stop-opacity:0.1806"/>
						<stop  offset="0.7871" style="stop-color:#3A7FBB;stop-opacity:6.275451e-02"/>
						<stop  offset="0.8227" style="stop-color:#3173B4;stop-opacity:0"/>
					</radialGradient>
					<ellipse class="st226" cx="410.4" cy="417.1" rx="12.7" ry="11.8"/>
				</g>
			</g>
			<g class="st227">
				<g>
					<g>
						
							<radialGradient id="SVGID_104_" cx="652.5434" cy="9.3338" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st228" cx="-6" cy="410.5" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_105_" cx="654.048" cy="40.8514" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st229" cx="32" cy="408.8" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_106_" cx="790.3351" cy="95.6299" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st230" cx="97.9" cy="255.2" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_107_" cx="888.2591" cy="226.3045" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st231" cx="255.2" cy="144.8" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_108_" cx="850.7757" cy="283.5127" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st232" cx="324.1" cy="187" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_109_" cx="329.9464" cy="171.4075" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st233" cx="189.1" cy="774.1" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_110_" cx="231.4969" cy="60.5932" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st234" cx="55.7" cy="885.1" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_111_" cx="190.8336" cy="6.0846" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st235" cx="-9.9" cy="930.9" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_112_" cx="334.1296" cy="31.3661" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st236" cx="20.6" cy="769.4" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_113_" cx="367.2396" cy="177.4002" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st237" cx="196.3" cy="732" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_114_" cx="366.9503" cy="366.3367" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st238" cx="423.8" cy="732.4" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_115_" cx="355.6277" cy="434.1897" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st239" cx="505.4" cy="745.1" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_116_" cx="431.1764" cy="496.3119" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st240" cx="580.2" cy="660" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_117_" cx="767.1434" cy="438.141" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st241" cx="510.2" cy="281.3" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_118_" cx="859.9554" cy="367.1796" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st242" cx="424.8" cy="176.7" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_119_" cx="941.0428" cy="351.6087" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st243" cx="406" cy="85.3" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_120_" cx="989.5976" cy="53.4143" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st244" cx="47.1" cy="30.6" rx="2.8" ry="2.7"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_121_" cx="976.6165" cy="147.287" r="2.3605" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st245" cx="160.1" cy="45.2" rx="2.8" ry="2.7"/>
					</g>
				</g>
				
				<g>
					<g>
					</g>
				</g>
				
			
				
				<g>
					<g>
						<g>
							<defs>
								<ellipse id="SVGID_132_" cx="545.5" cy="133.7" rx="4.1" ry="3.9"/>
							</defs>
							<clipPath id="SVGID_133_">
								<use xlink:href="#SVGID_132_"  style="overflow:visible;"/>
							</clipPath>
							<rect x="541.3" y="129.8" class="st816" width="8.3" height="7.8"/>
							<ellipse class="st816" cx="545.5" cy="133.7" rx="4.1" ry="3.9"/>
							<ellipse class="st816" cx="545.5" cy="133.7" rx="4.1" ry="3.8"/>
							<ellipse class="st816" cx="545.5" cy="133.7" rx="4" ry="3.8"/>
							<ellipse class="st817" cx="545.5" cy="133.7" rx="4" ry="3.7"/>
							<ellipse class="st817" cx="545.5" cy="133.7" rx="3.9" ry="3.7"/>
							<ellipse class="st818" cx="545.5" cy="133.7" rx="3.9" ry="3.6"/>
							<ellipse class="st818" cx="545.5" cy="133.7" rx="3.8" ry="3.6"/>
							<ellipse class="st819" cx="545.5" cy="133.7" rx="3.8" ry="3.5"/>
							<ellipse class="st819" cx="545.5" cy="133.7" rx="3.7" ry="3.5"/>
							<ellipse class="st820" cx="545.5" cy="133.7" rx="3.7" ry="3.5"/>
							<ellipse class="st821" cx="545.5" cy="133.7" rx="3.6" ry="3.4"/>
							<ellipse class="st821" cx="545.5" cy="133.7" rx="3.6" ry="3.4"/>
							<ellipse class="st822" cx="545.5" cy="133.7" rx="3.5" ry="3.3"/>
							<ellipse class="st823" cx="545.5" cy="133.7" rx="3.5" ry="3.3"/>
							<ellipse class="st824" cx="545.5" cy="133.7" rx="3.4" ry="3.2"/>
							<ellipse class="st824" cx="545.5" cy="133.7" rx="3.4" ry="3.2"/>
							<ellipse class="st825" cx="545.5" cy="133.7" rx="3.3" ry="3.1"/>
							<ellipse class="st826" cx="545.5" cy="133.7" rx="3.3" ry="3.1"/>
							<ellipse class="st827" cx="545.5" cy="133.7" rx="3.2" ry="3"/>
							<ellipse class="st828" cx="545.5" cy="133.7" rx="3.2" ry="3"/>
							<ellipse class="st829" cx="545.5" cy="133.7" rx="3.1" ry="2.9"/>
							<ellipse class="st829" cx="545.5" cy="133.7" rx="3.1" ry="2.9"/>
							<ellipse class="st830" cx="545.5" cy="133.7" rx="3" ry="2.8"/>
							<ellipse class="st831" cx="545.5" cy="133.7" rx="3" ry="2.8"/>
							<ellipse class="st832" cx="545.5" cy="133.7" rx="2.9" ry="2.7"/>
							<ellipse class="st833" cx="545.5" cy="133.7" rx="2.9" ry="2.7"/>
							<ellipse class="st834" cx="545.5" cy="133.7" rx="2.8" ry="2.6"/>
							<ellipse class="st835" cx="545.5" cy="133.7" rx="2.8" ry="2.6"/>
							<ellipse class="st836" cx="545.5" cy="133.7" rx="2.7" ry="2.5"/>
							<ellipse class="st836" cx="545.5" cy="133.7" rx="2.7" ry="2.5"/>
							<ellipse class="st837" cx="545.5" cy="133.7" rx="2.6" ry="2.4"/>
							<ellipse class="st838" cx="545.5" cy="133.7" rx="2.6" ry="2.4"/>
							<ellipse class="st839" cx="545.5" cy="133.7" rx="2.5" ry="2.3"/>
							<ellipse class="st840" cx="545.5" cy="133.7" rx="2.5" ry="2.3"/>
							<ellipse class="st841" cx="545.5" cy="133.7" rx="2.4" ry="2.2"/>
							<ellipse class="st842" cx="545.5" cy="133.7" rx="2.3" ry="2.2"/>
							<ellipse class="st843" cx="545.5" cy="133.7" rx="2.3" ry="2.2"/>
							<ellipse class="st844" cx="545.5" cy="133.7" rx="2.2" ry="2.1"/>
							<ellipse class="st845" cx="545.5" cy="133.7" rx="2.2" ry="2.1"/>
							<ellipse class="st846" cx="545.5" cy="133.7" rx="2.1" ry="2"/>
							<ellipse class="st847" cx="545.5" cy="133.7" rx="2.1" ry="2"/>
							<ellipse class="st848" cx="545.5" cy="133.7" rx="2" ry="1.9"/>
							<ellipse class="st849" cx="545.5" cy="133.7" rx="2" ry="1.9"/>
							<ellipse class="st850" cx="545.5" cy="133.7" rx="1.9" ry="1.8"/>
							<ellipse class="st851" cx="545.5" cy="133.7" rx="1.9" ry="1.8"/>
							<ellipse class="st852" cx="545.5" cy="133.7" rx="1.8" ry="1.7"/>
							<ellipse class="st853" cx="545.5" cy="133.7" rx="1.8" ry="1.7"/>
							<ellipse class="st854" cx="545.5" cy="133.7" rx="1.7" ry="1.6"/>
							<ellipse class="st855" cx="545.5" cy="133.7" rx="1.7" ry="1.6"/>
							<ellipse class="st856" cx="545.5" cy="133.7" rx="1.6" ry="1.5"/>
							<ellipse class="st857" cx="545.5" cy="133.7" rx="1.6" ry="1.5"/>
							<ellipse class="st858" cx="545.5" cy="133.7" rx="1.5" ry="1.4"/>
							<ellipse class="st859" cx="545.5" cy="133.7" rx="1.5" ry="1.4"/>
							<ellipse class="st860" cx="545.5" cy="133.7" rx="1.4" ry="1.3"/>
							<ellipse class="st861" cx="545.5" cy="133.7" rx="1.4" ry="1.3"/>
							<ellipse class="st862" cx="545.5" cy="133.7" rx="1.3" ry="1.2"/>
							<ellipse class="st863" cx="545.5" cy="133.7" rx="1.3" ry="1.2"/>
							<ellipse class="st864" cx="545.5" cy="133.7" rx="1.2" ry="1.1"/>
							<ellipse class="st865" cx="545.5" cy="133.7" rx="1.2" ry="1.1"/>
							<ellipse class="st866" cx="545.5" cy="133.7" rx="1.1" ry="1"/>
							<ellipse class="st866" cx="545.5" cy="133.7" rx="1.1" ry="1"/>
							<ellipse class="st867" cx="545.5" cy="133.7" rx="1" ry="1"/>
							<ellipse class="st868" cx="545.5" cy="133.7" rx="1" ry="1"/>
							<ellipse class="st869" cx="545.5" cy="133.7" rx="1" ry="0.9"/>
							<ellipse class="st870" cx="545.5" cy="133.7" rx="1" ry="0.9"/>
							<ellipse class="st871" cx="545.5" cy="133.7" rx="1" ry="0.9"/>
							<ellipse class="st872" cx="545.5" cy="133.7" rx="1" ry="0.9"/>
							<ellipse class="st873" cx="545.5" cy="133.7" rx="1" ry="0.9"/>
							<ellipse class="st874" cx="545.5" cy="133.7" rx="0.9" ry="0.9"/>
							<ellipse class="st875" cx="545.5" cy="133.7" rx="0.9" ry="0.9"/>
							<ellipse class="st876" cx="545.5" cy="133.7" rx="0.9" ry="0.8"/>
							<ellipse class="st877" cx="545.5" cy="133.7" rx="0.9" ry="0.8"/>
							<ellipse class="st878" cx="545.5" cy="133.7" rx="0.9" ry="0.8"/>
							<ellipse class="st879" cx="545.5" cy="133.7" rx="0.9" ry="0.8"/>
							<ellipse class="st880" cx="545.5" cy="133.7" rx="0.8" ry="0.8"/>
							<ellipse class="st881" cx="545.5" cy="133.7" rx="0.8" ry="0.8"/>
							<ellipse class="st882" cx="545.5" cy="133.7" rx="0.8" ry="0.8"/>
							<ellipse class="st883" cx="545.5" cy="133.7" rx="0.8" ry="0.7"/>
							<ellipse class="st884" cx="545.5" cy="133.7" rx="0.8" ry="0.7"/>
							<ellipse class="st885" cx="545.5" cy="133.7" rx="0.8" ry="0.7"/>
							<ellipse class="st886" cx="545.5" cy="133.7" rx="0.7" ry="0.7"/>
							<ellipse class="st887" cx="545.5" cy="133.7" rx="0.7" ry="0.7"/>
							<ellipse class="st888" cx="545.5" cy="133.7" rx="0.7" ry="0.7"/>
							<ellipse class="st889" cx="545.5" cy="133.7" rx="0.7" ry="0.6"/>
							<ellipse class="st890" cx="545.5" cy="133.7" rx="0.7" ry="0.6"/>
							<ellipse class="st891" cx="545.5" cy="133.7" rx="0.7" ry="0.6"/>
							<ellipse class="st892" cx="545.5" cy="133.7" rx="0.6" ry="0.6"/>
							<ellipse class="st893" cx="545.5" cy="133.7" rx="0.6" ry="0.6"/>
							<ellipse class="st894" cx="545.5" cy="133.7" rx="0.6" ry="0.6"/>
							<ellipse class="st895" cx="545.5" cy="133.7" rx="0.6" ry="0.6"/>
							<ellipse class="st896" cx="545.5" cy="133.7" rx="0.6" ry="0.5"/>
							<ellipse class="st897" cx="545.5" cy="133.7" rx="0.6" ry="0.5"/>
							<ellipse class="st898" cx="545.5" cy="133.7" rx="0.5" ry="0.5"/>
							<ellipse class="st899" cx="545.5" cy="133.7" rx="0.5" ry="0.5"/>
							<ellipse class="st900" cx="545.5" cy="133.7" rx="0.5" ry="0.5"/>
							<ellipse class="st901" cx="545.5" cy="133.7" rx="0.5" ry="0.5"/>
							<ellipse class="st902" cx="545.5" cy="133.7" rx="0.5" ry="0.5"/>
							<ellipse class="st903" cx="545.5" cy="133.7" rx="0.5" ry="0.4"/>
							<ellipse class="st904" cx="545.5" cy="133.7" rx="0.5" ry="0.4"/>
							<ellipse class="st905" cx="545.5" cy="133.7" rx="0.4" ry="0.4"/>
							<ellipse class="st906" cx="545.5" cy="133.7" rx="0.4" ry="0.4"/>
							<ellipse class="st907" cx="545.5" cy="133.7" rx="0.4" ry="0.4"/>
							<ellipse class="st908" cx="545.5" cy="133.7" rx="0.4" ry="0.4"/>
							<ellipse class="st908" cx="545.5" cy="133.7" rx="0.4" ry="0.3"/>
							<ellipse class="st909" cx="545.5" cy="133.7" rx="0.4" ry="0.3"/>
							<ellipse class="st910" cx="545.5" cy="133.7" rx="0.3" ry="0.3"/>
							<ellipse class="st911" cx="545.5" cy="133.7" rx="0.3" ry="0.3"/>
							<ellipse class="st912" cx="545.5" cy="133.7" rx="0.3" ry="0.3"/>
							<ellipse class="st913" cx="545.5" cy="133.7" rx="0.3" ry="0.3"/>
							<ellipse class="st914" cx="545.5" cy="133.7" rx="0.3" ry="0.3"/>
							<ellipse class="st915" cx="545.5" cy="133.7" rx="0.3" ry="0.2"/>
							<ellipse class="st916" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st917" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st918" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st919" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st920" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st921" cx="545.5" cy="133.7" rx="0.2" ry="0.2"/>
							<ellipse class="st922" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st923" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st924" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st925" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st926" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st927" cx="545.5" cy="133.7" rx="0.1" ry="0.1"/>
							<ellipse class="st928" cx="545.5" cy="133.7" rx="0.1" ry="0"/>
							<ellipse class="st929" cx="545.5" cy="133.7" rx="0" ry="0"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_144_" cx="877.3558" cy="96.7737" r="8.1298" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st1500" cx="99.3" cy="157.1" rx="9.8" ry="9.2"/>
					</g>
				</g>
				<g>
					<g>
						
							<radialGradient id="SVGID_145_" cx="356.4355" cy="484.135" r="8.1298" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
							<stop  offset="4.926110e-03" style="stop-color:#99DAED"/>
							<stop  offset="0.2562" style="stop-color:#4FAED9"/>
							<stop  offset="0.4457" style="stop-color:#4498CB;stop-opacity:0.7452"/>
							<stop  offset="0.6796" style="stop-color:#3A83BE;stop-opacity:0.4307"/>
							<stop  offset="0.8728" style="stop-color:#3377B6;stop-opacity:0.171"/>
							<stop  offset="1" style="stop-color:#3173B4;stop-opacity:0"/>
						</radialGradient>
						<ellipse class="st1501" cx="565.6" cy="744.2" rx="9.8" ry="9.2"/>
					</g>
				</g>
			</g>
			
				<radialGradient id="SVGID_146_" cx="832.3423" cy="9.3338" r="225.8962" gradientTransform="matrix(0 -1.1272 1.2037 0 -17.2037 1145.9824)" gradientUnits="userSpaceOnUse">
				<stop  offset="4.926110e-03" style="stop-color:#C3E8F1"/>
				<stop  offset="0.4691" style="stop-color:#E6F5F8;stop-opacity:0.4324"/>
				<stop  offset="0.8227" style="stop-color:#FFFFFF;stop-opacity:0"/>
			</radialGradient>
			<ellipse class="st1502" cx="-6" cy="207.8" rx="271.9" ry="254.6"/>
			
		</g>
	</g>
	<g class="st1503">
		<g>
			<g>
				<g>
					<g>
						<line class="st198" x1="986.2" y1="735.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.7,689.8 986.4,735.3 986.1,735.4 983.4,689.8 						"/>
					</g>
					<g>
						<line class="st198" x1="986.2" y1="735.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.7,689.8 986.4,735.3 986.1,735.4 983.4,689.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="983.3" y1="588.1" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="983.5,588.1 986.5,735.3 985.9,735.3 983,588.1 						"/>
					</g>
					<g>
						<line class="st198" x1="983.3" y1="588.1" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.5,588.1 983.8,689.8 983.4,689.8 983,588.1 						"/>
					</g>
					<g>
						<line class="st198" x1="983.3" y1="588.1" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.5,588.1 983.8,689.8 983.4,689.8 983,588.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="875.7" y1="670" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="875.7,669.7 983.6,689.5 983.5,690.2 875.7,670.4 						"/>
					</g>
					<g>
						<line class="st198" x1="875.7" y1="670" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="875.7,669.7 983.6,689.5 983.5,690.2 875.7,670.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="846" y1="708.9" x2="875.7" y2="670"/>
						<polygon class="st198" points="875.6,669.9 875.8,670.2 846.1,709 845.9,708.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="883.6" y1="765.3" x2="846" y2="708.9"/>
						<polygon class="st198" points="846.2,708.7 883.7,765.2 883.5,765.5 845.9,709 						"/>
					</g>
					<g>
						<line class="st198" x1="883.6" y1="765.3" x2="875.7" y2="670"/>
						<polygon class="st198" points="875.9,670 883.8,765.3 883.4,765.4 875.5,670.1 						"/>
					</g>
					<g>
						<line class="st198" x1="883.6" y1="765.3" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="986.2,735 986.3,735.7 883.7,765.7 883.5,765 						"/>
					</g>
					<g>
						<line class="st198" x1="883.6" y1="765.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.4,689.5 983.7,690.1 883.7,765.7 883.5,765 						"/>
					</g>
					<g>
						<line class="st198" x1="883.6" y1="765.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.4,689.5 983.7,690.1 883.7,765.7 883.5,765 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1005.3" y1="757.3" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="986.3,735.2 1005.3,757.2 1005.2,757.5 986.1,735.5 						"/>
					</g>
					<g>
						<line class="st198" x1="1005.3" y1="757.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.8,689.7 1005.4,757.2 1005.1,757.4 983.4,689.9 						"/>
					</g>
					<g>
						<line class="st198" x1="1005.3" y1="757.3" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.8,689.7 1005.4,757.2 1005.1,757.4 983.4,689.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1195.3" y1="684.8" x2="1269.1" y2="721"/>
						<polygon class="st198" points="1195.4,684.6 1269.2,720.7 1269,721.3 1195.2,685.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="1005.3" y2="757.3"/>
						<polygon class="st198" points="1018.1,649.8 1018.6,649.9 1005.5,757.4 1005,757.3 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.3" y2="588.1"/>
						<polygon class="st198" points="983.4,587.9 1018.5,649.7 1018.2,650 983.1,588.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="1018.2,649.8 1018.6,650 986.4,735.5 986,735.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1018.3,649.7 1018.5,650.1 983.7,690 983.5,689.7 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1018.3,649.7 1018.5,650.1 983.7,690 983.5,689.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="1005.3" y2="757.3"/>
						<polygon class="st198" points="1018.1,649.8 1018.6,649.9 1005.5,757.4 1005,757.3 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.3" y2="588.1"/>
						<polygon class="st198" points="983.4,587.9 1018.5,649.7 1018.2,650 983.1,588.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="1018.2,649.8 1018.6,650 986.4,735.5 986,735.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1018.3,649.7 1018.5,650.1 983.7,690 983.5,689.7 						"/>
					</g>
					<g>
						<line class="st198" x1="1018.4" y1="649.9" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1018.3,649.7 1018.5,650.1 983.7,690 983.5,689.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1135.9" y1="679.9" x2="1195.3" y2="684.8"/>
						<polygon class="st198" points="1195.3,684.6 1195.3,685.1 1135.9,680.2 1136,679.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="878.2" y1="623.4" x2="883.6" y2="765.3"/>
						<polygon class="st198" points="878.4,623.4 883.9,765.3 883.3,765.3 877.9,623.4 						"/>
					</g>
					<g>
						<line class="st198" x1="878.2" y1="623.4" x2="846" y2="708.9"/>
						<polygon class="st198" points="878,623.3 878.4,623.5 846.2,709 845.8,708.7 						"/>
					</g>
					<g>
						<line class="st198" x1="878.2" y1="623.4" x2="875.7" y2="670"/>
						<polygon class="st198" points="878,623.4 878.3,623.4 875.8,670.1 875.6,670 						"/>
					</g>
					<g>
						<line class="st198" x1="878.2" y1="623.4" x2="983.3" y2="588.1"/>
						<polygon class="st198" points="983.2,587.7 983.3,588.4 878.2,623.8 878.1,623.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="947.1" y1="840.2" x2="1005.3" y2="757.3"/>
						<polygon class="st198" points="1005.1,757.1 1005.4,757.5 947.3,840.4 946.9,840 						"/>
					</g>
					<g>
						<line class="st198" x1="947.1" y1="840.2" x2="883.6" y2="765.3"/>
						<polygon class="st198" points="883.8,765.1 947.3,840 946.9,840.4 883.4,765.6 						"/>
					</g>
					<g>
						<line class="st198" x1="947.1" y1="840.2" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="986,735.2 986.4,735.5 947.3,840.3 946.9,840.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="528" y1="739.1" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="528.1,739 564.8,788.8 564.6,789.1 527.8,739.3 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="765.1" y1="641.2" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="765,640.9 765.2,641.6 674.9,686.1 674.7,685.4 						"/>
					</g>
					<g>
						<line class="st198" x1="765.1" y1="641.2" x2="878.2" y2="623.4"/>
						<polygon class="st198" points="878.1,623 878.2,623.8 765.1,641.6 765.1,640.9 						"/>
					</g>
					<g>
						<line class="st198" x1="765.1" y1="641.2" x2="846" y2="708.9"/>
						<polygon class="st198" points="765.2,641 846.2,708.6 845.9,709.2 765,641.5 						"/>
					</g>
					<g>
						<line class="st198" x1="765.1" y1="641.2" x2="875.7" y2="670"/>
						<polygon class="st198" points="765.1,640.9 875.8,669.7 875.6,670.4 765,641.6 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="712.6" y1="797.9" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="712.5,797.9 712.8,797.9 709.6,847.4 709.3,847.4 						"/>
					</g>
					<g>
						<line class="st198" x1="712.6" y1="797.9" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="675,685.6 712.8,797.7 712.4,798 674.6,685.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="487.2" y1="690.9" x2="528" y2="739.1"/>
						<polygon class="st198" points="487.4,690.8 528.1,739 527.8,739.3 487.1,691.1 						"/>
					</g>
					<g>
						<line class="st198" x1="487.2" y1="690.9" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="487.4,690.7 564.9,788.7 564.5,789.2 487.1,691.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="748.3" y1="728.2" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="748.2,728 748.5,728.3 712.8,798 712.5,797.7 						"/>
					</g>
					<g>
						<line class="st198" x1="748.3" y1="728.2" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="764.9,641.2 765.3,641.3 748.5,728.2 748.1,728.1 						"/>
					</g>
					<g>
						<line class="st198" x1="748.3" y1="728.2" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="748.1,728 748.6,728.3 709.7,847.5 709.2,847.3 						"/>
					</g>
					<g>
						<line class="st198" x1="748.3" y1="728.2" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="674.9,685.5 748.4,727.9 748.2,728.4 674.7,686 						"/>
					</g>
					<g>
						<line class="st198" x1="748.3" y1="728.2" x2="846" y2="708.9"/>
						<polygon class="st198" points="846,708.5 846.1,709.2 748.4,728.5 748.3,727.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="487.4,690.8 524.9,742.4 524.6,742.8 487.1,691.1 						"/>
					</g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="528" y2="739.1"/>
						<polygon class="st198" points="527.9,739.1 528,739.2 524.8,742.7 524.7,742.5 						"/>
					</g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="524.8,742.4 564.8,788.7 564.6,789.1 524.6,742.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="487.4,690.8 524.9,742.4 524.6,742.8 487.1,691.1 						"/>
					</g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="528" y2="739.1"/>
						<polygon class="st198" points="527.9,739.1 528,739.2 524.8,742.7 524.7,742.5 						"/>
					</g>
					<g>
						<line class="st198" x1="524.7" y1="742.6" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="524.8,742.4 564.8,788.7 564.6,789.1 524.6,742.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="689.8" y1="709.3" x2="748.3" y2="728.2"/>
						<polygon class="st198" points="689.9,709 748.4,727.9 748.3,728.4 689.8,709.5 						"/>
					</g>
					<g>
						<line class="st198" x1="689.8" y1="709.3" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="690,709.2 712.8,797.8 712.4,798 689.6,709.4 						"/>
					</g>
					<g>
						<line class="st198" x1="689.8" y1="709.3" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="765,641 765.2,641.5 690,709.5 689.7,709 						"/>
					</g>
					<g>
						<line class="st198" x1="689.8" y1="709.3" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="690.1,709.2 709.7,847.3 709.2,847.5 689.6,709.3 						"/>
					</g>
					<g>
						<line class="st198" x1="689.8" y1="709.3" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="674.9,685.6 689.9,709.2 689.7,709.4 674.7,685.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="584.1" y1="603" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="584.2,602.7 675,685.4 674.6,686 583.9,603.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="803.3" y1="566.2" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="803.1,566 803.4,566.3 765.3,641.4 764.9,641.1 						"/>
					</g>
					<g>
						<line class="st198" x1="803.3" y1="566.2" x2="878.2" y2="623.4"/>
						<polygon class="st198" points="803.4,565.9 878.3,623.1 878.1,623.7 803.2,566.4 						"/>
					</g>
					<g>
						<line class="st198" x1="803.3" y1="566.2" x2="875.7" y2="670"/>
						<polygon class="st198" points="803.5,565.9 875.9,669.8 875.5,670.3 803.1,566.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="878.2" y2="623.4"/>
						<polygon class="st198" points="967.2,573.4 967.4,574 878.3,623.7 878.1,623.1 						"/>
					</g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="967.4,573.5 1018.5,649.7 1018.2,650.1 967.1,573.9 						"/>
					</g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="967.4,573.5 1018.5,649.7 1018.2,650.1 967.1,573.9 						"/>
					</g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="983.3" y2="588.1"/>
						<polygon class="st198" points="967.3,573.5 983.3,587.9 983.2,588.2 967.2,573.8 						"/>
					</g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="967.5,573.6 983.8,689.8 983.3,689.9 967,573.7 						"/>
					</g>
					<g>
						<line class="st198" x1="967.3" y1="573.7" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="967.5,573.6 983.8,689.8 983.3,689.9 967,573.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="741.2" y1="552.2" x2="803.3" y2="566.2"/>
						<polygon class="st198" points="741.2,552 803.3,565.9 803.2,566.4 741.2,552.5 						"/>
					</g>
					<g>
						<line class="st198" x1="741.2" y1="552.2" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="741.4,552.1 765.3,641.2 764.9,641.3 741,552.3 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="615.5" y1="603.1" x2="584.1" y2="603"/>
						<polygon class="st198" points="615.5,602.9 615.5,603.2 584.1,603.2 584.1,602.9 						"/>
					</g>
					<g>
						<line class="st198" x1="615.5" y1="603.1" x2="689.8" y2="709.3"/>
						<polygon class="st198" points="615.8,602.8 690,709 689.6,709.5 615.3,603.3 						"/>
					</g>
					<g>
						<line class="st198" x1="615.5" y1="603.1" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="615.7,602.8 675,685.5 674.6,686 615.4,603.3 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="615.5" y2="603.1"/>
						<polygon class="st198" points="615.4,602.9 615.7,603.3 562.9,678.8 562.6,678.4 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="584.1" y2="603"/>
						<polygon class="st198" points="583.9,603 584.3,603.1 562.9,678.7 562.6,678.5 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="562.6,678.4 562.9,678.7 524.9,742.8 524.6,742.4 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="562.6,678.4 562.9,678.7 524.9,742.8 524.6,742.4 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="562.7,678.3 562.8,678.9 487.3,691.2 487.2,690.7 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="528" y2="739.1"/>
						<polygon class="st198" points="562.6,678.4 562.9,678.7 528.1,739.3 527.8,739 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="563,678.6 564.9,788.9 564.4,788.9 562.5,678.6 						"/>
					</g>
					<g>
						<line class="st198" x1="562.7" y1="678.6" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="674.8,685.4 674.8,686.1 562.7,678.9 562.8,678.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="562.7" y2="678.6"/>
						<polygon class="st198" points="562.9,678.3 624.9,749.4 624.6,749.9 562.6,678.8 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="615.5" y2="603.1"/>
						<polygon class="st198" points="615.8,603 625,749.6 624.4,749.7 615.3,603.1 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="689.8" y2="709.3"/>
						<polygon class="st198" points="689.8,709 689.9,709.5 624.8,749.9 624.6,749.4 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="624.7,749.3 624.7,750 524.7,742.9 524.7,742.3 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="624.7,749.3 624.7,750 524.7,742.9 524.7,742.3 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="624.8,749.3 712.7,797.6 712.5,798.2 624.6,750 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="528" y2="739.1"/>
						<polygon class="st198" points="528,738.8 624.7,749.3 624.7,750 527.9,739.5 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="624.9,749.4 709.6,847.1 709.3,847.7 624.5,749.9 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="624.6,749.4 624.8,749.9 564.8,789.2 564.6,788.7 						"/>
					</g>
					<g>
						<line class="st198" x1="624.7" y1="749.7" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="674.7,685.5 674.9,685.9 624.9,749.9 624.6,749.5 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1117.9" y1="694.9" x2="1135.9" y2="679.9"/>
						<polygon class="st198" points="1135.9,679.8 1136,680.1 1118,695 1117.9,694.7 						"/>
					</g>
					<g>
						<line class="st198" x1="1117.9" y1="694.9" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1018.5,649.5 1118,694.5 1117.8,695.2 1018.3,650.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1117.9" y1="694.9" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1018.5,649.5 1118,694.5 1117.8,695.2 1018.3,650.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1117.9" y1="694.9" x2="1195.3" y2="684.8"/>
						<polygon class="st198" points="1195.3,684.6 1195.3,685.1 1117.9,695.2 1117.9,694.6 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1107.5" y1="600.1" x2="1117.9" y2="694.9"/>
						<polygon class="st198" points="1107.7,600.1 1118.1,694.8 1117.7,694.9 1107.3,600.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1107.5" y1="600.1" x2="1135.9" y2="679.9"/>
						<polygon class="st198" points="1107.7,600 1136.1,679.8 1135.8,680 1107.3,600.3 						"/>
					</g>
					<g>
						<line class="st198" x1="1107.5" y1="600.1" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1107.4,599.8 1107.6,600.5 1018.5,650.2 1018.3,649.6 						"/>
					</g>
					<g>
						<line class="st198" x1="1107.5" y1="600.1" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1107.4,599.8 1107.6,600.5 1018.5,650.2 1018.3,649.6 						"/>
					</g>
					<g>
						<line class="st198" x1="1107.5" y1="600.1" x2="1195.3" y2="684.8"/>
						<polygon class="st198" points="1107.6,599.9 1195.4,684.5 1195.1,685.1 1107.3,600.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1107.5" y2="600.1"/>
						<polygon class="st198" points="1107.2,600 1107.7,600.3 1070,740.6 1069.4,740.4 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1117.9" y2="694.9"/>
						<polygon class="st198" points="1117.8,694.7 1118,695.1 1069.8,740.7 1069.6,740.3 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1135.9" y2="679.9"/>
						<polygon class="st198" points="1135.8,679.7 1136.1,680.2 1069.8,740.7 1069.6,740.2 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1018.6,649.7 1069.9,740.3 1069.5,740.7 1018.2,650.1 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1018.6,649.7 1069.9,740.3 1069.5,740.7 1018.2,650.1 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="1005.3" y2="757.3"/>
						<polygon class="st198" points="1069.7,740.2 1069.7,740.7 1005.3,757.6 1005.2,757.1 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="986.2" y2="735.3"/>
						<polygon class="st198" points="1069.7,740.2 1069.7,740.8 986.2,735.6 986.2,735 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.7,689.5 1069.8,740.2 1069.6,740.8 983.5,690.1 						"/>
					</g>
					<g>
						<line class="st198" x1="1069.7" y1="740.5" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="983.7,689.5 1069.8,740.2 1069.6,740.8 983.5,690.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="839.5" y1="834.8" x2="947.1" y2="840.2"/>
						<polygon class="st198" points="947.1,839.8 947.1,840.6 839.5,835.2 839.5,834.5 						"/>
					</g>
					<g>
						<line class="st198" x1="839.5" y1="834.8" x2="883.6" y2="765.3"/>
						<polygon class="st198" points="883.4,765.2 883.8,765.5 839.6,835 839.3,834.7 						"/>
					</g>
					<g>
						<line class="st198" x1="839.5" y1="834.8" x2="846" y2="708.9"/>
						<polygon class="st198" points="845.8,708.8 846.3,708.9 839.7,834.8 839.2,834.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="756.4" y1="833" x2="839.5" y2="834.8"/>
						<polygon class="st198" points="839.5,834.5 839.5,835.1 756.4,833.3 756.4,832.7 						"/>
					</g>
					<g>
						<line class="st198" x1="756.4" y1="833" x2="689.8" y2="709.3"/>
						<polygon class="st198" points="690.1,709.1 756.6,832.8 756.2,833.2 689.6,709.5 						"/>
					</g>
					<g>
						<line class="st198" x1="756.4" y1="833" x2="748.3" y2="728.2"/>
						<polygon class="st198" points="748.6,728.1 756.6,833 756.2,833.1 748.1,728.2 						"/>
					</g>
					<g>
						<line class="st198" x1="756.4" y1="833" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="712.7,797.7 756.5,832.8 756.3,833.2 712.5,798.1 						"/>
					</g>
					<g>
						<line class="st198" x1="756.4" y1="833" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="756.4,832.8 756.4,833.2 709.5,847.6 709.4,847.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="756.4" y2="833"/>
						<polygon class="st198" points="799.7,766.3 800,766.6 756.6,833.2 756.2,832.8 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="839.5" y2="834.8"/>
						<polygon class="st198" points="800,766.3 839.6,834.7 839.3,835 799.7,766.6 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="748.3" y2="728.2"/>
						<polygon class="st198" points="748.4,727.9 799.9,766.2 799.8,766.6 748.2,728.4 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="799.8,766.1 799.9,766.7 712.7,798.2 712.6,797.6 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="765.3,641.1 800.1,766.3 799.6,766.5 764.8,641.4 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="799.7,766.1 800,766.7 709.6,847.7 709.3,847.1 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="883.6" y2="765.3"/>
						<polygon class="st198" points="883.6,765 883.6,765.6 799.9,766.7 799.8,766.1 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="846" y2="708.9"/>
						<polygon class="st198" points="845.9,708.7 846.2,709.1 800,766.6 799.7,766.2 						"/>
					</g>
					<g>
						<line class="st198" x1="799.8" y1="766.4" x2="875.7" y2="670"/>
						<polygon class="st198" points="875.5,669.8 875.9,670.3 800,766.7 799.7,766.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="615.5" y2="603.1"/>
						<polygon class="st198" points="615.6,602.8 686.6,609.3 686.6,609.8 615.5,603.3 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="741.2" y2="552.2"/>
						<polygon class="st198" points="741.1,552 741.3,552.4 686.8,609.8 686.5,609.3 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="584.1" y2="603"/>
						<polygon class="st198" points="686.6,609.2 686.6,609.9 584.1,603.4 584.1,602.7 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="689.8" y2="709.3"/>
						<polygon class="st198" points="686.8,609.5 690.1,709.3 689.6,709.3 686.4,609.6 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="748.3" y2="728.2"/>
						<polygon class="st198" points="686.9,609.3 748.6,727.9 748.1,728.4 686.4,609.7 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="765.1" y2="641.2"/>
						<polygon class="st198" points="686.7,609.3 765.2,641 765,641.5 686.6,609.8 						"/>
					</g>
					<g>
						<line class="st198" x1="686.6" y1="609.5" x2="674.8" y2="685.7"/>
						<polygon class="st198" points="686.4,609.5 686.8,609.6 675,685.8 674.6,685.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="562.7" y2="678.6"/>
						<polygon class="st198" points="520.7,631.2 562.9,678.4 562.6,678.8 520.5,631.6 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="615.5" y2="603.1"/>
						<polygon class="st198" points="615.5,602.7 615.6,603.4 520.6,631.7 520.5,631 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="584.1" y2="603"/>
						<polygon class="st198" points="584,602.8 584.2,603.3 520.6,631.6 520.5,631.1 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="520.8,631.4 525,742.6 524.5,742.6 520.3,631.4 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="520.8,631.4 525,742.6 524.5,742.6 520.3,631.4 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="520.4,631.2 520.7,631.5 487.4,691.1 487.1,690.8 						"/>
					</g>
					<g>
						<line class="st198" x1="520.6" y1="631.4" x2="528" y2="739.1"/>
						<polygon class="st198" points="520.8,631.3 528.2,739.1 527.7,739.2 520.3,631.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="386.6" y1="686.8" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="487.3,690.6 487.2,691.3 386.6,687.1 386.6,686.5 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="639.8" y1="835.1" x2="624.7" y2="749.7"/>
						<polygon class="st198" points="624.9,749.6 639.9,835.1 639.6,835.2 624.5,749.7 						"/>
					</g>
					<g>
						<line class="st198" x1="639.8" y1="835.1" x2="689.8" y2="709.3"/>
						<polygon class="st198" points="689.6,709.1 690.1,709.4 640,835.3 639.5,835 						"/>
					</g>
					<g>
						<line class="st198" x1="639.8" y1="835.1" x2="712.6" y2="797.9"/>
						<polygon class="st198" points="712.5,797.6 712.7,798.1 639.8,835.4 639.7,834.8 						"/>
					</g>
					<g>
						<line class="st198" x1="639.8" y1="835.1" x2="709.5" y2="847.4"/>
						<polygon class="st198" points="639.8,834.8 709.5,847.1 709.4,847.7 639.7,835.4 						"/>
					</g>
					<g>
						<line class="st198" x1="639.8" y1="835.1" x2="564.7" y2="788.9"/>
						<polygon class="st198" points="564.8,788.6 639.9,834.8 639.7,835.4 564.6,789.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="386.6" y2="686.8"/>
						<polygon class="st198" points="386.7,686.6 441.8,740.6 441.5,741 386.5,687 						"/>
					</g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="520.6" y2="631.4"/>
						<polygon class="st198" points="520.4,631.1 520.8,631.6 441.9,741.1 441.5,740.5 						"/>
					</g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="524.7,742.3 524.7,742.9 441.7,741.1 441.7,740.5 						"/>
					</g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="524.7" y2="742.6"/>
						<polygon class="st198" points="524.7,742.3 524.7,742.9 441.7,741.1 441.7,740.5 						"/>
					</g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="487.2" y2="690.9"/>
						<polygon class="st198" points="487.1,690.8 487.4,691.1 441.8,741 441.5,740.6 						"/>
					</g>
					<g>
						<line class="st198" x1="441.7" y1="740.8" x2="528" y2="739.1"/>
						<polygon class="st198" points="528,738.8 528,739.5 441.7,741.1 441.7,740.5 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1069.7" y2="740.5"/>
						<polygon class="st198" points="1056.9,600.2 1070,740.4 1069.4,740.5 1056.4,600.3 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1107.5" y2="600.1"/>
						<polygon class="st198" points="1107.5,599.9 1107.5,600.4 1056.6,600.5 1056.6,600 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1117.9" y2="694.9"/>
						<polygon class="st198" points="1056.8,600 1118.1,694.7 1117.7,695.1 1056.4,600.5 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="967.3" y2="573.7"/>
						<polygon class="st198" points="967.3,573.4 1056.7,599.9 1056.6,600.6 967.2,574 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1135.9" y2="679.9"/>
						<polygon class="st198" points="1056.8,600 1136.1,679.6 1135.8,680.2 1056.5,600.5 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1056.5,600.1 1056.8,600.4 1018.5,650.1 1018.2,649.7 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="1018.4" y2="649.9"/>
						<polygon class="st198" points="1056.5,600.1 1056.8,600.4 1018.5,650.1 1018.2,649.7 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="983.3" y2="588.1"/>
						<polygon class="st198" points="983.3,587.8 1056.7,600 1056.6,600.5 983.2,588.4 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1056.5,600 1056.8,600.5 983.8,690.1 983.4,689.6 						"/>
					</g>
					<g>
						<line class="st198" x1="1056.6" y1="600.2" x2="983.6" y2="689.8"/>
						<polygon class="st198" points="1056.5,600 1056.8,600.5 983.8,690.1 983.4,689.6 						"/>
					</g>
				</g>
			</g>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M1267.5,720.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1268.1,723.1,1267.4,722,1267.5,720.9z"/>
			<path class="st198" d="M1193.7,684.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1194.3,686.9,1193.6,685.9,1193.7,684.7z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1193.7,684.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1194.3,686.9,1193.6,685.9,1193.7,684.7z"/>
			<path class="st198" d="M1134.3,679.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1134.9,682,1134.3,681,1134.3,679.8z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M945.5,840.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C946.1,842.3,945.4,841.2,945.5,840.1z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M945.5,840.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C946.1,842.3,945.4,841.2,945.5,840.1z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M945.5,840.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C946.1,842.3,945.4,841.2,945.5,840.1z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M582.5,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C583.1,605.1,582.4,604.1,582.5,602.9z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M801.6,566c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C802.3,568.2,801.6,567.2,801.6,566z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M801.6,566c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C802.3,568.2,801.6,567.2,801.6,566z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M801.6,566c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C802.3,568.2,801.6,567.2,801.6,566z"/>
			<path class="st198" d="M876.5,623.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C877.2,625.5,876.5,624.4,876.5,623.3z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M801.6,566c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C802.3,568.2,801.6,567.2,801.6,566z"/>
			<path class="st198" d="M739.6,552.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C740.2,554.3,739.5,553.2,739.6,552.1z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M739.6,552.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C740.2,554.3,739.5,553.2,739.6,552.1z"/>
			<path class="st198" d="M582.5,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C583.1,605.1,582.4,604.1,582.5,602.9z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M582.5,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C583.1,605.1,582.4,604.1,582.5,602.9z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M1134.3,679.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1134.9,682,1134.3,681,1134.3,679.8z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1193.7,684.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1194.3,686.9,1193.6,685.9,1193.7,684.7z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1134.3,679.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1134.9,682,1134.3,681,1134.3,679.8z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1193.7,684.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1194.3,686.9,1193.6,685.9,1193.7,684.7z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1134.3,679.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1134.9,682,1134.3,681,1134.3,679.8z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1003.6,757.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1004.3,759.4,1003.6,758.4,1003.6,757.2z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M984.6,735.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C985.2,737.4,984.5,736.4,984.6,735.2z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M945.5,840.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C946.1,842.3,945.4,841.2,945.5,840.1z"/>
			<path class="st198" d="M837.8,834.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C838.5,836.9,837.8,835.9,837.8,834.7z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M837.8,834.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C838.5,836.9,837.8,835.9,837.8,834.7z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M837.8,834.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C838.5,836.9,837.8,835.9,837.8,834.7z"/>
			<path class="st198" d="M837.8,834.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C838.5,836.9,837.8,835.9,837.8,834.7z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M754.8,832.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C755.4,835.1,754.7,834.1,754.8,832.9z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M837.8,834.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C838.5,836.9,837.8,835.9,837.8,834.7z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M882,765.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C882.6,767.4,881.9,766.4,882,765.2z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M844.4,708.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C845,710.9,844.3,709.9,844.4,708.7z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M874.1,669.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C874.7,672.1,874,671.1,874.1,669.9z"/>
			<path class="st198" d="M798.2,766.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C798.8,768.5,798.2,767.5,798.2,766.3z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M739.6,552.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C740.2,554.3,739.5,553.2,739.6,552.1z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M582.5,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C583.1,605.1,582.4,604.1,582.5,602.9z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M746.7,728c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C747.3,730.2,746.6,729.2,746.7,728z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M763.5,641.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C764.1,643.3,763.4,642.3,763.5,641.1z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M673.2,685.6c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C673.8,687.8,673.1,686.8,673.2,685.6z"/>
			<path class="st198" d="M685,609.4c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C685.6,611.6,684.9,610.6,685,609.4z"/>
			<path class="st198" d="M561.1,678.4c0.1-1.2,0.8-2.1,1.7-2s1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C561.7,680.6,561.1,679.6,561.1,678.4z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M613.9,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C614.5,605.1,613.9,604.1,613.9,602.9z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M582.5,602.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C583.1,605.1,582.4,604.1,582.5,602.9z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M385,686.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C385.6,688.8,384.9,687.8,385,686.7z"/>
			<path class="st198" d="M623.1,749.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C623.7,751.7,623,750.7,623.1,749.5z"/>
			<path class="st198" d="M638.1,835c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C638.7,837.2,638.1,836.2,638.1,835z"/>
			<path class="st198" d="M688.2,709.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C688.8,711.3,688.2,710.3,688.2,709.1z"/>
			<path class="st198" d="M638.1,835c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C638.7,837.2,638.1,836.2,638.1,835z"/>
			<path class="st198" d="M711,797.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C711.6,799.9,710.9,798.9,711,797.7z"/>
			<path class="st198" d="M638.1,835c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C638.7,837.2,638.1,836.2,638.1,835z"/>
			<path class="st198" d="M707.8,847.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C708.5,849.5,707.8,848.4,707.8,847.3z"/>
			<path class="st198" d="M638.1,835c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C638.7,837.2,638.1,836.2,638.1,835z"/>
			<path class="st198" d="M563.1,788.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C563.7,791,563,789.9,563.1,788.8z"/>
			<path class="st198" d="M638.1,835c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C638.7,837.2,638.1,836.2,638.1,835z"/>
			<path class="st198" d="M385,686.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C385.6,688.8,384.9,687.8,385,686.7z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M519,631.2c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C519.6,633.4,518.9,632.4,519,631.2z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M523.1,742.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C523.7,744.7,523,743.6,523.1,742.5z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M485.6,690.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C486.2,693,485.6,692,485.6,690.8z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M526.3,739c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C527,741.2,526.3,740.2,526.3,739z"/>
			<path class="st198" d="M440,740.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C440.7,742.9,440,741.8,440,740.7z"/>
			<path class="st198" d="M1068.1,740.3c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1068.7,742.5,1068,741.5,1068.1,740.3z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M1105.8,600c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1106.5,602.2,1105.8,601.2,1105.8,600z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M1116.3,694.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1116.9,696.9,1116.2,695.9,1116.3,694.7z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M965.7,573.5c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C966.3,575.7,965.6,574.7,965.7,573.5z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M1134.3,679.8c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1134.9,682,1134.3,681,1134.3,679.8z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M1016.7,649.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1017.4,651.9,1016.7,650.9,1016.7,649.7z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M981.6,587.9c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.3,590.1,981.6,589.1,981.6,587.9z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
			<path class="st198" d="M982,689.7c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C982.6,691.9,981.9,690.9,982,689.7z"/>
			<path class="st198" d="M1055,600.1c0.1-1.2,0.8-2.1,1.7-2c0.9,0.1,1.6,1.1,1.5,2.3c-0.1,1.2-0.8,2.1-1.7,2
				C1055.6,602.3,1054.9,601.3,1055,600.1z"/>
		</g>
	</g>
	<g class="st1504">
		<g>
			<g>
				<g>
					<g>
						<line class="st198" x1="-34.5" y1="756.6" x2="-31.9" y2="791.3"/>
						
							<rect x="-33.3" y="756.6" transform="matrix(0.9971 -7.566582e-02 7.566582e-02 0.9971 -58.6586 -0.2925)" class="st198" width="0.3" height="34.8"/>
					</g>
					<g>
						<line class="st198" x1="-34.5" y1="756.6" x2="-31.9" y2="791.3"/>
						
							<rect x="-33.3" y="756.6" transform="matrix(0.9971 -7.566582e-02 7.566582e-02 0.9971 -58.6586 -0.2925)" class="st198" width="0.3" height="34.8"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-31.5" y1="868.9" x2="-34.5" y2="756.6"/>
						
							<rect x="-33.3" y="756.6" transform="matrix(0.9997 -2.633020e-02 2.633020e-02 0.9997 -21.4112 -0.5878)" class="st198" width="0.6" height="112.3"/>
					</g>
					<g>
						<line class="st198" x1="-31.5" y1="868.9" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-31.8,868.9 -32.1,791.3 -31.7,791.3 -31.3,868.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-31.5" y1="868.9" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-31.8,868.9 -32.1,791.3 -31.7,791.3 -31.3,868.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="76" y1="806.4" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="76,806.7 -31.9,791.6 -31.8,791 76.1,806.1 						"/>
					</g>
					<g>
						<line class="st198" x1="76" y1="806.4" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="76,806.7 -31.9,791.6 -31.8,791 76.1,806.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="105.7" y1="776.8" x2="76" y2="806.4"/>
						<polygon class="st198" points="76.1,806.5 75.9,806.3 105.6,776.7 105.8,776.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="68.1" y1="733.8" x2="105.7" y2="776.8"/>
						<polygon class="st198" points="105.6,776.9 68,733.9 68.3,733.7 105.8,776.7 						"/>
					</g>
					<g>
						<line class="st198" x1="68.1" y1="733.8" x2="76" y2="806.4"/>
						
							<rect x="71.9" y="733.6" transform="matrix(0.9941 -0.1081 0.1081 0.9941 -82.8536 12.309)" class="st198" width="0.4" height="73"/>
					</g>
					<g>
						<line class="st198" x1="68.1" y1="733.8" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-34.4,756.9 -34.6,756.4 68.1,733.5 68.2,734 						"/>
					</g>
					<g>
						<line class="st198" x1="68.1" y1="733.8" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-31.7,791.6 -32,791.1 68,733.5 68.3,734 						"/>
					</g>
					<g>
						<line class="st198" x1="68.1" y1="733.8" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-31.7,791.6 -32,791.1 68,733.5 68.3,734 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-53.5" y1="739.9" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-34.6,756.7 -53.6,740 -53.5,739.8 -34.4,756.5 						"/>
					</g>
					<g>
						<line class="st198" x1="-53.5" y1="739.9" x2="-31.9" y2="791.3"/>
						
							<rect x="-42.9" y="737.7" transform="matrix(0.9216 -0.3882 0.3882 0.9216 -300.5807 43.4713)" class="st198" width="0.4" height="55.8"/>
					</g>
					<g>
						<line class="st198" x1="-53.5" y1="739.9" x2="-31.9" y2="791.3"/>
						
							<rect x="-42.9" y="737.7" transform="matrix(0.9216 -0.3882 0.3882 0.9216 -300.5807 43.4713)" class="st198" width="0.4" height="55.8"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-243.6" y1="795.1" x2="-317.4" y2="767.6"/>
						<polygon class="st198" points="-243.7,795.3 -317.4,767.8 -317.3,767.3 -243.5,794.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-53.5" y2="739.9"/>
						<polygon class="st198" points="-66.4,821.8 -66.9,821.7 -53.8,739.8 -53.3,739.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.5" y2="868.9"/>
						<polygon class="st198" points="-31.7,869 -66.8,821.9 -66.5,821.6 -31.4,868.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-66.5,821.8 -66.8,821.7 -34.7,756.5 -34.3,756.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-66.5,821.9 -66.8,821.6 -32,791.2 -31.8,791.4 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-66.5,821.9 -66.8,821.6 -32,791.2 -31.8,791.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-53.5" y2="739.9"/>
						<polygon class="st198" points="-66.4,821.8 -66.9,821.7 -53.8,739.8 -53.3,739.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.5" y2="868.9"/>
						<polygon class="st198" points="-31.7,869 -66.8,821.9 -66.5,821.6 -31.4,868.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-66.5,821.8 -66.8,821.7 -34.7,756.5 -34.3,756.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-66.5,821.9 -66.8,821.6 -32,791.2 -31.8,791.4 						"/>
					</g>
					<g>
						<line class="st198" x1="-66.7" y1="821.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-66.5,821.9 -66.8,821.6 -32,791.2 -31.8,791.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-184.2" y1="798.9" x2="-243.6" y2="795.1"/>
						<polygon class="st198" points="-243.6,795.3 -243.6,794.9 -184.2,798.7 -184.2,799.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="73.5" y1="841.9" x2="68.1" y2="733.8"/>
						
							<rect x="70.6" y="733.7" transform="matrix(0.9987 -5.011227e-02 5.011227e-02 0.9987 -39.3919 4.5393)" class="st198" width="0.6" height="108.3"/>
					</g>
					<g>
						<line class="st198" x1="73.5" y1="841.9" x2="105.7" y2="776.8"/>
						<polygon class="st198" points="73.7,842 73.3,841.8 105.5,776.7 105.9,776.9 						"/>
					</g>
					<g>
						<line class="st198" x1="73.5" y1="841.9" x2="76" y2="806.4"/>
						<polygon class="st198" points="73.7,841.9 73.4,841.9 75.9,806.4 76.2,806.4 						"/>
					</g>
					<g>
						<line class="st198" x1="73.5" y1="841.9" x2="-31.5" y2="868.9"/>
						
							<rect x="-33.2" y="855.1" transform="matrix(0.9687 -0.2481 0.2481 0.9687 -211.6058 31.965)" class="st198" width="108.5" height="0.6"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="4.6" y1="676.7" x2="-53.5" y2="739.9"/>
						<polygon class="st198" points="-53.4,740 -53.7,739.7 4.4,676.6 4.8,676.9 						"/>
					</g>
					<g>
						<line class="st198" x1="4.6" y1="676.7" x2="68.1" y2="733.8"/>
						<polygon class="st198" points="68,734 4.5,676.9 4.8,676.5 68.3,733.6 						"/>
					</g>
					<g>
						<line class="st198" x1="4.6" y1="676.7" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-34.3,756.7 -34.7,756.5 4.4,676.6 4.8,676.8 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="423.8" y1="753.7" x2="387" y2="715.8"/>
						<polygon class="st198" points="423.6,753.9 386.9,715.9 387.2,715.7 423.9,753.6 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="186.6" y1="828.3" x2="276.9" y2="794.4"/>
						
							<rect x="183.5" y="811.1" transform="matrix(0.9366 -0.3504 0.3504 0.9366 -269.6504 132.6802)" class="st198" width="96.5" height="0.5"/>
					</g>
					<g>
						<line class="st198" x1="186.6" y1="828.3" x2="73.5" y2="841.9"/>
						<polygon class="st198" points="73.6,842.2 73.5,841.6 186.6,828.1 186.7,828.6 						"/>
					</g>
					<g>
						<line class="st198" x1="186.6" y1="828.3" x2="105.7" y2="776.8"/>
						<polygon class="st198" points="186.5,828.6 105.6,777 105.8,776.6 186.8,828.1 						"/>
					</g>
					<g>
						<line class="st198" x1="186.6" y1="828.3" x2="76" y2="806.4"/>
						<polygon class="st198" points="186.6,828.6 76,806.7 76.1,806.1 186.7,828.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="239.1" y1="709" x2="242.2" y2="671.2"/>
						
							<rect x="221.7" y="690" transform="matrix(8.071086e-02 -0.9967 0.9967 8.071086e-02 -466.6057 874.2899)" class="st198" width="37.9" height="0.3"/>
					</g>
					<g>
						<line class="st198" x1="239.1" y1="709" x2="276.9" y2="794.4"/>
						
							<rect x="257.8" y="705" transform="matrix(0.9144 -0.4047 0.4047 0.9144 -282.1479 168.7297)" class="st198" width="0.5" height="93.4"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="456.5" y1="817.6" x2="423.8" y2="753.7"/>
						<polygon class="st198" points="456.4,817.7 423.6,753.9 423.9,753.6 456.6,817.4 						"/>
					</g>
					<g>
						<line class="st198" x1="456.5" y1="817.6" x2="387" y2="715.8"/>
						<polygon class="st198" points="456.3,817.8 386.8,716 387.2,715.6 456.7,817.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="203.4" y1="762.1" x2="239.1" y2="709"/>
						<polygon class="st198" points="203.5,762.2 203.2,762 238.9,708.9 239.3,709.1 						"/>
					</g>
					<g>
						<line class="st198" x1="203.4" y1="762.1" x2="186.6" y2="828.3"/>
						
							<rect x="160.8" y="795" transform="matrix(0.2472 -0.969 0.969 0.2472 -623.7465 787.6128)" class="st198" width="68.3" height="0.4"/>
					</g>
					<g>
						<line class="st198" x1="203.4" y1="762.1" x2="242.2" y2="671.2"/>
						<polygon class="st198" points="203.6,762.2 203.1,762 242,671.1 242.5,671.3 						"/>
					</g>
					<g>
						<line class="st198" x1="203.4" y1="762.1" x2="276.9" y2="794.4"/>
						<polygon class="st198" points="276.8,794.6 203.3,762.3 203.5,761.9 277,794.2 						"/>
					</g>
					<g>
						<line class="st198" x1="203.4" y1="762.1" x2="105.7" y2="776.8"/>
						
							<rect x="105.1" y="769.2" transform="matrix(0.9888 -0.1494 0.1494 0.9888 -113.2459 31.7318)" class="st198" width="98.8" height="0.5"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="456.4,817.7 426.8,751.2 427.1,751 456.6,817.4 						"/>
					</g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="423.8" y2="753.7"/>
						
							<rect x="423.3" y="752.3" transform="matrix(0.7883 -0.6153 0.6153 0.7883 -372.8867 420.9761)" class="st198" width="4.2" height="0.2"/>
					</g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="387" y2="715.8"/>
						<polygon class="st198" points="426.9,751.2 386.9,715.9 387.1,715.7 427.1,751 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="456.4,817.7 426.8,751.2 427.1,751 456.6,817.4 						"/>
					</g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="423.8" y2="753.7"/>
						
							<rect x="423.3" y="752.3" transform="matrix(0.7883 -0.6153 0.6153 0.7883 -372.8867 420.9761)" class="st198" width="4.2" height="0.2"/>
					</g>
					<g>
						<line class="st198" x1="427" y1="751.1" x2="387" y2="715.8"/>
						<polygon class="st198" points="426.9,751.2 386.9,715.9 387.1,715.7 427.1,751 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="261.9" y1="776.5" x2="203.4" y2="762.1"/>
						<polygon class="st198" points="261.8,776.7 203.3,762.3 203.4,761.9 261.9,776.3 						"/>
					</g>
					<g>
						<line class="st198" x1="261.9" y1="776.5" x2="239.1" y2="709"/>
						<polygon class="st198" points="261.7,776.6 238.9,709 239.3,708.9 262.1,776.4 						"/>
					</g>
					<g>
						<line class="st198" x1="261.9" y1="776.5" x2="186.6" y2="828.3"/>
						
							<rect x="178.6" y="802.2" transform="matrix(0.8231 -0.5679 0.5679 0.8231 -416.028 269.3066)" class="st198" width="91.4" height="0.5"/>
					</g>
					<g>
						<line class="st198" x1="261.9" y1="776.5" x2="242.2" y2="671.2"/>
						
							<rect x="251.8" y="670.3" transform="matrix(0.9831 -0.1832 0.1832 0.9831 -128.367 58.4406)" class="st198" width="0.5" height="107.1"/>
					</g>
					<g>
						<line class="st198" x1="261.9" y1="776.5" x2="276.9" y2="794.4"/>
						
							<rect x="269.3" y="773.8" transform="matrix(0.7663 -0.6425 0.6425 0.7663 -441.7112 356.6836)" class="st198" width="0.3" height="23.4"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="367.6" y1="857.4" x2="276.9" y2="794.4"/>
						<polygon class="st198" points="367.5,857.7 276.8,794.7 277.1,794.2 367.8,857.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="148.4" y1="885.5" x2="186.6" y2="828.3"/>
						<polygon class="st198" points="148.6,885.7 148.3,885.4 186.4,828.2 186.8,828.5 						"/>
					</g>
					<g>
						<line class="st198" x1="148.4" y1="885.5" x2="73.5" y2="841.9"/>
						<polygon class="st198" points="148.3,885.8 73.4,842.1 73.7,841.7 148.6,885.3 						"/>
					</g>
					<g>
						<line class="st198" x1="148.4" y1="885.5" x2="76" y2="806.4"/>
						
							<rect x="111.9" y="792.3" transform="matrix(0.7378 -0.675 0.675 0.7378 -541.6054 297.5505)" class="st198" width="0.5" height="107.3"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="73.5" y2="841.9"/>
						<polygon class="st198" points="-15.5,880.1 -15.7,879.6 73.4,841.7 73.6,842.2 						"/>
					</g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-15.7,880 -66.8,821.9 -66.5,821.6 -15.4,879.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-15.7,880 -66.8,821.9 -66.5,821.6 -15.4,879.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="-31.5" y2="868.9"/>
						<polygon class="st198" points="-15.6,879.9 -31.6,869 -31.5,868.8 -15.5,879.7 						"/>
					</g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="-31.9" y2="791.3"/>
						
							<rect x="-24" y="790.6" transform="matrix(0.9834 -0.1812 0.1812 0.9834 -151.8396 9.5402)" class="st198" width="0.5" height="90"/>
					</g>
					<g>
						<line class="st198" x1="-15.6" y1="879.8" x2="-31.9" y2="791.3"/>
						
							<rect x="-24" y="790.6" transform="matrix(0.9834 -0.1812 0.1812 0.9834 -151.8396 9.5402)" class="st198" width="0.5" height="90"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="210.5" y1="885.8" x2="148.4" y2="885.5"/>
						<polygon class="st198" points="210.5,886 148.4,885.7 148.5,885.4 210.5,885.6 						"/>
					</g>
					<g>
						<line class="st198" x1="210.5" y1="885.8" x2="186.6" y2="828.3"/>
						<polygon class="st198" points="210.3,885.9 186.4,828.4 186.8,828.3 210.7,885.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="336.2" y1="857.4" x2="367.6" y2="857.4"/>
						<polygon class="st198" points="336.2,857.6 336.2,857.3 367.6,857.3 367.6,857.6 						"/>
					</g>
					<g>
						<line class="st198" x1="336.2" y1="857.4" x2="261.9" y2="776.5"/>
						<polygon class="st198" points="336,857.6 261.7,776.7 262.1,776.3 336.4,857.2 						"/>
					</g>
					<g>
						<line class="st198" x1="336.2" y1="857.4" x2="276.9" y2="794.4"/>
						<polygon class="st198" points="336,857.6 276.7,794.6 277.1,794.3 336.3,857.3 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="336.2" y2="857.4"/>
						<polygon class="st198" points="336.3,857.6 336,857.3 388.8,799.7 389.1,800 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="367.6" y2="857.4"/>
						<polygon class="st198" points="367.8,857.5 367.4,857.4 388.8,799.8 389.2,800 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="427" y2="751.1"/>
						<polygon class="st198" points="389.1,800 388.8,799.8 426.8,751 427.1,751.2 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="427" y2="751.1"/>
						<polygon class="st198" points="389.1,800 388.8,799.8 426.8,751 427.1,751.2 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="389,800.1 388.9,799.7 456.5,817.4 456.5,817.8 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="423.8" y2="753.7"/>
						<polygon class="st198" points="389.1,800 388.8,799.8 423.6,753.6 423.9,753.8 						"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="387" y2="715.8"/>
						
							<rect x="387.8" y="715.8" transform="matrix(0.9997 -2.312749e-02 2.312749e-02 0.9997 -17.4232 9.1762)" class="st198" width="0.5" height="84.1"/>
					</g>
					<g>
						<line class="st198" x1="389" y1="799.9" x2="276.9" y2="794.4"/>
						
							<rect x="332.7" y="741.1" transform="matrix(5.469644e-02 -0.9985 0.9985 5.469644e-02 -481.2316 1086.0013)" class="st198" width="0.6" height="112.2"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="389" y2="799.9"/>
						
							<rect x="357.8" y="731.6" transform="matrix(0.6581 -0.7529 0.7529 0.6581 -459.4711 533.7557)" class="st198" width="0.5" height="82.3"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="336.2" y2="857.4"/>
						<polygon class="st198" points="335.9,857.5 326.7,745.7 327.3,745.7 336.4,857.4 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="261.9" y2="776.5"/>
						<polygon class="st198" points="262,776.7 261.8,776.3 326.9,745.5 327.1,745.9 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="427" y2="751.1"/>
						<polygon class="st198" points="327,746 327,745.5 427,750.8 427,751.4 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="427" y2="751.1"/>
						<polygon class="st198" points="327,746 327,745.5 427,750.8 427,751.4 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="239.1" y2="709"/>
						<polygon class="st198" points="326.9,746 239,709.2 239.2,708.7 327.1,745.5 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="423.8" y2="753.7"/>
						<polygon class="st198" points="423.7,754 327,746 327,745.5 423.8,753.5 						"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="242.2" y2="671.2"/>
						
							<rect x="284.3" y="652.1" transform="matrix(0.6602 -0.7511 0.7511 0.6602 -435.4166 454.5305)" class="st198" width="0.6" height="112.8"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="387" y2="715.8"/>
						
							<rect x="323.5" y="730.6" transform="matrix(0.898 -0.4399 0.4399 0.898 -285.0868 231.5845)" class="st198" width="67.1" height="0.4"/>
					</g>
					<g>
						<line class="st198" x1="327" y1="745.7" x2="276.9" y2="794.4"/>
						<polygon class="st198" points="277.1,794.6 276.8,794.3 326.9,745.6 327.1,745.9 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-166.2" y1="787.5" x2="-184.2" y2="798.9"/>
						<polygon class="st198" points="-184.2,799 -184.3,798.8 -166.3,787.4 -166.1,787.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-166.2" y1="787.5" x2="-66.7" y2="821.7"/>
						
							<rect x="-116.7" y="752" transform="matrix(0.3256 -0.9455 0.9455 0.3256 -839.2996 432.5731)" class="st198" width="0.5" height="105.3"/>
					</g>
					<g>
						<line class="st198" x1="-166.2" y1="787.5" x2="-66.7" y2="821.7"/>
						
							<rect x="-116.7" y="752" transform="matrix(0.3256 -0.9455 0.9455 0.3256 -839.2996 432.5731)" class="st198" width="0.5" height="105.3"/>
					</g>
					<g>
						<line class="st198" x1="-166.2" y1="787.5" x2="-243.6" y2="795.1"/>
						<polygon class="st198" points="-243.6,795.3 -243.6,794.9 -166.2,787.3 -166.2,787.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-155.8" y1="859.7" x2="-166.2" y2="787.5"/>
						<polygon class="st198" points="-156,859.7 -166.4,787.5 -166,787.4 -155.5,859.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-155.8" y1="859.7" x2="-184.2" y2="798.9"/>
						<polygon class="st198" points="-155.9,859.7 -184.4,798.9 -184,798.8 -155.6,859.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-155.8" y1="859.7" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-155.7,859.9 -155.9,859.4 -66.8,821.5 -66.6,822 						"/>
					</g>
					<g>
						<line class="st198" x1="-155.8" y1="859.7" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-155.7,859.9 -155.9,859.4 -66.8,821.5 -66.6,822 						"/>
					</g>
					<g>
						<line class="st198" x1="-155.8" y1="859.7" x2="-243.6" y2="795.1"/>
						<polygon class="st198" points="-155.9,859.9 -243.7,795.3 -243.4,794.9 -155.6,859.4 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-155.8" y2="859.7"/>
						
							<rect x="-193.6" y="805.9" transform="matrix(0.3341 -0.9425 0.9425 0.3341 -851.0004 407.8239)" class="st198" width="113.4" height="0.6"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-166.2" y2="787.5"/>
						<polygon class="st198" points="-166.1,787.6 -166.3,787.3 -118.1,752.6 -117.9,752.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-184.2" y2="798.9"/>
						
							<rect x="-191.5" y="775.6" transform="matrix(0.8209 -0.5711 0.5711 0.8209 -470.0882 52.6444)" class="st198" width="80.7" height="0.5"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-66.8,821.9 -118.2,752.8 -117.8,752.6 -66.5,821.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-66.8,821.9 -118.2,752.8 -117.8,752.6 -66.5,821.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-53.5" y2="739.9"/>
						<polygon class="st198" points="-118,752.9 -118,752.5 -53.6,739.7 -53.5,740.1 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-34.5" y2="756.6"/>
						<polygon class="st198" points="-118,752.9 -118,752.5 -34.5,756.4 -34.5,756.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-32,791.5 -118.1,752.9 -117.9,752.5 -31.8,791.1 						"/>
					</g>
					<g>
						<line class="st198" x1="-118" y1="752.7" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-32,791.5 -118.1,752.9 -117.9,752.5 -31.8,791.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="112.3" y1="680.8" x2="4.6" y2="676.7"/>
						
							<rect x="58.2" y="624.9" transform="matrix(3.732318e-02 -0.9993 0.9993 3.732318e-02 -622.041 711.8262)" class="st198" width="0.5" height="107.7"/>
					</g>
					<g>
						<line class="st198" x1="112.3" y1="680.8" x2="68.1" y2="733.8"/>
						
							<rect x="55.7" y="707.1" transform="matrix(0.6397 -0.7686 0.7686 0.6397 -511.1663 324.18)" class="st198" width="68.9" height="0.4"/>
					</g>
					<g>
						<line class="st198" x1="112.3" y1="680.8" x2="105.7" y2="776.8"/>
						
							<rect x="60.9" y="728.6" transform="matrix(6.893031e-02 -0.9976 0.9976 6.893031e-02 -625.618 787.283)" class="st198" width="96.2" height="0.5"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="195.3" y1="682.2" x2="112.3" y2="680.8"/>
						<polygon class="st198" points="112.2,681 112.3,680.6 195.3,682 195.3,682.4 						"/>
					</g>
					<g>
						<line class="st198" x1="195.3" y1="682.2" x2="261.9" y2="776.5"/>
						<polygon class="st198" points="261.6,776.7 195.1,682.4 195.6,682 262.1,776.3 						"/>
					</g>
					<g>
						<line class="st198" x1="195.3" y1="682.2" x2="203.4" y2="762.1"/>
						
							<rect x="199.1" y="682" transform="matrix(0.995 -0.1004 0.1004 0.995 -71.4685 23.6528)" class="st198" width="0.5" height="80.3"/>
					</g>
					<g>
						<line class="st198" x1="195.3" y1="682.2" x2="239.1" y2="709"/>
						
							<rect x="217" y="669.9" transform="matrix(0.5219 -0.853 0.853 0.5219 -489.4821 517.8043)" class="st198" width="0.4" height="51.3"/>
					</g>
					<g>
						<line class="st198" x1="195.3" y1="682.2" x2="242.2" y2="671.2"/>
						
							<rect x="194.7" y="676.5" transform="matrix(0.9735 -0.2289 0.2289 0.9735 -149.0595 68.0277)" class="st198" width="48.2" height="0.3"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="195.3" y2="682.2"/>
						<polygon class="st198" points="152,733.1 151.7,732.8 195.2,682.1 195.5,682.3 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="112.3" y2="680.8"/>
						
							<rect x="131.9" y="674.1" transform="matrix(0.7962 -0.605 0.605 0.7962 -400.7793 223.9691)" class="st198" width="0.4" height="65.5"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="203.4" y2="762.1"/>
						<polygon class="st198" points="203.3,762.3 151.8,733.1 152,732.8 203.5,761.9 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="239.1" y2="709"/>
						<polygon class="st198" points="151.9,733.2 151.8,732.7 239,708.7 239.2,709.2 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="186.6" y2="828.3"/>
						<polygon class="st198" points="186.4,828.4 151.6,733 152.1,732.8 186.9,828.2 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="242.2" y2="671.2"/>
						
							<rect x="142.3" y="701.8" transform="matrix(0.8224 -0.5689 0.5689 0.8224 -364.3918 236.7562)" class="st198" width="109.4" height="0.6"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="68.1" y2="733.8"/>
						<polygon class="st198" points="68.1,734 68.1,733.5 151.9,732.7 151.9,733.2 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="105.7" y2="776.8"/>
						<polygon class="st198" points="105.8,776.9 105.6,776.7 151.7,732.8 152,733.1 						"/>
					</g>
					<g>
						<line class="st198" x1="151.9" y1="732.9" x2="76" y2="806.4"/>
						<polygon class="st198" points="76.2,806.6 75.8,806.2 151.7,732.7 152,733.1 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="336.2" y2="857.4"/>
						<polygon class="st198" points="336.2,857.6 299,857.7 299,857.2 336.2,857.2 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="210.5" y2="885.8"/>
						<polygon class="st198" points="210.6,886 210.4,885.7 298.9,857.3 299.1,857.6 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="367.6" y2="857.4"/>
						<polygon class="st198" points="299,857.7 299,857.2 367.6,857.2 367.6,857.7 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="261.9" y2="776.5"/>
						<polygon class="st198" points="298.8,857.5 261.7,776.5 262.1,776.5 299.2,857.4 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="203.4" y2="762.1"/>
						<polygon class="st198" points="298.8,857.6 203.1,762.3 203.6,762 299.2,857.3 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="186.6" y2="828.3"/>
						<polygon class="st198" points="298.9,857.7 186.6,828.6 186.7,828.1 299.1,857.2 						"/>
					</g>
					<g>
						<line class="st198" x1="299" y1="857.5" x2="276.9" y2="794.4"/>
						<polygon class="st198" points="299.2,857.5 298.8,857.4 276.7,794.4 277.1,794.5 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="389" y2="799.9"/>
						<polygon class="st198" points="401.5,862.3 388.9,800 389.1,799.7 401.8,862 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="336.2" y2="857.4"/>
						<polygon class="st198" points="336.2,857.7 336.1,857.2 401.6,861.9 401.7,862.4 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="367.6" y2="857.4"/>
						<polygon class="st198" points="367.7,857.6 367.6,857.2 401.6,862 401.7,862.4 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="427" y2="751.1"/>
						<polygon class="st198" points="401.4,862.2 426.7,751.1 427.2,751.1 401.9,862.2 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="427" y2="751.1"/>
						<polygon class="st198" points="401.4,862.2 426.7,751.1 427.2,751.1 401.9,862.2 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="401.8,862.3 401.5,862.1 456.3,817.5 456.6,817.7 						"/>
					</g>
					<g>
						<line class="st198" x1="401.6" y1="862.2" x2="423.8" y2="753.7"/>
						<polygon class="st198" points="401.4,862.2 423.5,753.7 424,753.7 401.9,862.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="533.2" y1="831.9" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="456.5,817.8 456.5,817.3 533.2,831.6 533.2,832.2 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="312" y1="680.6" x2="327" y2="745.7"/>
						
							<rect x="319.3" y="679.7" transform="matrix(0.9744 -0.225 0.225 0.9744 -152.2852 90.1827)" class="st198" width="0.4" height="66.8"/>
					</g>
					<g>
						<line class="st198" x1="312" y1="680.6" x2="261.9" y2="776.5"/>
						<polygon class="st198" points="262.1,776.6 261.6,776.4 311.7,680.5 312.2,680.7 						"/>
					</g>
					<g>
						<line class="st198" x1="312" y1="680.6" x2="239.1" y2="709"/>
						
							<rect x="236.4" y="694.6" transform="matrix(0.9347 -0.3555 0.3555 0.9347 -229.0262 143.3625)" class="st198" width="78.2" height="0.4"/>
					</g>
					<g>
						<line class="st198" x1="312" y1="680.6" x2="242.2" y2="671.2"/>
						<polygon class="st198" points="311.9,680.8 242.2,671.4 242.3,671 312,680.4 						"/>
					</g>
					<g>
						<line class="st198" x1="312" y1="680.6" x2="387" y2="715.8"/>
						<polygon class="st198" points="386.9,716 311.9,680.8 312.1,680.4 387.1,715.6 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="533.2" y2="831.9"/>
						<polygon class="st198" points="533.1,832.1 509.9,752.6 510.2,752.3 533.4,831.7 						"/>
					</g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="401.6" y2="862.2"/>
						<polygon class="st198" points="401.8,862.4 401.4,862 509.8,752.3 510.3,752.7 						"/>
					</g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="427" y2="751.1"/>
						<polygon class="st198" points="427,751.3 427,750.9 510.1,752.2 510,752.7 						"/>
					</g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="427" y2="751.1"/>
						<polygon class="st198" points="427,751.3 427,750.9 510.1,752.2 510,752.7 						"/>
					</g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="456.5" y2="817.6"/>
						<polygon class="st198" points="456.6,817.7 456.4,817.4 509.9,752.3 510.2,752.6 						"/>
					</g>
					<g>
						<line class="st198" x1="510" y1="752.5" x2="423.8" y2="753.7"/>
						<polygon class="st198" points="423.8,754 423.7,753.5 510,752.2 510.1,752.7 						"/>
					</g>
				</g>
				<g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-118" y2="752.7"/>
						
							<rect x="-111.7" y="752.3" transform="matrix(0.9926 -0.1214 0.1214 0.9926 -98.7204 -7.5687)" class="st198" width="0.6" height="107.7"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-155.8" y2="859.7"/>
						<polygon class="st198" points="-155.8,859.8 -155.8,859.5 -104.9,859.4 -104.9,859.8 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-166.2" y2="787.5"/>
						<polygon class="st198" points="-105.1,859.7 -166.4,787.6 -166,787.3 -104.7,859.4 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-15.6" y2="879.8"/>
						<polygon class="st198" points="-15.6,880.1 -105,859.8 -104.9,859.3 -15.5,879.6 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-184.2" y2="798.9"/>
						<polygon class="st198" points="-105.1,859.8 -184.4,799.1 -184.1,798.7 -104.8,859.4 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-104.8,859.7 -105,859.4 -66.8,821.6 -66.5,821.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-66.7" y2="821.7"/>
						<polygon class="st198" points="-104.8,859.7 -105,859.4 -66.8,821.6 -66.5,821.9 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-31.5" y2="868.9"/>
						
							<rect x="-68.4" y="827.2" transform="matrix(0.1255 -0.9921 0.9921 0.1255 -917.0576 688.0816)" class="st198" width="0.4" height="74"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-104.7,859.8 -105.1,859.4 -32.1,791.1 -31.7,791.5 						"/>
					</g>
					<g>
						<line class="st198" x1="-104.9" y1="859.6" x2="-31.9" y2="791.3"/>
						<polygon class="st198" points="-104.7,859.8 -105.1,859.4 -32.1,791.1 -31.7,791.5 						"/>
					</g>
				</g>
			</g>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -1061.9673 399.5458)" class="st198" cx="-317.4" cy="767.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -1020.6611 499.0247)" class="st198" cx="-243.6" cy="795.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -1020.6611 499.0247)" class="st198" cx="-243.6" cy="795.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -969.0187 561.7341)" class="st198" cx="-184.2" cy="798.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -670.9077 636.2838)" class="st198" cx="4.6" cy="676.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -670.9077 636.2838)" class="st198" cx="4.6" cy="676.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -670.9077 636.2838)" class="st198" cx="4.6" cy="676.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -512.4766 1166.9242)" class="st198" cx="367.6" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632970e-02 -0.9978 0.9978 6.632970e-02 -745.005 974.9225)" class="st198" cx="148.4" cy="885.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632970e-02 -0.9978 0.9978 6.632970e-02 -745.005 974.9225)" class="st198" cx="148.4" cy="885.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632970e-02 -0.9978 0.9978 6.632970e-02 -745.005 974.9225)" class="st198" cx="148.4" cy="885.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.678507e-02 -0.9978 0.9978 6.678507e-02 -771.4119 859.0719)" class="st198" cx="73.5" cy="841.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632970e-02 -0.9978 0.9978 6.632970e-02 -745.005 974.9225)" class="st198" cx="148.4" cy="885.5" rx="1.6" ry="1.6"/>
			<path class="st198" d="M211.8,884.7c1.2,1.8-0.9,3.6-2.6,2.2l0,0C208,885.1,210.1,883.3,211.8,884.7z"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			<path class="st198" d="M211.8,884.7c1.2,1.8-0.9,3.6-2.6,2.2l0,0C208,885.1,210.1,883.3,211.8,884.7z"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -512.4766 1166.9242)" class="st198" cx="367.6" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -512.4766 1166.9242)" class="st198" cx="367.6" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -969.0187 561.7341)" class="st198" cx="-184.2" cy="798.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -1020.6611 499.0247)" class="st198" cx="-243.6" cy="795.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -969.0187 561.7341)" class="st198" cx="-184.2" cy="798.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -1020.6611 499.0247)" class="st198" cx="-243.6" cy="795.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -969.0187 561.7341)" class="st198" cx="-184.2" cy="798.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.690410e-02 -0.9978 0.9978 6.690410e-02 -788.186 636.9496)" class="st198" cx="-53.5" cy="739.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -787.1361 671.6309)" class="st198" cx="-34.5" cy="756.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -670.9077 636.2838)" class="st198" cx="4.6" cy="676.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -574.538 747.3864)" class="st198" cx="112.3" cy="680.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -574.538 747.3864)" class="st198" cx="112.3" cy="680.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -574.538 747.3864)" class="st198" cx="112.3" cy="680.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -574.538 747.3864)" class="st198" cx="112.3" cy="680.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -498.3611 831.6649)" class="st198" cx="195.3" cy="682.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -574.538 747.3864)" class="st198" cx="112.3" cy="680.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -668.5653 752.9029)" class="st198" cx="68.1" cy="733.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -676.4359 830.4211)" class="st198" cx="105.7" cy="776.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -733.6503 828.3224)" class="st198" cx="76" cy="806.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -589.5595 835.6812)" class="st198" cx="151.9" cy="732.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			<path class="st198" d="M211.8,884.7c1.2,1.8-0.9,3.6-2.6,2.2l0,0C208,885.1,210.1,883.3,211.8,884.7z"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -512.4766 1166.9242)" class="st198" cx="367.6" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -570.6206 914.0878)" class="st198" cx="203.4" cy="762.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -652.3392 959.1678)" class="st198" cx="186.6" cy="828.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.632378e-02 -0.9978 0.9978 6.632378e-02 -534.1283 1018.0453)" class="st198" cx="276.9" cy="794.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -576.4418 1098.743)" class="st198" cx="299" cy="857.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -435.1268 1134.5195)" class="st198" cx="389" cy="799.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.667244e-02 -0.9978 0.9978 6.667244e-02 -541.7771 1135.6869)" class="st198" cx="336.2" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -512.4766 1166.9242)" class="st198" cx="367.6" cy="857.4" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -332.4491 1308.3337)" class="st198" cx="533.2" cy="831.9" rx="1.6" ry="1.6"/>
			<path class="st198" d="M328.6,745.8c-0.1,0.9-0.8,1.6-1.7,1.5c-0.9-0.1-1.6-0.8-1.5-1.7c0.1-0.9,0.8-1.6,1.7-1.5
				C328,744.2,328.7,744.9,328.6,745.8z"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -388.0062 946.2424)" class="st198" cx="312" cy="680.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672770e-02 -0.9978 0.9978 6.672770e-02 -530.3603 985.9611)" class="st198" cx="261.9" cy="776.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -388.0062 946.2424)" class="st198" cx="312" cy="680.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -484.2227 900.3538)" class="st198" cx="239.1" cy="709" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -388.0062 946.2424)" class="st198" cx="312" cy="680.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -443.7061 867.9478)" class="st198" cx="242.2" cy="671.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -388.0062 946.2424)" class="st198" cx="312" cy="680.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -352.9442 1054.3339)" class="st198" cx="387" cy="715.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.701743e-02 -0.9978 0.9978 6.701743e-02 -388.0062 946.2424)" class="st198" cx="312" cy="680.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -332.4491 1308.3337)" class="st198" cx="533.2" cy="831.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -485.462 1205.27)" class="st198" cx="401.6" cy="862.2" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -350.8823 1127.0962)" class="st198" cx="427" cy="751.1" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -389.7643 1218.3981)" class="st198" cx="456.5" cy="817.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655353e-02 -0.9978 0.9978 6.655353e-02 -356.5068 1126.376)" class="st198" cx="423.8" cy="753.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684071e-02 -0.9978 0.9978 6.684071e-02 -274.8257 1211.0774)" class="st198" cx="510" cy="752.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -861.1772 584.875)" class="st198" cx="-118" cy="752.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -1003.1411 647.0241)" class="st198" cx="-155.8" cy="859.7" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.661097e-02 -0.9978 0.9978 6.661097e-02 -940.8596 569.1804)" class="st198" cx="-166.2" cy="787.5" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -892.412 805.8406)" class="st198" cx="-15.6" cy="879.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.672993e-02 -0.9978 0.9978 6.672993e-02 -969.0187 561.7341)" class="st198" cx="-184.2" cy="798.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.684666e-02 -0.9978 0.9978 6.684666e-02 -882.1082 700.3167)" class="st198" cx="-66.7" cy="821.8" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.655947e-02 -0.9978 0.9978 6.655947e-02 -896.3782 779.5487)" class="st198" cx="-31.5" cy="868.9" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.689814e-02 -0.9978 0.9978 6.689814e-02 -819.2886 706.5773)" class="st198" cx="-31.9" cy="791.3" rx="1.6" ry="1.6"/>
			
				<ellipse transform="matrix(6.644083e-02 -0.9978 0.9978 6.644083e-02 -955.6252 697.7809)" class="st198" cx="-104.9" cy="859.6" rx="1.6" ry="1.6"/>
		</g>
	</g>
	<g class="st1505">
		<g>
			<linearGradient id="SVGID_147_" gradientUnits="userSpaceOnUse" x1="107.8553" y1="168.4606" x2="107.8553" y2="38.1516">
				<stop  offset="0" style="stop-color:#B75C27"/>
				<stop  offset="4.703292e-02" style="stop-color:#BB6229"/>
				<stop  offset="0.1216" style="stop-color:#C37332"/>
				<stop  offset="0.2144" style="stop-color:#D38E44"/>
				<stop  offset="0.3208" style="stop-color:#ECBB66"/>
				<stop  offset="0.3758" style="stop-color:#FFD97C"/>
				<stop  offset="0.4816" style="stop-color:#FFDB7D"/>
				<stop  offset="0.5429" style="stop-color:#FFE081"/>
				<stop  offset="0.5879" style="stop-color:#FDE888"/>
				<stop  offset="0.6238" style="stop-color:#FFE081"/>
				<stop  offset="0.6705" style="stop-color:#FFDB7D"/>
				<stop  offset="0.7636" style="stop-color:#FFD97C"/>
				<stop  offset="0.8466" style="stop-color:#E1A858"/>
				<stop  offset="1" style="stop-color:#B75C27"/>
			</linearGradient>
			<path class="st1506" d="M147.2,153.8l-0.9-0.2c-2-0.3-4,1-4.4,3.1l-0.2,0.9c-0.5,2.3-2.9,3.7-5.1,2.9l-0.9-0.3
				c-1.9-0.7-4.1,0.2-4.9,2.2l-0.4,0.9c-0.9,2.2-3.6,3.1-5.6,1.8l-0.8-0.5c-1.8-1.1-4.1-0.6-5.2,1.2l-0.5,0.8
				c-1.3,2-4.1,2.3-5.8,0.7l-0.7-0.6c-1.5-1.4-3.9-1.4-5.3,0.1l-0.7,0.7c-1.7,1.7-4.5,1.5-5.8-0.5l-0.6-0.8
				c-1.2-1.7-3.5-2.1-5.3-0.9l-0.8,0.5c-2,1.3-4.7,0.6-5.6-1.6l-0.4-0.9c-0.9-1.9-3.1-2.7-5-1.9l-0.9,0.4c-2.2,0.9-4.7-0.3-5.2-2.7
				l-0.2-0.9c-0.5-2-2.5-3.3-4.5-2.9l-0.9,0.2c-2.3,0.5-4.5-1.3-4.6-3.6l0-0.9c-0.1-2.1-1.8-3.7-3.9-3.7l-0.9,0
				c-2.4,0-4.2-2.1-3.8-4.5l0.2-0.9c0.3-2.1-1-4-3.1-4.4l-0.9-0.2c-2.3-0.5-3.7-2.9-2.9-5.1l0.3-0.9c0.7-1.9-0.2-4.1-2.2-4.9
				l-0.9-0.4c-2.2-0.9-3.1-3.6-1.8-5.6l0.5-0.8c1.1-1.8,0.6-4.1-1.2-5.2l-0.8-0.5c-2-1.3-2.3-4.1-0.7-5.8l0.6-0.7
				c1.4-1.5,1.4-3.9-0.1-5.3l-0.7-0.7c-1.7-1.7-1.5-4.5,0.5-5.8l0.8-0.6c1.7-1.2,2.1-3.5,0.9-5.3l-0.5-0.8c-1.3-2-0.6-4.7,1.6-5.6
				l0.9-0.4c1.9-0.9,2.7-3.1,1.9-5l-0.4-0.9c-0.9-2.2,0.3-4.7,2.7-5.2l0.9-0.2c2-0.5,3.3-2.5,2.9-4.5l-0.2-0.9
				c-0.5-2.3,1.3-4.5,3.6-4.6l0.9,0c2.1-0.1,3.7-1.8,3.7-3.9l0-0.9c0-2.4,2.1-4.2,4.5-3.8l0.9,0.2c2.1,0.3,4-1,4.4-3.1L74,49
				c0.5-2.3,2.9-3.7,5.1-2.9l0.9,0.3c1.9,0.7,4.1-0.2,4.9-2.2l0.4-0.9c0.9-2.2,3.6-3.1,5.6-1.8l0.8,0.5c1.8,1.1,4.1,0.6,5.2-1.2
				l0.5-0.8c1.3-2,4.1-2.3,5.8-0.7l0.7,0.6c1.5,1.4,3.9,1.4,5.3-0.1l0.7-0.7c1.7-1.7,4.5-1.5,5.8,0.5l0.6,0.8
				c1.2,1.7,3.5,2.1,5.3,0.9l0.8-0.5c2-1.3,4.7-0.6,5.6,1.6l0.4,0.9c0.9,1.9,3.1,2.7,5,1.9l0.9-0.4c2.2-0.9,4.7,0.3,5.2,2.7l0.2,0.9
				c0.5,2,2.5,3.3,4.5,2.9l0.9-0.2c2.3-0.5,4.5,1.3,4.6,3.6l0,0.9c0.1,2.1,1.8,3.7,3.9,3.7l0.9,0c2.4,0,4.2,2.1,3.8,4.5l-0.2,0.9
				c-0.3,2.1,1,4,3.1,4.4l0.9,0.2c2.3,0.5,3.7,2.9,2.9,5.1l-0.3,0.9c-0.7,1.9,0.2,4.1,2.2,4.9l0.9,0.4c2.2,0.9,3.1,3.6,1.8,5.6
				l-0.5,0.8c-1.1,1.8-0.6,4.1,1.2,5.2l0.8,0.5c2,1.3,2.3,4.1,0.7,5.8l-0.6,0.7c-1.4,1.5-1.4,3.9,0.1,5.4l0.7,0.7
				c1.7,1.7,1.5,4.5-0.5,5.8l-0.8,0.6c-1.7,1.2-2.1,3.5-0.9,5.3l0.5,0.8c1.3,2,0.6,4.7-1.6,5.6l-0.9,0.4c-1.9,0.9-2.7,3.1-1.9,5
				l0.4,0.9c0.9,2.2-0.3,4.7-2.7,5.2l-0.9,0.2c-2,0.5-3.3,2.5-2.9,4.5l0.2,0.9c0.5,2.3-1.3,4.5-3.6,4.6l-0.9,0
				c-2.1,0.1-3.7,1.8-3.7,3.9l0,0.9C151.7,152.4,149.6,154.2,147.2,153.8z"/>
			<g class="st1507">
				<defs>
					<path id="SVGID_148_" class="st1507" d="M146.8,153.7L146.8,153.7c-2.4-0.4-4.5,1.1-4.9,3.4l0,0.1c-0.5,2.6-3.2,4.1-5.6,3.2
						l-0.1,0c-2.1-0.8-4.5,0.3-5.4,2.4l0,0.1c-1,2.4-3.9,3.4-6.2,2l-0.1,0c-1.9-1.2-4.5-0.6-5.8,1.3l-0.1,0.1
						c-1.4,2.2-4.5,2.6-6.4,0.8l-0.1-0.1c-1.7-1.6-4.3-1.5-5.9,0.1l-0.1,0.1c-1.8,1.9-4.9,1.6-6.5-0.5l-0.1-0.1
						c-1.3-1.9-3.9-2.3-5.8-1l-0.1,0.1c-2.2,1.5-5.1,0.6-6.2-1.7l0-0.1c-1-2.1-3.4-3-5.5-2.1l-0.1,0c-2.4,1-5.2-0.4-5.8-2.9l0-0.1
						c-0.5-2.2-2.7-3.6-5-3.2l-0.1,0c-2.6,0.5-5-1.4-5.1-4l0-0.1c-0.1-2.3-2-4.1-4.3-4.1l-0.1,0c-2.6,0-4.6-2.3-4.2-4.9l0-0.1
						c0.4-2.3-1.1-4.4-3.4-4.8l-0.1,0c-2.6-0.5-4.1-3.2-3.2-5.6l0-0.1c0.8-2.1-0.3-4.5-2.4-5.4l-0.1,0c-2.4-1-3.4-3.9-2-6.2l0-0.1
						c1.2-1.9,0.6-4.5-1.3-5.8l-0.1-0.1c-2.2-1.4-2.6-4.5-0.8-6.4l0.1-0.1c1.6-1.7,1.5-4.3-0.1-5.9l-0.1-0.1
						c-1.9-1.8-1.6-4.9,0.5-6.5l0.1-0.1c1.9-1.3,2.3-3.9,1-5.8l-0.1-0.1c-1.5-2.2-0.6-5.1,1.7-6.2l0.1,0c2.1-1,3-3.4,2.1-5.5l0-0.1
						c-1-2.4,0.4-5.2,2.9-5.8l0.1,0c2.2-0.5,3.6-2.7,3.2-5l0-0.1c-0.5-2.6,1.4-5,4-5.1l0.1,0c2.3-0.1,4.1-2,4.1-4.3l0-0.1
						c0-2.6,2.3-4.6,4.9-4.2l0.1,0c2.3,0.4,4.4-1.1,4.8-3.4l0-0.1c0.5-2.6,3.2-4.1,5.6-3.2l0.1,0c2.1,0.8,4.5-0.3,5.4-2.4l0-0.1
						c1-2.4,3.9-3.4,6.2-2l0.1,0c1.9,1.2,4.5,0.6,5.8-1.3l0.1-0.1c1.4-2.2,4.5-2.6,6.4-0.8l0.1,0.1c1.7,1.6,4.3,1.5,5.9-0.1l0.1-0.1
						c1.8-1.9,4.9-1.6,6.5,0.5l0.1,0.1c1.3,1.9,3.9,2.3,5.8,1l0.1-0.1c2.2-1.5,5.1-0.6,6.2,1.7l0,0.1c1,2.1,3.4,3,5.5,2.1l0.1,0
						c2.4-1,5.2,0.4,5.8,2.9l0,0.1c0.5,2.2,2.7,3.6,5,3.2l0.1,0c2.6-0.5,5,1.4,5.1,4l0,0.1c0.1,2.3,2,4.1,4.3,4.1l0.1,0
						c2.6,0,4.6,2.3,4.2,4.9l0,0.1c-0.4,2.3,1.1,4.4,3.4,4.8l0.1,0c2.6,0.5,4.1,3.2,3.2,5.6l0,0.1c-0.8,2.1,0.3,4.5,2.4,5.4l0.1,0
						c2.4,1,3.4,3.9,2,6.2l0,0.1c-1.2,1.9-0.6,4.5,1.3,5.8l0.1,0.1c2.2,1.4,2.6,4.5,0.8,6.4l-0.1,0.1c-1.6,1.7-1.5,4.3,0.1,5.9
						l0.1,0.1c1.9,1.8,1.6,4.9-0.5,6.5l-0.1,0.1c-1.9,1.3-2.3,3.9-1,5.8l0.1,0.1c1.5,2.2,0.6,5.1-1.7,6.2l-0.1,0
						c-2.1,1-3,3.4-2.1,5.5l0,0.1c1,2.4-0.4,5.2-2.9,5.8l-0.1,0c-2.2,0.5-3.6,2.7-3.2,5l0,0.1c0.5,2.6-1.4,5-4,5.1l-0.1,0
						c-2.3,0.1-4.1,2-4.1,4.3l0,0.1C151.7,152.1,149.4,154.2,146.8,153.7z"/>
				</defs>
				<clipPath id="SVGID_149_">
					<use xlink:href="#SVGID_148_"  style="overflow:visible;"/>
				</clipPath>
			</g>
			<g>
				<linearGradient id="SVGID_150_" gradientUnits="userSpaceOnUse" x1="52.6772" y1="103.306" x2="163.033" y2="103.306">
					<stop  offset="0.1758" style="stop-color:#DB953B"/>
					<stop  offset="0.3758" style="stop-color:#FFD97C"/>
					<stop  offset="0.4816" style="stop-color:#FFDB7D"/>
					<stop  offset="0.5429" style="stop-color:#FFE081"/>
					<stop  offset="0.5879" style="stop-color:#FDE888"/>
					<stop  offset="0.6238" style="stop-color:#FFE081"/>
					<stop  offset="0.6705" style="stop-color:#FFDB7D"/>
					<stop  offset="0.7636" style="stop-color:#FFD97C"/>
				</linearGradient>
				<path class="st1510" d="M107.9,48.1c-30.5,0-55.2,24.7-55.2,55.2c0,30.5,24.7,55.2,55.2,55.2s55.2-24.7,55.2-55.2
					C163,72.8,138.3,48.1,107.9,48.1z M107.9,157c-29.7,0-53.7-24-53.7-53.7c0-29.7,24-53.7,53.7-53.7c29.7,0,53.7,24,53.7,53.7
					C161.5,133,137.5,157,107.9,157z"/>
			</g>
			<g class="st221">
				<g>
					<path class="st1511" d="M153.4,108.4c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,1,2.5,0c1-1.2,1.7-1.2,2.2-1.2c-1-0.9-1.8-1-2.5-0.9
						c-0.7,0.1-1.4,0.6-2.1,0.9C154.9,108.5,154.5,108.5,153.4,108.4z"/>
					<path class="st1511" d="M153.7,107.8c0,0,1.3-0.4,2-0.4c1.1-0.1,2-0.5,1.9-1.7c-0.2-1.6,0.4-2,0.8-2.4
						c-2.6,0.3-2.7,1.8-3.3,3.2C154.9,107,154.6,107.3,153.7,107.8z"/>
				</g>
				<g>
					<path class="st1511" d="M152.3,114.5c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.6,1.2,2.5,0.4c1.1-1.1,1.8-1,2.4-0.9
						c-0.9-1-1.7-1.3-2.4-1.2c-0.7,0-1.5,0.4-2.2,0.6C153.7,114.8,153.3,114.8,152.3,114.5z"/>
					<path class="st1511" d="M152.7,114c0,0.1,1.3-0.2,2.1-0.1c1.1,0.1,2-0.2,2.1-1.5c0.1-1.6,0.6-2,1.1-2.3
						c-2.6-0.1-2.9,1.4-3.7,2.8C153.9,113.4,153.6,113.6,152.7,114z"/>
				</g>
				<g>
					<path class="st1511" d="M150.4,120.5c0,0.1,1,0.8,1.5,1.5c0.6,0.9,1.4,1.4,2.4,0.7c1.3-0.9,1.9-0.7,2.5-0.5
						c-0.7-1.1-1.5-1.5-2.2-1.5c-0.7-0.1-1.5,0.2-2.3,0.3C151.7,121,151.3,120.9,150.4,120.5z"/>
					<path class="st1511" d="M150.8,120c0,0.1,1.3,0,2.1,0.2c1.1,0.2,2,0.1,2.3-1.2c0.3-1.5,0.9-1.9,1.4-2.1c-2.6-0.4-3.1,1-4.1,2.2
						C152.1,119.5,151.8,119.8,150.8,120z"/>
				</g>
				<g>
					<path class="st1511" d="M147.7,126.1c0,0,0.9,1,1.3,1.6c0.5,1,1.2,1.6,2.3,1.1c1.4-0.7,2-0.4,2.5-0.2c-0.6-1.2-1.3-1.7-2-1.8
						c-0.7-0.2-1.5,0-2.3,0C148.9,126.8,148.5,126.7,147.7,126.1z"/>
					<path class="st1511" d="M148.1,125.7c0,0.1,1.3,0.2,2,0.4c1,0.4,2,0.3,2.4-0.8c0.5-1.5,1.1-1.7,1.7-1.9
						c-2.5-0.8-3.2,0.6-4.3,1.6C149.5,125.4,149.1,125.6,148.1,125.7z"/>
				</g>
				<g>
					<path class="st1511" d="M144.2,131.3c0,0,0.8,1.1,1,1.8c0.4,1,1,1.8,2.1,1.4c1.5-0.6,2.1-0.2,2.5,0.2c-0.4-1.3-1-1.8-1.7-2.1
						c-0.7-0.3-1.5-0.2-2.3-0.3C145.3,132.2,145,132,144.2,131.3z"/>
					<path class="st1511" d="M144.7,131c0,0.1,1.3,0.4,1.9,0.7c1,0.5,1.9,0.6,2.5-0.5c0.7-1.4,1.4-1.5,1.9-1.6
						c-2.4-1.1-3.2,0.1-4.5,1C146.1,130.9,145.7,131,144.7,131z"/>
				</g>
				<g>
					<path class="st1511" d="M140,136c0,0,0.6,1.2,0.8,1.9c0.2,1.1,0.7,1.9,1.9,1.6c1.5-0.3,2.1,0.1,2.5,0.5
						c-0.2-1.3-0.8-1.9-1.4-2.3c-0.7-0.3-1.4-0.4-2.2-0.6C141.1,137,140.7,136.8,140,136z"/>
					<path class="st1511" d="M140.6,135.7c0,0.1,1.2,0.5,1.8,1c0.9,0.7,1.8,0.9,2.5-0.2c0.9-1.3,1.6-1.3,2.1-1.4
						c-2.2-1.4-3.2-0.3-4.6,0.4C142,135.8,141.6,135.9,140.6,135.7z"/>
				</g>
				<g>
					<path class="st1511" d="M135.3,140.1c0,0,0.4,1.3,0.5,2c0.1,1.1,0.5,2,1.7,1.9c1.6-0.1,2,0.4,2.4,0.8c0-1.3-0.5-2-1.1-2.5
						c-0.6-0.4-1.4-0.6-2.1-0.9C136.2,141.2,135.9,140.9,135.3,140.1z"/>
					<path class="st1511" d="M135.8,139.9c0,0.1,1.1,0.7,1.7,1.2c0.8,0.8,1.7,1.1,2.5,0.2c1-1.2,1.7-1.1,2.3-1.1
						c-2-1.7-3.2-0.8-4.6-0.2C137.2,140.2,136.8,140.2,135.8,139.9z"/>
				</g>
				<g>
					<path class="st1511" d="M130,143.5c0,0,0.3,1.3,0.2,2.1c-0.1,1.1,0.2,2,1.4,2.1c1.6,0.1,2,0.7,2.2,1.1c0.2-1.3-0.2-2.1-0.7-2.6
						c-0.5-0.5-1.3-0.8-2-1.2C130.7,144.7,130.5,144.4,130,143.5z"/>
					<path class="st1511" d="M130.6,143.4c0,0.1,1,0.8,1.5,1.4c0.7,0.9,1.5,1.3,2.5,0.5c1.2-1,1.9-0.9,2.4-0.8c-1.7-2-3-1.2-4.6-0.8
						C131.9,143.8,131.5,143.8,130.6,143.4z"/>
				</g>
				<g>
					<path class="st1511" d="M124.3,146.1c0,0,0.1,1.3-0.1,2.1c-0.2,1.1-0.1,2,1.1,2.3c1.6,0.3,1.8,0.9,2.1,1.4
						c0.3-1.3,0.1-2.1-0.4-2.7c-0.5-0.6-1.2-1-1.8-1.5C124.9,147.4,124.7,147.1,124.3,146.1z"/>
					<path class="st1511" d="M124.9,146.1c0,0.1,0.9,1,1.3,1.6c0.6,1,1.3,1.5,2.4,0.9c1.3-0.9,2-0.6,2.5-0.4
						c-1.5-2.2-2.8-1.6-4.4-1.5C126.2,146.7,125.8,146.6,124.9,146.1z"/>
				</g>
				<g>
					<path class="st1511" d="M118.4,147.9c-0.1,0-0.1,1.3-0.3,2c-0.4,1-0.4,2,0.8,2.4c1.5,0.5,1.7,1.2,1.8,1.7
						c0.5-1.2,0.4-2.1,0-2.7c-0.4-0.6-1-1.1-1.6-1.7C118.7,149.3,118.6,149,118.4,147.9z"/>
					<path class="st1511" d="M119,148c0,0,0.8,1.1,1.1,1.8c0.4,1,1.1,1.7,2.2,1.2c1.4-0.7,2-0.3,2.5-0.1c-1.1-2.4-2.6-2-4.2-2
						C120.1,148.8,119.7,148.7,119,148z"/>
				</g>
				<g>
					<path class="st1511" d="M112.2,149c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.6,1.9,0.5,2.5c1.4,0.7,1.5,1.4,1.6,1.9
						c0.7-1.2,0.6-2,0.4-2.7c-0.3-0.7-0.9-1.2-1.3-1.9C112.4,150.4,112.2,150,112.2,149z"/>
					<path class="st1511" d="M112.8,149.1c0,0,0.6,1.2,0.8,1.9c0.3,1.1,0.9,1.8,2.1,1.5c1.5-0.5,2.1-0.1,2.5,0.3
						c-0.8-2.5-2.3-2.3-3.8-2.6C113.8,150.1,113.4,149.9,112.8,149.1z"/>
				</g>
				<g>
					<path class="st1511" d="M105.9,149.1c-0.1,0-0.4,1.3-0.9,1.9c-0.6,0.9-0.9,1.8,0.1,2.5c1.3,0.9,1.3,1.6,1.3,2.1
						c0.8-1.1,0.9-1.9,0.7-2.6c-0.2-0.7-0.7-1.3-1.1-2C105.9,150.6,105.8,150.2,105.9,149.1z"/>
					<path class="st1511" d="M106.5,149.4c0,0,0.4,1.3,0.5,2c0.1,1.1,0.6,1.9,1.8,1.7c1.5-0.3,2,0.2,2.4,0.6c-0.5-2.6-2-2.6-3.5-3.1
						C107.4,150.4,107,150.2,106.5,149.4z"/>
				</g>
				<g>
					<path class="st1511" d="M99.7,148.4c-0.1,0-0.6,1.2-1.1,1.7c-0.8,0.8-1.1,1.7-0.2,2.5c1.2,1.1,1.1,1.7,1,2.3
						c1-0.9,1.2-1.7,1.1-2.5c-0.1-0.7-0.5-1.4-0.8-2.2C99.5,149.9,99.5,149.5,99.7,148.4z"/>
					<path class="st1511" d="M100.2,148.7c-0.1,0,0.3,1.3,0.3,2.1c0,1.1,0.4,2,1.6,2c1.6-0.1,2,0.5,2.3,0.9c-0.1-2.6-1.6-2.8-3-3.5
						C101,149.9,100.7,149.7,100.2,148.7z"/>
				</g>
				<g>
					<path class="st1511" d="M93.6,146.9c-0.1,0-0.8,1.1-1.3,1.6c-0.9,0.7-1.3,1.5-0.6,2.5c1,1.2,0.8,1.9,0.7,2.4
						c1.1-0.8,1.4-1.6,1.4-2.3c0-0.7-0.3-1.5-0.5-2.3C93.2,148.3,93.3,147.9,93.6,146.9z"/>
					<path class="st1511" d="M94.1,147.3c-0.1,0,0.1,1.3,0,2.1c-0.2,1.1,0.1,2,1.3,2.2c1.6,0.2,1.9,0.8,2.2,1.2
						c0.3-2.6-1.2-3-2.5-3.9C94.7,148.6,94.4,148.2,94.1,147.3z"/>
				</g>
				<g>
					<path class="st1511" d="M87.8,144.6c0,0-0.9,1-1.5,1.4c-0.9,0.6-1.5,1.3-0.9,2.4c0.8,1.3,0.6,2,0.4,2.5
						c1.2-0.6,1.6-1.4,1.7-2.1c0.1-0.7-0.1-1.5-0.2-2.3C87.2,145.9,87.3,145.5,87.8,144.6z"/>
					<path class="st1511" d="M88.2,145c-0.1,0-0.1,1.3-0.3,2c-0.3,1.1-0.2,2,1,2.3c1.5,0.4,1.8,1,2,1.5c0.6-2.6-0.8-3.2-1.9-4.2
						C88.6,146.4,88.4,146,88.2,145z"/>
				</g>
				<g>
					<path class="st1511" d="M82.4,141.5c0,0-1,0.8-1.7,1.1c-1,0.4-1.7,1.1-1.2,2.2c0.6,1.4,0.3,2,0,2.5c1.3-0.5,1.8-1.1,2-1.8
						c0.2-0.7,0.1-1.5,0.2-2.3C81.6,142.7,81.8,142.3,82.4,141.5z"/>
					<path class="st1511" d="M82.8,141.9c-0.1,0-0.3,1.3-0.6,2c-0.5,1-0.5,2,0.7,2.4c1.5,0.6,1.6,1.3,1.8,1.8c1-2.5-0.3-3.2-1.3-4.4
						C83,143.3,82.8,143,82.8,141.9z"/>
				</g>
				<g>
					<path class="st1511" d="M77.5,137.6c0,0-1.1,0.7-1.9,0.9c-1.1,0.3-1.8,0.9-1.5,2c0.4,1.5,0,2.1-0.3,2.5
						c1.3-0.3,1.9-0.9,2.2-1.6c0.3-0.7,0.3-1.5,0.5-2.3C76.5,138.7,76.7,138.4,77.5,137.6z"/>
					<path class="st1511" d="M77.7,138.2c-0.1,0-0.4,1.3-0.8,1.9c-0.6,0.9-0.7,1.9,0.3,2.5c1.4,0.8,1.4,1.5,1.5,2
						c1.3-2.3,0.1-3.2-0.7-4.6C77.7,139.6,77.6,139.2,77.7,138.2z"/>
				</g>
				<g>
					<path class="st1511" d="M73.1,133.2c0,0-1.2,0.5-2,0.6c-1.1,0.2-1.9,0.6-1.8,1.8c0.2,1.6-0.3,2-0.7,2.4c1.3-0.1,2-0.6,2.4-1.2
						c0.4-0.6,0.5-1.4,0.8-2.2C72,134.1,72.2,133.8,73.1,133.2z"/>
					<path class="st1511" d="M73.3,133.7c-0.1,0-0.6,1.2-1.1,1.8c-0.7,0.8-1,1.8,0,2.5c1.2,1,1.2,1.6,1.2,2.2
						c1.6-2.1,0.5-3.2-0.1-4.6C73.1,135.1,73,134.7,73.3,133.7z"/>
				</g>
				<g>
					<path class="st1511" d="M69.3,128.2c0,0-1.3,0.4-2,0.4c-1.1,0-2,0.3-2,1.6c0,1.6-0.5,2-1,2.3c1.3,0.1,2.1-0.3,2.5-0.9
						c0.5-0.6,0.7-1.3,1.1-2C68.1,129,68.4,128.7,69.3,128.2z"/>
					<path class="st1511" d="M69.4,128.7c-0.1,0-0.8,1.1-1.3,1.6c-0.8,0.7-1.2,1.6-0.4,2.5c1.1,1.1,1,1.8,0.9,2.3
						c1.9-1.9,1-3.1,0.5-4.6C69.1,130.1,69.1,129.7,69.4,128.7z"/>
				</g>
				<g>
					<path class="st1511" d="M66.3,122.7c0,0-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.1-2.2,1.3c-0.2,1.6-0.8,1.9-1.3,2.1
						c1.3,0.2,2.1-0.1,2.6-0.6c0.5-0.5,0.9-1.2,1.3-1.9C65,123.3,65.3,123.1,66.3,122.7z"/>
					<path class="st1511" d="M66.3,123.3c-0.1,0-0.9,1-1.5,1.4c-0.9,0.6-1.4,1.4-0.7,2.4c0.9,1.3,0.7,1.9,0.6,2.5
						c2.1-1.6,1.4-2.9,1.2-4.5C65.8,124.6,65.8,124.2,66.3,123.3z"/>
				</g>
				<g>
					<path class="st1511" d="M64,116.8c0-0.1-1.3,0-2.1-0.2c-1.1-0.3-2-0.2-2.3,1c-0.4,1.5-1,1.8-1.6,2c1.3,0.4,2.1,0.2,2.7-0.2
						c0.6-0.4,1-1.1,1.6-1.7C62.7,117.3,63,117.1,64,116.8z"/>
					<path class="st1511" d="M64,117.4c-0.1,0-1,0.8-1.7,1.2c-1,0.5-1.6,1.2-1,2.3c0.8,1.4,0.5,2,0.2,2.5c2.3-1.3,1.8-2.7,1.8-4.3
						C63.3,118.6,63.4,118.3,64,117.4z"/>
				</g>
				<g>
					<path class="st1511" d="M62.6,110.7c0-0.1-1.3-0.2-2-0.5c-1-0.4-2-0.5-2.5,0.6c-0.6,1.5-1.3,1.6-1.8,1.7c1.2,0.6,2,0.5,2.7,0.2
						c0.7-0.3,1.2-0.9,1.8-1.5C61.2,111,61.6,110.9,62.6,110.7z"/>
					<path class="st1511" d="M62.5,111.3c0,0-1.1,0.7-1.8,0.9c-1.1,0.4-1.8,1-1.3,2.2c0.6,1.5,0.2,2-0.1,2.5c2.4-1,2.1-2.5,2.3-4
						C61.6,112.4,61.8,112.1,62.5,111.3z"/>
				</g>
				<g>
					<path class="st1511" d="M62,104.5c0-0.1-1.3-0.4-1.9-0.7c-0.9-0.6-1.9-0.8-2.5,0.3c-0.8,1.4-1.5,1.4-2,1.5
						c1.1,0.8,1.9,0.8,2.6,0.5c0.7-0.2,1.3-0.8,2-1.2C60.6,104.6,61,104.5,62,104.5z"/>
					<path class="st1511" d="M61.8,105.1c0,0-1.2,0.5-2,0.7c-1.1,0.2-1.9,0.8-1.6,2c0.4,1.5-0.1,2.1-0.4,2.5
						c2.6-0.6,2.4-2.1,2.9-3.7C60.8,106,61,105.7,61.8,105.1z"/>
				</g>
				<g>
					<path class="st1511" d="M62.3,98.3c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-1-2.5,0c-1,1.2-1.7,1.2-2.2,1.2c1,0.9,1.8,1,2.5,0.9
						c0.7-0.1,1.4-0.6,2.1-0.9C60.8,98.1,61.2,98.1,62.3,98.3z"/>
					<path class="st1511" d="M62,98.8c0,0-1.3,0.4-2,0.4c-1.1,0.1-2,0.5-1.9,1.7c0.2,1.6-0.4,2-0.8,2.4c2.6-0.3,2.7-1.8,3.3-3.2
						C60.9,99.6,61.1,99.3,62,98.8z"/>
				</g>
				<g>
					<path class="st1511" d="M63.4,92.1c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.6-1.2-2.5-0.4c-1.1,1.1-1.8,1-2.4,0.9
						c0.9,1,1.7,1.3,2.4,1.2c0.7,0,1.4-0.4,2.2-0.6C62,91.8,62.4,91.8,63.4,92.1z"/>
					<path class="st1511" d="M63,92.6c0-0.1-1.3,0.2-2.1,0.1c-1.1-0.1-2,0.2-2.1,1.4c-0.1,1.6-0.6,2-1.1,2.3
						c2.6,0.1,2.9-1.4,3.7-2.8C61.8,93.2,62.1,93,63,92.6z"/>
				</g>
				<g>
					<path class="st1511" d="M65.3,86.1c0-0.1-1-0.8-1.5-1.5c-0.6-0.9-1.4-1.4-2.4-0.7c-1.3,0.9-1.9,0.7-2.5,0.5
						c0.7,1.1,1.5,1.5,2.2,1.5c0.7,0.1,1.5-0.2,2.3-0.3C64,85.6,64.4,85.7,65.3,86.1z"/>
					<path class="st1511" d="M64.9,86.6c0-0.1-1.3,0-2.1-0.2c-1.1-0.2-2-0.1-2.3,1.2c-0.3,1.5-0.9,1.9-1.4,2.1
						c2.6,0.4,3.1-1,4.1-2.2C63.6,87.1,63.9,86.8,64.9,86.6z"/>
				</g>
				<g>
					<path class="st1511" d="M68.1,80.5c0,0-0.9-1-1.3-1.6c-0.5-1-1.2-1.6-2.3-1.1c-1.4,0.7-2,0.4-2.5,0.2c0.6,1.2,1.3,1.7,2,1.8
						c0.7,0.2,1.5,0,2.3,0C66.8,79.8,67.2,80,68.1,80.5z"/>
					<path class="st1511" d="M67.6,80.9c0-0.1-1.3-0.2-2-0.4c-1-0.4-2-0.3-2.4,0.8c-0.5,1.5-1.1,1.7-1.7,1.9
						c2.5,0.8,3.2-0.6,4.3-1.6C66.2,81.2,66.6,81,67.6,80.9z"/>
				</g>
				<g>
					<path class="st1511" d="M71.5,75.3c0,0-0.8-1.1-1-1.8c-0.4-1-1-1.8-2.1-1.4c-1.5,0.6-2.1,0.2-2.5-0.2c0.4,1.3,1,1.8,1.7,2.1
						c0.7,0.3,1.5,0.2,2.3,0.3C70.4,74.5,70.7,74.6,71.5,75.3z"/>
					<path class="st1511" d="M71,75.6c0-0.1-1.3-0.4-1.9-0.7c-1-0.5-1.9-0.6-2.5,0.5c-0.7,1.4-1.4,1.5-1.9,1.6
						c2.4,1.1,3.2-0.1,4.5-1C69.6,75.7,70,75.6,71,75.6z"/>
				</g>
				<g>
					<path class="st1511" d="M75.7,70.6c0,0-0.6-1.2-0.8-1.9c-0.2-1.1-0.7-1.9-1.9-1.6c-1.5,0.3-2.1-0.1-2.5-0.5
						c0.2,1.3,0.8,1.9,1.4,2.3c0.7,0.3,1.4,0.4,2.2,0.6C74.6,69.6,75,69.9,75.7,70.6z"/>
					<path class="st1511" d="M75.1,70.9c0-0.1-1.2-0.5-1.8-1c-0.9-0.7-1.8-0.9-2.5,0.2c-0.9,1.3-1.6,1.3-2.1,1.4
						c2.2,1.4,3.2,0.3,4.6-0.4C73.7,70.8,74.1,70.7,75.1,70.9z"/>
				</g>
				<g>
					<path class="st1511" d="M80.4,66.5c0,0-0.5-1.3-0.5-2c-0.1-1.1-0.5-2-1.7-1.9c-1.6,0.1-2-0.4-2.4-0.8c0,1.3,0.5,2,1.1,2.5
						c0.6,0.4,1.4,0.6,2.1,0.9C79.5,65.4,79.9,65.7,80.4,66.5z"/>
					<path class="st1511" d="M79.9,66.7c0-0.1-1.1-0.7-1.7-1.2c-0.8-0.8-1.7-1.1-2.5-0.2c-1,1.2-1.7,1.1-2.3,1.1
						c2,1.7,3.2,0.8,4.6,0.2C78.5,66.4,78.9,66.4,79.9,66.7z"/>
				</g>
				<g>
					<path class="st1511" d="M85.7,63.2c0,0-0.3-1.3-0.2-2c0.1-1.1-0.2-2-1.4-2.1c-1.6-0.1-2-0.7-2.2-1.1c-0.2,1.3,0.2,2.1,0.7,2.6
						c0.5,0.5,1.3,0.8,2,1.2C85,61.9,85.2,62.2,85.7,63.2z"/>
					<path class="st1511" d="M85.1,63.2c0-0.1-1-0.8-1.5-1.4c-0.7-0.9-1.5-1.3-2.5-0.5c-1.2,1-1.9,0.9-2.4,0.8c1.7,2,3,1.2,4.6,0.8
						C83.8,62.8,84.2,62.8,85.1,63.2z"/>
				</g>
				<g>
					<path class="st1511" d="M91.4,60.5c0,0-0.1-1.3,0.1-2.1c0.2-1.1,0.1-2-1.1-2.3c-1.6-0.3-1.8-0.9-2.1-1.4
						c-0.3,1.3-0.1,2.1,0.4,2.7c0.5,0.6,1.2,1,1.8,1.5C90.8,59.2,91,59.5,91.4,60.5z"/>
					<path class="st1511" d="M90.8,60.5c0-0.1-0.9-1-1.3-1.6c-0.6-1-1.3-1.5-2.4-0.9c-1.3,0.9-2,0.6-2.5,0.4
						c1.5,2.2,2.8,1.6,4.4,1.5C89.5,59.9,89.9,60,90.8,60.5z"/>
				</g>
				<g>
					<path class="st1511" d="M97.3,58.7c0.1,0,0.1-1.3,0.3-2c0.4-1,0.4-2-0.8-2.4c-1.5-0.5-1.7-1.2-1.8-1.7c-0.5,1.2-0.4,2.1,0,2.7
						c0.4,0.6,1,1.1,1.6,1.7C97,57.3,97.2,57.6,97.3,58.7z"/>
					<path class="st1511" d="M96.8,58.6c0,0-0.8-1.1-1.1-1.8c-0.4-1-1.1-1.7-2.2-1.2c-1.4,0.7-2,0.3-2.5,0.1c1.1,2.4,2.6,2,4.2,2
						C95.6,57.8,96,57.9,96.8,58.6z"/>
				</g>
				<g>
					<path class="st1511" d="M103.5,57.7c0.1,0,0.3-1.3,0.6-2c0.5-1,0.6-1.9-0.5-2.5c-1.4-0.7-1.5-1.4-1.6-1.9
						c-0.7,1.2-0.6,2-0.4,2.7c0.3,0.7,0.9,1.2,1.3,1.9C103.3,56.2,103.5,56.6,103.5,57.7z"/>
					<path class="st1511" d="M102.9,57.5c0,0-0.6-1.2-0.8-1.9c-0.3-1.1-0.9-1.8-2.1-1.5c-1.5,0.5-2.1,0.1-2.5-0.3
						c0.8,2.5,2.3,2.3,3.8,2.6C101.9,56.5,102.3,56.7,102.9,57.5z"/>
				</g>
				<g>
					<path class="st1511" d="M109.8,57.5c0.1,0,0.4-1.3,0.9-1.9c0.6-0.9,0.9-1.8-0.1-2.5c-1.3-0.9-1.3-1.6-1.3-2.1
						c-0.8,1.1-0.9,1.9-0.7,2.6c0.2,0.7,0.7,1.3,1.1,2C109.8,56,109.9,56.5,109.8,57.5z"/>
					<path class="st1511" d="M109.2,57.3c0,0-0.4-1.3-0.5-2c-0.1-1.1-0.6-1.9-1.8-1.7c-1.5,0.3-2-0.2-2.4-0.6c0.5,2.6,2,2.6,3.5,3.1
						C108.3,56.2,108.7,56.4,109.2,57.3z"/>
				</g>
				<g>
					<path class="st1511" d="M116,58.2c0.1,0,0.6-1.2,1.1-1.7c0.8-0.8,1.1-1.7,0.2-2.5c-1.2-1.1-1.1-1.7-1-2.3
						c-1,0.9-1.2,1.7-1.1,2.5c0.1,0.7,0.5,1.4,0.8,2.2C116.2,56.7,116.2,57.2,116,58.2z"/>
					<path class="st1511" d="M115.5,57.9c0.1,0-0.3-1.3-0.3-2.1c0-1.1-0.4-2-1.6-2c-1.6,0.1-2-0.5-2.3-0.9c0.1,2.6,1.6,2.8,3,3.5
						C114.8,56.7,115,57,115.5,57.9z"/>
				</g>
				<g>
					<path class="st1511" d="M122.1,59.7c0.1,0,0.8-1.1,1.3-1.6c0.9-0.7,1.3-1.5,0.6-2.5c-1-1.2-0.8-1.9-0.7-2.4
						c-1.1,0.8-1.4,1.6-1.4,2.3c0,0.7,0.3,1.5,0.5,2.3C122.5,58.3,122.4,58.7,122.1,59.7z"/>
					<path class="st1511" d="M121.6,59.3c0.1,0-0.1-1.3,0-2.1c0.2-1.1-0.1-2-1.3-2.2c-1.6-0.2-1.9-0.8-2.2-1.2
						c-0.3,2.6,1.2,3,2.5,3.9C121,58,121.3,58.4,121.6,59.3z"/>
				</g>
				<g>
					<path class="st1511" d="M127.9,62c0,0,0.9-1,1.5-1.4c0.9-0.6,1.5-1.3,0.9-2.4c-0.8-1.3-0.6-2-0.4-2.5c-1.2,0.6-1.6,1.4-1.7,2.1
						c-0.1,0.7,0.1,1.5,0.2,2.3C128.5,60.7,128.4,61.1,127.9,62z"/>
					<path class="st1511" d="M127.5,61.6c0.1,0,0.1-1.3,0.3-2c0.3-1.1,0.2-2-1-2.3c-1.5-0.4-1.8-1-2-1.5c-0.6,2.6,0.8,3.2,1.9,4.2
						C127.1,60.3,127.3,60.6,127.5,61.6z"/>
				</g>
				<g>
					<path class="st1511" d="M133.3,65.2c0,0,1-0.8,1.7-1.1c1-0.4,1.7-1.1,1.2-2.2c-0.6-1.4-0.3-2,0-2.5c-1.3,0.5-1.8,1.1-2,1.8
						c-0.2,0.7-0.1,1.5-0.2,2.3C134.1,63.9,133.9,64.3,133.3,65.2z"/>
					<path class="st1511" d="M133,64.7c0.1,0,0.3-1.3,0.6-2c0.5-1,0.5-2-0.7-2.4c-1.5-0.6-1.6-1.3-1.8-1.8c-1,2.5,0.3,3.2,1.3,4.4
						C132.8,63.3,132.9,63.7,133,64.7z"/>
				</g>
				<g>
					<path class="st1511" d="M138.3,69c0,0,1.1-0.7,1.9-0.9c1.1-0.3,1.8-0.9,1.5-2c-0.4-1.5,0-2.1,0.3-2.5c-1.3,0.3-1.9,0.9-2.2,1.6
						c-0.3,0.7-0.3,1.5-0.5,2.3C139.2,67.9,139,68.2,138.3,69z"/>
					<path class="st1511" d="M138,68.5c0.1,0,0.4-1.3,0.8-1.9c0.6-0.9,0.7-1.9-0.3-2.5c-1.4-0.8-1.4-1.5-1.5-2
						c-1.3,2.3-0.1,3.2,0.7,4.6C138,67,138.1,67.4,138,68.5z"/>
				</g>
				<g>
					<path class="st1511" d="M142.7,73.4c0,0,1.2-0.5,2-0.6c1.1-0.2,1.9-0.6,1.8-1.8c-0.2-1.6,0.3-2,0.7-2.4c-1.3,0.1-2,0.6-2.4,1.2
						c-0.4,0.6-0.5,1.4-0.8,2.2C143.7,72.5,143.5,72.8,142.7,73.4z"/>
					<path class="st1511" d="M142.5,72.9c0.1,0,0.6-1.2,1.1-1.8c0.7-0.8,1-1.8,0-2.5c-1.2-1-1.2-1.6-1.2-2.2
						c-1.6,2.1-0.5,3.2,0.1,4.6C142.6,71.5,142.7,71.9,142.5,72.9z"/>
				</g>
				<g>
					<path class="st1511" d="M146.4,78.5c0,0,1.3-0.4,2-0.4c1.1,0,2-0.3,2-1.6c0-1.6,0.5-2,1-2.3c-1.3-0.1-2.1,0.3-2.5,0.9
						c-0.5,0.6-0.7,1.3-1.1,2C147.6,77.6,147.3,77.9,146.4,78.5z"/>
					<path class="st1511" d="M146.3,77.9c0.1,0,0.8-1.1,1.3-1.6c0.8-0.7,1.2-1.6,0.4-2.5c-1.1-1.1-1-1.8-0.9-2.3
						c-1.9,1.9-1,3.1-0.5,4.6C146.6,76.5,146.6,76.9,146.3,77.9z"/>
				</g>
				<g>
					<path class="st1511" d="M149.4,83.9c0,0,1.3-0.2,2.1-0.1c1.1,0.1,2-0.1,2.2-1.3c0.2-1.6,0.8-1.9,1.3-2.1
						c-1.3-0.2-2.1,0.1-2.6,0.6c-0.5,0.5-0.9,1.2-1.3,1.9C150.7,83.3,150.4,83.5,149.4,83.9z"/>
					<path class="st1511" d="M149.4,83.3c0.1,0,0.9-1,1.5-1.4c0.9-0.6,1.4-1.4,0.7-2.4c-0.9-1.3-0.7-1.9-0.6-2.5
						c-2.1,1.6-1.4,2.9-1.2,4.5C149.9,82,149.9,82.4,149.4,83.3z"/>
				</g>
				<g>
					<path class="st1511" d="M151.7,89.8c0,0.1,1.3,0,2.1,0.2c1.1,0.3,2,0.2,2.3-1c0.4-1.5,1-1.8,1.6-2c-1.3-0.4-2.1-0.2-2.7,0.2
						c-0.6,0.4-1,1.1-1.6,1.7C153.1,89.3,152.7,89.5,151.7,89.8z"/>
					<path class="st1511" d="M151.7,89.2c0.1,0,1-0.8,1.7-1.2c1-0.5,1.6-1.2,1-2.3c-0.8-1.4-0.5-2-0.2-2.5c-2.3,1.3-1.8,2.7-1.8,4.3
						C152.4,88,152.3,88.4,151.7,89.2z"/>
				</g>
				<g>
					<path class="st1511" d="M153.1,95.9c0,0.1,1.3,0.2,2,0.5c1,0.4,2,0.5,2.5-0.6c0.6-1.5,1.3-1.6,1.8-1.7c-1.2-0.6-2-0.5-2.7-0.2
						c-0.7,0.3-1.2,0.9-1.8,1.5C154.5,95.6,154.1,95.7,153.1,95.9z"/>
					<path class="st1511" d="M153.2,95.3c0,0,1.1-0.7,1.8-0.9c1.1-0.4,1.8-1,1.3-2.2c-0.6-1.5-0.2-2,0.1-2.5c-2.4,1-2.1,2.5-2.3,4
						C154.1,94.2,153.9,94.6,153.2,95.3z"/>
				</g>
				<g>
					<path class="st1511" d="M153.7,102.1c0,0.1,1.3,0.4,1.9,0.7c0.9,0.6,1.9,0.8,2.5-0.3c0.8-1.4,1.5-1.4,2-1.5
						c-1.1-0.8-1.9-0.8-2.6-0.5c-0.7,0.2-1.3,0.8-2,1.2C155.1,102,154.7,102.1,153.7,102.1z"/>
					<path class="st1511" d="M153.9,101.5c0,0,1.2-0.5,2-0.7c1.1-0.2,1.9-0.8,1.6-2c-0.4-1.5,0.1-2.1,0.4-2.5
						c-2.6,0.6-2.4,2.1-2.9,3.7C154.9,100.6,154.7,100.9,153.9,101.5z"/>
				</g>
			</g>
		</g>
		<g>
			<path d="M62.7,100.2l0.1-1l4.5,0.4l-0.1,1L62.7,100.2z"/>
			<path d="M63.6,93.7l4.4,0.8l-0.1,0.8L64.8,97l2.7,0.5l-0.2,1l-4.4-0.8l0.1-0.9l3.1-1.7l-2.7-0.5L63.6,93.7z"/>
			<path d="M65.1,91.6L64.7,93l-0.8-0.2l1.1-3.8l0.8,0.2l-0.4,1.4l3.5,1l-0.3,1L65.1,91.6z"/>
			<path d="M70,86.2l0.8,0.3l-1.3,3.2l-4.2-1.7l1.3-3.2l0.8,0.3l-0.9,2.2l0.9,0.4l0.8-1.9l0.8,0.3l-0.8,1.9l1,0.4L70,86.2z"/>
			<path d="M72.4,83.4l-1.5,0.2l0,0l-0.4,0.8l1.1,0.6L71,85.9l-4-2.1L68,82c0.2-0.4,0.4-0.6,0.7-0.8s0.5-0.3,0.8-0.3
				c0.3,0,0.6,0,0.9,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.1,0.3,0.2,0.5,0.2,0.9l1.8-0.2L72.4,83.4z M69.2,82c-0.2,0.1-0.4,0.3-0.5,0.5
				l-0.4,0.7l1.4,0.7l0.4-0.8c0.1-0.3,0.2-0.5,0.2-0.7s-0.2-0.4-0.4-0.5C69.6,81.9,69.4,81.9,69.2,82z"/>
			<path d="M71.9,75.9l3.7,2.6l-0.5,0.7l-3.5,0.3l2.2,1.6l-0.6,0.8l-3.7-2.6l0.5-0.7l3.5-0.3l-2.2-1.6L71.9,75.9z"/>
			<path d="M77.5,74.9l-1.4,1.6l0.5,0.9l-0.7,0.8l-2-4.5l0.7-0.8l4.7,1.5l-0.7,0.8L77.5,74.9z M76.7,74.6l-1.8-0.6l0.8,1.7
				L76.7,74.6z"/>
			<path d="M77.6,71l-1.1,1l-0.6-0.6l2.9-2.6l0.6,0.6l-1.1,1l2.5,2.7L80,73.7L77.6,71z"/>
			<path d="M79.5,68.2l0.8-0.6l2.8,3.5l-0.8,0.6L79.5,68.2z"/>
			<path d="M84.5,69.8c-0.4,0-0.8,0-1.2-0.2c-0.4-0.2-0.7-0.5-0.9-0.8C82.1,68.4,82,68,82,67.6c0-0.4,0.1-0.8,0.3-1.2
				c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.3,0.8-0.4,1.2-0.4c0.4,0,0.8,0,1.2,0.2c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.4,0.4,0.8,0.4,1.2
				c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.4-0.5,0.7-0.9,0.9C85.3,69.6,84.9,69.8,84.5,69.8z M85.7,68.1c0.1-0.2,0.2-0.5,0.1-0.7
				c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				C83,67,83,67.2,83,67.4c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.5-0.1,0.7-0.2
				C85.5,68.5,85.6,68.3,85.7,68.1z"/>
			<path d="M90.1,61.8l2,4.1l-0.8,0.4l-3.2-1.5l1.2,2.5l-0.9,0.4l-2-4.1l0.8-0.4l3.2,1.5l-1.2-2.5L90.1,61.8z"/>
			<path d="M95.4,63.6l-2,0.7l-0.1,1l-1,0.3l0.5-4.9l1-0.3l3.4,3.6l-1,0.4L95.4,63.6z M94.9,63l-1.2-1.4l-0.1,1.9L94.9,63z"/>
			<path d="M96.6,59.6l1-0.2l0.8,3.6l2.2-0.5l0.2,0.8L97.6,64L96.6,59.6z"/>
			<path d="M105.9,61.8l-2.1,0.1l-0.3,1l-1.1,0.1l1.7-4.6l1-0.1l2.3,4.4l-1.1,0.1L105.9,61.8z M105.5,61l-0.8-1.7l-0.6,1.8L105.5,61
				z"/>
			<path d="M107.9,58.2l1,0l-0.1,4.5l-1,0L107.9,58.2z"/>
			<path d="M112.3,58.4l2,0.3c0.5,0.1,0.9,0.2,1.2,0.5c0.3,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.2,0.8,0.1,1.2c-0.1,0.4-0.2,0.8-0.5,1.1
				c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.1-0.8,0.2-1.3,0.1l-2-0.3L112.3,58.4z M113.8,62.3c0.4,0.1,0.8,0,1.1-0.2c0.3-0.2,0.5-0.5,0.5-1
				c0.1-0.4,0-0.8-0.3-1.1c-0.2-0.3-0.6-0.5-1-0.5l-0.9-0.1l-0.4,2.8L113.8,62.3z"/>
			<path d="M119.6,64.5l-0.5-1.4l0,0l-0.9-0.2l-0.3,1.2l-1-0.3l1.2-4.4l1.9,0.5c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.4,0.4,0.5,0.7
				c0.1,0.3,0.1,0.6,0,0.9s-0.2,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.4l0.6,1.7L119.6,64.5z M120.2,61c-0.1-0.2-0.3-0.3-0.6-0.4
				l-0.8-0.2l-0.4,1.5l0.8,0.2c0.3,0.1,0.6,0.1,0.7,0c0.2-0.1,0.3-0.3,0.4-0.5C120.4,61.4,120.3,61.2,120.2,61z"/>
			<path d="M122.8,60.8l1,0.4l-1.5,4.2l-1-0.4L122.8,60.8z"/>
			<path d="M129,63.4l-3.7,3.3l-0.9-0.4l0.1-4.9l1,0.5l-0.1,3.5L128,63L129,63.4z"/>
			<path d="M129.5,63.8l0.9,0.5l-2.2,3.9l-0.9-0.5L129.5,63.8z"/>
			<path d="M135.1,67.3l-2.5,3.7l-0.7-0.5l-0.3-3.5l-1.5,2.3l-0.9-0.6l2.5-3.7l0.7,0.5l0.3,3.5l1.5-2.3L135.1,67.3z"/>
			<path d="M137.1,71.9l0.7,0.6l-1.2,1.4c-0.3,0-0.6-0.1-0.9-0.2s-0.6-0.3-0.8-0.5c-0.4-0.3-0.6-0.7-0.8-1.1
				c-0.1-0.4-0.2-0.8-0.1-1.2s0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.6,1-0.7c0.4-0.1,0.8-0.1,1.2,0c0.4,0.1,0.8,0.3,1.2,0.6
				c0.3,0.3,0.5,0.5,0.7,0.9c0.2,0.3,0.2,0.6,0.2,1l-0.9,0c0-0.5-0.2-0.9-0.5-1.2c-0.2-0.2-0.4-0.3-0.7-0.4c-0.2-0.1-0.5,0-0.7,0
				s-0.5,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.2,0.5,0.3,0.7,0.4L137.1,71.9
				z"/>
			<path d="M141.5,73.3l0.7,0.8l-2.8,2.4l1.5,1.7l-0.7,0.5l-2.1-2.5L141.5,73.3z"/>
			<path d="M144.1,76.5l0.6,0.8l-3.6,2.7l-0.6-0.8L144.1,76.5z"/>
			<path d="M142.3,81.4c0-0.4,0.1-0.8,0.3-1.2s0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.4,1.2-0.4c0.4,0,0.8,0.1,1.2,0.3
				c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.3,0.3,0.7,0.4,1c0,0.3,0,0.7-0.1,1l-0.9-0.3c0.1-0.4,0.1-0.9-0.1-1.2c-0.1-0.2-0.3-0.4-0.5-0.5
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.4,0.6,0.6,1,0.7l-0.2,0.9c-0.3,0-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.5-0.7-0.8C142.5,82.2,142.4,81.8,142.3,81.4z"/>
			<path d="M146.3,87.5l-0.8,0.4l-1.5-3.2l4.1-1.9l1.4,3.1l-0.8,0.4l-1-2.1l-0.9,0.4l0.9,1.9l-0.7,0.3l-0.9-1.9l-0.9,0.4L146.3,87.5
				z"/>
			<path d="M151.4,91l-4.3,1.4l-0.3-0.8l1.9-3l-2.6,0.9l-0.3-1l4.3-1.4l0.3,0.8l-1.9,3l2.6-0.9L151.4,91z"/>
			<path d="M147.5,93.8c0-0.3,0.1-0.6,0.2-0.9l0.8,0.2c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3,0,0.5,0,0.8c0.1,0.3,0.2,0.5,0.3,0.6
				c0.1,0.1,0.2,0.2,0.4,0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.6c0-0.4,0-0.6,0.1-0.9
				c0-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.5,0,0.7,0.1c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.3,0.3,0.6,0.4,1
				c0.1,0.3,0.1,0.6,0.1,0.8s-0.1,0.5-0.1,0.8l-0.8-0.1c0.1-0.5,0.2-0.9,0.1-1.3c-0.1-0.3-0.2-0.5-0.3-0.6c-0.1-0.1-0.3-0.2-0.4-0.1
				c-0.2,0-0.2,0.1-0.3,0.3c0,0.2-0.1,0.4-0.1,0.8c0,0.4,0,0.6-0.1,0.9c0,0.2-0.1,0.4-0.3,0.6c-0.1,0.2-0.4,0.3-0.7,0.4
				c-0.3,0.1-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.3-0.3-0.6-0.4-1C147.5,94.5,147.5,94.2,147.5,93.8z"/>
			<path d="M149.4,100.7l-0.8,0.1l-0.4-3.5l4.5-0.5l0.4,3.4l-0.8,0.1l-0.3-2.4l-1,0.1l0.2,2.1l-0.8,0.1l-0.2-2.1l-1,0.1L149.4,100.7
				z"/>
		</g>
		<g>
			<path d="M79.5,88.9h3.3v14.4h-3.3V88.9z"/>
			<path d="M95.7,100.2H89l-1.3,3.1h-3.4l6.4-14.4H94l6.4,14.4H97L95.7,100.2z M94.7,97.7l-2.3-5.5l-2.3,5.5H94.7z"/>
			<path d="M102,88.9h3.3v14.4H102V88.9z"/>
			<path d="M108.7,88.9h6.5c1.6,0,2.9,0.3,4.1,0.9c1.2,0.6,2.1,1.4,2.8,2.5c0.7,1.1,1,2.3,1,3.8c0,1.4-0.3,2.7-1,3.8
				c-0.7,1.1-1.6,1.9-2.8,2.5c-1.2,0.6-2.6,0.9-4.1,0.9h-6.5V88.9z M115.1,100.6c1.4,0,2.6-0.4,3.4-1.2c0.9-0.8,1.3-1.9,1.3-3.3
				s-0.4-2.5-1.3-3.3c-0.9-0.8-2-1.2-3.4-1.2h-3v8.9H115.1z"/>
			<path d="M125.7,88.9h3.3v11.7h7.2v2.7h-10.5V88.9z"/>
		</g>
		<polygon points="107.9,68.3 109.8,72.3 114.3,72.9 111.1,76.1 111.8,80.5 107.9,78.4 103.9,80.5 104.6,76.1 101.4,72.9 
			105.9,72.3 		"/>
		<g>
			<g>
				<polygon points="118.5,76.9 119.8,79.4 122.5,79.8 120.5,81.7 121,84.4 118.5,83.1 116.1,84.4 116.6,81.7 114.6,79.8 
					117.3,79.4 				"/>
				<polygon points="97.2,76.9 98.4,79.4 101.1,79.8 99.1,81.7 99.6,84.4 97.2,83.1 94.7,84.4 95.2,81.7 93.2,79.8 95.9,79.4 				
					"/>
			</g>
		</g>
		<text transform="matrix(1 0 0 1 73.1289 134.6962)" class="st1512 st1513">{{ token }}</text>
		<g>
			<path d="M82.4,120.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L82.4,120.7z M82.1,119.9l-0.5-1.3l-0.5,1.3H82.1z"/>
			<path d="M85.1,121.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
				c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
				c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6s-0.6,0.2-1,0.2C85.8,121.6,85.4,121.5,85.1,121.3z"/>
			<path d="M89.3,121.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1c0.2-0.3,0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.3,0.7,0.6l-0.7,0.7c-0.3-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0-0.6,0.1
				c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1
				c0.4,0,0.7-0.2,1-0.5l0.7,0.7c-0.2,0.2-0.4,0.4-0.7,0.6s-0.6,0.2-1,0.2C90,121.6,89.7,121.5,89.3,121.3z"/>
			<path d="M94.5,120.4h-0.6v1.1h-1.2v-4.1h1.9c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.6,0.5s0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8
				s-0.3,0.4-0.6,0.5l0.9,1.3h-1.2L94.5,120.4z M95.1,118.5c-0.1-0.1-0.3-0.2-0.5-0.2h-0.6v1.2h0.6c0.2,0,0.4-0.1,0.5-0.2
				c0.1-0.1,0.2-0.3,0.2-0.4S95.2,118.6,95.1,118.5z"/>
			<path d="M100.3,120.6v0.9h-3.3v-4.1h3.2v0.9h-2.1v0.7h1.8v0.9h-1.8v0.7H100.3z"/>
			<path d="M101,117.4h1.9c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.7s0.3,0.7,0.3,1.1s-0.1,0.8-0.3,1.1s-0.5,0.5-0.8,0.7
				c-0.3,0.2-0.7,0.3-1.2,0.3H101V117.4z M102.9,120.6c0.4,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				c-0.2-0.2-0.5-0.3-0.8-0.3h-0.7v2.2H102.9z"/>
			<path d="M105.8,117.4h1.2v4.1h-1.2V117.4z"/>
			<path d="M108.6,118.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V118.3z"/>
			<path d="M114,120.7h-1.7l-0.3,0.8h-1.2l1.8-4.1h1.1l1.8,4.1h-1.2L114,120.7z M113.7,119.9l-0.5-1.3l-0.5,1.3H113.7z"/>
			<path d="M116.5,118.3h-1.3v-0.9h3.7v0.9h-1.2v3.2h-1.2V118.3z"/>
			<path d="M119.4,117.4h1.2v4.1h-1.2V117.4z"/>
			<path d="M122.2,121.3c-0.3-0.2-0.6-0.4-0.8-0.8s-0.3-0.7-0.3-1.1s0.1-0.8,0.3-1.1s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3
				s0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1s-0.1,0.8-0.3,1.1s-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.2,0.3
				S122.6,121.5,122.2,121.3z M123.9,120.5c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6
				s0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1S123.8,120.6,123.9,120.5z"/>
			<path d="M130.1,117.4v4.1h-1l-1.8-2.2v2.2h-1.1v-4.1h1l1.8,2.2v-2.2H130.1z"/>
			<path d="M135.6,119.1l-0.1,0.8h0.7v0.8h-0.8l-0.1,0.9h-0.8l0.1-0.9h-0.7l-0.1,0.9H133l0.1-0.9h-0.7v-0.8h0.8l0.1-0.8h-0.7v-0.8
				h0.8l0.1-0.9h0.8l-0.1,0.9h0.7l0.1-0.9h0.8l-0.1,0.9h0.7v0.8H135.6z M134.8,119.1h-0.7l-0.1,0.8h0.7L134.8,119.1z"/>
		</g>
	</g>
	<g class="st1505">
		<text :transform="`matrix(1 0 0 1 ${locationLeft} 452.2593)`" class="st1514 st1515" v-if="location">{{location.toUpperCase()}}</text>
	</g>
	<g class="st1505">
		<text transform="matrix(1 0 0 1 110 529.0226)" class="st1514 st1516" v-if="discipline">{{ discipline.toUpperCase() }}</text>
	</g>
	<g class="st1505">
		<path d="M107.3,239.9c-1.3-0.7-2.4-1.8-3.2-3.1c-0.8-1.3-1.1-2.8-1.1-4.4s0.4-3.1,1.1-4.4c0.8-1.3,1.8-2.3,3.2-3.1
			c1.4-0.7,2.9-1.1,4.5-1.1c1.3,0,2.4,0.2,3.5,0.6c1.1,0.4,2,1,2.7,1.8l-1.1,1.1c-1.3-1.3-3-2-5-2c-1.3,0-2.6,0.3-3.7,0.9
			c-1.1,0.6-2,1.5-2.6,2.5c-0.6,1.1-0.9,2.3-0.9,3.6s0.3,2.5,0.9,3.6c0.6,1.1,1.5,1.9,2.6,2.5c1.1,0.6,2.3,0.9,3.7,0.9
			c2,0,3.7-0.7,5-2l1.1,1.1c-0.7,0.8-1.6,1.4-2.7,1.9c-1.1,0.4-2.2,0.6-3.5,0.6C110.2,241,108.7,240.6,107.3,239.9z"/>
		<path d="M133.9,239.3v1.5h-12v-16.9h11.6v1.5h-9.8v6h8.8v1.5h-8.8v6.3H133.9z"/>
		<path d="M149.6,240.8l-3.9-5.4c-0.4,0-0.9,0.1-1.4,0.1h-4.5v5.4h-1.8v-16.9h6.3c2.2,0,3.9,0.5,5.1,1.5c1.2,1,1.8,2.5,1.8,4.3
			c0,1.3-0.3,2.4-1,3.4c-0.7,0.9-1.6,1.6-2.9,2l4.1,5.8H149.6z M148.2,232.8c0.9-0.7,1.3-1.8,1.3-3.1c0-1.4-0.4-2.4-1.3-3.2
			c-0.9-0.7-2.2-1.1-3.8-1.1h-4.5v8.5h4.5C146,233.9,147.3,233.6,148.2,232.8z"/>
		<path d="M158.6,225.4h-6v-1.5h13.7v1.5h-6v15.4h-1.8V225.4z"/>
		<path d="M171,225.4v6.7h8.8v1.5H171v7.2h-1.8v-16.9h11.6v1.5H171z"/>
		<path d="M184.5,223.9h1.8v16.9h-1.8V223.9z"/>
		<path d="M194.7,239.9c-1.3-0.7-2.4-1.8-3.2-3.1c-0.8-1.3-1.1-2.8-1.1-4.4s0.4-3.1,1.1-4.4c0.8-1.3,1.8-2.3,3.2-3.1
			c1.4-0.7,2.9-1.1,4.5-1.1c1.3,0,2.4,0.2,3.5,0.6c1.1,0.4,2,1,2.7,1.8l-1.1,1.1c-1.3-1.3-3-2-5-2c-1.3,0-2.6,0.3-3.7,0.9
			c-1.1,0.6-2,1.5-2.6,2.5c-0.6,1.1-0.9,2.3-0.9,3.6s0.3,2.5,0.9,3.6c0.6,1.1,1.5,1.9,2.6,2.5c1.1,0.6,2.3,0.9,3.7,0.9
			c2,0,3.7-0.7,5-2l1.1,1.1c-0.7,0.8-1.6,1.4-2.7,1.9c-1.1,0.4-2.2,0.6-3.5,0.6C197.5,241,196,240.6,194.7,239.9z"/>
		<path d="M219.4,236.3H210l-2,4.5h-1.9l7.7-16.9h1.8l7.7,16.9h-1.9L219.4,236.3z M218.8,234.9l-4.1-9.1l-4.1,9.1H218.8z"/>
		<path d="M228.6,225.4h-6v-1.5h13.7v1.5h-6v15.4h-1.8V225.4z"/>
		<path d="M251.2,239.3v1.5h-12v-16.9h11.6v1.5H241v6h8.8v1.5H241v6.3H251.2z"/>
		<path d="M262.3,240.2c-0.8-0.4-1.4-1.1-1.9-1.8c-0.5-0.8-0.7-1.7-0.7-2.6s0.2-1.8,0.7-2.6c0.5-0.8,1.1-1.4,1.9-1.8
			c0.8-0.4,1.7-0.7,2.7-0.7s1.9,0.2,2.7,0.7c0.8,0.4,1.4,1.1,1.9,1.8s0.7,1.7,0.7,2.6s-0.2,1.8-0.7,2.6s-1.1,1.4-1.9,1.8
			c-0.8,0.4-1.7,0.7-2.7,0.7S263.1,240.7,262.3,240.2z M267.2,239.4c0.6-0.4,1.1-0.9,1.5-1.5s0.5-1.4,0.5-2.1s-0.2-1.5-0.5-2.1
			c-0.4-0.6-0.9-1.1-1.5-1.5c-0.6-0.4-1.4-0.5-2.2-0.5c-0.8,0-1.5,0.2-2.2,0.5c-0.6,0.4-1.2,0.9-1.5,1.5c-0.4,0.6-0.6,1.4-0.6,2.1
			s0.2,1.5,0.6,2.1s0.9,1.1,1.5,1.5c0.6,0.4,1.4,0.5,2.2,0.5C265.8,240,266.5,239.8,267.2,239.4z"/>
		<path d="M273.8,231.7v4h5.2v0.9h-5.2v4.3h-1.1v-10.1h6.9v0.9H273.8z"/>
		<path d="M297.3,236.3h-9.4l-2,4.5H284l7.7-16.9h1.8l7.7,16.9h-1.9L297.3,236.3z M296.7,234.9l-4.1-9.1l-4.1,9.1H296.7z"/>
		<path d="M306.6,239.9c-1.3-0.7-2.4-1.8-3.2-3.1c-0.8-1.3-1.1-2.8-1.1-4.4s0.4-3.1,1.1-4.4c0.8-1.3,1.8-2.3,3.2-3.1
			c1.4-0.7,2.9-1.1,4.5-1.1c1.3,0,2.4,0.2,3.5,0.6c1.1,0.4,2,1,2.7,1.8l-1.1,1.1c-1.3-1.3-3-2-5-2c-1.3,0-2.6,0.3-3.7,0.9
			c-1.1,0.6-2,1.5-2.6,2.5s-0.9,2.3-0.9,3.6s0.3,2.5,0.9,3.6c0.6,1.1,1.5,1.9,2.6,2.5c1.1,0.6,2.3,0.9,3.7,0.9c2,0,3.7-0.7,5-2
			l1.1,1.1c-0.7,0.8-1.6,1.4-2.7,1.9c-1.1,0.4-2.2,0.6-3.5,0.6C309.5,241,308,240.6,306.6,239.9z"/>
		<path d="M323.7,239.9c-1.3-0.7-2.4-1.8-3.2-3.1c-0.8-1.3-1.1-2.8-1.1-4.4s0.4-3.1,1.1-4.4c0.8-1.3,1.8-2.3,3.2-3.1
			c1.4-0.7,2.9-1.1,4.5-1.1c1.3,0,2.4,0.2,3.5,0.6c1.1,0.4,2,1,2.7,1.8l-1.1,1.1c-1.3-1.3-3-2-5-2c-1.3,0-2.6,0.3-3.7,0.9
			c-1.1,0.6-2,1.5-2.6,2.5s-0.9,2.3-0.9,3.6s0.3,2.5,0.9,3.6c0.6,1.1,1.5,1.9,2.6,2.5c1.1,0.6,2.3,0.9,3.7,0.9c2,0,3.7-0.7,5-2
			l1.1,1.1c-0.7,0.8-1.6,1.4-2.7,1.9c-1.1,0.4-2.2,0.6-3.5,0.6C326.5,241,325,240.6,323.7,239.9z"/>
		<path d="M349.8,240.8l-3.9-5.4c-0.4,0-0.9,0.1-1.4,0.1H340v5.4h-1.8v-16.9h6.3c2.2,0,3.9,0.5,5.1,1.5c1.2,1,1.8,2.5,1.8,4.3
			c0,1.3-0.3,2.4-1,3.4c-0.7,0.9-1.6,1.6-2.9,2l4.1,5.8H349.8z M348.4,232.8c0.9-0.7,1.3-1.8,1.3-3.1c0-1.4-0.4-2.4-1.3-3.2
			c-0.9-0.7-2.2-1.1-3.8-1.1H340v8.5h4.5C346.2,233.9,347.5,233.6,348.4,232.8z"/>
		<path d="M367.7,239.3v1.5h-12v-16.9h11.6v1.5h-9.8v6h8.8v1.5h-8.8v6.3H367.7z"/>
		<path d="M371.9,223.9h6.9c1.8,0,3.4,0.4,4.7,1.1c1.4,0.7,2.4,1.7,3.2,3c0.8,1.3,1.1,2.7,1.1,4.4c0,1.6-0.4,3.1-1.1,4.4
			c-0.8,1.3-1.8,2.3-3.2,3s-3,1.1-4.7,1.1h-6.9V223.9z M378.7,239.3c1.5,0,2.8-0.3,3.9-0.9c1.1-0.6,2-1.4,2.6-2.4
			c0.6-1,0.9-2.2,0.9-3.6s-0.3-2.6-0.9-3.6c-0.6-1-1.5-1.9-2.6-2.4c-1.1-0.6-2.4-0.9-3.9-0.9h-5v13.8H378.7z"/>
		<path d="M391.9,223.9h1.8v16.9h-1.8V223.9z"/>
		<path d="M402.5,225.4h-6v-1.5h13.7v1.5h-6v15.4h-1.8V225.4z"/>
		<path d="M422.9,236.3h-9.4l-2,4.5h-1.9l7.7-16.9h1.8l7.7,16.9h-1.9L422.9,236.3z M422.2,234.9l-4.1-9.1l-4.1,9.1H422.2z"/>
		<path d="M432.1,225.4h-6v-1.5h13.7v1.5h-6v15.4h-1.8V225.4z"/>
		<path d="M442.7,223.9h1.8v16.9h-1.8V223.9z"/>
		<path d="M452.8,239.9c-1.4-0.8-2.4-1.8-3.2-3.1c-0.8-1.3-1.1-2.8-1.1-4.4s0.4-3.1,1.1-4.4c0.8-1.3,1.8-2.3,3.2-3.1
			c1.4-0.8,2.9-1.1,4.6-1.1s3.2,0.4,4.5,1.1c1.3,0.7,2.4,1.8,3.2,3.1c0.8,1.3,1.2,2.8,1.2,4.4s-0.4,3.1-1.2,4.4
			c-0.8,1.3-1.8,2.3-3.2,3.1c-1.3,0.7-2.9,1.1-4.5,1.1S454.2,240.6,452.8,239.9z M461,238.5c1.1-0.6,1.9-1.4,2.5-2.5
			c0.6-1.1,0.9-2.3,0.9-3.6s-0.3-2.5-0.9-3.6s-1.5-1.9-2.5-2.5c-1.1-0.6-2.3-0.9-3.6-0.9s-2.6,0.3-3.6,0.9c-1.1,0.6-1.9,1.4-2.6,2.5
			s-0.9,2.3-0.9,3.6s0.3,2.5,0.9,3.6c0.6,1.1,1.5,1.9,2.6,2.5c1.1,0.6,2.3,0.9,3.6,0.9S459.9,239.1,461,238.5z"/>
		<path d="M484.4,223.9v16.9h-1.5L472,227.1v13.7h-1.8v-16.9h1.5l10.9,13.7v-13.7H484.4z"/>
	</g>
	<g class="st1505">
		<path d="M97.4,327.4v13.3h-1.4v-6.1h-8.3v6.1h-1.4v-13.3h1.4v5.9h8.3v-5.9H97.4z"/>
		<path d="M110.1,337.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L110.1,337.2z M109.6,336l-3.2-7.1l-3.2,7.1H109.6z"/>
		<path d="M116.2,340.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2s1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7s-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7c-0.6-0.2-1.1-0.6-1.5-1.1
			c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5c0.8,0,1.5,0.1,2.2,0.3
			c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C118.1,340.8,117.1,340.7,116.2,340.4z"/>
		<path d="M142.4,335c0.5,0.6,0.7,1.3,0.7,2.2c0,1.1-0.4,2-1.3,2.6c-0.8,0.6-2.1,0.9-3.7,0.9H132v-13.3h5.7c1.5,0,2.6,0.3,3.4,0.9
			c0.8,0.6,1.2,1.4,1.2,2.5c0,0.7-0.2,1.4-0.5,1.9c-0.4,0.5-0.9,0.9-1.5,1.1C141.3,334.1,142,334.4,142.4,335z M133.4,328.6v4.8h4.2
			c1.1,0,1.9-0.2,2.5-0.6s0.9-1,0.9-1.8c0-0.8-0.3-1.4-0.9-1.8c-0.6-0.4-1.4-0.6-2.5-0.6H133.4z M140.8,339c0.6-0.4,0.9-1,0.9-1.9
			c0-1.7-1.2-2.5-3.6-2.5h-4.7v5h4.7C139.3,339.6,140.2,339.4,140.8,339z"/>
		<path d="M155.7,339.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H155.7z"/>
		<path d="M168.4,339.5v1.2H159v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H168.4z"/>
		<path d="M182.9,327.4v13.3h-1.2l-8.5-10.8v10.8h-1.4v-13.3h1.2l8.6,10.8v-10.8H182.9z"/>
		<path d="M200.5,337.2h-7.4l-1.6,3.6H190l6.1-13.3h1.4l6.1,13.3h-1.5L200.5,337.2z M200,336l-3.2-7.1l-3.2,7.1H200z"/>
		<path d="M206.6,340.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2s1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7s-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7c-0.6-0.2-1.1-0.6-1.5-1.1
			c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5c0.8,0,1.5,0.1,2.2,0.3
			c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C208.4,340.8,207.5,340.7,206.6,340.4z"/>
		<path d="M218.3,340.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2s1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7s-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7c-0.6-0.2-1.1-0.6-1.5-1.1
			c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5c0.8,0,1.5,0.1,2.2,0.3
			c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C220.1,340.8,219.2,340.7,218.3,340.4z"/>
		<path d="M238.5,339.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H238.5z"/>
		<path d="M242.6,340.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2s1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7s-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7c-0.6-0.2-1.1-0.6-1.5-1.1
			c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5c0.8,0,1.5,0.1,2.2,0.3
			c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C244.5,340.8,243.6,340.7,242.6,340.4z"/>
		<path d="M254.3,340.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2s1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7s-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7c-0.6-0.2-1.1-0.6-1.5-1.1
			c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5c0.8,0,1.5,0.1,2.2,0.3
			c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8c-0.6-0.2-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C256.2,340.8,255.2,340.7,254.3,340.4z"/>
		<path d="M274.6,339.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H274.6z"/>
		<path d="M277.9,327.4h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V327.4z M283.2,339.5c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H283.2z"/>
		<path d="M306.9,337.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L306.9,337.2z M306.3,336l-3.2-7.1L300,336H306.3z"/>
		<path d="M323.2,327.4v13.3h-1.2l-8.5-10.8v10.8h-1.4v-13.3h1.2l8.6,10.8v-10.8H323.2z"/>
		<path d="M327.6,327.4h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V327.4z M332.9,339.5c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7H329v10.9H332.9z"/>
		<path d="M356.6,337.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L356.6,337.2z M356,336l-3.2-7.1l-3.2,7.1H356z"/>
		<path d="M363.9,340c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8s1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7c1.6,0,2.9-0.5,3.9-1.6
			l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5s-1.8,0.5-2.7,0.5C366.1,340.8,364.9,340.5,363.9,340z"/>
		<path d="M377.2,340c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8s1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7c1.6,0,2.9-0.5,3.9-1.6
			l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5s-1.8,0.5-2.7,0.5C379.5,340.8,378.3,340.5,377.2,340z"/>
		<path d="M397.7,340.7l-3-4.3c-0.3,0-0.7,0.1-1.1,0.1h-3.6v4.2h-1.4v-13.3h5c1.7,0,3,0.4,4,1.2c1,0.8,1.4,1.9,1.4,3.3
			c0,1-0.3,1.9-0.8,2.6c-0.5,0.7-1.3,1.2-2.3,1.5l3.2,4.6H397.7z M396.6,334.4c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5
			c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.7h3.5C394.9,335.3,395.9,335,396.6,334.4z"/>
		<path d="M411.8,339.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H411.8z"/>
		<path d="M415.1,327.4h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V327.4z M420.4,339.5c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H420.4z"/>
		<path d="M430.8,327.4h1.4v13.3h-1.4V327.4z"/>
		<path d="M439.1,328.6h-4.7v-1.2h10.7v1.2h-4.7v12.1h-1.4V328.6z"/>
		<path d="M456.8,339.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H456.8z"/>
		<path d="M460.1,327.4h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V327.4z M465.4,339.5c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H465.4z"/>
		<path d="M480.8,327.4h1.4v13.3h-1.4V327.4z"/>
		<path d="M497.6,327.4v13.3h-1.2l-8.5-10.8v10.8h-1.4v-13.3h1.2l8.6,10.8v-10.8H497.6z"/>
		<path d="M95.3,357.4h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L95.3,357.4z M94.7,356.3l-3.2-7.1l-3.2,7.1H94.7z"/>
		<path d="M102.6,360.2c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7
			c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5s-1.8,0.5-2.7,0.5C104.8,361.1,103.6,360.8,102.6,360.2z"/>
		<path d="M115.9,360.2c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7
			c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5s-1.8,0.5-2.7,0.5C118.2,361.1,117,360.8,115.9,360.2z"/>
		<path d="M129.3,360.2c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1.3,0,2.5,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.5,2.4c0.6,1,0.9,2.2,0.9,3.5s-0.3,2.4-0.9,3.5
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.2,0.9-3.6,0.9C131.6,361.1,130.4,360.8,129.3,360.2z M135.7,359.1c0.8-0.5,1.5-1.1,2-2
			s0.7-1.8,0.7-2.8c0-1-0.2-2-0.7-2.8c-0.5-0.8-1.1-1.5-2-2c-0.8-0.5-1.8-0.7-2.8-0.7c-1.1,0-2,0.2-2.9,0.7c-0.9,0.5-1.5,1.1-2,2
			c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7C133.9,359.8,134.9,359.6,135.7,359.1z"
			/>
		<path d="M152.1,361l-3-4.3c-0.3,0-0.7,0.1-1.1,0.1h-3.6v4.2H143v-13.3h5c1.7,0,3,0.4,4,1.2c1,0.8,1.4,1.9,1.4,3.3
			c0,1-0.3,1.9-0.8,2.6c-0.5,0.7-1.3,1.2-2.2,1.5l3.2,4.6H152.1z M151,354.7c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5
			c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.7h3.5C149.3,355.6,150.3,355.3,151,354.7z"/>
		<path d="M156.7,347.7h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V347.7z M162.1,359.8c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.6-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H162.1z"/>
		<path d="M180.6,357.4h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L180.6,357.4z M180,356.3l-3.2-7.1l-3.2,7.1H180z"/>
		<path d="M196.9,347.7V361h-1.2l-8.5-10.8V361h-1.4v-13.3h1.2l8.6,10.8v-10.8H196.9z"/>
		<path d="M203.5,360.2c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7
			c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5s-1.8,0.5-2.7,0.5C205.7,361.1,204.5,360.8,203.5,360.2z"/>
		<path d="M224.3,359.8v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H224.3z"/>
		<path d="M250.8,347.7l-4.5,13.3h-1.5l-3.9-11.3l-3.9,11.3h-1.5l-4.5-13.3h1.4l3.8,11.4l4-11.4h1.3l3.9,11.5l3.9-11.5H250.8z"/>
		<path d="M253.4,347.7h1.4V361h-1.4V347.7z"/>
		<path d="M261.7,348.9H257v-1.2h10.7v1.2h-4.7V361h-1.4V348.9z"/>
		<path d="M281.1,347.7V361h-1.4v-6.1h-8.3v6.1H270v-13.3h1.4v5.9h8.3v-5.9H281.1z"/>
		<path d="M298.8,357.4h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L298.8,357.4z M298.3,356.3l-3.2-7.1l-3.2,7.1H298.3z"/>
		<path d="M312.5,357.4h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L312.5,357.4z M312,356.3l-3.2-7.1l-3.2,7.1H312z"/>
		<path d="M317.8,347.7h1.4V361h-1.4V347.7z"/>
		<path d="M323.6,347.7h1.4v12.1h7.4v1.2h-8.8V347.7z"/>
		<path d="M349.2,361.2l-1.8-1.9c-1.1,1.2-2.6,1.8-4.4,1.8c-0.9,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.2-0.7-1.6-1.2s-0.6-1.1-0.6-1.8
			c0-0.8,0.3-1.5,0.8-2.1c0.5-0.6,1.4-1.3,2.6-2c-0.6-0.7-1.1-1.2-1.3-1.7c-0.2-0.5-0.4-1-0.4-1.5c0-0.8,0.3-1.5,0.9-2.1
			c0.6-0.5,1.4-0.8,2.4-0.8c0.9,0,1.7,0.2,2.3,0.7c0.6,0.5,0.8,1.1,0.8,1.9c0,0.7-0.2,1.3-0.7,1.8c-0.4,0.5-1.2,1.1-2.3,1.7l3.6,3.8
			c0.4-0.7,0.7-1.6,0.9-2.6l1.1,0.3c-0.2,1.2-0.6,2.2-1.2,3.1l1.9,2L349.2,361.2z M346.6,358.5l-4-4.1c-1.1,0.6-1.8,1.1-2.2,1.6
			c-0.4,0.5-0.6,1-0.6,1.6c0,0.7,0.3,1.3,0.9,1.7c0.6,0.4,1.4,0.7,2.3,0.7C344.5,360,345.7,359.5,346.6,358.5z M342,349.1
			c-0.4,0.3-0.6,0.8-0.6,1.3c0,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0.6,0.8,1.2,1.4c1-0.6,1.6-1,2-1.4c0.4-0.4,0.6-0.8,0.6-1.3
			c0-0.5-0.2-0.9-0.5-1.2s-0.8-0.4-1.4-0.4C342.9,348.6,342.3,348.8,342,349.1z"/>
		<path d="M358.8,348.9v5.2h6.9v1.2h-6.9v5.6h-1.4v-13.3h9.1v1.2H358.8z"/>
		<path d="M372.1,348.9h-4.7v-1.2h10.7v1.2h-4.7V361h-1.4V348.9z"/>
		<path d="M386.2,360.6c-0.9-0.3-1.6-0.7-2.1-1.3l0.5-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
			c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5
			c0.8,0,1.5,0.1,2.2,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8s-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C388,361.1,387.1,360.9,386.2,360.6z"/>
		<path d="M399.4,348.9h-4.7v-1.2h10.7v1.2h-4.7V361h-1.4V348.9z"/>
		<path d="M415.4,357.4H408l-1.6,3.6H405l6.1-13.3h1.4l6.1,13.3H417L415.4,357.4z M414.9,356.3l-3.2-7.1l-3.2,7.1H414.9z"/>
		<path d="M431.8,347.7V361h-1.2l-8.5-10.8V361h-1.4v-13.3h1.2l8.6,10.8v-10.8H431.8z"/>
		<path d="M436.1,347.7h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V347.7z M441.5,359.8c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H441.5z"/>
		<path d="M460,357.4h-7.4L451,361h-1.5l6.1-13.3h1.4L463,361h-1.5L460,357.4z M459.4,356.3l-3.2-7.1l-3.2,7.1H459.4z"/>
		<path d="M474.3,361l-3-4.3c-0.3,0-0.7,0.1-1.1,0.1h-3.6v4.2h-1.4v-13.3h5c1.7,0,3,0.4,4,1.2c1,0.8,1.4,1.9,1.4,3.3
			c0,1-0.3,1.9-0.8,2.6c-0.5,0.7-1.3,1.2-2.3,1.5l3.2,4.6H474.3z M473.2,354.7c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5
			c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.7h3.5C471.5,355.6,472.5,355.3,473.2,354.7z"/>
		<path d="M479,347.7h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.4c0.6,1,0.9,2.2,0.9,3.4s-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.4c-1.1,0.6-2.3,0.8-3.7,0.8H479V347.7z M484.3,359.8c1.2,0,2.2-0.2,3.1-0.7c0.9-0.5,1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8s-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3.1-0.7h-3.9v10.9H484.3z"/>
		<path d="M495.5,360.6c-0.9-0.3-1.6-0.7-2.1-1.3l0.5-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
			c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5
			c0.8,0,1.5,0.1,2.2,0.3s1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8s-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3s0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C497.3,361.1,496.4,360.9,495.5,360.6z"/>
		<path d="M125.8,373.1v3.5h4.6v0.8h-4.6v3.8h-0.9v-8.9h6.1v0.8H125.8z"/>
		<path d="M134.2,380.7c-0.7-0.4-1.3-0.9-1.7-1.6s-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6c0.7-0.4,1.5-0.6,2.4-0.6
			s1.7,0.2,2.4,0.6s1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.5,0.6,2.3s-0.2,1.6-0.6,2.3c-0.4,0.7-1,1.2-1.7,1.6s-1.5,0.6-2.4,0.6
			S134.9,381.1,134.2,380.7z M138.5,380c0.6-0.3,1-0.8,1.3-1.3s0.5-1.2,0.5-1.9c0-0.7-0.2-1.3-0.5-1.9s-0.8-1-1.3-1.3
			c-0.6-0.3-1.2-0.5-1.9-0.5s-1.3,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.3,1.3s-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9s0.8,1,1.3,1.3
			c0.6,0.3,1.2,0.5,1.9,0.5S138,380.3,138.5,380z"/>
		<path d="M149.5,381.2l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8h-0.9v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H149.5z M148.7,377c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			s-1.1-0.6-2-0.6h-2.4v4.5h2.4C147.6,377.6,148.2,377.4,148.7,377z"/>
		<path d="M162.1,376.8h0.9v3.4c-0.4,0.4-0.9,0.6-1.5,0.8c-0.6,0.2-1.2,0.3-1.8,0.3c-0.9,0-1.7-0.2-2.4-0.6s-1.3-0.9-1.7-1.6
			c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6s1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.9,0.3c0.6,0.2,1,0.5,1.4,1
			l-0.6,0.6c-0.7-0.7-1.6-1-2.7-1c-0.7,0-1.4,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9
			c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5c0.9,0,1.7-0.2,2.3-0.7V376.8z"/>
		<path d="M172,380.4v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H172z"/>
		<path d="M181.6,372.3v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H181.6z"/>
		<path d="M190.8,380.4v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H190.8z"/>
		<path d="M199,381.2l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8H193v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H199z M198.3,377c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			s-1.1-0.6-2-0.6h-2.4v4.5h2.4C197.2,377.6,197.8,377.4,198.3,377z"/>
		<path d="M207.7,378.8h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L207.7,378.8z M207.4,378.1l-2.1-4.8l-2.1,4.8H207.4z"/>
		<path d="M211.3,372.3h0.9v8.1h5v0.8h-5.9V372.3z"/>
		<path d="M228.1,381.2l-2-2.9c-0.2,0-0.5,0-0.7,0H223v2.8H222v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H228.1z M227.4,377c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			s-1.1-0.6-2-0.6H223v4.5h2.4C226.2,377.6,226.9,377.4,227.4,377z"/>
		<path d="M237.5,380.4v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H237.5z"/>
		<path d="M248.6,382c-0.3,0.3-0.6,0.6-1,0.8c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.5-0.3-1-0.7-1.6-1.3
			c-0.9,0-1.6-0.2-2.3-0.6s-1.2-0.9-1.6-1.6s-0.6-1.4-0.6-2.3c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6c0.7-0.4,1.5-0.6,2.4-0.6
			s1.7,0.2,2.4,0.6s1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.5,0.6,2.3c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.7,1.1-1.3,1.6
			c-0.6,0.4-1.2,0.7-1.9,0.8c0.7,0.7,1.3,1,1.9,1c0.7,0,1.2-0.3,1.7-0.8L248.6,382z M240.3,378.7c0.3,0.6,0.8,1,1.3,1.3
			c0.6,0.3,1.2,0.5,1.9,0.5s1.3-0.2,1.9-0.5c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9c0-0.7-0.2-1.3-0.5-1.9s-0.8-1-1.3-1.3
			c-0.6-0.3-1.2-0.5-1.9-0.5s-1.3,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.3,1.3s-0.5,1.2-0.5,1.9C239.8,377.5,240,378.1,240.3,378.7z"/>
		<path d="M251.2,380.3c-0.6-0.7-1-1.6-1-2.9v-5.1h0.9v5c0,1,0.2,1.8,0.7,2.3s1.1,0.8,2,0.8c0.9,0,1.6-0.3,2-0.8
			c0.5-0.5,0.7-1.3,0.7-2.3v-5h0.9v5.1c0,1.3-0.3,2.2-1,2.9s-1.5,1-2.7,1C252.7,381.3,251.8,381,251.2,380.3z"/>
		<path d="M260.4,372.3h0.9v8.9h-0.9V372.3z"/>
		<path d="M270.2,381.2l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8h-0.9v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H270.2z M269.5,377c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			s-1.1-0.6-2-0.6h-2.4v4.5h2.4C268.4,377.6,269,377.4,269.5,377z"/>
		<path d="M279.6,380.4v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H279.6z"/>
		<path d="M291.1,372.3v8.9h-0.9v-7.1l-3.5,6h-0.4l-3.5-5.9v7.1h-0.9v-8.9h0.8l3.9,6.6l3.8-6.6H291.1z"/>
		<path d="M300.2,380.4v0.8H294v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H300.2z"/>
		<path d="M309.9,372.3v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H309.9z"/>
		<path d="M314.5,373.1h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V373.1z"/>
		<path d="M320.5,381c-0.6-0.2-1.1-0.5-1.4-0.8l0.4-0.7c0.3,0.3,0.7,0.6,1.3,0.8c0.5,0.2,1.1,0.3,1.6,0.3c0.8,0,1.4-0.1,1.8-0.4
			c0.4-0.3,0.6-0.7,0.6-1.1c0-0.4-0.1-0.6-0.3-0.9s-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.2-1.3-0.4c-0.7-0.2-1.2-0.3-1.6-0.5
			c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.4,0.1-0.9,0.4-1.2c0.2-0.4,0.6-0.7,1.1-0.9c0.5-0.2,1.1-0.3,1.8-0.3
			c0.5,0,1,0.1,1.5,0.2s0.9,0.3,1.3,0.6l-0.3,0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.1-0.8-0.2-1.2-0.2c-0.8,0-1.4,0.2-1.8,0.5
			s-0.6,0.7-0.6,1.2c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.8,0.2,1.3,0.4c0.7,0.2,1.2,0.3,1.6,0.5
			c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.4,0.7,0.4,1.3c0,0.4-0.1,0.9-0.4,1.2c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1.1,0.3-1.8,0.3
			C321.7,381.3,321.1,381.2,320.5,381z"/>
		<path d="M334.4,372.3h0.9v8.9h-0.9V372.3z"/>
		<path d="M345.6,372.3v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H345.6z"/>
		<path d="M357.4,378.8h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L357.4,378.8z M357.1,378.1l-2.1-4.8l-2.1,4.8H357.1z"/>
		<path d="M362.3,380.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.8,0.3c0.6,0.2,1,0.5,1.4,1l-0.6,0.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.7,0-1.3,0.2-1.9,0.5
			s-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			c1.1,0,1.9-0.4,2.6-1.1l0.6,0.6c-0.4,0.4-0.9,0.7-1.4,1c-0.6,0.2-1.2,0.3-1.8,0.3C363.8,381.3,363,381.1,362.3,380.7z"/>
		<path d="M371.2,380.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.8,0.3c0.6,0.2,1,0.5,1.4,1l-0.6,0.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.7,0-1.3,0.2-1.9,0.5
			s-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			c1.1,0,1.9-0.4,2.6-1.1l0.6,0.6c-0.4,0.4-0.9,0.7-1.4,1c-0.6,0.2-1.2,0.3-1.8,0.3C372.7,381.3,371.9,381.1,371.2,380.7z"/>
		<path d="M380.2,380.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.7,1.6s0.6,1.5,0.6,2.3s-0.2,1.6-0.6,2.3s-1,1.2-1.7,1.6
			c-0.7,0.4-1.5,0.6-2.4,0.6C381.7,381.3,380.9,381.1,380.2,380.7z M384.4,380c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9
			c0-0.7-0.2-1.3-0.5-1.9c-0.3-0.6-0.8-1-1.3-1.3c-0.6-0.3-1.2-0.5-1.9-0.5c-0.7,0-1.3,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.3,1.3
			c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.3,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			C383.2,380.5,383.9,380.3,384.4,380z"/>
		<path d="M395.4,381.2l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8h-0.9v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H395.4z M394.6,377c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			c-0.5-0.4-1.1-0.6-2-0.6h-2.4v4.5h2.4C393.5,377.6,394.2,377.4,394.6,377z"/>
		<path d="M398.5,372.3h3.6c0.9,0,1.8,0.2,2.5,0.6s1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.4,0.6,2.3s-0.2,1.6-0.6,2.3s-1,1.2-1.7,1.6
			s-1.5,0.6-2.5,0.6h-3.6V372.3z M402,380.4c0.8,0,1.5-0.2,2-0.5c0.6-0.3,1-0.7,1.4-1.3c0.3-0.5,0.5-1.2,0.5-1.9s-0.2-1.3-0.5-1.9
			c-0.3-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.3-0.5-2-0.5h-2.6v7.3H402z"/>
		<path d="M414.4,378.8h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L414.4,378.8z M414.1,378.1l-2.1-4.8l-2.1,4.8H414.1z"/>
		<path d="M425.3,372.3v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H425.3z"/>
		<path d="M429.7,380.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.8,0.3c0.6,0.2,1,0.5,1.4,1l-0.6,0.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.7,0-1.3,0.2-1.9,0.5
			s-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			c1.1,0,1.9-0.4,2.6-1.1l0.6,0.6c-0.4,0.4-0.9,0.7-1.4,1c-0.6,0.2-1.2,0.3-1.8,0.3C431.2,381.3,430.4,381.1,429.7,380.7z"/>
		<path d="M443.6,380.4v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H443.6z"/>
		<path d="M450.9,373.1h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V373.1z"/>
		<path d="M457.8,380.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.9,0,1.7,0.2,2.4,0.6c0.7,0.4,1.3,0.9,1.7,1.6s0.6,1.5,0.6,2.3s-0.2,1.6-0.6,2.3s-1,1.2-1.7,1.6
			c-0.7,0.4-1.5,0.6-2.4,0.6C459.3,381.3,458.5,381.1,457.8,380.7z M462.1,380c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9
			c0-0.7-0.2-1.3-0.5-1.9c-0.3-0.6-0.8-1-1.3-1.3c-0.6-0.3-1.2-0.5-1.9-0.5c-0.7,0-1.3,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.3,1.3
			c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.3,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			C460.9,380.5,461.5,380.3,462.1,380z"/>
		<path d="M167.4,392.6h0.9v8.9h-0.9V392.6z"/>
		<path d="M176.8,399.1h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L176.8,399.1z M176.4,398.3l-2.1-4.8l-2.1,4.8H176.4z"/>
		<path d="M180.3,392.6h3.6c0.9,0,1.8,0.2,2.5,0.6c0.7,0.4,1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.4,0.6,2.3s-0.2,1.6-0.6,2.3
			s-1,1.2-1.7,1.6c-0.7,0.4-1.5,0.6-2.5,0.6h-3.6V392.6z M183.9,400.7c0.8,0,1.5-0.2,2-0.5c0.6-0.3,1-0.7,1.4-1.3
			c0.3-0.5,0.5-1.2,0.5-1.9s-0.2-1.3-0.5-1.9s-0.8-1-1.4-1.3s-1.3-0.5-2-0.5h-2.6v7.3H183.9z"/>
		<path d="M190.8,392.6h0.9v8.9h-0.9V392.6z"/>
		<path d="M194.6,392.6h0.9v8.1h5v0.8h-5.9V392.6z"/>
		<path d="M205.4,392.6h0.9v8.9h-0.9V392.6z"/>
		<path d="M216.7,392.6v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H216.7z"/>
		<path d="M221.3,393.4h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V393.4z"/>
		<path d="M233.1,400.7v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H233.1z"/>
		<path d="M241.4,401.5l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8h-0.9v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H241.4z M240.6,397.3c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			s-1.1-0.6-2-0.6h-2.4v4.5h2.4C239.5,397.9,240.2,397.7,240.6,397.3z"/>
		<path d="M251.9,392.6v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H251.9z"/>
		<path d="M260.4,399.1h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L260.4,399.1z M260,398.3l-2.1-4.8l-2.1,4.8H260z"/>
		<path d="M265.2,393.4h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V393.4z"/>
		<path d="M270.7,392.6h0.9v8.9h-0.9V392.6z"/>
		<path d="M276.1,401c-0.7-0.4-1.3-0.9-1.7-1.6s-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6c0.7-0.4,1.5-0.6,2.4-0.6
			s1.7,0.2,2.4,0.6s1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.5,0.6,2.3s-0.2,1.6-0.6,2.3c-0.4,0.7-1,1.2-1.7,1.6s-1.5,0.6-2.4,0.6
			S276.8,401.3,276.1,401z M280.4,400.2c0.6-0.3,1-0.8,1.3-1.3s0.5-1.2,0.5-1.9c0-0.7-0.2-1.3-0.5-1.9s-0.8-1-1.3-1.3
			c-0.6-0.3-1.2-0.5-1.9-0.5s-1.3,0.2-1.9,0.5c-0.6,0.3-1,0.8-1.3,1.3s-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9s0.8,1,1.3,1.3
			c0.6,0.3,1.2,0.5,1.9,0.5S279.8,400.5,280.4,400.2z"/>
		<path d="M292.6,392.6v8.9h-0.8l-5.7-7.2v7.2h-0.9v-8.9h0.8l5.7,7.2v-7.2H292.6z"/>
		<path d="M301.1,399.1h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L301.1,399.1z M300.8,398.3l-2.1-4.8l-2.1,4.8H300.8z"/>
		<path d="M304.6,392.6h0.9v8.1h5v0.8h-5.9V392.6z"/>
		<path d="M322.4,397.6c0.3,0.4,0.5,0.9,0.5,1.5c0,0.8-0.3,1.3-0.8,1.8s-1.4,0.6-2.5,0.6h-4.1v-8.9h3.8c1,0,1.8,0.2,2.3,0.6
			c0.5,0.4,0.8,1,0.8,1.7c0,0.5-0.1,0.9-0.4,1.3c-0.2,0.3-0.6,0.6-1,0.8C321.6,397,322.1,397.3,322.4,397.6z M316.4,393.4v3.2h2.8
			c0.7,0,1.3-0.1,1.7-0.4c0.4-0.3,0.6-0.7,0.6-1.2c0-0.5-0.2-0.9-0.6-1.2c-0.4-0.3-0.9-0.4-1.7-0.4H316.4z M321.3,400.3
			c0.4-0.3,0.6-0.7,0.6-1.3c0-1.1-0.8-1.7-2.4-1.7h-3.1v3.3h3.1C320.3,400.7,320.9,400.6,321.3,400.3z"/>
		<path d="M331.3,400.7v0.8H325v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H331.3z"/>
		<path d="M334,401.2c-0.6-0.2-1.1-0.5-1.4-0.8l0.4-0.7c0.3,0.3,0.7,0.6,1.3,0.8c0.5,0.2,1.1,0.3,1.6,0.3c0.8,0,1.4-0.1,1.8-0.4
			c0.4-0.3,0.6-0.7,0.6-1.1c0-0.4-0.1-0.6-0.3-0.9s-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.2-1.3-0.4c-0.7-0.2-1.2-0.3-1.6-0.5
			c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.4,0.1-0.9,0.4-1.2c0.2-0.4,0.6-0.7,1.1-0.9c0.5-0.2,1.1-0.3,1.8-0.3
			c0.5,0,1,0.1,1.5,0.2s0.9,0.3,1.3,0.6l-0.3,0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.1-0.8-0.2-1.2-0.2c-0.8,0-1.4,0.2-1.8,0.5
			s-0.6,0.7-0.6,1.2c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.8,0.2,1.3,0.4c0.7,0.2,1.2,0.3,1.6,0.5
			c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.4,0.7,0.4,1.3c0,0.4-0.1,0.9-0.4,1.2c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1.1,0.3-1.8,0.3
			C335.3,401.5,334.6,401.4,334,401.2z"/>
		<path d="M342.9,393.4h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V393.4z"/>
		<path d="M357.8,393.4c0.6,0.5,1,1.3,1,2.2c0,0.9-0.3,1.7-1,2.2s-1.5,0.8-2.7,0.8h-2.4v2.8h-0.9v-8.9h3.3
			C356.2,392.6,357.1,392.9,357.8,393.4z M357.1,397.3c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7c-0.5-0.4-1.1-0.6-2-0.6h-2.4
			v4.4h2.4C355.9,397.8,356.6,397.6,357.1,397.3z"/>
		<path d="M366.8,401.5l-2-2.9c-0.2,0-0.5,0-0.7,0h-2.4v2.8h-0.9v-8.9h3.3c1.1,0,2,0.3,2.7,0.8c0.6,0.5,1,1.3,1,2.2
			c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,0.8-1.5,1l2.2,3H366.8z M366.1,397.3c0.5-0.4,0.7-0.9,0.7-1.6c0-0.7-0.2-1.3-0.7-1.7
			c-0.5-0.4-1.1-0.6-2-0.6h-2.4v4.5h2.4C364.9,397.9,365.6,397.7,366.1,397.3z"/>
		<path d="M375.5,399.1h-4.9l-1.1,2.4h-1l4.1-8.9h0.9l4.1,8.9h-1L375.5,399.1z M375.2,398.3l-2.1-4.8l-2.1,4.8H375.2z"/>
		<path d="M380.4,401c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.8,0.3c0.6,0.2,1,0.5,1.4,1l-0.6,0.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.7,0-1.3,0.2-1.9,0.5
			s-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			c1.1,0,1.9-0.4,2.6-1.1l0.6,0.6c-0.4,0.4-0.9,0.7-1.4,1c-0.6,0.2-1.2,0.3-1.8,0.3C381.9,401.5,381.1,401.4,380.4,401z"/>
		<path d="M389.6,393.4h-3.1v-0.8h7.2v0.8h-3.1v8.1h-0.9V393.4z"/>
		<path d="M395.2,392.6h0.9v8.9h-0.9V392.6z"/>
		<path d="M400.5,401c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.3s0.2-1.6,0.6-2.3c0.4-0.7,1-1.2,1.7-1.6
			c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.3,0.1,1.8,0.3c0.6,0.2,1,0.5,1.4,1l-0.6,0.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.7,0-1.3,0.2-1.9,0.5
			s-1,0.8-1.4,1.3c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.7,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,1.9,0.5
			c1.1,0,1.9-0.4,2.6-1.1l0.6,0.6c-0.4,0.4-0.9,0.7-1.4,1c-0.6,0.2-1.2,0.3-1.8,0.3C402,401.5,401.2,401.4,400.5,401z"/>
		<path d="M414.4,400.7v0.8h-6.3v-8.9h6.1v0.8h-5.2v3.2h4.6v0.8h-4.6v3.3H414.4z"/>
		<path d="M417.2,401.2c-0.6-0.2-1.1-0.5-1.4-0.8l0.4-0.7c0.3,0.3,0.7,0.6,1.3,0.8c0.5,0.2,1.1,0.3,1.6,0.3c0.8,0,1.4-0.1,1.8-0.4
			c0.4-0.3,0.6-0.7,0.6-1.1c0-0.4-0.1-0.6-0.3-0.9s-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.2-1.3-0.4c-0.7-0.2-1.2-0.3-1.6-0.5
			c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.4,0.1-0.9,0.4-1.2c0.2-0.4,0.6-0.7,1.1-0.9c0.5-0.2,1.1-0.3,1.8-0.3
			c0.5,0,1,0.1,1.5,0.2s0.9,0.3,1.3,0.6l-0.3,0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.1-0.8-0.2-1.2-0.2c-0.8,0-1.4,0.2-1.8,0.5
			s-0.6,0.7-0.6,1.2c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.8,0.2,1.3,0.4c0.7,0.2,1.2,0.3,1.6,0.5
			c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.4,0.7,0.4,1.3c0,0.4-0.1,0.9-0.4,1.2c-0.2,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1.1,0.3-1.8,0.3
			C418.4,401.5,417.8,401.4,417.2,401.2z"/>
		<path d="M140.9,418.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L140.9,418.2z M140.4,417l-3.2-7.1L134,417H140.4z"/>
		<path d="M155.1,409.6c1,0.8,1.4,1.9,1.4,3.3c0,1.4-0.5,2.5-1.4,3.3s-2.3,1.2-4,1.2h-3.6v4.2h-1.4v-13.3h5
			C152.9,408.4,154.2,408.8,155.1,409.6z M154.1,415.4c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.6
			h3.5C152.4,416.3,153.4,416,154.1,415.4z"/>
		<path d="M168.6,409.6c1,0.8,1.4,1.9,1.4,3.3c0,1.4-0.5,2.5-1.4,3.3s-2.3,1.2-4,1.2H161v4.2h-1.4v-13.3h5
			C166.3,408.4,167.6,408.8,168.6,409.6z M167.6,415.4c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5c-0.7-0.6-1.7-0.9-3-0.9H161v6.6h3.5
			C165.9,416.3,166.9,416,167.6,415.4z"/>
		<path d="M173.1,408.4h1.4v12.1h7.4v1.2h-8.8V408.4z"/>
		<path d="M184.3,408.4h1.4v13.3h-1.4V408.4z"/>
		<path d="M192.2,421c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5c0-1.3,0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			s2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5s1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7
			c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5c-0.8,0.3-1.8,0.5-2.7,0.5C194.4,421.8,193.3,421.5,192.2,421z"/>
		<path d="M211.6,418.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L211.6,418.2z M211.1,417l-3.2-7.1l-3.2,7.1H211.1z"/>
		<path d="M227.3,416c0.5,0.6,0.7,1.3,0.7,2.2c0,1.1-0.4,2-1.3,2.6c-0.8,0.6-2.1,0.9-3.7,0.9h-6.1v-13.3h5.7c1.5,0,2.6,0.3,3.4,0.9
			s1.2,1.4,1.2,2.5c0,0.7-0.2,1.4-0.5,1.9c-0.4,0.5-0.9,0.9-1.5,1.1C226.2,415.1,226.8,415.4,227.3,416z M218.3,409.6v4.8h4.2
			c1.1,0,1.9-0.2,2.5-0.6c0.6-0.4,0.9-1,0.9-1.8s-0.3-1.4-0.9-1.8c-0.6-0.4-1.4-0.6-2.5-0.6H218.3z M225.7,420
			c0.6-0.4,0.9-1,0.9-1.9c0-1.7-1.2-2.5-3.6-2.5h-4.7v5h4.7C224.2,420.6,225.1,420.4,225.7,420z"/>
		<path d="M231.2,408.4h1.4v12.1h7.4v1.2h-8.8V408.4z"/>
		<path d="M251.8,420.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H251.8z"/>
		<path d="M262.7,409.6H258v-1.2h10.7v1.2h-4.7v12.1h-1.4V409.6z"/>
		<path d="M273,421c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5c0-1.3,0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.3-0.9,3.6-0.9s2.5,0.3,3.6,0.9s1.9,1.4,2.5,2.4c0.6,1,0.9,2.2,0.9,3.5c0,1.3-0.3,2.4-0.9,3.5c-0.6,1-1.4,1.8-2.5,2.4
			c-1.1,0.6-2.2,0.9-3.6,0.9S274.1,421.5,273,421z M279.4,419.9c0.8-0.5,1.5-1.1,2-2s0.7-1.8,0.7-2.8c0-1-0.2-2-0.7-2.8
			c-0.5-0.8-1.1-1.5-2-2c-0.8-0.5-1.8-0.7-2.8-0.7c-1.1,0-2,0.2-2.9,0.7c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8
			c0,1,0.2,2,0.7,2.8s1.2,1.5,2,2s1.8,0.7,2.9,0.7C277.6,420.6,278.6,420.3,279.4,419.9z"/>
		<path d="M291.7,408.4h1.4v13.3h-1.4V408.4z"/>
		<path d="M300,409.6h-4.7v-1.2h10.7v1.2h-4.7v12.1H300V409.6z"/>
		<path d="M309.4,407.8c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.3-0.1,0.5c0,0.2-0.1,0.4-0.2,0.6l-0.8,2.6h-0.9l0.7-2.7
			c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.6,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3S309.3,407.6,309.4,407.8z"/>
		<path d="M313.9,421.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
			c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5
			c0.8,0,1.5,0.1,2.2,0.3c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8s-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.7s1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C315.7,421.8,314.8,421.7,313.9,421.4z"/>
		<path d="M331.1,409.6v5.2h6.9v1.2h-6.9v5.6h-1.4v-13.3h9.1v1.2H331.1z"/>
		<path d="M349.4,418.2H342l-1.6,3.6H339l6.1-13.3h1.4l6.1,13.3H351L349.4,418.2z M348.9,417l-3.2-7.1l-3.2,7.1H348.9z"/>
		<path d="M356.7,421c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5c0-1.3,0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			s2.3-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4l-0.9,0.9c-1-1.1-2.3-1.6-3.9-1.6c-1.1,0-2,0.2-2.9,0.7
			c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7
			c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9c-0.6,0.6-1.3,1.1-2.1,1.5c-0.8,0.3-1.8,0.5-2.7,0.5C359,421.8,357.8,421.5,356.7,421z"/>
		<path d="M368.2,408.4h1.4v13.3h-1.4V408.4z"/>
		<path d="M373.9,408.4h1.4v12.1h7.4v1.2h-8.8V408.4z"/>
		<path d="M385.1,408.4h1.4v13.3h-1.4V408.4z"/>
		<path d="M393.4,409.6h-4.7v-1.2h10.7v1.2h-4.7v12.1h-1.4V409.6z"/>
		<path d="M401.7,408.4h1.4v13.3h-1.4V408.4z"/>
		<path d="M416.9,420.5v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H416.9z"/>
		<path d="M421,421.4c-0.9-0.3-1.6-0.7-2.1-1.3l0.6-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3c-0.3-0.3-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
			c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.4-0.6,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5
			c0.8,0,1.5,0.1,2.2,0.3c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.2-0.6-1.8-0.8s-1.3-0.3-1.9-0.3c-1.2,0-2,0.2-2.6,0.7
			c-0.6,0.5-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.7s1.1,0.4,2,0.6c1,0.2,1.8,0.5,2.4,0.7
			c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.7,0.5-2.8,0.5
			C422.8,421.8,421.9,421.7,421,421.4z"/>
		<path d="M445.1,418.2h-7.4l-1.6,3.6h-1.5l6.1-13.3h1.4l6.1,13.3h-1.5L445.1,418.2z M444.6,417l-3.2-7.1l-3.2,7.1H444.6z"/>
		<path d="M452.3,409.6h-4.7v-1.2h10.7v1.2h-4.7v12.1h-1.4V409.6z"/>
	</g>
	<g class="st1505">
		<path d="M189.9,478.8h1.4v13.3h-1.4V478.8z"/>
		<path d="M206.7,478.8v13.3h-1.2l-8.5-10.8v10.8h-1.4v-13.3h1.2l8.5,10.8v-10.8H206.7z"/>
		<path d="M218.5,480h-4.7v-1.2h10.7v1.2h-4.7v12h-1.4V480z"/>
		<path d="M237.9,478.8v13.3h-1.4V486h-8.3v6.1h-1.4v-13.3h1.4v5.9h8.3v-5.9H237.9z"/>
		<path d="M251.6,490.9v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H251.6z"/>
		<path d="M259.9,478.8h5.4c1.4,0,2.6,0.3,3.7,0.8c1.1,0.6,1.9,1.3,2.5,2.3c0.6,1,0.9,2.1,0.9,3.4c0,1.3-0.3,2.4-0.9,3.4
			c-0.6,1-1.4,1.8-2.5,2.3c-1.1,0.6-2.3,0.8-3.7,0.8h-5.4V478.8z M265.2,490.9c1.2,0,2.2-0.2,3-0.7s1.5-1.1,2-1.9
			c0.5-0.8,0.7-1.8,0.7-2.8c0-1.1-0.2-2-0.7-2.8c-0.5-0.8-1.2-1.5-2-1.9c-0.9-0.5-1.9-0.7-3-0.7h-3.9v10.8H265.2z"/>
		<path d="M275.5,478.8h1.4v13.3h-1.4V478.8z"/>
		<path d="M282,491.7c-0.9-0.3-1.6-0.7-2.1-1.3l0.5-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.6,0.4,2.5,0.4c1.2,0,2.1-0.2,2.7-0.7
			c0.6-0.4,0.9-1,0.9-1.7c0-0.5-0.2-1-0.5-1.3s-0.7-0.6-1.2-0.7c-0.5-0.2-1.1-0.4-2-0.6c-1-0.3-1.8-0.5-2.4-0.7
			c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-1.9c0-0.7,0.2-1.3,0.5-1.8c0.4-0.5,0.9-1,1.6-1.3c0.7-0.3,1.6-0.5,2.7-0.5
			c0.8,0,1.5,0.1,2.2,0.3c0.7,0.2,1.4,0.5,1.9,0.9l-0.5,1.1c-0.6-0.4-1.1-0.6-1.8-0.8c-0.6-0.2-1.2-0.3-1.9-0.3
			c-1.2,0-2,0.2-2.6,0.7c-0.6,0.4-0.9,1-0.9,1.7c0,0.5,0.2,1,0.5,1.3c0.3,0.3,0.7,0.6,1.2,0.7c0.5,0.2,1.1,0.4,2,0.6
			c1,0.2,1.8,0.5,2.4,0.7c0.6,0.2,1.1,0.6,1.5,1.1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.5,1.8c-0.4,0.5-0.9,1-1.7,1.3
			s-1.7,0.5-2.7,0.5C283.9,492.2,282.9,492,282,491.7z"/>
		<path d="M295,491.3c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.5s0.3-2.4,0.9-3.5c0.6-1,1.4-1.8,2.5-2.4
			c1.1-0.6,2.2-0.9,3.6-0.9c1,0,1.9,0.2,2.7,0.5s1.5,0.8,2.1,1.4l-0.9,0.9c-1-1-2.3-1.6-3.9-1.6c-1,0-2,0.2-2.9,0.7s-1.5,1.1-2,2
			c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8c0.5,0.8,1.2,1.5,2,2c0.9,0.5,1.8,0.7,2.9,0.7c1.6,0,2.9-0.5,3.9-1.6l0.9,0.9
			c-0.6,0.6-1.3,1.1-2.1,1.4c-0.8,0.3-1.8,0.5-2.7,0.5C297.3,492.2,296.1,491.9,295,491.3z"/>
		<path d="M306.5,478.8h1.4v13.3h-1.4V478.8z"/>
		<path d="M321.1,480c1,0.8,1.4,1.9,1.4,3.3c0,1.4-0.5,2.5-1.4,3.3c-1,0.8-2.3,1.2-4,1.2h-3.6v4.2h-1.4v-13.3h5
			C318.8,478.8,320.1,479.2,321.1,480z M320.1,485.8c0.7-0.6,1-1.4,1-2.4c0-1.1-0.3-1.9-1-2.5c-0.7-0.6-1.7-0.9-3-0.9h-3.5v6.6h3.5
			C318.4,486.7,319.4,486.4,320.1,485.8z"/>
		<path d="M325.6,478.8h1.4v12h7.4v1.2h-8.8V478.8z"/>
		<path d="M336.7,478.8h1.4v13.3h-1.4V478.8z"/>
		<path d="M353.5,478.8v13.3h-1.2l-8.5-10.8v10.8h-1.4v-13.3h1.2l8.5,10.8v-10.8H353.5z"/>
		<path d="M367.2,490.9v1.2h-9.4v-13.3h9.1v1.2h-7.7v4.7h6.9v1.2h-6.9v4.9H367.2z"/>
		<path d="M377.7,491.3c-1.1-0.6-1.9-1.4-2.5-2.4c-0.6-1-0.9-2.2-0.9-3.4s0.3-2.4,0.9-3.4c0.6-1,1.4-1.8,2.5-2.4s2.3-0.9,3.6-0.9
			s2.5,0.3,3.5,0.9c1.1,0.6,1.9,1.4,2.5,2.4c0.6,1,0.9,2.2,0.9,3.5s-0.3,2.4-0.9,3.5c-0.6,1-1.4,1.8-2.5,2.4
			c-1.1,0.6-2.2,0.9-3.5,0.9S378.7,491.9,377.7,491.3z M384.1,490.2c0.8-0.5,1.5-1.1,2-2c0.5-0.8,0.7-1.8,0.7-2.8c0-1-0.2-2-0.7-2.8
			c-0.5-0.8-1.1-1.5-2-2c-0.8-0.5-1.8-0.7-2.8-0.7s-2,0.2-2.8,0.7c-0.9,0.5-1.5,1.1-2,2c-0.5,0.8-0.7,1.8-0.7,2.8c0,1,0.2,2,0.7,2.8
			c0.5,0.8,1.2,1.5,2,2s1.8,0.7,2.8,0.7S383.2,490.7,384.1,490.2z"/>
		<path d="M392.7,480v5.2h6.9v1.2h-6.9v5.6h-1.4v-13.3h9.1v1.2H392.7z"/>
	</g>
	<g class="st1505">
		<text :transform="`matrix(1 0 0 1 ${companyLeft} 303.0841)`" class="st198 st1517 st1518" v-if="company">{{company.toUpperCase()}}</text>
	</g>
	<g class="st1505">
		<g>
			<path d="M331.8,773.5h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V773.5z"/>
			<path d="M337,773.2v3.6h-0.4v-1.6h-2.2v1.6H334v-3.6h0.4v1.6h2.2v-1.6H337z"/>
			<path d="M338.2,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M339.9,776.6c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				s0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
				C340.4,776.8,340.2,776.7,339.9,776.6z"/>
			<path d="M344.8,776.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				s0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4s-0.5,0.1-0.7,0.1C345.4,776.8,345.1,776.7,344.8,776.5z"/>
			<path d="M350.4,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H350.4z"/>
			<path d="M353.7,776.7l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H353.7z M353.4,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				s-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C352.9,775.3,353.2,775.2,353.4,775z"/>
			<path d="M355.6,773.5h-1.3v-0.3h2.9v0.3H356v3.2h-0.4V773.5z"/>
			<path d="M357.8,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M359.7,773.5v1.4h1.8v0.3h-1.8v1.5h-0.4v-3.6h2.4v0.3H359.7z"/>
			<path d="M362.6,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M364.7,776.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				s0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4s-0.5,0.1-0.7,0.1C365.3,776.8,365,776.7,364.7,776.5z"/>
			<path d="M369.9,775.8h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L369.9,775.8z M369.8,775.5l-0.9-1.9l-0.9,1.9H369.8z"/>
			<path d="M371.9,773.5h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V773.5z"/>
			<path d="M376.6,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H376.6z"/>
			<path d="M378.9,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M380.6,776.6c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
				C381.1,776.8,380.9,776.7,380.6,776.6z"/>
			<path d="M387.1,775.8h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L387.1,775.8z M387,775.5l-0.9-1.9l-0.9,1.9H387z"/>
			<path d="M392.3,773.5c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.3-1.1,0.3h-1v1.1h-0.4v-3.6h1.3
				C391.6,773.2,392,773.3,392.3,773.5z M392,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7s-0.5-0.2-0.8-0.2h-0.9v1.8h0.9
				C391.5,775.3,391.8,775.2,392,775z"/>
			<path d="M395.9,776.7l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H395.9z M395.6,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				s-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C395.1,775.3,395.4,775.2,395.6,775z"/>
			<path d="M397.8,776.5c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				s0.6-0.2,1-0.2s0.7,0.1,1,0.2s0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.6
				c-0.3,0.2-0.6,0.2-1,0.2S398,776.7,397.8,776.5z M399.5,776.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2S399.3,776.4,399.5,776.2z"/>
			<path d="M403.8,773.5c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.4,0.9s-0.6,0.3-1.1,0.3h-1v1.1h-0.4v-3.6h1.3
				C403.2,773.2,403.6,773.3,403.8,773.5z M403.6,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9
				v1.8h0.9C403.1,775.3,403.4,775.2,403.6,775z"/>
			<path d="M407.6,776.4v0.3H405v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H407.6z"/>
			<path d="M410.9,776.7l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H410.9z M410.6,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C410.1,775.3,410.4,775.2,410.6,775z"/>
			<path d="M412.8,773.5h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V773.5z"/>
			<path d="M416.2,775.5v1.2h-0.4v-1.2l-1.4-2.3h0.4l1.2,2l1.2-2h0.4L416.2,775.5z"/>
			<path d="M420.2,776.5c-0.3-0.2-0.5-0.4-0.7-0.7s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.7s0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.6
				c-0.3,0.2-0.6,0.2-1,0.2S420.4,776.7,420.2,776.5z M421.9,776.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C421.4,776.4,421.7,776.4,421.9,776.2z"/>
			<path d="M424.2,773.5v1.4h1.8v0.3h-1.8v1.5h-0.4v-3.6h2.4v0.3H424.2z"/>
			<path d="M428.4,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M432.2,775.8h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L432.2,775.8z M432,775.5l-0.9-1.9l-0.9,1.9H432z"/>
			<path d="M433.6,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M435.1,773.2h1.5c0.4,0,0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6s-0.6,0.2-1,0.2h-1.5V773.2z M436.6,776.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H436.6z"/>
			<path d="M439.4,773.2h0.4v3.2h2v0.3h-2.4V773.2z"/>
			<path d="M443.7,773.2h0.4v3.2h2v0.3h-2.4V773.2z"/>
			<path d="M446.6,776.7c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2s0.1-0.1,0.2-0.1s0.1,0,0.2,0.1s0.1,0.1,0.1,0.2s0,0.1-0.1,0.2
				s-0.1,0.1-0.2,0.1S446.7,776.7,446.6,776.7z"/>
			<path d="M447.9,773.2h0.4v3.2h2v0.3h-2.4V773.2z"/>
			<path d="M450.9,776.7c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2s0.1-0.1,0.2-0.1s0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
				s0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1S450.9,776.7,450.9,776.7z"/>
			<path d="M452.7,776.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				s0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4s-0.5,0.1-0.7,0.1C453.3,776.8,452.9,776.7,452.7,776.5z"/>
			<path d="M459.3,775.8h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L459.3,775.8z M459.2,775.5l-0.9-1.9l-0.9,1.9H459.2z"/>
			<path d="M463.7,773.2v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H463.7z"/>
			<path d="M464.9,773.2h1.5c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V773.2z M466.3,776.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2h-1.1v2.9H466.3z"/>
			<path d="M474.1,773.2v3.6h-0.4v-2.9l-1.4,2.4h-0.2l-1.4-2.4v2.8h-0.4v-3.6h0.3l1.5,2.6l1.5-2.6H474.1z"/>
			<path d="M475.6,776.4c-0.3-0.3-0.4-0.7-0.4-1.2v-2h0.4v2c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3
				s0.3-0.5,0.3-0.9v-2h0.4v2c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4C476.3,776.8,475.9,776.6,475.6,776.4z"/>
			<path d="M479.5,776.6c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3s-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1
				C480,776.8,479.8,776.7,479.5,776.6z"/>
			<path d="M483.1,773.5h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V773.5z"/>
			<path d="M489.5,775.2c0.1,0.2,0.2,0.3,0.2,0.6c0,0.3-0.1,0.5-0.3,0.7s-0.6,0.2-1,0.2h-1.6v-3.6h1.5c0.4,0,0.7,0.1,0.9,0.2
				c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.4,0.3C489.2,774.9,489.3,775,489.5,775.2z M487,773.5v1.3h1.1
				c0.3,0,0.5-0.1,0.7-0.2s0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2H487z M489,776.3c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.4-0.3-0.7-1-0.7H487v1.3h1.3C488.6,776.4,488.9,776.4,489,776.3z"/>
			<path d="M493,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H493z"/>
			<path d="M497.7,776.7l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H497.7z M497.4,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C496.9,775.3,497.2,775.2,497.4,775z"/>
			<path d="M501.5,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H501.5z"/>
			<path d="M503.1,773.5h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V773.5z"/>
			<path d="M505.6,776.4c-0.3-0.3-0.4-0.7-0.4-1.2v-2h0.4v2c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.9v-2h0.4v2c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4S505.8,776.6,505.6,776.4z"/>
			<path d="M511.7,776.7l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H511.7z M511.4,775c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				s-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C510.9,775.3,511.2,775.2,511.4,775z"/>
			<path d="M515.9,773.2v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H515.9z"/>
			<path d="M519.6,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H519.6z"/>
			<path d="M520.5,773.2h1.5c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6s0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V773.2z M521.9,776.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H521.9z"/>
			<path d="M526,773.2h0.4v3.6H526V773.2z"/>
			<path d="M531.3,773.2v3.6h-0.4v-2.9l-1.4,2.4h-0.2l-1.4-2.4v2.8h-0.4v-3.6h0.3l1.5,2.6l1.5-2.6H531.3z"/>
			<path d="M536.2,773.2v3.6h-0.4v-2.9l-1.4,2.4h-0.2l-1.4-2.4v2.8h-0.4v-3.6h0.3l1.6,2.6l1.5-2.6H536.2z"/>
			<path d="M539.8,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H539.8z"/>
			<path d="M540.7,773.2h1.5c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6s0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V773.2z M542.2,776.4c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H542.2z"/>
			<path d="M544.9,773.2h0.4v3.6h-0.4V773.2z"/>
			<path d="M548.7,775.8h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L548.7,775.8z M548.6,775.5l-0.9-1.9l-0.9,1.9H548.6z"/>
			<path d="M550.7,773.5h-1.3v-0.3h2.9v0.3H551v3.2h-0.4V773.5z"/>
			<path d="M555.4,776.4v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H555.4z"/>
			<path d="M556.3,773.2h0.4v3.2h2v0.3h-2.4V773.2z"/>
			<path d="M560.2,775.5v1.2h-0.4v-1.2l-1.4-2.3h0.4l1.2,2l1.2-2h0.4L560.2,775.5z"/>
			<path d="M331.5,782.5c-0.3-0.3-0.4-0.7-0.4-1.2v-2h0.4v2c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3
				s0.3-0.5,0.3-0.9v-2h0.4v2c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4C332.1,782.9,331.7,782.8,331.5,782.5z"/>
			<path d="M337.5,779.6c0.3,0.2,0.4,0.5,0.4,0.9s-0.1,0.7-0.4,0.9s-0.6,0.3-1.1,0.3h-1v1.1h-0.4v-3.6h1.3
				C336.9,779.3,337.3,779.4,337.5,779.6z M337.3,781.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2
				h-0.9v1.8h0.9C336.8,781.4,337.1,781.3,337.3,781.2z"/>
			<path d="M339.4,782.6c-0.3-0.2-0.5-0.4-0.7-0.6s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9s0.4-0.5,0.7-0.7s0.6-0.2,1-0.2
				s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				c-0.3,0.2-0.6,0.2-1,0.2S339.7,782.8,339.4,782.6z M341.1,782.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C340.6,782.5,340.9,782.5,341.1,782.4z"/>
			<path d="M346.1,779.3v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H346.1z"/>
			<path d="M351,782.9l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.3-0.6,0.4l0.9,1.2H351z M350.7,781.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C350.2,781.4,350.5,781.3,350.7,781.2z"/>
			<path d="M354.8,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H354.8z"/>
			<path d="M359.2,783.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.2s-0.4-0.3-0.6-0.5
				c-0.3,0-0.7-0.1-0.9-0.3s-0.5-0.4-0.6-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				c0.3-0.2,0.6-0.2,1-0.2s0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.5-0.5,0.6
				c-0.2,0.2-0.5,0.3-0.8,0.3c0.3,0.3,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.7-0.3L359.2,783.2z M355.9,781.8c0.1,0.2,0.3,0.4,0.5,0.5
				s0.5,0.2,0.8,0.2s0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5
				c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
				C355.7,781.3,355.8,781.6,355.9,781.8z"/>
			<path d="M360.3,782.5c-0.3-0.3-0.4-0.7-0.4-1.2v-2h0.4v2c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.9v-2h0.4v2c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4S360.5,782.8,360.3,782.5z"/>
			<path d="M366.5,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H366.5z"/>
			<path d="M367.6,782.8c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				s0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C368.1,782.9,367.8,782.8,367.6,782.8z"/>
			<path d="M371.1,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M373,782.8c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2s0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
				s0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1C373.1,782.9,373,782.9,373,782.8z"/>
			<path d="M376.3,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M379.1,782.6c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				s0.6-0.2,1-0.2s0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				s-0.6,0.2-1,0.2S379.4,782.8,379.1,782.6z M380.8,782.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8
				c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
				c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2S380.6,782.5,380.8,782.4z"/>
			<path d="M384.7,782.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1C385.3,782.9,385,782.8,384.7,782.6z"/>
			<path d="M390.8,779.3v3.6h-0.4v-1.6h-2.2v1.6h-0.4v-3.6h0.4v1.6h2.2v-1.6H390.8z"/>
			<path d="M394.5,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H394.5z"/>
			<path d="M395.9,782.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1C396.5,782.9,396.2,782.8,395.9,782.6z"/>
			<path d="M400.1,781.2l-0.7,0.7v0.9H399v-3.6h0.4v2.2l2.1-2.2h0.4l-1.5,1.6l1.7,2h-0.4L400.1,781.2z"/>
			<path d="M403.9,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M406.2,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M408.6,782.8c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				s0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C409.1,782.9,408.8,782.8,408.6,782.8z"/>
			<path d="M415.8,779.3l-1.6,3.6h-0.4l-1.6-3.6h0.4l1.4,3.1l1.4-3.1H415.8z"/>
			<path d="M418.4,781.9h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L418.4,781.9z M418.3,781.6l-0.9-1.9l-0.9,1.9H418.3z"/>
			<path d="M419.9,779.3h0.4v3.2h2v0.3h-2.4V779.3z"/>
			<path d="M422.9,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M424.4,779.3h1.5c0.4,0,0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V779.3z M425.8,782.5c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H425.8z"/>
			<path d="M429.7,781.6v1.2h-0.4v-1.2l-1.4-2.3h0.4l1.2,2l1.2-2h0.4L429.7,781.6z"/>
			<path d="M435.4,779.6c0.3,0.2,0.4,0.5,0.4,0.9s-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.3-1.1,0.3h-1v1.1H433v-3.6h1.3
				C434.8,779.3,435.2,779.4,435.4,779.6z M435.2,781.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2
				h-0.9v1.8h0.9C434.7,781.4,435,781.3,435.2,781.2z"/>
			<path d="M436.6,779.3h0.4v3.2h2v0.3h-2.4V779.3z"/>
			<path d="M442.2,782.5v0.3h-2.5v-3.6h2.4v0.3H440v1.3h1.8v0.3H440v1.3H442.2z"/>
			<path d="M445.3,781.9h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L445.3,781.9z M445.2,781.6l-0.9-1.9l-0.9,1.9H445.2z"/>
			<path d="M446.9,782.8c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				s0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C447.4,782.9,447.2,782.8,446.9,782.8z"/>
			<path d="M452.4,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H452.4z"/>
			<path d="M459.5,779.3l-1.2,3.6h-0.4l-1-3l-1,3h-0.4l-1.2-3.6h0.4l1,3.1l1.1-3.1h0.4l1.1,3.1l1-3.1H459.5z"/>
			<path d="M462.6,782.9l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3s0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H462.6z M462.3,781.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C461.9,781.4,462.1,781.3,462.3,781.2z"/>
			<path d="M463.9,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M466.1,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M470.9,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H470.9z"/>
			<path d="M473.8,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M476.6,782.6c-0.3-0.2-0.5-0.4-0.7-0.6s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9s0.4-0.5,0.7-0.7s0.6-0.2,1-0.2
				s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				c-0.3,0.2-0.6,0.2-1,0.2S476.9,782.8,476.6,782.6z M478.3,782.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C477.8,782.5,478.1,782.5,478.3,782.4z"/>
			<path d="M481.6,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M485.4,781.9h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L485.4,781.9z M485.2,781.6l-0.9-1.9l-0.9,1.9H485.2z"/>
			<path d="M486.8,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M488.3,779.3h1.5c0.4,0,0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V779.3z M489.8,782.5c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H489.8z"/>
			<path d="M492.5,779.3h0.4v3.2h2v0.3h-2.4V779.3z"/>
			<path d="M497.5,779.3v3.6h-0.4v-3.2h-0.8v-0.3H497.5z"/>
			<path d="M500.5,780.8c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.4
				c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.3c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.5,0.6-0.6
				c0.2-0.1,0.5-0.2,0.9-0.2c0.4,0,0.6,0.1,0.8,0.2l-0.1,0.3c-0.2-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1
				c0,0.1,0,0.2,0,0.3c0.1-0.2,0.2-0.3,0.4-0.5c0.2-0.1,0.4-0.2,0.7-0.2C500.1,780.7,500.3,780.8,500.5,780.8z M500.5,782.4
				c0.2-0.1,0.2-0.3,0.2-0.6s-0.1-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.3,0-0.5,0.1s-0.2,0.2-0.3,0.3s-0.1,0.3-0.1,0.4
				c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1C500.1,782.6,500.3,782.5,500.5,782.4z"/>
			<path d="M502.2,782.7c-0.2-0.1-0.4-0.4-0.5-0.6s-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.2-1s0.3-0.5,0.5-0.6s0.5-0.2,0.7-0.2
				c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.6
				c-0.2,0.1-0.5,0.2-0.7,0.2C502.6,782.9,502.4,782.8,502.2,782.7z M503.5,782.4c0.2-0.1,0.3-0.3,0.4-0.5s0.1-0.5,0.1-0.8
				c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.3-0.4,0.5
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0.1,0.8c0.1,0.2,0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.2C503.1,782.5,503.3,782.5,503.5,782.4z"
				/>
			<path d="M506.9,781.1c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
				s-0.4-0.2-0.6-0.3l0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3v-0.3l1-1.2h-1.8v-0.3h2.3v0.3l-1,1.3C506.4,780.8,506.7,780.9,506.9,781.1
				z"/>
			<path d="M509.9,782.6c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4L512,780c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1C510.5,782.9,510.2,782.8,509.9,782.6z"/>
			<path d="M515.2,781.9h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L515.2,781.9z M515,781.6l-0.9-1.9l-0.9,1.9H515z"/>
			<path d="M519,779.6c0.3,0.2,0.4,0.5,0.4,0.9s-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.3-1.1,0.3h-1v1.1h-0.4v-3.6h1.3
				C518.4,779.3,518.7,779.4,519,779.6z M518.7,781.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-0.8-0.2H517
				v1.8h0.9C518.3,781.4,518.5,781.3,518.7,781.2z"/>
			<path d="M520.2,779.3h0.4v3.6h-0.4V779.3z"/>
			<path d="M522.4,779.6h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V779.6z"/>
			<path d="M525.2,782.6c-0.3-0.2-0.5-0.4-0.7-0.6s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9s0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.2,1-0.2
				s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				c-0.3,0.2-0.6,0.2-1,0.2S525.5,782.8,525.2,782.6z M526.9,782.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C526.4,782.5,526.7,782.5,526.9,782.4z"/>
			<path d="M528.9,779.3h0.4v3.2h2v0.3h-2.4V779.3z"/>
			<path d="M535.5,781.9h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L535.5,781.9z M535.3,781.6l-0.9-1.9l-0.9,1.9H535.3z"/>
			<path d="M539.7,779.3l-1.6,3.6h-0.4l-1.6-3.6h0.4l1.4,3.1l1.4-3.1H539.7z"/>
			<path d="M542.7,782.5v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H542.7z"/>
			<path d="M543.4,782.8c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2s0.1-0.1,0.2-0.1s0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
				s0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1S543.5,782.9,543.4,782.8z"/>
			<path d="M331.3,788.9c-0.2-0.1-0.4-0.2-0.6-0.3l0.1-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.3,0,0.6-0.1,0.7-0.2
				s0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.7-0.1
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.1,0.3c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.2
				c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.3,0.1,0.5,0.2c0.3,0.1,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C331.8,789,331.6,789,331.3,788.9z"/>
			<path d="M334.6,788.6c-0.3-0.3-0.4-0.7-0.4-1.2v-2h0.4v2c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3
				s0.3-0.5,0.3-0.9v-2h0.4v2c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1.1,0.4C335.2,789,334.8,788.9,334.6,788.6z"/>
			<path d="M338.3,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M340.5,785.7h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V785.7z"/>
			<path d="M345.3,788.6v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H345.3z"/>
			<path d="M349.2,787.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
				s-0.4-0.2-0.6-0.3l0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.3s0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3V787l1-1.2h-1.8v-0.3h2.3v0.3l-1,1.3C348.8,787,349,787.1,349.2,787.2z"/>
			<path d="M351,785.4v3.6h-0.4v-3.2h-0.8v-0.3H351z"/>
			<path d="M352.6,788.8c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.3-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.5,0.5-0.6
				c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.4,0.5,0.6s0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1s-0.3,0.5-0.5,0.6
				c-0.2,0.1-0.5,0.2-0.7,0.2C353,789,352.8,788.9,352.6,788.8z M353.9,788.5c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.5,0.1-0.8
				c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.3-0.4,0.5
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.2C353.5,788.7,353.7,788.6,353.9,788.5z"/>
			<path d="M359.2,788h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L359.2,788z M359,787.7l-0.9-1.9l-0.9,1.9H359z"/>
			<path d="M362.4,787.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
				s-0.4-0.2-0.6-0.3l0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.3s0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3V787l1-1.2h-1.8v-0.3h2.3v0.3l-1,1.3C361.9,787,362.2,787.1,362.4,787.2z"
				/>
			<path d="M365.2,787.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
				s-0.4-0.2-0.6-0.3l0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3V787l1-1.2h-1.8v-0.3h2.3v0.3l-1,1.3C364.7,787,365,787.1,365.2,787.2z"/>
			<path d="M368.1,787.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
				s-0.4-0.2-0.6-0.3l0.2-0.3c0.1,0.1,0.3,0.2,0.5,0.3s0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3V787l1-1.2h-1.8v-0.3h2.3v0.3l-1,1.3C367.6,787,367.9,787.1,368.1,787.2z"
				/>
			<path d="M371.1,788.8c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1C371.7,789,371.4,788.9,371.1,788.8z"/>
			<path d="M377.2,785.4v3.6h-0.4v-1.6h-2.2v1.6h-0.4v-3.6h0.4v1.6h2.2v-1.6H377.2z"/>
			<path d="M380.9,788.6v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H380.9z"/>
			<path d="M382.9,787.7v1.2h-0.4v-1.2l-1.4-2.3h0.4l1.2,2l1.2-2h0.4L382.9,787.7z"/>
			<path d="M387.4,788.6v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H387.4z"/>
			<path d="M391.3,785.4v3.6H391l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H391.3z"/>
			<path d="M395.4,785.4v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H395.4z"/>
			<path d="M399.1,788.6v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H399.1z"/>
			<path d="M400.2,788.5c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.2l-0.2,0.7h-0.2l0.2-0.7c-0.1,0-0.1,0-0.1-0.1
				c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.2-0.1S400.1,788.5,400.2,788.5z"/>
			<path d="M407.4,785.4l-1.2,3.6h-0.4l-1-3l-1.1,3h-0.4l-1.2-3.6h0.4l1,3.1l1.1-3.1h0.4l1,3.1l1-3.1H407.4z"/>
			<path d="M409.2,787.7v1.2h-0.4v-1.2l-1.4-2.3h0.4l1.2,2l1.2-2h0.4L409.2,787.7z"/>
			<path d="M411.7,788.8c-0.3-0.2-0.5-0.4-0.7-0.6s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9s0.4-0.5,0.7-0.7s0.6-0.2,1-0.2
				s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				c-0.3,0.2-0.6,0.2-1,0.2S412,788.9,411.7,788.8z M413.4,788.5c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C412.9,788.7,413.2,788.6,413.4,788.5z"/>
			<path d="M419.1,785.4v3.6h-0.4v-2.9l-1.4,2.4h-0.2l-1.4-2.4v2.8h-0.4v-3.6h0.3l1.5,2.6l1.5-2.6H419.1z"/>
			<path d="M420.3,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M424.8,785.4v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H424.8z"/>
			<path d="M428.5,787.2h0.4v1.4c-0.2,0.1-0.4,0.3-0.6,0.3s-0.5,0.1-0.7,0.1c-0.4,0-0.7-0.1-1-0.2s-0.5-0.4-0.7-0.7
				c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6s0.6-0.2,1-0.2c0.3,0,0.5,0,0.8,0.1
				c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,0.9-0.3V787.2z
				"/>
			<path d="M433.5,787.4c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.5,0.4s-0.5,0.1-0.7,0.1
				c-0.4,0-0.8-0.1-1-0.3s-0.4-0.4-0.4-0.7c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.3,0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.3
				c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.3,0.1-0.5,0.3-0.7s0.5-0.3,0.9-0.3c0.3,0,0.5,0,0.7,0.1s0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3C433.2,787.2,433.3,787.3,433.5,787.4z M433,788.5c0.2-0.1,0.3-0.3,0.3-0.5
				c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.7,0.2c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5
				c0.2,0.1,0.4,0.2,0.7,0.2C432.6,788.7,432.8,788.6,433,788.5z M431.6,786.8c0.2,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2
				c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.5
				S431.5,786.7,431.6,786.8z"/>
			<path d="M436.6,788.6v0.3h-2.5v-0.3l1.5-1.5c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.4-0.2-0.5
				s-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.1-1,0.4l-0.3-0.2c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.4,0,0.7,0.1,0.9,0.3
				c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.4-0.5,0.6l-1.2,1.2H436.6z"/>
			<path d="M437.7,788.8c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.3-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.5,0.5-0.6
				c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.4,0.5,0.6s0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1s-0.3,0.5-0.5,0.6
				c-0.2,0.1-0.5,0.2-0.7,0.2C438.2,789,437.9,788.9,437.7,788.8z M439,788.5c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.5,0.1-0.8
				c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.3-0.4,0.5
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.2C438.7,788.7,438.8,788.6,439,788.5z"/>
			<path d="M441.1,788.8c-0.2-0.1-0.4-0.4-0.5-0.6s-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.2-1s0.3-0.5,0.5-0.6s0.5-0.2,0.7-0.2
				c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.6
				c-0.2,0.1-0.5,0.2-0.7,0.2C441.6,789,441.3,788.9,441.1,788.8z M442.4,788.5c0.2-0.1,0.3-0.3,0.4-0.5s0.1-0.5,0.1-0.8
				c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.3-0.4,0.5
				c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0.1,0.8c0.1,0.2,0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.2C442,788.7,442.2,788.6,442.4,788.5z"/>
			<path d="M444.8,785.4v3.6h-0.4v-3.2h-0.8v-0.3H444.8z"/>
			<path d="M447.9,788.8c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				c0.3-0.2,0.6-0.2,1-0.2s0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				s-0.6,0.2-1,0.2S448.1,788.9,447.9,788.8z M449.6,788.5c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8
				c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
				c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2S449.4,788.6,449.6,788.5z"/>
			<path d="M454,789l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3s0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H454z M453.7,787.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C453.2,787.5,453.5,787.4,453.7,787.3z"/>
			<path d="M458.8,788h-2l-0.4,1H456l1.6-3.6h0.4l1.6,3.6h-0.4L458.8,788z M458.7,787.7l-0.9-1.9l-0.9,1.9H458.7z"/>
			<path d="M460.8,788.8c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				s0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5s0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4s-0.5,0.1-0.7,0.1C461.4,789,461,788.9,460.8,788.8z"/>
			<path d="M464.3,788.8c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6
				c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2
				c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				c0.4,0,0.8-0.1,1.1-0.4l0.2,0.2c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1C465,789,464.6,788.9,464.3,788.8z"/>
			<path d="M469.9,789l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1h-0.4v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3s0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H469.9z M469.6,787.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C469.1,787.5,469.4,787.4,469.6,787.3z"/>
			<path d="M473.6,788.6v0.3h-2.5v-3.6h2.4v0.3h-2.1v1.3h1.8v0.3h-1.8v1.3H473.6z"/>
			<path d="M474.5,785.4h1.5c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V785.4z M476,788.6c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2h-1.1v2.9H476z"/>
			<path d="M478.7,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M481,785.7h-1.3v-0.3h2.9v0.3h-1.3v3.2H481V785.7z"/>
			<path d="M485.3,788h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L485.3,788z M485.1,787.7l-0.9-1.9l-0.9,1.9H485.1z"/>
			<path d="M487.2,785.7h-1.3v-0.3h2.9v0.3h-1.3v3.2h-0.4V785.7z"/>
			<path d="M489.4,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M491.6,788.8c-0.3-0.2-0.5-0.4-0.7-0.6s-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9s0.4-0.5,0.7-0.7s0.6-0.2,1-0.2
				s0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				c-0.3,0.2-0.6,0.2-1,0.2S491.8,788.9,491.6,788.8z M493.3,788.5c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8
				c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2
				C492.8,788.7,493.1,788.6,493.3,788.5z"/>
			<path d="M498.2,785.4v3.6h-0.3l-2.3-2.9v2.9h-0.4v-3.6h0.3l2.3,2.9v-2.9H498.2z"/>
			<path d="M502.7,785.3c0.4,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.2c0,0.4-0.1,0.8-0.2,1c-0.1,0.2-0.4,0.4-0.6,0.4
				c-0.2,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.4c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5-0.1-0.6-0.2
				c-0.2-0.1-0.3-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.6-0.2
				c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4V786h0.3v1.9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.3
				c0.1-0.2,0.1-0.5,0.1-0.8c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.3
				c-0.3,0.2-0.6,0.4-0.7,0.7c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.3,0.4,0.6,0.7,0.7c0.3,0.2,0.7,0.3,1.1,0.3
				c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.2l0.1,0.3c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.5,0-0.9-0.1-1.2-0.3
				c-0.4-0.2-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2c0-0.4,0.1-0.8,0.3-1.2c0.2-0.3,0.5-0.6,0.8-0.8c0.4-0.2,0.8-0.3,1.2-0.3
				C501.9,785,502.3,785.1,502.7,785.3z M501.9,788.2c0.1-0.1,0.3-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4-0.1-0.5
				s-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1s-0.3,0.2-0.3,0.4c-0.1,0.2-0.1,0.3-0.1,0.5
				c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4s0.3,0.1,0.5,0.1C501.6,788.4,501.8,788.3,501.9,788.2z"/>
			<path d="M504.7,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M508.4,788h-2l-0.4,1h-0.4l1.6-3.6h0.4l1.6,3.6h-0.4L508.4,788z M508.3,787.7l-0.9-1.9l-0.9,1.9H508.3z"/>
			<path d="M509.8,785.4h0.4v3.6h-0.4V785.4z"/>
			<path d="M511.4,785.4h1.5c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.1,0.7-0.2,0.9
				s-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-1,0.2h-1.5V785.4z M512.8,788.6c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v2.9H512.8z"/>
			<path d="M515.6,785.4h0.4v3.2h2v0.3h-2.4V785.4z"/>
			<path d="M518.5,788.9c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2s0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
				s0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1C518.6,789,518.6,789,518.5,788.9z"/>
			<path d="M520.3,788.8c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.7
				c0.3-0.2,0.6-0.2,1-0.2s0.7,0.1,1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7
				s-0.6,0.2-1,0.2S520.6,788.9,520.3,788.8z M522.1,788.5c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3-0.1-0.5-0.2-0.8
				c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8
				c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2S521.8,788.6,522.1,788.5z"/>
			<path d="M526.5,789l-0.8-1.1c-0.1,0-0.2,0-0.3,0h-1v1.1H524v-3.6h1.3c0.5,0,0.8,0.1,1.1,0.3s0.4,0.5,0.4,0.9
				c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.4l0.9,1.2H526.5z M526.2,787.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.5-0.2-0.8-0.2h-0.9v1.8h0.9C525.7,787.5,526,787.4,526.2,787.3z"/>
			<path d="M530.2,787.2h0.4v1.4c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.4,0-0.7-0.1-1-0.2s-0.5-0.4-0.7-0.7
				c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.2-0.3,0.4-0.5,0.7-0.6s0.6-0.2,1-0.2c0.3,0,0.5,0,0.7,0.1
				c0.2,0.1,0.4,0.2,0.6,0.4l-0.2,0.2c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,0.9-0.3V787.2z"/>
		</g>
		<g>
			<path class="st1519" d="M375,765.3h-41.5c-1.7,0-3-1.4-3-3v-12.8c0-1.7,1.4-3,3-3H375c1.7,0,3,1.4,3,3v12.8
				C378.1,763.9,376.7,765.3,375,765.3z M330.8,749.7V762c0,1.7,1.4,3,3,3h41c1.7,0,3-1.4,3-3v-12.2c0-1.7-1.4-3-3-3h-41
				C332.1,746.7,330.8,748.1,330.8,749.7z"/>
			<g>
				<path class="st1519" d="M340.1,752.6v10.8h-3.4v-10.8H340.1z"/>
				<path class="st1519" d="M348.6,761.8H345l-0.5,1.6h-3.6l4-10.8h3.9l4,10.8h-3.6L348.6,761.8z M347.8,759.3l-1-3l-1,3H347.8z"/>
				<path class="st1519" d="M356.9,752.6v10.8h-3.4v-10.8H356.9z"/>
				<path class="st1519" d="M367.9,760.8c-0.5,0.8-1.1,1.5-2,2c-0.9,0.5-1.8,0.7-3,0.7h-4.5v-10.8h4.5c1.1,0,2.1,0.2,3,0.7
					c0.9,0.5,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.8C368.6,759.1,368.4,760,367.9,760.8z M364.5,759.8c0.4-0.4,0.7-1,0.7-1.8
					c0-0.8-0.2-1.4-0.7-1.8c-0.4-0.4-1.1-0.6-1.8-0.6h-0.8v4.8h0.8C363.4,760.5,364,760.2,364.5,759.8z"/>
				<path class="st1519" d="M373.2,760.9h3.3v2.6h-6.7v-10.8h3.4V760.9z"/>
			</g>
			<g>
				<g>
					<path class="st1519" d="M333.3,758.1c0.2-0.2,0.2-0.5,0.1-0.7l1.2-1.2c0.1-0.1,0.2-0.1,0.3-0.1h0.6v0.4c0,0.1,0,0.2,0,0.2
						c0,0.1,0.1,0.2,0.2,0.1c0.1,0,0.2-0.1,0.1-0.2c0,0,0-0.1,0-0.2v-4.4c0-0.3,0.3-0.6,0.6-0.6h4.4c0.1,0,0.1,0,0.2,0
						c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0h-0.4v-1.6c0.2-0.1,0.4-0.3,0.4-0.6c0-0.3-0.3-0.6-0.6-0.6
						s-0.6,0.3-0.6,0.6c0,0.3,0.2,0.5,0.4,0.6v1.6h-0.4v-0.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v0.8h-0.4v-3
						c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2v3H338v-0.8c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2v0.8h-0.4v-0.6
						c0-0.2-0.1-0.4-0.2-0.6l-1.2-1.2c0-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2
						s-0.2,0.3-0.2,0.4s0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1l1.2,1.2c0.1,0.1,0.1,0.2,0.1,0.3v0.6h-0.4
						c-0.5,0-1,0.4-1,1v0.4h-1.6c-0.1-0.2-0.3-0.4-0.6-0.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.5-0.2,0.6-0.4h1.6
						v0.4h-0.8c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2h0.8v0.4h-3c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2h3v0.4h-0.8
						c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2h0.8v0.4h-0.6c-0.2,0-0.4,0.1-0.6,0.2l-1.2,1.2c-0.2-0.1-0.5-0.1-0.7,0.1
						c-0.1,0.1-0.2,0.3-0.2,0.4s0.1,0.3,0.2,0.4C332.7,758.3,333,758.3,333.3,758.1z M340,748.9c0-0.1,0.1-0.2,0.2-0.2
						c0.1,0,0.2,0.1,0.2,0.2s-0.1,0.2-0.2,0.2C340.1,749.2,340,749.1,340,748.9z M335.1,748.7c0,0-0.1-0.1-0.1-0.1
						c0-0.1,0-0.1,0.1-0.1s0.1-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
						S335.1,748.7,335.1,748.7z M333.2,752.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
						C333.5,752.8,333.4,752.9,333.2,752.9z M332.7,757.8c-0.1-0.1-0.1-0.2,0-0.3s0.2-0.1,0.3,0c0,0,0.1,0.1,0.1,0.1
						c0,0.1,0,0.1-0.1,0.1C332.9,757.9,332.8,757.9,332.7,757.8z"/>
				</g>
			</g>
			<g>
				<path class="st1519" d="M381.9,748v3.3h-1V748H381.9z"/>
				<path class="st1519" d="M385.6,751.4h-1l-1.1-1.7v1.7h-1V748h1l1.1,1.7V748h1V751.4z"/>
				<path class="st1519" d="M388.7,748v0.8h-0.9v2.5h-1v-2.5h-0.9V748H388.7z"/>
				<path class="st1519" d="M390,748.8v0.4h1v0.8h-1v0.5h1.2v0.8h-2.2V748h2.2v0.8H390z"/>
				<path class="st1519" d="M393.3,751.4l-0.6-1.2v1.2h-1V748h1.6c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4
					c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4l0.7,1.3H393.3z M392.6,749.5h0.4c0.1,0,0.2,0,0.2-0.1
					c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1h-0.4V749.5z"/>
				<path class="st1519" d="M398,751.4h-1l-1.1-1.7v1.7h-1V748h1l1.1,1.7V748h1V751.4z"/>
				<path class="st1519" d="M400.6,750.9h-1.1l-0.2,0.5h-1.1l1.2-3.3h1.2l1.2,3.3h-1.1L400.6,750.9z M400.3,750.1l-0.3-0.9l-0.3,0.9
					H400.3z"/>
				<path class="st1519" d="M404.7,748v0.8h-0.9v2.5h-1v-2.5h-0.9V748H404.7z"/>
				<path class="st1519" d="M406,748v3.3h-1V748H406z"/>
				<path class="st1519" d="M409,748.2c0.3,0.1,0.5,0.4,0.6,0.6s0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9c-0.2,0.3-0.4,0.5-0.6,0.6
					s-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2s-0.5-0.4-0.6-0.6c-0.2-0.3-0.2-0.6-0.2-0.9s0.1-0.6,0.2-0.9s0.4-0.5,0.6-0.6
					c0.3-0.1,0.6-0.2,0.9-0.2C408.5,748,408.7,748,409,748.2z M407.7,749.1c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5
					s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
					C407.9,748.9,407.8,749,407.7,749.1z"/>
				<path class="st1519" d="M413.4,751.4h-1l-1.1-1.7v1.7h-1V748h1l1.1,1.7V748h1V751.4z"/>
				<path class="st1519" d="M416.1,750.9h-1.1l-0.2,0.5h-1.1l1.2-3.3h1.2l1.2,3.3h-1.1L416.1,750.9z M415.8,750.1l-0.3-0.9l-0.3,0.9
					H415.8z"/>
				<path class="st1519" d="M418.6,750.6h1v0.8h-2.1V748h1V750.6z"/>
				<path class="st1519" d="M385.7,758.3h-2.4l-0.4,1.1h-2.4l2.6-7.2h2.6l2.6,7.2h-2.4L385.7,758.3z M385.2,756.6l-0.6-2l-0.6,2
					H385.2z"/>
				<path class="st1519" d="M391.2,752.2v7.2h-2.3v-7.2H391.2z"/>
				<path class="st1519" d="M383.8,762.6c-0.1,0.3-0.3,0.5-0.6,0.6c-0.3,0.1-0.6,0.2-0.9,0.2h-1.4V760h1.4c0.3,0,0.7,0.1,0.9,0.2
					c0.3,0.1,0.5,0.3,0.6,0.6c0.1,0.3,0.2,0.5,0.2,0.9C384,762,383.9,762.3,383.8,762.6z M382.7,762.2c0.1-0.1,0.2-0.3,0.2-0.6
					c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.6-0.2h-0.3v1.5h0.3C382.4,762.4,382.6,762.4,382.7,762.2z"/>
				<path class="st1519" d="M386,763.4l-0.6-1.2v1.2h-1V760h1.6c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4
					c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4l0.7,1.3H386z M385.4,761.5h0.4c0.1,0,0.2,0,0.2-0.1
					c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1h-0.4V761.5z"/>
				<path class="st1519" d="M388.6,760v3.3h-1V760H388.6z"/>
				<path class="st1519" d="M389.9,760l0.7,2.3l0.7-2.3h1.1l-1.1,3.3h-1.4l-1.1-3.3H389.9z"/>
				<path class="st1519" d="M393.7,760v3.3h-1V760H393.7z"/>
				<path class="st1519" d="M397.4,763.4h-1l-1.1-1.7v1.7h-1V760h1l1.1,1.7V760h1V763.4z"/>
				<path class="st1519" d="M400.5,760.3c0.3,0.2,0.4,0.5,0.5,0.9h-1.1c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
					c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.3,0.1,0.5,0.2,0.6c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.3h-0.8
					v-0.7h1.7v1c-0.1,0.2-0.3,0.5-0.6,0.6c-0.3,0.2-0.6,0.3-1,0.3c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.6-0.6
					c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.6c0.3-0.1,0.6-0.2,0.9-0.2
					C399.9,760,400.2,760.1,400.5,760.3z"/>
				<path class="st1519" d="M403.3,762.6h1v0.8h-2.1V760h1V762.6z"/>
				<path class="st1519" d="M405.6,760v3.3h-1V760H405.6z"/>
				<path class="st1519" d="M408.5,760.2c0.2,0.1,0.4,0.3,0.6,0.5s0.2,0.4,0.3,0.7h-1.1c-0.1-0.1-0.1-0.2-0.2-0.3
					c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.2,0.4,0.2
					c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.3h1.1c0,0.3-0.1,0.5-0.3,0.7s-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.8,0.2
					c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.6
					c0.2-0.1,0.5-0.2,0.9-0.2C408,760,408.2,760,408.5,760.2z"/>
				<path class="st1519" d="M410.7,760.9v0.4h1v0.8h-1v0.5h1.2v0.8h-2.2V760h2.2v0.8H410.7z"/>
				<path class="st1519" d="M415.5,763.4h-1l-1.1-1.7v1.7h-1V760h1l1.1,1.7V760h1V763.4z"/>
				<path class="st1519" d="M418.4,762.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.4,0-0.7-0.1-1-0.3
					c-0.3-0.2-0.4-0.5-0.4-0.8h1.1c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2
					c-0.1,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6
					c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.4,0,0.7,0.1,1,0.3c0.2,0.2,0.4,0.5,0.4,0.8h-1.1c0-0.2-0.1-0.3-0.2-0.3
					c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0.1,0.3,0.2,0.4,0.3
					c0.1,0.1,0.2,0.3,0.2,0.6C418.6,762.6,418.5,762.8,418.4,762.9z"/>
				<path class="st1519" d="M420,760.9v0.4h1v0.8h-1v0.5h1.2v0.8h-2.2V760h2.2v0.8H420z"/>
			</g>
		</g>
	</g>
	<g class="st1505">
		<path d="M40.6,748.5c-0.7-0.3-1.3-0.6-1.7-1l0.4-0.9c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.3,0.4,2,0.4c0.9,0,1.6-0.2,2.1-0.5
			c0.5-0.3,0.7-0.8,0.7-1.3c0-0.4-0.1-0.8-0.4-1c-0.3-0.2-0.6-0.4-0.9-0.6c-0.4-0.1-0.9-0.3-1.5-0.4c-0.8-0.2-1.4-0.4-1.9-0.6
			c-0.5-0.2-0.9-0.5-1.2-0.8s-0.5-0.9-0.5-1.5c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.7-0.8,1.3-1s1.3-0.4,2.2-0.4c0.6,0,1.2,0.1,1.8,0.2
			c0.6,0.2,1.1,0.4,1.5,0.7l-0.4,0.9c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.1-1-0.2-1.5-0.2c-0.9,0-1.6,0.2-2.1,0.5
			c-0.5,0.4-0.7,0.8-0.7,1.4c0,0.4,0.1,0.8,0.4,1s0.6,0.5,1,0.6c0.4,0.1,0.9,0.3,1.6,0.4c0.8,0.2,1.4,0.4,1.9,0.6
			c0.5,0.2,0.9,0.5,1.2,0.8s0.5,0.9,0.5,1.5c0,0.5-0.1,1-0.4,1.4c-0.3,0.4-0.7,0.8-1.3,1c-0.6,0.3-1.3,0.4-2.2,0.4
			C42,748.9,41.3,748.7,40.6,748.5z"/>
		<path d="M49.1,738.3h1.1v10.5h-1.1V738.3z"/>
		<path d="M61.1,743.5h1.1v4c-0.5,0.4-1.1,0.8-1.7,1c-0.7,0.2-1.4,0.3-2.1,0.3c-1,0-2-0.2-2.8-0.7s-1.5-1.1-2-1.9s-0.7-1.7-0.7-2.7
			s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.5,2-1.9s1.8-0.7,2.9-0.7c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1l-0.7,0.7
			c-0.8-0.8-1.9-1.2-3.2-1.2c-0.9,0-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6s-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2s0.9,1.2,1.6,1.6
			c0.7,0.4,1.4,0.6,2.3,0.6c1.1,0,2-0.3,2.7-0.8V743.5z"/>
		<path d="M74,738.3v10.5h-0.9l-6.8-8.5v8.5h-1.1v-10.5h0.9l6.8,8.5v-8.5H74z"/>
		<path d="M84.9,747.8v1h-7.4v-10.5h7.2v1h-6.1v3.7H84v0.9h-5.4v3.9H84.9z"/>
		<path d="M87.5,738.3h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7c0,1-0.2,1.9-0.7,2.7
			c-0.5,0.8-1.1,1.4-2,1.9c-0.9,0.4-1.8,0.7-2.9,0.7h-4.3V738.3z M91.7,747.8c0.9,0,1.7-0.2,2.4-0.5s1.2-0.9,1.6-1.5
			c0.4-0.6,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.2-1.6-1.5c-0.7-0.4-1.5-0.5-2.4-0.5h-3.1v8.6H91.7z"/>
		<path d="M105.6,748.2c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.4,2-1.9
			c0.8-0.5,1.8-0.7,2.8-0.7s2,0.2,2.8,0.7s1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7s-1.1,1.5-2,1.9s-1.8,0.7-2.8,0.7
			S106.4,748.6,105.6,748.2z M110.7,747.3c0.7-0.4,1.2-0.9,1.6-1.6c0.4-0.7,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2
			c-0.4-0.7-0.9-1.2-1.6-1.6s-1.4-0.6-2.3-0.6s-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2
			c0.4,0.7,0.9,1.2,1.6,1.6s1.4,0.6,2.3,0.6S110,747.7,110.7,747.3z"/>
		<path d="M125.2,738.3v10.5h-0.9l-6.8-8.5v8.5h-1.1v-10.5h0.9l6.8,8.5v-8.5H125.2z"/>
		<path d="M140.7,744.2c0.4,0.4,0.6,1,0.6,1.7c0,0.9-0.3,1.6-1,2.1s-1.6,0.7-2.9,0.7h-4.8v-10.5h4.5c1.2,0,2.1,0.2,2.7,0.7
			c0.6,0.5,1,1.1,1,2c0,0.6-0.1,1.1-0.4,1.5c-0.3,0.4-0.7,0.7-1.2,0.9C139.8,743.5,140.4,743.8,140.7,744.2z M133.6,739.2v3.8h3.3
			c0.8,0,1.5-0.2,2-0.5s0.7-0.8,0.7-1.4c0-0.6-0.2-1.1-0.7-1.4s-1.1-0.5-2-0.5H133.6z M139.5,747.4c0.5-0.3,0.7-0.8,0.7-1.5
			c0-1.3-1-2-2.9-2h-3.7v3.9h3.7C138.3,747.8,139,747.7,139.5,747.4z"/>
		<path d="M151.3,747.8v1h-7.4v-10.5h7.2v1h-6.1v3.7h5.4v0.9h-5.4v3.9H151.3z"/>
		<path d="M162.6,738.3v10.5h-1.1v-4.8H155v4.8h-1.1v-10.5h1.1v4.7h6.6v-4.7H162.6z"/>
		<path d="M172.6,746h-5.9l-1.3,2.8h-1.2l4.8-10.5h1.1l4.8,10.5h-1.2L172.6,746z M172.2,745.1l-2.5-5.6l-2.5,5.6H172.2z"/>
		<path d="M176.8,738.3h1.1v9.5h5.9v1h-7V738.3z"/>
		<path d="M186.8,739.2v4.1h5.4v1h-5.4v4.5h-1.1v-10.5h7.2v1H186.8z"/>
		<path d="M200.8,748.2c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.4,2-1.9
			c0.8-0.5,1.8-0.7,2.8-0.7s2,0.2,2.8,0.7s1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7s-1.1,1.5-2,1.9s-1.8,0.7-2.8,0.7
			S201.7,748.6,200.8,748.2z M205.9,747.3c0.7-0.4,1.2-0.9,1.6-1.6c0.4-0.7,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2
			c-0.4-0.7-0.9-1.2-1.6-1.6s-1.4-0.6-2.3-0.6s-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2
			c0.4,0.7,0.9,1.2,1.6,1.6s1.4,0.6,2.3,0.6S205.2,747.7,205.9,747.3z"/>
		<path d="M212.8,739.2v4.1h5.4v1h-5.4v4.5h-1.1v-10.5h7.2v1H212.8z"/>
		<path d="M225.1,738.3h1.1v10.5h-1.1V738.3z"/>
		<path d="M236.2,746h-5.9l-1.3,2.8h-1.2l4.8-10.5h1.1l4.8,10.5h-1.2L236.2,746z M235.8,745.1l-2.5-5.6l-2.5,5.6H235.8z"/>
		<path d="M240.4,738.3h1.1v10.5h-1.1V738.3z"/>
		<path d="M244.9,738.3h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7c0,1-0.2,1.9-0.7,2.7
			c-0.5,0.8-1.1,1.4-2,1.9c-0.9,0.4-1.8,0.7-2.9,0.7h-4.3V738.3z M249.1,747.8c0.9,0,1.7-0.2,2.4-0.5s1.2-0.9,1.6-1.5
			c0.4-0.6,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.2-1.6-1.5c-0.7-0.4-1.5-0.5-2.4-0.5H246v8.6H249.1z"/>
		<path d="M257.3,738.3h1.1v9.5h5.9v1h-7V738.3z"/>
		<path d="M270.1,738.3h1.1v9.5h5.9v1h-7V738.3z"/>
		<path d="M278.7,748.6c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2C279,748.8,278.8,748.8,278.7,748.6z"/>
		<path d="M282.5,738.3h1.1v9.5h5.9v1h-7V738.3z"/>
		<path d="M291.1,748.6c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2C291.5,748.8,291.3,748.8,291.1,748.6z"/>
		<path d="M296.4,748.2c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.5,2-1.9
			c0.8-0.5,1.8-0.7,2.8-0.7c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1l-0.7,0.7c-0.8-0.8-1.9-1.2-3.1-1.2
			c-0.8,0-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2c0.4,0.7,0.9,1.2,1.6,1.6
			c0.7,0.4,1.4,0.6,2.3,0.6c1.3,0,2.3-0.4,3.1-1.3l0.7,0.7c-0.5,0.5-1,0.9-1.7,1.1s-1.4,0.4-2.2,0.4
			C298.2,748.9,297.3,748.6,296.4,748.2z"/>
		<path d="M39.9,786.3h4.3c1.1,0,2.1,0.2,2.9,0.7c0.9,0.4,1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7c0,1-0.2,1.9-0.7,2.7
			c-0.5,0.8-1.1,1.4-2,1.9c-0.9,0.4-1.8,0.7-2.9,0.7h-4.3V786.3z M44.1,795.8c0.9,0,1.7-0.2,2.4-0.5s1.2-0.9,1.6-1.5
			c0.4-0.6,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2c-0.4-0.6-0.9-1.2-1.6-1.5c-0.7-0.4-1.5-0.5-2.4-0.5H41v8.6H44.1z"/>
		<path d="M52.3,786.3h1.1v10.5h-1.1V786.3z"/>
		<path d="M64,796.8l-2.4-3.4c-0.3,0-0.6,0-0.8,0h-2.8v3.3h-1.1v-10.5h3.9c1.3,0,2.4,0.3,3.1,1c0.8,0.6,1.1,1.5,1.1,2.6
			c0,0.8-0.2,1.5-0.6,2.1c-0.4,0.6-1,1-1.8,1.2l2.6,3.6H64z M63.1,791.8c0.5-0.5,0.8-1.1,0.8-1.9c0-0.8-0.3-1.5-0.8-2
			c-0.6-0.5-1.3-0.7-2.4-0.7h-2.8v5.3h2.8C61.8,792.5,62.6,792.3,63.1,791.8z"/>
		<path d="M75.1,795.8v1h-7.4v-10.5h7.2v1h-6.1v3.7h5.4v0.9h-5.4v3.9H75.1z"/>
		<path d="M79.4,796.2c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.5,2-1.9
			c0.8-0.5,1.8-0.7,2.8-0.7c0.8,0,1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1l-0.7,0.7c-0.8-0.8-1.9-1.2-3.1-1.2
			c-0.8,0-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2c0.4,0.7,0.9,1.2,1.6,1.6
			c0.7,0.4,1.4,0.6,2.3,0.6c1.3,0,2.3-0.4,3.1-1.3l0.7,0.7c-0.5,0.5-1,0.9-1.7,1.1s-1.4,0.4-2.2,0.4
			C81.2,796.9,80.2,796.6,79.4,796.2z"/>
		<path d="M90.3,787.2h-3.7v-1h8.5v1h-3.7v9.5h-1.1V787.2z"/>
		<path d="M98.5,796.2c-0.8-0.5-1.5-1.1-2-1.9c-0.5-0.8-0.7-1.7-0.7-2.7s0.2-1.9,0.7-2.7c0.5-0.8,1.1-1.4,2-1.9
			c0.8-0.5,1.8-0.7,2.8-0.7s2,0.2,2.8,0.7s1.5,1.1,2,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7s-1.1,1.5-2,1.9s-1.8,0.7-2.8,0.7
			S99.4,796.6,98.5,796.2z M103.6,795.3c0.7-0.4,1.2-0.9,1.6-1.6c0.4-0.7,0.6-1.4,0.6-2.2s-0.2-1.6-0.6-2.2
			c-0.4-0.7-0.9-1.2-1.6-1.6s-1.4-0.6-2.3-0.6s-1.6,0.2-2.3,0.6s-1.2,0.9-1.6,1.6c-0.4,0.7-0.6,1.4-0.6,2.2s0.2,1.6,0.6,2.2
			c0.4,0.7,0.9,1.2,1.6,1.6s1.4,0.6,2.3,0.6S102.9,795.7,103.6,795.3z"/>
		<path d="M116.5,796.8l-2.4-3.4c-0.3,0-0.6,0-0.8,0h-2.8v3.3h-1.1v-10.5h3.9c1.3,0,2.4,0.3,3.1,1c0.8,0.6,1.1,1.5,1.1,2.6
			c0,0.8-0.2,1.5-0.6,2.1c-0.4,0.6-1,1-1.8,1.2l2.6,3.6H116.5z M115.6,791.8c0.5-0.5,0.8-1.1,0.8-1.9c0-0.8-0.3-1.5-0.8-2
			c-0.6-0.5-1.3-0.7-2.4-0.7h-2.8v5.3h2.8C114.3,792.5,115.1,792.3,115.6,791.8z"/>
	</g>
	<g class="st1505">
		<path class="st1520" d="M59.4,768.9c-1.9,0.8-5.2,1.8-6.8,0c-2.7-3.1,5.1-6.5,6.9-7.2c3.2-1.3,6.6-2.2,9.8-3.2
			c3-0.9,6.1-1.8,9.2-2.6c1-0.3,2-0.5,3-0.7c0.2,0,0.5-0.1,0.7-0.1c-0.5,0-0.1-0.4-0.3-0.1c-0.2,0.1-0.3,0.3-0.5,0.4
			c-2.3,2-4.5,4-6.5,6.2c-5.4,5.6-10.2,11.9-15.5,17.6c-2.9,3.1-6,6.1-9.1,9c-2.6,2.3-5.3,4.7-8.4,6.4c-0.4,0.2-1.6,1-2.1,0.5
			c-0.5-0.4,0.4-1.7,0.6-2c2-3.2,5.3-5.6,8.2-7.9c10.7-8.4,22.1-15.4,33.6-22.7c4.7-3,9.5-6,14-9.3c0.6-0.4,2.5-1.5,2.6-2.4
			c0-0.9-2.2,0.5-2.4,0.7c-3.9,2.6-7.7,5.6-11.3,8.6c-2.1,1.7-3.9,3.8-5.8,5.8c-4.8,5-9.4,10.1-14.1,15.3
			c-3.8,4.2-7.7,8.4-11.1,12.9c-0.3,0.4,0.9,0.2,1.1,0c3.2-3.6,6.4-7,9.8-10.4c2.5-2.5,5-4.9,7.7-7.2c3.1-2.6,6.4-5.2,10-7.1
			c0.2-0.1,0.4-0.2,0.6-0.3c-0.3,0.1-0.2-0.2-0.3,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,0.8-1.1,1.6-1.6,2.4c-1.7,2.3-3.5,4.6-5.3,6.8
			c-1.9,2.3-3.8,4.5-5.8,6.7c-0.9,0.9-1.8,1.8-2.7,2.7c-0.3,0.3-1.8,1.4-1.6,2.1c0.3,1.1,3.4-1.5,3.6-1.7c1.4-1.3,2.9-2.3,4.4-3.4
			c6-4.4,12.3-8.4,18.9-11.9c3.2-1.7,6.8-3.6,10.4-4.1c0.1,0,1.2-0.1,1,0.3c-0.4,0.6-1.4,0.6-2,0.7c-4.2,0.3-1.1-6.3,0-7.7
			c1.6-2,3.7-3.6,5.6-5.2c1.9-1.5,3.8-3,5.9-4.4c0.4-0.3,3.7-2.5,3.8-1.9c0.2,0.7-0.5,1.7-0.8,2.3c-0.6,1.3-1.5,2.5-2.3,3.7
			c-1.8,2.7-3.9,5.2-6.1,7.6c-3.7,4-6.8,8.4-10.2,12.7c-3.8,4.9-7.8,10.2-13.1,13.6c0.3,0,0.7,0,1,0.1c2.5-4.5,8.1-7.4,12.5-10
			c5.2-3.1,10.3-6.3,15.2-9.9c1-0.7,8-5.4,6.2-7.3c-0.9-1-2.9-0.1-3.8,0.4c-2.3,1.1-4.3,3-6.2,4.8c-4,3.8-8.2,8.2-10.7,13.1
			c-0.6,1.1-1.5,3.2-0.5,4.4c1,1.1,3.2,0,4.2-0.5c3.2-1.7,5.9-4.4,8.5-6.9c2.8-2.6,5.7-5.2,8.5-7.8c4.5-4.1,8.9-8.2,13.4-12.2
			c0.3-0.2,0.9-0.9,1-0.9c-0.3,0-0.6,0.1-0.9,0.1c-0.3-0.1,0-0.3-0.3,0c-0.2,0.2-0.5,0.5-0.7,0.7c-0.8,0.9-1.7,1.7-2.5,2.5
			c-2.1,2.1-4.1,4.1-6.2,6.2c-3.1,3.1-5.9,6.5-8.6,9.9c-3,3.7-6.3,7.5-8.5,11.7c-0.3,0.7-0.6,1.6,0.5,1.3c0.9-0.3,1.7-1.1,2.4-1.7
			c11.2-9.9,20.5-21.9,32.4-31.1c-0.4,0-0.7,0-1.1-0.1c-3.7,4.4-7.8,8.4-11.6,12.7c-4.5,5.2-9,10.4-13.5,15.6
			c-2.1,2.3-4.1,4.6-6.3,6.8c-1.6,1.6-3.2,3.1-5.1,4.3c-1.4,0.9-3.4,2-5,1.3c-2.3-0.9-0.6-4.8,0.1-6.3c1.2-2.6,3.1-4.9,5.2-6.9
			c6.2-5.8,14.4-7.7,22.5-9c5.4-0.9,10.8-1,16.3-0.5c0.3,0,1.4-0.5,0.8-0.6c-5.3-0.5-10.6-0.4-15.9,0.4c-7.8,1.1-15.7,2.9-22.2,7.6
			c-4.1,3-7.9,7.4-9,12.4c-0.4,2.1,0.5,3.7,2.7,3.7c2.9,0.1,5.8-2,7.9-3.7c3.3-2.7,6.1-6.1,8.9-9.2c3.2-3.6,6.4-7.3,9.6-11
			c5.2-6,10.7-11.6,15.8-17.6c0.4-0.4-0.9-0.2-1.1-0.1c-4.2,3.2-8,7-11.8,10.7c-4.5,4.4-8.9,8.9-13.3,13.3c-2.8,2.8-5.5,5.9-8.7,8.3
			c-0.5,0.3,0-0.2,0.3,0.1c-0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.7,0.7-1.4,1.1-2c1.3-2.1,2.8-4.1,4.3-6c3.2-4.3,6.5-8.6,10.3-12.5
			c2.2-2.3,4.5-4.5,6.8-6.8c1-1,2-2,3-3c0.4-0.5,1.1-0.9,1.4-1.5c0.1-0.1,0.1-0.2-0.1-0.3c0,0,0,0,0,0c-0.2-0.1-0.7,0-0.9,0.1
			c-1,0.6-1.9,1.6-2.8,2.4c-2.2,2-4.4,4-6.6,6c-5.6,5.1-11.2,10.3-16.8,15.4c-1.5,1.3-3.1,2.6-4.8,3.7c-0.7,0.4-2.1,1.4-2.9,0.7
			c-1.3-1.1,0.7-4.4,1.3-5.4c3-5.1,7.5-9.7,11.9-13.5c1.3-1.1,3.2-3,5-3.1c1.6-0.1,0.5,1.7,0,2.2c-2.9,3.5-7.4,6.1-11.1,8.6
			c-4.2,2.7-8.4,5.3-12.7,7.8c-3.5,2-7.5,4.8-9.5,8.5c-0.2,0.3,0.9,0.1,1,0.1c5.6-3.7,9.9-9.4,14-14.7c2.1-2.7,4.1-5.4,6.2-8
			c1.8-2.3,3.9-4.4,5.8-6.6c2.1-2.6,4.2-5.3,5.8-8.3c0.3-0.6,2-3.4,0.4-3.5c-1.5-0.1-3.1,1.1-4.3,1.8c-2.3,1.5-4.6,3.2-6.7,4.9
			c-3.4,2.7-9.2,6.9-8.2,12.1c0.4,2.2,3.1,2,4.7,1.5c0.3-0.1,2.4-0.9,1.7-1.6c-0.9-0.9-3.5,0-4.4,0.3c-3.5,1-6.9,2.7-10.2,4.5
			c-6.3,3.4-12.4,7.4-18.2,11.6c-1.5,1.1-3,2.1-4.4,3.4c-0.4,0.4-0.8,0.7-1.3,1c-0.6,0.4,0.1,0,0,0.3c0-0.2,0.2-0.4,0.4-0.5
			c0.9-1.1,2.1-2,3.1-3.1c4.1-4.2,7.7-8.8,11.3-13.4c0.9-1.2,2.2-2.6,2.8-4.1c0.4-1.1-2.7,0.3-2.8,0.4c-6.3,3.5-11.9,8.6-17,13.7
			c-3.6,3.6-7,7.3-10.4,11c0.4,0,0.8,0,1.1,0c2.6-3.5,5.6-6.7,8.5-10c4-4.5,8-8.9,12.1-13.3c2.8-3,5.6-6.1,8.5-9
			c2.6-2.6,5.8-4.8,8.8-7c1.6-1.2,3.3-2.5,5-3.6c0.4-0.2-0.4-0.3-0.6,0c-0.1,0.2-0.3,0.3-0.5,0.5c-0.7,0.6-1.4,1.1-2.2,1.7
			c-4.7,3.4-9.6,6.5-14.5,9.6c-11.5,7.3-23,14.4-33.6,22.9c-2.7,2.1-5.5,4.4-7.4,7.3c-0.4,0.6-1.5,2.4-0.1,2.7
			c1.7,0.3,3.6-1,4.9-1.8c3-1.9,5.7-4.3,8.3-6.7c5.9-5.4,11.3-11.3,16.5-17.4c2.5-3,5-6,7.7-8.8c2-2.1,4.2-4,6.4-6
			c0.6-0.6,0.5-0.8-0.4-0.7c-1.5,0.2-3.1,0.7-4.6,1.1c-5,1.4-10,2.8-15,4.4c-2.9,0.9-5.8,2.1-8.4,3.7c-1.5,1-3.6,2.6-3.4,4.6
			c0.4,3.7,6.9,1.8,8.9,1C60.7,769,59.9,768.7,59.4,768.9L59.4,768.9"/>
		<path class="st1520" d="M129.4,777c4.5-1,9-2.4,13.1-4.6c2.5-1.4,4.8-3.3,6.4-5.7c1.2-1.9-1.2-1.4-2.2-0.9c-3.1,1.3-5.9,3.7-8.4,6
			c-3,2.7-5.7,5.8-8.1,9.1c-1.5,2.1-3.6,4.8-3.9,7.5c-0.2,1.7,1.7,1.2,2.7,0.8c2.2-1,4.1-2.7,5.9-4.4c2.3-2.1,4.5-4.3,6.7-6.4
			c4.2-3.9,8.6-7.8,13-11.5c0.6-0.5,1.2-1,1.8-1.5c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.1,0.1-0.2-0.5-0.1c0,0.1-0.2,0.4-0.2,0.4
			c-1.6,2.8-4.5,5.3-6.8,7.5c-5.3,5.2-10.1,10.9-14.5,16.9c-0.3,0.4,0.9,0.1,1,0c2.3-1.7,4.3-3.7,6.1-5.9c1.9-2.4,4.2-4.5,6.4-6.6
			c5.8-5.5,12.2-10,19.1-14c0.3-0.2,0.7-0.4,1-0.5c-0.7,0.3-0.3,0-0.8,0.6c-0.9,1.3-2,2.5-3.1,3.7c-2.7,3-5.3,6-7.8,9.1
			c-3.3,4-7,7.8-11.4,10.7c-0.3,0.2-0.7,0.7-0.8,0.2c-0.1-0.3,0.2-0.7,0.4-1c0.8-1.1,2-1.9,3.2-2.6c3.4-2.2,7.2-3.6,10.9-5
			c5.9-2.3,12-4.7,17.5-7.9c0.9-0.5,3-1.5,3-2.8c0.1-1.8-3.6-0.4-4.2-0.1c-6.7,3-12,8.9-16.6,14.4c-1.7,2-3.5,4.1-4.7,6.5
			c-0.5,1-0.1,1.6,1,1.2c1-0.4,1.9-1.2,2.7-1.9c1.6-1.3,3.1-2.7,4.6-4.1c4-3.7,7.9-7.4,11.9-11.1c2.8-2.6,5.6-5.5,8.7-7.7
			c0.5-0.4-0.2-0.2-0.3,0c-0.2,0.5-0.6,0.9-0.9,1.3c-1.3,1.7-2.8,3.3-4.2,4.9c-4.7,5.4-9.2,10.8-13.4,16.6c-0.3,0.4,0.9,0.1,1.1-0.1
			c2.2-2,4.2-4.2,6.2-6.2c3.1-3.2,6-6.5,9.4-9.3c0.9-0.7,1.8-1.4,2.7-1.9c0.3-0.2,0.6-0.2,0.4,0.2c-0.1,0.5-0.5,0.8-0.9,1.2
			c-0.9,0.9-1.8,1.7-2.7,2.5c-2.1,2-4.3,4.1-5.9,6.5c-0.8,1.2-1.8,2.9,0.2,3.2c2.3,0.4,5.1-0.3,7.3-0.8c3.6-0.8,7.1-1.8,10.6-3.2
			c0.8-0.3,0-0.5-0.4-0.3c-3.4,1.3-6.9,2.3-10.5,3.1c-1,0.2-6.6,1.7-7,0c-0.2-0.9,0.9-2.1,1.3-2.7c0.9-1.3,2-2.4,3.1-3.5
			c1.7-1.8,3.7-3.4,5.4-5.2c0.3-0.4,1.2-1.4,0.3-1.7c-1.1-0.4-2.7,0.8-3.5,1.3c-3.1,2.1-5.7,5-8.3,7.7c-2.2,2.3-4.5,4.6-6.7,6.9
			c-0.7,0.7-1.5,1.5-2.2,2.2c0.4,0,0.8,0,1.1-0.1c4.9-6.8,10.3-13.1,15.8-19.4c0.8-0.9,2.6-2.4,2.8-3.7c0.1-0.7-1.7,0.1-1.9,0.2
			c-3,1.9-5.6,4.8-8.3,7.2c-4,3.7-7.9,7.5-11.9,11.1c-1.6,1.5-3.2,2.9-4.9,4.3c-0.5,0.5-1.1,0.9-1.7,1.3c-0.2,0.1-0.4,0.3-0.6,0.4
			c-0.4,0.3,0.3,0.1,0.2-0.1c-0.1-0.6,0.7-1.6,1-2.1c0.8-1.4,1.8-2.6,2.8-3.9c2.2-2.8,4.6-5.4,7.1-7.9c3-3,6.4-5.9,10.3-7.6
			c0.5-0.2,2.5-0.6,1.9,0.6c-0.4,0.7-1.4,1.3-2.1,1.7c-5.5,3.5-11.9,5.7-17.9,8.1c-3.4,1.3-6.8,2.6-10,4.5c-1.6,0.9-4.4,2.4-4.9,4.4
			c-0.4,1.6,2.3,0.3,2.8,0c2.4-1.5,4.6-3.5,6.7-5.4c4.3-4,7.8-8.9,11.7-13.3c1.2-1.4,2.5-2.8,3.7-4.2c0.3-0.4,1-1.1,1-1.6
			c0-0.8-3.2,1.1-3.4,1.1c-3.1,1.8-6.2,3.7-9.1,5.7c-3.3,2.3-6.3,5-9.3,7.7c-2.2,2.1-4.4,4.2-6.4,6.5c-1.9,2.2-3.9,4.4-6.3,6.2
			c0.3,0,0.7,0,1,0c2.9-3.9,5.9-7.7,9.1-11.3c2.9-3.2,6.1-6.1,9-9.3c1.1-1.2,2.2-2.4,3.1-3.7c0.2-0.3,0.7-1-0.1-1
			c-1.4,0.1-2.7,1.6-3.7,2.4c-5.3,4.4-10.5,9-15.5,13.8c-2.9,2.7-5.5,5.7-8.9,7.8c-3.3,2.1-0.8-3-0.2-4.1c2.4-4.3,5.8-8.1,9.2-11.5
			c1.7-1.7,3.6-3.3,5.6-4.7c1.1-0.8,2.2-1.5,3.4-2c0.9-0.4,0.5,0.4,0.3,0.9c-2.2,3.8-6.6,5.9-10.5,7.4c-2.4,0.9-5.3,1.8-7.6,2.3
			C129.1,776.6,128.6,777.2,129.4,777L129.4,777"/>
	</g>
	<g class="st1505">
		<g>
			<g>
				<path d="M144.3,644.9h-3.8l-0.8,1.8H139l3.1-6.8h0.7l3.1,6.8h-0.8L144.3,644.9z M144,644.3l-1.6-3.7l-1.6,3.7H144z"/>
				<path d="M148,646.3c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.8s0.2-1.2,0.5-1.8c0.3-0.5,0.7-0.9,1.3-1.2
					c0.5-0.3,1.2-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.7l-0.5,0.5c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.5,0.4
					c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4s0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.5,0.4
					c0.8,0,1.5-0.3,2-0.8l0.5,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C149.2,646.8,148.6,646.6,148,646.3z"/>
				<path d="M154.9,646.3c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.8s0.2-1.2,0.5-1.8c0.3-0.5,0.7-0.9,1.3-1.2
					c0.5-0.3,1.2-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.7l-0.5,0.5c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.5,0.4
					c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4s0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.5,0.4
					c0.8,0,1.5-0.3,2-0.8l0.5,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C156,646.8,155.4,646.6,154.9,646.3z"/>
				<path d="M165.4,646.7l-1.6-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.2h-0.7v-6.8h2.5c0.9,0,1.5,0.2,2,0.6c0.5,0.4,0.7,1,0.7,1.7
					c0,0.5-0.1,1-0.4,1.3c-0.3,0.4-0.7,0.6-1.2,0.8l1.7,2.3H165.4z M164.8,643.5c0.4-0.3,0.5-0.7,0.5-1.3c0-0.6-0.2-1-0.5-1.3
					c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4h1.8C163.9,644,164.5,643.8,164.8,643.5z"/>
				<path d="M172.6,646.1v0.6h-4.8v-6.8h4.7v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H172.6z"/>
				<path d="M174.3,639.9h2.8c0.7,0,1.4,0.1,1.9,0.4c0.6,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.8s-0.2,1.2-0.5,1.8
					s-0.7,0.9-1.3,1.2c-0.6,0.3-1.2,0.4-1.9,0.4h-2.8V639.9z M177,646.1c0.6,0,1.1-0.1,1.6-0.4c0.4-0.2,0.8-0.6,1-1
					c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.4-1.6-0.4h-2v5.6H177z"/>
				<path d="M182.3,639.9h0.7v6.8h-0.7V639.9z"/>
				<path d="M186.5,640.5h-2.4v-0.6h5.5v0.6h-2.4v6.2h-0.7V640.5z"/>
				<path d="M194.7,644.9h-3.8l-0.8,1.8h-0.7l3.1-6.8h0.7l3.1,6.8h-0.8L194.7,644.9z M194.5,644.3l-1.6-3.7l-1.6,3.7H194.5z"/>
				<path d="M198.4,640.5H196v-0.6h5.5v0.6h-2.4v6.2h-0.7V640.5z"/>
				<path d="M202.7,639.9h0.7v6.8h-0.7V639.9z"/>
				<path d="M206.7,646.3c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.8s0.2-1.2,0.5-1.8c0.3-0.5,0.7-0.9,1.3-1.2
					c0.5-0.3,1.2-0.5,1.8-0.5s1.3,0.1,1.8,0.4s1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.8s-0.2,1.2-0.5,1.8c-0.3,0.5-0.7,0.9-1.3,1.2
					s-1.1,0.4-1.8,0.4S207.3,646.6,206.7,646.3z M210,645.8c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
					s-0.6-0.8-1-1s-0.9-0.4-1.5-0.4s-1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4s0.1,1,0.4,1.4
					c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.5,0.4S209.6,646,210,645.8z"/>
				<path d="M219.4,639.9v6.8h-0.6l-4.4-5.5v5.5h-0.7v-6.8h0.6l4.4,5.5v-5.5H219.4z"/>
				<path d="M164.9,650.3v6.8h-0.6l-4.4-5.5v5.5h-0.7v-6.8h0.6l4.4,5.5v-5.5H164.9z"/>
				<path d="M167.8,656.4c-0.5-0.5-0.7-1.2-0.7-2.2v-3.9h0.7v3.9c0,0.8,0.2,1.4,0.5,1.8c0.4,0.4,0.9,0.6,1.5,0.6
					c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.5-1,0.5-1.8v-3.9h0.7v3.9c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.2,0.8-2.1,0.8
					S168.3,656.9,167.8,656.4z"/>
				<path d="M181.9,650.3v6.8h-0.7v-5.4l-2.7,4.6h-0.3l-2.7-4.5v5.4h-0.7v-6.8h0.6l3,5l2.9-5H181.9z"/>
				<path d="M189.4,654.2c0.2,0.3,0.4,0.7,0.4,1.1c0,0.6-0.2,1-0.6,1.3c-0.4,0.3-1.1,0.5-1.9,0.5h-3.1v-6.8h2.9
					c0.8,0,1.3,0.2,1.8,0.5c0.4,0.3,0.6,0.7,0.6,1.3c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.5-0.8,0.6
					C188.9,653.7,189.2,653.9,189.4,654.2z M184.8,650.9v2.5h2.2c0.6,0,1-0.1,1.3-0.3s0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9
					c-0.3-0.2-0.7-0.3-1.3-0.3H184.8z M188.6,656.2c0.3-0.2,0.5-0.5,0.5-1c0-0.8-0.6-1.3-1.8-1.3h-2.4v2.6h2.4
					C187.9,656.5,188.3,656.4,188.6,656.2z"/>
				<path d="M196.3,656.5v0.6h-4.8v-6.8h4.7v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H196.3z"/>
				<path d="M202.6,657.1l-1.6-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.2H198v-6.8h2.5c0.9,0,1.5,0.2,2,0.6c0.5,0.4,0.7,1,0.7,1.7
					c0,0.5-0.1,1-0.4,1.3c-0.3,0.4-0.7,0.6-1.2,0.8l1.7,2.3H202.6z M202,653.9c0.4-0.3,0.5-0.7,0.5-1.3c0-0.6-0.2-1-0.5-1.3
					c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4h1.8C201.2,654.3,201.7,654.2,202,653.9z"/>
			</g>
			<text transform="matrix(1 0 0 1 140.5419 678.158)" class="st1514 st1521">{{ token }}</text>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 274.6083 673.9095)" class="st1514 st1522">{{ date }}</text>
			<g>
				<path d="M272.8,644l-3.5,8h-0.8l-3.5-8h0.9l3.1,6.9l3.1-6.9H272.8z"/>
				<path d="M278.7,649.8h-4.5l-1,2.1h-0.9l3.7-8h0.8l3.7,8h-0.9L278.7,649.8z M278.4,649.1l-1.9-4.3l-1.9,4.3H278.4z"/>
				<path d="M281.9,644h0.8v7.3h4.5v0.7h-5.3V644z"/>
				<path d="M288.6,644h0.8v8h-0.8V644z"/>
				<path d="M292.1,644h3.3c0.8,0,1.6,0.2,2.2,0.5s1.1,0.8,1.5,1.4c0.4,0.6,0.5,1.3,0.5,2.1s-0.2,1.5-0.5,2.1
					c-0.4,0.6-0.9,1.1-1.5,1.4c-0.6,0.3-1.4,0.5-2.2,0.5h-3.3V644z M295.3,651.2c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.2
					c0.3-0.5,0.4-1.1,0.4-1.7c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.8-0.4h-2.4v6.5H295.3z"/>
				<path d="M305.3,644.7v3.1h4.1v0.7h-4.1v3.4h-0.8v-8h5.5v0.7H305.3z"/>
				<path d="M317.2,652l-1.8-2.6c-0.2,0-0.4,0-0.6,0h-2.1v2.5h-0.8v-8h3c1,0,1.8,0.2,2.4,0.7s0.9,1.2,0.9,2c0,0.6-0.2,1.2-0.5,1.6
					c-0.3,0.4-0.8,0.7-1.4,0.9l2,2.7H317.2z M316.5,648.2c0.4-0.4,0.6-0.8,0.6-1.5c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.3-1-0.5-1.8-0.5
					h-2.1v4h2.1C315.5,648.7,316.1,648.5,316.5,648.2z"/>
				<path d="M321.3,651.5c-0.6-0.4-1.1-0.8-1.5-1.5c-0.4-0.6-0.5-1.3-0.5-2.1s0.2-1.5,0.5-2.1s0.9-1.1,1.5-1.5
					c0.6-0.4,1.4-0.5,2.2-0.5s1.5,0.2,2.1,0.5c0.6,0.4,1.1,0.8,1.5,1.5s0.5,1.3,0.5,2.1s-0.2,1.5-0.5,2.1c-0.4,0.6-0.9,1.1-1.5,1.5
					c-0.6,0.4-1.3,0.5-2.1,0.5S322,651.9,321.3,651.5z M325.2,650.8c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7
					s-0.1-1.2-0.4-1.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.7-0.4s-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.2
					c-0.3,0.5-0.4,1.1-0.4,1.7s0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.4,1.7,0.4S324.7,651.1,325.2,650.8z"/>
				<path d="M337.9,644v8H337v-6.4l-3.1,5.4h-0.4l-3.1-5.3v6.4h-0.8v-8h0.7l3.5,5.9l3.4-5.9H337.9z"/>
			</g>
		</g>
		<g>
			<text transform="matrix(1 0 0 1 400.4481 673.909)" class="st1514 st1522">{{dateTo}}</text>
			<g>
				<path d="M403.8,644l-3.5,8h-0.8l-3.5-8h0.9l3.1,6.9l3.1-6.9H403.8z"/>
				<path d="M409.7,649.8h-4.5l-1,2.1h-0.9l3.7-8h0.8l3.7,8h-0.9L409.7,649.8z M409.4,649.1l-1.9-4.3l-1.9,4.3H409.4z"/>
				<path d="M412.9,644h0.8v7.3h4.5v0.7h-5.3V644z"/>
				<path d="M419.6,644h0.8v8h-0.8V644z"/>
				<path d="M423,644h3.3c0.8,0,1.6,0.2,2.2,0.5c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.5,1.3,0.5,2.1s-0.2,1.5-0.5,2.1
					c-0.4,0.6-0.9,1.1-1.5,1.4c-0.6,0.3-1.4,0.5-2.2,0.5H423V644z M426.3,651.2c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.2
					c0.3-0.5,0.4-1.1,0.4-1.7c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.8-0.4h-2.4v6.5H426.3z"/>
				<path d="M437,644.7h-2.8V644h6.5v0.7h-2.8v7.3H437V644.7z"/>
				<path d="M443.3,651.5c-0.6-0.4-1.1-0.8-1.5-1.5c-0.4-0.6-0.5-1.3-0.5-2.1s0.2-1.5,0.5-2.1s0.9-1.1,1.5-1.5
					c0.6-0.4,1.4-0.5,2.2-0.5s1.5,0.2,2.1,0.5c0.6,0.4,1.1,0.8,1.5,1.5s0.5,1.3,0.5,2.1s-0.2,1.5-0.5,2.1c-0.4,0.6-0.9,1.1-1.5,1.5
					c-0.6,0.4-1.3,0.5-2.1,0.5S443.9,651.9,443.3,651.5z M447.1,650.8c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7
					s-0.1-1.2-0.4-1.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.4-1.7-0.4s-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.2
					c-0.3,0.5-0.4,1.1-0.4,1.7s0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.4,1.7,0.4S446.6,651.1,447.1,650.8z"/>
			</g>
		</g>
		<rect x="240.7" y="632.2" class="st198" width="1.5" height="54.8"/>
		<rect x="361.7" y="632.2" class="st198" width="1.5" height="54.8"/>
	</g>
	<g class="st1505">
		<path d="M17.3,558.9H15v-0.6h5.4v0.6H18v6.1h-0.7V558.9z"/>
		<path d="M27.1,558.3v6.7h-0.7v-3.1h-4.2v3.1h-0.7v-6.7h0.7v3h4.2v-3H27.1z"/>
		<path d="M29.3,558.3H30v6.7h-0.7V558.3z"/>
		<path d="M32.6,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			s0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2C33.5,565,33,564.9,32.6,564.8z"/>
		<path d="M41.7,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C42.8,565,42.2,564.9,41.7,564.6z"/>
		<path d="M52.2,564.3v0.6h-4.7v-6.7H52v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H52.2z"/>
		<path d="M58.4,565l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H58.4z M57.9,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4h1.8
			C57,562.2,57.5,562.1,57.9,561.8z"/>
		<path d="M62,558.9h-2.4v-0.6H65v0.6h-2.4v6.1H62V558.9z"/>
		<path d="M66.2,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M69.8,558.9v2.6h3.5v0.6h-3.5v2.8H69v-6.7h4.6v0.6H69.8z"/>
		<path d="M75.1,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M79.1,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C80.2,565,79.6,564.9,79.1,564.6z"/>
		<path d="M88.9,563.2h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L88.9,563.2z M88.6,562.6L87,559l-1.6,3.6H88.6z"/>
		<path d="M92.5,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M101.5,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H101.5z"/>
		<path d="M110.2,565l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H110.2z M109.6,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4
			h1.8C108.8,562.2,109.3,562.1,109.6,561.8z"/>
		<path d="M117.3,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H117.3z"/>
		<path d="M125.9,558.3v6.7h-0.7v-5.4l-2.6,4.5h-0.3l-2.6-4.5v5.3h-0.7v-6.7h0.6l2.9,5l2.9-5H125.9z"/>
		<path d="M132.3,563.2h-3.7l-0.8,1.8H127l3.1-6.7h0.7l3.1,6.7h-0.7L132.3,563.2z M132,562.6l-1.6-3.6l-1.6,3.6H132z"/>
		<path d="M134.9,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M143.4,558.3v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H143.4z"/>
		<path d="M146,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			s0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2C147,565,146.5,564.9,146,564.8z"/>
		<path d="M159.6,558.3l-3,6.7h-0.7l-3-6.7h0.8l2.6,5.8l2.6-5.8H159.6z"/>
		<path d="M164.5,563.2h-3.7L160,565h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L164.5,563.2z M164.3,562.6l-1.6-3.6l-1.6,3.6H164.3z"/>
		<path d="M167.2,558.3h0.7v6.1h3.8v0.6h-4.5V558.3z"/>
		<path d="M172.8,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M175.7,558.3h2.7c0.7,0,1.3,0.1,1.9,0.4c0.5,0.3,1,0.7,1.3,1.2s0.4,1.1,0.4,1.7s-0.1,1.2-0.4,1.7s-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.2,0.4-1.9,0.4h-2.7V558.3z M178.4,564.3c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4
			s-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H178.4z"/>
		<path d="M186.8,558.9v2.6h3.5v0.6h-3.5v2.8h-0.7v-6.7h4.6v0.6H186.8z"/>
		<path d="M193.2,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C194.4,565,193.8,564.9,193.2,564.6z M196.5,564c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C195.6,564.4,196,564.3,196.5,564z"/>
		<path d="M204.7,565l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H204.7z M204.1,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4
			h1.8C203.3,562.2,203.8,562.1,204.1,561.8z"/>
		<path d="M210.8,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M220.6,558.3v6.7h-0.7v-3.1h-4.2v3.1H215v-6.7h0.7v3h4.2v-3H220.6z"/>
		<path d="M227.6,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H227.6z"/>
		<path d="M232.1,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			s0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2C233.1,565,232.6,564.9,232.1,564.8z"/>
		<path d="M238.7,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C239.9,565,239.3,564.9,238.7,564.6z"/>
		<path d="M245.5,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C246.6,565,246,564.9,245.5,564.6z M248.7,564c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C247.8,564.4,248.3,564.3,248.7,564z"/>
		<path d="M256.9,558.9c0.5,0.4,0.7,1,0.7,1.7c0,0.7-0.2,1.3-0.7,1.7s-1.2,0.6-2,0.6h-1.8v2.1h-0.7v-6.7h2.5
			C255.7,558.3,256.4,558.5,256.9,558.9z M256.4,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8
			v3.3h1.8C255.5,562.2,256,562.1,256.4,561.8z"/>
		<path d="M263.9,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H263.9z"/>
		<path d="M269.2,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C270.3,565,269.7,564.9,269.2,564.6z M272.4,564c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C271.5,564.4,272,564.3,272.4,564z"/>
		<path d="M276.8,558.9v2.6h3.5v0.6h-3.5v2.8h-0.7v-6.7h4.6v0.6H276.8z"/>
		<path d="M288.8,563.2h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L288.8,563.2z M288.6,562.6L287,559l-1.6,3.6H288.6z"/>
		<path d="M292.5,564.6c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C293.6,565,293,564.9,292.5,564.6z"/>
		<path d="M299.3,564.6c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2c0.5-0.3,1.1-0.4,1.8-0.4
			c0.5,0,1,0.1,1.4,0.2s0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4s-0.8,0.6-1,1
			c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4c0.8,0,1.5-0.3,2-0.8l0.4,0.5
			c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C300.4,565,299.8,564.9,299.3,564.6z"/>
		<path d="M309.6,565l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1H305v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H309.6z M309,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2s-0.9-0.4-1.5-0.4h-1.8v3.4h1.8
			C308.2,562.2,308.7,562.1,309,561.8z"/>
		<path d="M316.7,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H316.7z"/>
		<path d="M318.3,558.3h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2
			s-1.2,0.4-1.9,0.4h-2.7V558.3z M321,564.3c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H321z"/>
		<path d="M326.2,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M330.4,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M338.5,563.2h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L338.5,563.2z M338.2,562.6l-1.6-3.6l-1.6,3.6H338.2z"/>
		<path d="M342.1,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M346.3,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M350.3,564.6c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.1,0.4-1.8,0.4C351.5,565,350.9,564.9,350.3,564.6z M353.6,564c0.4-0.2,0.8-0.6,1-1s0.4-0.9,0.4-1.4
			c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4s-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4S353.1,564.3,353.6,564z"/>
		<path d="M362.8,558.3v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H362.8z"/>
		<path d="M371.7,563.2H368l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L371.7,563.2z M371.4,562.6l-1.6-3.6l-1.6,3.6H371.4z"/>
		<path d="M374.8,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9s0.5-0.5,0.8-0.7s0.8-0.2,1.4-0.2c0.4,0,0.8,0.1,1.1,0.2
			c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			c0.2,0.2,0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2
			C375.7,565,375.2,564.9,374.8,564.8z"/>
		<path d="M383.2,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9s0.5-0.5,0.8-0.7s0.8-0.2,1.4-0.2c0.4,0,0.8,0.1,1.1,0.2
			c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			c0.2,0.2,0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2
			C384.1,565,383.6,564.9,383.2,564.8z"/>
		<path d="M393.2,558.9c0.5,0.4,0.7,1,0.7,1.7c0,0.7-0.2,1.3-0.7,1.7s-1.2,0.6-2,0.6h-1.8v2.1h-0.7v-6.7h2.5
			C392,558.3,392.7,558.5,393.2,558.9z M392.6,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2s-0.9-0.4-1.5-0.4h-1.8v3.3h1.8
			C391.8,562.2,392.3,562.1,392.6,561.8z"/>
		<path d="M400.1,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H400.1z"/>
		<path d="M402.9,564.6c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.5,0,1,0.1,1.4,0.2s0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4s-0.8,0.6-1,1
			c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4c0.8,0,1.5-0.3,2-0.8l0.4,0.5
			c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C404,565,403.4,564.9,402.9,564.6z"/>
		<path d="M408.7,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M412.2,558.9v2.6h3.5v0.6h-3.5v2.8h-0.7v-6.7h4.6v0.6H412.2z"/>
		<path d="M417.6,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M425.2,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H425.2z"/>
		<path d="M426.9,558.3h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2
			s-1.2,0.4-1.9,0.4h-2.7V558.3z M429.6,564.3c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H429.6z"/>
		<path d="M437.3,558.3h0.7v6.7h-0.7V558.3z"/>
		<path d="M445.8,558.3v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H445.8z"/>
		<path d="M451.8,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M461.6,558.3v6.7h-0.7v-3.1h-4.2v3.1H456v-6.7h0.7v3h4.2v-3H461.6z"/>
		<path d="M468.5,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H468.5z"/>
		<path d="M476.8,563.2h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L476.8,563.2z M476.6,562.6L475,559l-1.6,3.6H476.6z"/>
		<path d="M485.1,558.3v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H485.1z"/>
		<path d="M492.9,558.3v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H492.9z"/>
		<path d="M499.8,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H499.8z"/>
		<path d="M505.6,565l-2.2-2.9l-2.2,2.9h-0.8l2.5-3.4l-2.4-3.3h0.8l2,2.7l2-2.7h0.8l-2.4,3.2l2.6,3.5H505.6z"/>
		<path d="M508.2,564.3c-0.5-0.5-0.7-1.2-0.7-2.2v-3.8h0.7v3.8c0,0.8,0.2,1.4,0.5,1.7s0.9,0.6,1.5,0.6c0.7,0,1.2-0.2,1.5-0.6
			s0.5-1,0.5-1.7v-3.8h0.7v3.8c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.2,0.7-2,0.7C509.4,565,508.7,564.8,508.2,564.3z"/>
		<path d="M519.7,565l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H519.7z M519.1,561.8c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2s-0.9-0.4-1.5-0.4h-1.8v3.4h1.8
			C518.3,562.2,518.8,562.1,519.1,561.8z"/>
		<path d="M526.8,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H526.8z"/>
		<path d="M531.4,564.8c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9s0.5-0.5,0.8-0.7s0.8-0.2,1.4-0.2c0.4,0,0.8,0.1,1.1,0.2
			c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			c0.2,0.2,0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2
			C532.3,565,531.8,564.9,531.4,564.8z"/>
		<path d="M537.5,564.3c-0.5-0.5-0.7-1.2-0.7-2.2v-3.8h0.7v3.8c0,0.8,0.2,1.4,0.5,1.7s0.9,0.6,1.5,0.6c0.7,0,1.2-0.2,1.5-0.6
			s0.5-1,0.5-1.7v-3.8h0.7v3.8c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.2,0.7-2,0.7C538.7,565,538,564.8,537.5,564.3z"/>
		<path d="M549.7,562.1c0.2,0.3,0.4,0.6,0.4,1.1c0,0.6-0.2,1-0.6,1.3c-0.4,0.3-1.1,0.5-1.9,0.5h-3.1v-6.7h2.9c0.7,0,1.3,0.2,1.7,0.5
			c0.4,0.3,0.6,0.7,0.6,1.3c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.4,0.5-0.8,0.6C549.1,561.6,549.4,561.8,549.7,562.1z M545.1,558.8v2.4
			h2.1c0.5,0,1-0.1,1.3-0.3s0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9s-0.7-0.3-1.3-0.3H545.1z M548.8,564.1c0.3-0.2,0.5-0.5,0.5-1
			c0-0.8-0.6-1.3-1.8-1.3h-2.4v2.5h2.4C548.1,564.4,548.5,564.3,548.8,564.1z"/>
		<path d="M551.3,564.8c-0.3-0.2-0.6-0.4-0.8-0.7l0.4-0.5c0.4,0.5,0.9,0.8,1.4,0.8c0.8,0,1.2-0.5,1.2-1.5v-4.1h-2.4v-0.6h3.1v4.6
			c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.8,0.5-1.5,0.5C552,565,551.6,564.9,551.3,564.8z"/>
		<path d="M561.2,564.3v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H561.2z"/>
		<path d="M563.9,564.6c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.5,0,1,0.1,1.4,0.2s0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4s-0.8,0.6-1,1
			c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4c0.8,0,1.5-0.3,2-0.8l0.4,0.5
			c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C565,565,564.4,564.9,563.9,564.6z"/>
		<path d="M570.9,558.9h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V558.9z"/>
		<path d="M72,576.7h-2.4v-0.6H75v0.6h-2.4v6.1H72V576.7z"/>
		<path d="M77.2,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C78.3,582.8,77.7,582.7,77.2,582.4z M80.4,581.8c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C79.5,582.2,80,582.1,80.4,581.8z"/>
		<path d="M87.7,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C88.8,582.8,88.2,582.7,87.7,582.4z"/>
		<path d="M94.4,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C95.6,582.8,95,582.7,94.4,582.4z M97.7,581.8c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C96.8,582.2,97.3,582.1,97.7,581.8z"/>
		<path d="M106.9,576.1v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H106.9z"/>
		<path d="M110.4,576.7h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M114.6,576.1h0.7v6.7h-0.7V576.1z"/>
		<path d="M123.1,576.1v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H123.1z"/>
		<path d="M126,582.1c-0.5-0.5-0.7-1.2-0.7-2.2v-3.8h0.7v3.8c0,0.8,0.2,1.4,0.5,1.7c0.4,0.4,0.9,0.6,1.5,0.6c0.7,0,1.2-0.2,1.5-0.6
			c0.4-0.4,0.5-1,0.5-1.7v-3.8h0.7v3.8c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.2,0.7-2,0.7C127.1,582.8,126.4,582.6,126,582.1z"/>
		<path d="M137.6,582.2v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H137.6z"/>
		<path d="M139.3,576.1h2.7c0.7,0,1.3,0.1,1.9,0.4c0.5,0.3,1,0.7,1.3,1.2s0.4,1.1,0.4,1.7s-0.1,1.2-0.4,1.7s-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.2,0.4-1.9,0.4h-2.7V576.1z M142,582.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4
			s-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H142z"/>
		<path d="M150.1,582.6c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			s0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2C151,582.8,150.5,582.8,150.1,582.6z"/>
		<path d="M159.8,581H156l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L159.8,581z M159.5,580.4l-1.6-3.6l-1.6,3.6H159.5z"/>
		<path d="M163.4,576.7H161v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M167.6,576.1h0.7v6.7h-0.7V576.1z"/>
		<path d="M170.9,582.6c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			s0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9s-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2C171.8,582.8,171.3,582.8,170.9,582.6z"/>
		<path d="M177.1,576.7v2.6h3.5v0.6h-3.5v2.8h-0.7v-6.7h4.6v0.6H177.1z"/>
		<path d="M186.3,581h-3.7l-0.8,1.8H181l3.1-6.7h0.7l3.1,6.7h-0.7L186.3,581z M186,580.4l-1.6-3.6l-1.6,3.6H186z"/>
		<path d="M190,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C191.1,582.8,190.5,582.7,190,582.4z"/>
		<path d="M196.9,576.7h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M202.2,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C203.3,582.8,202.7,582.7,202.2,582.4z M205.4,581.8c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C204.5,582.2,205,582.1,205.4,581.8z"/>
		<path d="M213.6,582.8l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H213.6z M213.1,579.6c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4
			h1.8C212.2,580,212.7,579.9,213.1,579.6z"/>
		<path d="M218.2,580.5v2.3h-0.7v-2.3l-2.7-4.4h0.8l2.3,3.8l2.3-3.8h0.7L218.2,580.5z"/>
		<path d="M225.6,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C226.7,582.8,226.1,582.7,225.6,582.4z"/>
		<path d="M232.3,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2c-0.5,0.3-1.1,0.4-1.8,0.4
			C233.5,582.8,232.9,582.7,232.3,582.4z M235.6,581.8c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4C234.7,582.2,235.1,582.1,235.6,581.8z"/>
		<path d="M246.2,576.1v6.7h-0.7v-5.4l-2.6,4.5h-0.3l-2.6-4.5v5.3h-0.7v-6.7h0.6l2.9,5l2.9-5H246.2z"/>
		<path d="M252.9,576.7c0.5,0.4,0.7,1,0.7,1.7c0,0.7-0.2,1.3-0.7,1.7s-1.2,0.6-2,0.6h-1.8v2.1h-0.7v-6.7h2.5
			C251.7,576.1,252.4,576.3,252.9,576.7z M252.4,579.6c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8
			v3.3h1.8C251.5,580,252,579.9,252.4,579.6z"/>
		<path d="M255.1,576.1h0.7v6.1h3.8v0.6h-4.5V576.1z"/>
		<path d="M260.8,576.1h0.7v6.7h-0.7V576.1z"/>
		<path d="M267.9,581h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L267.9,581z M267.6,580.4l-1.6-3.6l-1.6,3.6H267.6z"/>
		<path d="M276.1,576.1v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H276.1z"/>
		<path d="M279.4,582.4c-0.5-0.3-1-0.7-1.3-1.2c-0.3-0.5-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2
			c0.5-0.3,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.4,0.2c0.4,0.2,0.8,0.4,1.1,0.7l-0.4,0.4c-0.5-0.5-1.2-0.8-2-0.8c-0.5,0-1,0.1-1.4,0.4
			c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.8,0,1.5-0.3,2-0.8l0.4,0.5c-0.3,0.3-0.7,0.6-1.1,0.7c-0.4,0.2-0.9,0.3-1.4,0.3C280.6,582.8,280,582.7,279.4,582.4z"/>
		<path d="M289.9,582.2v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H289.9z"/>
		<path d="M295.4,576.7H293v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M300.6,582.4c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.1,0.4-1.8,0.4C301.8,582.8,301.2,582.7,300.6,582.4z M303.9,581.8c0.4-0.2,0.8-0.6,1-1s0.4-0.9,0.4-1.4
			c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4s-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4S303.4,582.1,303.9,581.8z"/>
		<path d="M311.3,576.7H309v-0.6h5.4v0.6H312v6.1h-0.7V576.7z"/>
		<path d="M321.1,576.1v6.7h-0.7v-3.1h-4.2v3.1h-0.7v-6.7h0.7v3h4.2v-3H321.1z"/>
		<path d="M328,582.2v0.6h-4.7v-6.7h4.6v0.6H324v2.4h3.5v0.6H324v2.5H328z"/>
		<path d="M336.4,581h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L336.4,581z M336.1,580.4l-1.6-3.6l-1.6,3.6H336.1z"/>
		<path d="M344.3,579.9c0.2,0.3,0.4,0.6,0.4,1.1c0,0.6-0.2,1-0.6,1.3c-0.4,0.3-1.1,0.5-1.9,0.5h-3.1v-6.7h2.9c0.7,0,1.3,0.2,1.7,0.5
			c0.4,0.3,0.6,0.7,0.6,1.3c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.4,0.5-0.8,0.6C343.7,579.4,344.1,579.6,344.3,579.9z M339.8,576.7v2.4
			h2.1c0.5,0,1-0.1,1.3-0.3s0.4-0.5,0.4-0.9s-0.1-0.7-0.4-0.9s-0.7-0.3-1.3-0.3H339.8z M343.5,581.9c0.3-0.2,0.5-0.5,0.5-1
			c0-0.8-0.6-1.3-1.8-1.3h-2.4v2.5h2.4C342.7,582.2,343.2,582.1,343.5,581.9z"/>
		<path d="M347.4,582.4c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.1,0.4-1.8,0.4C348.5,582.8,347.9,582.7,347.4,582.4z M350.6,581.8c0.4-0.2,0.8-0.6,1-1s0.4-0.9,0.4-1.4
			c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4s-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4S350.2,582.1,350.6,581.8z"/>
		<path d="M359.7,576.1l-3,6.7H356l-3-6.7h0.8l2.6,5.8l2.6-5.8H359.7z"/>
		<path d="M365.4,582.2v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H365.4z"/>
		<path d="M370,582.6c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			s-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9s0.5-0.5,0.8-0.7s0.8-0.2,1.4-0.2c0.4,0,0.8,0.1,1.1,0.2
			c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6s0.4,0.3,0.6,0.4s0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.1,0.6,0.3,0.8,0.5
			c0.2,0.2,0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.2-1.4,0.2
			C370.9,582.8,370.4,582.8,370,582.6z"/>
		<path d="M376.6,576.7h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M384.7,581H381l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L384.7,581z M384.4,580.4l-1.6-3.6l-1.6,3.6H384.4z"/>
		<path d="M392.9,576.1v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H392.9z"/>
		<path d="M395.1,576.1h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2
			s-1.2,0.4-1.9,0.4h-2.7V576.1z M397.8,582.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H397.8z"/>
		<path d="M407.1,581h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L407.1,581z M406.9,580.4l-1.6-3.6l-1.6,3.6H406.9z"/>
		<path d="M414.4,582.8l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H414.4z M413.8,579.6c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2s-0.9-0.4-1.5-0.4h-1.8v3.4h1.8
			C412.9,580,413.4,579.9,413.8,579.6z"/>
		<path d="M416.7,576.1h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2
			s-1.2,0.4-1.9,0.4h-2.7V576.1z M419.4,582.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H419.4z"/>
		<path d="M431.9,582.9l-0.9-1c-0.6,0.6-1.3,0.9-2.2,0.9c-0.4,0-0.8-0.1-1.2-0.2s-0.6-0.3-0.8-0.6c-0.2-0.3-0.3-0.6-0.3-0.9
			c0-0.4,0.1-0.8,0.4-1.1s0.7-0.7,1.3-1c-0.3-0.3-0.5-0.6-0.7-0.8c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.4,0.2-0.8,0.5-1
			c0.3-0.3,0.7-0.4,1.2-0.4c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.6,0.6-1.2,0.9l1.8,1.9
			c0.2-0.4,0.4-0.8,0.4-1.3l0.6,0.2c-0.1,0.6-0.3,1.1-0.6,1.5l1,1L431.9,582.9z M430.6,581.5l-2-2.1c-0.5,0.3-0.9,0.6-1.1,0.8
			c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.3,1.2,0.3C429.5,582.3,430.1,582,430.6,581.5z M428.2,576.8
			c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.6,0.7c0.5-0.3,0.8-0.5,1-0.7c0.2-0.2,0.3-0.4,0.3-0.7
			c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.2-0.7-0.2C428.7,576.5,428.4,576.6,428.2,576.8z"/>
		<path d="M437.3,576.7H435v-0.6h5.4v0.6H438v6.1h-0.7V576.7z"/>
		<path d="M447.1,576.1v6.7h-0.7v-3.1h-4.2v3.1h-0.7v-6.7h0.7v3h4.2v-3H447.1z"/>
		<path d="M454,582.2v0.6h-4.7v-6.7h4.6v0.6H450v2.4h3.5v0.6H450v2.5H454z"/>
		<path d="M462.4,581h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L462.4,581z M462.1,580.4l-1.6-3.6l-1.6,3.6H462.1z"/>
		<path d="M465.1,576.1h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2
			s-1.2,0.4-1.9,0.4h-2.7V576.1z M467.7,582.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H467.7z"/>
		<path d="M473,576.1h2.7c0.7,0,1.3,0.1,1.9,0.4s1,0.7,1.3,1.2s0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7s-0.7,0.9-1.3,1.2s-1.2,0.4-1.9,0.4
			H473V576.1z M475.6,582.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4s-0.1-1-0.4-1.4
			c-0.2-0.4-0.6-0.7-1-1c-0.4-0.2-1-0.3-1.5-0.3h-2v5.5H475.6z"/>
		<path d="M480.9,576.1h0.7v6.7h-0.7V576.1z"/>
		<path d="M485.1,576.7h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V576.7z"/>
		<path d="M489.2,576.1h0.7v6.7h-0.7V576.1z"/>
		<path d="M493.3,582.4c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.1,0.4-1.8,0.4C494.4,582.8,493.8,582.7,493.3,582.4z M496.5,581.8c0.4-0.2,0.8-0.6,1-1s0.4-0.9,0.4-1.4
			c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4s-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4S496.1,582.1,496.5,581.8z"/>
		<path d="M505.8,576.1v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H505.8z"/>
		<path d="M512.1,581h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L512.1,581z M511.9,580.4l-1.6-3.6l-1.6,3.6H511.9z"/>
		<path d="M514.8,576.1h0.7v6.1h3.8v0.6h-4.5V576.1z"/>
		<path d="M228.1,600.6l-1.5-2.2c-0.2,0-0.4,0-0.5,0h-1.8v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H228.1z M227.5,597.4c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4h-1.8v3.4
			h1.8C226.6,597.9,227.1,597.7,227.5,597.4z"/>
		<path d="M235.1,600v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H235.1z"/>
		<path d="M243.5,601.2c-0.2,0.2-0.4,0.4-0.7,0.6s-0.6,0.2-0.9,0.2c-0.4,0-0.8-0.1-1.1-0.3s-0.8-0.5-1.2-1c-0.6,0-1.2-0.2-1.7-0.5
			s-0.9-0.7-1.2-1.2c-0.3-0.5-0.4-1.1-0.4-1.7c0-0.6,0.2-1.2,0.5-1.7c0.3-0.5,0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2s0.5,1.1,0.5,1.7c0,0.6-0.1,1.1-0.3,1.5s-0.6,0.9-1,1.2c-0.4,0.3-0.9,0.5-1.4,0.6
			c0.5,0.5,1,0.8,1.5,0.8c0.5,0,0.9-0.2,1.3-0.6L243.5,601.2z M237.2,598.7c0.2,0.4,0.6,0.8,1,1c0.4,0.2,0.9,0.4,1.4,0.4
			c0.5,0,1-0.1,1.4-0.4c0.4-0.2,0.8-0.6,1-1c0.2-0.4,0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1
			c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5,0-1,0.1-1.4,0.4c-0.4,0.2-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			C236.9,597.8,237,598.2,237.2,598.7z"/>
		<path d="M245.5,599.9c-0.5-0.5-0.7-1.2-0.7-2.2v-3.8h0.7v3.8c0,0.8,0.2,1.4,0.5,1.7c0.4,0.4,0.9,0.6,1.5,0.6
			c0.7,0,1.2-0.2,1.5-0.6c0.4-0.4,0.5-1,0.5-1.7v-3.8h0.7v3.8c0,1-0.2,1.7-0.7,2.2c-0.5,0.5-1.2,0.7-2,0.7
			C246.6,600.6,245.9,600.4,245.5,599.9z"/>
		<path d="M252.4,593.9h0.7v6.7h-0.7V593.9z"/>
		<path d="M259.8,600.6l-1.5-2.2c-0.2,0-0.4,0-0.5,0H256v2.1h-0.7v-6.7h2.5c0.9,0,1.5,0.2,2,0.6s0.7,1,0.7,1.7c0,0.5-0.1,1-0.4,1.3
			s-0.6,0.6-1.1,0.8l1.6,2.3H259.8z M259.3,597.4c0.4-0.3,0.5-0.7,0.5-1.2c0-0.5-0.2-1-0.5-1.2c-0.4-0.3-0.9-0.4-1.5-0.4H256v3.4
			h1.8C258.4,597.9,258.9,597.7,259.3,597.4z"/>
		<path d="M266.9,600v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H266.9z"/>
		<path d="M275.5,593.9v6.7h-0.7v-5.4l-2.6,4.5h-0.3l-2.6-4.5v5.3h-0.7v-6.7h0.6l2.9,5l2.9-5H275.5z"/>
		<path d="M282.5,600v0.6h-4.7v-6.7h4.6v0.6h-3.9v2.4h3.5v0.6h-3.5v2.5H282.5z"/>
		<path d="M289.7,593.9v6.7h-0.6l-4.3-5.4v5.4h-0.7v-6.7h0.6l4.3,5.4v-5.4H289.7z"/>
		<path d="M293.2,594.5h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V594.5z"/>
		<path d="M297.7,600.4c-0.5-0.2-0.8-0.4-1.1-0.6l0.3-0.5c0.2,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.5-0.5,0.5-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.6-0.2-1-0.3c-0.5-0.1-0.9-0.3-1.2-0.4
			c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.3-0.6-0.3-1c0-0.3,0.1-0.6,0.3-0.9s0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.2,1.4-0.2
			c0.4,0,0.8,0.1,1.1,0.2c0.4,0.1,0.7,0.3,1,0.4l-0.2,0.6c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1,0.1-1.3,0.3
			c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.6,0.2,1,0.3c0.5,0.1,0.9,0.2,1.2,0.4
			c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.6,0.3,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7s-0.8,0.2-1.4,0.2
			C298.6,600.6,298.2,600.6,297.7,600.4z"/>
		<path d="M306.8,600.2c-0.5-0.3-1-0.7-1.3-1.2s-0.5-1.1-0.5-1.7s0.2-1.2,0.5-1.7s0.7-0.9,1.3-1.2s1.1-0.4,1.8-0.4
			c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.7s-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2
			c-0.5,0.3-1.1,0.4-1.8,0.4C307.9,600.6,307.3,600.5,306.8,600.2z M310,599.7c0.4-0.2,0.8-0.6,1-1s0.4-0.9,0.4-1.4
			c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.4-1.4-0.4s-1,0.1-1.4,0.4s-0.8,0.6-1,1c-0.2,0.4-0.4,0.9-0.4,1.4
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.8,1,1s0.9,0.4,1.4,0.4S309.6,599.9,310,599.7z"/>
		<path d="M314.4,594.5v2.6h3.5v0.6h-3.5v2.8h-0.7v-6.7h4.6v0.6H314.4z"/>
		<path d="M326.5,598.8h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L326.5,598.8z M326.2,598.2l-1.6-3.6l-1.6,3.6H326.2z"/>
		<path d="M333.4,598.8h-3.7l-0.8,1.8h-0.7l3.1-6.7h0.7l3.1,6.7h-0.7L333.4,598.8z M333.1,598.2l-1.6-3.6l-1.6,3.6H333.1z"/>
		<path d="M336.1,593.9h0.7v6.7h-0.7V593.9z"/>
		<path d="M339,593.9h0.7v6.1h3.8v0.6H339V593.9z"/>
		<path d="M351.9,600.7l-0.9-1c-0.6,0.6-1.3,0.9-2.2,0.9c-0.4,0-0.8-0.1-1.2-0.2s-0.6-0.3-0.8-0.6c-0.2-0.3-0.3-0.6-0.3-0.9
			c0-0.4,0.1-0.8,0.4-1.1s0.7-0.7,1.3-1c-0.3-0.3-0.5-0.6-0.7-0.8c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.4,0.2-0.8,0.5-1
			c0.3-0.3,0.7-0.4,1.2-0.4c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.6,0.6-1.2,0.9l1.8,1.9
			c0.2-0.4,0.4-0.8,0.4-1.3l0.6,0.2c-0.1,0.6-0.3,1.1-0.6,1.5l1,1L351.9,600.7z M350.6,599.3l-2-2.1c-0.5,0.3-0.9,0.6-1.1,0.8
			c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.3,1.2,0.3C349.5,600.1,350.1,599.8,350.6,599.3z M348.2,594.6
			c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.6,0.7c0.5-0.3,0.8-0.5,1-0.7c0.2-0.2,0.3-0.4,0.3-0.7
			c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.2-0.7-0.2C348.7,594.4,348.4,594.4,348.2,594.6z"/>
		<path d="M356.7,594.5v2.6h3.5v0.6h-3.5v2.8H356v-6.7h4.6v0.6H356.7z"/>
		<path d="M363.4,594.5h-2.4v-0.6h5.4v0.6h-2.4v6.1h-0.7V594.5z"/>
	</g>
	<g class="st1505">
		<path class="st198" d="M466.9,61.9h17.5c0.4,0.3,0.6,0.7,0.8,1.1c0.9,2.6,1.9,5.3,2.8,7.9c0.7,2.1,1.4,4.1,2.1,6.2
			c0.1,0.2,0.1,0.4,0.2,0.6c0.6,1.6,1.2,3.1,1.8,4.7c0.4,1.2,0.8,2.5,1.3,3.7c0.5,1.4,1.2,2.8,1.5,4.3c0,0,0,0,0,0.1
			c0.5,1.2,1,2.4,1.3,3.6c0.5,1.7,1.1,3.4,1.8,5c0.5,1.1,0.9,2.2,1.1,3.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.4,0.8,0.6,1.2c0.2,0.4,0.3,0.8,0.4,1.2c1.8,5,3.5,10,5.3,15c1,2.9,2.1,5.8,3.2,8.8
			c0.3,0.9,0.6,1.9,1,2.8c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.4,0.3,0.8,0.3,1.2c0,0,0,0,0,0
			c0.2,0.4,0.5,0.8,0.7,1.2c-0.2,0.3-0.1,0.2-0.3,0.5h-16c-0.3-0.2-0.5-0.5-0.5-0.8c0-0.1-0.1-0.2-0.1-0.3c-1.2-3.3-2.4-6.5-3.6-9.8
			c-0.4-1.2-0.8-2.5-1.3-3.7c-0.8-2.5-1.8-4.9-2.7-7.4c-1.1-3.1-2.2-6.3-3.3-9.4c-0.5-1.4-1-2.7-1.5-4.1c-1.2-3.5-2.5-7-3.7-10.5
			c-0.8-2.4-1.7-4.7-2.6-7.1c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.3-0.2,0.4c-0.4,1.1-0.8,2.1-1.2,3.2c-0.7,1.9-1.4,3.9-2.1,5.8
			c-0.3,1-0.7,1.9-1.1,2.8c-0.4,1.2-0.8,2.3-1.2,3.5c-0.8,2.1-1.5,4.3-2.3,6.4c-0.2,0.5-0.4,1.1-0.7,1.6c-0.2,0.5-0.7,0.8-1.2,0.9
			c-0.3,0-0.6,0.1-0.9,0.1c-1.6,0-3.2,0-4.7,0c-3.1,0-6.2,0-9.4,0c-0.2,0-0.3,0-0.5,0c0.1-0.2,0.1-0.3,0.1-0.5
			c0.5-1.4,0.9-2.9,1.6-4.3c0-0.1,0-0.2,0.1-0.3c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.5-1.4,1-2.9,1.5-4.3
			c0.3-0.8,0.6-1.5,0.9-2.3c0.5-1.4,0.9-2.8,1.4-4.1c0.3-0.9,0.8-1.8,1-2.7c0.3-1.1,0.7-2.2,1.1-3.2c0.4-0.9,0.7-1.7,1-2.6
			c0.3-0.9,0.6-1.9,0.9-2.8c0.4-1,0.8-2.1,1.2-3.1c0.7-2.1,1.5-4.2,2.2-6.3c0.8-2.3,1.6-4.6,2.4-6.8
			C466.4,62.4,466.6,62.1,466.9,61.9z"/>
		<linearGradient id="SVGID_151_" gradientUnits="userSpaceOnUse" x1="418.5338" y1="99.2888" x2="477.8578" y2="99.2888">
			<stop  offset="0" style="stop-color:#123154"/>
			<stop  offset="1" style="stop-color:#0B1B30"/>
		</linearGradient>
		<path class="st1523" d="M446,61.9h15.2c0.2,0.2,0.2,0.4,0.1,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.5,1.5-1.1,3-1.6,4.5
			c-1.9,5.3-3.8,10.6-5.8,15.9c-0.2,0.6-0.5,1.3-0.7,1.9c-0.2,0.6-0.3,1.2-0.5,1.9c-0.9,2.4-1.8,4.9-2.7,7.3
			c-0.8,2.3-1.6,4.6-2.5,6.9c-0.6,1.7-1.3,3.4-1.9,5.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.6-0.3,1.2-0.5,1.7c-0.2,0.8-0.5,1.5-0.8,2.3
			c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c5.5,0,11,0.1,16.5,0.1c4,0,8.1-0.1,12.1-0.1c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0.2,0.7,0.5
			c0.1,0.1,0.1,0.3,0.2,0.4c0.5,1.4,1,2.8,1.5,4.2c0.7,1.8,1.3,3.7,2,5.5c0.3,0.7,0.1,1-0.7,1c-2.8,0-5.6,0-8.4,0
			c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0c-0.3,0-0.5,0-0.7,0c-4,0-8.1,0-12.1,0c-2.3,0-4.7,0-7-0.1c-1.5,0-3.1,0.1-4.6-0.1
			c-0.1,0-0.3,0-0.4,0c-0.9,0.1-1.5,0.5-1.9,1.3c-0.2,0.4-0.3,0.7-0.4,1.1c-0.2,0.7-0.3,1.5-0.5,2.2c-0.4,1.3-0.8,2.5-1.4,3.7
			c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.3-0.3,0.5-0.4,0.8c-0.3,1-0.7,2.1-1,3.1c-0.2,0.5-0.4,0.8-0.8,1h-15.8v-1.1
			c0.2-0.1,0.2-0.2,0.2-0.4c0.8-2.2,1.6-4.3,2.3-6.5c1.2-3.4,2.5-6.9,3.7-10.3c0.8-2.4,1.7-4.8,2.5-7.1c0.4-1.1,0.9-2.2,1.2-3.2
			c0.7-2,1.3-4,2-5.9c1-2.8,2-5.6,3-8.4c0.6-1.7,1.2-3.4,1.9-5c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.6,0.3-1.1,0.5-1.7
			c0.2-0.6,0.4-1.1,0.6-1.7c2.6-7.3,5.2-14.7,7.8-22c0,0,0,0,0,0C444.8,62.3,445.3,61.9,446,61.9z"/>
		<path class="st198" d="M440.4,136.7c-0.3-0.3-0.2-0.7-0.1-1c0.2-0.7,0.5-1.4,0.7-2c0.7-2.1,1.5-4.2,2.2-6.3c0-0.1,0.1-0.2,0.1-0.4
			c0.1-0.3,0.3-0.4,0.6-0.5c0.2,0,0.4-0.1,0.6-0.1c4.8,0,9.5,0,14.3,0c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.4,0.2,0.4,0.5
			c0,0.2-0.1,0.3-0.1,0.5c-1,2.8-2,5.5-3,8.3c-0.1,0.4-0.3,0.6-0.6,0.9C450.9,136.7,445.7,136.7,440.4,136.7z"/>
		<linearGradient id="SVGID_152_" gradientUnits="userSpaceOnUse" x1="493.6761" y1="99.2888" x2="533.5756" y2="99.2888">
			<stop  offset="0" style="stop-color:#123154"/>
			<stop  offset="1" style="stop-color:#0B1B30"/>
		</linearGradient>
		<path class="st1524" d="M533.6,134.3c0,0.4-0.1,0.7-0.4,1c-0.2,0.3-0.7,0.6-1.2,0.7c-0.6,0.2-1.3,0.3-2.3,0.5
			c-1,0.1-2.2,0.2-3.7,0.2c-1.5,0-2.7-0.1-3.6-0.2c-1-0.1-1.7-0.3-2.3-0.5c-0.6-0.2-1-0.4-1.2-0.7c-0.2-0.3-0.4-0.6-0.4-1l-24.8-70
			c0-0.4,0.1-0.7,0.4-1c0.2-0.3,0.7-0.6,1.3-0.7c0.6-0.2,1.4-0.3,2.3-0.5c1-0.1,2.2-0.2,3.6-0.2c1.5,0,2.7,0.1,3.7,0.2
			c1,0.1,1.7,0.3,2.3,0.5c0.6,0.2,1,0.4,1.2,0.7c0.2,0.3,0.4,0.6,0.4,1L533.6,134.3z"/>
		<linearGradient id="SVGID_153_" gradientUnits="userSpaceOnUse" x1="517.4646" y1="97.7533" x2="581.5377" y2="97.7533">
			<stop  offset="0" style="stop-color:#123154"/>
			<stop  offset="1" style="stop-color:#0B1B30"/>
		</linearGradient>
		<path class="st1525" d="M581.5,128.8c0,1.2,0,2.1-0.1,2.9c-0.1,0.8-0.2,1.4-0.4,2c-0.2,0.5-0.4,0.9-0.7,1.1
			c-0.3,0.2-0.6,0.3-1,0.3h-34.1c-1.3,0-2.3-0.4-3.2-1.1c-0.8-0.7-0.8-1.8-1.3-3.4c0-0.2,0-0.3-0.1-0.5l-23-66.9
			c-0.2-0.5-0.1-1,0.2-1.4c0,0,0,0,0,0c0.2-0.3,0.7-0.6,1.2-0.7c0.6-0.2,1.4-0.3,2.3-0.5c1-0.1,2.2-0.2,3.7-0.2
			c1.5,0,2.7,0.1,3.7,0.2c1,0.1,1.7,0.3,2.3,0.5c0.6,0.2,1,0.4,1.2,0.7c0.2,0.3,0.3,0.6,0.4,0.9c0,0.1,0,0.2,0.1,0.4l22.8,58.7
			c0.2,0.6,0.8,1,1.4,1h22.3c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.5,0.6,0.7,1c0.2,0.5,0.3,1.1,0.4,1.9
			C581.5,126.7,581.5,127.7,581.5,128.8z"/>
	</g>
	<rect x="418.5" y="141.8" class="st1526" width="194.5" height="3.2"/>
</g>
</svg>

  </div>
</template>

<script>
import db from '@/fb';


export default {
  props: {
    certificate:{},
    company:{},
    location:{},
    token:{},
    date:{},
    dateTo:{},
  },
  data() {
    return {
      discipline: 'Iadil international standards',
    }
  },
  methods: {
    
  },
  computed: {
    companyFont(){
      if(this.$props.company){
        let a = (14 / (this.$props.company.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 53
        let b = this.$props.company.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length < 15 ? 53 : a
        return b
      }
    },
    companyLeft(){
      if(this.$props.company){
        let a = (14 / (this.$props.company.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 300
        a = 60
        return a
      }
    },
    locationFont(){
      if(this.$props.location){
        let a = (13 / (this.$props.location.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length)) * 24
        let b = this.$props.location.replace(/[\/\.\*\\#$\[\]\t\n\r\s]/g, '_').length < 14 ? 24 : a
        b = 24
        return b
      }
    },
    locationLeft(){
      let a = 180
      return a
    },
  }, 
  watch: {
    
  },
  mounted() {
      
  }
}
</script>
