var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('v-container',{staticClass:"my-5"},[_c('v-layout',{staticClass:"mb-3",attrs:{"wrap":"","row":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","px-1":""}},[_c('v-text-field',{attrs:{"label":"Search By Name/Email","solo":""},on:{"input":function($event){_vm.filteraima_users()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"text-xs-center"},[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_vm._e(),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.filteredaima_users.length)+" Results ")]),_vm._v(" "),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pagesNumber,"total-visible":5},on:{"input":_vm.updateVisible},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},_vm._l((_vm.visibleaima_users),function(center){return _c('v-flex',{key:center.key,attrs:{"xs12":"","sm12":"","md12":"","mb-1":""}},[_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_vm._l((Object.keys(center).filter(function (x){
                  var show = ['aima_report_token', 'company', 'email', 'name', 'password', 'registerDate']
                  if(typeof center[x] != 'object'
                    && show.indexOf(x) != -1
                  ) {
                    return x
                  }
                })),function(key){return _c('v-flex',{key:center.key+key,attrs:{"xs3":""}},[_c('div',{staticStyle:{"font-size":"11px","font-weight":"600"}},[_vm._v(_vm._s(key.toUpperCase()))]),_vm._v(" "),_c('div',{staticClass:"subheadline"},[_vm._v(_vm._s(center[key]))])])}),_vm._v(" "),_c('v-flex',{attrs:{"xs3":""}},[_c('div',{staticStyle:{"font-size":"11px","font-weight":"600"}},[_vm._v("SESSIONS")]),_vm._v(" "),(center.sessions)?_c('div',[_vm._v(_vm._s(Object.keys(center.sessions).length))]):_vm._e()])],2)],1),_vm._v(" "),_c('v-card-actions',{attrs:{"dense":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.resetPass(center)}}},[_vm._v("Deactivate User")])],1)],1)],1)})),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","py-3":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pagesNumber,"total-visible":7},on:{"input":_vm.updateVisible},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }