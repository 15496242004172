<template>
  <div class="projects">
    <v-container class="my-5">
      <v-card v-if="!dbsuccess">
        <v-img
            class="white--text"
            height="100px"
            src="https://portal.iaidl.org/tback.jpg"
        >
          <v-container fill-height fluid>
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <span class="headline">Add Asset</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
        <v-layout wrap ma-3>
          <v-flex xs12 sm12 px-1 my-1>
            <v-text-field
                label="Token"
                readonly
                v-model="token"
                solo-inverted
                suffix="Token"
                :loading="tokenLoading"
                disabled
                light
                class="tokenField"
                color="white"
                background-color="primary"
            ></v-text-field>
          </v-flex>

          <v-flex xs8 sm12 px-1 my-1>
            <v-textarea
                label="Asset Title"
                v-model="AssetDetails"
                solo
                clearable
                required
                multi-line
                :error-messages="nameErrors"
                @blur="$v.AssetDetails.$touch()"
                :disabled="noToken"
            ></v-textarea>
          </v-flex>



          <v-flex xs12 sm12 my-1>
              <v-text-field
                  v-model="file"
                  label="PDF file"
                  outline
                  :loading="uploadProgress"
                  append-outer-icon="cloud_upload"
                  @click:append-outer="$refs.file.click()"
              ></v-text-field>

              <input
                  type="file"
                  style="display: none"
                  ref="file"
                  accept="csv/*"
                  @change="onFilePicked"
              >
          </v-flex>





          <v-flex xs12 sm12 my-1>
            <v-card-actions>
              <span v-if="dberror" class="red--text">{{ dberror }}</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="isLoading"  :dark="false" @click="submit()"  large>
                Add Asset
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, between, email, numeric, alpha } from 'vuelidate/lib/validators'
import { titleCase, randomString } from '@/customFunction'
import {firebase, db, auth, createFirebase, storageRef} from '@/fb';

export default {
  mixins: [validationMixin],
  props: ['userName'],
  data() {
    return {
      date: new Date().toISOString(),
      noToken:false,
      resumeSub:false,
      dialog:false,
      isLoading:false,
      AssetDetails:"",
      file:null,
      subBtn:null,
      dberror:null,
      dbsuccess:null,
      printBtn:true,
      tokenKey:null,
      tokenCode:null,
      uploadProgress:null,
      tokenLoading:true
    }
  },
  methods: {
    onFilePicked (e) {
      let aa = confirm("this will upload the selected file")
      if( aa == true ){

        this.loading = true
        const files = e.target.files
        if(files[0] !== undefined) {
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.file = files[0] // this is an image file that can be sent to server...
            // console.log(this.file)
            let thsType = this.file.type
            //alert(thsType)
            if(thsType != 'application/pdf'){
              alert("You should select a pdf file")
            } else {
              this.loadFile(this.file, this.file.type, this.file.name.split('.').pop())
            }

          })
        } else {
          this.name = null
          this.fileName = null
        }


      }

    },
    loadFile(filer, type, ext){
      let file = filer
      let metadata = {
        contentType: type
      };
      let uploadTask = storageRef.child('assets_pdfs/ref_' + randomString(8, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot)=> {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadProgress = true
          }, (error) =>{
            console.log(error);
            switch (error.code) {

              case 'storage/unauthorized':
                break;
              case 'storage/canceled':
                break;
              case 'storage/unknown':
                break;
            }
          },() => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
              console.log('File available at', downloadURL);
              this.file = downloadURL
              this.resumeSub = true
              this.loading = false
              this.uploadProgress = false
              alert("File Uploaded")
            });
          });
    },
    submit() {
      if(this.resumeSub){
        //alert("file found")
        //-----

        this.$v.$touch()
        if (this.noToken) {
          return
        }
        if (this.$v.$invalid) {
          console.log('errors there')
        } else {

          let refe = {
            title:this.AssetDetails,
            file:this.file,
          }
          db.ref('assets/').push(refe)
              .then(x=>{
                this.goTo('/assets')
              })


        }

        //-----
      } else {
        alert("No File Found, please upload the file first")
      }


    },
    goTo(path) {
      this.$router.push(path)
    },

  },
  watch: {

  },
  computed: {
    token() {
      this.tokenLoading = true
      let vtoken = `IAIDL_ANC_${randomString(8, 'aA#')}`
      this.tokenCode = vtoken
      this.tokenLoading = false
      this.noToken = false
      return vtoken
    },
    nameErrors () {
      const errors = []
      return errors
    },
  },
  validations: {
    AssetDetails: {
      required
    }
  },
  created() {
    this.uploadProgress = false
  }
}
</script>
<style>
.tokenField * {
  color:white !important;
}
</style>
